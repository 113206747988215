/*jshint esversion:9*/

import {pointsActionTypes} from './points-types';

export const setDefaultPoints = array => ({
  type:pointsActionTypes.SET_DEFAULT_ACTION_POINTS,
  payload:array
});

export const addDefaultPoints = object => ({
  type:pointsActionTypes.ADD_DEFAULT_ACTION_POINTS,
  payload:object
});

export const editDefaultPoint = object => ({
  type:pointsActionTypes.EDIT_DEFAULT_ACTION_POINTS,
  payload:object
});

export const deleteDefaultPoint = id => ({
  type:pointsActionTypes.DELETE_DEFAULT_ACTION_POINT,
  payload:id
});
