/*jshint esversion:9*/

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, Button, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
//import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import OutlinePerson from '../../../Assets/outline-person.png'
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Skill from '../../../Assets/skill.png';
import Interest from '../../../Assets/interest.png';
import './progressBar-style.scss';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';


const ColorlibConnector = withStyles({
  vertical: {
    width:'5px',
    padding: '0 23px 0',
    '& span': {
      height:'100%'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
    '& span': {
      borderColor: '#FFCC33',
    },
  },
  active: {
    borderColor: '#784af4',
    '& span': {
      borderColor: '#FFCC33',
    },
  },

})(StepConnector);


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height:'550px',
    border: 'none!important'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Create your profile', 'Select your skills', 'Select your Interests'];
}



function ColorlibStepIcon(props) {

  const { active, completed } = props;

  const useColorlibStepIconStyles = makeStyles({
    root: {
      fontSize:'1rem!important',
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 70,
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      'clip-path': 'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'
    },
    stepIconContainer:{
      filter: active && 'drop-shadow(rgba(0, 0, 0, 0.35) 0px 5px 15px)',
    },
    active: {
      backgroundColor:'#FFFFFF',
      color: '#FFCC33',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.7)',
    },
    completed: {
      backgroundColor:'#FFCC33',
    },
    'MuiStepLabel-active':{
      fontSize:'1.5rem!important'
    }
  });


  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <PersonIcon style={{width:'28px', height:'28px'}}/>,
    2: <BuildIcon style={{width:'28px', height:'28px'}}/>,
    3: <FavoriteIcon style={{width:'28px', height:'28px'}}/>,
  };

  return (
    <div className={classes.stepIconContainer}>
      <div
        style={{fontSize:'1rem!important'}}
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
          {icons[String(props.icon)]}
      </div>
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`;
    case 1:
      return 'An ad group contains one or more ads which target a shared set of keywords.';
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return 'Unknown step';
  }
}

export default function ProgressBar({step,background}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const stepLabelStyles = makeStyles({
    label:{
      fontSize: '1.5rem!important',

      '& .MuiStepLabel-labelContainer' : {
        '& .MuiStepLabel-active' : {
          fontSize: '1.2rem!important',
          fontWeight: '700'
        }
      }
    }
  })

  const stepLabelClasses = stepLabelStyles();

  React.useEffect(()=>{
    setActiveStep(step);
  },[step])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper style={{height:'100%',border: 'none', boxShadow:'none',background:'#FAFAFA',padding:'15% 0 30%'}} activeStep={activeStep} orientation="vertical" connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel className={stepLabelClasses.label} StepIconComponent={ColorlibStepIcon} style={{fontSize:activeStep === index ? '1.5rem!important' : '0.5rem!important'}}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}












// const ColorlibConnector = withStyles({
//   alternativeLabel: {
//     top: 22,
//   },
//   active: {
//     '& $line': {
//       borderTop:'none',
//       heigth:'5px',
//       marginTop:15,
//       borderTopStyle:'dashed',
//       borderTopWidth:'2px',
//       borderColor: '#FFCC33',
//           borderRadius: 5,
//     },
//   },
//   completed: {
//     '& $line': {
//       borderTop:'none',
//       heigth:'5px',
//       marginTop:15,
//       borderTopStyle:'dashed',
//       borderTopWidth:'2px',
//       borderColor: '#FFCC33',
//           borderRadius: 5,
//     },
//   },
//   line: {
//     borderTop:'none',
//     heigth:'5px',
//     marginTop:15,
//     borderTopStyle:'dashed',
//     borderTopWidth:'2px',
//     borderColor: '#eaeaf0',
//     borderRadius: 5,
//   },

// })(StepConnector);

// const useColorlibStepIconStyles = makeStyles({
//   root: {
//     fontSize:'1rem!important',
//     backgroundColor: '#ccc',
//     zIndex: 1,
//     color: '#fff',
//     width: 70,
//     height: 80,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     'clip-path': 'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'
//   },
//   active: {
//     backgroundColor:'#FFCC33',
//     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
//   },
//   completed: {
//     backgroundColor:'#FFCC33',
//   },
// });

// function ColorlibStepIcon(props) {
//   const classes = useColorlibStepIconStyles();
//   const { active, completed } = props;

//   const icons = {
//     1: <img src={OutlinePerson} alt='person' width='28' height='28'/>,
//     2: <img src={Skill} alt='skill' width='28' height='28'/>,
//     3: <img src={Interest} alt='interest' width='28' height='28'/>,
//   };

//   return (
//     <div
//       style={{fontSize:'1rem!important'}}
//       className={clsx(classes.root, {
//         [classes.active]: active,
//         [classes.completed]: completed,
//       })}
//     >
//       {icons[String(props.icon)]}
//     </div>
//   );
// }

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    */
//   active: PropTypes.bool,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node,
// };



// function getSteps() {
//   return ['Create your profile', 'Select your skills', 'Select your Interests'];
// }

// // function getStepContent(step) {
// //   switch (step) {
// //     case 0:
// //       return 'Select campaign settings...';
// //     case 1:
// //       return 'What is an ad group anyways?';
// //     case 2:
// //       return 'This is the bit I really care about!';
// //     default:
// //       return 'Unknown step';
// //   }
// // }

// export default function ProgressBar({step, background}) {

//   const useStyles = makeStyles((theme) => ({
//     root: {
//       backgroundColor: background,
//       width: '100%',
//     },
//     stepper: {
//       backgroundColor: background,
//       boxShadow:'none!important',      
//       height:'100%'
//     },
//     button: {
//       marginRight: theme.spacing(1),
//     },
//     instructions: {
//       marginTop: theme.spacing(1),
//       marginBottom: theme.spacing(1),
//     },
//   }));

  // React.useEffect(()=>{
  //   setActiveStep(step);
  // },[step])

//   const classes = useStyles();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const steps = getSteps();

//   // const handleNext = () => {
//   //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   // };
//   //
//   // const handleBack = () => {
//   //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   // };
//   //
//   // const handleReset = () => {
//   //   setActiveStep(0);
//   // };

//     return (
//       <div className={classes.root}>
  
//         <Stepper alternativeLabel orientation="vertical" activeStep={activeStep} connector={<ColorlibConnector />} className={classes.stepper}>
//           {steps.map((label) => (
//             <Step key={label}>
//               <StepLabel StepIconComponent={ColorlibStepIcon} style={{fontSize:'1rem!important'}}>{label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>
  
//       </div>
//     );

//}
