/*jshint esversion:9*/

import React, { useEffect, useState } from 'react';
import './sidebar-style.scss';
import { withRouter } from 'react-router-dom';
import { Paper, IconButton, Badge, withStyles, Tooltip } from '@material-ui/core';
import SubscribeToRooms from '../../subscribeToRooms';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import shoutout from '../../Assets/campaign.png';
import trophy from '../../Assets/trophy.png';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserData } from '../../redux/user/user-selector';
import { setSubscribedRooms } from '../../redux/notifications/notification-actions';
import { selectNewMessages, selectSubscribedRooms } from '../../redux/notifications/notification-selector';
import ArticleBtn from './ArticleBtn';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);



function Sidebar({ handleClick, location, history, newNotificationsCount, userData, newMessages, setSubscribedRooms, subscribedRooms }) {

  const [collaborations, setCollaborations] = useState([]);
  const [unreads, setUnreads] = useState(0);

  const token = localStorage.getItem('bhyve-token');

  // useEffect(() => {
  //   if(window.location.href.split('/')[4] === 'Chat') {
  //     console.log('entered side bar');
  //     setUnreads([]);
  //   }
  // },[location]);

  useEffect(() => {
  }, [unreads]);


  useEffect(() => {
    if (window.location.href.split('/')[4] !== 'Chat') {
      if (subscribedRooms.length > 0) {
        let unreadRooms = subscribedRooms.filter(item => item.unread !== 0);
        let counter = 0;
        if (unreadRooms.length > 0) {
          unreadRooms.forEach(room => {
            counter += room.unread
          })
        }
        setUnreads(counter);
      }
    } else {
      setUnreads(0);
    }
  }, [subscribedRooms, userData, location])


  useEffect(() => {
    async function settingCollabs() {
      let getCollabResult = await axios.post(`${process.env.REACT_APP_API_URL}collaboration/participants`, { participants: [userData.userInformation.publicId] }, {
        headers: {
          'x-api-key': '1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
          'Authorization': `Bearer ${token}`
        }
      });
      getCollabResult.data.forEach(collab => {
        if (collab.status === 1) {
          console.log('i am hit');
          setSubscribedRooms({
            id: collab.collaborationPublicId,
            status: false,
            unread: 0
          });
        }
      })
    }
    if (userData) {
      settingCollabs();
    }

  }, [userData])

  const getArticleIcon = (history, location) => {
    const handleArticleBtnClick = () => history.push('/User/cms');;
    return <ArticleBtn handleClick={handleArticleBtnClick} />
  }

  return (
    <Paper className='sidebar-container'>
      <SubscribeToRooms />
      {getArticleIcon(history, location)}
      <Tooltip title="Buzzbox" placement="left">
        <div className='sidebar-icon-container sidebar-buzzBox'>
          <IconButton className='sidebar-icon' onClick={() => handleClick('buzzBox')}>
            <RecordVoiceOverIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Leaderboard" placement="left">
        <div className='sidebar-icon-container'>
          <IconButton className='sidebar-icon' onClick={() => handleClick('leaderboard')}>
            <img src={trophy} alt='shoutout' width='25' height='25' />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Chatbox" placement="left">
        <div className='sidebar-icon-container'>
          <IconButton style={{ color: 'black', position: 'relative' }} className='sidebar-icon' onClick={() => history.push('/User/Chat/00000000-0000-0000-0000-000000000000')}>
            <StyledBadge badgeContent={unreads > 0 ? unreads : null} color="primary" overlap='rectangle'>
              <ChatOutlinedIcon style={{ color: 'white' }} />
            </StyledBadge>
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Notifications" placement="left">
        <div className='sidebar-icon-container'>
          <IconButton style={{ color: 'black' }} className='sidebar-icon' onClick={() => handleClick('notifications')}>
            <StyledBadge badgeContent={newNotificationsCount && newNotificationsCount} color="primary" overlap='rectangle'>
              <NotificationsNoneOutlinedIcon style={{ color: 'white' }} />
            </StyledBadge>
          </IconButton>
        </div>
      </Tooltip>
    </Paper>
  )
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  newMessages: selectNewMessages,
  subscribedRooms: selectSubscribedRooms
});

const mapDispatchToProps = dispatch => ({
  setSubscribedRooms: room => dispatch(setSubscribedRooms(room))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
