import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import rankOneImage from '../../../Assets/rankOne.png';
import styles from './style.module.scss';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline:'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RankOneModal({open, handleClose}) {
  const classes = useStyles();


  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={styles.rank}>
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <IconButton onClick={() => handleClose()}>
                      <Close/>
                  </IconButton>
              </div>
              <img src={rankOneImage} alt='rank-one-image'/>
              <h3>Woohoo!!</h3>
              <h2>Congratulations for achieving</h2>
              <h1>Rank 1</h1>
            </div>
          </div>
        </Fade>
      </Modal>
  );
}
