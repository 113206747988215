/*jshint esversion:9*/

import React from 'react';
import {Typography,IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './default-points-style.scss';



function DefaultPoints({points,handleEditModal,handleDelete}){


  return(
    <div className='default-points-container'>
      <div className='form'>
      {points&&points.map((item,index)=>
            <div className='form-input' key={index}>
            <Typography variant='h6'>{item.action}
            <IconButton onClick={()=>handleEditModal(item)}>
              <EditIcon/>
            </IconButton>
            <IconButton onClick={()=>handleDelete(item.actionPublicId)}>
              <DeleteIcon/>
            </IconButton>
            </Typography>
            <Typography variant='subtitle1'>{item.points} points</Typography>
          </div>)}
      </div>
      <br/>
    </div>
  )
}



export default DefaultPoints;
