/*jshint esversion:9*/
import {pointsActionTypes} from './points-types';
import {editActionPoint} from './points-utils';

const INITIAL_STATE = {
  defaultPoints:null
};

const pointsReducer = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case pointsActionTypes.SET_DEFAULT_ACTION_POINTS:
    return{
      ...state,
      defaultPoints:action.payload
    };

    case pointsActionTypes.ADD_DEFAULT_ACTION_POINTS:
    return{
      ...state,
      defaultPoints:[...state.defaultPoints,action.payload]
    };

    case pointsActionTypes.EDIT_DEFAULT_ACTION_POINTS:
    return{
      ...state,
      defaultPoints:editActionPoint(state.defaultPoints,action.payload)
    };

    case pointsActionTypes.DELETE_DEFAULT_ACTION_POINT:
    return{
      ...state,
      defaultPoints:state.defaultPoints.filter(item => item.actionPublicId !== action.payload)
    };

    default:
    return state;
  }
};

export default pointsReducer;
