import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import cmsAxios from "../../axios/authAxios";
import ArticleListItem from "../articleListItem";
import fetchPublishedArticle from "../../redux/article/actions/fetch_published_article";
import Loading from "../loading";
import style from "./style.module.scss";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import getSynopsis from "../getSynopsis";
import { fetchPublishedArticleByAuthor } from "../../redux/article/article-actions";

const PublishedArticleByAuthor = ({
  userId,
  articlesByAuthor,
  fetchPublishedArticleByAuthor,
  user,
}) => {
  const history = useHistory();
  const handleBookmark = (e, id, setBookmark, current) => {
    let articles = articlesByAuthor;
    let article = articles.find((article) => article.contentPublicId === id);
    if (!article) return;
    if (current) {
      cmsAxios
        .delete(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleLike = (e, id, setLike, current) => {
    let element = e.currentTarget;
    let articles = articlesByAuthor;
    const article = articles.find((article) => article.contentPublicId === id);
    if (!article) return console.log("no article found with id" + id);
    if (current) {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/unlike`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/like`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            console.log(
              (element.querySelector("small").innerHTML =
                parseInt(element.querySelector("small").innerHTML) + 1)
            );
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleDelete = (id) => {
    cmsAxios.delete(`/cms/article/:${id}`).then((response) => {
      fetchPublishedArticle();
    });
  };

  const handleArticleItemClick = (e, id) => {
    history.push(`/User/cms/${id}/singleArticle`);
  };

  const handlePublishEditClick = (id) => {
    cmsAxios.put(`/cms/article/${id}/unpublish`).then((response) => {
      if (response.data && response.data === "Article moved to draft") {
        history.push(`/User/cms/${id}/edit`);
      }
    });
  };

  useEffect(() => {
    fetchPublishedArticleByAuthor(userId);
  }, [userId]);

  const getAllArticles = (articles) => {
    return articles.map((article) => {
      return (
        <ArticleListItem
          key={article.contentPublicId}
          articleId={article.contentPublicId}
          publishedOn={article.updatedAt}
          onClick={handleArticleItemClick}
          author={article.user}
          authorDesignation={article.user ? article.user.department : null}
          articleTitle={article.title}
          articleContent={getSynopsis(article.content)}
          articleCover={article.coverImageUrl}
          articleThumbnail={article.thumbnailImageUrl}
          likesNo={article.likesCount}
          commentNo={article.commentsCount}
          viewsNo={article.viewsCount}
          author_profile={article.user && article.user.profilePhotoUrl}
          read={article.content}
          handleLike={handleLike}
          like={article.likedByMe}
          bookmark={article.bookmarkedByMe}
          handleBookmark={handleBookmark}
          edit={true}
          remove={true}
          share={true}
          handleDelete={handleDelete}
          handleEdit={handlePublishEditClick}
          isEdited={article.isEdited}
          moreOptionHidden={true}
        />
      );
    });
  };

  return (
    <Paper className="py-md my-lg" style={{ backgroundColor: "white" }}>
      <Typography
        variant="h6"
        style={{ fontWeight: 700 }}
        className={`mx-md mb-sm my-0`}
      >
        Published Articles
      </Typography>
      <hr className={`my-0`} />
      <div className={`${style.articlesContainer}`}>
        {articlesByAuthor.length > 0 ? (
          getAllArticles(articlesByAuthor)
        ) : (
          <p style={{ textAlign: "center", fontSize: "18px" }}>
            No published articles
          </p>
        )}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  articlesByAuthor: state.articles.articlesByAuthor,
  user: state.user,
});
const mapDispatchToProps = {
  fetchPublishedArticleByAuthor,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishedArticleByAuthor);
