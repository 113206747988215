/*jshint esversion:9*/

import React,{useState} from 'react';
import {TextField,IconButton,Button,Typography} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import convert from '../../Assets/convert.png';

function AddCategories({handleUpload,uploaded}){

  const [file,setFile] = useState(null);
  const [fileName,setFileName] = useState('');

  const handleChange = e => {
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  return(
    <div style={{width:'100%',textAlign:'center'}}>
    <Typography variant='h5' style={{fontWeight:'bold'}}>Upload a .csv file</Typography>
    <br/>
      <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
        <div style={{width:'20%'}}>
          <TextField
          className='input'
          id="input-with-icon-textfield"
          label='Categories File'
          type='text'
          value={file&&file.name}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}

          />
            <input onChange={handleChange} key={fileName} type='file' id='logo-upload' style={{display:'none'}}/>
          </div>
          <div >
            <label htmlFor='logo-upload'>
              <IconButton color="primary" aria-label="upload picture" component="span" button onClick={()=>handleUpload()}>
                <PublishIcon/>
              </IconButton>
            </label>
          </div>
      </div>
      <br/>
      <Button variant='outlined'  startIcon={<img src={convert} alt='convert' width='25'/>} color='primary' style={{marginRight:'5px'}} onClick={()=>{setFileName('');handleUpload(file);}}>Upload & Convert</Button>
    </div>
  );
}

export default AddCategories;
