/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import AddBadges from "../../../Components/add-badges/add-badges-component";
import BadgesList from "../../../Components/badges-list/badges-list-component";
import Snackbar from "../../../Components/SubComponents/snackbar/snackbar";
import UpdateBadgeModal from "../../../Components/SubComponents/edit-badge-modal/edit-badge-modal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  setBadges,
  addBadge,
  updateBadge,
  deleteBadge,
} from "../../../redux/badges/badges-actions";
import { selectBadges } from "../../../redux/badges/badges-selector";
import Logout from "../../../logout";
import axios from "axios";
import "./badges-style.scss";
import { selectUserData } from "../../../redux/user/user-selector";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function BadgesPage({
  badges,
  setBadges,
  addABadge,
  updateBadge,
  deleteABadge,
  userData,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const getBadges = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/badges/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    };

    async function gettingBadges() {
      try {
        let getBadgesResult = await getBadges();
        if (getBadgesResult) {
          setBadges(getBadgesResult.data);
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response.message);
          if (error.response.status === 401) {
            Logout();
          }
        }
      }
    }
    if (!badges) {
      gettingBadges();
    }
  }
  }, [badges, token, setBadges]);

  const handleAdd = async (info) => {
    try {
      setLoading(true);
      let formData = new FormData();
      Object.keys(info).map((item) =>
        formData.append(item.toString(), info[item])
      );
      let addBadgeResult = await addBadge(formData);
      if (addBadgeResult) {
        setLoading(false);
        addABadge(addBadgeResult.data);
        setAlert("Badge added successfully!");
        setSeverity("success");
        setAlertOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setAlert(err.message);
      setSeverity("error");
      setAlertOpen(true);
      if (err.response) {
        console.log(err.response.message);
        if (err.response.status === 401) {
          Logout();
        }
      }
    }
  };

  const addBadge = async (req) => {
    const result = await axios.post(
      `${process.env.REACT_APP_OWNER_URL}ownerPanel/badges`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleUpdate = async (info) => {
    try {
      setLoading(true);
      if (info.badgeName !== "" && info.image !== null) {
        //var success=null;
        if (info.oldBadgeName !== info.badgeName) {
          try {
            let updateBadgeNameResult = await updateBadgeName(info.badgeName);
            if (updateBadgeNameResult) {
              const obj = {
                name: updateBadgeNameResult.data.badgeName,
                image: null,
                id: updateBadgeNameResult.data.publicId,
              };
              updateBadge(obj);
              setAlert("badge name updated succesfully!");
              setSeverity("success");
              setAlertOpen(true);
              setLoading(false);
              setUpdateModal(false);
            }
          } catch (err) {
            setLoading(false);
            setAlert(err.message);
            setSeverity("error");
            setAlertOpen(true);
            setUpdateModal(false);
            if (err.response) {
              console.log(err.response.message);
              if (err.response.status === 401) {
                Logout();
              }
            }
            return;
          }
        } else {
          if (info.oldImage !== info.image.name) {
            let req = new FormData();
            req.append("image", info.image);
            try {
              let updateBadgeImageResult = await updateBadgeImage(req);
              if (updateBadgeImageResult) {
                setLoading(false);
                const obj = {
                  name: null,
                  image: updateBadgeImageResult.data.badgeImageUrl,
                  id: updateBadgeImageResult.data.publicId,
                };
                updateBadge(obj);
                setAlert("badge image updated succesfully!");
                setSeverity("success");
                setAlertOpen(true);
                setUpdateModal(false);
                return;
              }
            } catch (err) {
              setAlert(err);
              setSeverity("error");
              setAlertOpen(true);
              setUpdateModal(false);
              if (err.response) {
                console.log(err.response.message);
                if (err.response.status === 401) {
                  Logout();
                }
              }
              return;
            }
          } else {
            setLoading(false);
            setAlert("No new info was updated!");
            setSeverity("info");
            setAlertOpen(true);
            setUpdateModal(false);
            return;
          }
        }
        if (info.oldImage !== info.image.name) {
          let req = new FormData();
          req.append("image", info.image);
          try {
            let updateBadgeImageResult = await updateBadgeImage(req);
            if (updateBadgeImageResult) {
              setLoading(false);
              const obj = {
                name: null,
                image: updateBadgeImageResult.data.badgeImageUrl,
                id: updateBadgeImageResult.data.publicId,
              };
              updateBadge(obj);
              setAlert("badge image updated succesfully!");
              setSeverity("success");
              setAlertOpen(true);
              setUpdateModal(false);
              return;
            }
          } catch (err) {
            setAlert(err.message);
            setSeverity("error");
            setAlertOpen(true);
            setUpdateModal(false);
            if (err.response) {
              console.log(err.response.message);
              if (err.response.status === 401) {
                Logout();
              }
            }
            return;
          }
        }
      } else {
        setAlert("Please fill all the fields!");
        setSeverity("warning");
        setAlertOpen(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateBadgeName = async (name) => {
    const req = {
      badgeName: name,
    };
    const result = await axios.patch(
      `${process.env.REACT_APP_OWNER_URL}ownerPanel/badges/${selectedBadge.publicId}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const updateBadgeImage = async (image) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_OWNER_URL}ownerPanel/badges/${selectedBadge.publicId}/updateImage`,
      image,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const handleDelete = async (badge) => {
    setLoading(true);
    try {
      let deleteBadgeResult = await deleteBadge(badge.publicId);
      if (deleteBadgeResult) {
        deleteABadge(badge.publicId);
        setLoading(false);
        setAlert("Badge deleted successfully!");
        setSeverity("info");
        setAlertOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setAlert(err.message);
      setSeverity("error");
      setAlertOpen(true);
      if (err.response) {
        console.log(err.response.message);
        if (err.response.status === 401) {
          Logout();
        }
      }
    }
  };

  const deleteBadge = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_OWNER_URL}ownerPanel/badges/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  return (
    <div className="client-container">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar
        Open={alertOpen}
        alert={alert}
        severity={severity}
        handleClose={handleAlertClose}
      />
      <div className="greeting">
        <Typography
          variant="h3"
          style={{ fontWeight: "bold", marginBottom: "80px" }}
        >
          Badges
        </Typography>
      </div>
      <AddBadges handleAdd={handleAdd} />
      <BadgesList
        handleUpdateModal={(badge) => {
          setUpdateModal(true);
          setSelectedBadge(badge);
        }}
        handleDelete={handleDelete}
      />
      <UpdateBadgeModal
        open={updateModal}
        details={selectedBadge}
        handleUpdate={handleUpdate}
        handleClose={() => {
          setUpdateModal(false);
        }}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  badges: selectBadges,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setBadges: (array) => dispatch(setBadges(array)),
  addABadge: (object) => dispatch(addBadge(object)),
  updateBadge: (object) => dispatch(updateBadge(object)),
  deleteABadge: (id) => dispatch(deleteBadge(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BadgesPage);
