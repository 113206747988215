import {createSelector} from 'reselect';

const notifications = state => state.notifications;

export const selectNewNotifications = createSelector(
  [notifications],
  notifications => notifications.newNotifications
);

export const selectNewMessages = createSelector(
  [notifications],
  notifications => notifications.newMessages
);

export const selectSubscribedRooms = createSelector(
  [notifications],
  notifications => notifications.subscribedRooms
);