/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import RecommendedCollaboratorCard from "../SubComponents/recomm-collaborator-card/recomm-collaborator-card";
import CreateCollabModal from "../SubComponents/create-collab-modal/create-collab-modal";
import PendingCollabModal from "../SubComponents/pending-collab-modal/pending-collab-modal";
import {
  Paper,
  Typography,
  IconButton,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectCompanyDepartments,
} from "../../redux/user/user-selector";
import { withRouter } from "react-router-dom";
import Logout from "../../logout";
import axios from "axios";
import Tour from "../../tour";
import "./collaborators-style.scss";

const CollaboratorCardMemo = React.memo(RecommendedCollaboratorCard);

function CollabComponent({
  title,
  subtitle,
  rows,
  userData,
  Collaborators,
  history,
  type,
  companyDepartments,
}) {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);
  const [showTour, setShowTour] = useState(false);
  const [data, setData] = useState(null);
  const [collab, setCollab] = useState(false);
  const [collabUserId, setCollabUserId] = useState(null);
  const [pendingRequestDetails, setPendingRequestDetails] = useState({
    user: {},
    role: 0,
    details: {},
  });
  const [pendingModal, setPendingModal] = useState(false);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    setEndIndex(rows);
    function sortUsers(users) {
      users.sort(function (a, b) {
        return a.points - b.points;
      });
      users.reverse();
      return users;
    }
    setShowTour(true);
    switch (type) {
      case "recommended":
        setData(Collaborators);
        break;
      case "popular":
        let popularUsers = sortUsers([...Collaborators]);
        setData(popularUsers);
        break;
      case "closest":
        let closestUsers = [];
        let otherUsers = [];
        Collaborators.forEach((user) => {
          if (user.userLocation === userData.userInformation.userLocation) {
            closestUsers.push(user);
          } else {
            otherUsers.push(user);
          }
        });
        setData([...closestUsers, ...otherUsers]);
        break;
      default:
        break;
    }
  }, [rows, userData, Collaborators, type]);

  const handleNext = () => {
    const length = data.length;
    let rem = length - endIndex;
    if (rem > 0) {
      if (rem > rows || rem === rows) {
        setStartIndex((prevValue) => prevValue + rows);
        setEndIndex((prevValue) => prevValue + rows);
      } else {
        let overhead = rows - rem;
        let start = endIndex - overhead;
        setStartIndex(start);
        setEndIndex(data.length);
      }
    }
  };

  const handleBack = () => {
    const length = data.length;
    if (startIndex > rows || startIndex === rows) {
      setStartIndex((prevValue) => prevValue - rows);
      setEndIndex((prevValue) => prevValue - rows);
    } else {
      let overhead = rows - startIndex;
      let end = startIndex + overhead;
      setStartIndex(0);
      setEndIndex(end);
    }
  };

  const navigateToUser = (id) => {
    history.push(`/User/Profile/${id}`);
  };

  const collaborate = async (name, id) => {
    try {
      const fetchCollabInfoResult = await axios.post(
        `${process.env.REACT_APP_API_URL}collaboration/participants`,
        { participants: [id, userData.userInformation.publicId] },
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        fetchCollabInfoResult.data === "" ||
        fetchCollabInfoResult.data.length === 0
      ) {
        setCollab(true);
        setCollabUserId(id);
      } else {
        console.log(fetchCollabInfoResult.data[0]);
        switch (fetchCollabInfoResult.data[0].status) {
          case 1:
            let user = fetchCollabInfoResult.data[0].participants.filter(
              (item) => item !== userData.userInformation.publicId
            );
            history.push(`/User/Chat/${user[0]}`);
            break;

          case 0:
            if (
              fetchCollabInfoResult.data[0].createdBy ===
              userData.userInformation.publicId
            ) {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 0,
              });
              setPendingModal(true);
            } else {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 1,
              });
              setPendingModal(true);
            }
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const updateCollabRequest = async (req) => {
    let updateCollabResult = await axios.patch(
      `${process.env.REACT_APP_API_URL}collaboration`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return updateCollabResult;
  };

  const handelAccept = async (data) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 1,
        rejectReason: "",
      };
      let acceptRequestResult = await updateCollabRequest(reqObject);
      if (acceptRequestResult) {
        console.log(acceptRequestResult);
        let user = data.participants.filter(
          (item) => item !== userData.userInformation.publicId
        );
        history.push(`Chat/${user}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDecline = async (data, reason) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 2,
        rejectReason: reason,
      };
      let declineRequestResult = await updateCollabRequest(reqObject);
      if (declineRequestResult) {
        console.log(declineRequestResult);
        let user = data.participants.filter(
          (item) => item !== userData.userInformation.publicId
        );
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  return (
    data &&
    data.length > 0 && (
      <Paper className="post-container">
        {/* {showTour && <Tour type='dashboard'/>} */}
        <div className="container-title">
          <Typography variant="h6" className="collab-section-title">
            {title}
          </Typography>
          <p style={{ width: "100%", fontSize: "1.1rem" }}>{subtitle}</p>
        </div>
        {data && data.length > 0 && (
          <div className="post-cards-container">
            <Fade in={true} style={{ transitionDelay: "200ms" }}>
              <div
                className="cards-view"
                style={{
                  gridTemplateColumns:
                    rows === 3
                      ? "calc(100%/3) calc(100%/3) calc(100%/3)"
                      : rows === 2
                      ? "auto auto"
                      : "auto",
                }}
              >
                {data.slice(startIndex, endIndex).map((item, index) => (
                  <CollaboratorCardMemo
                    key={index}
                    index={index}
                    img={item.profilePhotoUrl}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    skillMatched={item.skillMatched}
                    navigateToUser={() => navigateToUser(item.id)}
                    userTitle={item.department}
                    collaborate={() => collaborate(item.firstName, item.id)}
                    locationDetails={item.locationDetails}
                    companyDepartments={companyDepartments}
                  />
                ))}
              </div>
            </Fade>
          </div>
        )}
        {data && (
          <div className="post-scroller">
            {rows < data.length && (
              <IconButton
                className="scroll-btn"
                onClick={handleBack}
                disabled={startIndex === 0}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {rows < data.length && (
              <IconButton
                className="scroll-btn"
                onClick={handleNext}
                disabled={endIndex === data.length}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </div>
        )}
        {!data && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {data && data.length === 0 && (
          <div
            style={{ width: "100%", textAlign: "center", minHeight: "100px" }}
          >
            <Typography variant="subtitle1">
              No collaborators to recommend for now.
            </Typography>
          </div>
        )}
        <CreateCollabModal
          open={collab}
          handleClose={() => {setCollabUserId(null); setCollab(false)}}
          collabUserId={collabUserId}
          userId={userData && userData.userInformation.publicId}
          companyDepartments={companyDepartments}
        />
        <PendingCollabModal
          open={pendingModal}
          handleClose={() => setPendingModal(false)}
          pendingRequestDetails={pendingRequestDetails}
          handelAccept={handelAccept}
          handleDecline={handleDecline}
        />
      </Paper>
    )
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  companyDepartments: selectCompanyDepartments,
});

export default withRouter(connect(mapStateToProps)(CollabComponent));
