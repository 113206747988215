/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography,Button,CircularProgress} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InfoEdit from '../SubComponents/info-edit/info-edit';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../redux/user/user-selector';
import {setUserData} from '../../redux/user/user-actions';
import Snackbar from '../SubComponents/snackbar/snackbar';
import axios from 'axios';



function SocialEdit({userData,setUserData}){
  const [details,setDetails] = useState({
    facebook:null,
    twitter:null,
    linkedIn:null
  });
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);

  useEffect(()=>{
    if(userData){
      setDetails({
        facebook:userData.userInformation.socialMediaUrls.facebook,
        twitter:userData.userInformation.socialMediaUrls.twitter,
        linkedIn:userData.userInformation.socialMediaUrls.linkedIn
      });
    }
  },[userData]);

  const handleChange= e => {
    const {value,name} = e.target;
    setDetails(prevValue => {
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  const handleSave = () => {
      setLoading(true);
      var data = {
        socialMediaUrls:details
      };
      console.log(data);
      updateUserProfile(data)
      .then(res => {
        console.log(res);
        var current = {
          ...userData,
          userInformation:res.data.user
        };
        setUserData(current);
              setLoading(false);
              setMessage('Social handles updated successfully!');
              setSeverity('success');
              setAlertOpen(true);
      })
      .catch(err=>{
        console.log(err);
        setMessage('error');
        setSeverity('error');
        setAlertOpen(true);
        setLoading(false);
      });
  };

  const updateUserProfile = async data => {
      const token = localStorage.getItem('bhyve-token');
    const result = await axios.patch(`${process.env.REACT_APP_API_URL}user/me`,data,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  };

  return(
    <Paper className='skillCard-container' style={{marginTop:'30px'}}>
      <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
      <Typography variant='h6' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'2'}}><span style={{padding:'8px 0 8px 0'}} className='skill-card-title'>SOCIAL MEDIA</span></Typography>
      <br/>
      <InfoEdit label='Facebook' disabled={true} name='facebook' value={details.facebook} onChange={handleChange}  placeholder='Facebook profile' type='text' icon={<FacebookIcon/>}/>
      <InfoEdit label='Twitter' disabled={true} name='twitter' value={details.twitter} onChange={handleChange} placeholder='Twitter profile' type='text' icon={<TwitterIcon/>}/>
      <InfoEdit label='LinkedIn' disabled={true} name='linkedIn' value={details.linkedIn} onChange={handleChange} placeholder='LinkedIn profile' type='text' icon={<LinkedInIcon/>}/>
      <br/>
      <br/>
      {loading&&<CircularProgress disableShrink />}
      {!loading&&<Button className='edit-btn' onClick={handleSave} disabled={true}>Save Changes</Button>}
    </Paper>
  )
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData
});

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object))
});

export default connect(mapStateToProps,mapDispatchToProps)(SocialEdit);
