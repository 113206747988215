/*jshint esversion:9*/
import { createSelector } from "reselect";

const user = (state) => state.user;

export const selectUserData = createSelector([user], (user) => user.UserData);

export const selectCompanyInformation = createSelector(
  [user],
  (user) => user.companyInformation
);

export const selectUserPoints = createSelector([user], (user) => user.points);

export const selectLocationData = createSelector(
  [user],
  (user) => user.locationData
);

export const selectBackgroundColor = createSelector(
  [user],
  (user) => user.backgroundColor
);

export const selectCompanyDepartments = createSelector(
  [user],
  (user) => user.companyDepartments
);

export const selectSettings = createSelector([user], (user) => user.settings);
