/*jshint esversion:9*/
import {badgesActionTypes} from './badges-types';

export const setBadges = array => ({
  type: badgesActionTypes.SET_BADGES,
  payload:array
});

export const addBadge = object => ({
  type: badgesActionTypes.ADD_BADGE,
  payload:object
});

export const updateBadge = object => ({
  type:badgesActionTypes.UPDATE_BADGE,
  payload:object
});

export const deleteBadge = id => ({
  type:badgesActionTypes.DELETE_BADGE,
  payload:id
});
