import React,{useEffect,useState} from 'react';
import MasterClassModal from '../SubComponents/masterClass-register-modal/index';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {selectCompanyInformation} from '../../redux/user/user-selector';
import {createStructuredSelector} from 'reselect';
import TestVideo from '../../Assets/testVideo.mp4';
import './style.scss';



const DashboardCarousel = ({history, companyInfo}) => {


    const [masterClass,setMasterClass] = useState(false);
    const [skill,setSkill] = useState('');
    const [active,setActive] = useState(1);

    const indicatorStyles = {
        background: 'rgb(216, 216, 216)',
        width: 10,
        height: 10,
        display: 'inline-block',
        borderRadius: '50%',
        border: '1px',
        margin: '0 8px',
        boxShadow: '1px 1px 4px rgb(0 0 0 / 40%)',
        cursor: 'pointer'
    };

    // useEffect(() => {
    //   if(companyInfo) {

    //     let carousalInterval = setInterval(() => {
    //       let carousel_slide = document.querySelector(".slide");
    //       if(active < companyInfo.carouselDetails.length) {
    //         carousel_slide.style.transition = "1s";
    //         document.getElementById(`radio${active + 1}`).checked = true;
    //         let currentValue = active + 1;
    //         setActive(currentValue);
    //       }else{
    //         setActive(1);
    //         document.getElementById(`radio1`).checked = true;
    //         // setTimeout(() => {
    //         //   let size = carousel_slide.clientWidth * companyInfo.carouselDetails.length;
    //         //   carousel_slide.style.transition = "none";
    //         //   carousel_slide.style.transform = `translateX(${-size}px)`;
    //         // }, 300);
    //       }
    //     },5000);
    //     return () => clearInterval(carousalInterval);
    //   }
    // },[companyInfo,active])



    return(
      <div>
        <Carousel autoPlay={true} interval={5000} transitionTime={1000} showThumbs={false} infiniteLoop={true} showStatus={false} renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
                return (
                    <li
                        style={{ ...indicatorStyles, background: '#133DA0' }}
                        //aria-label={`Selected: ${label} ${index + 1}`}
                        //title={`Selected: ${label} ${index + 1}`}
                    />
                );
            }
            return (
                <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    //title={`${label} ${index + 1}`}
                    //aria-label={`${label} ${index + 1}`}
                />
            );
        }}
        >
          {companyInfo && companyInfo.carouselDetails.map((item, index) => 
            <div key={item.id} className='slide-entry'  onClick={() => {
              if(item.type === 'USER_PROFILE') {
                history.push(`/User/Profile/${item.typeId}`);
              }
              if(item.type === 'SKILL') {
                history.push(`/User/Search?q=${item.typeId}`);
              }
              if(item.type === "MASTERCLASS" ) {
                setSkill(item.typeId);
                setMasterClass(true);
              }
            }}>
                <img  src={item.imageUrl} height='289'/>
                {/* <video height="289" controls autoplay='autoplay'>
                  <source src={TestVideo} type='video/mp4'/>
                </video> */}
            </div>
            )}
        </Carousel>
        <MasterClassModal Open={masterClass} skill={skill} handleClose={() => setMasterClass(false)}/>
      </div>

    )
}

const mapStateToProps = createStructuredSelector({
    companyInfo: selectCompanyInformation
});

export default withRouter(connect(mapStateToProps)(DashboardCarousel));

{/* <div className='carousel-slider-container'>
<div className='carousel-slider'>
  {companyInfo && companyInfo.carouselDetails.map((item, index) => 
  <input type="radio" name="radio-btn" id={`radio${index+1}`} />
  )
}
    {companyInfo && companyInfo.carouselDetails.map((item, index) => 
      <div key={item.id} style={{border: companyInfo.companyBackgroundColor && "1px solid #B4B4B4", cursor:'pointer'}} className={`slide ${index === 0 && 'first'}`} onClick={() => {
        if(item.type === 'USER_PROFILE') {
          history.push(`/User/Profile/${item.typeId}`);
        }
        if(item.type === 'SKILL') {
          history.push(`/User/Search/${item.typeId}`);
        }
        if(item.type === "MASTERCLASS" ) {
          setSkill(item.typeId);
          setMasterClass(true);
        }
      }}>
          <img  src={item.imageUrl}/>
      </div>
      )}
</div>
<div className="navigation-manual">
  {companyInfo && companyInfo.carouselDetails.map((item, index) => 
    <label htmlFor={`radio${index+1}`} className={`manual-btn ${active === index + 1 && 'active'}`} onClick={() => setActive(index + 1)}></label>
    )
  }
</div>
<MasterClassModal Open={masterClass} skill={skill} handleClose={() => setMasterClass(false)}/>
</div> */}