import React,{useState} from 'react';
import {makeStyles,Modal,Fade,Backdrop,Button,IconButton,TextField} from '@material-ui/core';
import HighFive from '../../../Assets/high-five.png';
import Clock from '../../../Assets/clock.png';
import CloseIcon from '@material-ui/icons/Close';
import './pending-collab-modal-style.scss';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width:'600px', 
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
    },
  }));

export default function PendingCollabModal({open,handleClose,pendingRequestDetails,handelAccept,handleDecline}) {

    const [decline,setDecline] = useState(false);
    const [reason,setReason] = useState('');

    const classes = useStyles();

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className='pending-collab-modal-top'>
                <IconButton onClick={() => handleClose()}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className='pending-collab-modal-message'>
                {pendingRequestDetails && pendingRequestDetails.role === 0 ? 
                    <img src={Clock} width='100' height='100'/>
                    :
                    <img src={HighFive} width='100' height='100'/>
                }
                <br/>
                <br/>
                {pendingRequestDetails && pendingRequestDetails.role === 0 ? 
                    <h3>Looks like <span style={{fontSize:'1.2rem',color: '#EE813A',fontWeight:'bold'}}>{pendingRequestDetails.user.name}</span> already has a request pending from you! Wait for them to accept it, and then you can add more skills to your chat.</h3>
                    :
                    <h3>Looks like <span style={{color: '#EE813A',fontWeight:'bold'}}>{pendingRequestDetails.user.name}</span> also wants to collaborate with you, but for different skills. You can accept or decline the same, and then add new skills to the chat</h3>
                }
                {pendingRequestDetails && pendingRequestDetails.role === 1 &&
                    <div className='pending-collab-modal-btn-group'>
                        <Button disabled={decline} className='pending-collab-modal-btn' onClick={() => handelAccept(pendingRequestDetails.details)}>
                            Accept
                        </Button>
                        <Button disabled={decline} className='pending-collab-modal-btn' onClick={() =>  setDecline(true)}>
                            Decline
                        </Button>
                    </div>
                }
                {
                  decline && pendingRequestDetails.role === 1 &&
                  <div style={{width: "100%", display:'flex',flexWrap:'wrap',justifyContent:'center', padding:'10px 0'}}>
                    <TextField value={reason} onChange={e => setReason(e.target.value)} style={{width: "80%"}}variant='outlined' label="Why are you declining?" multiline rows={4} />
                    <div style={{width: "100%", textAlign:'center',marginTop:'10px'}}>
                      <Button variant='contained' style={{color:'white', background: '#66CCFF'}} onClick={() => handleDecline(pendingRequestDetails.details, reason)}>
                        Send
                      </Button>
                    </div>
                  </div>
                }
            </div>
          </div>
        </Fade>
      </Modal>
    )
}