/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography,Button} from '@material-ui/core';
import Loader from '../SubComponents/loader/loader-component';
import Snackbar from '../SubComponents/snackbar/snackbar';
import BadgesDetailsModal from '../SubComponents/badgesDetails-modal/badgesDetails-modal';
import Badges from '../../Assets/BeeIcon.png';
import {connect} from 'react-redux';
import {setUserData} from '../../redux/user/user-actions';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../redux/user/user-selector';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import Logout from '../../logout';
//import './userPoints-style.scss';

function UserBadges({badges,userData,currentUser,history}){
  const [userbadges,setUserBadges] = useState(null);
  const [badgesModal,setBadgesModal] = useState(false);

  const token = localStorage.getItem('bhyve-token');

  useEffect(()=>{
    try {
      if(badges){
        console.log(badges);
        async function gettingAllBadges() {
          try {
            let badgeList = await convertingBadges(badges);
            console.log(badgeList);
            console.log('the badge list now:', badgeList);
            setUserBadges(badgeList);
          } catch (error) {
            if(error.response){
              console.log(error.response.message);
              if(error.response.status === 401) {
                history.push('/logout');
              }
            }
          }
        }

        gettingAllBadges();
      } 
    } catch (error) {
      console.log(error.message);
    }
  },[badges,currentUser]);

  const convertingBadges = async badges => {
    const getUserInfo = async users => {

      let result = await axios.post(`${process.env.REACT_APP_API_URL}user/profiles/info`,{userPublicIds:users},{
        headers:{
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
        'Authorization': `Bearer ${token}`
        }
      });
      return result;
    }

    let userIds = [];
    let userList = [];
    let badgeList = [];
    badges.forEach(badge => {
      userIds.push(badge.badgeDonorPublicId);
    });
    let usersResult = await getUserInfo(userIds);
    if(usersResult) {
      for(let i = 0; i < usersResult.data.length; i ++) {
        let id = Object.keys(usersResult.data[i])[0];
        let object = {
          id: id,
          name: id === userData.userInformation.publicId ? 'you' : `${usersResult.data[i][id].firstName} ${usersResult.data[i][id].lastName}`
        }
        userList.push(object);
      }
    }
    badges.forEach(badge => {
      let donorExist = userList.find(item => item.id === badge.badgeDonorPublicId);
      if(donorExist) {
        let badgeObject = {
          ...badge,
          name: donorExist.name
        };
        badgeList.push(badgeObject);
      }
    })
    return badgeList;
  }


  const handleBadgesModal = () => {
    try {
      setBadgesModal(true);

    } catch (error) {
      console.log(error.message);
    }
  };


  return(
    <div className='points-container'>
      <div style={{width:'100%',textAlign:'center',cursor:'pointer'}} onClick={handleBadgesModal}>
        <img alt='badge' src={Badges} className='points-img'/>
      </div>
      <br/>
      <Typography variant='h5' className='profile-content' style={{fontWeight:'bold',cursor:'pointer'}} onClick={handleBadgesModal}>{userbadges && userbadges.length}</Typography>
      <Typography variant='subtitle2' className='profile-content muted'>Badges</Typography>
      <BadgesDetailsModal open={badgesModal} badges={userbadges && userbadges} handleClose={()=>setBadgesModal(false)}/>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object))
});

const mapStateToProps = createStructuredSelector({
  userData: selectUserData
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UserBadges));
