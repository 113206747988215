import React from "react";
import style from "./userWidget.module.scss";
import Profile from "../profile";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrimaryButton from "../primaryButton";
import ArticleAction from "../articleAction";
import BookmarkBtn from "../bookmarkBtn";
import FlagReasonModal from "../flagReasonModal";
import CreateCollabModal from "../../../../../Components/SubComponents/create-collab-modal/create-collab-modal";
import PendingCollabModal from "../../../../../Components/SubComponents/pending-collab-modal/pending-collab-modal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ShareIcon from "@material-ui/icons/Share";
import FlagIcon from "@material-ui/icons/Flag";
import settingDate from "../settingDate/settingDate";
import cmsAxios from "../../axios/authAxios";
import { withRouter } from "react-router";
import getDepartmentName from "../../../../../utils/getDepartmentName";

const UserWidget = ({
  isEdited,
  userName,
  userDesignation,
  userProfile,
  userId,
  createAt,
  read_length,
  offsetX,
  offsetY,
  commentsCount,
  likes,
  likePost,
  disLikePost,
  isLiked,
  history,
  authorId,
  coAuthors,
  publishedOn,
  isBookmarked,
  bookmarkHandle,
  companyDepartments,
  ...props
}) => {
  const [coordinate, setCoordinate] = React.useState({ x: 0, y: 0 });
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [collab, setCollab] = React.useState(false);
  const [collabUserId, setCollabUserId] = React.useState(null);
  const [pendingRequestDetails, setPendingRequestDetails] = React.useState({
    user: {},
    role: 0,
    details: {},
  });
  const [bookmarkIsActive, setBookmarkIsActive] = React.useState(false);
  const [pendingModal, setPendingModal] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleClose = () => setMenuOpen(false);

  const collaborate = async (name) => {
    try {
      const fetchCollabInfoResult = await cmsAxios.post(
        `${process.env.REACT_APP_API_URL}collaboration/participants`,
        { participants: [authorId, userId] }
      );

      if (
        fetchCollabInfoResult.data === "" ||
        fetchCollabInfoResult.data.length === 0
      ) {
        setCollab(true);
        setCollabUserId(authorId);
      } else {
        console.log(fetchCollabInfoResult.data[0]);
        switch (fetchCollabInfoResult.data[0].status) {
          case 1:
            let user = fetchCollabInfoResult.data[0].participants.filter(
              (item) => item !== userId
            );
            history.push(`/User/Chat/${user[0]}`);
            break;

          case 0:
            if (fetchCollabInfoResult.data[0].createdBy === userId) {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 0,
              });
              setPendingModal(true);
            } else {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 1,
              });
              setPendingModal(true);
            }
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const updateCollabRequest = async (req) => {
    let updateCollabResult = await cmsAxios.patch(
      `${process.env.REACT_APP_API_URL}collaboration`,
      req
    );
    return updateCollabResult;
  };

  const handelAccept = async (data) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 1,
        rejectReason: "",
      };
      let acceptRequestResult = await updateCollabRequest(reqObject);
      if (acceptRequestResult) {
        let user = data.participants.filter((item) => item !== userId);
        history.push(`Chat/${user}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDecline = async (data, reason) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 2,
        rejectReason: reason,
      };
      let declineRequestResult = await updateCollabRequest(reqObject);
      if (declineRequestResult) {
        let user = data.participants.filter((item) => item !== userId);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };
  const handleBookmarkClick = () => {
    if (bookmarkHandle) bookmarkHandle();
  };
  /* Below code - Popper Menu is meant to be hidden for now */

  // const popperMenu = () => {
  //   return (
  //     <Popper
  //       open={menuOpen}
  //       anchorEl={anchorRef.current}
  //       role={undefined}
  //       transition
  //       disablePortal
  //     >
  //       {({ TransitionProps, placement }) => (
  //         <Grow
  //           {...TransitionProps}
  //           style={{
  //             transformOrigin:
  //               placement === "bottom" ? "center top" : "center bottom",
  //           }}
  //         >
  //           <Paper className={style.menuPaper}>
  //             <ClickAwayListener onClickAway={handleClose}>
  //               <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
  //                 <MenuItem onClick={handleClose} className={style.menuItem}>
  //                   <ListItemIcon>
  //                     <ShareIcon style={{ color: "black" }} />
  //                   </ListItemIcon>
  //                   <small>Share Content</small>
  //                 </MenuItem>
  //                 <MenuItem
  //                   onClick={() => setFlag(true)}
  //                   style={{ color: "red" }}
  //                   className={style.menuItem}
  //                 >
  //                   <ListItemIcon>
  //                     <FlagIcon style={{ color: "red" }} />
  //                   </ListItemIcon>
  //                   <small>Flag Content</small>
  //                 </MenuItem>
  //               </MenuList>
  //             </ClickAwayListener>
  //           </Paper>
  //         </Grow>
  //       )}
  //     </Popper>
  //   );
  // };

  const navigateToProfile = (id) => {
    history.push(`/User/Profile/${id}`);
  };
  React.useEffect(() => {
    if (isBookmarked) setBookmarkIsActive(true);
  });
  return (
    <div
      className={`${style.wrapper} px-sm py-md`}
      style={{ top: offsetY, left: offsetX - 240 }}
    >
      <div className={`${style.top_wrapper}`}>
        <Profile
          navigateToAuthorProfile={() => navigateToProfile(authorId)}
          image={userProfile}
        />
        {/* Below code - Popper Menu is meant to be hidden for now */}

        {/* <IconButton
          size="small"
          aria-label="delete"
          ref={anchorRef}
          onClick={() => setMenuOpen((prevMenuOpen) => !prevMenuOpen)}
          aria-controls={menuOpen ? "menu-list-grow" : undefined}
        >
          <MoreVertIcon />
        </IconButton> */}
      </div>
      <div className={`${style.identity_wrapper}`}>
        <p
          className={`${style.name} mt-sm my-0`}
          onClick={() => navigateToProfile(authorId)}
        >
          {userName ? userName : "no name provided"}
        </p>
        {!!userDesignation && (
          <small className={`${style.user_designation}`}>
            {getDepartmentName(userDesignation, companyDepartments)}
          </small>
        )}
        <br />
      </div>
      <p style={{ marginTop: "10px", fontSize: "15px" }}>
        {settingDate(publishedOn)} {isEdited ? "(Edited)" : null}
      </p>
      <div className={`${style.timestamp_wrapper} my-md`}>
        <div className={`${style.create_at}`}>{!!createAt ? createAt : ""}</div>
        <div className={`${style.read_length} mr-md`}>
          {!!read_length ? read_length : ""}
        </div>
      </div>
      {!!authorId && !!userId && authorId !== userId && (
        <PrimaryButton
          variant="contained"
          onClick={() => collaborate(userName && userName.split(" ")[0])}
        >
          Collaborate
        </PrimaryButton>
      )}
      <hr className="mt-lg" />
      {coAuthors.length > 0 && (
        <div className={`${style.co_author_wrapper}`}>
          <small className={`mb-0 w-700`} style={{ color: "#6b6b6b" }}>
            co-author
          </small>
          <div className={`${style.co_author_list_wrapper}`}>
            {coAuthors.map((author, index) => (
              <div
                className={`${style.co_author} my-sm`}
                onClick={() => navigateToProfile(author.publicId)}
              >
                <Profile size="xs" image={author.profilePhotoUrl} />
                <p
                  className={`ml-sm my-0 w-700`}
                >{`${author.firstName} ${author.lastName}`}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`${style.action_wrapper} mt-sm`}>
        <ArticleAction
          onClick={!isLiked ? likePost : disLikePost}
          icon="like"
          isLiked={isLiked}
          active={isLiked}
        >
          {likes}
        </ArticleAction>
        <ArticleAction icon="comment">{commentsCount}</ArticleAction>
        <BookmarkBtn active={isBookmarked} onClick={handleBookmarkClick} />
      </div>
      {/* Below code - Popper Menu is meant to be hidden for now */}
      {/* {popperMenu()} */}
      <FlagReasonModal
        open={flag}
        handleClose={() => setFlag(false)}
        flagArticle={() => setFlag(false)}
      />
      <CreateCollabModal
        open={collab}
        handleClose={() => setCollab(false)}
        collabUserId={collabUserId}
        userId={userId}
      />
      <PendingCollabModal
        open={pendingModal}
        handleClose={() => setPendingModal(false)}
        pendingRequestDetails={pendingRequestDetails}
        handelAccept={handelAccept}
        handleDecline={handleDecline}
      />
    </div>
  );
};

export default withRouter(UserWidget);
