/*jshint esversion:9*/

import React from 'react';
import {Avatar,Typography,Badge} from '@material-ui/core';
import './leaderboard-top-rankers-style.scss';

function TopRanker({firstName,lastName,points,serial,img,navigateToUser}){
  return(
    <div className='top-ranker-container'>
    <div className='top-ranker-img'>
    <Badge color="primary" overlap="circle" badgeContent={serial+1}
    anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
    >
      {img!==''&&<Avatar src={img} style={{width:'60px',height:'60px',cursor:"pointer"}} onClick={()=>navigateToUser()}/>}
      {img===''&& <Typography variant='h5' className='initial-container' onClick={()=>navigateToUser()} style={{width:'60px',height:'60px',textAlign:'center!important',display:'',paddingTop:'24%',color:'white',background:'#FFCC33',marginBottom:'15px',cursor:"pointer"}}>
          {`${firstName.slice(0,1)}${lastName.slice(0,1)}`}
        </Typography>}
    </Badge>
    </div>
      <Typography variant='subtitle1' style={{justifyContent:'center',cursor:'pointer',display:'flex',lineHeight:'1.2'}} onClick={()=>navigateToUser()}>{firstName}</Typography>
      <Typography variant='subtitle1' style={{justifyContent:'center',cursor:'pointer',display:'flex'}} onClick={()=>navigateToUser()}>{lastName.length>8?`${lastName.substring(0,1)}`:lastName}</Typography>
      <Typography variant='subtitle1' style={{justifyContent:'center',color:'#66CCFF',display:'flex'}}>{points}</Typography>
      <Typography variant='caption' style={{justifyContent:'center',display:'flex'}}>Points</Typography>
    </div>
  );
}

export default TopRanker;
