import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs'

const CustomTabs = withStyles({
    root: {
        borderBottom: 'transparent',
        marginTop: '1rem'
    },
    indicator: {
        backgroundColor: '#2092ca',
        height: 4
    },
})(Tabs);

export default CustomTabs;