import React from "react";
import style from "./profile.module.scss";
import defaultThumbnail from "../../img/default_thumbnail.png";

const getProfileUrl = (image) => (image ? image : defaultThumbnail);
const getSize = (size) => {
  switch (size) {
    case "xs":
      return { width: 20, height: 20 };
    case "sm":
      return { width: 32, height: 32 };
    case "lg":
      return { width: 65, height: 65 };
    default:
      return { width: 44, height: 44 };
  }
};

const Profile = ({ className, navigateToAuthorProfile, ...props }) => {
  let image = getProfileUrl(props.image);
  let { width, height } = getSize(props.size);
  let styles = { backgroundImage: `url(${image})`, width, height };
  return (
    <div
      {...props}
      style={{ ...styles, ...props.style }}
      className={`${style.wrapper} ${className}`}
      onClick={navigateToAuthorProfile}
    ></div>
  );
};
export default Profile;
