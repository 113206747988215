import {notificationActionTypes} from './notification-types';

export const setNewNotifications = array => ({
    type:notificationActionTypes.SET_NEW_NOTIFICATIONS,
    payload:array
});

export const updateNotifications = () => ({
    type:notificationActionTypes.UPDATE_NOTIFICATIONS
});

export const setNewMessages = messages => ({
    type: notificationActionTypes.SET_NEW_MESSAGES,
    payload: messages
});

export const setSubscribedRooms = room => ({
    type: notificationActionTypes.SET_SUBSCRIBED_ROOMS,
    payload: room
});