import cmsAxios from "../../../axios/authAxios"
{
}
export default async ({
    title, content, synopsis, coverImageKey, coverImageUrl, thumbnailImageUrl, thumbnailImageKey, selectedSkills, selectedAuthors
}) => {
    let body = {
        title,
        content: content || '',
        synopsis: synopsis || '',
        coverImageUrl,
        coverImageKey,
        thumbnailImageUrl,
        thumbnailImageKey,
        taggedSkills: selectedSkills,
        taggedCoAuthorsPublicId: selectedAuthors
    }
    // console.log(body)
    try {
        return await cmsAxios.post('cms/article', body)
    } catch (err) {
        return { error: err }
    }
}