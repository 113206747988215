import React,{useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import Loader from '../Components/SubComponents/loader/loader-component';
import axios from 'axios';
import Logout from "../logout";


function ImplicitCallback(){


    useEffect(()=>{
        try {
            const doOIDC = async token => {
                let result = await axios.get(`${process.env.REACT_APP_API_URL_AUTH}authentication/oidc`,{
                    headers:{
                        'token':'',
                        'Authorization': `Bearer ${token}`
                      }
                });
                window.location = result.data;
            }

            let token = window.location.hash.split('=')[1];
            token = token.split('&state')[0]
            doOIDC(token);
        } catch (error) {
            console.log(error);
            Logout();
        }

    },[])


    return(
        <div>
            <Loader open={true} text='Redirecting..'/>
        </div>
    )
}

export default withRouter(ImplicitCallback);

