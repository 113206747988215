/* jshint esversion: 9*/
/*jshint -W087*/
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './root-reducer';
import { persistStore } from 'redux-persist';
import KeenMiddleware from './keen-middleware';


//const middlewares = [logger];
let composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      // 2. Append to middleware composition
      applyMiddleware(thunk, createLogger(), KeenMiddleware),
    )
  );

  if (module.hot) {
    module.hot.accept('./root-reducer', () => {
      const nextRootReducer = require('./root-reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  const persistor = persistStore(store);
  return { store, persistor };
};

// const store = createStore(rootReducer, applyMiddleware(...middlewares));


export default configureStore;
  // export {persistor};
