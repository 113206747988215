/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Input from "../SubComponents/Input/Input";
import AddLocationModal from "../SubComponents/add-location-modal/add-location-modal";
import LocationsListView from "../SubComponents/locations-list-view/locations-list-view";
import "./add-client-style.scss";
import PublishIcon from "@material-ui/icons/Publish";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Snackbar from "../SubComponents/snackbar/snackbar";
import { connect } from "react-redux";
import { addCompany } from "../../redux/company/company-actions";
import axios from "axios";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/user-selector";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function AddClient({ history, AddCompany, userData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [alertOpen, setalertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({
    companyName: "",
    companyLogo: "",
    tagLine: "",
    termsConditions: "",
    pocName:"",
    pocPhone:"",
    pocEmail:""
  });
  const [addresses, setAddresses] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value
      };
    });
    console.log(details);
  };

  const extractEmail = (str) => (str.match(/^[\w\d\s@.]+/) || []).pop() || '';
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: extractEmail(value)
      };
    });
    console.log(details);
  };

  const extractCompanyName = (str) => (str.match(/^[\w\d\s-&]+/) || []).pop() || '';
  const handleChangeCompanyName = (e) => {
    const { name, value } = e.target;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: extractCompanyName(value),
      };
    });
    console.log(details);
  };

  const extractText = (str) => (str.match(/^[a-zA-Z ]*/) || []).pop() || '';
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: extractText(value.toString()),
      };
    });
  };

  const extractPhoneNumber = (str) => (str.match("^[0-9]*") || []).pop() || '';
  const handleChangePhone = (e) => {
    const { name, value } = e.target;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: extractPhoneNumber(value),
      };
    });
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    setDetails((prevValue) => {
      return {
        ...prevValue,
        companyLogo: file,
      };
    });
    console.log(details);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addLocation = (details) => {
    try {
      if (
        details.extendedStreetAddress &&
        details.streetAddress &&
        details.city &&
        details.state &&
        details.country &&
        details.zipCode
      ) {
        if (/^[A-Za-z\s]+$/.test(details.country)) {
          if (/^[A-Za-z\s]+$/.test(details.city)) {
            if (details.state.length === 2) {
              setAddresses((prevValue) => {
                return [...prevValue, details];
              });
              setOpen(false);
              console.log(addresses);
            } else {
              setAlert("Enter valid state e.g: MH,GJ");
              setSeverity("warning");
              setalertOpen(true);
            }
          } else {
            setAlert("Enter valid city !");
            setSeverity("warning");
            setalertOpen(true);
          }
        } else {
          setAlert("Enter valid country !");
          setSeverity("warning");
          setalertOpen(true);
        }
        //const location = `${details.address}, ${details.streetAddress}, ${details.city}, ${details.state}, ${details.country}-${details.zipCode}`;
      } else {
        setAlert("Please fill all the required fields!");
        setSeverity("warning");
        setalertOpen(true);
      }
      console.log(addresses);
    } catch (error) {
      console.log(error.message);
    }
  };

  const addCompanyToDatabase = async (token, data) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/company`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  }
  };

  const addCompany = async () => {
    try {
      setLoading(true);
      const tokenDetails = localStorage.getItem("bhyve-token");
      let formData = new FormData();
      formData.append("companyName", details.companyName);
      formData.append("companyLogo", details.companyLogo);
      formData.append("tagLine", details.tagLine);
      formData.append("termsConditions", details.termsConditions);

      let keys = Object.keys(addresses[0]);
      addresses.map((item, index) =>
        keys.map((key) =>
          formData.append(`addresses[${index}][${key}]`, addresses[index][key])
        )
      );
      console.log(formData);
      try {
        let result = await addCompanyToDatabase(tokenDetails, formData);
        if (result) {
          console.log(result);
          setLoading(false);
          AddCompany(result.data);
          setAlert("Company created successfully!");
          setSeverity("success");
          setalertOpen(true);
          history.push("/OwnerPanel/Client/ClientList");
        }
      } catch (err) {
        console.log(err.message);
        setLoading(false);
        setAlert("Unexpected error occured. Please try again!");
        setSeverity("error");
        setalertOpen(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="add-client-container">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar
        Open={alertOpen}
        alert={alert}
        severity={severity}
        handleClose={() => setalertOpen(false)}
      />
      <div className="greeting">
        <Typography variant="body1">Create Your Client</Typography>
        <br />
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          Create client by adding details
        </Typography>
      </div>
      <div className="form">
        <div className="form-input">
          <Input
            type="text"
            name="companyName"
            value={details.companyName}
            onChange={handleChangeCompanyName}
            label="Company Name"
          />
        </div>
        <div className="form-input">
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "100%" }}>
              <TextField
                className="input"
                id="input-with-icon-textfield"
                label="Logo"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
                value={details.companyLogo.name}
              />
              <input
                type="file"
                id="logo-upload"
                onChange={handleFile}
                style={{ display: "none" }}
              />
            </div>
            <div>
              <label htmlFor="logo-upload">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PublishIcon />
                </IconButton>
              </label>
            </div>
          </div>
        </div>
        <div className="form-input">
          <Input
            type="text"
            name="tagLine"
            onChange={handleChangeCompanyName}
            value={details.tagLine}
            label="Tag Line"
          />
        </div>
        <div className="form-input">
          <Input
            type="text"
            name="termsConditions"
            rows={3}
            value={details.termsConditions}
            onChange={handleChange}
            label="Terms and Conditions"
          />
        </div>
        <div className="form-input">
          <LocationsListView handleClick={handleOpen} locations={addresses} />
        </div>
        <div className="form-input">
          <Paper className="poc-section" elevation={0}>
            <div className="form-input">
              <Typography
                variant="h6"
                style={{ textAlign: "left", width: "50%" }}
                className="skill-name"
              >
                POC Details
              </Typography>
              <br />
              <div className="form-input">
                <Input
                  type="text"
                  name="pocName"
                  label="Name"
                  onChange={handleChangeText}
                  value={details.pocName}
                  icon={<PersonIcon />}
                />
              </div>
              <div className="form-input">
                <Input
                  type="text"
                  name="pocPhone"
                  label="Phone"
                  onChange={handleChangePhone}
                  value={details.pocPhone}
                  icon={<PhoneIcon />}
                />
              </div>
              <div className="form-input">
                <Input
                  type="text"
                  name="pocEmail"
                  label="Email"
                  onChange={handleChangeEmail}
                  value={details.pocEmail}
                  icon={<EmailIcon />}
                />
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button className="addCompany-btn" onClick={addCompany}>
          Add Company
        </Button>
      </div>
      <div style={{ marginBottom: "50px" }}>
        <Button onClick={() => history.push("/OwnerPanel/Client/ClientList")}>
          Go Back
        </Button>
      </div>
      <AddLocationModal
        Open={open}
        handleClose={handleClose}
        addLocation={addLocation}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  AddCompany: (object) => dispatch(addCompany(object)),
});

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
