/*jshint esversion:9*/

import React, { useEffect, useState } from "react";
import { Avatar, Typography, IconButton, Link } from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import "./shoutout-reply-style.scss";
import Tooltip from "@material-ui/core/Tooltip";

function ShoutoutReply({
  name,
  img,
  comment,
  time,
  commentOwned,
  handleDelete,
  shoutoutOwned,
  verified,
  handleVerification,
  handleEdit,
  handleLike,
  liked,
  navigateToAuthor,
  navigateToUser,
  users,
}) {
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    console.log("here");
    console.log(users);
  }, [users]);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    //console.log(text.split(" ").map(item => {return item.split('').find(l => l===urlRegex);}));
    let link = text.replace(urlRegex, " `$1");
    // console.log(split);
    // if(split.length > 1){
    //   console.log(split[1].split('</a'));
    // }else{
    //   console.log(split[0].split('</a'));
    // }
    return link;
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  function capitalize(input) {
    try {
      let word;
      if (input.split("").includes("_")) {
        word = input.replaceAll("_", " ").slice(1);
      } else {
        word = input.slice(1);
      }
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            //debugger;
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              " & " +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  return (
    <div className="shoutout-reply-container">
      <div className="replier-info">
        <div className="replier-img-name">
          {img !== "" && (
            <Avatar
              src={img}
              className="replier-img"
              style={{ cursor: "pointer", width: "55px", height: "55px" }}
              onClick={() => navigateToAuthor()}
            />
          )}
          {img === "" && (
            <Typography
              variant="subtitle1"
              className="initial-container"
              onClick={() => navigateToAuthor()}
              style={{
                cursor: "pointer",
                textAlign: "center!important",
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
                color: "white",
                background: "#FFCC33",
                width: "55px",
                height: "55px",
              }}
            >
              {`${name.split(" ")[0].slice(0, 1)}${name
                .split(" ")[1]
                .slice(0, 1)}`}
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold" }}
            className="replier-name"
          >
            {name}
          </Typography>
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <Tooltip title="Like">
              <IconButton button onClick={() => handleLike()}>
                <ThumbUpIcon color={liked ? "primary" : ""} />
              </IconButton>
            </Tooltip>
            {verified && !shoutoutOwned && (
              <IconButton disabled>
                <CheckCircleIcon style={{ color: "#66CCFF" }} />
              </IconButton>
            )}
            {shoutoutOwned && (
              <IconButton button onClick={() => handleVerification()}>
                <CheckCircleIcon style={{ color: verified ? "#66CCFF" : "" }} />
              </IconButton>
            )}
            {commentOwned && (
              <Tooltip title="Edit">
                <IconButton button onClick={() => handleEdit()}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* {commentOwned && (
              <Tooltip title="Delete">
                <IconButton button onClick={() => handleDelete()}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )} */}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="caption" className="replier-date">
              {time}
            </Typography>
          </div>
        </div>
      </div>
      <Typography
        variant="body1"
        className="comment-text"
        style={{ maxHeight: seeMore ? "" : "50px" }}
      >
        {urlify(comment)
          .split(" ")
          .map((item) =>
            item.split("")[0] === "#" ? (
              <Link
                href={`/User/Search?q=${
                  item.split("").includes("_")
                    ? item.split("_").join(" ").slice(1)
                    : item.slice(1)
                }`}
                style={{
                  color: "#EE813A",
                  padding: "0 5px 0 5px",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                {`#${capitalize(item)}`}
              </Link>
            ) : item.split("")[0] === "@" ? (
              <span
                className="userLink"
                style={{
                  color: "#000",
                  padding: "0 5px 0 5px",
                  fontWeight: "600",
                }}
                onClick={() => navigateToUser(item.slice(1), users)}
              >
                {`${
                  item.split("").includes("_")
                    ? item.split("_").join(" ").slice(1)
                    : item.slice(1)
                }`}
              </span>
            ) : item.split("")[0] === "`" ? (
              <a href={item.slice(1)} target="_blank" rel="noopener noreferrer">
                {item.slice(1)}
              </a>
            ) : (
              `${item} `
            )
          )}
      </Typography>
      {comment.length > 100 && (
        <span onClick={() => setSeeMore(!seeMore)} className="see-more">
          {seeMore ? "...See Less" : "...See More"}
        </span>
      )}
      <br />
    </div>
  );
}

export default ShoutoutReply;
