import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import InterestCard from "../SubComponents/interest-card/interestCard-component";
import InterestModal from "../SubComponents/interestModal/InterestModal";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Logout from "../../logout";
import { withRouter } from "react-router-dom";
import "./interest-view-style.scss";

function InterestView({ UserInfo, user, handleEdit, history }) {
  const [allInterests, setAllInterests] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState(null);
  const [selectedCat, setSelectedCat] = useState("");
  const [skills, setSkills] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    try {
      console.log(UserInfo);
      const getAllInterests = async () => {
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_API_URL}user/me/skillCategories`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
          );
          return result;
        } catch (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.message);
            if (error.response.status === 401) {
              history.push("/logout");
            }
          }
        }
      };
      async function gettingAllInterests() {
        try {
          let getAllInterestsResult = await getAllInterests();
          if (getAllInterestsResult) {
            console.log(getAllInterestsResult);
            setAllInterests(getAllInterestsResult.data);
          }
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            history.push("/logout");
          }
        }
      }
      gettingAllInterests();
      if (UserInfo) {
        if (UserInfo.userInterestSet) {
          let current = UserInfo.userInterestSet.userInterests.map(
            (item) => item.skillDisplayName
          );
          console.log(current);
          setSelectedInterests(current);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [UserInfo]);

  useEffect(() => {
    if (allInterests && selectedInterests) {
      setSelectedSkills(selectedInterests);
      let Current = [];
      selectedInterests.forEach((item) => {
        try {
          let category;
          allInterests.skillCategories.forEach((Cat) => {
            const exist = Cat.skills.find(
              (s) => s.skillDisplayName.toLowerCase() === item.toLowerCase()
            );
            if (exist) {
              category = Cat;
              return;
            }
          });
          console.log(category);
          if (
            !Current.find(
              (cat) =>
                cat.skillCategory.publicId === category.skillCategory.publicId
            )
          ) {
            if (category) Current.push(category);
          }
        } catch (error) {
          console.log(`item: ${item} and error: ${error}`);
        }
      });
      console.log(Current);
      setSelectedCategories(Current);
    }
  }, [allInterests, selectedInterests]);

  const addToList = (id) => {
    try {
      const exist = allInterests.skillCategories.find(
        (item) => item.skillCategory.publicId === id
      );
      console.log(exist);
      console.log(selectedSkills);
      const updatedSkills = [];
      if (exist) {
        setSelectedCat(exist.skillCategory.skillCategoryDisplayName);
        exist.skills.map((skill) => {
          if (
            selectedSkills.find(
              (item) =>
                item.toLowerCase() === skill.skillDisplayName.toLowerCase()
            )
          ) {
            updatedSkills.push(skill.skillDisplayName);
          }
        });
        console.log(updatedSkills);
        setSkills(updatedSkills);
        setOpenModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper className="skillCard-container">
      <div style={{ width: "100%", padding: "10px 10px" }}>
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid rgba(112, 112, 112,0.3)",
            lineHeight: "1.5",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ padding: "8px 0 3px 0" }} className="skill-card-title">
            Interests
          </span>
          {user && (
            <EditIcon
              style={{ opacity: "0.6", cursor: "pointer", marginTop: "10px" }}
              onClick={() => handleEdit()}
            />
          )}
        </Typography>
      </div>
      <div className="interest-view-container">
        {selectedCategories &&
          selectedCategories.map((item, index) => (
            <InterestCard
              key={index}
              Id={item.skillCategory.publicId}
              name={item.skillCategory.skillCategoryDisplayName}
              imgUrl={item.skillCategory.imageUrl}
              view={true}
              handleSelect={addToList}
            />
          ))}
      </div>
      <InterestModal
        category={selectedCat}
        open={openModal}
        skills={skills && skills}
        handleClose={() => setOpenModal(false)}
        hideSelectAllCheckbox={true}
      />
    </Paper>
  );
}

export default withRouter(InterestView);
