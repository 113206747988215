/*jshint esversion:9*/
/*jshint -W087*/

export const addSkill = (list,category) => {
   try {
    const exist = list.find(item => item.skillCategory.publicId === category.skillCategory.publicId);

    if(exist){
      //debugger;
      return list.map(item => item.skillCategory.publicId === category.skillCategory.publicId ?
        category : item
      );
    }
  
    return list; 
   } catch (error) {
     console.log(error.message);
   }
};


export const deleteSkill = (list,object) => {
  try {
    const exist = list.find(item => item.skillCategory.publicId === object.categoryId);

    if(exist){
      return list.map(item => item.skillCategory.publicId === object.categoryId?
      {...item,skills:item.skills.filter(item => item.skillPublicId !== object.skillId)}:
      item
    );
    }
  
    return list;
  } catch (error) {
    console.log(error.message);
  }
};


export const updateSkill = (list,object) => {
   try {
    const exist = list.find(item => item.skillCategory.publicId === object.categoryId);
    let catId = object.categoryId;
    delete object.categoryId;
    if(exist){
     list = list.map(item=> item.skillCategory.publicId === catId ?
      {...item,skills:item.skills.map(skillItem => skillItem.skillPublicId === object.skillPublicId ? object : skillItem)}:
      item
    );
    console.log(list);
    return list;
    }
    return list;
   } catch (error) {
     console.log(error.message);
   }
};

export const updateCategoryName = (list,object) => {
   try {
    const exist = list.find(item => item.skillCategory.publicId === object.id);
    if(exist){
      return list.map(item => item.skillCategory.publicId === object.id?
        {...item,skillCategory:{...item.skillCategory,skillCategoryName:object.name, skillCategoryDisplayName: object.displayName}}:item
      );
    }
  
    return list;
   } catch (error) {
     console.log(error.message);
   }
};
