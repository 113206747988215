/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import {Typography,Backdrop,makeStyles,CircularProgress} from '@material-ui/core';
import SkillTab from '../../../Components/SubComponents/skillTab/skillTab-new-component';
import AddCategory from '../../../Components/add-categories/add-categories-component';
import SkillCategoryInfo from '../../../Components/SubComponents/skill-category-info/skill-category-info';
import ManuallyAddCategory from '../../../Components/manually-add-category/manually-add-category-component';
import Snackbar from '../../../Components/SubComponents/snackbar/snackbar';
import AddSkillModal from '../../../Components/SubComponents/categories-add-skill-modal/categories-add-skill-modal';
import EditCategoryModal from '../../../Components/SubComponents/edit-category-modal/edit-category-modal';
import DeleteCategoryModal from '../../../Components/SubComponents/delete-category-modal/delete-category-modal';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectCategories} from '../../../redux/categories/categories-selector';
import {setCategories,addSkill,deleteSkill,updateSkill,addSingleCategory,deleteCategory,updateCategoryName} from '../../../redux/categories/categories-actions';
import axios from 'axios';
import './categories-style.scss';
import Logout from '../../../logout';
import { selectUserData } from '../../../redux/user/user-selector';

const useStyles = makeStyles((theme)=>({

backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
}));

const SkillCategoryInfoMemo = React.memo(SkillCategoryInfo);


function CategoryPage({setCategories,categories,addASkill,deleteASkill,updateSkill,addSingleCategory,deleteCategory,updateCategoryName, userData}){
  const classes = useStyles();
  const [uploaded,setUploaded] = useState(false);
  const [loading,setLoading] = useState(false);
  const [alertOpen,setAlertOpen] = useState(false);
  const [severity,setSeverity] = useState('');
  const [alert,setAlert] = useState('');
  const [category,setCategory] = useState(null);
  const [skill,setSkill] = useState(null);
  const [addSkill,setAddSkill] = useState(false);
  const [categoryModal,setCategoryModal] = useState(false);
  const [skillOperation,setSkillOperation] = useState('');
  const [deleteCategoryOpen,setDeleteCategoryOpen] = useState(false);
  const [deleteCategoryData,setDeleteCategoryData] = useState(null);

  useEffect(()=>{
    try {
      const token = localStorage.getItem('bhyve-token');
      console.log('hit');
        const getCategories = async() => {
          if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
          const result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategories`,{
             headers:{
               'Authorization': `Bearer ${token}`,
               'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
             }
           });
           return result;
          }
        };
        async function gettingCategories(){
          try {
            let getCategoriesResult = await getCategories();
            if(getCategoriesResult){
              console.log(getCategoriesResult.data);
              setCategories({skillCategories:getCategoriesResult.data});
            }
          } catch(err){
            if(err.response){
              console.log(err.response.message);
              if(err.response.status===401){
                Logout();
              }
            }
          }
        }
        gettingCategories();
    } catch (error) {
      console.log(error.message);
    }
         
  },[]);


  const convertCategories = async(token,fileKey) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    let result;
    console.log(fileKey);
      result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/convert/file?fileKey=${fileKey}`,{
         headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
         }
       });
    return result;
      }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleUpload = async file => {
        try {
          const token = localStorage.getItem('bhyve-token');
          if(file){
            console.log('file type ', file.type);
            if(file.type === 'text/csv'){
              setLoading(true);
              let formData = new FormData();
              formData.append('file',file);
              let uploadCSVResult = await uploadCSV(token,formData);
              if(uploadCSVResult){
                setUploaded(true);
                  console.log(uploadCSVResult.data);
                  //setFileKey(res.data.fileKey);
                  setAlert('File uploaded successfully!');
                  setSeverity('info');
                  setAlertOpen(true);
                  handleConvert(uploadCSVResult.data.fileKey);
                  setLoading(false);
              }
            }else{
                setAlert('Invalid file type! Please upload a .csv file.');
                setSeverity('warning');
                setAlertOpen(true);
                setLoading(false);
            }
          }
        } catch (err) {
          setAlert(err);
          setSeverity('error');
          setAlertOpen(true);
          setLoading(false);
          if(err.response){
            console.log(err.response.message);
            if(err.response.status===401){
              Logout();
            }
          }
        }
  };

  const uploadCSV = async(token,file) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    console.log(file);
    const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/file`,file,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

  const handleConvert = async (key) => {
    try {
      const token = localStorage.getItem('bhyve-token');
    setLoading(true);
    let convertCategoriesResult = await convertCategories(token,key);
    if(convertCategoriesResult){
        console.log(convertCategoriesResult);
        setUploaded(false);
        setCategories(convertCategoriesResult.data);
        setAlert('File converted successfully!');
        setSeverity('success');
        setAlertOpen(true);
        setLoading(false);
    }
    } catch (err) {
      setAlert(err);
      setSeverity('error');
      setAlertOpen(true);
      setLoading(false);
      if(err.response){
        console.log(err.response.message);
        if(err.response.status===401){
          Logout();
        }
      }
    }
  };

  const openAddSkillModal = (data,operation) => {
    console.log(data);
    setSkill(data);
    setSkillOperation(operation);
    setAddSkill(true);
  };

  const handleAddSkill = async (data,skill) => {
    try {
      console.log('data',data,'skill',skill);
      setLoading(true);
      const req = {
        skillName:skill.skill
      };
      let updateSkillInCategoryResult = await updateSkillInCategory(data,req);
      if(updateSkillInCategoryResult){
        console.log(updateSkillInCategoryResult);
        if(skillOperation==='add'){
          const category = {
            skillCategory: data.skillCategory,
            skills: [                      
                      {
                        skillPublicId: updateSkillInCategoryResult.data.skillPublicId,
                        skillName: updateSkillInCategoryResult.data.skillName,
                        skillDisplayName: updateSkillInCategoryResult.data.skillDisplayName
                      },
                      ...updateSkillInCategoryResult.data.category.skills
                    ]
          };
          console.log(category);
          addASkill(category);
          setAlert('Skill added successfully!');
        }else{
          updateSkill({categoryId: data.categoryId,...updateSkillInCategoryResult.data});
          setAlert('Skill updated successfully!');
      }
          setSeverity('success');
          setAlertOpen(true);
          setAddSkill(false);
          setLoading(false);
    }
    } catch (err) {
          console.log(err);
          setSeverity('error');
          setAlertOpen(true);
          setAddSkill(false);
          setLoading(false);
          if(err.response){
            console.log(err.response.message);
            if(err.response.status===401){
              Logout();
            }
          }
    }
  };

  const updateSkillInCategory = async(data,request)=> {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

          const token = localStorage.getItem('bhyve-token');
    if(skillOperation==='add'){
      const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategory/${data.skillCategory.publicId}/skill`,request,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }else{
      console.log(data);
      const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skill/${data.skillId}`,request,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }
  }

  };

  const handleModalClose = () => {
    if(categoryModal){
      setCategoryModal(false);
    }else{
        setAddSkill(false);
    }
  };

  const handleSkillDelete = async (skillId,categoryId) => {
    try {
      handleModalClose();
      setLoading(true);
      let deleteSkillResult = await deleteSkill(skillId);
      if(deleteSkillResult){
        const object = {
          skillId,
          categoryId
        };
        deleteASkill(object);
        setAlert('Skill deleted successfully!');
        setSeverity('info');
        setAlertOpen(true);
        setLoading(false);
      }
    } catch (err) {
      setAlert(err);
        setSeverity('error');
        setAlertOpen(true);
        setLoading(false);
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
    }
  };

  const deleteSkill = async(id) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const token = localStorage.getItem('bhyve-token');
    const result = await axios.delete(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skill/${id}`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

  async function handleAddCategory(data){
    try {
      setLoading(true);
      console.log(data);
      if(categories){
        if(data.skillCategoryName){
          const exist = categories.skillCategories.find(item => item.skillCategory.skillCategoryName === data.skillCategoryName);
          if(exist){
            setAlert('A category with the same name already exists!');
            setSeverity('warning');
            setAlertOpen(true);
            setLoading(false);
            return;
          }
          let addCategoryResult = await addCategory(data);
          if(addCategoryResult){
              console.log(addCategoryResult);
              addSingleCategory(addCategoryResult.data);
              setAlert('Category added successfully!');
              setSeverity('success');
              setAlertOpen(true);
              setLoading(false);
          }
        }else{
          setAlert('Please enter the category name!');
          setSeverity('warning');
          setAlertOpen(true);
          setLoading(false);
        }
      } 
    } catch (err) {
      setAlert(err);
      setSeverity('error');
      setAlertOpen(true);
      setLoading(false);
      if(err.response){
        console.log(err.response.message);
        if(err.response.status===401){
          Logout();
        }
      }
    }
  }

  const addCategory = async(req) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
          const token = localStorage.getItem('bhyve-token');
    const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategory`,req,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });

    return result;
  }
  };

  const handleCategoryDelete = async id => {
    try {
      setDeleteCategoryOpen(false);
      setLoading(true);
      let deleteSingleCategoryResult = await deleteSingleCategory(id);
      if(deleteSingleCategoryResult){     
        deleteCategory(id);
        setAlert('Category deleted successfully!');
        setSeverity('info');
        setAlertOpen(true);
        setLoading(false);
      }
    } catch (err) {
        setAlert(err);
        setSeverity('error');
        setAlertOpen(true);
        setLoading(false);
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
    }
  };

  const deleteSingleCategory = async(id) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const token = localStorage.getItem('bhyve-token');
    const result = await axios.delete(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategory/${id}`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });

    return result;
  }
  };

  const handleCategoryModal = data => {
    setCategory(data);
    setCategoryModal(true);
  };

  const handleCategoryUpdate = async (data,info) => {
    try {
      let updateCategoryResult;
      console.log(typeof(info.image)!=='string');
      //   
        if(info.skillCategoryName!==''||typeof(info.image)!=='string'){
                setLoading(true);
          setCategoryModal(false);
          if(info.skillCategoryName!==''&&info.skillCategoryName!==category.skillCategory.skillCategoryName){
            try {
             updateCategoryResult = await updateCategory(data.skillCategory.publicId,info,'name');
             //debugger;
              if(updateCategoryResult){
                console.log(updateCategoryResult);
                const object = {
                  id:data.skillCategory.publicId,
                  name:updateCategoryResult.data.skillCategory.skillCategoryName,
                  displayName: updateCategoryResult.data.skillCategory.skillCategoryDisplayName,
                };
                console.log(object);
                updateCategoryName(object);
                setAlert('Category name updated successfully!');
                setSeverity('success');
                setAlertOpen(true);
                if(typeof(info.image)==='string'){
                  setLoading(false);
                }
              }
            } catch (err) {
              setLoading(false);
              setAlert(err);
              setSeverity('error');
              setAlertOpen(true);
              if(err.response){
                console.log(err.response.message);
                if(err.response.status===401){
                  Logout();
                }
              }
            }
          }else{
            setLoading(false);
          }
    
          if(info.image&&typeof(info.image)!=='string'){
            try {
              updateCategoryResult = await updateCategory(data.skillCategory.publicId,info,'image');
              if(updateCategoryResult){
                setLoading(false);
                setAlert('Category image updated successfully!');
                setSeverity('success');
                setAlertOpen(true);
              }
            } catch (err) {
              setLoading(false);
              setAlert(err);
              setSeverity('error');
              setAlertOpen(true);
              if(err.response){
                console.log(err.response.message);
                if(err.response.status===401){
                  Logout();
                }
              }
            }
          }
        }else{
          setAlert('Please fill one of the fields!');
          setSeverity('warning');
          setAlertOpen(true);
        }    
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateCategory = async(id,info,type) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

    
          const token = localStorage.getItem('bhyve-token');
    if(type==='name'){
      const req = {
      skillCategoryName: info.skillCategoryName
      };
      const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategory/${id}`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });

      return result;
    }else{
      let formData = new FormData();
      formData.append('image',info.image);
            console.log('called');
      const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategory/${id}/image`,formData,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      console.log(result);
      return result;
    }
  }
  };

  const categoryDelete = category => {
    setDeleteCategoryData(category);
    setDeleteCategoryOpen(true);
  }

  return(
    <div className='client-container'>
    <Backdrop className={classes.backdrop} open={loading} >
      <CircularProgress color="primary" />
    </Backdrop>
    <Snackbar Open={alertOpen} alert={alert} severity={severity} handleClose={handleAlertClose}/>
    <Typography variant='h3' style={{fontWeight:'bold',width:'100%',textAlign:'center',marginBottom:'80px'}}>Categories</Typography>
      <AddCategory uploaded={uploaded} handleUpload={handleUpload} />
    <Typography variant='h6' style={{fontWeight:'bold',width:'100%',textAlign:'center',margin:'10px 0 10px 0'}}>OR</Typography>
    <ManuallyAddCategory handleAdd={handleAddCategory}/>
      {categories &&
        <div className='category-info-section'>
        {categories.skillCategories && 
          <div style={{width:'90%'}}>
              <SkillTab  
                allCategoriesAndSkills={categories.skillCategories} 
                ownerPanel  
                handleEditSkill={openAddSkillModal} 
                handleCategoryEdit={handleCategoryModal} 
                handleCategoryDelete={categoryDelete}
              />  
          </div>    
        }
        </div>}
        <AddSkillModal operation={skillOperation} open={addSkill} data={skill} handleAdd={handleAddSkill} handleClose={handleModalClose} handleDelete={handleSkillDelete}/>
        <EditCategoryModal open={categoryModal} data={category} handleUpdate={handleCategoryUpdate} handleClose={handleModalClose}/>
        <DeleteCategoryModal open={deleteCategoryOpen} handleClose={() => setDeleteCategoryOpen(false)} data={deleteCategoryData} handleCategoryDelete={handleCategoryDelete}/>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  categories: selectCategories,
  userData: selectUserData
});

const mapDispatchToProps = dispatch => ({
  setCategories: array => dispatch(setCategories(array)),
  addASkill: skill => dispatch(addSkill(skill)),
  deleteASkill: object => dispatch(deleteSkill(object)),
  updateSkill: object => dispatch(updateSkill(object)),
  addSingleCategory: object => dispatch(addSingleCategory(object)),
  deleteCategory:id => dispatch(deleteCategory(id)),
  updateCategoryName: object => dispatch(updateCategoryName(object))
});

export default connect(mapStateToProps,mapDispatchToProps)(CategoryPage);


// {categories.skillCategories && categories.skillCategories.map((item,index)=>
//   <SkillCategoryInfoMemo data={item} key={index} handleAddSkill={openAddSkillModal} handleDelete={handleSkillDelete} handleCategoryDelete={handleCategoryDelete} handleCategoryEdit={handleCategoryModal}/>
// )}