import React, { useState, useEffect } from "react";
import SkillTabSkill from "../skillTab-skill/index";
import {
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import "./skillTab-new-style.scss";
import { useHistory } from "react-router";

function SkillTabNew({
  allCategoriesAndSkills,
  userCategories,
  setNewSkillList,
  ownerPanel,
  handleEditSkill,
  handleCategoryEdit,
  handleCategoryDelete,
  handleAddSkill,
}) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [skillList, setSkillList] = useState(null);
  const [expertiseMode, setExpertiseMode] = useState(false);
  const [search, setSearch] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [organizedSkillsList, setOrganizedSkillsList] = useState([]);
  const token = localStorage.getItem("bhyve-token");
  const history = useHistory();

  useEffect(() => {
    try {
      if (currentCategory) {
        let categoryFound = allCategoriesAndSkills.find(
          (item) => item.skillCategory.publicId === currentCategory
        );
        setSkillList(categoryFound.skills);
      } else {
        if (allCategoriesAndSkills) {
          setCurrentCategory(allCategoriesAndSkills[0].skillCategory.publicId);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [currentCategory, allCategoriesAndSkills]);

  useEffect(() => {
    try {
      if (
        allCategoriesAndSkills &&
        userCategories &&
        userCategories.length > 0
      ) {
        setSelectedSkills(userCategories);
        setNewSkillList(userCategories);
        let current = [];
        userCategories.forEach((skill) => {
          allCategoriesAndSkills.forEach((item) => {
            let exist = item.skills.find(
              (currentSkill) =>
                currentSkill.skillPublicId === skill.skillPublicId
            );
            if (exist) {
              let categoryExists = current.find(
                (currentCategory) =>
                  currentCategory.id === item.skillCategory.publicId
              );
              if (categoryExists) {
                current = current.map((currentCategory) =>
                  currentCategory.id === item.skillCategory.publicId
                    ? {
                        ...currentCategory,
                        quantity: currentCategory.quantity + 1,
                      }
                    : currentCategory
                );
              } else {
                current.push({ id: item.skillCategory.publicId, quantity: 1 });
              }
            }
          });
        });
        setSelectedCategories(current);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [userCategories, allCategoriesAndSkills]);

  const sortAlphabetically = (originalArray) => {
    return originalArray.sort(function (a, b) {
      var firstItem = a.skillName.toUpperCase();
      var secondItem = b.skillName.toUpperCase();
      return firstItem < secondItem ? -1 : firstItem > secondItem ? 1 : 0;
    });
  };

  useEffect(() => {
    if (!!skillList && !!selectedSkills) {
      let selectedSkillList = [];
      let unSelectedSkillList = [];
      skillList.forEach((skill) => {
        if (
          selectedSkills.some(
            (item) => item.skillPublicId === skill.skillPublicId
          )
        ) {
          selectedSkillList.push(skill);
        } else {
          unSelectedSkillList.push(skill);
        }
      });
      selectedSkillList = sortAlphabetically(selectedSkillList);
      Array.prototype.push.apply(selectedSkillList, unSelectedSkillList);
      setOrganizedSkillsList(selectedSkillList);
    }
  }, [skillList]);

  const handleSelectCategory = (event, category) => {
    if (
      event.target.tagName !== "svg" &&
      event.target.tagName !== "BUTTON" &&
      event.target.tagName !== "path" &&
      event.target.tagName !== "DIV" &&
      event.target.id !== "category-actions"
    ) {
      setCurrentCategory(category.skillCategory.publicId);
    }
  };

  const handleSkillSelect = (skill) => {
    try {
      let currentSkillList = selectedSkills;
      let exist = currentSkillList.find(
        (item) => item.skillPublicId === skill.skillPublicId
      );
      if (exist) {
        setCurrentSkill(exist);
      } else {
        setCurrentSkill(skill);
      }
      setExpertiseMode(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleExpertiseSelect = (score, skillDetails) => {
    try {
      let currentSkillList = selectedSkills;
      let exist = currentSkillList.find(
        (item) => item.skillPublicId === skillDetails.skillPublicId
      );
      if (exist) {
        currentSkillList = currentSkillList.map((item) =>
          item.skillPublicId === skillDetails.skillPublicId
            ? { ...item, score: parseFloat(score) }
            : item
        );
      } else {
        currentSkillList.push({
          ...skillDetails,
          score: parseFloat(score),
          description: "",
          skillType: "PRIMARY_SKILL",
        });
      }
      setSelectedSkills(currentSkillList);
      let current = [];
      currentSkillList.forEach((skill) => {
        allCategoriesAndSkills.forEach((item) => {
          let exist = item.skills.find(
            (currentSkill) => currentSkill.skillPublicId === skill.skillPublicId
          );
          if (exist) {
            let categoryExists = current.find(
              (currentCategory) =>
                currentCategory.id === item.skillCategory.publicId
            );
            if (categoryExists) {
              current = current.map((currentCategory) =>
                currentCategory.id === item.skillCategory.publicId
                  ? {
                      ...currentCategory,
                      quantity: currentCategory.quantity + 1,
                    }
                  : currentCategory
              );
            } else {
              current.push({ id: item.skillCategory.publicId, quantity: 1 });
            }
          }
        });
      });
      setSelectedCategories(current);
      setExpertiseMode(false);
      setNewSkillList(currentSkillList);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRemoveSkill = (id) => {
    try {
      let currentSkillList = selectedSkills;
      let exist = currentSkillList.find((item) => item.skillPublicId === id);
      if (exist) {
        currentSkillList = currentSkillList.filter(
          (item) => item.skillPublicId !== id
        );
      }
      setSelectedSkills(currentSkillList);
      let current = [];
      currentSkillList.forEach((skill) => {
        allCategoriesAndSkills.forEach((item) => {
          let exist = item.skills.find(
            (currentSkill) => currentSkill.skillPublicId === skill.skillPublicId
          );
          if (exist) {
            let categoryExists = current.find(
              (currentCategory) =>
                currentCategory.id === item.skillCategory.publicId
            );
            if (categoryExists) {
              current = current.map((currentCategory) =>
                currentCategory.id === item.skillCategory.publicId
                  ? {
                      ...currentCategory,
                      quantity: currentCategory.quantity + 1,
                    }
                  : currentCategory
              );
            } else {
              current.push({ id: item.skillCategory.publicId, quantity: 1 });
            }
          }
        });
      });
      setSelectedCategories(current);
      setExpertiseMode(false);
      setNewSkillList(currentSkillList);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSearchChange = async (e) => {
    try {
      const { value } = e.target;
      setSearch(value);
      if (value.length > 0) {
        setOrganizedSkillsList([]);
        setSearchMode(true);
        let searchResult = await axios.get(
          `${process.env.REACT_APP_API_URL}user/autocomplete/skills?skillName=${value}&limit=0`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSkillList(searchResult.data);
      } else {
        handleCancelSearch();
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const handleCancelSearch = () => {
    let categoryFound = allCategoriesAndSkills.find(
      (item) => item.skillCategory.publicId === currentCategory
    );
    setSkillList(categoryFound.skills);
    setSearchMode(false);
    setSearch("");
  };

  const categoryEdit = (e, category) => {
    if (
      e.target.tagName === "svg" ||
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "path" ||
      (e.target.tagName === "DIV" && e.target.id === "category-actions")
    ) {
      handleCategoryEdit(category);
    }
  };

  const categoryDelete = (e, category) => {
    if (
      e.target.tagName === "svg" ||
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "path" ||
      (e.target.tagName === "DIV" && e.target.id === "category-actions")
    ) {
      handleCategoryDelete(category);
    }
  };

  return (
    <Paper style={{ width: "100%" }}>
      <div className="main-skillTab-container">
        {!searchMode && (
          <div className="skillTab-category-selector-main">
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                padding: "5% 0 1% 10%",
                textTransform: "uppercase",
                borderBottom: "1px solid rgba(0,0,0,0.2)",
              }}
            >
              Categories
            </Typography>
            <div className="skillTab-category-selector-sub">
              {allCategoriesAndSkills &&
                allCategoriesAndSkills.map((category) => (
                  <Typography
                    key={category.skillCategory.publicId}
                    variant="body1"
                    className={`category-btn ${
                      currentCategory === category.skillCategory.publicId &&
                      "makeBold"
                    }`}
                    style={{
                      backgroundColor:
                        currentCategory === category.skillCategory.publicId
                          ? "#66CCFF"
                          : "",
                    }}
                    onClick={(e) => handleSelectCategory(e, category)}
                  >
                    <span>
                      {`${category.skillCategory.skillCategoryDisplayName}`}
                    </span>
                    <span>
                      {selectedCategories.find(
                        (item) => item.id === category.skillCategory.publicId
                      ) &&
                        `${
                          selectedCategories.find(
                            (item) =>
                              item.id === category.skillCategory.publicId
                          ).quantity
                        }`}
                    </span>
                    {ownerPanel && (
                      <div
                        id="category-actions"
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          zIndex: "800",
                        }}
                      >
                        <IconButton
                          id="category-edit"
                          onClick={() => {
                            setExpertiseMode(false);
                            handleEditSkill(category, "add");
                          }}
                          disabled={
                            currentCategory !== category.skillCategory.publicId
                          }
                        >
                          <AddIcon
                            style={{
                              color:
                                currentCategory ===
                                category.skillCategory.publicId
                                  ? "black"
                                  : "gray",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          id="category-edit"
                          onClick={(e) => categoryEdit(e, category)}
                          disabled={
                            currentCategory !== category.skillCategory.publicId
                          }
                        >
                          <EditIcon
                            style={{
                              color:
                                currentCategory ===
                                category.skillCategory.publicId
                                  ? "black"
                                  : "gray",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          id="category-delete"
                          onClick={(e) => categoryDelete(e, category)}
                          disabled={
                            currentCategory !== category.skillCategory.publicId
                          }
                        >
                          <DeleteIcon
                            style={{
                              color:
                                currentCategory ===
                                category.skillCategory.publicId
                                  ? "black"
                                  : "gray",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </Typography>
                ))}
            </div>
          </div>
        )}
        <div
          className="skillTab-skill-selector-main"
          style={searchMode ? { width: "100%" } : { width: "70%" }}
        >
          {!ownerPanel && (
            <div className="search-icon-container">
              {!searchMode && (
                <span>
                  {`${
                    allCategoriesAndSkills &&
                    allCategoriesAndSkills.find(
                      (item) => item.skillCategory.publicId === currentCategory
                    ) &&
                    allCategoriesAndSkills.find(
                      (item) => item.skillCategory.publicId === currentCategory
                    ).skillCategory.skillCategoryDisplayName
                  }`}
                  {selectedCategories.find(
                    (item) => item.id === currentCategory
                  ) &&
                    ` (${
                      selectedCategories.find(
                        (item) => item.id === currentCategory
                      ).quantity
                    })`}
                </span>
              )}
              {searchMode && (
                <div className="search-title">
                  <ArrowBackIcon onClick={handleCancelSearch} />
                  <span>Search Results</span>
                </div>
              )}
              <TextField
                placeholder="Search Skills"
                className="search-txt-field"
                variant="outlined"
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchMode && (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ width: "10px", height: "10px" }}
                        onClick={handleCancelSearch}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchChange}
              />
            </div>
          )}
          <div
            className="expertise-selection-container"
            style={{ display: expertiseMode ? "flex" : "none" }}
          >
            {!ownerPanel && (
              <div className="expertise-selection-sub-container">
                {currentSkill && (
                  <Typography
                    variant="subtitle1"
                    className="expertise-selection-skill"
                  >
                    {currentSkill.skillDisplayName}
                  </Typography>
                )}
                <Typography
                  variant="subtitle1"
                  className="expertise-selection-title"
                >
                  Expertise Level
                </Typography>
                <FormControl
                  component="fieldset"
                  className="radio-grp"
                  style={{ marginBottom: "5px" }}
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="score"
                    value={currentSkill && currentSkill.score}
                    onChange={handleExpertiseSelect}
                    defaultValue="top"
                  >
                    <FormControlLabel
                      value={0.333}
                      control={<Radio color="primary" />}
                      label="Beginner"
                    />
                    <FormControlLabel
                      value={0.667}
                      control={<Radio color="primary" />}
                      label="Proficient"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label="Expert"
                    />
                  </RadioGroup>
                </FormControl>
                <div>
                  {currentSkill && currentSkill.score && (
                    <Button
                      className="expertise-selection-remove-btn"
                      startIcon={<ClearIcon />}
                      onClick={handleRemoveSkill}
                    >
                      Remove
                    </Button>
                  )}
                  <br />
                  <Button
                    className="expertise-selection-back-btn"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => setExpertiseMode(false)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
            {ownerPanel && (
              <div className="expertise-selection-skill">
                <Typography
                  variant="subtitle2"
                  className="expertise-selection-skill"
                >
                  {currentSkill && currentSkill.skillDisplayName}
                </Typography>
                <Button
                  className="expertise-selection-back-btn"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setExpertiseMode(false);
                    handleEditSkill(
                      {
                        skillName: currentSkill.skillDisplayName,
                        skillId: currentSkill.skillPublicId,
                        categoryId: currentCategory,
                      },
                      "edit"
                    );
                  }}
                >
                  Edit
                </Button>
                <Button
                  className="expertise-selection-remove-btn"
                  startIcon={<ClearIcon />}
                  style={{ color: "red" }}
                  onClick={() => {
                    setExpertiseMode(false);
                    handleEditSkill(
                      {
                        skillName: currentSkill.skillDisplayName,
                        skillId: currentSkill.skillPublicId,
                        categoryId: currentCategory,
                      },
                      "delete"
                    );
                  }}
                >
                  Delete
                </Button>
                <Button
                  className="expertise-selection-back-btn"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setExpertiseMode(false)}
                >
                  Back
                </Button>
              </div>
            )}
          </div>
          {searchMode && (
            <p className="search-result-count">
              {organizedSkillsList.length > 0
                ? `Found ${organizedSkillsList.length} skills`
                : ""}
            </p>
          )}
          <div
            className="skillTab-skill-selector-sub"
            style={
              searchMode
                ? { gridTemplateColumns: "25% 25% 25% 25%", maxHeight: "75%" }
                : { width: "" }
            }
          >
            {organizedSkillsList &&
              organizedSkillsList.map((skill) => (
                <SkillTabSkill
                  skill={skill}
                  selected={selectedSkills.find(
                    (item) => item.skillPublicId === skill.skillPublicId
                  )}
                  level={
                    selectedSkills.find(
                      (item) => item.skillPublicId === skill.skillPublicId
                    ) &&
                    selectedSkills.find(
                      (item) => item.skillPublicId === skill.skillPublicId
                    ).score
                  }
                  handleSkillSelect={handleExpertiseSelect}
                  handleRemoveSkill={() =>
                    handleRemoveSkill(skill.skillPublicId)
                  }
                  ownerPanel={ownerPanel}
                  handleEditSkill={() => {
                    handleEditSkill(
                      {
                        skillName: skill.skillDisplayName,
                        skillId: skill.skillPublicId,
                        categoryId: currentCategory,
                      },
                      "edit"
                    );
                  }}
                  handleDeleteSkill={() => {
                    handleEditSkill(
                      {
                        skillName: skill.skillDisplayName,
                        skillId: skill.skillPublicId,
                        categoryId: currentCategory,
                      },
                      "delete"
                    );
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default SkillTabNew;

// <div className='skill-btn-container' key={skill.skillPublicId}>
// {selectedSkills.find(item => item.skillPublicId===skill.skillPublicId) && <FiberManualRecordIcon style={{color:selectedSkills.find(item => item.skillPublicId===skill.skillPublicId).score===1?'#66CCFF':selectedSkills.find(item => item.skillPublicId===skill.skillPublicId).score===0.667?'#FFCC33':'#EE813A'}}/>}
// <Typography variant='body1' className='skill-btn'  onClick={()=>handleSkillSelect(skill)}>{skill.skillDisplayName}</Typography>
// </div>
