/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import InterestComponent from '../../Components/interestSection/interest-component';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData,selectCompanyInformation} from '../../redux/user/user-selector';
import {setUserData} from '../../redux/user/user-actions';
import Loader from '../SubComponents/loader/loader-component';
import Snackbar from '../SubComponents/snackbar/snackbar';
import Logout from '../../logout';
import axios from 'axios';
//import InterestModal from '../../Components/SubComponents/interestModal/interestModal-component';



function EditInterest({userData,companyInformation,setUserData, history}){

  const [allInterests,setAllInterests] = useState(null);
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);
  const [selectedInterests,setSelectedInterests] = useState([]);
  const [confirmChanges, setConfirmChanges] = useState(false);

  const token = localStorage.getItem('bhyve-token');

  useEffect(()=>{
    try {
      if(companyInformation) {
        const getAllInterests = async() => {
          const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me/skillCategories`,{
            headers:{
              'Authorization': `Bearer ${token}`,
              'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
            }
          });
          return result;
        };
        async function gettingAllInterests() {
          try {
            let getAllInterestsResult = await getAllInterests();
            if(getAllInterestsResult) {
              console.log(getAllInterestsResult);
              setAllInterests(getAllInterestsResult.data);
            } 
          } catch (error) {
            console.log(error);
            if(error.response && error.response.status === 401) {
              history.push('/logout');
            }
          }
        }
        gettingAllInterests();
      }
      if(userData) {
        if(userData.userInformation.userInterestSet) {
          let current = userData.userInformation.userInterestSet.userInterests.map(item => item.skillName);
          console.log(current);
          setSelectedInterests(current);
        }
      } 
    } catch (error) {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
  },[companyInformation,userData]);

  const handleSave = async done => {
    try {
      if(done) {
        setMessage('Interests updated successfully!');
        setSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
  };


  return(
    <div style={{width:'100%',textAlign:'center'}}>
    <Loader open={loading}/>
    <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
    <div className='create-profile-top-right'>
          <div>     
            <h2>Pick your Interests</h2>
          </div>
          <div className='button-container'>
            <Button  className='back-btn' onClick={() => history.push(`/User/Profile/${userData.userInformation.publicId}`)}>Back</Button>
            {!loading && <Button variant='contained' className='nxt-btn' onClick={() => setConfirmChanges(true)}>Save</Button>}
            {loading && <CircularProgress disableShrink style={{margin: 'auto auto'}}/>}
          </div>
        </div>
    <InterestComponent 
      selectedInterests={userData&&userData['userInformation'].userInterestSet&&userData['userInformation'].userInterestSet.userInterests} 
      handleNext={handleSave} 
      skillCategories={allInterests&&allInterests} 
      profileCompletion={userData&&userData.userInformation.profileCompleted}
      confirmChanges={confirmChanges}
    />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  companyInformation:selectCompanyInformation
});

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditInterest));
