/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import {Typography,CircularProgress,Avatar,Menu,MenuItem} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './search-autoComplete-style.scss';

export default function SearchAutoComplete({navigation,results,loading,query,handleSearchQuery,handleChangeQuery}){

  const [users,setUsers] = useState([]);
  const [skills,setSkills] = useState([]);
  const [selectedOption,setSelectedOption] = useState('');

  useEffect(() => {
    if(results) {
      console.log(results);
      let userList = [];
      results.users.forEach(user => {
        let obj = {
          profilePhotoUrl: user.profilePhotoUrl,
          name: `${user.firstName} ${user.lastName}`
        }
        userList.push(obj);
      })
      let skillList = [];
      results.skills.forEach(skill => {
        let obj = {
          name: skill.skillName
        }
        skillList.push(obj);
      })
      setUsers(userList);
      setSkills(skillList);
    }
  },[results]);

  useEffect(() => {
    if((users.length > 0 || skills.length > 0) && (navigation !== null)) {
      if(navigation < users.length) {
        setSelectedOption(users[navigation].name);
        handleChangeQuery(users[navigation].name);
      }
      else{
        if(navigation !== null) {
          let index = navigation - users.length;
          setSelectedOption(skills[index].name);
          handleChangeQuery(skills[index].name);
        }else {
          setSelectedOption('');
          handleChangeQuery(null);
        }
      }
    }else{
      setSelectedOption('');
      handleChangeQuery(null);
    }
  },[navigation, users, skills]);

  function capitalize(input) {  
    try {
      let word = input;
      if(word) {
        var words = word.split(' ');  
        var CapitalizedWords = [];  
        let wordsTobeIgnored = ['and','the','of','on','are', 'a']; 
        words.forEach(element => {
            let currentElement = '';  
            if(element.split('').length > 1 && element.split('').includes('&')) {
              let temp = element.split[0];
              currentElement = element.split('')[0].toUpperCase() + '&' + element.split('')[2].toUpperCase();
            }else{
              currentElement = element;
            }
            if(currentElement === 'cpr') {
              CapitalizedWords.push('CPR');  
            }else if(currentElement === 'chp') {
              CapitalizedWords.push('CHP');  
            }else if(wordsTobeIgnored.includes(currentElement)) {
              CapitalizedWords.push(currentElement); 
            }else {
              CapitalizedWords.push(currentElement[0].toUpperCase() + currentElement.slice(1, currentElement.length));  
            }         
        });  
        return CapitalizedWords.join(' ');  
      }
    } catch (error) {
      console.log(error);
      return input;
    }
}  


  return(
    <div className='autoComplete-container'>
      {loading&&
        <div className='loader'>
          <CircularProgress color='primary'/>
        </div>
        }
      {!loading&&users.map(item =>
        <div 
          className='result-container' 
          style={{background: selectedOption === item.name && 'rgba(215,213,213,0.5)'}} 
          onClick={() => handleSearchQuery(item.name)} 
          onMouseOver={() => setSelectedOption(item.name)} 
        >
          {item.profilePhotoUrl!==''&&<Avatar src={item.profilePhotoUrl} alt='ppl' style={{cursor:'pointer',width:'40px',height:'40px'}} />}
          {item.profilePhotoUrl===''&& <Typography variant='subtitle1' className='initial-container'>
            {`${item.name.split(' ')[0].slice(0,1)}${item.name.split(' ')[1].slice(0,1)}`}
          </Typography>}
          <h4 className='result-text'>{item.name}</h4>
        </div>
      )}
      {query && skills.length > 0 && 
      <div style={{width:'100%',padding:'10px 5px 0 5px',display:'flex',borderBottom: '1px solid gray'}}>
        <SearchIcon/>
        <p style={{ opacity:'0.6', fontWeight:'500',margin:'0'}}>"{query}" in skills</p>
      </div>}
      {!loading&&skills.map(item =>
        <div 
          className='result-container' 
          style={{background: selectedOption === item.name && 'rgba(215,213,213,0.5)'}} 
          onClick={() => handleSearchQuery(capitalize(item.name))} 
          onMouseOver={() => setSelectedOption(item.name)}
        >
          <h4 className='result-text'>{capitalize(item.name)}</h4>
        </div>
      )}
    </div>
  )
}
