import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';

const ArticleBtn = ({ handleClick }) => {
    return (
        <Tooltip title="Knowledge" placement="left">
            <div className='sidebar-icon-container sidebar-cms'>
                <IconButton className='sidebar-icon' onClick={handleClick}>
                    <DescriptionIcon style={{ color: 'white' }} />
                </IconButton>
            </div>
        </Tooltip>
    )
}

export default ArticleBtn