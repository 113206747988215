/*jshint esversion:9*/

import React from "react";
import {
  makeStyles,
  CardActions,
  Button,
  CardActionArea,
  Chip,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import user from "../../../Assets/user.png";
import moment from "moment";
import "./search-result-card-style.scss";
import getDepartmentName from "../../../utils/getDepartmentName";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "28% 72%",
    width: "100%",
    height: "150px",
    margin: "5px 5px 5px 0",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  details: {
    display: "flex",
    // flexDirection: 'column',
  },
  content: {
    width: "100%",
    height: "148px",
    paddingBottom: "0!important",
    paddingTop: "0!important",
    paddingRight: "10px!important",
    minHeight: "148px",
    paddingLeft: "0!important",
    // flex: '1 0 auto',
  },
  cover: {
    cursor: "pointer",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  skillEndorsement: {
    display: "flex",
    marginTop: "15px",
  },
  cardActions: {
    position: "relative",
  },
  collabBtn: {
    backgroundColor: "#FFCC33!important",
    marginLeft: "auto!important",
    "& span": {
      color: "black",
      fontWeight: "600",
    },
  },
}));

export default function SearchResultCard({
  handleCardClick,
  name,
  active,
  lastActive,
  dp,
  title,
  handleCollaborate,
  level,
  owner,
  companyDepartments,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {dp && (
        <CardMedia
          onClick={() => handleCardClick()}
          className={classes.cover}
          component={() => {
            return (
              <div
                className="card-dp-container"
                onClick={() => handleCardClick()}
              >
                <Avatar src={dp} className="card-dp-avatar" />
              </div>
            );
          }}
        />
      )}
      {!dp && (
        <div
          className={classes.cover}
          style={{ padding: "20% 10%" }}
          onClick={() => handleCardClick()}
        >
          <Typography
            variant="h3"
            className="initial-container"
            style={{
              width: "100px",
              height: "100px",
              cursor: "pointer",
              textAlign: "center!important",
              display: "flex",
              justifyContent: "center",
              color: "white",
              background: "#FFCC33",
            }}
          >
            {name.split(" ").length > 0
              ? `${name.split(" ")[0].split("")[0]}${
                  name.split(" ")[1].split("")[0]
                }`
              : name.split()[0].split("")[0]}
          </Typography>
        </div>
      )}
      <CardContent className={classes.content}>
        <div className="search-card-content-container">
          <CardActionArea
            className={classes.cardActions}
            onClick={() => handleCardClick()}
          >
            <div className="search-left-grid">
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{ fontWeight: "600", width: "100%" }}
              >
                {name}
              </Typography>
              <Tooltip
                title={getDepartmentName(title, companyDepartments)}
                placement="top"
              >
                <p
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                    overflow: "hidden",
                    fontSize: "13px",
                    marginBottom: "0!important",
                  }}
                >
                  {getDepartmentName(title, companyDepartments)}
                </p>
              </Tooltip>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                style={{
                  paddingTop: "5px",
                  width: "90%",
                  textOverflow: "ellipsis",
                  whiteSpace: "noWrap",
                  overflow: "hidden",
                }}
              >
                {active
                  ? "User is active"
                  : lastActive !== null
                  ? `last active ${moment(lastActive * 1000).fromNow()}`
                  : "dont know"}
              </Typography>
              {level && (
                <div className="selected-expertise-level-container">
                  {level >= 0.333 && (
                    <label className="selected-expertise-card-level" />
                  )}
                  {level >= 0.667 && (
                    <label className="selected-expertise-card-level" />
                  )}
                  {level >= 0.8 && (
                    <label className="selected-expertise-card-level" />
                  )}
                  {level === 1 && (
                    <label className="selected-expertise-card-level" />
                  )}
                </div>
              )}
              {/* {level && 
                <Chip 
                  label={level===0.333?'Beginner':level===0.667?'Proficient':'Expert'} 
                  className='skill-level-chip'
                  style={{
                    width:'100px!important',
                    background:level===1?'rgba(102,204,255,0.5)':level===0.667?'rgba(255,204,51,0.5)':'rgba(238,129,58,0.5)',
                    color:'white',
                    height:'25px',
                    fontWeight:'400',
                    marginTop:'5px',
                    marginBottom:'auto',
                    borderRadius:'50px',
                    border: `2px solid ${level===1?'#66CCFF':level===0.667?'#FFCC33':'#EE813A'}`
                  }}
                />} */}
            </div>
          </CardActionArea>
          <div className="search-right-grid">
            {!owner && (
              <Button
                startIcon={<ForumOutlinedIcon />}
                className={classes.collabBtn}
                onClick={() => handleCollaborate()}
                style={{ alignSelf: "flex-end", color: "#707070" }}
              >
                Collaborate
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

// <div className={classes.skillEndorsement}>
// <Typography variant="subtitle1" style={{marginRight:'15px'}} component="p" >
//   Marketing
// </Typography>
// <Typography variant='body2' style={{color:'gray'}}><svg style={{color:'gray',marginRight:'2px'}} height="18" viewBox="0 0 192 192" width="18" xmlns="http://www.w3.org/2000/svg"><circle cx="96" cy="32" r="8"/><circle cx="184" cy="72" r="8"/><circle cx="8" cy="72" r="8"/><path d="m23.805 160h144.395l15.61-70.265a8 8 0 0 0 -12.248-8.391l-40.462 26.972-27.945-55.894a8 8 0 0 0 -14.31 0l-27.945 55.894-40.463-26.972a8 8 0 0 0 -12.247 8.391z"/><path d="m24 168v16a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-16z"/></svg>   3</Typography>
// </div>

{
  /* <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
          <CardActionArea       onClick={()=>handleCardClick()}>
        <Typography gutterBottom variant="subtitle1"  style={{fontWeight:'600',width:'90%',textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden'}}>
          {name}
        </Typography>
        <Typography variant='caption' color="textSecondary" component='span' style={{paddingTop:'5px',width:'90%',textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden'}}>{active?'User is active':lastActive!==null?`last active ${moment(lastActive*1000).fromNow()}`:'dont know'}</Typography>
          </CardActionArea>
          {title.length > 25 &&
          <Tooltip title={title} placement='top'>
            <p className={classes.title} style={{opacity:'0.6', width: '100%', textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden',fontSize:'13px',marginBottom:'0!important'}}>{`${title.slice(0,24)}...`}</p>
          </Tooltip> 
          }
          {title.length < 26 && <p className={classes.title} style={{opacity:'0.6', width: '100%', textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden',fontSize:'13px',marginBottom:'0!important'}}>{title}</p>}
        </div>
        <CardActions className={classes.cardActions}>
        {level && <Chip label={level===0.333?'Beginner':level===0.667?'Proficient':'Expert'} style={{width:'100px!important',background:level===1?'#66CCFF':level===0.667?'#FFCC33':'#EE813A',color:'white',height:'20px',fontWeight:'400',marginTop:'5px',marginBottom:'auto',borderRadius:'10px',marginLeft:'auto'}}/>}
          <Button startIcon={<ForumOutlinedIcon/>} className={classes.collabBtn}  onClick={()=>handleCollaborate()} style={{alignSelf:'flex-end', color:'#707070'}}>
            Collaborate
          </Button>
        </CardActions> */
}
