import React, { useState } from "react";
import {
  Avatar,
  Popover,
  ClickAwayListener,
  Button,
  Fade,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FileIcon from "../../../Assets/file-icon.png";
import Linkify from "linkify-react";
import CancelIcon from "@material-ui/icons/Cancel";
import "./style.scss";

function ChatBoxChat({ handleUserRouting, sender, message, dp, handleDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  const id = Boolean(anchorEl) ? `simple-popover-${message.id}` : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const imageTypes = [
    "jpg",
    "jpeg",
    "png",
    "tif",
    "tiff",
    "bmp",
    "gif",
    "eps",
    "raw",
    "cr2",
    "nef",
    "orf",
    "sr2",
  ];

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div
        className={`single-chat-container ${
          message.messageType === "sent" ? "chat-right" : "chat-left"
        }`}
      >
        <div
          className={`single-chat-avatar-text`}
          style={{
            justifyContent:
              message.messageType === "sent" ? "flex-end" : "flex-start",
          }}
        >
          {message.messageType === "recieved" && dp !== "" && (
            <Avatar
              src={dp}
              className="sender-dp"
              onClick={() => handleUserRouting()}
            />
          )}
          {message.messageType === "recieved" && dp === "" && (
            <Typography
              variant="subtitle2"
              onClick={() => handleUserRouting()}
              className="initial-container"
              style={{
                cursor: "pointer",
                textAlign: "center!important",
                display: "flex",
                justifyContent: "center",
                paddingTop: "4%",
                color: "white",
                background: "#FFCC33",
                width: "40px",
                height: "40px",
                margin: "auto 0",
              }}
            >
              {`${sender.split(" ")[0].slice(0, 1)}${sender
                .split(" ")[1]
                .slice(0, 1)}`}
            </Typography>
          )}
          <div
            className={`chat-text-container ${
              message.messageType === "sent" ? "text-sent" : "text-recieved"
            }`}
          >
            {message.contentType === "text" && (
              <Linkify
                className="chat-text"
                tagName="p"
                options={{ target: { url: "_blank" } }}
              >
                {message.body}
              </Linkify>
            )}

            {message.contentType !== "text" && (
              <div
                className={
                  message.messageType === "sent"
                    ? "attach-sent"
                    : "attach-recieved"
                }
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
              >
                <a href={message.body} target="_blank" download>
                  {mouseEntered && (
                    <Fade in={true} timeout={500}>
                      <div className="download-overlay">
                        <CloudDownloadIcon />
                      </div>
                    </Fade>
                  )}
                  <img
                    src={
                      imageTypes.includes(message.contentType)
                        ? message.body
                        : FileIcon
                    }
                    alt="attachment"
                    className="attachment-image"
                  />
                </a>
                {!imageTypes.includes(message.contentType) && (
                  <p style={{ textAlign: "center" }}>
                    {message.attachmentKey.split("/")[1]}
                  </p>
                )}
              </div>
            )}
            <div
              className={`chat-time-status ${
                message.messageType === "sent" ? "time-sent" : "time-recieved"
              }`}
            >
              <p>{message.displayTime}</p>
              {/* {message.messageType === 'sent' && 
                            <p>
                                {message.status}
                            </p>
                        } */}
              {/* {message.messageType === 'sent' && 
                            <p className='more-option' onClick={handleClick}>
                                more
                            </p>
                        } */}
            </div>
          </div>
        </div>
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ boxShadow: "none" }}
        >
          <Button
            style={{ textTransform: "none" }}
            startIcon={<DeleteIcon style={{ color: "red" }} />}
            onClick={() => {
              setAnchorEl(null);
              handleDelete(message.id);
            }}
          >
            Delete
          </Button>
        </Popover>
      </div>
    </ClickAwayListener>
  );
}

export default ChatBoxChat;
