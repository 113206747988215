/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import InfoEdit from "../info-edit/info-edit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import BusinessIcon from "@material-ui/icons/Business";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: "none!important",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    width: "48%",
  },
}));

export default function ExpModal({
  Open,
  handleExpModalClose,
  handleUpdate,
  selected,
  companyName,
  companyDepartments
}) {
  const classes = useStyles();
  const [details, setDetails] = useState({
    id: null,
    companyName: "",
    designation: "",
    department: "",
    startDate: null,
    endDate: null,
    description: "",
  });
  const [checked, setChecked] = useState(false);
  const [doneClick, setDoneClick] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    if(companyName) {
      setDetails(state => ({...state, companyName}))
    }
    if(companyDepartments) {
      setDepartments(companyDepartments)
    }
  }, [companyName, companyDepartments])

  useEffect(() => {
    try {
      if (selected) {
        // console.log(selected);
        setDetails({
          ...selected,
          startDate: selected.startDate.split("/").reverse().join("-"),
          endDate: selected.endDate.split("/").reverse().join("-"),
        });
        if (selected.endDate === "present") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      } else {
        setDetails({
          id: "",
          companyName,
          designation: "",
          department: "",
          startDate: null,
          endDate: null,
          description: "",
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [selected]);

  useEffect(() => {
    setDetails((prevValue) => {
      return {
        ...prevValue,
        endDate: checked ? "present" : null,
      };
    })
  }, [checked]);

  const [characterCountExceedError, setCharacterCountExceedError] =
    useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    // console.log(value);
    // if (name === "description" && value.length === 200) {
    //   return;
    // }
    if(name === 'department') {
      let departmentValue
      departmentValue = departments.filter(item => item.departmentDisplayName === value)[0]?.departmentPublicId ?? ''
      setDetails(state => ({...state, department: departmentValue}))
      // console.log('departmentValue-----------', departmentValue)
      return
    }
    if (name === "description" && value.length >= 200) {
      setDetails((prevValue) => {
        setCharacterCountExceedError(true);
        return {
          ...prevValue,
          [name]: value.substring(0, 200),
        };
      });
    } else if (name === "description" && value.length < 200) {
      setDetails((prevValue) => {
        setCharacterCountExceedError(false);
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setDetails(prevValue => {
        return{
          ...prevValue,
          [name]:value
        };
      });
    }
  };

  const handleDateChange = (value, name) => {
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: moment(value).format("yyyy-MM-DD"),
      };
    });
  };

  const handleSave = async () => {
    // if(departments.length === 1) {
    //   // console.log('departments[0].departmentPublicId', departments[0].departmentPublicId)
    //   await setDetails(state => ({...state, department: departments[0].departmentPublicId}))
    // }
    try {
      setDoneClick(true);
      if (
        details.designation === "" ||
        details.department === "" ||
        details.startDate === null ||
        details.endDate === null
      ) {
        return;
      } else {
        setDoneClick(false);
        var now = new Date();
        if (details.endDate !== "present") {
          if (
            now > new Date(details.startDate) &&
            now > new Date(details.endDate)
          ) {
            if (new Date(details.endDate) > new Date(details.startDate)) {
              setDateError(false);
              setDetails({
                id: "",
                companyName,
                designation: "",
                department: "",
                startDate: null,
                endDate: null,
                description: "",
              });
              await handleUpdate(details);
            } else {
              setDateError(true);
              return;
            }
          } else {
            setDateError(true);
            return;
          }
        } else {
          if (now > new Date(details.startDate)) {
            setDateError(false);
            setDetails({
              id: "",
              companyName,
              designation: "",
              department: "",
              startDate: null,
              endDate: null,
              description: "",
            });
            await handleUpdate(details);
          } else {
            setDateError(true);
            return;
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getDepartment = (depId) => {
    let value = ''
    // console.log('getDepartment', companyDepartments)
    const dep = departments.filter(item => item.departmentPublicId === depId)
    if(dep.length) {
      value = dep[0].departmentDisplayName
    }
    return value
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={Open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onBackdropClick={() => {
        handleExpModalClose();
      }}
    >
      <Slide direction="up" in={Open}>
        <div className={classes.paper}>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              className="skill-name"
              style={{ width: "100%" }}
            >
              <span
                style={{
                  position: "relative",
                  top: "15px",
                  fontWeight: "720",
                  fontSize: "1.3rem",
                }}
              >
                Work Experience
              </span>
            </Typography>
            <div style={{ borderBottom: "1.5px solid black" }}>
              <IconButton onClick={() => handleExpModalClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <br />
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              label="Company"
              name="companyName"
              value={details.companyName}
              type="text"
              icon={<BusinessIcon />}
              disabled
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              label="Designation"
              name="designation"
              value={details.designation}
              onChange={handleChange}
              error={
                doneClick && details.designation.replace(/\s/g, "").length < 1
              }
              placeholder="Ex. Manager (Required)"
              type="text"
              icon={<BusinessCenterIcon />}
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              label="Department"
              name="department"
              value={getDepartment(details.department)}
              onChange={handleChange}
              error={
                doneClick && details.department.replace(/\s/g, "").length < 1
              }
              placeholder="Select department"
              type="select"
              icon={<WorkOutlineIcon />}
              options={['Select department', ...departments.map(item => item.departmentDisplayName)]}
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              label="Start Date"
              placeholder=" (Required)"
              name="startDate"
              value={details.startDate}
              onChange={handleChange}
              error={(doneClick && details.startDate === null) || dateError}
              type="Date"
              views={["year", "month"]}
              icon={<CalendarTodayIcon />}
              dateChange={handleDateChange}
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              disabled={checked}
              placeholder=" (Required)"
              label="End Date"
              name="endDate"
              value={details.endDate}
              onChange={handleChange}
              error={(doneClick && details.endDate === null) || dateError}
              type="Date"
              views={["year", "month"]}
              icon={<CalendarTodayIcon />}
              dateChange={handleDateChange}
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  name="checkedA"
                  color="primary"
                />
              }
              label="I am currently working in this role"
            />
          </div>
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <InfoEdit
              label="Description"
              name="description"
              value={details.description}
              onChange={handleChange}
              multiline={true}
              type="text"
              placeholder="Tell us about your work in this role (200 characters max.)"
              icon={<EditIcon />}
              limitCharacters={true}
            />
            {characterCountExceedError && (
              <small style={{ color: "red", float: "right" }}>
                You have reached 200 character limit!
              </small>
            )}
          </div>
          <br />
          <div style={{ marginBottom: "15px", padding: "0 10px 0 10px" }}>
            <Button className="edit-btn" onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
