/*jshint esversion:9*/

import React from 'react';
import {Paper,Typography} from '@material-ui/core';
import './edit-company-option-style.scss';

function EditCompanyOption({img,text,onClick}){
  return(
    <Paper className='edit-company-option-btn' onClick={()=>onClick()}>
      <div className='option-btn-img'>
        <img src={img} alt='logo'/>
      </div>
      <Typography variant='h6'>{text}</Typography>
    </Paper>
  )
}

export default EditCompanyOption;
