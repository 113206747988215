
import {walkthroughActionTypes} from './walkthrough-types';

const INITIAL_STATE = {
  tourStart:false,
  triggerPoint: 0
};

const walkthroughReducer = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case walkthroughActionTypes.SET_TOUR_START:
    return{
      ...state,
      tourStart:action.payload
    };

    case walkthroughActionTypes.SET_TRIGGER_POINT:
    return{
      ...state,
      triggerPoint:action.payload
    };

    default:
    return state;
  }
};

export default walkthroughReducer;
