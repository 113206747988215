import React, { useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useHistory } from "react-router";
import Wrapper from "../wrapper";
import cmsAxios from "../../axios/authAxios";
import CustomTabs from "../CustomTabs";
import CustomTab from "../../util/tab";
import ArticleListItem from "../articleListItem";
import fetchRecommendedArticle from "../../redux/article/actions/fetch_recommendedArticle";
import fetchTrendingArticle from "../../redux/article/actions/fetch_trendingArticle";
import fetchBookmarkedArticle from "../../redux/article/actions/fetch_bookmarkArticle";
import Loading from "../loading";
import style from "./recommendedArticle.module.scss";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import getSynopsis from "../getSynopsis";

const RecommendedArticleWidget = (props) => {
  console.log("RecommendedArticleWidget props", props);
  console.log(props);
  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState(0);
  const TabPanel = (props) =>
    props.value === props.index ? props.children : null;
  // const [like, setLike] = useState({
  //   id: 1,
  //   liked: false
  // })
  // const [unLike, setUnLike] = useState({
  //   id: 1,
  //   unliked: false
  // })
  // const [bookmark, setBookMark] = useState({
  //   id: 1,
  //   bokkmarked: false
  // })
  // const [unBookmark, setUnBookMark] = useState({
  //   id: 1,
  //   bokkmarked: false
  // })
  // const handleLike = async (e, id) => {
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${id}/like`)
  //     console.log(response.data)
  //     setLike({
  //       id,
  //       liked: true
  //     })
  //     setUnLike({
  //       id,
  //       unliked: false
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // const handleUnLike = async (e, id) => {
  //   // const article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${id}/unlike`)
  //     console.log(response.data)
  //     setUnLike({
  //       id,
  //       unliked: true
  //     })
  //     setLike({
  //       id,
  //       liked: false
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // const handleBookmark = async (e, id) => {
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${id}/bookmark`)
  //     console.log(response.data)
  //     setBookMark({
  //       id,
  //       bokkmarked: true
  //     })
  //     setUnBookMark({
  //       id,
  //       bokkmarked: false
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // const handleDeleteBookmark = async (e, id) => {
  //   console.log(id)
  //   // let article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.delete(`/cms/article/${id}/bookmark`)
  //     console.log(response.data)
  //     setBookMark({
  //       id,
  //       bokkmarked: false
  //     })
  //     setUnBookMark({
  //       id,
  //       bokkmarked: true
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const handleBookmark = (e, id, setBookmark, current) => {
    let articles =
      currentTab === 0
        ? props.recommendedArticles
        : currentTab === 1
        ? props.trendingArticles
        : currentTab === 3
        ? props.bookmarkedArticles
        : props.recommendedArticles;
    let article = articles.find((article) => article.contentPublicId === id);
    if (!article) return console.log("no article found with id" + id);
    if (current) {
      cmsAxios
        .delete(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleLike = (e, id, setLike, current) => {
    let element = e.currentTarget;
    // if (currentTab == 0 && (!props.recommendedLoaded)) props.fetchRecommendedArticle();
    // if (currentTab == 1 && (!props.trendingLoaded)) props.fetchTrendingArticle();
    // if (currentTab == 2 && (!props.bookmarkedLoaded)) props.fetchBookmarkedArticle();
    let articles =
      currentTab === 0
        ? props.recommendedArticles
        : currentTab === 1
        ? props.trendingArticles
        : currentTab === 3
        ? props.bookmarkedArticles
        : props.recommendedArticles;
    const article = articles.find((article) => article.contentPublicId === id);
    if (!article) return console.log("no article found with id" + id);
    if (current) {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/unlike`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            // console.log(
            //   (element.querySelector("small").innerHTML =
            //     parseInt(element.querySelector("small").innerHTML) - 1)
            // );
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/like`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            console.log(
              (element.querySelector("small").innerHTML =
                parseInt(element.querySelector("small").innerHTML) + 1)
            );
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleDelete = (id) => {
    cmsAxios.delete(`/cms/article/:${id}`).then((response) => {
      props.fetchPublishedArticle();
    });
  };

  const handleSingleArticleClick = (e, id) =>
    history.push(`cms/${id}/singleArticle`);
  const getAllArticles = (articles) => {
    return articles.map((article) => {
      return (
        <ArticleListItem
          key={article.contentPublicId}
          onClick={handleSingleArticleClick}
          publishedOn={article.updatedAt}
          articleId={article.contentPublicId}
          author={article.user}
          articleTitle={article.title}
          articleContent={article.synopsis}
          articleCover={article.coverImageUrl}
          likesNo={article.likesCount}
          articleThumbnail={article.thumbnailImageUrl}
          commentNo={article.commentsCount}
          viewsNo={article.viewsCount}
          read={article.content}
          handleLike={handleLike}
          like={article.likedByMe}
          bookmark={article.bookmarkedByMe}
          handleBookmark={handleBookmark}
          share={true}
          comment={
            article.commentsCount > 0 && article.comments
              ? article.comments[0]
              : null
          }
          moreOptionHidden={true}
          // likes={article.likesCount}
          // commentNo={article.commentsCount}
          // viewNo={article.viewsCount}
          // comment={article.commentsCount > 0 && article.comments ? article.comments[0] : null}
          // likeClick={handleLike}
          // unLike={handleUnLike}
          // unBookMark={handleDeleteBookmark}
          // bookMarkClick={handleBookmark}
          // bookmarkedByMe={article.bookmarkedByMe || (article.contentPublicId === bookmark.id && bookmark.bokkmarked)}
          // likedByMe={article.likedByMe || (article.contentPublicId === like.id && like.liked)}
          // isUnliked={article.contentPublicId === like.id && unLike.unliked}
          // isUnBookmarked={article.contentPublicId === unBookmark.id && unBookmark.bokkmarked}
        />
      );
    });
  };

  React.useEffect(() => {
    if (currentTab == 0 && !props.recommendedLoaded)
      props.fetchRecommendedArticle();
    if (currentTab == 1 && !props.trendingLoaded) props.fetchTrendingArticle();
    if (currentTab == 2 && !props.bookmarkedLoaded)
      props.fetchBookmarkedArticle();
  }, [currentTab]);

  return (
    <Paper className="py-md my-lg" style={{ backgroundColor: "white" }}>
      <Typography
        variant="h6"
        style={{ fontWeight: 700 }}
        className={`mx-md mb-sm my-0`}
      >
        Recommended Reads
      </Typography>
      <CustomTabs value={currentTab} onChange={(_, val) => setCurrentTab(val)}>
        <CustomTab label="All" />
        <CustomTab label="Trending" />
        <CustomTab label="Saved" />
      </CustomTabs>

      <hr className={`my-0`} />

      <div className={`${style.articlesContainer}`}>
        <TabPanel value={currentTab} index={0}>
          {props.recommendedLoaded ? (
            props.recommendedArticles.length > 0 ? (
              getAllArticles(props.recommendedArticles)
            ) : (
              <p style={{ textAlign: "center", fontSize: "18px" }}>
                No Recommended Article
              </p>
            )
          ) : (
            <Loading />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {props.trendingLoaded ? (
            props.trendingArticles.length > 0 ? (
              getAllArticles(props.trendingArticles)
            ) : (
              <p style={{ textAlign: "center", fontSize: "18px" }}>
                No Trending Article
              </p>
            )
          ) : (
            <Loading />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          {props.bookmarkedLoaded ? (
            props.bookmarkedArticles.length > 0 ? (
              getAllArticles(props.bookmarkedArticles)
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                No Bookmarked Article
              </p>
            )
          ) : (
            <Loading />
          )}
        </TabPanel>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  recommendedArticles: [...state.articles.recommendedArticles],
  trendingArticles: [...state.articles.trendingArticles],
  bookmarkedArticles: [...state.articles.bookmarkedArticles],
  recommendedLoaded: state.articles.isRecommendedLoaded,
  trendingLoaded: state.articles.isTrendingLoaded,
  bookmarkedLoaded: state.articles.isBookmarkedLoaded,
  user: state.user,
});
const mapDispatchToProps = {
  fetchRecommendedArticle,
  fetchTrendingArticle,
  fetchBookmarkedArticle,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedArticleWidget);
