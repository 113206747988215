import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Logout from "../../logout";
import "./style.scss";

function TrendingSkills({ history }) {
  const [skillList, setSkillList] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    const gettingTrendingSkills = async () => {
      try {
        let getTrendingSkillsResult = await axios.post(
          `${process.env.REACT_APP_API_URL}user/skills/trendingSkills`,
          {},
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSkillList(getTrendingSkillsResult.data);
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response.message);
          if (error.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    };
    gettingTrendingSkills();
  }, []);

  function capitalize(input) {
    try {
      let word = input;
      if (word) {
        let currentInput = word;
        if (typeof input === "object") {
          if (
            input.skillName.toLowerCase() ===
            input.skillDisplayName.toLowerCase()
          ) {
            if (input.skillName !== input.skillDisplayName) {
              return input.skillDisplayName;
            } else {
              currentInput = input.skillDisplayName;
            }
          } else {
            return input.skillDisplayName;
          }
        }
        var words = currentInput.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              "&" +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        if (typeof input === "object") {
          return input.skillDisplayName;
        } else {
          return input;
        }
      }
    } catch (error) {
      console.log(error);
      if (typeof input === "object") {
        return input.skillDisplayName;
      } else {
        return input;
      }
    }
  }

  return (
    <Paper style={{ padding: "8% 0", height: "fit-content" }}>
      <div className="container-title">
        <Typography variant="h6" className="collab-section-title">
          Trending
        </Typography>
        {loading && <p>Loading...</p>}
        {!loading && (
          <div className="trending-skills-container">
            {skillList.map((item) => (
              <p
                onClick={() =>
                  history.push(`/User/Search?q=${item.skillName.toLowerCase()}`)
                }
              >
                #{capitalize(item.skillName)}
              </p>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
}

export default withRouter(TrendingSkills);
