import React from 'react';
import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import classes from './videoDialog.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Button from '../../../button';
// import Upload from '@material-ui/icons/Backup'
import { TextField } from '@material-ui/core';
// import PublishIcon from '@material-ui/icons/Publish';
import { useDropzone } from 'react-dropzone';

const UploadFileSelectDialog = ({
    open,
    urlRef,
    videoUrlError,
    setShowToolbar,
    handleClose,
    handleYoutube,
    videoUpload,
    imageUpload,
    pdfUpload,
    onDrop,
    selectedFileError,
    // onDrop,
    ...props
}) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    const getTitle = (type) => {
        switch (type) {
            case "video":
                return <h2>Upload Video</h2>
            case "image":
                return <h2>Upload Image</h2>
            case "document":
                return <h2>Upload Document</h2>
            default:
                return "Upload File"
        }
    }
    const handleBtnClick = () => {
        switch (open) {
            case "video":
                return videoUpload()
            case "image":
                return imageUpload()
            case "document":
                return pdfUpload()
            default:
                return "";
        }
    }
    const getSizeLimit = (type) => {
        switch (type) {
            case "video":
                return "25"
            case "image":
                return "25"
            case "document":
                return "25"
            default:
                return "Upload File"
        }

    }
    return (
        <Dialog
            open={!!open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // fullWidth
            maxWidth='md'
            onClose={handleClose}
        >
            <div className={`${classes.header}`}>
                {getTitle(open)}
                <IconButton className={`${classes.closeBtn}`} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.selected_file_error_wrapper}>{!!selectedFileError && <h4 className={classes.selected_file_error}>{selectedFileError}</h4>}</div>
            <div className={`${classes.content}`}>
                {open === 'video' ? (
                    <div className={classes.urlInputWrapper}>
                        <TextField
                            error={!!videoUrlError}
                            helperText={!!videoUrlError ? videoUrlError : ""}
                            variant="outlined"
                            placeholder="Enter video link here"
                            size="sm"
                            className={`${classes.urlInputField}`}
                            ref={urlRef}
                        />
                        <Button style={{ textTransform: "capitalize" }} onClick={handleYoutube} >
                            <small style={{ fontWeight: 900 }}>Save Link</small>
                        </Button>
                    </div>
                ) : null
                }
                <div  {...getRootProps({ onClick: event => event.stopPropagation() })} className={`${classes.drop_placeholder}`}>
                    <input {...getInputProps()} />
                    <OpenInBrowserIcon style={{ width: 50, height: 50 }} color="primary" />
                    <h4 style={{ margin: 0 }}>Drag and Drop Your File</h4>
                    <small>or</small>
                    <Button style={{ boxShadow: 'none' }} onClick={handleBtnClick} color="primary" variant="contained">Select file to upload</Button>
                    <small style={{ fontSize: "13px" }}>File size limit {getSizeLimit(open)}MB</small>
                </div>
            </div>
            {/* <DialogTitle id="alert-dialog-title">Add Video </DialogTitle>
            <DialogContent>
                <input style={{
                    width: '95%'
                }} placeholder='Insert youtube or vimeo link' type='text' id='yturl' ref={urlRef} />
                <IconButton size="small" onClick={videoUpload}>
                    <Upload fontSize="medium" />
                </IconButton>
                <p style={{
                    color: 'red'
                }}> {!!error ? error : null}</p>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: 'black' }} onClick={() => { setIsVideoDialogOpen(false); setShowToolbar(false) }} variant="contained" autoFocus>
                    Cancel
                </Button>
                <Button onClick={handleYoutube} style={{
                    backgroundColor: '#1E3F97',
                    color: 'white'
                }} variant="contained" autoFocus>
                    Save
                </Button>
            </DialogActions> */}
        </Dialog>
    )
}

export default UploadFileSelectDialog;