/*jshint esversion :9*/

import React,{useState} from 'react';
import {Button} from '@material-ui/core';
import Input from '../SubComponents/Input/Input';
import './add-default-points-style.scss';


function AddDefaultPoints({handleAdd}){

  const [details,setDetails] = useState({
    action:'',
    points:''
  })

  const handleChange = e => {
    const {value,name} = e.target;
    setDetails(prevValue => {
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  return(
    <div className='add-default-points-container'>
    <div className='add-default-points-form'>
      <Input label='Action Name' name='action' value={details.action} type='text' onChange={handleChange}/>
      <br/>
      <Input label='Value' name='points' value={details.points} type='number' onChange={handleChange}/>
      <br/>
      <Button variant='outlined' color='primary' onClick={()=>handleAdd(details)}>Add</Button>
    </div>
    </div>
  )
}

export default AddDefaultPoints;
