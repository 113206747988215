/*jshint esversion:9*/

import React from 'react';
import Experience from '../experience/experience-component';
import ContactInformation from '../contact-information/contact-information-component';
import BasicInformation from '../basic-information/basic-information-component';
import SocialEdit from '../social-edit/social-edit-component';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../redux/user/user-selector';


function EditProfileComponent({userData}) {
if(userData) {
  return(
    <div>
    <BasicInformation info={userData.userInformation} mode='edit'/>
    <Experience info={userData.userInformation} mode='edit' />
    <ContactInformation mode='edit'/>
    </div>
  )
}else{
  return(
    <div></div>
  )

}
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData
});

export default connect(mapStateToProps)(EditProfileComponent);
    // <SocialEdit/>
