import React, { useState, useEffect, useRef } from "react";
import BreadCrumbs from "../../util/breadCrumbs";
import moment from "moment";
import style from "./singleArticle.module.scss";
import PreviewArticle from "../../util/articlePreview";
import fetchSingleArticle from "../../redux/article/actions/fetch_single_article";
import clearCurrentArticle from "../../redux/article/actions/clear_current_article";
import Wrapper from "../../util/wrapper";
import Comment from "../../util/comment";
import CommentTextField from "../../util/commentTextField";
import UserWidget from "../../util/userWidget";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router";
import { fetchComments } from "../../redux/article/actions/fetch_comments";
import { saveComments } from "../../redux/article/actions/save_comments";
import { likePost, disLikePost } from "../../redux/article/article-actions";
import defaultCoverImage from "../../img/default-cover-image.png";
import cmsAxios from "../../axios/authAxios";
import getSkills from "./getSkills";
import Loading from "../../util/loading";
import logout from "../../../../../logout";
import "./style.scss";

const SingleArticle = (props) => {
  document.querySelector("body").style.backgroundColor = "white";
  const { articleId } = useParams();
  const history = useHistory();
  const wrapper = useRef(null);
  const [skills, setSkills] = useState(false);
  const [isSkillsLoading, setIsSkillsLoading] = useState(true);
  const [useWidgetCoordinate, setUseWidgetCoordinate] = useState({
    x: 0,
    y: 0,
  });
  const [commentValue, setCommentValue] = useState("");
  const [coAuthors, setCoAuthors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [userTagCounter, setUserTagCounter] = useState([]);
  const [userCounter, setUserCounter] = useState(false);
  const [skillTagCounter, setSkillTagCounter] = useState([]);
  const [skillCounter, setSkillCounter] = useState(false);
  const search = useLocation().search;
  const addViewCount = () => cmsAxios.post(`/cms/article/${articleId}/view`);
  const handleClick = (tagName) => history.push(`/User/Search?q=${tagName}`);
  const handleLoading = () => {
    if (!isSkillsLoading && props.contentPublicId) {
      setIsLoading(false);
    }
  };

  const getUserWidgetDetails = (author) => {
    if (!!author) {
      const { firstName, lastName, department, profilePhotoUrl, publicId } =
        author;
      return {
        userName: `${firstName} ${lastName}`,
        userDesignation: `${department}`,
        userProfile: `${profilePhotoUrl}`,
        authorId: publicId,
      };
    } else return {};
  };
  const bookmarkHandle = () => {
    cmsAxios
      .post(`/cms/article/${props.contentPublicId}/bookmark`)
      .then((response) => {
        if (response.data) {
          setIsBookmarked(!isBookmarked);
        }
      });
  };

  const handleCommentChange = (event) => {
    let value = event.target.value;
    setCommentValue(value);
    //detectTagging(value);
  };

  useEffect(() => {
    console.log(skillTagCounter);
  }, [skillTagCounter]);

  //function for custom auto-complete --- DO NOT DELETE

  // const detectTagging = value => {
  //   if(userCounter) {
  //     if(value[value.length - 1] !== " " && value[value.length - 1] !== '@') {
  //       setUserTagCounter(prevValue => {return [...prevValue, value[value.length - 1]]});
  //       return;
  //     } else{
  //       setUserCounter(false);
  //       setUserTagCounter([]);
  //     }
  //   }

  //   if(skillCounter) {
  //     if(value[value.length - 1] !== " " && value[value.length - 1] !== '#') {
  //       setSkillTagCounter(prevValue => {return [...prevValue, value[value.length - 1]]});
  //       return;
  //     } else{
  //       setSkillCounter(false);
  //       setSkillTagCounter([]);
  //     }
  //   }

  //   if(value.length > 1) {
  //     if(value[value.length - 1] === '@' && value[value.length - 2] === ' ') {
  //       setUserCounter(true);
  //       return;
  //     }

  //     if(value[value.length - 1] === '#' && value[value.length - 2] === ' ') {
  //       setSkillCounter(true);
  //       return;
  //     }
  //   } else{
  //     if(value[value.length - 1] === '@') {
  //       setUserCounter(true);
  //       return;
  //     }

  //     if(value[value.length - 1] === '#') {
  //       setSkillCounter(true);
  //       return;
  //     }
  //   }

  // }

  //function for custom auto-complete --- DO NOT DELETE

  const handleSkills = (id) => {
    getSkills(id).then((response) => {
      setSkills(response.data.map((skill) => skill.skillDisplayName));
      setIsSkillsLoading(false);
    });
  };
  const handleEnter = (event) => {
    // if (event.key === "Enter") {
    // let request = { comment: event.target.value };
    props.saveComments(articleId, { comment: commentValue });
    setCommentValue("");
    // }
  };
  const wrapperCallback = React.useCallback((wrapperEl) => {
    if (wrapperEl) {
      wrapper.current = wrapperEl;
      setUserWidgetCoordinates(wrapperEl);
    }
  }, []);
  const handleUserWidgetOnResize = () => {
    setUserWidgetCoordinates(wrapper.current);
  };
  const setUserWidgetCoordinates = (wrapper) => {
    if (wrapper) {
      let { x, y } = wrapper.getBoundingClientRect();
      setUseWidgetCoordinate({ x, y });
    }
  };
  useEffect(() => {
    if (!props.contentPublicId) props.fetchSingleArticle(articleId);
    addViewCount();
    window.addEventListener("resize", handleUserWidgetOnResize);

    return () => {
      document.querySelector("body").style.backgroundColor =
        "rgb(235, 248, 255)";
      window.removeEventListener("resize", handleUserWidgetOnResize);
      props.clearCurrentArticle();
    };
  }, []);

  useEffect(() => {
    if (props.bookmarkedByMe) setIsBookmarked(true);
    if (props.contentPublicId) handleSkills(props.contentPublicId);
    handleLoading();
    const scrollToComment = new URLSearchParams(search).get("scrollToComment");
    if (props.comments?.length && scrollToComment === "true") {
      document.getElementById("comment_heading").scrollIntoView();
    }
    if (props?.taggedCoAuthors) {
      setCoAuthors(props.taggedCoAuthors);
    }
  }, [props]);

  const handleSelect = (selection) => {
    setUserTagCounter([]);
    setSkillTagCounter([]);
    // if(selection.publicId) {

    // }
  };

  const navigateToUser = async (user) => {
    try {
      let query = user.split("_").join(" ");
      let userInfo = await cmsAxios.post(
        `${process.env.REACT_APP_API_URL}search/unknown`,
        { searchQuery: query }
      );
      history.push(`/User/Profile/${userInfo.data.users[0].publicId}`);
    } catch (error) {
      console.log(error);
      if (error.status && error.status.code === "401") {
        logout();
      }
    }
  };

  if (!props.contentPublicId) return <Loading />;
  return (
    <>
      <UserWidget
        isEdited={props.isEdited}
        bookmarkHandle={bookmarkHandle}
        isBookmarked={isBookmarked}
        {...getUserWidgetDetails(props.author)}
        offsetX={useWidgetCoordinate.x - 12}
        offsetY={useWidgetCoordinate.y}
        isLiked={
          props.likes &&
          props.likes.some(
            (item) =>
              item.user.publicId ===
              props.user.UserData.userInformation.publicId
          )
        }
        commentsCount={props.commentsCount}
        likes={props.likesCount}
        likePost={() =>
          props.likePost(
            articleId,
            props.user.UserData.userInformation.publicId
          )
        }
        disLikePost={() =>
          props.disLikePost(
            articleId,
            props.user.UserData.userInformation.publicId
          )
        }
        userId={
          props.user &&
          props.user.UserData &&
          props.user.UserData.userInformation &&
          props.user.UserData.userInformation.publicId
        }
        coAuthors={coAuthors}
        publishedOn={props.updatedAt}
        companyDepartments={props.user.companyDepartments}
      />
      <div style={{ width: 1000 }} className={`mx-auto mt-md`}>
        {" "}
        <BreadCrumbs />{" "}
      </div>
      <main className={`${style.wrapper} mt-lg`} ref={wrapperCallback}>
        <Wrapper
          className="py-0"
          style={{ width: "100%", backgroundColor: "white" }}
        >
          {props.coverImageUrl ? (
            <div
              className={`${style.cover_wrapper}`}
              style={{
                backgroundImage: `url("${props.coverImageUrl}")`,
              }}
            />
          ) : null}
          {skills && (
            <div className={`${style.tag_wrapper} my-sm`}>
              {skills.map((tag) => (
                <small className={`${style.tag}`}>
                  <span onClick={() => handleClick(tag)}>{`#${tag}`}</span>
                </small>
              ))}
            </div>
          )}
          {props.title ? (
            <h1 className={`${style.title} mt-sm`}>{props.title}</h1>
          ) : null}
          <PreviewArticle noPadding content={props.content} noHeader />
        </Wrapper>
        <div style={{ height: 1, backgroundColor: "black" }}></div>
        <div className={`${style.comment_wrapper} p-md`}>
          <h4 className={`my-0`} id={"comment_heading"}>
            Comment
          </h4>
          <CommentTextField
            userImage={
              props.user.UserData
                ? props.user.UserData.userInformation.profilePhotoUrl
                : null
            }
            commentValue={commentValue}
            setCommentValue={setCommentValue}
            handleChange={handleCommentChange}
            handleEnter={handleEnter}
            handleSent={handleEnter}
            userTagCounter={userTagCounter}
            skillTagCounter={skillTagCounter}
            onSelect={handleSelect}
          />
          {props.comments &&
            props.comments
              .slice(0)
              .reverse()
              .map((comment, i) => {
                return (
                  <Comment
                    key={i}
                    author={`${comment.user.firstName} ${comment.user.lastName}`}
                    comment={comment.comment}
                    profile={comment.user.profilePhotoUrl}
                    time={moment(comment.updatedAt).fromNow()}
                    navigateToAuthor={() =>
                      history.push(`/User/Profile/${comment.user.publicId}`)
                    }
                    navigateToUser={navigateToUser}
                    // companyDepartments={props.user.companyDepartments}
                  />
                );
              })}
        </div>
      </main>
    </>
  );
};
const mapStateToProps = (state) => ({
  ...state.articles.currentArticle,
  author: state.articles.currentArticle.user,
  isLoading: state.articles.isCurrentArticleLoaded,
  user: { ...state.user },
});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchComments: () => dispatch(fetchComments()),
    saveComments: (articleId, request) =>
      dispatch(saveComments(articleId, request)),
    likePost: (id, userId) => dispatch(likePost(id, userId)),
    disLikePost: (articleId, likeId) =>
      dispatch(disLikePost(articleId, likeId)),
    fetchSingleArticle: (id) => dispatch(fetchSingleArticle(id)),
    clearCurrentArticle: () => dispatch({ type: "CLEAR CURRENT ARTICLE" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleArticle);
