/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import BasicProfile from '../../../Components/basicProfile/basicProfile-component';
import UserPoints from '../../../Components/userPoints/userPoints-component';
import Loader from '../../../Components/SubComponents/loader/loader-component';
import EditProfileComponent from '../../../Components/editProfile/editProfile-component';
import EditInterest from '../../../Components/editInterest/editInterest-component';
import ChangePassword from '../../../Components/changePassword/changePassword-component';
import EditSkillsCategories from '../../../Components/editSkillsCategories/editSkillsCategories-component';
import ProfileCompletion from '../../../Components/profile-completion/profile-completion';
import ErrorBoundary from '../../../Components/error-boundary/error-boundary-component';
import axios from 'axios';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData,selectUserPoints} from '../../../redux/user/user-selector';
import {setCompanyInformation,setUserData} from '../../../redux/user/user-actions';
import Logout from '../../../logout';

function EditProfile({history,userData,setCompanyInformation,setUserData,userPoints}){
       

  const [loading,setLoading] = useState(false);
  const token = localStorage.getItem('bhyve-token');

  useEffect(()=>{
    try {
      const getCompanyInformation = async() => {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me/companyDetails`,{
          headers:{
            'Authorization': `Bearer ${token}`,
           'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
          }
        });
        return result;
      };
        async function gettingCompanyInformation() {
          try {
            let getCompanyInformationResult = await getCompanyInformation();
            setCompanyInformation(getCompanyInformationResult.data);
          } catch (err) {
            console.log(err);
            if(err.response && err.response.status === 401) {
              history.push('/logout');
            }
          }
        }
       gettingCompanyInformation();
    } catch (err) {
     console.log(err);
     if(err.response && err.response.status === 401) {
      history.push('/logout');
   }
    }
  },[token,setCompanyInformation ]);

  const handleChoose = (option) => {
    switch (option) {
      case 'profile':
        history.push('/User/EditProfile/Profile');
        break;
        case 'interest':
          history.push('/User/EditProfile/Interest');
          break;
        case 'password':
          history.push('/User/EditProfile/ChangePassword');
          break;
        case 'skills':
          history.push('/User/EditProfile/EditSkillsCategories');
          break;
      default:

    }
  };



  const handleProfilePicChange = async e => {
    try {
      setLoading(true);
      console.log('hit');
      let formData = new FormData();
      formData.append('image',e.target.files[0]);
      try {
        let uploadProfilePicResult = await uploadProfilePic(formData,e.target.files[0].type);
        if(uploadProfilePicResult){
          console.log(uploadProfilePicResult);
        const current = {
          ...userData,
          userInformation:uploadProfilePicResult.data
        };
          setUserData(current);
          setLoading(false);
          window.location.reload(true);
        }
      } catch (err) {
        console.log(err);
        if(err.response && err.response.status === 401) {
          history.push('/logout');
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if(err.response && err.response.status === 401) { 
        history.push('/logout');
     }
     setLoading(false);
    }
  };

  const uploadProfilePic = async (formData,type) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}user/me/profile-picture`,formData,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    });
    return result;
  };



  return(
    <div className='profile-container '>
    <ErrorBoundary>
    <Loader open={loading}/>
    <div className='profile-grid'>
      <Switch>
        <Route path='/User/EditProfile/Profile' exact component={EditProfileComponent}/>
        <Route path='/User/EditProfile/Interest' exact component={EditInterest}/>
        <Route path='/User/EditProfile/ChangePassword' exact component={ChangePassword}/>
        <Route path='/User/EditProfile/EditSkillsCategories' exact component={EditSkillsCategories}/>
      </Switch>
    </div>

</ErrorBoundary>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  userPoints:selectUserPoints
});

const mapDispatchToProps = dispatch => ({
  setUserData:object => dispatch(setUserData(object)),
  setCompanyInformation: object => dispatch(setCompanyInformation(object))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditProfile));

