import React from "react";
import OktaAuth from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import {
  Button,
  Container,
  Typography,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Snackbar from "../SubComponents/snackbar/snackbar";
//import CrossSiteTrackingModal from '../SubComponents/cross-site-tracking-modal/index';
import ForgotPasswordModal from "../SubComponents/forgotPassword-modal/index";
import axios from "axios";
import { RoutingUrl } from "../../Pages/routing-url";
import "./style.scss";
import Logo from "../../Assets/bhyveNewLogo.png";
import googleIcon from "../../Assets/google.png";
import windowsIcon from "../../Assets/windows.png";

export default withOktaAuth(
  class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.routingUrl = new RoutingUrl();
      this.state = {
        email: "",
        password: "",
        emailError: "",
        passwordError: "",
        sessionToken: null,
        forgotPassword: false,
        alertOpen: false,
        severity: "",
        message: "",
        crossSiteTracking: false,
        visibility: false,
        companyInfo: null,
      };

      this.oktaAuth = new OktaAuth({ issuer: props.issuer });

      this.submitLogin = this.submitLogin.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handleForgotPassword = this.handleForgotPassword.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
      this.handleAlertClose = this.handleAlertClose.bind(this);
      this.handleReload = this.handleReload.bind(this);
      this.handleVisibilityToggle = this.handleVisibilityToggle.bind(this);
      this.setCompanyInfoByName = this.setCompanyInfoByName.bind(this);
      this.leadToGoogleSignIn = this.leadToGoogleSignIn.bind(this);
      this.leadToAzureSignIn = this.leadToAzureSignIn.bind(this);
    }

    handleEmailChange(e) {
      this.setState({ email: e.target.value });
    }

    handlePasswordChange(e) {
      this.setState({ password: e.target.value });
    }

    async setCompanyInfoByName(name) {
      let getCompanyInfoResult = await axios.get(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/company/information?companyName=${name}`,
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      console.log(getCompanyInfoResult);
      this.setState({ companyInfo: getCompanyInfoResult.data });
    }

    componentDidMount() {
      let companyName = this.routingUrl.CompanyNameFromLoginUrl();
      if (companyName) {
        this.setCompanyInfoByName(companyName);
      }
    }

    submitLogin(e) {
      e.preventDefault();
      console.log(window.location.origin);
      const emailString = this.state.email;
      //const passwordString = this.state.password;
      const expression =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      const emailFormat = expression.test(String(emailString).toLowerCase());

      if (emailString !== "") {
        if (emailFormat !== true) {
          this.setState({ emailError: "Email is incorrect!" });
        } else {
          this.setState({ emailError: "" });
        }
      } else {
        this.setState({ emailError: "Email field cannot be empty!" });
      }
      if (this.state.password === "") {
        this.setState({ passwordError: "Password field cannot be empty!" });
      } else {
        this.setState({ passwordError: "" });
      }

      this.oktaAuth
        .signIn({
          username: this.state.email,
          password: this.state.password,
        })
        .then((res) => {
          const sessionToken = res.sessionToken;
          //debugger;
          console.log(sessionToken);
          this.setState(
            { sessionToken },
            // sessionToken is a one-use token, so make sure this is only called once
            () => this.props.authService.redirect({ sessionToken })
          );
        })
        .catch((err) => {
          console.log("Found an error", err.xhr.status);
          this.setState({
            ...this.state,
            message: "Invalid Credentials! Please try again!",
            severity: "error",
            alertOpen: true,
          });
        });

      // this.saveData(emailString, passwordString);
    }

    saveData(emailString, passwordString) {
      localStorage.setItem("loginEmail", emailString);
      localStorage.setItem("loginPassword", passwordString);
    }

    handleForgotPassword() {
      console.log("clicked");
      this.setState({
        ...this.state,
        forgotPassword: true,
      });
    }

    handleKeyPress(e) {
      if (e.keyCode === 13) {
        this.submitLogin(e);
      }
    }

    handleAlertClose() {
      this.setState({
        ...this.state,
        message: "",
        severity: "",
        alertOpen: false,
      });
    }

    handleReload() {
      window.location.reload(true);
    }

    handleVisibilityToggle() {
      this.setState({
        ...this.state,
        visibility: !this.state.visibility,
      });
    }

    leadToGoogleSignIn() {
      window.location =
        "https://dev-953032.okta.com/sso/saml2/0oa4383wbqAQs4hoB4x7";
    }

    leadToAzureSignIn() {
      window.location = `https://dev-953032.okta.com/oauth2/v1/authorize?idp=0oa44ub2dl4NrHJ6q4x7&client_id=0oa44uoegg9ORoRE54x7&response_type=id_token&response_mode=fragment&scope=openid profile email&redirect_uri=${window.location.origin}/implicit/callback&state=WM6D&nonce=YsG76jo`;
    }

    render() {
      if (this.state.sessionToken) {
        // Hide form while sessionToken is converted into id/access tokens
        return null;
      }

      return (
        // this.state.companyInfo &&
        <div className="background">
          <iframe
            src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
            style={{ display: "none" }}
          />
          <Snackbar
            Open={this.state.alertOpen}
            severity={this.state.severity}
            alert={this.state.message}
            handleClose={this.handleAlertClose}
          />
          <div className="login-main-container">
            <div className="login-branding-container">
              <h1>
                Welcome to{" "}
                {this.state.companyInfo &&
                this.state.companyInfo.companyDisplayName
                  ? this.state.companyInfo.companyDisplayName === "hindalcodemo"
                    ? "Hindalco"
                    : this.state.companyInfo.companyDisplayName
                  : "BHyve"}
                's Peer Learning Network
              </h1>
              <hr />
              <h2>Quote of the day</h2>
              <h3>
                "Teaching peers is one of the best ways to develop mastery."
              </h3>
              <h3>-Jeff Atwood, Co-Founder Stack Overflow</h3>
              <div className="bhyve-watermark-container">
                <Typography variant="body1" className="bhyve-watermark-text">
                  Powered&nbsp;By&nbsp;
                </Typography>
                <img src={Logo} alt="logo" className="bhyve-watermark-logo" />
              </div>
            </div>

            <div className="login-form-container">
              {this.state.companyInfo &&
              this.state.companyInfo.companyLogoDetails.length > 0 ? (
                <img
                  className="logo"
                  alt="logo"
                  src={
                    this.state.companyInfo.companyLogoDetails[
                      this.state.companyInfo.companyLogoDetails.length - 1
                    ].imageUrl
                  }
                  width="210"
                />
              ) : (
                <img className="logo" src={Logo} width="180" />
              )}
              <h2>Sign In</h2>
              <form style={{ width: "100%" }} onKeyDown={this.handleKeyPress}>
                <OutlinedInput
                  className="login-form-input"
                  id="outlined-adornment-password"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="Email Address"
                />
                <OutlinedInput
                  className="login-form-input"
                  id="outlined-adornment-password"
                  type={this.state.visibility ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  placeholder="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      {this.state.visibility ? (
                        <VisibilityOffIcon
                          style={{ color: "gray", cursor: "pointer" }}
                          onClick={this.handleVisibilityToggle}
                        />
                      ) : (
                        <VisibilityIcon
                          style={{ color: "gray", cursor: "pointer" }}
                          onClick={this.handleVisibilityToggle}
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  className="login-form-submit-btn"
                  onClick={this.submitLogin}
                >
                  Let's Go
                </Button>
              </form>
              <h4 onClick={this.handleForgotPassword}>
                Forgot password or email id?
              </h4>
              {(window.location.href.includes("http://localhost:5000") ||
                window.location.href.includes("https://my.bhyve.io/")) && (
                <div className="or-flex">
                  <hr className="or-left-rule" />
                  <span>OR</span>
                  <hr className="or-left-rule" />
                </div>
              )}
              {(window.location.href.includes("http://localhost:5000") ||
                window.location.href.includes("https://my.bhyve.io/")) && (
                <Button
                  variant="contained"
                  className="google-sign-in-btn"
                  startIcon={<img src={googleIcon} width="20" height="20" />}
                  onClick={this.leadToGoogleSignIn}
                >
                  Sign in with Google
                </Button>
              )}
              <br />
              {window.location.href.includes("https://test.bhyve.io/") && (
                <Button
                  variant="contained"
                  className="google-sign-in-btn azure-sign-in-btn"
                  startIcon={<img src={windowsIcon} width="20" height="20" />}
                  onClick={this.leadToAzureSignIn}
                >
                  Sign in with Azure
                </Button>
              )}
            </div>
          </div>
          <ForgotPasswordModal
            open={this.state.forgotPassword}
            handleClose={() =>
              this.setState({ ...this.state, forgotPassword: false })
            }
          />
        </div>
      );
    }
  }
);

//<Input className='emailInputField' type='text' value={this.state.email} onChange={this.handleEmailChange} label='Email' icon={<MailOutlineIcon/>} inputErrorMessage={this.state.emailError} inputErrorMessageStyle='errorMessage'/>
//<Input className='passwordInputField' type='password' value={this.state.password} label='Password' icon={<LockIcon/>} onChange={this.handlePasswordChange} inputErrorMessage={this.state.passwordError} inputErrorMessageStyle='errorMessage'/>

{
  /* <Container className='mainContainer'>
                    <div className='loginContainer'>
                        {this.state.companyInfo.companyLogoDetails.length > 0 && <img className='logo' alt='logo' src={this.state.companyInfo.companyLogoDetails[this.state.companyInfo.companyLogoDetails.length - 1].imageUrl} width='210'/>}
                        <Typography variant='caption' style={{color:'gray',fontSize:'16px'}}>Welcome to {this.state.companyInfo.companyDisplayName}'s Peer Learning Zone</Typography>
                        <form style={{margin:'50px auto 50px auto'}} onKeyDown={this.handleKeyPress}>
                            <FormControl className='emailInputField'  style={{width:'95%'}}>
                              <Input style={{paddingBottom:'10px'}} value={this.state.email} onChange={this.handleEmailChange} id='email-input' placeholder='Email'
                              startAdornment={<InputAdornment position="start"><MailOutlineIcon style={{color:'gray'}}/></InputAdornment>}
                               />
                            </FormControl>
                            <FormControl className='passwordInputField'>
                              <Input style={{paddingBottom:'10px'}} type={this.state.visibility ? 'text' : 'password'} value={this.state.password} onChange={this.handlePasswordChange} placeholder='Password' id='pass-input'
                              startAdornment={<InputAdornment position="start"><LockIcon style={{color:'gray'}}/></InputAdornment>}
                              endAdornment={
                                <InputAdornment position="end">
                                  <div style={{display:'flex',gap:'5px'}}>
                                    {this.state.visibility ? <VisibilityOffIcon style={{color:'gray',cursor:'pointer'}} onClick={this.handleVisibilityToggle}/> : <VisibilityIcon style={{color:'gray',cursor:'pointer'}} onClick={this.handleVisibilityToggle}/>}
                                    <Typography style={{color:'#EE813A',fontSize:'14.5px',borderLeft: '1px solid gray', padding:'2px 0 0 5px',cursor:'pointer'}} onClick={this.handleForgotPassword}>Forgot?</Typography>
                                  </div>
                                </InputAdornment>}
                               />
                            </FormControl>
                            <Button
                            className='loginButton'
                            text={false}
                            onClick = {this.submitLogin}>
                                Login
                            </Button>
                        </form>
                        <p style={{fontSize:'14px',textAlign:'center',margin:'0'}}>By continuing, you agree with our </p>
                        <p style={{fontSize:'14px',textAlign:'center',margin:'0'}}>Terms & Conditions</p>
                    </div>
                </Container> */
}
