/*jshint esversion:9*/
import React from 'react';
import { makeStyles,Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import comingSoonLogo from '../../../Assets/comingSoon.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RedeemPointsModal({open,handleClose}) {
  const classes = useStyles();


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <img src={comingSoonLogo} alt='comingSoon' style={{width:'400px'}}/>
            <Typography variant='h4' style={{fontWeight:'bold'}}>Coming Soon!</Typography>
            <Typography variant='caption'>Hold on! We are working on it.</Typography>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
