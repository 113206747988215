import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Tooltip,
  Divider,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./collab-request-style.scss";
import getDepartmentName from "./../../../utils/getDepartmentName";

function CollabRequest({
  details,
  handleAccept,
  handleDecline,
  loading,
  history,
  companyDepartments,
}) {
  const [skills, setSkills] = useState([]);
  const [unWrapped, setUnWrapped] = useState(false);

  useEffect(() => {
    if (details) {
      let list = details.collaborationSkill.map(
        (item) => item.skillDisplayName
      );
      setSkills(list);
    }
  }, [details]);

  return (
    <div className="collab-request-container">
      <div className="collab-request-info">
        <div className="collab-request-dp-details">
          {details.participants.profilePhotoUrl !== "" && (
            <Avatar
              className="collab-request-info-avatar"
              src={details.participants.profilePhotoUrl}
            />
          )}
          {details.participants.profilePhotoUrl === "" && (
            <Typography
              variant="h5"
              className="collab-request-info-initial"
              onClick={() => history.push(`Profile/${details.participants.id}`)}
              style={{
                cursor: "pointer",
                textAlign: "center!important",
                display: "flex",
                justifyContent: "center",
                color: "white",
                background: "#FFCC33",
              }}
            >
              {`${details.participants.firstName.slice(
                0,
                1
              )}${details.participants.lastName.slice(0, 1)}`}
            </Typography>
          )}
          <div className="collab-request-info-details">
            <h3>{`${details.participants.firstName} ${details.participants.lastName}`}</h3>
            {!!details.participants.department && (
              <p>
                {getDepartmentName(
                  details.participants.department,
                  companyDepartments
                )}
              </p>
            )}
          </div>
        </div>
        <div className="collab-request-buttons">
          {loading === details.collaborationPublicId && (
            <CircularProgress color="primary" />
          )}
          {loading !== details.collaborationPublicId && (
            <Button
              variant="contained"
              className="collab-request-buttons-btn reject-btn"
              onClick={() => handleDecline(details.collaborationPublicId)}
            >
              Reject
            </Button>
          )}
          {loading !== details.collaborationPublicId && (
            <Button
              variant="contained"
              className="collab-request-buttons-btn"
              onClick={() =>
                handleAccept(
                  details.collaborationPublicId,
                  details.participants.id
                )
              }
            >
              Accept
            </Button>
          )}
        </div>
      </div>
      <div className="collab-request-des-time">
        <div className="collab-request-des-details">
          {skills.length > 0 && (
            <h4>
              Wants to learn
              <Tooltip title={skills[0]} placement="top">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`Search/?q=${skills[0]}`)}
                >
                  {` #${skills[0]}`}
                </span>
              </Tooltip>
              {skills.length > 1 && (
                <Tooltip
                  title={
                    skills.length - 1 > 1
                      ? skills.slice(1, skills.length).join(",")
                      : skills[1]
                  }
                  placement="top"
                >
                  <span>{` and ${skills.length - 1} more`}</span>
                </Tooltip>
              )}
            </h4>
          )}
          {details.description && (
            <div className="description-container">
              <p
                className={
                  details.description.length > 150 && !unWrapped && "wrapped"
                }
              >
                {'"' + details.description + '"'}
              </p>
              {details.description.length > 150 && (
                <span onClick={() => setUnWrapped(!unWrapped)}>
                  {unWrapped ? "...See Less" : "...See More"}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="collab-request-info-time">
          <p>{moment(details.createdAt).fromNow()}</p>
        </div>
      </div>
      <br />
      <Divider />
      <br />
    </div>
  );
}

export default withRouter(CollabRequest);
