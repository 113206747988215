/*jshint esversion:9*/
/*jshint -W087*/

import {useEffect,useState} from 'react';

export default function DisplayDate(date){
      // ;
  const [New,setNew] = useState('');
  useEffect(()=>{
    const months = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
    let newDate = new Date(date).toLocaleDateString('en-GB');
    let dateArray = newDate.split('/');
    let newDay = dateArray[0].split('')[0]==='0'?dateArray[0].split('')[1]:dateArray[0];
    let newMonth = dateArray[1].split('')[0]==='0'?dateArray[1].split('')[1]:dateArray[1];
    let newYear = dateArray[2];
    setNew(`${newDay} ${months[newMonth]},${newYear}`);

  },[date]);
  return New;
}
