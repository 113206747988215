import React,{useState} from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function ClientListImageList({images}) {

    const [currentIndex,setCurrentIndex] = useState(0);

    return(
        images && 
        <div style={{display:'flex'}}>
            <ArrowLeftIcon style={{margin: 'auto 0', cursor:'pointer',color: currentIndex === 0 && 'gray'}} onClick={() => currentIndex !== 0 && setCurrentIndex(prevValue => {return prevValue - 1})}/>
                <img src={images[currentIndex].imageUrl} alt='logo' width='100'/>
            <ArrowRightIcon style={{margin: 'auto 0', cursor:'pointer',color: currentIndex === images.length - 1 && 'gray'}}  onClick={() => currentIndex !== images.length - 1 && setCurrentIndex(prevValue => {return prevValue + 1})}/>
        </div>
    )
}