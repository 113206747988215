import React, { useState, useEffect, useRef } from "react";
import defaultCoverImageUrl from "../../img/default-cover-image.png";
import style from "./newArticle.module.scss";
import Wrapper from "../../util/wrapper";
import ArticleEditor from "../../util/editor";
import PreviewArticle from "../../util/articlePreview";
import PublishDialog from "../../util/publishDialog";
import fetchUsers from "../../redux/users/actions/fetchUsers";
import searchUsers from "../../redux/users/actions/searchUsers";
import clearCurrentArticle from "../../redux/article/actions/clear_current_article";
import fetchSingleArticle from "../../redux/article/actions/fetch_single_article";
import setCurrentArticle from "../../redux/article/actions/set_current_article";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
// import Button from '@material-ui/core/Button'
// import EditIcon from '@material-ui/icons/Edit';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { useHistory, useParams } from "react-router-dom";
import CropDialog from "../../util/imageCrop/cropDialog";
import BreadCrumbs from "../../util/breadCrumbs";
import getSynopsis from "../../util/getSynopsis";
import publishArticle from "./util/publishArticle";
import createArticle from "./util/createArticle";
import updateArticle from "./util/updateArticle";
import moment from "moment";
import getArticle from "./util/getArticle";
import uploadImage from "./util/uploadImage";
import Loading from "../../util/loading";
import getSkillsId from "./util/getSkillsId";
import getSkillsName from "./util/getSkillsName";
import getAuthorsName from "./util/getAuthorsName";
const NewArticle = ({ article, ...props }) => {
  const { articleId: paramArticleId } = useParams();
  const autoUpdateInitDelay = 1000;
  const autoSaveStateUpdateTimeout = 6000;
  const history = useHistory();
  // article items
  const [id, setId] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const inputUpdateTimeout = React.useRef(null);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageKey, setCoverImageKey] = useState("");
  // synopsis
  const [synopsis, setSynopsis] = React.useState("");
  const [synopsisError, setSynopsisError] = React.useState(false);
  const handleSynopsis = (e) => {
    let value = e.currentTarget.value;
    setSynopsis(value);
    if (value.length < 141) {
      setSynopsisError(false);
      clearTimeout(inputUpdateTimeout.current);
      inputUpdateTimeout.current = setTimeout(
        () => setIsUpdated(true),
        autoUpdateInitDelay
      );
    }
    if (value.length > 141)
      setSynopsisError("Synopsis should not exceed 140 characters");
  };
  // const synopsis = React.useRef('');
  const [thumbnailImageUrl, setThumbnailImageUrl] = React.useState("");
  const [thumbnailImageKey, setThumbnailImageKey] = React.useState("");
  // skills
  const [skillsDisplayName, setSkillsDisplayName] = useState([]);
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [selectedSkillsDetails, setSelectedSkillsDetails] = React.useState([]);
  // selected author
  const [coAuthorValues, setCoAuthorValues] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = React.useState([]);
  const [selectedAuthorsDetails, setSelectedAuthorsDetails] = React.useState(
    []
  );
  // auto save
  const lastAutoSaveTimeStamp = React.useRef(null);
  const [autoSaveState, setAutoSaveState] = React.useState("Not Saved");
  // cover dialog
  const [coverDialogImage, setCoverDialogImage] = React.useState(null);
  const [dialogCoverDialogFileName, setCoverDialogFileName] = useState("");
  const [coverEditDialogIsOpen, setCoverEditDialogIsOpen] =
    React.useState(false);
  // publish dialog
  const [publishDialogThumbnailImage, setPublishDialogThumbnailImage] =
    React.useState("");
  const [publishDialogIsOpen, setPublishDialogOpen] = React.useState(false);
  const [skillsError, setSkillsError] = useState(false);
  // utility
  const [canPreview, setCanPreview] = React.useState(true);
  const handleCantPreview = () =>
    setCanPreview(!(title !== "" && content !== ""));
  const [hasError, setHasError] = React.useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isPreview, setIsPreview] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [canPublish, setPublish] = React.useState(false);
  const fileRef = useRef(null);
  const handleCanPublish = () => {
    title && content && selectedSkills.length > 0
      ? setPublish(true)
      : setPublish(false);
  };
  const setArticle = (article) => {
    // console.log(article)
    setId(article.contentPublicId);
    setTitle(article.title);
    setContent(article.content);
    setSynopsis(article.synopsis);
    setSelectedSkills(article.taggedSkills);
    setSelectedSkillsDetails(article.taggedSkillsDetails);
    setSkillsDisplayName(getSkillsName(article.taggedSkillsDetails));
    setSelectedAuthors(article.taggedCoAuthorsPublicId);
    setSelectedAuthorsDetails(article.taggedCoAuthors);
    setCoAuthorValues(getAuthorsName(article.taggedCoAuthors));
    setCoverImageUrl(article.coverImageUrl);
    setThumbnailImageUrl(article.thumbnailImageUrl);
  };
  const updateArticleNow = async (articleNewDetails) => {
    let article = {
      id,
      title,
      synopsis,
      content,
      coverImageUrl,
      coverImageKey,
      thumbnailImageUrl,
      thumbnailImageKey,
      selectedSkills,
      selectedAuthors,
    };
    Object.keys(articleNewDetails).forEach((key) => {
      article[key] = articleNewDetails[key];
    });
    // console.log("articleNewDetails",article)
    return await updateArticle({ ...article });
  };
  const closeCoverEditDialogOpen = () => setCoverEditDialogIsOpen(false);
  const openCoverEditDialogOpen = () => setCoverEditDialogIsOpen(true);
  const openPublishDialog = () => setPublishDialogOpen(true);
  const handlePreview = () => setIsPreview(true);
  const handleEdit = () => setIsPreview(false);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    clearTimeout(inputUpdateTimeout.current);
    inputUpdateTimeout.current = setTimeout(
      () => setIsUpdated(true),
      autoUpdateInitDelay
    );
  };

  const preventNewLine = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
  };
  // const handleThumbnailClick = () => setThumbnailUrl(null);
  const closePublishDialog = () => {
    setSkillsError(false);
    setPublishDialogOpen(false);
  };
  const handleCoverChange = (e) => {
    if (!e.target.files[0]) return;
    let file = e.target.files[0];
    e.target.value = "";
    setCoverDialogImage(URL.createObjectURL(file));
    setCoverDialogFileName(file.name);
    openCoverEditDialogOpen();
  };
  const handleCoverDialogImageChange = (image, imageName) => {
    uploadImage(image, imageName).then((response) => {
      setCoverImageUrl(response.data.url);
      setCoverImageKey(response.data.storeKey);
      setIsUpdated(true);
    });
  };
  // const changeCoAuthorSearch = (e) => console.log(e);
  const saveDraft = () => {
    updateArticle({
      id,
      title,
      content,
      synopsis,
      coverImageKey,
      coverImageUrl,
      thumbnailImageUrl,
      thumbnailImageKey,
      selectedSkills,
      selectedAuthors,
    }).then(() => {
      lastAutoSaveTimeStamp.current = moment();
      history.push(`/User/cms`);
    });
  };

  const removeCoverImage = (event) => {
    event.stopPropagation();
    setCoverImageUrl("");
    let coverImgUrl = "";
    updateArticle({
      id,
      title,
      content,
      synopsis,
      coverImageKey,
      coverImgUrl,
      thumbnailImageUrl,
      thumbnailImageKey,
      selectedSkills,
      selectedAuthors,
    }).then(() => {
      lastAutoSaveTimeStamp.current = moment();
    });
  };

  const handleEditorOnChange = (value, editor) => {
    setContent(value);
    clearTimeout(inputUpdateTimeout.current);
    inputUpdateTimeout.current = setTimeout(
      () => setIsUpdated(true),
      autoUpdateInitDelay
    );
  };
  const handleAutoSave = () => {
    setInterval(() => {
      if (lastAutoSaveTimeStamp.current !== null) {
        setAutoSaveState(
          `Saved ${moment().diff(
            lastAutoSaveTimeStamp.current,
            "minute"
          )} min ago`
        );
      }
    }, autoSaveStateUpdateTimeout);
  };

  const handleNext = () => {
    setSkillsError(false);
    if (thumbnailImageUrl) setPublishDialogThumbnailImage(thumbnailImageUrl);
    if (!thumbnailImageUrl) {
      if (coverImageUrl) setPublishDialogThumbnailImage(coverImageUrl);
    }
    openPublishDialog();
  };

  const handleThumbnailChange = async (e) => {
    if (!e.target.files[0]) return;
    setPublishDialogThumbnailImage(URL.createObjectURL(e.target.files[0]));
  };
  // const uploadImage = async (file, name) => {
  //   try {
  //     const result = new File([file], name, { type: file.type });
  //     const formData = new FormData();
  //     formData.append("file", result);
  //     const response = await authAxios.post('cms/upload', formData)
  //     setCoverImageUrl(response.data.url)
  //     setcoverImageKey(response.data.storeKey)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // const createArticle = async () => {
  //   const synopsis = getSynopsis(data)
  //   let body = {
  //     title,
  //     content: data || '',
  //     synopsis: synopsis || '',
  //     coverImageUrl,
  //     coverImageKey,
  //     thumbnailImageUrl: thumbnailUrl && thumbnailUrl,
  //     thumbnailImageKey: thumbnailKey && thumbnailKey
  //   }
  //   try {
  //     const response = await authAxios.post('cms/article', body)
  //     setId(response.data.contentPublicId)
  //     setIsRequestSent(true)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  const handleSave = async (update) => {
    if (!selectedSkills.length) {
      console.error("selected skill is empty");
      return setSkillsError(true);
    }
    if (synopsis === "") return setSynopsisError("Synopsis cannot be empty");
    if (synopsis.length > 140)
      return setSynopsisError("Synopsis should not exceed 140 characters");
    if (update) {
      let updateResponse = await updateArticle({
        id,
        title,
        content,
        synopsis,
        selectedSkills,
        selectedAuthors,
        coverImageUrl,
        coverImageKey,
        thumbnailImageUrl,
        thumbnailImageKey,
      });
      if (!updateResponse.data) return console.log("Error updating article");
    }
    let publishedResponse = await publishArticle(id);
    if (publishedResponse.data) history.push(`/User/cms`);
  };
  // useEffect(() => {
  //   (!!title && !!data) ? setCantPreview(false) : setCantPreview(true)
  //   const timeout = setTimeout(() => {
  //     if (!title && !data && !coverImageUrl && !coverImageKey) return
  //     if (id && !isUpdating) {
  //       const synopsis = getSynopsis(data)
  //       setIsUpdating(true);
  //       return updateArticle({ id, title, data, synopsis, selectedSkills, selectedAuthors, coverImageUrl, coverImageKey, thumbnailUrl, thumbnailKey })
  //         .then(() => {
  //           console.log('patch request sent updating auto save to latest time')
  //           lastAutoSaveTimeStamp.current = moment();
  //           setIsUpdating(false)
  //         })
  //     };
  //     if ((title || data || coverImageKey || coverImageUrl) && !id && !isUpdating) {
  //       if (window.location.pathname === '/User/cms/newArticle') {
  //         const synopsis = getSynopsis(data)
  //         setIsUpdating(true)
  //         createArticle({
  //           title, data, synopsis, coverImageKey, coverImageUrl, thumbnailKey, thumbnailKey
  //         })
  //           .then((response) => {
  //             setAutosaveState('saved');
  //             lastAutoSaveTimeStamp.current = moment();
  //             setId(response.data.contentPublicId)
  //             setIsUpdating(false)
  //           })
  //           .catch(error => console.log(error))
  //       }
  //       return;
  //     }
  //   }, 2000)
  //   return () => {
  //     clearTimeout(timeout)
  //   }
  // }, [title, data, coverImageUrl, coverImageKey, thumbnailUrl, selectedAuthors, selectedSkills])
  // React.useEffect(() => {
  //   if (article && Object.keys(article).length > 0) {
  //     setCoverImage(article.coverImageUrl);
  //     setCoverImageUrl(article.coverImageUrl);
  //     setTitle(article.title);
  //     setData(article.content);
  //     setSelectedSkills(article.taggedSkills);
  //     if (article.taggedCoAuthorsPublicId) setSelectedAuthors(article.taggedCoAuthorsPublicId);
  //   }
  // }, [article]);
  React.useEffect(() => {
    if (isUpdated && !isLoading) {
      handleCanPublish();
      if (!isCreated) {
        if (title !== "" && content !== "") {
          setIsUpdating(true);
          createArticle({
            title,
            content,
            synopsis,
            coverImageKey,
            coverImageUrl,
            thumbnailImageUrl,
            thumbnailImageKey,
            selectedSkills,
            selectedAuthors,
          })
            .then((response) => {
              if (response.data) {
                let { contentPublicId } = response.data;
                if (contentPublicId) {
                  setId(contentPublicId);
                  setIsCreated(true);
                  setAutoSaveState("Saved");
                  lastAutoSaveTimeStamp.current = moment();
                }
              }
            })
            .finally(() => {
              setIsUpdated(false);
              setIsUpdating(false);
            });
        } else {
          setIsUpdated(false);
        }
      } else if (isCreated && !isLoading) {
        setIsUpdating(true);
        updateArticle({
          id,
          title,
          content,
          synopsis,
          coverImageKey,
          coverImageUrl,
          thumbnailImageUrl,
          thumbnailImageKey,
          selectedSkills,
          selectedAuthors,
        })
          .then((response) => {
            lastAutoSaveTimeStamp.current = moment();
          })
          .finally(() => {
            setIsUpdated(false);
            setIsUpdating(false);
          });
      } else {
        setIsUpdated(false);
      }
    }
  }, [isUpdated]);
  React.useEffect(() => {
    if (paramArticleId && isLoading) {
      getArticle({ id: paramArticleId }).then((response) => {
        setIsCreated(true);
        setArticle(response);
        setCanPreview(true);
        setAutoSaveState("Saved");
        handleAutoSave();
        setIsLoading(false);
      });
    } else {
      handleAutoSave();
      setIsLoading(false);
    }
    return () => {
      props.clearCurrentArticle();
    };
  }, []);
  React.useEffect(() => {
    handleCantPreview();
    handleCanPublish();
    // console.log({
    //   id,
    //   title,
    //   content,
    //   synopsis,
    //   coverImageKey,
    //   coverImageUrl,
    //   thumbnailImageUrl,
    //   thumbnailImageKey,
    //   selectedSkills,
    //   selectedAuthors,
    //   selectedSkillsDetails,
    //   selectedAuthorsDetails,
    //   skillsDisplayName,
    // });
    // console.log(`-----------------------------`)
    // console.log(`is updated :${isUpdated}`)
    // console.log(`is updating :${isUpdating}`)
    // console.log(`can preview :${canPreview}`)
    // console.log(`is created :${isCreated}`)
    // console.log(`title :${title}`)
    // console.log(`content :${content}`)
    // console.log(`-----------------------------`)
  });
  if (!isLoading) {
    return (
      <div id="new_article_wrapper" className={`${style.wrapper} mt-lg`}>
        <BreadCrumbs />
        <div
          className={`${style.cover_wrapper}`}
          style={{
            backgroundImage: `url("${coverImageUrl ? coverImageUrl : ""}")`,
          }}
        >
          <label
            onClick={() => fileRef.current.click()}
            htmlFor=""
            style={!coverImageUrl ? { opacity: 1, color: "inherit" } : null}
          >
            <AddPhotoAlternateIcon fontSize="large" />
            <input
              ref={fileRef}
              id=""
              onChange={handleCoverChange}
              type="file"
            />
            <span>
              {coverImageUrl ? "Change Cover Picture" : "Add Cover Picture"}
            </span>
            <span style={{ color: "red" }} onClick={removeCoverImage}>
              {coverImageUrl ? <strong>Remove Cover Picture</strong> : null}
            </span>
          </label>
        </div>
        <div className={`${style.article_title}`}>
          <InputBase
            className={`${style.title}`}
            onChange={handleTitleChange}
            value={title}
            placeholder="Title"
            fullWidth
            multiline
            onKeyPress={preventNewLine}
          />
        </div>
        <Wrapper
          id="content_wrapper"
          className="py-0"
          style={{ width: "100%", backgroundColor: "white" }}
        >
          {isPreview ? (
            <PreviewArticle
              shadow={true}
              content={content}
              handleEdit={handleEdit}
              handleNext={handleNext}
            />
          ) : (
            <ArticleEditor
              lastSave={autoSaveState}
              cantPreview={canPreview}
              handleValue={content}
              onChange={handleEditorOnChange}
              saveDraft={saveDraft}
              handlePreview={handlePreview}
            />
          )}
        </Wrapper>
        <PublishDialog
          handleSave={handleSave}
          open={publishDialogIsOpen}
          handleClose={closePublishDialog}
          searchUsers={props.searchedUser}
          handleThumbnail={handleThumbnailChange}
          thumbnailUrl={thumbnailImageUrl}
          setThumbnailUrl={setThumbnailImageUrl}
          thumbnail={publishDialogThumbnailImage}
          setThumbnail={setPublishDialogThumbnailImage}
          thumbnailKey={thumbnailImageKey}
          setThumbnailKey={setThumbnailImageKey}
          uploadImage={uploadImage}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectedSkillsDetails={selectedSkillsDetails}
          setSelectedAuthors={setSelectedAuthors}
          selectedAuthorsDetails={selectedAuthorsDetails}
          setCoAuthorValues={setCoAuthorValues}
          selectedAuthors={selectedAuthors}
          createArticle={updateArticle}
          coverImage={coverImageUrl}
          skillsError={skillsError}
          synopsis={synopsis}
          setSynopsis={handleSynopsis}
          synopsisError={synopsisError}
          values={skillsDisplayName}
          setValues={setSkillsDisplayName}
          coAuthorValues={coAuthorValues}
          setIsUpdated={setIsUpdated}
          canPublish={canPublish}
          setSkillsError={setSkillsError}
          updateArticleNow={updateArticleNow}
          user={props.user}
        />
        <CropDialog
          open={coverEditDialogIsOpen}
          image={coverDialogImage}
          setImage={setCoverDialogImage}
          handleClose={closeCoverEditDialogOpen}
          uploadImage={handleCoverDialogImageChange}
          fileName={dialogCoverDialogFileName}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  article: state.articles.currentArticle,
  searchedUser: state.users.search,
  users: state.users.users,
  user: state.user,
});
const mapDispatchToProps = {
  fetchUsers,
  clearCurrentArticle,
  searchUsers,
  fetchSingleArticle,
  setCurrentArticle,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewArticle);
