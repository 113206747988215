import React,{useState} from 'react';
import FilePreviewPopup from '../FilePreviewPopup'

const PdfLink = ({url, text})=>{
    const [openModal,setOpenModal] = useState(false)
    const viewPdf = (e)=>{
        e.preventDefault();
        setOpenModal(true)
    }
    return (
      <>
        <FilePreviewPopup open={openModal} setOpen={setOpenModal} url={url} />
        <a
          href={url}
          target="_blank"
          data-type="application/pdf"
          onClick={viewPdf}
        >
          {text}
        </a>
      </>
    );
}
export default PdfLink;