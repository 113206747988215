/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import WelcomeText from "../createProfile-welcomeText/welcomeText";
import TitleText from "../createProfile-titleText/titleText";
import SubText from "../createProfile-subText/subText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "./interestModal-style.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 800,
    minHeight: 500,
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    position: "relative",
  },
  skillsContainer: {
    padding: "20px",
    border: "1px solid #D5D5D5",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    textAlign: "left",
    columnGap: "10px",
    rowGap: "10px",

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#7C7C7C",
      borderRadius: "10px",
    },
  },
  close: {
    position: "absolute",
    top: "0",
    right: "0",
    margin: "10px",
  },
  categoryName: {
    textAlign: "left",
    fontWeight: "bold",
  },
}));

export default function InterestSkillsModal({
  categoryDisplay,
  category,
  open,
  handleClose,
  skills,
  selected,
  handleSelect,
  handleSelectAll,
  hideSelectAllCheckbox,
}) {
  const classes = useStyles();
  const [selectAllCategories, setSelectAllCategories] = useState([]);
  const [organizedSkillsList, setOrganizedSkillsList] = useState([]);

  const sortAlphabetically = (originalArray) => {
    return originalArray.sort(function (a, b) {
      var firstItem = a.skillName.toUpperCase();
      var secondItem = b.skillName.toUpperCase();
      return firstItem < secondItem ? -1 : firstItem > secondItem ? 1 : 0;
    });
  };

  useEffect(() => {
    if (open) {
      let selectedSkillList = [];
      let unSelectedSkillList = [];
      if (skills.length > 0) {
        skills.forEach((skill) => {
          if (
            !!skill.selected ||
            (selected && selected.includes(skill.skillName))
          ) {
            selectedSkillList.push(skill);
          } else {
            unSelectedSkillList.push(skill);
          }
        });
        selectedSkillList = sortAlphabetically(selectedSkillList);
        Array.prototype.push.apply(selectedSkillList, unSelectedSkillList);
        setOrganizedSkillsList(selectedSkillList);
      }
    }
  }, [open]);

  const handleSelectSkill = (skill) => {
    handleSelect(skill);
    let list = [];
    list = organizedSkillsList.map((item) => {
      if (item.selected && skill.skillName === item.skillName) {
        item.selected = false;
      }
      return item;
    });
    setOrganizedSkillsList(list);
  };

  const handleSelectAllChange = (e) => {
    let current = selectAllCategories;
    if (!selectAllCategories.includes(categoryDisplay)) {
      current.push(categoryDisplay);
    } else if (selectAllCategories.includes(categoryDisplay)) {
      current = current.filter((item) => item !== categoryDisplay);
    }
    if (current.includes(categoryDisplay)) {
      handleSelectAll(true);
    } else {
      let organizedlist = [];
      organizedlist = organizedSkillsList.map((item) => {
        item.selected = false;
        return item;
      });
      setOrganizedSkillsList(organizedlist);
      handleSelectAll(false);
    }
    setSelectAllCategories(current);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="up" in={open}>
          <div className={classes.paper}>
            <Typography variant="subtitle1" className={classes.categoryName}>
              {categoryDisplay}
            </Typography>
            {category && (
              <IconButton
                className={classes.close}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            )}
            {category && <h2 style={{ fontWeight: "bold" }}>{category}</h2>}
            <div
              className={classes.skillsContainer}
              style={{ overflowY: "auto", maxHeight: "480px" }}
            >
              {selected &&
                organizedSkillsList.map((item, index) => (
                  <Button
                    className={
                      (item.selected || selected.includes(item.skillName)) &&
                      "skill-selected"
                    }
                    onClick={() => handleSelectSkill(item)}
                    style={{
                      justifyContent: "left",
                      textAlign: "left",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                    key={index}
                  >
                    {item.skillDisplayName}
                  </Button>
                ))}
              {!selected &&
                skills.map((item, index) => (
                  <Button
                    style={{
                      justifyContent: "left",
                      textAlign: "left",
                      textTransform: "none",
                      fontSize: "1rem!important",
                    }}
                    key={index}
                  >
                    {item}
                  </Button>
                ))}
            </div>
            <div className="bottom-section">
              {!hideSelectAllCheckbox && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={selectAllCategories.includes(categoryDisplay)}
                      name="checkedC"
                      onChange={handleSelectAllChange}
                    />
                  }
                  label="Select All"
                />
              )}
              {selected && (
                <Button onClick={() => handleClose()} className="edit-btn">
                  OK
                </Button>
              )}
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
