export const viemo = (editorRef,id) => {
    editorRef.current.insertContent(`
            <iframe src="https://player.vimeo.com/video/${id}" width="500" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            `);
  };

 export const youtube = (editorRef,id)=>{
    editorRef.current.insertContent(`
    <iframe src='https://www.youtube.com/embed/${id}'
    frameborder='0'
    allow='autoplay; encrypted-media'
    allowfullscreen
    width="500"
    height="360"
    title='video'/>`)
}

export const viemoId = (url)=>{
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    if(match){
        return match[3]
    } else {
        return null;
    }

}

export const youtubeId =(url)=> {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return null;
    }
}