export default (departmentId, departmentList) => {
  if (Array.isArray(departmentList)) {
    const firstMatch = departmentList.find(
      (item) => item.departmentPublicId === departmentId
    );
    if (!!firstMatch) {
      return firstMatch.departmentDisplayName;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
