/*jshint esversion:9*/
/*jshint -W087*/

import React, { useEffect, useState } from "react";
import SkillTab from "../SubComponents/skillTab/skillTab-new-component";
import SkillModal from "../SubComponents/skillModal/skillModal-component";
import Snackbar from "../SubComponents/snackbar/snackbar";
import { connect } from "react-redux";
import { setUserData } from "../../redux/user/user-actions";
import Loader from "../SubComponents/loader/loader-component";
import Logout from "../../logout";
import "./skillSection-style.scss";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Fade, Button } from "@material-ui/core";

function PrimarySkills({
  skillCategories,
  handleNext,
  history,
  userSkillSet,
  setUserData,
  setNewSkills,
}) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("bhyve-token");

  //this useEffect is called to set the 'selectedSkills' useState if user already have skills saved
  useEffect(() => {
    if (userSkillSet) {
      setSelectedSkills(userSkillSet);
    }
    return () => {
      window.scrollTo(0, 0);
    };
  }, [userSkillSet]);

  const addUserSkills = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/skills`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const updateUserSkills = async (data) => {
    // const token = localStorage.getItem("bhyve-token");
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/skills`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  //handles saving/updating user skillset
  const handleSave = async () => {
    console.log(selectedSkills);
    if (selectedSkills.length > 4) {
      console.log(selectedSkills);
      let identical = false;
      if (userSkillSet) {
        //check if there is any change in the already saved userSkillSet
        if (selectedSkills.length === userSkillSet.length) {
          selectedSkills.forEach((item, index) => {
            let identicalItem = userSkillSet.find(
              (i) => i.skillPublicId === item.skillPublicId
            );
            if (identicalItem) {
              if (JSON.stringify(identicalItem) === JSON.stringify(item)) {
                identical = true;
              } else {
                identical = false;
              }
            } else {
              identical = false;
            }
          });
        }
      }
      if (!identical) {
        const object = {
          userSkills: selectedSkills,
        };
        console.log(object);
        setLoading(true);
        if (userSkillSet) {
          try {
            let updateUserSkillsResult = await updateUserSkills(object);
            if (updateUserSkillsResult) {
              console.log(updateUserSkillsResult);
              const current = {
                userInformation: updateUserSkillsResult.data,
              };
              setUserData(current);
              setLoading(false);
            }
          } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 401) {
              history.push("/logout");
            }
            setLoading(false);
          }
        } else {
          try {
            let addUserSkillsResult = await addUserSkills(object);
            if (addUserSkillsResult) {
              console.log(addUserSkillsResult);
              const current = {
                userInformation: addUserSkillsResult.data,
              };
              setUserData(current);
              setLoading(false);
            }
          } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 401) {
              history.push("/logout");
            }
            setLoading(false);
          }
        }
        return;
      }
      handleNext(selectedSkills);
    } else {
      setAlert("Please select atleast 5 skills");
      setSeverity("warning");
      setAlertOpen(true);
    }
  };

  const setNewSkillList = (list) => {
    if (list.length > 0) {
      setSelectedSkills(list);
      setNewSkills(list);
    }
  };

  return (
    <Fade in={true} timeout={2000}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Loader open={loading} />
        <div className="skill-container">
          <Snackbar
            Open={alertOpen}
            alert={alert}
            severity={severity}
            handleClose={() => setAlertOpen(false)}
          />
          <SkillTab
            userCategories={userSkillSet && userSkillSet}
            allCategoriesAndSkills={
              skillCategories && skillCategories.skillCategories
            }
            setNewSkillList={setNewSkillList}
          />
          <br />
          <br />
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "30px" }}
          >
            <Button className="btn" onClick={handleSave}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </Fade>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
});

export default withRouter(connect(null, mapDispatchToProps)(PrimarySkills));
