/*jshint esversion:9*/

import React from 'react';
import {SwipeableDrawer,IconButton,Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShoutoutTab from '../shoutout/shoutout-component';
import Leaderboard from '../leaderboard/leaderboard-component';
import NotificationPanel from '../notifications/notifications-component';
import './side-drawer-style.scss';

function SideDrawer({Open,handleClose,option, setUnreadNotificationsCount}){
  return(
    <React.Fragment         style={{width:'0!important',zIndex:'900'}}>
      <SwipeableDrawer
        anchor='right'
        open={Open}
        swipeAreaWidth={0}
        onClose={() => {
          if(option === 'notifications') {
            setUnreadNotificationsCount();
          }
          handleClose();
        }}
      >
        <div
          className='drawer'
          role="presentation"
        >
        <div style={{position:'fixed',height:'100%', width: '350px'}}>
        <div style={{width:'100%',display:'flex'}}>
        <IconButton onClick={()=>{
          if(option === 'notifications') {
            setUnreadNotificationsCount();
          }
          handleClose();}}>
          <CloseIcon/>
        </IconButton>
        <Typography variant='h6' style={{width:'100%',paddingTop:'10px',textAlign:'center',paddingRight:'20px',fontWeight:'bold'}}>{option==='buzzBox'?'BuzzBox':option==='notifications'?'Notifications':'Leaderboard'}</Typography>
        </div>
        {option==='buzzBox'?
        <ShoutoutTab handleClose={()=>handleClose()}/>:
        option==='notifications'?
        <NotificationPanel/>:
        <Leaderboard handleClose={()=>handleClose()}/>
        }
        </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>

  )
}

export default SideDrawer;

// <ShoutoutTab/>
