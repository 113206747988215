/*jshint esversion:9*/

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LeadedrboardWeeklyReport from '../leaderboard-weekly-report/leaderboard-weekly-report-component';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height:'100%',width:'100%'}}
    >
      {value === index && (
        <Box p={3} className='shoutout-tab-panel-box'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height:'100%',
  },
}));

export default function Leaderboard({handleClose}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className='appBar' style={{width:'350px!important',height:'98%'}}>
        <Tabs
        className='skillTab'
        value={value}
        onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Current Week" {...a11yProps(0)} />
          <Tab label="Last Week" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0} >
          <LeadedrboardWeeklyReport currentWeek={true} handleClose={()=>handleClose()}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LeadedrboardWeeklyReport currentWeek={true} handleClose={()=>handleClose()}/>
        </TabPanel>
      </AppBar>

    </div>
  );
}
