/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import DisplayDate from "../displayDate/displayDate";
import {
  makeStyles,
  Typography,
  OutlinedInput,
  IconButton,
  Avatar,
  InputAdornment,
  Link,
  Popover,
  Button,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./shoutout-modal-style.scss";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import SendIcon from "@material-ui/icons/Send";
import ShoutoutReply from "../shoutout-reply/shoutout-reply";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../../redux/user/user-selector";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { withRouter } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";

const Item = ({ entity: { name } }) => {
  return (
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "96%",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          padding: "0 5px 0 5px",
        }}
      >{`${name}`}</span>
    </div>
  );
};

const User = ({ entity: { profilePhotoUrl, firstName, lastName } }) => (
  <div
    style={{
      display: "flex",
      padding: "0 5px 0 5px",
    }}
  >
    <Avatar src={profilePhotoUrl} style={{ width: "30px", height: "30px" }} />
    <span
      style={{
        marginLeft: "10px",
      }}
    >{`${firstName} ${lastName}`}</span>
  </div>
);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function ShoutoutModal({
  Open,
  history,
  handleClose,
  data,
  userData,
  sendComment,
  loadingComments,
  handleLike,
  handleDeleteComment,
  handleVerification,
  navigateToAuthor,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [postData, setPostData] = useState(null);
  const [comment, setComment] = useState({
    id: null,
    text: "",
  });
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    try {
      if (data) {
        setPostData(data);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, [data]);

  const New = DisplayDate(postData && postData.createdAt);

  useEffect(() => {}, [postData]);

  const handleChange = (e) => {
    const { value } = e.target;
    let currentValue = value;

    if (currentValue.length === 250) {
      return;
    }

    if (currentValue.length > 250) {
      currentValue = currentValue.slice(0, 249);
    }

    setComment((prevValue) => {
      return {
        ...prevValue,
        text: currentValue,
      };
    });
  };

  const handleComment = () => {
    console.log(comment);
    if (comment.text !== "") {
      setComment({
        id: null,
        text: "",
      });
      sendComment(comment, postData.shoutoutPublicId, taggedUsers);
    }
  };

  const addEmoji = (e) => {
    if (comment.text.length === 200) {
      return;
    }
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setComment((prevValue) => {
      return { ...prevValue, text: prevValue.text + emoji };
    });
    setEmojiAnchorEl(null);
  };

  const handleOpenEmojiMenu = (e) => {
    if (!emojiAnchorEl) {
      setEmojiAnchorEl(e.currentTarget);
    } else {
      setEmojiAnchorEl(null);
    }
  };

  const handleEmojiMenuClose = () => {
    setEmojiAnchorEl(null);
  };

  const handleEditComment = (id) => {
    let current = postData.comments.find(
      (comment) => comment.commentPublicId === id
    );
    setComment({
      id: id,
      text: current.text,
    });
  };

  const handleLikeComment = (id) => {
    let current = postData.comments.find((item) => item.commentPublicId === id);
    handleLike("COMMENTS", id, current.likes, postData.shoutoutPublicId);
  };

  const getAutoCompleteSuggestions = async (key) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/autocomplete/skills?skillName=${key}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const getAutoCompleteUserSuggestions = async (name) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/username/autocomplete?name=${name}&limit=3`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const handleSelected = (e) => {
    let current = taggedUsers;
    console.log(current);
    current.push(e.item);
    console.log(current);
    setTaggedUsers(current);
  };

  const navigateToUser = (user, taggedUsers) => {
    try {
      let taggedUser = taggedUsers.find(
        (item) =>
          item.firstName === user.split("_")[0] &&
          item.lastName === user.split("_")[1]
      );
      if (taggedUser) {
        history.push(`/User/Profile/${taggedUser.publicId}`);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (comment.text !== "") {
        setComment({
          id: null,
          text: "",
        });
        sendComment(comment, postData.shoutoutPublicId, taggedUsers);
      }
    }
  };

  function capitalize(input) {
    try {
      let word;
      if (input.split("").includes("_")) {
        word = input.replaceAll("_", " ").slice(1);
      } else {
        word = input.slice(1);
      }
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            //debugger;
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              " & " +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={Open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onBackdropClick={() => {
        setPostData(null);
        handleClose();
      }}
    >
      <Fade in={Open} mountOnEnter unmountOnExit>
        <div className="shoutout-modal-container">
          {loading && <div>Loading...</div>}
          {error && (
            <div style={{ textAlign: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bolder" }}>
                Some error occured while loading this shoutout !
              </Typography>
              <Button
                color="primary"
                onClick={() => {
                  setPostData(null);
                  handleClose();
                }}
              >
                Go Back
              </Button>
            </div>
          )}
          {postData && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  className="skill-name"
                  style={{ width: "100%" }}
                >
                  <span
                    style={{
                      position: "relative",
                      top: "15px",
                      fontWeight: "720",
                      fontSize: "1.3rem",
                    }}
                  >
                    BuzzBox
                  </span>
                </Typography>
                <div style={{ borderBottom: "1.5px solid black" }}>
                  <IconButton
                    onClick={() => {
                      setPostData(null);
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div className="shoutout-sender-info-container">
                {postData.profilePhotoUrl !== "" && (
                  <Avatar
                    className="sender-img"
                    src={postData.profilePhotoUrl}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateToAuthor()}
                  />
                )}
                {postData.profilePhotoUrl === "" && (
                  <Typography
                    variant="subtitle1"
                    className="initial-container"
                    onClick={() => navigateToAuthor()}
                    style={{
                      cursor: "pointer",
                      textAlign: "center!important",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                      color: "white",
                      background: "#FFCC33",
                      width: "55px",
                      height: "55px",
                    }}
                  >
                    {postData.firstName &&
                      `${postData.firstName.slice(
                        0,
                        1
                      )}${postData.lastName.slice(0, 1)}`}
                    {!postData.firstName &&
                      `${postData.name.split(" ")[0].slice(0, 1)}${postData.name
                        .split(" ")[1]
                        .slice(0, 1)}`}
                  </Typography>
                )}
                <div style={{ paddingLeft: "15px" }}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {postData.firstName &&
                      `${postData.firstName} ${postData.lastName}`}
                    {!postData.firstName &&
                      `${postData.name.split(" ")[0]} ${
                        postData.name.split(" ")[1]
                      }`}
                  </Typography>
                  <Typography variant="subtitle2">{New}</Typography>
                </div>
              </div>
              <Typography
                variant="body1"
                style={{ width: "100%", marginBottom: "20px" }}
              >
                {postData.text.split(" ").map((item) =>
                  item.split("")[0] === "#" ? (
                    <Link
                      href={`/User/Search?q=${
                        item.split("").includes("_")
                          ? item.replaceAll("_", " ").slice(1)
                          : item.slice(1)
                      }`}
                      style={{
                        color: "#EE813A",
                        padding: "0 5px 0 5px",
                        cursor: "pointer",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      {`#${capitalize(item)}`}
                    </Link>
                  ) : item.split("")[0] === "@" ? (
                    <span
                      className="userLink"
                      onClick={() =>
                        navigateToUser(
                          item.slice(1),
                          postData.taggedUsersPublicId
                        )
                      }
                      style={{
                        color: "#000",
                        padding: "0 5px 0 5px",
                        fontWeight: "600",
                      }}
                    >
                      {`${
                        item.split("").includes("_")
                          ? item.replace("_", " ").slice(1)
                          : item.slice(1)
                      }`}
                    </span>
                  ) : (
                    `${item} `
                  )
                )}
              </Typography>
              <div className="post-actions">
                <div className="post-action-button">
                  <Tooltip title="Like">
                    <IconButton
                      button
                      onClick={() =>
                        handleLike(
                          "SHOUTOUTS",
                          postData.shoutoutPublicId,
                          postData.likes
                        )
                      }
                    >
                      <ThumbUpIcon
                        color={
                          postData.likes.find(
                            (like) =>
                              like.author === userData.userInformation.publicId
                          )
                            ? "primary"
                            : ""
                        }
                      />
                    </IconButton>
                  </Tooltip>

                  <Typography
                    variant="body1"
                    style={{ marginLeft: "2px", paddingTop: "8px" }}
                  >
                    {postData.likes && postData.likes.length}
                  </Typography>
                </div>
                <div className="post-action-button">
                  <Tooltip title="Reply">
                    <IconButton>
                      <ReplyOutlinedIcon
                        color={
                          postData.comments.find(
                            (comment) =>
                              comment.author ===
                              userData.userInformation.publicId
                          )
                            ? "primary"
                            : ""
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    variant="body1"
                    style={{ marginLeft: "2px", paddingTop: "8px" }}
                  >
                    {postData.comments && postData.comments.length}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "10% 76% 7% 7%",
                  marginBottom: "20px",
                }}
              >
                {userData.userInformation.profilePhotoUrl !== "" && (
                  <Avatar
                    src={userData.userInformation.profilePhotoUrl}
                    style={{ cursor: "pointer", marginTop: "5px" }}
                  />
                )}
                {userData.userInformation.profilePhotoUrl === "" && (
                  <Typography
                    variant="h6"
                    className="initial-container"
                    onClick={() => navigateToAuthor()}
                    style={{
                      cursor: "pointer",
                      textAlign: "center!important",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "7%",
                      color: "white",
                      background: "#FFCC33",
                      width: "45px",
                      height: "45px",
                    }}
                  >
                    {userData.userInformation.firstName &&
                      `${userData.userInformation.firstName.slice(
                        0,
                        1
                      )}${userData.userInformation.lastName.slice(0, 1)}`}
                    {!userData.userInformation.firstName &&
                      `${userData.userInformation.name
                        .split(" ")[0]
                        .slice(0, 1)}${userData.userInformation.name
                        .split(" ")[1]
                        .slice(0, 1)}`}
                  </Typography>
                )}
                <ReactTextareaAutocomplete
                  loaderStyle={{
                    position: "relative",
                    top: "20px",
                  }}
                  containerStyle={{
                    position: "relative",
                    zIndex: "1",
                    borderColor: "yellow",
                    borderRadius: "10px",
                  }}
                  listStyle={{
                    width: "250px",
                    marginTop: "10px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    zIndex: "10",
                    borderRadius: "2px",
                    padding: "5px 0 5px 0",
                    backgroundColor: "white",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  dropdownStyle={{
                    zIndex: "10",
                    position: "absolute",
                    bottom: "10px",
                    backgroundColor: "white",
                    width: "100px",
                  }}
                  itemClassName="list-item"
                  ref={(rta) => {
                    ShoutoutModal.rta = rta;
                  }}
                  innerRef={(textarea) => {
                    ShoutoutModal.textarea = textarea;
                  }}
                  value={comment.text}
                  className="response-text-area"
                  placeholder="Type your response here... (250 characters max.)"
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  onItemSelected={handleSelected}
                  loadingComponent={() => <span>Loading</span>}
                  trigger={{
                    "#": {
                      dataProvider: async (token) => {
                        let skillNames = [];
                        if (token.length >= 3) {
                          if (token.includes("_")) {
                            token = token.split("_").join(" ");
                          }
                          skillNames = await getAutoCompleteSuggestions(
                            token
                          ).then((res) => {
                            return res.data;
                          });
                          skillNames = skillNames.map((item) => {
                            return { name: item.skillName };
                          });
                          skillNames = skillNames.map((item) =>
                            item.name.split("").includes(" ")
                              ? { name: item.name.replace(/ /g, "_") }
                              : item
                          );
                          console.log(skillNames);
                          console.log(skillNames);
                          return skillNames;
                        }
                      },
                      component: Item,
                      output: (item, trigger) => `#${item.name}`,
                    },
                    "@": {
                      dataProvider: async (token) => {
                        let userNames = [];
                        if (token.length >= 3) {
                          if (token.includes("_")) {
                            token = token.split("_").join(" ");
                          }
                          userNames = await getAutoCompleteUserSuggestions(
                            token
                          )
                            .then((res) => {
                              console.log(res.data);
                              return res.data;
                            })
                            .catch((err) => console.log(err));
                          userNames = userNames.map((item) => {
                            return { ...item };
                          });
                          //skillNames = skillNames.map(item => item.name.split('').includes(" ")?{name:item.name.replace(/\s+/, '_')}:item);
                          console.log(userNames);
                          return userNames;
                        }
                      },
                      component: User,
                      output: (item, trigger) =>
                        `@${item.firstName}_${item.lastName}`,
                    },
                  }}
                />
                <InsertEmoticonIcon
                  style={{ cursor: "pointer", margin: "10px 10px 0 10px" }}
                  aria-describedby="emoji-popover"
                  onClick={handleOpenEmojiMenu}
                />
                <SendIcon
                  onClick={handleComment}
                  style={{ cursor: "pointer", margin: "10px 0 0 10px" }}
                />
              </div>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Replies
              </Typography>
              <div className="replies-container">
                {loadingComments && "Loading Comments......"}
                {postData.comments &&
                  postData.comments
                    .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                    .reverse()
                    .map((item, index) => (
                      <ShoutoutReply
                        users={
                          item.taggedUsersPublicId && item.taggedUsersPublicId
                        }
                        navigateToUser={navigateToUser}
                        navigateToAuthor={() =>
                          history.push(`/User/Profile/${item.author}`)
                        }
                        name={`${item.firstName} ${item.lastName}`}
                        img={item.profilePhotoUrl}
                        comment={item.text}
                        time={moment(item.createdAt).fromNow()}
                        key={index}
                        shoutoutOwned={
                          userData &&
                          userData.userInformation.publicId === postData.author
                        }
                        commentOwned={
                          userData &&
                          userData.userInformation.publicId === item.author
                        }
                        verified={item.verified}
                        handleDelete={() =>
                          handleDeleteComment(item.commentPublicId)
                        }
                        handleVerification={() =>
                          handleVerification(
                            item.commentPublicId,
                            postData.shoutoutPublicId,
                            item.verified
                          )
                        }
                        handleEdit={() =>
                          handleEditComment(item.commentPublicId)
                        }
                        handleLike={() =>
                          handleLikeComment(item.commentPublicId)
                        }
                        liked={
                          item.likes &&
                          item.likes.find(
                            (item) =>
                              item.author === userData.userInformation.publicId
                          )
                        }
                      />
                    ))}
              </div>
            </div>
          )}
          <Popover
            id={Boolean(emojiAnchorEl) ? "emoji-popover" : undefined}
            open={Boolean(emojiAnchorEl)}
            anchorEl={emojiAnchorEl}
            onClose={handleEmojiMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Picker onSelect={addEmoji} />
          </Popover>
        </div>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default withRouter(connect(mapStateToProps)(ShoutoutModal));
