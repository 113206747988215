const RoutingUrlForLoginApp = () => {
    let routingUrlForLoginApp = '';
    try{
        const currentMainAppUrl = window.location.href;
        const basicLoginUrl = process.env.REACT_APP_API_URL_LOGIN;
        const basicMainAppUrl = process.env.REACT_APP_API_URL_MAIN;

        if( currentMainAppUrl.includes('localhost') )
            routingUrlForLoginApp = basicLoginUrl;
        else{
            let currentMainAppSplitUrl = currentMainAppUrl.split("://");
            let currentMainAppDomain = currentMainAppSplitUrl[1];
            let basicLoginSplitUrl = basicLoginUrl.split("://");
            let basicLoginDomain = basicLoginSplitUrl[1];
            let basicMainAppSplitUrl = basicMainAppUrl.split("://");
            let basicMainAppDomain = basicMainAppSplitUrl[1];
            let extraDomainAdded = currentMainAppDomain.split(basicMainAppDomain)[0];
            routingUrlForLoginApp = `${currentMainAppSplitUrl[0]}://${extraDomainAdded}${basicLoginDomain}`;
        }
        return routingUrlForLoginApp;
    }catch(error) {
        console.log(error);
        return routingUrlForLoginApp;
    }
}

class RoutingUrl {
    ExtraDomainAddedInLoginUrl = () => {
        try{
            const currentLoginUrl = window.location.href;
            const basicLoginUrl = process.env.REACT_APP_API_URL_LOGIN;
            let currentLoginSplitUrl = currentLoginUrl.split("://");
            let currentLoginDomain = currentLoginSplitUrl[1];
            let basicLoginSplitUrl = basicLoginUrl.split("://");
            let basicLoginDomain = basicLoginSplitUrl[1];
            let extraDomainAdded = currentLoginDomain.split(basicLoginDomain)[0];
            return extraDomainAdded;
        }catch(error) {
            console.log(error);
            return '';
        }
    }

    CompanyNameFromLoginUrl = () => {
        let companyNameFromLoginUrl = "";
        try{
            if(window.location.href.includes('localhost')) {
                companyNameFromLoginUrl = 'bhyve';
            }else if(window.location.href.includes('gcp-dev')){
                companyNameFromLoginUrl = 'bhyve'
            } else {
                let extraDomainAddedInLoginUrl = this.ExtraDomainAddedInLoginUrl();
                let extraDomainAddedInLoginSplitUrl = extraDomainAddedInLoginUrl.split(".");
                if(extraDomainAddedInLoginSplitUrl.length === 2)
                    companyNameFromLoginUrl = "bhyve";
                else
                    companyNameFromLoginUrl = extraDomainAddedInLoginSplitUrl[0];
            }
            return companyNameFromLoginUrl;
        }catch(error) {
            console.log(error);
            return companyNameFromLoginUrl;
        }
    }

    RoutingUrlForMainApp = () => {
        let routingMainAppUrl = '';
        try{
            const currentLoginUrl = window.location.href;
            const basicMainAppUrl = process.env.REACT_APP_API_URL_MAIN;

            if( currentLoginUrl.includes('localhost') )
                routingMainAppUrl = basicMainAppUrl;
            else{
                let extraDomainAdded = this.ExtraDomainAddedInLoginUrl();
                let basicMainAppSplitUrl = basicMainAppUrl.split("://");
                let basicMainAppDomain = basicMainAppSplitUrl[1];
                routingMainAppUrl = `${currentLoginUrl.split("://")[0]}://${extraDomainAdded}${basicMainAppDomain}`;
            }
            return routingMainAppUrl;
        }catch(error) {
            console.log(error);
            return routingMainAppUrl;
        }
    }
}


export {RoutingUrlForLoginApp,RoutingUrl};