/*jshint esversion:9*/

import React from 'react';
import {Typography,IconButton} from '@material-ui/core';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectBadges} from '../../redux/badges/badges-selector';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './badges-list-style.scss';

function BadgesList({badges,handleUpdateModal,handleDelete}){
  return(
    <div className='badges-list'>
    <div className='badges-list-container'>
      {badges&&badges.map((item,index)=>
        <div className='badge-container'>
        <div className='badge-container-top'>
          <div>
                    <img src={item.badgeImageUrl} alt={item.badgeImageKey} className='badge-img'/>
                              <Typography variant='h6'>{item.badgeName}</Typography>
          </div>
          <div>
            <IconButton button onClick={()=>handleUpdateModal(item)}>
              <EditIcon/>
            </IconButton>
            <IconButton button onClick={()=>handleDelete(item)}>
              <DeleteIcon/>
            </IconButton>
          </div>
        </div>
        </div>
      )}
    </div>
  </div>
  )
}

const mapStateToProps = createStructuredSelector({
  badges:selectBadges
});

export default connect(mapStateToProps,null)(BadgesList);
