/*jshint esversion:9*/

import React from 'react';
import {Paper,Typography,List,ListItem,ListItemText,ListItemSecondaryAction,IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import './skill-category-info-style.scss';

const ListItemTextMemo = React.memo(ListItemText);

function SkillCategoryInfo({data,handleAddSkill,handleDelete,handleCategoryDelete,handleCategoryEdit}){

  const handleEdit = (skillName,skillId,categoryId) => {
    const data = {
      skillName,
      skillId,
      categoryId,
    };
    handleAddSkill(data,'edit');
  };
  return(
    <Paper className='skill-category-info-container'>
    <div className='skillCategory-title'>
      <Typography color='primary' variant='h6' style={{fontWeight:'bold',maxWidth:'180px'}}>{data.skillCategory.skillCategoryDisplayName}
      </Typography>
      <div style={{display:'flex',flexWrap:'wrap'}}>
      <IconButton button onClick={()=> handleAddSkill(data,'add')}>
        <AddCircleIcon/>
      </IconButton>
      <IconButton button onClick={()=> handleCategoryEdit(data)}>
        <EditIcon/>
      </IconButton>
      <IconButton button onClick={()=>handleCategoryDelete(data.skillCategory.publicId)}>
        <DeleteIcon/>
      </IconButton>
      </div>
    </div>
      <List>
      <div className='skills-container'>
        {data.skills&&data.skills.map((item)=>
          <ListItem key={item.publicId}>
            <ListItemTextMemo primary={item.skillDisplayName} style={{width:'20%'}}/>
            <ListItemSecondaryAction>
            <IconButton button onClick={()=>handleEdit(item.skillDisplayName,item.skillPublicId,data.skillCategory.publicId,)}>
              <EditIcon/>
            </IconButton>
              <IconButton button onClick={()=>handleDelete(item.skillPublicId,data.skillCategory.publicId)}>
                <DeleteIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </div>
      </List>
    </Paper>
  )
}

export default SkillCategoryInfo;
