import React,{useEffect,useState} from 'react';
import {Paper,Fade,Typography} from '@material-ui/core';
import Badges from '../userBadges/userBadges-component';
import Points from '../userPoints/userPoints-component';
import UserRank from '../userRank/userRank-component';
import axios from 'axios'
import './three-in-one-style.scss';

export default function ThreeInOne({badges,user,currentUser}) {

    const [points, setPoints] = useState(0);
    const [rank, setRank] = useState('');

    const token = localStorage.getItem('bhyve-token');

    useEffect(() => {
        console.log(badges);
    },[badges])

    useEffect(() => {
        async function getRank() {
            const result = await axios.post(`${process.env.REACT_APP_API_URL_POINTS}points/rank`,{userPublicId: currentUser},{
                headers:{
                  'Authorization': `Bearer ${token}`,
                  'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                }
              });
              setPoints(result.data.points);
              setRank(result.data.rank);
        }
        getRank();
    },[currentUser]);

    return (
        <Fade in={true} timeout={2000}>
            <Paper className='three-in-one-container'>
                <div className='title-container'>
                    <Typography variant='h6'>{user && 'My'} Ranking and Score</Typography>
                    {user && <p>Increase your score by updating your skills, interests etc.</p>}
                </div>
                <div className='three-in-one-grid'>
                    <Points points={points} user={user}/>
                    <Badges badges={badges && badges} user={user} currentUser={currentUser}  />
                    <UserRank rank={rank}/>
                </div>
            </Paper>
        </Fade>
    )
}