import React from 'react';
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Paper from '@material-ui/core/Paper';
import { Picker } from "emoji-mart"
const Emoji = ({ el, open, handleClose, handleEmojiOnClick }) => {
    if (!el && !open) return null;
    return (
        <Popper anchorEl={el} open={open}>
            <ClickAwayListener onClickAway={handleClose}>
                <Paper>
                    <Picker onClick={handleEmojiOnClick} />
                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}

export default Emoji;