/*jshint esversion:9*/
/*jshint -W087*/

import {useEffect,useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import  Logout from '../../../logout';
import { useFormControl } from '@material-ui/core';

export default function BuzzBoxInfiniteScroll(skip,tab){

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(false);
  const [buzzes,setBuzzes] = useState([]);
  const [hasMore,setHasMore] = useState(false);

  useEffect(()=>{
    try {
      if(tab===0){
        if(skip===0){
          setBuzzes([]);
        }
        setLoading(true);
        setError(false);
        const getBuzzes = async () => {
          const token = localStorage.getItem('bhyve-token');
          const getBuzz = async () => {
            const result = await axios.get(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/?skip=${skip}&take=5`, {
              headers: {
                'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
            'Authorization': `Bearer ${token}` 
              }
            });
            return result;
          };
          try {
            let currentBuzzes = await getBuzz();
            if(currentBuzzes) {
              console.log(currentBuzzes);
              let finalBuzzes = []
              currentBuzzes.data.forEach(item => {
                let exist = buzzes.find(buzz => buzz.shoutoutPublicId === item.shoutoutPublicId);
                if(!exist) {
                  finalBuzzes.push(item);
                }
              });
              console.log(finalBuzzes);
              setHasMore(currentBuzzes.data.length > 0);
              if(finalBuzzes.length > 0) {
                      setBuzzes(prevBuzzes => {
                        return [...new Set([...prevBuzzes,...finalBuzzes])];
                      });
                      setLoading(false);
             }else{
               setLoading(false);
             }
            }
          } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
            if(e.response && e.response.status === 401) {
              window.location.href='http://localhost:5000/login'
            }
          }
        };
      getBuzzes();
      }
    } catch (error) {
      console.log(error.message);
    }
},[skip,tab]);



  return {loading,error,buzzes,hasMore};

}
