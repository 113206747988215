/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import {withRouter} from 'react-router-dom';
import { useLocation } from "react-router";
import SeachAutoComplete from '../SubComponents/search-autoComplete/search-autoComplete-component';
import Tour from '../../tour';
import { fade, makeStyles,Avatar,Link,Paper,Tooltip,Fade } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LastTour from '../../last-tour';
import Logout from '../../logout';
import axios from 'axios';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {setCompanyInformation,setBackgroundColor} from '../../redux/user/user-actions';
import { setTourStart, setTriggerPoint } from '../../redux/walkthrough/walkthrough-actions';
import {selectTriggerPoint} from '../../redux/walkthrough/walkthrough-selector';
import {selectCompanyInformation} from '../../redux/user/user-selector';
import './navbar-style.scss';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    cursor:'pointer',
    width:'5%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    backgroundColor: '#D1DFFF',
    position: 'relative',
    borderRadius: '20px',
    // backgroundColor: fade('#DADADA', 0.15),
    // '&:hover': {
    //   backgroundColor: fade('#DADADA', 0.25),
    // },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(15),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    //right:'1%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width:'470px',
  },
  inputInput: {
    padding: '8px 0 8px 50px',
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
    '&::placeholder':{
      marginLeft:'30px'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent:'space-between'
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

 function Navbar(
   {
     info,
     history,
     handleSearch,
     handleAboutUs,
     handleFeedback,
     handleContact, 
     companyInfo, 
     setCompanyInfo, 
     setBackgroundColor, 
     triggerPoint, 
     setTourStart, 
     setTriggerPoint,
     match
    }) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpEl, setHelpEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [logo,setLogo] = useState(null);
  const [search,setSearch] = useState('');
  const [autoCompleteResults,setAutoCompleteResults] = useState(null);
  const [loading,setLoading] = useState(false);
  const [searchStart,setSearchStart] = useState(false);
  const [selectedIndex,setSelectedIndex] = useState(null);
  const [selectedByNavigation, setSelectedByNavigation] = useState(null);
  const [logoActive,setLogoActive] = useState(1);
  const [fadeIn,setFadeIn] = useState(false);
  const [fadeOut,setFadeOut] = useState(false);


  useEffect(()=>{
    try {
      const token = localStorage.getItem('bhyve-token');
      const getCompanyDetails = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me/companyDetails`,{
          headers:{
            'Authorization': `Bearer ${token}`,
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
          }
        });
        return result;
      };
      async function gettingCompanyInfo() {
        let getInfoResult = await getCompanyDetails();
        console.log(getInfoResult);
        if(getInfoResult.data.companyBackgroundColor) {
          document.body.style.backgroundColor = getInfoResult.data.companyBackgroundColor;
        }
          console.log(getInfoResult.data.companyBackgroundColor);
        if(getInfoResult.data.borderColor) {
          setBackgroundColor(
            getInfoResult.data.borderColor
          )
          // for(let i = 0; i < document.body.getElementsByClassName('MuiPaper-root').length; i++) {
          //   console.log(document.body.getElementsByClassName('MuiPaper-root')[i]);
          //   document.body.getElementsByClassName('MuiPaper-root')[i].style.boxShadow = 'none';
          //   //document.body.getElementsByClassName('MuiPaper-root')[i].style.border = `2px solid ${getInfoResult.data.borderColor}`;
          // }
          // console.log(document.body.getElementsByClassName('MuiCard-root'));
          // for(let i = 0; i < document.body.getElementsByClassName('MuiCard-root').length; i++) {
          //   console.log(document.body.getElementsByClassName('MuiCard-root')[i]);
          //   document.body.getElementsByClassName('MuiCard-root')[i].style.boxShadow = 'none';
          //   //document.body.getElementsByClassName('MuiCard-root')[i].style.border = `2px solid ${getInfoResult.data.borderColor}`;
          // }
          //document.body.getElementsByClassName('slide').style.border = `3px solid ${getInfoResult.data.borderColor}`
        }
        setCompanyInfo(getInfoResult.data);
      }
      if(!companyInfo) {
        gettingCompanyInfo();
      }
      else{
        if(companyInfo.companyLogoDetails.length > 0) {
          if(companyInfo.companyLogoDetails.length > 1) {
            let logoPos = 0;
            setLogo(companyInfo.companyLogoDetails[logoPos++].imageUrl);
            setFadeIn(true);
            let navLogoShuffle = setInterval(() => {
              if(fadeIn) {
                setFadeIn(false);
              }
              setTimeout(() => {
                if(logoPos >= companyInfo.companyLogoDetails.length) {
                  logoPos = 0;
                }
                setLogo(companyInfo.companyLogoDetails[logoPos++].imageUrl);
                setFadeIn(true);
              },3000);
            },10000);
            return () => clearInterval(navLogoShuffle);
          }else{
            setLogo(companyInfo.companyLogoDetails[0].imageUrl);
            setFadeIn(true);
          }         
        }
        // let navCarousalInterval = setInterval(() => {
        //   if(logoActive < companyInfo.companyLogoDetails.length) {
        //     document.getElementById(`nav-radio${logoActive + 1}`).checked = true;
        //     let currentValue = logoActive + 1;
        //     setLogoActive(currentValue);
        //   }else{
        //     document.getElementById(`nav-radio1`).checked = true;
        //     setLogoActive(1);
        //   }
        // },4000);
        // return () => clearInterval(navCarousalInterval);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [companyInfo]);

  useEffect(() => {
    if(triggerPoint === 3) {
      let div = document.getElementById('help-btn');
      setHelpEl(div);
    }
  },[triggerPoint]);

  const isMenuOpen = Boolean(anchorEl);
  const isHelpOpen = Boolean(helpEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


//   const logoutUser = async () => {
//     const token = localStorage.getItem('bhyve-token');
//       const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me/logout`,{
//         headers:{
//           'Authorization': `Bearer ${token}`,
//           'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
//         }
//       });
//       return result;
// };

  const handleLogout = async () => {
    history.push('/logout');
    // try {
    //   let logoutUserResult = await logoutUser();
    //   if(logoutUserResult) {
    //     console.log(logoutUserResult.data);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
  };

  const handleProfileMenuOpen = (event) => {
                    setAnchorEl(event.currentTarget);
        setTimeout(()=>{
                  setHelpEl(null);
        },1000);
  };

  const handleHelpMenuOpen = (event) => {
        setAnchorEl(null);
        console.log(event.currentTarget);
    setHelpEl(event.currentTarget);
  };


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setHelpEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleKeyPress = e => {
    if(autoCompleteResults) {
      if(autoCompleteResults.users.length > 0 || autoCompleteResults.skills.length > 0) {
        let total = autoCompleteResults.users.length + autoCompleteResults.skills.length - 1;
        if(selectedIndex !== null) {
          if(e.keyCode === 38 && selectedIndex !== 0) {
            setSelectedIndex(prevValue => prevValue - 1);
          }else if(e.keyCode === 38 && selectedIndex === 0) {
            setSelectedIndex(null);
          }
          if(e.keyCode === 40 && selectedIndex !== total) {
            setSelectedIndex(prevValue => prevValue + 1);
          }else if(e.keyCode === 40 && selectedIndex === total) {
            setSelectedIndex(null);
          }
        }else{
          if(e.keyCode === 40){
            setSelectedIndex(0);
          }
        }
      }
    }

    if(e.keyCode === 13){
        if(selectedByNavigation) {
          handleSearch(selectedByNavigation);
        }else {
          handleSearch(search);
        }
        setAutoCompleteResults(null);
        setSearch('');
        setSelectedIndex(null);
        setSelectedByNavigation(null);
        setLoading(false);
     }
  };

  const getAutoCompleteUserSuggestions = async name => {
    const token = localStorage.getItem('bhyve-token');
  const result = await axios.get(`${process.env.REACT_APP_API_URL}search/autocomplete?searchQuery=${name}`,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
  }


  const handleSearchInput = async e => {
    try {
      const {value} = e.target;
      setSearch(value);
      if(value !== '' && value.length > 2) {
        try {
          setLoading(true);
          let outcome = await getAutoCompleteUserSuggestions(value);
          console.log(outcome.data);
          setAutoCompleteResults(outcome.data);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
          setAutoCompleteResults([]);
          if(e.response) {
            console.log(e.response.message);
            if(e.response.status === 401) {
              history.push('/logout');
            }
          }
        }
      }else{
        setAutoCompleteResults(null);
      }
    } catch (e) {
      console.log(e.message);
      if(e.response) {
        console.log(e.response.message);
        if(e.response.status === 401) {
          history.push('/logout');
        }
      }
    }
  };

  const navigateToUser = publicId => {
    setAutoCompleteResults(null);
    history.push(`/User/Profile/${publicId}`);
  };

  const handleSelectedOption = string => {
    handleSearch(string);
    setAutoCompleteResults(null);
    setLoading(false);
  }

  const handleChangeQuery = text => {
    if (text) {
      setSelectedByNavigation(text);
    }else{
      setSelectedByNavigation(null);
    }

  }

  const restartTour = () => {
    if(window.location.pathname === '/User/Dashboard') {
      setHelpEl(null);
      setTriggerPoint(0);
      setTourStart(true);
    }else{
      history.push('/User/Dashboard');
      setTimeout(() => {
        setHelpEl(null);
        setTriggerPoint(0);
        setTourStart(true);
      },2000);
    }
  }

    const helpMenuId = 'primary-help-menu';
  const renderHelpMenu = (
    <Popper open={isHelpOpen} anchorEl={helpEl} role={undefined} transition disablePortal style={{zIndex:'9999'}}>
    {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
            <Paper style={{zIndex:'9999'}}>
                <ClickAwayListener onClickAway={handleMenuClose}>
                 <MenuList autoFocusItem={isHelpOpen} id="helpMenuId" >
                 <MenuItem onClick={()=>{setHelpEl(null);handleAboutUs();}}>About Us</MenuItem>
                 <MenuItem><Link color='inherit' className='link' href='https://bhyve.io/help-guide/' target='_blank' rel='noopener'>Help Guide</Link></MenuItem>
                 <MenuItem className='bee-tour-option' onClick={restartTour}>Start Bee-Tour</MenuItem>
                 <MenuItem><Link color='inherit' className='link' href='https://bhyve.io/privacy-policy/' target='_blank' rel='noopener'>Privacy Policy</Link></MenuItem>
                 <MenuItem onClick={()=>{setHelpEl(null);handleFeedback();}}>Feedback</MenuItem>
                 <MenuItem onClick={()=>{setHelpEl(null);handleContact();}}>Contact</MenuItem>
      </MenuList>
      </ClickAwayListener>
      <LastTour />
            </Paper>
     </Grow>
      )}
    </Popper>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Popper open={isMenuOpen} anchorEl={anchorEl} role={undefined} transition disablePortal style={{zIndex:'9999'}}>
    {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
            <Paper style={{zIndex:'9999'}}>
                <ClickAwayListener onClickAway={handleMenuClose}>
                 <MenuList autoFocusItem={isMenuOpen} id="menuId" >
      <MenuItem onClick={()=>{setAnchorEl(null); history.push(`/User/Profile/${info.userInformation.publicId}`)}}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </MenuList>
      </ClickAwayListener>
            </Paper>
     </Grow>
      )}
    </Popper>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={()=>history.push(`/User/Profile/${info.userInformation.publicId}`)}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <ExitToAppIcon />
      </IconButton>
      <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  // 

  return (
    <div className={classes.grow}>
      <LastTour />
      <AppBar position="fixed" className='navBar' style={{backgroundColor:'#133DA0!important'}} color='inherit'>
        <Toolbar style={{paddingLeft:'5px'}}>
        {companyInfo && 
          <div className='nav-logo-main-container'>
            <Fade in={fadeIn} timeout={2000}>
              <div className='nav-logo-container' onClick={()=>history.push('/User/Dashboard')}>
                          <img  src={logo}/>
                {/* <img src={logo} alt='logo' className={classes.title} onClick={()=>history.push('/User/Dashboard')}/> */}
              </div>
            </Fade>
          </div>
          }         
          <div className={`${classes.search} search-container`}>
            <div className={classes.searchIcon}>
              <SearchIcon/>
            </div>
            <InputBase
              onChange={handleSearchInput}
              onKeyDown={handleKeyPress}
              value={search}
              placeholder="Search for people and skills"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
            {(autoCompleteResults||loading)&&<ClickAwayListener onClickAway={()=>{setAutoCompleteResults(null);setLoading(false);}}>
              <div className='autoComplete-main-container'>
                <SeachAutoComplete 
                  navigation={selectedIndex} 
                  results={autoCompleteResults} 
                  loading={loading} 
                  navigateToAuthor={navigateToUser} 
                  query={search} 
                  handleSearchQuery={handleSelectedOption}
                  handleChangeQuery={handleChangeQuery}
                  />
              </div>
            </ClickAwayListener>}       
          </div>
          <div className={classes.grow} />
          <p className='welcome-text' >Welcome, {info&&info.userInformation.firstName}</p>
          <Tooltip title="Dashboard" placement="bottom">
            <IconButton
              style={{marginLeft:'30px'}}
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={()=>history.push('/User/Dashboard')}
              color="inherit"
              className='nav-home-icon'
              style={{color:'white'}}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
            <Tooltip title="Help" placement="bottom">
          <IconButton
            edge="end"
            color="inherit"
            aria-label='Help'
            aria-haspopup="true"
            aria-controls={helpMenuId}
            style={{marginRight:'5px',color:'white'}}
            onClick={handleHelpMenuOpen}
            id='help-btn'
            className='nav-help'
          >

            <HelpOutlineIcon/>
          </IconButton>
          </Tooltip>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {info&&info.userInformation.profilePhotoUrl!==''&&<Avatar src={info&&info.userInformation.profilePhotoUrl}/>}
              {info&&info.userInformation.profilePhotoUrl===''&& <Typography variant='h6' className='initial-container' style={{width:'40px',height:'40px',cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'6px',color:'white',background:'#FFCC33'}}>
          {`${info.userInformation.firstName.slice(0,1)}${info.userInformation.lastName.slice(0,1)}`}
        </Typography>}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderHelpMenu}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  companyInfo: selectCompanyInformation,
  triggerPoint: selectTriggerPoint
});

const mapDispatchToProps = dispatch => ({
  setCompanyInfo: object => dispatch(setCompanyInformation(object)),
  setBackgroundColor: object => dispatch(setBackgroundColor(object)),
  setTourStart: bool => dispatch(setTourStart(bool)),
  setTriggerPoint: value => dispatch(setTriggerPoint(value))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Navbar))
