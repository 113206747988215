/*jshint esversion:9*/
/*jshint -W087*/

import React,{useEffect,useState} from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import  Logout from '../../../logout';
import {connect} from 'react-redux';
import {selectUserData} from '../../../redux/user/user-selector';
import {createStructuredSelector} from 'reselect';
import Loader from '../../../Components/SubComponents/loader/loader-component';
import {setUserData,setLocationData,setCompanyInformation,setBackgroundColor,setSettings} from '../../../redux/user/user-actions';
import {setCategories} from '../../../redux/categories/categories-actions';
import RoutingUrlForLoginApp, {RoutingUrl} from "../../routing-url";
import UnAuthorizedDialog from '../../../Components/SubComponents/unAuthorized-modal/index.js';
import axios from 'axios';

function Home({setUserData,setBackgroundColor,setLocationData,setCategories,setCompanyInformation,setSettings}){

  const [loading,setLoading] = useState(false);
  const [openDialog,setOpenDialog] = useState(false);

  const history = useHistory();
  const routingUrl = new RoutingUrl();
  const routingUrlForMainApp = routingUrl.RoutingUrlForMainApp();
  localStorage.setItem("routingUrlForMainApp", routingUrlForMainApp);

    useEffect(()=>{
      const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'));
      const companyNameFromUrl = routingUrl.CompanyNameFromLoginUrl();
      const getSignUpInformation = async(token) => {
        console.log('fetch user info ', {
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
          'Authorization': `Bearer ${token}`
        });
        const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me`,{
          headers:{
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
            'Authorization': `Bearer ${token}`
          }
        });
        return result;
      };

      const getBhyveToken = async() => {
        const result = await axios.get(`${process.env.REACT_APP_API_URL_AUTH}authentication/verify`,{
          headers:{
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
            'Authorization': `Bearer ${oktaTokens.idToken.value}`,
            'token': oktaTokens.accessToken.value,
            'companyName': companyNameFromUrl
          }
        });
        return result;
      };
    async function checkAuthstate(){
      setLoading(true);
/*
      if(window.location.origin === 'https://hindalco.bhyve.io') {
        window.location = 'https://dev-953032.okta.com/sso/saml2/0oa43qgo6lIQcBoei4x7';
        return;
      }
      if(window.location.origin === 'https://rieco.bhyve.io') {
        window.location = 'https://dev-953032.okta.com/oauth2/v1/authorize?idp=0oa48rnsyev9bwpJw4x7&client_id=0oa44uoegg9ORoRE54x7&response_type=id_token&response_mode=fragment&scope=openid profile email&redirect_uri=https://rieco.bhyve.io/implicit/callback&state=WM6D&nonce=YsG76jo';
        return;
      }
*/
      if(oktaTokens && Object.keys(oktaTokens).length > 0){
        let tokenExist = localStorage.getItem('bhyve-token');
        if(!tokenExist || tokenExist === null){
          //debugger;
            try{
              let res = await getBhyveToken();
              console.log('bhyve login response ', res);
              if(res){
                localStorage.setItem('bhyve-token',res.data.token);
                GettingSignUpInformation(res.data.token);
              }
            }catch(error) {
              setLoading(false);
              console.log('bhyve login error', error);
              if(error.response && error.response.status === 401) {
                setOpenDialog(true);
              }else{
                history.push('/logout');
              }
            }
        }else{
          //debugger;
          GettingSignUpInformation(tokenExist);
        }
      }else{
        history.push('/login');
      }
    }
    checkAuthstate();

      async function GettingSignUpInformation(token){
        try{
          let result = await getSignUpInformation(token);
          console.log('Fetch user info result ', result.data);
          if(result) {
            setUserData({userInformation:result.data.userInformation});
            if(result.data.userLocation){
              setLocationData(result.data.userLocation);
            }
            if(result.data.userInformation.settings) {
              setSettings(result.data.userInformation.settings);
            }
            if(result.data.companyDetails) {
              setCompanyInformation(result.data.companyDetails);

              if(result.data.companyDetails.companyBackgroundColor) {
                document.body.style.backgroundColor = result.data.companyDetails.companyBackgroundColor;
                setBackgroundColor(
                  `0 8px 40px -12px 
                    ${result.data.companyDetails.companyBackgroundColor === '#FFFAEB' ? 
                      'rgba(255,204,51,0.4)' 
                    : 
                      'rgba(235,248,255,0.4)'
                  }`
                )
                console.log(result.data.companyDetails.companyBackgroundColor);
                for(let i = 0; i < document.body.getElementsByClassName('MuiPaper-root').length; i++) {
                  console.log(document.body.getElementsByClassName('MuiPaper-root')[i]);
                  document.body.getElementsByClassName('MuiPaper-root')[i].style.boxShadow = `0 8px 40px -12px ${result.data.companyDetails.companyBackgroundColor === '#FFFAEB' ? 'rgba(255,204,51,0.4)' : 'rgba(235,248,255,0.4)'}`;
                }
                console.log(document.body.getElementsByClassName('MuiCard-root'));
                for(let i = 0; i < document.body.getElementsByClassName('MuiCard-root').length; i++) {
                  console.log(document.body.getElementsByClassName('MuiCard-root')[i]);
                  document.body.getElementsByClassName('MuiCard-root')[i].style.boxShadow = `0 8px 40px -12px ${result.data.companyDetails.companyBackgroundColor === '#FFFAEB' ? 'rgba(255,204,51,0.4)' : 'rgba(235,248,255,0.4)'}`;
                }
              }
            }
            if(result.data.skillCategories) {
              setCategories(result.data.skillCategories);
            }
            if(result.data.userInformation.userRole==='OWNER') {
              history.push('/OwnerPanel')
            }else{
              if(result.data.userInformation.profileCompleted) {
                history.push('/User/Dashboard');
              }else{
                console.log('Propfile not completed');
                history.push('/CreateYourProfile');
              }
            }

          }
        } catch(error) {
          if(error.response && error.response.status===401){
            history.push('/logout')
          }
        }
      }


    },[]);

    return(
      <div>
        <Loader open={loading} text='Initial Sync'/>
        <UnAuthorizedDialog open={openDialog} handleClick = {() => history.push('/logout')}/>
      </div>
    );

}

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object)),
  setLocationData: object => dispatch(setLocationData(object)),
  setCompanyInformation: object => dispatch(setCompanyInformation(object)),
  setCategories: categories => dispatch(setCategories(categories)),
  setBackgroundColor: color => dispatch(setBackgroundColor(color)),
  setSettings: object => dispatch(setSettings(object))
});

const mapStateToProps = createStructuredSelector({
  userData:selectUserData
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));
