/*jshint esversion:9*/

import React from "react";
import { TextField, InputAdornment, FormHelperText } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import "./Input.scss";

const Input = (props) => {
  return (
    <div style={{ width: "100%", marginBottom: "10px" }}>
      {props.type !== "date" && props.type !== "radio" && (
        <div className="textfield-container">
          <label htmlFor={`input-${props.name ? props.name : props.label}`}>
            {props.label}
          </label>
          <TextField
            helperText={props.helperText}
            className="input"
            disabled={props.disabled && props.disabled}
            id={`input-${props.name ? props.name : props.label}`}
            select={props.type === "select" ? true : false}
            type={props.type}
            value={props.value}
            name={props.name}
            placeholder={props.placeholder && props.placeholder}
            variant={props.variant && props.variant}
            onChange={(e) => props.onChange(e)}
            multiline={props.rows && true}
            rows={props.rows && props.rows}
            InputProps={
              props.icon && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ color: "gray", width: "24px" }}
                  >
                    {props.icon}
                  </InputAdornment>
                ),
              }
            }
            error={props.error}
            SelectProps={{
              native: true,
            }}
          >
            {props.type === "select" &&
              props.options &&
              props.options.map((item, index) => (
                <option key={index} value={item}>{`${item
                  .slice(0, 1)
                  .toUpperCase()}${item.slice(1, item.length)}`}</option>
              ))}
          </TextField>
        </div>
      )}
      {props.type === "date" && (
        <div className="textfield-container" style={{ marginBottom: "10px" }}>
          <label htmlFor={`input-${props.name ? props.name : props.label}`}>
            {props.label}
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id={`input-${props.name ? props.name : props.label}`}
              helperText={props.helperText}
              className="txt-field"
              inputVariant={props.variant && props.variant}
              invalidDateMessage={null}
              style={{ width: "100%" }}
              format={!props.views && "dd/MM/yyyy"}
              disableFuture
              openTo="year"
              views={props.views ? props.views : ["date", "month", "year"]}
              name={props.name}
              value={props.value}
              onChange={(e) => props.dateChange(e, props.name)}
              InputProps={
                props.icon && {
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "gray", width: "24px" }}
                    >
                      {props.icon}
                    </InputAdornment>
                  ),
                }
              }
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
      {props.type === "radio" && (
        <div className="radio-container" style={{ marginBottom: "20px" }}>
          <label htmlFor={`input-${props.name ? props.name : props.label}`}>
            {props.label}
          </label>
          <div className="radios">
            <RadioGroup
              id={`input-${props.name ? props.name : props.label}`}
              name={props.name}
              value={props.value}
              onChange={(e) => props.onChange(e)}
              inputVariant={props.variant && props.variant}
            >
              {props.forGenderSelection ? (
                // props.options.map((item) => (
                //     <FormControlLabel
                //       value={item.value}
                //       style={{ color: "green", width: "35% !important" }}
                //       control={<Radio />}
                //       label={item.name}
                //     />
                //   ))
                //
                // Not mapping array for proper spacing between items
                // Last 2 items require different spacing to be aligned with the first
                <>
                  <FormControlLabel
                    value={props.options[0].value}
                    control={<Radio />}
                    label={props.options[0].name}
                  />
                  <FormControlLabel
                    value={props.options[1].value}
                    control={<Radio />}
                    label={props.options[1].name}
                    style={{ marginRight: "15px" }}
                  />
                  <FormControlLabel
                    value={props.options[2].value}
                    control={<Radio />}
                    label={props.options[2].name}
                    style={{ paddingLeft: "20px" }}
                  />
                </>
              ) : (
                props.options.map((item) => (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))
              )}
            </RadioGroup>
            {props.helperText && (
              <FormHelperText className="helper-text">
                {props.helperText}
              </FormHelperText>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;
