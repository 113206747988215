/*jshint esversion:9*/
/*jshint -W087*/

export const updateCompany = (list,object) => {
   try {
    const exist = list.find(item => item.companyPublicId === object.companyPublicId);

    if(exist){
      return list.map(item => item.companyPublicId === object.companyPublicId?
        object:
        item
      );
    }
  
    return list; 
   } catch (error) {
     console.log(error.message);
   }
};
