/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,Radio,Button,RadioGroup,FormControlLabel,FormHelperText,IconButton} from '@material-ui/core';
import InfoEdit from '../info-edit/info-edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import './edit-skillSet-style.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function EditSkillSetModal({open,handleClose,selectedSkill,handleRemove,handleDone}) {
  const classes = useStyles();

  const [details,setDetails] = useState({
    skillPublicId:null,
    skillName:null,
    skillType:'PRIMARY_SKILL',
    description:'',
    score:null
  });

  useEffect(()=>{
    try {
      if(selectedSkill){
        if(typeof selectedSkill === 'string'){
          setDetails({
            skillPublicId:null,
            skillName:selectedSkill,
            skillType:'PRIMARY_SKILL',
            description:'',
            score:null
          });
          return;
        }
  
        if(selectedSkill.skillType){
          setDetails(selectedSkill);
        }else{
          setDetails({
            ...selectedSkill,
            skillType:'PRIMARY_SKILL',
            description:'',
            score:null
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  },[selectedSkill]);


  const handleChange = e => {
    try {
      console.log(details);
      const {name,value} = e.target;
      if(name==='description'){
        if(details.description.length<160){
          setDetails(prevValue => {
          return{
            ...prevValue,
            [name]:value
          };
        });
        return;
        }
        return;
      }
      if(name !== 'score'){
        setDetails(prevValue => {
        return{
          ...prevValue,
          [name]:value
        };
      });
    }else{
      setDetails(prevValue => {
        return{
          ...prevValue,
          score:parseInt(value)
        };
      });
    } 
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <Typography variant='h4' style={{fontWeight:'bold',borderBottom:'1px solid black',paddingBottom:'5px',display:'flex',justifyContent:'space-between'}}><span style={{position:'relative',top:'10px'}}>{details.skillName}</span><IconButton onClick={handleClose}><CloseIcon/></IconButton></Typography>
          <br/>
          <InfoEdit label='Description' name='description' value={details.description} onChange={handleChange} multiline={true} type='text' placeholder="Write one line about why you're good at this skill"/>
          <div style={{display:'flex',justifyContent:'flex-end'}}>
            <Typography variant='caption'>{details.description.length}/160</Typography>
          </div>
          <br/>
          <div className='edit-skillType'>
            <Typography variant='subtitle1' className='label'>Expertise level:</Typography>
            <RadioGroup aria-label="gender" name='score' value={details.score} style={{display:'flex',flexWarp:'no-wrap',flexDirection:'row'  }} onChange={handleChange}>
         <FormControlLabel value={1} control={<Radio />} label="Basic" />
         <FormControlLabel value={2} control={<Radio />} label="Average" />
         <FormControlLabel value={3} control={<Radio />} label="Expert" />
         </RadioGroup>
         <FormHelperText style={{marginTop:'10px'}}>Required*</FormHelperText>
          </div>
          <Button variant='contained' style={{marginRight:'10px',color:'white',background:'#66CCFF'}} onClick={()=>handleDone(details)}>Save</Button>
          {selectedSkill&&selectedSkill.skillType&&<Button variant='contained' style={{marginRight:'10px',color:'white',background:'red'}} onClick={()=>handleRemove(details.skillPublicId)}>Remove</Button>}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
