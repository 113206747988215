/*jshint esversion:9*/
import React,{useState} from 'react';
import { makeStyles,Typography,FormControlLabel,Checkbox,Button,IconButton } from '@material-ui/core';
import Input from '../Input/Input';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import feedback from '../../../Assets/feedback.png';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth:'1100px',
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
  },
  grid:{
    display:'grid',
    gridTemplateColumns:'40% 60%',
    columnGap:'10px'
  }
}));

export default function FeedbackModal({open,handleClose}) {
  const classes = useStyles();
  const [checked,setChecked] = useState(null);
  const [problem,setProblem] = useState(null);
  const [feature,setFeature] = useState(null);

const options = ["I can't find something","I can't figure out how to do something","I'm stuck with a feature","Nothing's wrong, I love BHyve","Something else"];
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            <IconButton onClick={handleClose}>
             <CloseIcon/>
            </IconButton>
          </div>
          <div className={classes.grid}>
          <div style={{textAlign:'left'}}>
                                <Typography variant='h4' style={{fontWeight:'bold',paddingLeft:'50px'}}>Feedback</Typography>
            <img src={feedback} alt='comingSoon' style={{width:'400px'}}/>
          </div>
          <div style={{textAlign:'left'}}>
            <br/>
            <Typography variant='h6'>We'd love to hear from you! Tell us what's happening ?</Typography>
            <br/>
            {options.map(item =>
              < FormControlLabel
              style={{width:'100%'}}
            control = {
              <Checkbox
              checked = {
                checked===item
              }
              onChange = {
                ()=>setChecked(item)
              }
              name = "checkedB"
              color = "primary" />
            }
            label = {item} />
            )}
            <br/>
            <br/>
            <div style={{width:'70%'}}>
            <Input rows='2' variant='outlined' label='Describe your problem' placeholder="What's the problem?..." onChange={e => setProblem(e.target.value)}/>
            </div>
            <br/>
            <div style={{width:'70%'}}>
            <Input rows='2' variant='outlined' label='Suggestion' placeholder="Suggest a feature..." onChange={e => setFeature(e.target.value)}/>
            </div>
            <br/>
            <Button variant='contained' className='edit-btn'>Submit</Button>
          </div>
          </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
