/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography,CircularProgress} from '@material-ui/core';
import RecognitionFeedText from '../SubComponents/recognition-feed-text/recognition-feed-text';
import axios from 'axios';
import moment from 'moment';
import Logout from '../../logout';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectBadges} from '../../redux/badges/badges-selector';
import {withRouter} from 'react-router-dom';
import './recognitionFeed-style.scss';

function RecognitionFeed({handleClick,handleUserLink,allBadges,history}) {

  const [data,setData] = useState(null);
  const [loading,setLoading] = useState(false);

  useEffect(()=> {
    try {
      setLoading(true);
      const token = localStorage.getItem('bhyve-token');
      const getRecognitionFeed = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}recognition-feed`,{
          headers:{
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
            'Authorization': `Bearer ${token}`
          }
        });
        return result;
      };
  
      const getFeed = async() => {
        try {
          let result = await getRecognitionFeed();
          let feed = [];
          result.data.forEach((item, i) => {
            if(item.recognitionFeedType === 'BADGE') {
              let badge = allBadges.find(badge => badge.publicId === item.badgePublicId);
              if(badge) {
                feed.push({
                  item: badge.badgeName,
                  itemImage: badge.badgeImageUrl,
                  time: moment(item.createdAt).fromNow(),
                  ...item
                });
              }
            }else{
              feed.push({...item,time: moment(item.createdAt).fromNow()});
            }
          });   
          console.log(feed);      
         setData(feed);
          setLoading(false);
        } catch (error) {
          if(error.response) {
            console.log(error.response.message);
            setData([]);
            if(error.response && error.response.status === 401) {
              history.push('/logout');
            }
          }
        }
      };
      
      if(allBadges) {
        getFeed();
      } 
    } catch (error) {
      console.log(error.message);
    }
  },[allBadges]);




  return(
    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',position:'relative',flexWrap:'wrap',marginTop:'50px'}}>
      <Paper className='recognition-feed-main-container'>
      <Typography variant='h6' className='recognition-feed-title'>Recognition Feed</Typography>
        <div class='recognition-feed-container'>
        {!data&&
          <div className='loader-container'>
          <CircularProgress disableShrink />
          </div>
        }
        {data&&data.length===0&&<Typography variant='subtitle1'>No feed to show here ! Comeback later.</Typography>}
        {data && data.map((item,index) => 
          <RecognitionFeedText 
            type={item.recognitionFeedType}
            skillName={item.collaborationSkillDisplayName && item.collaborationSkillDisplayName}
            senderFirstName={item.donorUserPublicId.firstName} 
            senderLastName={item.donorUserPublicId.lastName} 
            handleDonorLink={()=>handleUserLink(item.donorUserPublicId.publicId)}  
            senderImg={item.donorUserPublicId.profilePhotoUrl} 
            recepientFirstName={item.receiverUserPublicId.firstName} 
            recepientLastName={item.receiverUserPublicId.lastName}  
            handleRecieverLink={()=>handleUserLink(item.receiverUserPublicId.publicId)} 
            recieverImg={item.receiverUserPublicId.profilePhotoUrl} 
            item={item.item} time={item.time} 
            key={index} 
            handleClick={()=>handleClick(item.itemImage,item.item)}
            handleSkillSearch={() => history.push(`/User/Search?q=${item.collaborationSkillDisplayName}`)}
          />)}
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  allBadges: selectBadges
})

export default withRouter(connect(mapStateToProps)(RecognitionFeed));
