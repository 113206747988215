import { DISLIKE_POST, LIKE_POST, SAVE_COMMENT,DELETE_BOOKMARK,BOOKMARK_ARTICLE,FETCH_PUBLISHED_ARTICLE_BY_AUTHOR } from '../types';
import cmsAxios from '../../axios/authAxios';

export const saveComment = (articleId, request) => dispatch => {
  cmsAxios.post(`cms/article/${articleId}/comments`,request).then(
    response => {
        dispatch({ type: SAVE_COMMENT, payload: response.data})
    }
  )
};

export const likePost = (id,userId) => dispatch => {
  cmsAxios.post(`cms/article/${id}/like`).then(
    response => {
        dispatch({ type: LIKE_POST, payload: userId})
    }
  )
  //dispatch({type: LIKE_POST,})
};

export const disLikePost = (articleId,userId) => dispatch => {
  cmsAxios.post(`cms/article/${articleId}/unlike`).then(
    response => {
        dispatch({ type: DISLIKE_POST, payload: userId })
    }
  )
};

export const fetchPublishedArticleByAuthor = (authorPublicId)=>async (dispatch)=>{
  try {
    const response = await cmsAxios.get(`cms/article/author/${authorPublicId}/published`)
    if(response.data){
      dispatch({type:FETCH_PUBLISHED_ARTICLE_BY_AUTHOR, payload:response.data})
    }
  } catch (err) {
    console.log(err)
  }
  
}


