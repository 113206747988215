import React from 'react';
import { makeStyles,Typography,IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close'
import Fade from '@material-ui/core/Fade';
import admin from '../../../Assets/admin.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding:'50px',
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ForgotPasswordModal({open,handleClose}) {
  const classes = useStyles();


  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{width:'100%',paddingLeft:'92%'}}>
              <IconButton onClick={handleClose}>
                <CloseIcon/>
              </IconButton>
            </div>
            <img src={admin} alt='comingSoon' style={{width:'400px'}}/>
            <Typography variant='h5' style={{fontWeight:'bold'}}>Please reach out to admin@bhyve.io .</Typography>
          </div>
        </Fade>
      </Modal>
  );
}
