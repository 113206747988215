import {notificationActionTypes} from './notification-types';

const INITIAL_STATE = {
    newNotifications: [],
    newMessages: null,
    subscribedRooms: []
}


const notificationReducer = (state=INITIAL_STATE,action) => {
    switch (action.type) {
        case notificationActionTypes.SET_NEW_NOTIFICATIONS:
            return {
                ...state,
                newNotifications: [...action.payload,...state.newNotifications]
            }
        
        case notificationActionTypes.UPDATE_NOTIFICATIONS:
            let updatedNotifications = state.newNotifications.map(item => (!item.published) ? {...item,published: true} : item);
            return {
                ...state,
                newNotifications: updatedNotifications
            }

        case notificationActionTypes.SET_NEW_MESSAGES:
            let currentlySubscribed = state.subscribedRooms;
            let exists = currentlySubscribed.find(item => item.id === action.payload.roomId);
            if(state.newMessages) {
                if((action.payload.id !== state.newMessages.id)) {
                    if(exists) {
                        currentlySubscribed = currentlySubscribed.map(item => item.id === action.payload.roomId ? {...item, unread: item.unread + 1} : item);
                    }else{
                        currentlySubscribed.push({id: action.payload.roomId, status: true, unread: 1});
                    }
                }
            }else{
                currentlySubscribed = currentlySubscribed.map(item => item.id === action.payload.roomId ? {...item, unread: 1} : item);
            }

            return{
                ...state,
                newMessages: action.payload,
                subscribedRooms: currentlySubscribed
            }

        case notificationActionTypes.SET_SUBSCRIBED_ROOMS:
            let currentRooms = state.subscribedRooms;
            let exist = currentRooms.find(item => item.id === action.payload.id);
            if(exist) {
                currentRooms = currentRooms.map(item => item.id === action.payload.id ? {...action.payload, unread: action.payload.unread !== undefined ?  action.payload.unread : item.unread}: item);
            }else{
                currentRooms.push(action.payload);
            }
            return{
                ...state,
                subscribedRooms: currentRooms
            }
        default:
            return state;
    }
}

export default notificationReducer;