import React from 'react';
import style from './wrapper.module.scss';
const Wrapper = ({ children, ...rest }) => {
    return (
        <div
            {...rest}
            style={{ ...rest.style }}
            className={`${style.wrapper} ${rest.className}`}>
            {children}
        </div>
    )
}

export default Wrapper;