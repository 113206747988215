import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  CircularProgress,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import ChatHistoryEntry from "../SubComponents/chat-history-entry/chat-history-entry";
import AddCollabSkillModal from "../SubComponents/add-collab-skill-modal/add-collab-skill-modal";
import EndorsementModal from "../SubComponents/endorsement-modal/endorsement-modal";
import SuccessModal from "../SubComponents/ack-endorse-success-modal/ack-endorse-success-modal";
import Snackbar from "../SubComponents/snackbar/snackbar";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import "./chat-history-style.scss";

const ChatHistoryEntryMemo = React.memo(ChatHistoryEntry);

function ChatHistory({
  history,
  convHistory,
  handleUserChange,
  otherId,
  historyLoading,
  setHistoryLoading,
  newUnRead,
}) {
  const historyList = React.useRef();
  const [conversations, setConversations] = useState(null);
  const [endorse, setEndorse] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [endorsementDetails, setEndorsementDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successRole, setSuccessRole] = useState(0);
  const [success, setSuccess] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [addSkillModal, setAddSkillModal] = useState(false);
  const [addSkillModalDetails, setAddSkillModalDetails] = useState({
    collabDetails: {},
    userDetails: {},
  });

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    if (convHistory) {
      if (otherId) {
        let convs = convHistory;
        let selectedConv = convHistory.find(
          (item) => item.participants.id === otherId
        );
        if (selectedConv) {
          setConversations(convs);
        } else {
          setConversations(convHistory);
        }
      } else {
        setConversations(convHistory);
      }
    }
  }, [convHistory, otherId]);

  const handleAcknowledgement = async (collabId, skillId) => {
    try {
      setMessage("Acknowledging...");
      setSeverity("info");
      setAlertOpen(true);
      let reqObject = {
        collaborationPublicId: collabId,
        collaborationSkillPublicId: skillId,
      };
      const acknowledgeResult = await axios.patch(
        `${process.env.REACT_APP_API_URL}collaborationSkill/acknowledge`,
        reqObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      let current = conversations;
      current = current.map((item) =>
        item.collabDetails.collaborationPublicId === collabId
          ? { ...item, collabDetails: acknowledgeResult.data }
          : item
      );
      let skill = current
        .find((item) => item.collabDetails.collaborationPublicId === collabId)
        .collabDetails.collaborationSkill.find(
          (item) => item.collaborationSkillPublicId === skillId
        ).skillDisplayName;
      setSuccessRole(0);
      setSelectedSkill(skill);
      setConversations(current);
      setAlertOpen(false);
      setTimeout(() => {
        setSuccess(true);
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleEndorsement = (obj) => {
    try {
      setEndorsementDetails(obj);
      setEndorse(true);
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleEndorsementSubmit = async (review, recommendation, skillName) => {
    try {
      setLoading(true);
      let reqObject = {
        collaborationPublicId: endorsementDetails.collabId,
        collaborationSkillPublicId:
          endorsementDetails.skill.collaborationSkillPublicId,
        review: review,
        recommendation: recommendation,
      };

      const endorseResult = await axios.patch(
        `${process.env.REACT_APP_API_URL}collaborationSkill/endorse`,
        reqObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );

      if (endorseResult) {
        let current = conversations;
        current = current.map((item) =>
          item.collabDetails.collaborationPublicId ===
          endorsementDetails.collabId
            ? { ...item, collabDetails: endorseResult.data }
            : item
        );
        setConversations(current);
        setLoading(false);
        setEndorse(false);
        setEndorsementDetails(null);
        setSelectedSkill(skillName);
        setSuccessRole(1);
        setSuccess(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleAddSkill = (collabData) => {
    setAddSkillModalDetails({
      collabDetails: collabData.collabDetails,
      userDetails: collabData.participants,
    });
    setAddSkillModal(true);
  };

  const handleAddSuccess = (data) => {
    let current = conversations;
    current = current.map((item) =>
      item.collabDetails.collaborationPublicId === data.collaborationPublicId
        ? { ...item, collabDetails: data }
        : item
    );
    setConversations(current);
  };

  const handleSelectUser = (conv) => {
    handleUserChange(conv);
  };

  const handleSearchChange = (e) => {
    setHistoryLoading(true);
    const { value } = e.target;
    let convsFound = [];
    if (!!convHistory && convHistory.length > 0) {
      convHistory.forEach((item) => {
        if (item.participants.name.toLowerCase().includes(value)) {
          convsFound.push(item);
        }
      });
    }
    setConversations(convsFound);
    setHistoryLoading(false);
  };

  return (
    <div className="history-container">
      <div className="history-container-title">
        <h2>Recent Collaborations</h2>
      </div>

      <div className="history-list-container">
        <div className="search-container">
          <OutlinedInput
            className="chat-search-text"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            }
            placeholder="Search People"
            onChange={handleSearchChange}
          />
        </div>
        {!historyLoading && conversations && conversations.length > 0 && (
          <div
            ref={historyList}
            id="history-list"
            className="history-entry-main-container"
          >
            {conversations.map((item, index) => (
              <ChatHistoryEntryMemo
                newUpdate={newUnRead}
                index={index}
                item={item}
                handleUserChange={() => handleSelectUser(item)}
                otherId={otherId}
                handleAcknowledgement={handleAcknowledgement}
                handleEndorsement={handleEndorsement}
                handleAddSkill={handleAddSkill}
              />
            ))}
          </div>
        )}
        {historyLoading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "50%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>

      {!historyLoading && conversations && conversations.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "50%",
          }}
        >
          <p style={{ opacity: "0.6" }}>Chat History not found !</p>
        </div>
      )}
      <EndorsementModal
        open={endorse}
        handleClose={() => {
          setEndorsementDetails(null);
          setEndorse(false);
        }}
        details={endorsementDetails}
        handleSubmit={handleEndorsementSubmit}
        loading={loading}
      />
      <SuccessModal
        open={success}
        handleClose={() => setSuccess(false)}
        skill={selectedSkill}
        role={successRole}
      />
      <AddCollabSkillModal
        open={addSkillModal}
        handleClose={() => setAddSkillModal(false)}
        details={addSkillModalDetails}
        handleAddSuccess={handleAddSuccess}
      />
      <Snackbar
        Open={alertOpen}
        severity={severity}
        alert={message}
        handleClose={() => setAlertOpen(false)}
      />
    </div>
  );
}

export default withRouter(ChatHistory);
