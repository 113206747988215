/*jshint esversion:9*/

import React, {useState} from 'react';
import {Paper, Typography, Divider} from '@material-ui/core';
import BuzzBox from '../buzz-box/buzz-box-component';
import Snackbar from '../SubComponents/snackbar/snackbar';
import BuzzFeedTextArea from '../SubComponents/buzz-feed-textarea/buzz-feed-textarea';
import './buzz-feed-style.scss';


function BuzzFeed(){

  const [activitySkip,setActivitySkip] = useState(0);
  const [selectedBuzz,setSelectedBuzz] = useState(null);


  const handleSkip = val => {
      setActivitySkip(val);
  }


  return(
    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',flexWrap:'wrap',marginTop:'50px'}}>
      <Paper className='buzz-feed-main-container' elevation={4}>
        <Typography variant='h6' className='buzz-box-title'>BuzzBox</Typography>
        <div class='buzz-feed-container'>
          <BuzzBox type='activity' skip={activitySkip} setSkip={handleSkip} feed={true} handleEditOnFeed={buzz => setSelectedBuzz(buzz)}/>        
        </div>
        <Divider style={{width:'100%'}}/>
        <div className='buzz-feed-textarea-main-container'>
          <BuzzFeedTextArea selectedBuzz={selectedBuzz} />
        </div>
      </Paper>
    </div>
  )
}

export default BuzzFeed;
