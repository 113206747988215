/*jshint esversion:9*/

import React, {useEffect} from 'react';
import {Typography,Chip,Tooltip} from '@material-ui/core';
import Crown from '../../../Assets/crown@2x.png';
import './skills-style.scss';

function Skills({name,level,des,totalEndorsementCount,endorsementAvg}){

  useEffect(() => {
    console.log(level);
  },[level]);



  function capitalize(input) {  
    try {
      let word = input;
      if(word) {
        var words = word.split(' ');  
        var CapitalizedWords = [];  
        let wordsTobeIgnored = ['and','the','of','on','are', 'a']; 
        words.forEach(element => {
            let currentElement = '';  
            if(element.split('').length > 1 && element.split('').includes('&')) {
              let temp = element.split[0];
              currentElement = element.split('')[0].toUpperCase() + '&' + element.split('')[2].toUpperCase();
            }else{
              currentElement = element;
            }
            if(currentElement === 'cpr') {
              CapitalizedWords.push('CPR');  
            }else if(currentElement === 'chp') {
              CapitalizedWords.push('CHP');  
            }else if(wordsTobeIgnored.includes(currentElement)) {
              CapitalizedWords.push(currentElement); 
            }else {
              CapitalizedWords.push(currentElement[0].toUpperCase() + currentElement.slice(1, currentElement.length));  
            }         
        });  
        return CapitalizedWords.join(' ');  
      }else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
} 

  return(
    <div className='skills-container' style={{paddingBottom: !des && '0', minHeight: !des && '40px'}}>
      <div className='skill-title' style={{marginBottom: !des && '0'}}>
        <Tooltip title={capitalize(name)} placement='top-left'>
          <Typography variant='body1' className='skill-title'>{capitalize(name)}</Typography>
        </Tooltip>
        <Tooltip title={(level === 0.333 || level === "0.333") ? 'Beginner' : (level === 0.667 ||level === '0.667') ? 'Intermediate' : (level === 0.80 || level === '0.80') ? 'Advanced' : 'Expert'} placement='left'>
          <div className='selected-expertise-level-container'>
                  {level >= 0.333 && <label className='selected-expertise-card-level'/>}
                  {level >= 0.667 && <label className='selected-expertise-card-level'/>}
                  {level >= 0.80 && <label className='selected-expertise-card-level'/>}
                  {level === 1 && <label className='selected-expertise-card-level'/>}
            </div>
        </Tooltip>
        <div style={{width:'100%',display:'grid',gridTemplateColumns:'auto auto'}}>
          {totalEndorsementCount && <img src={Crown} width='25' height='20' style={{marginTop:'5px'}}/>}
          {totalEndorsementCount && totalEndorsementCount > 2 && <p style={{lineHeight:'1ch',marginTop:"10px",marginRight:'5px',textAlign:'center'}}>{Math.floor(endorsementAvg)}</p>}
        </div>
      </div>
      {des && 
      <Typography varaint='caption' style={{color:'#707070'}}>
        {des}
      </Typography>}
    </div>
  )
}

export default Skills;
