import {useParams, withRouter} from "react-router-dom";
import React, {useEffect} from "react";

const SamlSingleSignOn = ({history}) => {
    const { token } = useParams();
    console.log('bhyve token for saml ', token);

    useEffect( () => {
        if(token && typeof(token) === 'string' && token !== '') {
            localStorage.setItem('bhyve-token', token);
            localStorage.setItem('okta-token-storage', JSON.stringify({type: 'SAML'}));
            history.push('/');
        }else {
            history.push('/logout');
        }
    })

    return(
        <div>
            Authenticating....
        </div>
    )
}

export default withRouter(SamlSingleSignOn);