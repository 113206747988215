/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography,TextField,Button,CircularProgress} from '@material-ui/core';
import Info from '../SubComponents/info/info';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EditIcon from '@material-ui/icons/Edit';
import InfoEdit from '../SubComponents/info-edit/info-edit';
import './contact-information-style.scss';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData,selectCompanyInformation,selectLocationData} from '../../redux/user/user-selector';
import {setUserData, setLocationData} from '../../redux/user/user-actions';
import Snackbar from '../SubComponents/snackbar/snackbar';
import Logout from '../../logout';
import {withRouter} from 'react-router-dom';
import axios from 'axios';

function ContactInformation({user,mode,info,userData,setUserData, setLocationData, companyInformation,locationData, handleEdit, handleDone, history}){

  const [details,setDetails] = useState({
    email:null,
    phone:null,
    userLocation:null
  });
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);
  const [phoneError,setPhoneError] = useState(false);

    const token = localStorage.getItem('bhyve-token');

useEffect(()=>{
  try {
    if(mode === 'view') {
      if(info) {
        setDetails({
          email:info.email,
          phone:info.phone,
          userLocation:info.userLocation
        });
      }
    }else{
      if(userData) {
        setDetails({
          email:userData.userInformation.email,
          phone:userData.userInformation.phone,
          userLocation:userData.userInformation.userLocation
        });
      }
    }  
  } catch (error) {
    console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
  }
},[info,mode,userData]);

  const handleChange= e => {
    const {value,name} = e.target;
    setDetails(prevValue => {
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  const handleSave = async () => {
    try {
      if(
        (userData.userInformation.userLocation !== details.userLocation) || 
        (userData.userInformation.phone !== details.phone.toString())
        ) {
            if(
              (details.phone.toString().length === 0) || 
              (details.phone.toString().replace(/\s/g, '').length > 0 && details.phone.toString().replace(/\s/g, '').length === 10) 
              ) {
              setPhoneError(false);
              setLoading(true);
              const data = {
                userLocation:details.userLocation,
                phone:details.phone
              };
              console.log(data);
              let updateUserProfileResult = await updateUserProfile(data);
              if(updateUserProfileResult) {
                console.log(updateUserProfileResult);
                const current = {
                  ...userData,
                  userInformation: updateUserProfileResult.data.user
                };
                setUserData(current);
                setLocationData(updateUserProfileResult.data.userLocation);
                setLoading(false);
                setMessage('Contact updated successfully!');
                setSeverity('success');
                setAlertOpen(true);
                handleDone();
              }
            }else{
              setPhoneError(true);
            }
        }else { 
          console.log('nothing changed!');
          handleDone();
        }
      } catch (error) {
          console.log(error);
          if(error.response && error.response.status === 401) {
            history.push('/logout');
          }
          setMessage('error');
          setSeverity('error');
          setAlertOpen(true);
          setLoading(false);
          handleDone();
      }
  };

  const updateUserProfile = async data => {
    const result = await axios.patch(`${process.env.REACT_APP_API_URL}user/me`,data,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  };

  return(
    mode === 'view' ?
    <Paper className='skillCard-container' style={{marginBottom:'30px'}}>
      <div style={{width:'100%',padding:'10px 10px'}}>
        <Typography variant='subtitle1' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5',display:'flex',justifyContent:'space-between'}}>
          <span style={{padding:'8px 0 3px 0'}} className='skill-card-title'>Contact Information</span>
          {user && <EditIcon style={{opacity:'0.6',cursor:'pointer'}} onClick={() => handleEdit()}/>}
        </Typography>
        <br/>
      <Info icon={<MailOutlineIcon/>} label='Email' data={details.email}/>
      <Info icon={<PhoneOutlinedIcon/>} label='Phone' data={details.phone}/>
      <Info icon={<LocationOnOutlinedIcon/>} label='Address' data={(!locationData.streetAddress || locationData.streetAddress.length === 0) ? `${ locationData.city}, ${locationData.country}` : `${locationData.streetAddress}, ${locationData.city}`}/>
      </div>
    </Paper>
    :
    <Paper className='skillCard-container' style={{marginBottom:'30px'}}>
      <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
      <div style={{width:'100%',padding:'10px 10px'}}>
        <Typography variant='subtitle1' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5'}}><span style={{padding:'8px 0 3px 0'}} className='skill-card-title'>Contact Information</span></Typography>
        <br/>
        <InfoEdit label='Email' disabled={true} value={details.email} onChange={handleChange}  type='text' icon={<MailOutlineIcon/>}/>
        <InfoEdit label='Phone' error={phoneError} value={details.phone} onChange={handleChange} name='phone' type='number' icon={<PhoneOutlinedIcon/>}/>
        <div className='edit-contact-content'>
          <Typography variant='body1' className='label'><span style={{marginRight:"8px"}}><LocationOnOutlinedIcon/></span>Address:</Typography>
          <TextField style={{width:'100%'}} value={details.userLocation} name='userLocation' onChange={handleChange} select
            SelectProps={{
                    native: true,
                  }}
            >
            {companyInformation && companyInformation.addresses.map((item,index)=>
              <option key={index} value={item.companyAddressPublicId}>{(!item.streetAddress || item.streetAddress.length === 0) ? `${ item.city}, ${item.country}` : `${item.streetAddress}, ${item.city}`}</option>
            )}
          </TextField>
        </div>
        {loading && <CircularProgress disableShrink />}
        {!loading && <Button className='edit-btn' onClick={handleSave}>Done</Button>}
      </div>
    </Paper>
  )
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  companyInformation:selectCompanyInformation,
  locationData:selectLocationData
});

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object)),
  setLocationData: object => dispatch(setLocationData(object))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ContactInformation));
