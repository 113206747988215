/*jshint esversion:9*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import configureStore from './redux/store';
import {persistGate} from 'redux-persist/integration/react';
import Amplify from '@aws-amplify/core';
import config from './aws-exports';

Amplify.configure(config);



const {store,persistor} = configureStore();
// console.log(store);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <persistGate persistor={persistor}>
    <App />
    </persistGate>
  </Router>
  </Provider>,
  document.getElementById('root')
);
