/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  IconButton,
  Chip,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grow from "@material-ui/core/Grow";
import PublishIcon from "@material-ui/icons/Publish";
import Input from "../Input/Input";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import "./edit-company-sub-modal-style.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeigth: "600px",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 450,
    maxWidth: 700,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    maxHeight: "700px",
    overflowY: "auto",
  },
}));

function EditCompanySubModal({
  Open,
  company,
  handleUpdate,
  selected,
  handleClose,
  handleBack,
  categories,
  handleDeleteAddress,
  departments,
  departmentsSelected,
  handleSyncWithDefaultPoints
}) {
  const classes = useStyles();
  const [details, setDetails] = useState({
    name: "",
    tagLine: "",
    addresses: [],
    logoDetails: [],
    logo: null,
  });
  const [edit, setEdit] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({
    skillCategory: {
      publicId: "",
      skillCategoryName: "",
    },
    skills: [],
  });
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [operation, setOperation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState({
    companyAddressPublicId: null,
    streetAddress: "",
    extendedStreetAddress: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const [skillList, setSkillList] = useState([]);
  const [actionPoints, setActionPoints] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [editLogoMode, setEditLogoMode] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [profitFactor, setProfitFactor] = useState(0);
  const [monthlyFreeAmount, setMonthlyFreeAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    console.log(company);
    setDetails((prevValue) => {
      return {
        ...prevValue,
        name: company.companyName,
        tagLine: company.tagLine,
        addresses: company.addresses,
        logoDetails: company.companyLogoDetails,
      };
    });
  }, [company, setDetails]);

  useEffect(() => {
    try {
      const getSelectedCategories = async (companyId) => {
        const result = await axios.get(
          `${process.env.REACT_APP_OWNER_URL}companySkillsCategories/company/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            },
          }
        );
        return result;
      };

      // const getSelectedDepartments = async(companyId) => {
      //   const reqUrl = String(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${companyId}`)
      //   const result = await axios.get(reqUrl, {
      //     headers:{
      //       'Authorization': `Bearer ${token}`,
      //       'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      //     }
      //   });
      //   return result;
      // };

      const getAllActionPoints = async (id) => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL_POINTS}customActionPoints/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              company: company.companyName,
            },
          }
        );
        return result;
      };

      const getDefaultPoints = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL_POINTS}default-action-points`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            },
          }
        );
        return result;
      };

      console.log(company);

      if (categories && selected === "categories") {
        console.log(categories);
        //   ;
        if (currentCategory.skillCategory.skillCategoryName !== "") {
          const category = categories.find(
            (item) =>
              item.skillCategory.skillCategoryName ===
              currentCategory.skillCategory.skillCategoryName
          );
          if (category) {
            const skills = category.skills;
            console.log(skills);
            setSkillList(skills);
          } else {
            setSkillList([]);
          }
          if (selectedCategories && currentCategory.skills.length === 0) {
            const exist = selectedCategories.find(
              (item) =>
                item.skillCategory.skillCategoryName ===
                currentCategory.skillCategory.skillCategoryName
            );
            if (exist) {
              setCurrentCategory((prevValue) => {
                return {
                  ...prevValue,
                  skills: exist.skills,
                };
              });
            }
          }
        }
        if (categoryList.length === 0) {
          // let currentList = []
          // currentList = categories;
          categories.map((item) =>
            setCategoryList((prevValue) => [...prevValue, item])
          );
        } // if(editCategory){

        if (!selectedCategories) {
          getSelectedCategories(company.companyPublicId)
            .then((res) => {
              console.log(res);
              if (res.data === "") {
                setSelectedCategories(null);
                setEditCategory(false);
              } else {
                setSelectedCategories(res.data.skillCategories);
                setEditCategory(true);
              }
            })
            .catch((err) => {
              console.log(err);
              alert(
                `An error occured while getting the skill categories for ${company.companyName}`
              );
            });
        }

        // }else{
        //   setSelectedCategories([]);
        // }
      }
      // if (selected==='departments') {
      //   if(!selectedDepartments.length) {
      //     getSelectedDepartments(company.companyPublicId)
      //       .then(res => {
      //         // setSelectedDepartments(res.data ?? [])
      //       })
      //       .catch(err => console.log(err))
      //   }
      // }

      if (selected === "points") {
        if (actionPoints && actionPoints.length === 0) {
          getAllActionPoints(company.companyPublicId)
            .then((res) => {
              console.log(res);
              if (res.data.length === 0) {
                console.log("length is zero");
                setEdit(false);
                getDefaultPoints()
                  .then((result) => {
                    console.log(result);
                    setActionPoints(result.data);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(
                      `An error occured while getting the action Points for ${company.companyName}`
                    );
                  });
              } else {
                console.log("length is not zero");
                setEdit(true);
                setActionPoints(res.data[0].actionPoints);
              }
              console.log(edit);
            })
            .catch((err) => {
              console.log(err);
              alert(
                `An error occured while getting the action Points for ${company.companyName}`
              );
            });
        }
      }
      console.log(categoryList);
    } catch (error) {
      console.log(error.message);
    }
  }, [
    actionPoints,
    categories,
    selected,
    company,
    categoryList,
    edit,
    token,
    skillList,
    currentCategory,
    selectedCategories,
  ]);

  useEffect(() => {
    if (departmentsSelected && departmentsSelected.length > 0) {
      setSelectedDepartments(departmentsSelected);
    } else {
      setSelectedDepartments([]);
    }
  }, [departmentsSelected]);

  const handleSelectDepartment = (department) => {
    const departmentExists = !!selectedDepartments.filter(
      (item) => item.departmentPublicId === department.departmentPublicId
    ).length;
    if (departmentExists) {
      const filteredDepartments = selectedDepartments.filter(
        (item) => item.departmentPublicId !== department.departmentPublicId
      );
      setSelectedDepartments(filteredDepartments);
    } else {
      setSelectedDepartments((state) => [...state, department]);
    }
  };

  useEffect(() => {
    if (selectAll) {
      setCurrentCategory((prevValue) => {
        return {
          ...prevValue,
          skills: skillList,
        };
      });
    } else {
      setCurrentCategory((prevValue) => {
        return {
          ...prevValue,
          skills: [],
        };
      });
    }
  }, [selectAll, skillList]);

  const handleLogo = (e) => {
    setDetails({ logo: e.target.files[0] });
  };

  const extractCompanyName = (str) =>
    (str.match(/^[a-zA-Z0-9-& ]+/) || []).pop() || "";
  const extractAlphaNumeric = (str) =>
    (str.match(/^[a-zA-Z ]*/) || []).pop() || "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (selected === "location") {
      setSelectedAddress((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setDetails((prevValue) => {
        return {
          ...prevValue,
          [name]: extractCompanyName(value),
        };
      });
    }
  };

  const handleChangeAplha = (e) => {
    const { name, value } = e.target;
    if (selected === "location") {
      setSelectedAddress((prevValue) => {
        return {
          ...prevValue,
          [name]: extractAlphaNumeric(value),
        };
      });
    } else {
      setDetails((prevValue) => {
        return {
          ...prevValue,
          [name]: extractCompanyName(value),
        };
      });
    }
  };

  const handleEditAddress = (index) => {
    setOperation("edit");
    setSelectedAddress(details.addresses[index]);
    //setEditCategory(true);
  };

  const handleAddAddress = () => {
    setOperation("add");
    setSelectedAddress({
      companyAddressPublicId: null,
      streetAddress: "",
      extendedStreetAddress: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    });
  };

  const handleCategorySelect = (e) => {
    try {
      const { value } = e.target;
      const category = categoryList.find(
        (item) => item.skillCategory.publicId === value
      );
      setCurrentCategory({
        skillCategory: {
          skillCategoryName: category.skillCategory.skillCategoryName,
          publicId: category.skillCategory.publicId,
          imageKey: category.skillCategory.imageKey,
          imageUrl: category.skillCategory.imageUrl,
          skillCategoryDisplayName:
            category.skillCategory.skillCategoryDisplayName,
          skillCategoryImage: category.skillCategory.skillCategoryImage,
        },
        skills: [],
      });
      console.log(skillList);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSkillSelect = (e) => {
    try {
      var current = currentCategory.skills;
      const { value, name } = e.target;
      const exist = current.find((item) => item.skillPublicId === value);
      if (!exist) {
        current.push({
          skillPublicId: value,
          skillDisplayName: name,
          skillName: name.toLowerCase(),
        });
      } else {
        current = current.filter((item) => item.skillPublicId !== value);
      }
      console.log(current);
      setCurrentCategory((prevValue) => {
        return {
          ...prevValue,
          skills: current,
        };
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const addToList = () => {
    try {
      console.log(selectedCategories);
      const exist =
        selectedCategories &&
        selectedCategories.find(
          (item) =>
            item.skillCategory.skillCategoryName ===
            currentCategory.skillCategory.skillCategoryName
        );
      if (exist) {
        const current = selectedCategories.map((item) =>
          item.skillCategory.skillCategoryName ===
          currentCategory.skillCategory.skillCategoryName
            ? { ...item, skills: currentCategory.skills }
            : item
        );
        console.log(current);
        setSelectedCategories(current);
      } else {
        if (selectedCategories) {
          setSelectedCategories((prevValue) => [...prevValue, currentCategory]);
        } else {
          setSelectedCategories([currentCategory]);
        }
      }
      setSkillList([]);
      setCurrentCategory({
        skillCategory: {
          publicId: "",
          skillCategoryName: "",
        },
        skills: [],
      });
      console.log(currentCategory);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEditCategory = (category) => {
    try {
      setCurrentCategory({
        skillCategory: {
          skillCategoryName: category.skillCategory.skillCategoryName,
          publicId: category.skillCategory.publicId,
          imageKey: category.skillCategory.imageKey,
          imageUrl: category.skillCategory.imageUrl,
          skillCategoryDisplayName:
            category.skillCategory.skillCategoryDisplayName,
          skillCategoryImage: category.skillCategory.skillCategoryImage,
        },
        skills: [],
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCategoryDelete = (id) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item.skillCategory.publicId !== id)
    );
  };

  const handleActionPointChange = (index) => (e) => {
    try {
      var { value, name } = e.target;
      if (value === "") {
        value = null;
      } else {
        value = parseInt(value);
      }
      setActionPoints(
        actionPoints.map((item) =>
          item.action === name ? { ...item, points: value } : item
        )
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCategoryUpdate = () => {
    if (selectedCategories && selectedCategories.length > 0) {
      setEditCategory(true);
    }
    handleUpdate(selectedCategories, editCategory);
    setSelectedCategories(null);
  };

  const handleLogoSelect = (data) => {
    console.log(data);
    setSelectedLogo(data);
  };

  const handleAddLogo = () => {
    setSelectedLogo(null);
    setDetails((prevValue) => {
      return {
        ...prevValue,
        logo: null,
      };
    });
    setEditLogoMode(true);
  };

  const handleEditLogo = () => {
    setDetails((prevValue) => {
      return {
        ...prevValue,
        logo: null,
      };
    });
    setEditLogoMode(true);
  };

  const handleUpdatingLogo = async (operation) => {
    setLogoLoading(true);
    if (operation === "add") {
      if (details.logo) {
        let body = new FormData();
        body.append("companyLogo", details.logo);
        let addLogoResult = await axios.post(
          `${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${company.companyPublicId}/logo`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            },
          }
        );
        console.log(addLogoResult);
        setDetails((prevValue) => {
          return {
            ...prevValue,
            logoDetails: addLogoResult.data.companyLogoDetails,
            logo: null,
          };
        });
        setLogoLoading(false);
        setEditLogoMode(false);
      } else {
        alert("please upload a logo first!");
        setLogoLoading(false);
      }
    }

    if (operation === "edit") {
      //debugger;
      if (details.logo) {
        let body = new FormData();
        body.append("companyLogo", details.logo);
        body.append("logoName", selectedLogo.imageKey);
        let updateLogoResult = await axios.patch(
          `${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${company.companyPublicId}/logo`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            },
          }
        );
        setDetails((prevValue) => {
          return {
            ...prevValue,
            logoDetails: updateLogoResult.data.companyLogoDetails,
            logo: null,
          };
        });
        setLogoLoading(false);
        setSelectedLogo(null);
        setEditLogoMode(false);
      } else {
        alert("please upload a logo first!");
        setLogoLoading(false);
      }
    }
  };

  const handleDeleteLogo = async () => {
    try {
      //debugger;
      let reqObject = {
        logoImageKey: selectedLogo.imageKey,
      };

      const deleteLogoResult = await axios.delete(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${company.companyPublicId}/logo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
          data: reqObject,
        }
      );
      //debugger;
      console.log(deleteLogoResult);
      setDetails((prevValue) => {
        return {
          ...prevValue,
          logoDetails: deleteLogoResult.data.companyLogoDetails,
          logo: null,
        };
      });
      setLogoLoading(false);
      setSelectedLogo(null);
    } catch (error) {
      console.log(error);
    }
  };

  const makePayment = () => {
    const payload = {
      companyPublicId: company.companyPublicId,
      totalAmountPaid: parseInt(amountPaid),
    };
    axios
      .post(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/mc/overall-pricing/amount-paid`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      )
      .then(() => {
        alert("Updated");
      })
      .catch(() => {
        alert("Unable to Update! Try Again");
      });
  };

  const [bill, setBill] = useState({});

  const updateMasterclassDetails = () => {
    const payload = {
      companyPublicId: company.companyPublicId,
      monthlyFreeAmount: parseInt(monthlyFreeAmount),
      profitFactor: parseInt(profitFactor),
    };
    axios
      .post(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/mc/company-pricing`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      )
      .then(() => {
        alert("Updated");
      })
      .catch(() => {
        alert("Unable to Update! Try Again");
      });
  };

  const fetchPricing = () => {
    axios
      .get(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/mc/overall-pricing/${company.companyPublicId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      )
      .then((e) => {
        console.log(e.data)
        setBill(e.data)
      })
      .catch(() => {
        alert("Unable to Fetch Bill! Try Again");
      });
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={Open} timeout={1000}>
          <div className={classes.paper}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                marginBottom: "50px ",
              }}
            >
              {selected === "logo"
                ? `Upload a new logo for ${company.companyName}`
                : selected === "other"
                ? `Fill new details for ${company.companyName}`
                : selected === "categories"
                ? `Add/Edit Skill Categories for ${company.companyName}`
                : selected === "points"
                ? `Add/Edit Action Points for ${company.companyName}`
                : selected === "departments"
                ? `Add/Edit departments for ${company.companyName}`
                : `Add/Edit addresses for ${company.companyName}`}
            </Typography>
            <div className="modal-form">
              {selected === "logo" ? (
                <div className="form" style={{ width: "80%" }}>
                  <div className="modal-form-input">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "80% 20%",
                        width: "100%",
                        padding: "0 20px",
                      }}
                    >
                      {!editLogoMode && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxHeight: "200px",
                            overflowY: "auto",
                            gap: "15px",
                          }}
                        >
                          <div
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              border: "1px solid gray",
                              height: "fit-content",
                            }}
                          >
                            <IconButton onClick={() => handleAddLogo()}>
                              <AddIcon />
                            </IconButton>
                          </div>
                          {details &&
                            details.logoDetails.length > 0 &&
                            details.logoDetails.map((item) => (
                              <div
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                  border:
                                    selectedLogo &&
                                    selectedLogo.imageKey === item.imageKey &&
                                    "1px solid gray",
                                }}
                                onClick={() => handleLogoSelect(item)}
                              >
                                <img
                                  src={item.imageUrl}
                                  alt="logo"
                                  width="80"
                                />
                              </div>
                            ))}
                        </div>
                      )}
                      {editLogoMode && (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          {selectedLogo && (
                            <img
                              src={selectedLogo.imageUrl}
                              alt={selectedLogo.imageKey}
                              width="100"
                            />
                          )}
                          <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "100%" }}>
                              <TextField
                                className="input"
                                id="input-with-icon-textfield"
                                label="Logo"
                                type="text"
                                value={details.logo && details.logo.name}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                SelectProps={{
                                  native: true,
                                }}
                              />
                              <input
                                type="file"
                                id="logo-upload"
                                onChange={handleLogo}
                                style={{ display: "none" }}
                              />
                            </div>
                            <div>
                              <label htmlFor="logo-upload">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <PublishIcon />
                                </IconButton>
                              </label>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: "5px",
                            }}
                          >
                            {!logoLoading && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleUpdatingLogo(
                                    selectedLogo ? "edit" : "add"
                                  )
                                }
                              >
                                {selectedLogo ? "Update" : "Add"}
                              </Button>
                            )}
                            {!logoLoading && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setEditLogoMode(false)}
                              >
                                Cancel
                              </Button>
                            )}
                            {logoLoading && (
                              <CircularProgress color="primary" />
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          borderLeft: "1px solid gray",
                          paddingLeft: "5px",
                        }}
                      >
                        <Button
                          style={{ width: "80px", marginBottom: "5px" }}
                          variant="contained"
                          disabled={!selectedLogo}
                          onClick={handleEditLogo}
                        >
                          Edit
                        </Button>
                        <Button
                          style={{ width: "80px" }}
                          variant="contained"
                          disabled={!selectedLogo}
                          onClick={handleDeleteLogo}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    {/* <div style={{display:'flex',width:'100%'}}>
                  <div style={{width:'100%'}}>
                    <TextField
                    className='input'
                    id="input-with-icon-textfield"
                    label='Logo'
                    type='text'
                    value={details.logo&&details.logo.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      native: true,
                    }}

                    />
                      <input type='file' id='logo-upload' onChange={handleLogo} style={{display:'none'}}/>
                    </div>
                    <div >
                      <label htmlFor='logo-upload'>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <PublishIcon/>
                        </IconButton>
                      </label>
                    </div>
                </div> */}
                  </div>
                </div>
              ) : selected === "other" ? (
                <div className="form">
                  <div className="modal-form-input">
                    <Input
                      label="Company Name"
                      name="name"
                      type="text"
                      value={details.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="modal-form-input">
                    <Input
                      label="Tag Line"
                      type="text"
                      name="tagLine"
                      value={details.tagLine}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ) : selected === "points" ? (
                <div className="modal-form">
                  <div className="points-grid">
                    {actionPoints.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor={item.action}
                          style={{ width: "100%", fontWeight: "600" }}
                        >
                          {item.action}
                        </label>
                        <TextField
                          id={item.action}
                          style={{ width: "100%" }}
                          type="number"
                          value={item.points}
                          onChange={handleActionPointChange(index)}
                          name={item.action}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : selected === "categories" ? (
                <div className="categories-add-container">
                  <div className="category-form">
                    <TextField
                      style={{ width: "100%" }}
                      select
                      value={currentCategory.skillCategory.publicId}
                      onChange={handleCategorySelect}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value={null}>none</option>
                      {categoryList &&
                        categoryList.map((item, index) => (
                          <option
                            key={index}
                            value={item.skillCategory.publicId}
                          >
                            {item.skillCategory.skillCategoryName}
                          </option>
                        ))}
                    </TextField>
                    <div className="category-form-skills-container">
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={() => setSelectAll(!selectAll)}
                              color="primary"
                            />
                          }
                          label={"Select All"}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          overflowY: "auto",
                          maxHeight: "200px",
                        }}
                      >
                        {skillList.map((item) => (
                          <FormControlLabel
                            key={item.skillPublicId}
                            control={
                              <Checkbox
                                checked={
                                  currentCategory.skills.find(
                                    (skill) =>
                                      skill.skillPublicId === item.skillPublicId
                                  ) || selectAll
                                }
                                value={item.skillPublicId}
                                onChange={handleSkillSelect}
                                name={item.skillDisplayName}
                                color="primary"
                              />
                            }
                            label={item.skillDisplayName}
                          />
                        ))}
                      </div>
                    </div>
                    <Button
                      disabled={currentCategory.skills.length === 0}
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={addToList}
                    >
                      ADD
                    </Button>
                  </div>
                  <div className="selected-categories-container">
                    {selectedCategories &&
                      selectedCategories.map((item, index) => (
                        <Chip
                          style={{ margin: "5px", color: "white" }}
                          color="primary"
                          onClick={() => handleEditCategory(item)}
                          key={index}
                          label={item.skillCategory.skillCategoryName}
                          deleteIcon={<CancelIcon style={{ color: "white" }} />}
                          onDelete={() =>
                            handleCategoryDelete(item.skillCategory.publicId)
                          }
                        />
                      ))}

                    {selectedCategories ? (
                      selectedCategories.length === 0 ? (
                        <Typography variant="subtitle1">
                          No categories added yet !
                        </Typography>
                      ) : (
                        <div></div>
                      )
                    ) : (
                      <Typography variant="subtitle1">
                        No categories added yet !
                      </Typography>
                    )}
                  </div>
                </div>
              ) : selected === "location" ? (
                <div className="modal-form">
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                      color="primary"
                      button
                      onClick={handleAddAddress}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </div>
                  {details.addresses &&
                    details.addresses.map((item, index) => (
                      <div className="address-container">
                        <Typography variant="subtitle1">{`${item.streetAddress}, ${item.extendedStreetAddress}, ${item.city}, ${item.state}, ${item.country}-${item.zipCode}`}</Typography>
                        <IconButton
                          button
                          onClick={() => handleEditAddress(index)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          button
                          onClick={() =>
                            handleDeleteAddress(item.companyAddressPublicId)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  <div
                    className="form"
                    style={{ display: operation ? "" : "none" }}
                  >
                    <div className="modal-form-input">
                      <Input
                        label="Address"
                        name="streetAddress"
                        type="text"
                        value={selectedAddress.streetAddress}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="modal-form-input">
                      <Input
                        label="Street Address"
                        name="extendedStreetAddress"
                        type="text"
                        value={selectedAddress.extendedStreetAddress}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="modal-form-input">
                      <Input
                        label="City"
                        name="city"
                        type="text"
                        value={selectedAddress.city}
                        onChange={handleChangeAplha}
                      />
                    </div>
                    <div className="modal-form-input">
                      <Input
                        label="State"
                        name="state"
                        type="text"
                        value={selectedAddress.state}
                        onChange={handleChangeAplha}
                      />
                    </div>
                    <div className="modal-form-input">
                      <Input
                        label="Country"
                        name="country"
                        type="text"
                        value={selectedAddress.country}
                        onChange={handleChangeAplha}
                      />
                    </div>
                    <div className="modal-form-input">
                      <Input
                        label="ZipCode"
                        name="zipCode"
                        type="number"
                        value={selectedAddress.zipCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              ) : selected === "departments" ? (
                <div className="modal-form">
                  {departments ? (
                    departments.length > 0 ? (
                      departments.map((department) => (
                        <FormControlLabel
                          key={department.id}
                          label={department.departmentDisplayName}
                          control={
                            <Checkbox
                              checked={
                                !!selectedDepartments.filter(
                                  (item) =>
                                    item.departmentPublicId ===
                                    department.departmentPublicId
                                ).length
                              }
                              value={department.departmentPublicId}
                              onChange={() =>
                                handleSelectDepartment(department)
                              }
                              name={department.departmentDisplayName}
                              color="primary"
                            />
                          }
                        />
                      ))
                    ) : (
                      <p>
                        No departments are added. Please add departments in the
                        department section.
                      </p>
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              ) : selected === "masterclass" ? (
                <div className="form">
                  <div className="modal-form-input">
                    <Input
                      label="Monthly Free Amount($)"
                      name="monthlyAmount"
                      type="number"
                      value={monthlyFreeAmount}
                      onChange={(e) => setMonthlyFreeAmount(e.target.value)}
                    />
                  </div>
                  <div className="modal-form-input">
                    <Input
                      label="Profit Factor"
                      name="profitFactor"
                      type="number"
                      value={profitFactor}
                      onChange={(e) => setProfitFactor(e.target.value)}
                    />
                  </div>
                  <div className="modal-form-input">
                    <Input
                      label="Make Payment($)"
                      name="amountPaid"
                      type="number"
                      value={amountPaid}
                      onChange={(e) => setAmountPaid(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      spacing: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => updateMasterclassDetails()}
                      style={{
                        marginRight: "10px",
                        color: "#66CCFF",
                        borderColor: "#66CCFF",
                      }}
                    >
                      Update Profit Factor and Monthly Free Minutes
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        marginRight: "10px",
                        color: "#66CCFF",
                        borderColor: "#66CCFF",
                      }}
                      onClick={() => makePayment()}
                    >
                      Make Payment
                    </Button>
                  </div>
                </div>
              ) : null}
              <div style={{ width: "100%", marginTop: "10px" }}>
                {selected !== "logo" && selected !== "masterclass" && (
                  <Button
                    variant="outlined"
                    style={{
                      marginRight: "10px",
                      color: "#66CCFF",
                      borderColor: "#66CCFF",
                    }}
                    onClick={() => {
                      selected === "location"
                        ? handleUpdate(selectedAddress, null)
                        : selected === "categories"
                        ? handleCategoryUpdate()
                        : selected === "points"
                        ? handleUpdate(actionPoints, edit)
                        : selected === "departments"
                        ? handleUpdate(selectedDepartments)
                        : handleUpdate(details, null);
                    }}
                  >
                    Update
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleBack()}
                >
                  Go back
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleSyncWithDefaultPoints()}}
                >
                  Sync With Default Points
                </Button>
              </div>
              <div style={{ width: "100%", marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  style={{ color: "red", borderColor: "red" }}
                  onClick={() => {
                    setSelectedCategories(null);
                    setActionPoints([]);
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Grow>
      </Modal>
    </div>
  );
}

// const mapStateToProps = createStructuredSelector({
//   categories:selectCategories
// });

export default EditCompanySubModal;
