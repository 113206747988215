/*jshint esversion:9*/

import React,{useEffect} from 'react';
import {Switch,Route} from 'react-router-dom';
import AddClient from '../../../Components/add-client/add-client-component';
import ClientList from '../../../Components/client-list/client-list-component';
import axios from 'axios';
import {connect} from 'react-redux';
import {setCompanyList} from '../../../redux/company/company-actions';
import './client-page-style.scss';
import Logout from '../../../logout';
import { selectUserData } from "../../../redux/user/user-selector";
import { createStructuredSelector } from "reselect";

function ClientPage({setCompanyList, userData}){
  const token = localStorage.getItem('bhyve-token');

  useEffect(()=>{
    console.log('hit');
    async function gettingCompanies(){
      
      try {
        let getCompaniesResult = await getCompanies();
        if(getCompaniesResult){
          console.log(getCompaniesResult);
        setCompanyList(getCompaniesResult.data);
        }
      } catch (err) {
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
      }
    }
    gettingCompanies();
  },[setCompanyList]);

  const getCompanies = async() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

  return(
    <div className='client-container'>
    <Switch>
      <Route exact path='/OwnerPanel/Client/AddClient'  component={AddClient}/>
      <Route exact path='/OwnerPanel/Client/ClientList'  component={ClientList}/>
    </Switch>
    </div>
  )
}

const  mapDispatchToProps = dispatch => ({
  setCompanyList : array => dispatch(setCompanyList(array))
});

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);
