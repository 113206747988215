/*jshint esversion:9*/
// import RoutingUrlForLoginApp from "./Pages/routing-url";


// export default function Logout(){
//     const routingUrlForLoginApp = RoutingUrlForLoginApp();
//     localStorage.clear();
//     window.location.href=`${routingUrlForLoginApp}/logout`;
// }



import React,{useEffect, useState} from 'react';
import Loader from './Components/SubComponents/loader/loader-component';
import {Button} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import logoutImage from './Assets/Logout.png';
import './logout.scss';
import axios from "axios";

 const Logout = ({history}) => {

    const { oktaAuth, authService } = useOktaAuth();
    const [loggingOut, setLoggingOut] = useState(false);

    const handleLogoutApi = async () => {
        console.log('calling logout api...')
        const token = await localStorage.getItem("bhyve-token");

        if(!token) return

        await axios.get(
            `${process.env.REACT_APP_API_URL}user/me/logout`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
        );

        window.localStorage.clear();
    }

    const logout = async () => {
        setLoggingOut(true);
        try {
            authService.logout('/login');
            setLoggingOut(false);
        } catch (error) {
            console.log('error');
            setLoggingOut(false);
        }
    };

    useEffect(() => {
        handleLogoutApi();
    },[]);

    const logoutUser = () => {
        logout();
        history.push('/login');
    }

    return(
        <div className='logout-page-container'>
            <img src={logoutImage} alt='logout-image' width='280'/>
            <h3>Thanks for visiting BHyve</h3>
            <h4>You are now logged out.</h4>

            {!loggingOut ? <Button className='log-back-btn' onClick={logoutUser}>Log Back In</Button>
            :
            <Loader open={true} text='logging out'/>}
        </div>
    )
}

export default withRouter(Logout);