/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import BasicProfile from "../../../Components/basicProfile/basicProfile-component";
import ThreeInOne from "../../../Components/three-in-one/three-in-one-component";
import ProfileCompletion from "../../../Components/profile-completion/profile-completion";
import Loader from "../../../Components/SubComponents/loader/loader-component";
import SkillCard from "../../../Components/skillCard/skillCard-component";
import InterestView from "../../../Components/interest-view/interest-view-component";
import RecommContainer from "../../../Components/recommendation-container/recommendation-container-component";
import UserPoints from "../../../Components/userPoints/userPoints-component";
import UserBadges from "../../../Components/userBadges/userBadges-component";
import Experience from "../../../Components/experience/experience-component";
import ContactInformation from "../../../Components/contact-information/contact-information-component";
import BasicInformation from "../../../Components/basic-information/basic-information-component";
import PublishedArticleByAuthor from "../cms/util/PublishedArticleByAuthor";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectUserPoints,
  selectLocationData,
  selectCompanyInformation,
} from "../../../redux/user/user-selector";
import { setUserData } from "../../../redux/user/user-actions";
import { useLocation } from "react-router";
import Logout from "../../../logout";
import "./ViewProfile-style.scss";

function ViewProfile({
  setUserData,
  userData,
  match,
  locationData,
  history,
  companyDetails,
  ...props
}) {
  const [User, setUser] = useState(null);
  const [onload, setOnLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [openAddExp, setOpenAddExp] = useState(false);

  const search = useLocation().search;

  // useEffect(() => {

  // },[userData]);

  useEffect(() => {
    if (!companyDetails?.companyPublicId) {
      return;
    }
    if (departments.length) return;
    const getDepartments = async (companyPublicId) => {
      const token = await localStorage.getItem("bhyve-token");
      const result = await axios.get(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${companyPublicId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );

      const departments = result.data?.companyDepartments ?? [];
      setDepartments(departments);
    };
    getDepartments(companyDetails.companyPublicId);
  }, [companyDetails]);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    window.scroll(0, 0);
  }, [onload]);

  const scrollToExperience = new URLSearchParams(search).get(
    "scrollToExperience"
  );
  if (
    document.getElementById("experience-container") &&
    scrollToExperience === "true"
  ) {
    document.getElementById("experience-container").scrollIntoView();
    setTimeout(() => setOpenAddExp(true), 1000);
  }

  const handleEdit = () => {
    setEditProfile(true);
  };

  useEffect(() => {
    try {
      const getuserInformation = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}user/view/${match.params.userId}`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };

      async function gettingOtherUserInformation() {
        try {
          let getUserInformationResult = await getuserInformation();
          if (getUserInformationResult) {
            setUser(getUserInformationResult.data);
          }
        } catch (err) {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }

      if (userData) {
        if (match.params.userId === userData.userInformation.publicId) {
          setUser(userData.userInformation);
        } else {
          gettingOtherUserInformation();
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  }, [match, userData]);

  const handleProfilePicChange = async (e) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      try {
        let uploadProfilePicResult = await uploadProfilePic(
          formData,
          e.target.files[0].type
        );
        if (uploadProfilePicResult) {
          const current = {
            ...userData,
            userInformation: uploadProfilePicResult.data,
          };
          setUserData(current);
          setLoading(false);
          window.location.reload(true);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push("/logout");
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        history.push("/logout");
      }
      setLoading(false);
    }
  };

  const uploadProfilePic = async (formData, type) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/me/profile-picture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const handleUserBadges = (badges) => {
    let currentUser = User;
    currentUser = {
      ...currentUser,
      badges: badges,
    };
    setUser(currentUser);
  };

  return (
    <div className="profile-container">
      <Loader open={loading} />
      {User && User.profileCompleted && (
        <div className="profile-grid">
          <div className="profile-grid-top">
            <BasicProfile
              mode={editProfile ? "edit" : "view"}
              info={User && User}
              handleEdit={handleEdit}
              user={match.params.userId === userData.userInformation.publicId}
              currentUserId={userData.userInformation.publicId}
              Location={
                match.params.userId === userData.userInformation.publicId
                  ? locationData
                  : User.userLocation
              }
              handleProfilePicChange={handleProfilePicChange}
              setUserBadges={handleUserBadges}
            />
            {match && !editProfile && (
              <ThreeInOne
                user={match.params.userId === userData.userInformation.publicId}
                badges={
                  match &&
                  match.params.userId === userData.userInformation.publicId
                    ? userData.userInformation.badges
                    : User.badges
                }
                currentUser={match.params.userId}
              />
            )}
            {editProfile && (
              <BasicInformation
                info={userData.userInformation}
                mode={editProfile ? "edit" : "view"}
                handleEditDone={() => setEditProfile(false)}
                companyDepartments={departments}
              />
            )}
          </div>
          {!profileComplete &&
            match.params.userId === userData.userInformation.publicId && (
              <div className="profile-grid-2">
                <ProfileCompletion
                  handleProfileComplete={() => setProfileComplete(true)}
                  handleEdit={() => setEditProfile(true)}
                />
              </div>
            )}
          <div className="profile-grid-3">
            <SkillCard
              info={User && User.userSkillsSet}
              user={match.params.userId === userData.userInformation.publicId}
              handleEdit={() =>
                history.push("/User/EditProfile/EditSkillsCategories")
              }
            />
            <InterestView
              UserInfo={User && User}
              user={match.params.userId === userData.userInformation.publicId}
              handleEdit={() => history.push("/User/EditProfile/Interest")}
            />
          </div>
          <div className="profile-grid-bottom">
            <RecommContainer
              userId={User && User.publicId}
              navigateToUser={(id) => {
                history.push(`/User/Profile/${id}`);
                window.scrollTo(0, 0);
              }}
            />
            <div style={{ width: "100%" }}>
              {match.params.userId === userData.userInformation.publicId && (
                <ContactInformation
                  mode={editContact ? "edit" : "view"}
                  info={User && User}
                  user={
                    match.params.userId === userData.userInformation.publicId
                  }
                  handleDone={() => setEditContact(false)}
                  handleEdit={() => setEditContact(true)}
                />
              )}
              <Experience
                info={User && User}
                mode="edit"
                user={match.params.userId === userData.userInformation.publicId}
                companyDepartments={departments}
                openModal={openAddExp}
              />
            </div>
          </div>
          <PublishedArticleByAuthor userId={match.params.userId} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  locationData: selectLocationData,
  companyDetails: selectCompanyInformation,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewProfile)
);

// {User&&User.profileCompleted&&
//   <div className='profile-grid'>
//   <div className='profile-col-left'>
//   <BasicProfile mode='view' info={User&&User} handleEdit={handleEdit} user={match.params.userId === userData.userInformation.publicId} currentUserId={userData.userInformation.publicId} Location={match.params.userId === userData.userInformation.publicId ? locationData : User.userLocation}/>
//   <UserPoints points={match.params.userId === userData.userInformation.publicId ? userPoints : otherUserPoints} user={match.params.userId === userData.userInformation.publicId}/>
//   <UserBadges badges={match.params.userId === userData.userInformation.publicId ? userData.userInformation.badges : User.badges} user={match.params.userId === userData.userInformation.publicId} currentUser={match.params.userId}/>
//   </div>
//   <div className='profile-col-right'>
//   <SkillCard info={User&&User.userSkillsSet}/>
//   <Experience mode='view' info={User&&User.workExperience}/>
//   {userData&&match.params.userId === userData.userInformation.publicId&&<ContactInformation mode='view' info={User&&User}/>}
//   {userData&&match.params.userId === userData.userInformation.publicId&&<BasicInformation mode='view' info={User&&User}/>}
//   </div>
// </div>}
// {User&&!User.profileCompleted&&
// <div>
//   <Typography variant='h3' style={{fontWeight:'bold',textAlign:'center'}}>Oops !</Typography>
//   <br/>
//   <Typography variant='h6'>This user haven't completed their profile yet !</Typography>
//   <br/>
//   <div style={{textAlign:'center'}}>
//     <Button className='edit-btn' onClick={()=>history.push('/User/Dashboard')}>Go back to Dashboard</Button>
//   </div>
// </div>
// }
