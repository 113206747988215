import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Typography,
  Avatar,
  IconButton,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Menu,
  MenuItem,
  makeStyles,
  Popover,
} from "@material-ui/core";
import AddCollabSkillModal from "../SubComponents/add-collab-skill-modal/add-collab-skill-modal";
import EndorsementModal from "../SubComponents/endorsement-modal/endorsement-modal";
import SuccessModal from "../SubComponents/ack-endorse-success-modal/ack-endorse-success-modal";
import ChatBoxChat from "../SubComponents/chat-box-chat/index";
import Snackbar from "../SubComponents/snackbar/snackbar";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { withRouter } from "react-router-dom";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CancelIcon from "@material-ui/icons/Cancel";
import GradeIcon from "@material-ui/icons/Grade";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Logout from "../../logout";
import axios from "axios";
import "./style.scss";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

const ChatBoxChatMemo = React.memo(ChatBoxChat);

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: "10px",
    gap: "10px",
  },
}));

function ChatBox({
  convDetails,
  messageLoading,
  userId,
  handleChatSend,
  uploading,
  handleFileChange,
  handleDeleteMessage,
  addSkillToConversation,
  history,
  tourStatus,
  showFileSizeExceededOverlay,
  setShowFileSizeExceededOverlay,
}) {
  const messageContainer = useRef(null);
  const [message, setMessage] = useState({
    roomId: "",
    author: userId,
    contentType: "text",
    body: "",
  });
  const [Attachments, setAttachments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);
  const [collabString, setCollabString] = useState("");
  const [endorsementDetails, setEndorsementDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successRole, setSuccessRole] = useState(0);
  const [success, setSuccess] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [addSkillModal, setAddSkillModal] = useState(false);
  const [addSkillModalDetails, setAddSkillModalDetails] = useState({
    collabDetails: {},
    userDetails: {},
  });
  const [endorse, setEndorse] = useState(false);
  const [canEndorse, setCanEndorse] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const token = localStorage.getItem("bhyve-token");

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenEmojiMenu = (e) => {
    if (!emojiAnchorEl) {
      setEmojiAnchorEl(e.currentTarget);
    } else {
      setEmojiAnchorEl(null);
    }
  };

  const handleEmojiMenuClose = () => {
    setEmojiAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //     debugger;
  //     console.log(Attachments);
  // },[Attachments]);

  useEffect(() => {
    if (!tourStatus) {
      let element = document.getElementById("chat-more");
      console.log("more menu: ", element);
      setAnchorEl(element);
    } else {
      setAnchorEl(null);
    }
  }, [tourStatus]);

  useEffect(() => {
    // document.addEventListener("keydown", handleKeyPress, false);
    if (convDetails) {
      console.log(convDetails);
      //debugger;
      if (messageContainer) {
        setMessage((prevValue) => {
          return {
            ...prevValue,
            roomId: convDetails.collabDetails.collaborationPublicId,
          };
        });
        if (messageContainer.current) {
          // messageContainer.current.addEventListener('DOMNodeInserted', event => {
          // console.log('entered');
          //   const { currentTarget: target } = event;
          //   console.log(target);
          //   target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
          // });
          messageContainer.current.scroll({
            top: messageContainer.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }
      let collabSkills = [];
      convDetails.collabDetails.collaborationSkill.map((item, index) =>
        collabSkills.push(item.skillDisplayName)
      );
      setCollabString(collabSkills.join());
      convDetails.collabDetails.collaborationSkill.forEach((skill) => {
        if (skill.menteeId === userId) {
          setCanEndorse(true);
        }
      });

      // return () => {
      //     document.removeEventListener("keydown", handleKeyPress, false);
      //   };
    }
  }, [convDetails, userId]);

  const handleMessageChange = (e) => {
    const { value } = e.target;
    setMessage((prevValue) => {
      return {
        ...prevValue,
        body: value,
      };
    });
  };

  const handleKeyPress = (e) => {
    console.log(e);
    console.log(e.keyCode);
    console.log(message);
    if (e.keyCode === 13 && message.body !== "") {
      //   e.preventDefault();
      setMessage((prevValue) => {
        return {
          ...prevValue,
          body: "",
        };
      });
      handleChatSend(message);
    }
  };

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setMessage((prevValue) => {
      return {
        ...prevValue,
        body: prevValue.body + emoji,
      };
    });
  };

  // const handleKeyPress =  useCallback( e => {
  //     console.log(e);
  //     console.log(e.keyCode);
  //     console.log(message);
  //     if(e.keyCode === 13 && message.body !== ''){
  //     //   e.preventDefault();
  //       setMessage(prevValue => {
  //         return{
  //             ...prevValue,
  //             body: ''
  //         }
  //     });
  //     handleChatSend(message);
  //      }
  // },[])

  const handleEndorsement = () => {
    try {
      //console.log(obj);
      //let endorsedSkill = convDetails.collabDetails.collaborationSkill.find(i => i.collaborationSkillPublicId === selectedSkill);
      let endorseableSkills = [];
      convDetails.collabDetails.collaborationSkill.map((item) =>
        item.mentorId !== userId && Object.keys(item.review).length === 0
          ? endorseableSkills.push(item)
          : item
      );
      if (endorseableSkills.length > 0) {
        let obj = {
          collabId: convDetails.collabDetails.collaborationPublicId,
          user: convDetails.participants,
          skills: endorseableSkills,
        };
        console.log(obj);
        setEndorsementDetails(obj);
        setEndorse(true);
      } else {
        setAlertMessage("No more skills to endorse.");
        setSeverity("warning");
        setAlertOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleEndorsementSubmit = async (
    review,
    recommendation,
    collaborationSkillPublicId
  ) => {
    try {
      setLoading(true);
      let reqObject = {
        collaborationPublicId: convDetails.collabDetails.collaborationPublicId,
        collaborationSkillPublicId: collaborationSkillPublicId,
        review: review,
        recommendation: recommendation,
      };
      let skillName = convDetails.collabDetails.collaborationSkill.find(
        (item) => item.collaborationSkillPublicId === collaborationSkillPublicId
      ).skillDisplayName;

      console.log(reqObject);

      const endorseResult = await axios.patch(
        `${process.env.REACT_APP_API_URL}collaborationSkill/endorse`,
        reqObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );

      if (endorseResult) {
        console.log(endorseResult);
        addSkillToConversation(endorseResult.data, endorsementDetails.collabId);
        setLoading(false);
        setEndorse(false);
        setEndorsementDetails(null);
        setSelectedSkill(skillName);
        setSuccessRole(1);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 1500);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleAddSkill = () => {
    setAddSkillModalDetails({
      collabDetails: convDetails.collabDetails,
      userDetails: convDetails.participants,
    });
    setAddSkillModal(true);
  };

  const handleAddSuccess = (data) => {
    addSkillToConversation(data, data.collaborationPublicId);
    // let current = conversations;
    // current = current.map(item => item.collabDetails.collaborationPublicId === data.collaborationPublicId ? {...item,collabDetails: data} : item);
    // setConversations(current);
  };

  const hideFileSizeExceededOverlay = () => {
    setShowFileSizeExceededOverlay(false);
  };

  const classes = useStyles();
  return (
    <div
      className={`chatbox-container ${uploading && "chat-box-overlay"} 
      ${showFileSizeExceededOverlay && "filesize-exceeded-overlay"}`}
    >
      {uploading && (
        <div className="chatbox-uploading-container">
          <CircularProgress />
          <p>Uploading...</p>
        </div>
      )}
      {showFileSizeExceededOverlay && (
        <div
          className="filesize-exceeded-container"
          onClick={() => {
            hideFileSizeExceededOverlay();
          }}
        >
          <WarningRoundedIcon
            color="primary"
            style={{ fontSize: "100px", color: "#FFCC33" }}
          />
          <p style={{ fontSize: "18", fontWeight: "bold" }}>
            The file exceeds the size limit
          </p>
          <p>Maximum file size is 15MB</p>
        </div>
      )}
      <div className="chat-box-top">
        <div className="chat-box-top-name-avatar">
          {convDetails.participants.profilePhotoUrl !== "" && (
            <Avatar
              className="chat-participant-avatar"
              onClick={() =>
                history.push(`/User/Profile/${convDetails.participants.id}`)
              }
              src={convDetails.participants.profilePhotoUrl}
            />
          )}
          {convDetails.participants.profilePhotoUrl === "" && (
            <Typography
              variant="subtitle2"
              className="initial-container"
              onClick={() =>
                history.push(`/User/Profile/${convDetails.participants.id}`)
              }
              style={{
                cursor: "pointer",
                textAlign: "center!important",
                display: "flex",
                justifyContent: "center",
                paddingTop: "8px",
                color: "white",
                background: "#FFCC33",
                width: "40px",
                height: "40px",
                margin: "auto 0",
              }}
            >
              {`${convDetails.participants.name
                .split(" ")[0]
                .slice(0, 1)}${convDetails.participants.name
                .split(" ")[1]
                .slice(0, 1)}`}
            </Typography>
          )}
          <div className="chat-participant-name-collab">
            <Typography
              variant="subtitle2"
              className="chat-participant-name"
              onClick={() =>
                history.push(`/User/Profile/${convDetails.participants.id}`)
              }
            >
              {convDetails.participants.name}
            </Typography>
            <Typography variant="body2" className="chat-participant-collab">
              You are collaborating with {convDetails.participants.name} for{" "}
              <span className="chat-participant-collab-skills">
                {collabString}
              </span>
            </Typography>
          </div>
        </div>
        <IconButton
          className="chat-more-icon"
          id="chat-more"
          onClick={handleOpenMenu}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
      <div className="chat-box-chat-container">
        {messageLoading && <CircularProgress />}
        {!messageLoading && convDetails.messages && (
          <div className="chat-container" ref={messageContainer}>
            {convDetails.messages.map((msg) => (
              <ChatBoxChatMemo
                handleUserRouting={() =>
                  history.push(`/User/Profile/${convDetails.participants.id}`)
                }
                sender={convDetails.participants.name}
                message={msg}
                dp={convDetails.participants.profilePhotoUrl}
                handleDelete={(id) => handleDeleteMessage(convDetails.id, id)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="chat-box-bottom">
        <OutlinedInput
          variant="outlined"
          className="chat-box-input"
          placeholder="Hello, hope you’e doing well! Thanks for accepting my request"
          onChange={handleMessageChange}
          value={message.body}
          disabled={Attachments.length > 0}
          onKeyDown={handleKeyPress}
          tabIndex="0"
        />
        {Attachments.length > 0 && (
          <div className="attachments-container">
            {Attachments.map((item, index) => (
              <div className="attachment-preview-container" key={index}>
                <CancelIcon className="remove-attachment-btn" />
                <div className="attachment-preview">
                  <img
                    src={item.url}
                    className="attachment-preview-img"
                    width="80"
                    height="80"
                    alt="preview"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="chat-box-input-options">
          <input
            id="attachment-input"
            type="file"
            onChange={(e) => handleFileChange(e, convDetails)}
            style={{ display: "none" }}
          />
          <label htmlFor="attachment-input">
            <IconButton component="span">
              <AttachmentIcon />
            </IconButton>
          </label>
          <IconButton
            aria-describedby="emoji-popover"
            onClick={handleOpenEmojiMenu}
          >
            <InsertEmoticonIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setMessage((prevValue) => {
                return { ...prevValue, body: "" };
              });
              handleChatSend(message);
            }}
          >
            <SendIcon />
          </IconButton>
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {canEndorse && (
          <MenuItem
            onClick={handleClose}
            className={classes.menuItem}
            onClick={handleEndorsement}
          >
            <GradeIcon />
            Endorse
          </MenuItem>
        )}
        <MenuItem
          onClick={handleClose}
          className={classes.menuItem}
          onClick={handleAddSkill}
        >
          <PlaylistAddIcon />
          Add Topic
        </MenuItem>
      </Menu>
      <Popover
        id={Boolean(emojiAnchorEl) ? "emoji-popover" : undefined}
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={handleEmojiMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Picker onSelect={addEmoji} />
      </Popover>
      <EndorsementModal
        open={endorse}
        handleClose={() => {
          setEndorsementDetails(null);
          setEndorse(false);
        }}
        details={endorsementDetails}
        handleSubmit={handleEndorsementSubmit}
        loading={loading}
      />
      <SuccessModal
        open={success}
        handleClose={() => setSuccess(false)}
        skill={selectedSkill}
        role={successRole}
      />
      <AddCollabSkillModal
        open={addSkillModal}
        handleClose={() => setAddSkillModal(false)}
        details={addSkillModalDetails}
        handleAddSuccess={handleAddSuccess}
      />
      <Snackbar
        Open={alertOpen}
        severity={severity}
        alert={alertMessage}
        handleClose={() => setAlertOpen(false)}
      />
    </div>
  );
}

export default withRouter(ChatBox);
