/*jshint esversion:9*/
import React,{useState} from 'react';
import { makeStyles,Typography,Button,Link,IconButton } from '@material-ui/core';
import Input from '../Input/Input';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import contactUs from '../../../Assets/contactUs.png';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth:'1100px',
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
  },
  grid:{
    display:'grid',
    gridTemplateColumns:'auto auto',
    columnGap:'10px'
  }
}));

export default function FeedbackModal({open,handleClose}) {
  const classes = useStyles();
  const [checked,setChecked] = useState(null);
  const [problem,setProblem] = useState(null);
  const [feature,setFeature] = useState(null);

const options = ["I can't find something","I can't figure out how to do something","I'm stuck with a feature","Nothing's wrong, I love BHyve","Something else"];
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            <IconButton onClick={handleClose}>
             <CloseIcon/>
            </IconButton>
          </div>
          <div className={classes.grid}>
          <div style={{textAlign:'left'}}>
                                <Typography variant='h4' style={{fontWeight:'bold',paddingLeft:'50px'}}>Contact Us</Typography>
            <img src={contactUs} alt='comingSoon' style={{width:'300px'}}/>
          </div>
          <div style={{textAlign:'left'}}>
            <br/>
            <Typography variant='h6'>Talk to us!</Typography>
            <br/>
            <br/>
            <div style={{width:'300px'}}>
            <Input rows='2' variant='outlined' label='Your message' placeholder="Tell us..." onChange={e => setFeature(e.target.value)}/>
            </div>
            <br/>
            <Button variant='contained' className='edit-btn'>Submit</Button>
            <br/>
            <br/>
            <Typography variant='subtitle2'>Take a look at our <Link style={{color:'#EE813A',cursor:'pointer'}} href='https://bhyve.io/about-us/' target='_blank' rel='noopener'>FAQs</Link></Typography>
            <br/>
            <Typography variant='subtitle2'>Take a product <Link style={{color:'#EE813A',cursor:'pointer'}} href='https://bhyve.io/about-us/' target='_blank' rel='noopener'>walkthrough</Link></Typography>
          </div>
          </div>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}
