/*jshint esversion:9*/

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Link,
} from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./activity-post-style.scss";
import Linkify from "linkify-react";
import Tooltip from "@material-ui/core/Tooltip";

function ActivityPost({
  user,
  firstName,
  lastName,
  img,
  handleClick,
  text,
  time,
  comments,
  likes,
  commentList,
  tags,
  handleLike,
  liked,
  owned,
  deleteShoutOut,
  editShoutout,
  users,
  history,
  navigateToUser,
  navigateToAuthor,
  commented,
  handleDrawerClose,
  feed,
  search,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    deleteShoutOut();
  };

  const handleEdit = () => {
    handleClose();
    editShoutout();
  };

  const charCountInString = (word, character) => {
    let count = 0;
    for (const char of word) {
      if (char === character) {
        count++;
      }
    }
    return count;
  };

  function capitalize(input) {
    try {
      let word;
      if (input.split("").includes("_")) {
        word = input.replaceAll("_", " ").slice(1);
      } else {
        word = input.slice(1);
      }
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              "&" +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  try {
    return (
      <div style={{ width: "100%" }}>
        <div className="activity-post-container">
          <div className="dp-container">
            {img !== "" && (
              <Avatar
                src={img}
                alt="ppl"
                className="post-dp"
                style={{
                  cursor: "pointer",
                  width: feed ? "60px" : search ? "80px" : "",
                  height: feed ? "60px" : search ? "80px" : "",
                }}
                onClick={() => navigateToAuthor()}
              />
            )}
            {img === "" && (
              <Typography
                variant={feed ? "h5" : search ? "h4" : "h6"}
                className="initial-container"
                onClick={() => navigateToAuthor()}
                style={{
                  width: feed ? "60px" : search ? "80px" : "40px",
                  height: feed ? "60px" : search ? "80px" : "40px",
                  cursor: "pointer",
                  textAlign: "center!important",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: feed ? "15px" : search ? "12%" : "5px",
                  color: "white",
                  background: "#FFCC33",
                }}
              >
                {`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
              </Typography>
            )}
          </div>
          <div>
            <div className="post-info">
              <div className="sender-primay-info">
                <Typography
                  variant="subtitle1"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    fontWeight: "600",
                  }}
                  onClick={() => navigateToAuthor()}
                >
                  {firstName}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    variant="caption"
                    className="muted"
                    style={{
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      marginRight: !owned && "48px",
                    }}
                  >
                    {time}
                  </Typography>
                  {owned && (
                    <IconButton button onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </div>
              </div>
              <Linkify options={{ target: { url: "_blank" } }}>
                <Typography
                  variant="body1"
                  style={{ width: "100%", display: "block" }}
                >
                  {text.split(" ").map((item) =>
                    item.split("")[0] === "#" ? (
                      <span
                        onClick={() => {
                          history.push(
                            `/User/Search?q=${
                              item.split("").includes("_")
                                ? item.split("_").join(" ").slice(1)
                                : item.slice(1)
                            }`
                          );
                          handleDrawerClose();
                        }}
                        style={{
                          color: "#EE813A",
                          margin: "0 2px 0 2px",
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        {`#${capitalize(item)} `}
                      </span>
                    ) : item.split("")[0] === "@" ? (
                      <span
                        className="userLink"
                        onClick={() => navigateToUser(item.slice(1), users)}
                        style={{
                          color: "#000",
                          margin: "0 2px 0 2px",
                          fontWeight: "600",
                        }}
                      >
                        {`${
                          item.split("").includes("_")
                            ? item.split("_").join(" ").slice(1)
                            : item.slice(1)
                        } `}
                      </span>
                    ) : (
                      `${item} `
                    )
                  )}
                </Typography>
              </Linkify>
            </div>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            {/* <MenuItem onClick={handleDelete}>Delete</MenuItem> */}
          </Menu>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="post-actions">
            <div className="post-action-button">
              <Tooltip title="Like">
                <IconButton button onClick={() => handleLike()}>
                  <ThumbUpIcon color={liked ? "primary" : ""} />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body1"
                style={{ marginLeft: "2px", paddingTop: "10px" }}
              >
                {likes}
              </Typography>
            </div>
            <div className="post-action-button" style={{ marginLeft: "100px" }}>
              <Tooltip title="Reply">
                <IconButton
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <ReplyOutlinedIcon color={commented ? "primary" : ""} />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body1"
                style={{ marginLeft: "2px", paddingTop: "10px" }}
              >
                {comments}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.log(error);
  }
}

export default withRouter(ActivityPost);
