import React from "react";
import parse from "html-react-parser";
import linkifyHtml from "linkifyjs/html";
import Profile from "../profile";
import style from "./comment.module.scss";
import UserIntitials from "../userInitials/userInitials";
// import Button from '@material-ui/core/Button';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withRouter } from "react-router";
import { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCompanyDepartments } from "./../../../../../redux/user/user-selector";
import getDepartmentName from "../../../../../utils/getDepartmentName";
import Linkify from "linkify-react";

const Comment = ({
  author,
  designation,
  profile,
  comment,
  time,
  isExpandable,
  navigateToAuthor,
  history,
  navigateToUser,
  companyDepartments,
}) => {
  const [expanded, setExpanded] = useState(false);

  function capitalize(input) {
    try {
      let word;
      if (input.split("").includes("_")) {
        word = input.replaceAll("_", " ").slice(1);
      } else {
        word = input.slice(1);
      }
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              "&" +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  return (
    <>
      <div className={`${style.wrapper} py-sm mt-xxs`}>
        <div className={`${style.author_wrapper}`}>
          <div onClick={() => navigateToAuthor()}>
            {profile !== "" && (
              <Profile image={profile} size="xs" className="mt-xxs" />
            )}
            {author && profile === "" && (
              <UserIntitials
                firstName={author.split(" ")[0]}
                lastName={author.split(" ")[1]}
                size="small"
              />
            )}
          </div>
          <div>
            <p
              className={`${style.author_name} my-0`}
              onClick={() => navigateToAuthor()}
            >
              {author ? author : "no author"}
            </p>
            {!!designation &&
            !!companyDepartments &&
            companyDepartments.length > 0 ? (
              <small className={`${style.author_designation}`}>
                {getDepartmentName(designation, companyDepartments)}
              </small>
            ) : null}
          </div>
          <small className={`${style.timestamp}`}>{time}</small>
          {/* <Button
            size="small"
            startIcon={<MoreVertIcon />}
            className={`${style.optionsBtn}`}
          /> */}
        </div>
        <div className={`${style.comment_wrapper}`}>
          <Linkify
            tagName="p"
            options={{ target: { url: "_blank" } }}
            className={`mb-0 ${style.comment}`}
            style={{
              height: isExpandable ? (expanded ? "auto" : "43px") : "auto",
            }}
          >
            {comment
              ? comment.split(" ").map((item) =>
                  item.split("")[0] === "#" ? (
                    <span
                      onClick={() => {
                        history.push(
                          `/User/Search?q=${
                            item.split("").includes("_")
                              ? item.split("_").join(" ").slice(1)
                              : item.slice(1)
                          }`
                        );
                      }}
                      style={{
                        color: "#EE813A",
                        margin: "0 2px 0 2px",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                    >{`#${capitalize(item)}`}</span>
                  ) : item.split("")[0] === "@" ? (
                    <span
                      onClick={() => navigateToUser(item.slice(1))}
                      style={{
                        color: "black",
                        margin: "0 2px 0 2px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >{` ${
                      item.split("").includes("_")
                        ? item.split("_").join(" ").slice(1)
                        : item.slice(1)
                    } `}</span>
                  ) : (
                    ` ${item} `
                  )
                )
              : "no comment"}
          </Linkify>
          {isExpandable && comment?.length > 130 && (
            <span onClick={() => setExpanded((bool) => !bool)}>
              {expanded ? "...show less" : "...show more"}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  companyDepartments: selectCompanyDepartments,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comment)
);
