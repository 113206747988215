import React,{useEffect,useState} from 'react';
import {Paper,Typography, Divider, Avatar} from '@material-ui/core';
import axios from 'axios';
import Logout from '../../logout';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './recommendation-container-style.scss';


function RecommContainer({userId, navigateToUser, history}) {

    const [recommendations,setRecommendations] = useState([]);

    const [seeMore, setSeeMore] = useState(false);

    const token = localStorage.getItem('bhyve-token');

    useEffect(() => {
        try {
            async function gettingRecommendation() {
                // let getRecommResult = await axios.post(`${process.env.REACT_APP_API_URL}collaborationSkill/recommendations`,{userPublicId: userId},{
                //     headers:{
                //       'Authorization': `Bearer ${token}`,
                //       'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                //     }
                // });
                // console.log(getRecommResult);
             
                let getTotalRecommResult = await axios.post(`${process.env.REACT_APP_API_URL}collaborationSkill/totalRecommendations`,{userPublicId: userId},{
                    headers:{
                      'Authorization': `Bearer ${token}`,
                      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                    }
                });
                console.log('total recommendations: ', getTotalRecommResult);
                setRecommendations(getTotalRecommResult.data);
            }
            if(userId) {
                gettingRecommendation();
            }
        } catch (error) {   
            console.log(error);
            if(error.response) {
                if(error.response.status === 401) {
                    history.push('/logout');
                }
            }
        }
    },[userId])

    return(
        <Paper className='skillCard-container recommendations-main-container' >
            <div style={{width:'100%',padding:'10px 10px'}}>
                <Typography variant='subtitle1' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5',display:'flex', justifyContent:'space-between'}}>
                    <span style={{padding:'8px 0 3px 0'}} className='skill-card-title'>Recommendations</span>
                </Typography>
            </div>
            <div className='recommendations-container'>
                {recommendations.map(r => 
                    <div className='recommendation-entry'>
                        <div className='recommendation-entry-data'>
                            {r.profilePhotoUrl !== '' && <Avatar src={r.profilePhotoUrl} style={{marginTop:'10px',cursor:'pointer'}} onClick={()=>navigateToUser(r.publicId)}/>}
                            {r.profilePhotoUrl === '' && <Typography variant='h6' className='initial-container' onClick={()=>navigateToUser(r.publicId)} style={{marginTop:'10px',cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'5px',color:'white',background:'#FFCC33',width:'40px', height:'40px'}}>
                                {`${r.firstName.slice(0,1)}${r.lastName.slice(0,1)}`}
                                </Typography>}
                            <div>
                                <div className='recommendation-entry-name-time'>
                                    <Typography variant='subtitle1' style={{fontWeight:'500',cursor:'pointer'}} onClick={()=>navigateToUser(r.publicId)}>{r.firstName}</Typography>
                                    <Typography  variant='caption' style={{paddingTop:'5px'}}>{moment(r.updatedAt).fromNow()}</Typography>
                                </div>
                                <Typography className='recommendation-entry-data-text' style={{maxHeight: seeMore ? '' : '80px'}} variant='body1'>{r.recommendation}</Typography>
                                {r.recommendation.length > 200 && <p className='see-more' onClick={() => setSeeMore(!seeMore)}>{seeMore ? "...See Less" : "...See More"}</p>}
                            </div>
                        </div>
                        <Divider style={{width:'100%'}}/>
                    </div>
                )}
            </div>
        </Paper>
    )
}

export default withRouter(RecommContainer)