import React from 'react';
import style from './articleListItemOptionDialog.module.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
const ArticleListItemOptionDialog = ({handleDeleteOpenDialog, isOpen, elRef, edit, share, remove, handleClose, handleEdit, handleShare, handleDelete }) => {
    const handleDeleteClick = () => {
        handleClose()
        handleDeleteOpenDialog(true)
    }
    const handleEditClick = () => {
        handleClose()
        if (handleEdit) handleEdit()
    }
    const handleShareClick = () => {
        handleClose()
        if (handleShare) handleShare()
    }
    return (
        <Popper open={isOpen} anchorEl={elRef} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper className={style.menuPaper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                                {edit && handleEdit && <MenuItem onClick={handleEditClick} className={style.menuItem}>
                                    <ListItemIcon>
                                        <EditIcon style={{ color: 'black' }} />
                                    </ListItemIcon>
                                    <small>Edit</small>
                                </MenuItem>}
                                {share && handleShare && <MenuItem onClick={handleShareClick} className={style.menuItem}>
                                    <ListItemIcon>
                                        <ShareIcon style={{ color: 'black' }} />
                                    </ListItemIcon>
                                    <small>Share Content</small>
                                </MenuItem>}
                                {remove && <MenuItem onClick={handleDeleteClick} style={{ color: 'red' }} className={style.menuItem}>
                                    <ListItemIcon>
                                        <DeleteIcon style={{ color: 'red' }} />
                                    </ListItemIcon>
                                    <small>Delete Content</small>
                                </MenuItem>}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default ArticleListItemOptionDialog;