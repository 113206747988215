import React from 'react';
import classes from './uploadDialog.module.scss';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ImageIcon from '@material-ui/icons/Image';
import Button from '../button';
import formatBytes from '../formatBytes';

const UploadProgressDialog = ({
    open,
    handleClose,
    progress,
    currentUploadFileDetails,
    appendImageToEditor,
    appendVideoToEditor,
    appendDocumentToEditor,
    handleUploadFileChange,
    ...props
}) => {
    const getIcon = (open) => {
        switch (open) {
            case "video":
                return <VideocamIcon color="primary" />
            case "image":
                return <ImageIcon color="primary" />
            default:
                return <DescriptionIcon color="primary" />
        }
    }
    const handleSave = () => {
        switch (open) {
            case "video":
                return appendVideoToEditor()
            case "image":
                return appendImageToEditor()
            default:
                return appendDocumentToEditor()
        }
    }
    const getFileSize = (open) => {
        switch (open) {
            case "video":
                return "25MB";
            default:
                return "25MB";
        }
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-progress"
            aria-describedby="alert-dialog-for progress display"
            maxWidth='md'
            onClose={handleClose}
        >
            <div className={`${classes.header}`}>
                <h2 style={{ textTransform: "capitalize" }}>Upload {open}</h2>
                <small>File size limit is {getFileSize(open)}</small>
                <IconButton onClick={handleClose} className={`${classes.closeBtn}`}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={`${classes.content}`}>
                <div className={`${classes.upload_content_wrapper}`}>
                    <div>{getIcon(open)}
                    </div>
                    <div>
                        <div>
                            <h4 style={{ fontWeight: 800 }} className="my-0">{currentUploadFileDetails ? currentUploadFileDetails.name : null}</h4>
                            <small>{currentUploadFileDetails ? formatBytes(currentUploadFileDetails.size) : null}</small>
                            <IconButton size="small" style={{ marginLeft: "auto", color: "red" }} onClick={handleUploadFileChange}> <RemoveCircleOutlineIcon fontSize="small" /> </IconButton>
                        </div>
                        <LinearProgress variant="determinate" value={progress} />
                    </div>
                </div>
                {currentUploadFileDetails ? currentUploadFileDetails.remoteUrl ? (
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none", alignSelf: "center", marginBottom: 20 }}
                        onClick={handleSave}
                    >Save</Button>
                ) : (
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none", alignSelf: "center", marginBottom: 20 }}
                        onClick={handleClose}
                    >Close</Button>
                ) : null}
            </div>
        </Dialog>
    )
}

export default UploadProgressDialog;
