/*jshint esversion:9*/
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles,makeStyles,Typography,IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 500,
  },
  container:{
        maxHeight: 400,

        '&::-webkit-scrollbar': {
          width: '4px !important'
        },
      
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1 !important '
        },
         
        /* Handle */
        '&::-webkit-scrollbar-thumb' :{
          background: '#888 !important'
        }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FFCC33',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export default function PointsTableModal({open,handleClose,data,loading}) {
  
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open} mountOnEnter unmountOnExit>
          <div className={classes.paper}>
            <div style={{width:'100%',textAlign:'right'}}>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon/>
              </IconButton>
            </div>
            <Typography variant='h4' style={{fontWeight:'bold',textAlign:'center',width:'100%'}}>Want to earn more points?</Typography>
            <Typography variant='caption'>Below is the table for actions and the respective points you'll earn when you perform them.</Typography>
                        <br/>
            <br/>
            {loading&&<CircularProgress disableShrink />}
          {loading===false&&data&&
            <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table}  stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
            <StyledTableCell align="center">Points</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {data[0].actionPoints.map((row,index) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {index+1}
              </StyledTableCell>
              <StyledTableCell align="center">{row.action.replace(/_/g, " ")}</StyledTableCell>
              <StyledTableCell align="center">{row.points}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
