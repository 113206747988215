/*jshint esversion:9*/

import React from 'react';
import {Paper,Typography,Button} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InfoEdit from '../SubComponents/info-edit/info-edit';



function ChangePassword(){
  return(
    <Paper className='skillCard-container' style={{marginTop:'30px'}}>
      <Typography variant='h6' className='skill-name'>UPDATE PASSWORD</Typography>
      <br/>
      <InfoEdit label='Current Password' type='password' icon={<LockOutlinedIcon/>}/>
      <InfoEdit label='New Password' type='password' icon={<LockOutlinedIcon/>}/>
      <InfoEdit label='Re-Type Password' type='password' icon={<LockOutlinedIcon/>}/>
      <br/>
      <br/>
      <Button className='edit-btn'>Save Changes</Button>
    </Paper>
  )
}

export default ChangePassword;
