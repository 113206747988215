import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import CollabRequest from "../SubComponents/collab-request/collab-request";
import CollabDeclineModal from "../SubComponents/collab-decline-modal/collab-decline-modal";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Logout from "../../logout";
import "./collab-request-list-style.scss";

function CollabRequestList({ currentUserId, history, companyDepartments }) {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(null);
  const [collabDecline, setCollabDecline] = useState(false);
  const [requestToDecline, setRequestToDecline] = useState(null);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    async function gettingCollabs() {
      try {
        const fetchCollabsResult = await axios.get(
          `${process.env.REACT_APP_API_URL}collaboration`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (fetchCollabsResult && fetchCollabsResult.data.length > 0) {
          let requestingUsers = [];
          let finalArray = [];
          fetchCollabsResult.data.forEach((request) => {
            let user = request.participants.filter(
              (item) => item !== currentUserId
            );
            requestingUsers.push(user[0]);
          });
          const getUserInfosResult = await axios.post(
            `${process.env.REACT_APP_API_URL}user/profiles/info`,
            { userPublicIds: [...requestingUsers] },
            {
              headers: {
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          requestingUsers = [];
          getUserInfosResult.data.forEach((user) => {
            let id = Object.keys(user)[0];
            let obj = {
              id: id,
              ...user[id],
            };
            requestingUsers.push(obj);
          });
          fetchCollabsResult.data.forEach((request) => {
            let user = request.participants.filter(
              (item) => item !== currentUserId
            );
            let userExist = requestingUsers.find((item) => item.id === user[0]);
            if (userExist) {
              let obj = {
                ...request,
                participants: userExist,
              };
              finalArray.push(obj);
            }
          });
          console.log(finalArray);
          setRequests(finalArray);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response.message);
          if (error.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    }

    gettingCollabs();
  }, [currentUserId]);

  const updateCollabRequest = async (req) => {
    let updateCollabResult = await axios.patch(
      `${process.env.REACT_APP_API_URL}collaboration`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return updateCollabResult;
  };

  const handleAccept = async (collabId, collabUserId) => {
    try {
      setLoading(collabId);
      console.log(collabId);
      let reqObject = {
        collaborationPublicId: collabId,
        status: 1,
        rejectReason: "",
      };

      let acceptRequestResult = await updateCollabRequest(reqObject);
      if (acceptRequestResult) {
        console.log(acceptRequestResult);
        let current = requests;
        current = current.filter(
          (item) =>
            item.collaborationPublicId !==
            acceptRequestResult.data.collaborationPublicId
        );
        setRequests(current);
        setLoading(null);
        history.push(`Chat/${collabUserId}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
      setLoading(null);
    }
  };

  const handleDecline = (id) => {
    setLoading(id);
    setRequestToDecline(id);
    setCollabDecline(true);
  };

  const handleDeclineRequest = async (reason) => {
    try {
      setCollabDecline(false);
      let reqObject = {
        collaborationPublicId: requestToDecline,
        status: 2,
        rejectReason: reason,
      };
      let declineRequestResult = await updateCollabRequest(reqObject);
      if (declineRequestResult) {
        setRequestToDecline(null);
        let current = requests;
        current = current.filter(
          (item) =>
            item.collaborationPublicId !==
            declineRequestResult.data.collaborationPublicId
        );
        setRequests(current);
        console.log(declineRequestResult);
        setLoading(null);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
      setLoading(null);
    }
  };

  if (requests.length > 0) {
    return (
      <Paper className="collab-list-container">
        <div className="collab-list-title">
          <Typography variant="subtitle1" className="collab-list-title-text">
            Peer Learning Requests ({requests.length})
          </Typography>
        </div>
        <div className="collab-list-requests-container">
          <div className="collab-list-requests-sub-container">
            {requests.map((item) => (
              <CollabRequest
                key={item.collaborationPublicId}
                companyDepartments={companyDepartments}
                details={item}
                handleAccept={handleAccept}
                loading={loading}
                handleDecline={handleDecline}
              />
            ))}
          </div>
        </div>
        <CollabDeclineModal
          open={collabDecline}
          handleClose={() => setCollabDecline(false)}
          handleSend={handleDeclineRequest}
        />
      </Paper>
    );
  } else {
    return null;
  }
}

export default withRouter(CollabRequestList);
