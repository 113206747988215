/*jshint esversion:9*/

import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Navbar from "../../../Components/navbar/navbar-component";
import Sidebar from "../../../Components/sidebar/sidebar-component";
import SideDrawer from "../../../Components/side-drawer/side-drawer-component";
import Dashboard from "../Dashboard/Dashboard-page";
import Profile from "../ViewProfile/Viewprofile-page";
import EditProfile from "../EditProfile/EditProfile-page";
import SearchPage from "../Search/Search-page";
import ChatPage from "../Chat/Chat-page";
import AboutUsModal from "../../../Components/SubComponents/aboutUs-modal/aboutUs-modal";
import FeedbackModal from "../../../Components/SubComponents/feedback-modal/feedback-modal";
import ContactModal from "../../../Components/SubComponents/contact-modal/contact-modal";
import ErrorBoundary from "../../../Components/error-boundary/error-boundary-component";
import Logo from "../../../Assets/bhyveNewLogo.png";
import Talk from "talkjs";
import axios from "axios";
import Logout from "../../../logout";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectCompanyInformation,
} from "../../../redux/user/user-selector";
import {
  selectTriggerPoint,
  selectTourStart,
} from "../../../redux/walkthrough/walkthrough-selector";
import { setNewNotifications } from "../../../redux/notifications/notification-actions";
import { selectNewNotifications } from "../../../redux/notifications/notification-selector";
import {
  setUserData,
  setCompanyDepartments,
} from "../../../redux/user/user-actions";
import "./User-Page-style.scss";
import CmsPage from "../cms";

function User({
  history,
  userData,
  setNewNotifications,
  newNotifications,
  triggerPoint,
  tourStatus,
  companyInfo,
  setCompanyDepartments,
}) {
  const [unreads, setUnreads] = useState(null);
  const [about, setAbout] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [contact, setContact] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [footerLogo, setFooterLogo] = useState(null);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    try {
      const getNewNotifications = async () => {
        const token = localStorage.getItem("bhyve-token");
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL_NOTIFICATIONS}notify-user/?skip=${skip}`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };
      async function gettingNotifications() {
        try {
          let getNewNotificationsResult = await getNewNotifications();
          //debugger;
          if (getNewNotificationsResult.data.userNotifications.length !== 0) {
            console.log(getNewNotificationsResult);
            setNewNotifications(
              getNewNotificationsResult.data.userNotifications
            );
          }
          setUnreadNotificationsCount(
            getNewNotificationsResult.data.unreadNotificationCount
          );
        } catch (err) {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }
      gettingNotifications();
      let id = setInterval(async () => gettingNotifications(), 30000);
      return () => clearInterval(id);
    } catch (error) {
      console.log(error.message);
    }
  }, [skip]);

  useEffect(() => {
    setSkip(newNotifications ? newNotifications.length : 0);
    //debugger;
  }, [newNotifications]);

  useEffect(() => {
    if (triggerPoint === 1) {
      setSideOption("buzzBox");
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [triggerPoint]);

  useEffect(() => {
    if (companyInfo) {
      setFooterLogo(companyInfo.companyLogoDetails[0].imageUrl);
    }
  }, [companyInfo]);

  const [open, setOpen] = useState(false);
  const [sideOption, setSideOption] = useState("buzzbox");

  const handleClick = (option) => {
    setSideOption(option);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (string) => {
    history.push(`/User/Search?q=${string}`);
  };

  ////
  useEffect(() => {
    console.log("The entire userData", userData);
  }, []);
  ////

  return (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <Navbar
          info={userData && userData}
          handleSearch={handleSearch}
          handleAboutUs={() => setAbout(true)}
          handleFeedback={() => setFeedback(true)}
          handleContact={() => setContact(true)}
        />
        <Sidebar
          handleClick={handleClick}
          unreads={unreads}
          newNotificationsCount={unreadNotificationsCount}
        />
        <SideDrawer
          option={sideOption}
          Open={open}
          handleClose={handleClose}
          setUnreadNotificationsCount={setUnreadNotificationsCount}
        />
        <Switch>
          <Route path="/User/Dashboard" exact component={Dashboard} />
          <Route path="/User/Profile/:userId" exact component={Profile} />
          <Route path="/User/EditProfile" sensitive component={EditProfile} />
          <Route path="/User/Search" exact component={SearchPage} />
          <Route path="/User/Chat/:userId" exact component={ChatPage} />
          <Route path="/User/cms" sensitive component={CmsPage} />
        </Switch>
        <div className="bhyve-watermark-container">
          <span>
            <Typography variant="body1" className="bhyve-watermark-text">
              Powered&nbsp;By
            </Typography>
          </span>
          <span>
            <img src={Logo} alt="logo" className="bhyve-watermark-logo" />
          </span>
        </div>
        <AboutUsModal open={about} handleClose={() => setAbout(false)} />
        <FeedbackModal open={feedback} handleClose={() => setFeedback(false)} />
        <ContactModal open={contact} handleClose={() => setContact(false)} />
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  newNotifications: selectNewNotifications,
  triggerPoint: selectTriggerPoint,
  tourStatus: selectTourStart,
  companyInfo: selectCompanyInformation,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
  setNewNotifications: (array) => dispatch(setNewNotifications(array)),
  setCompanyDepartments: (array) => dispatch(setCompanyDepartments(array)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
