/*jshint esversion:9*/

import React, { useEffect, useState } from "react";
import Talk from "talkjs";
import ChatHistory from "../../../Components/chat-history/chat-history-component";
import ChatBox from "../../../Components/chat-box/index";
import SubscribeToRooms from "../../../subscribeToRooms";
import { graphqlOperation, API } from "@aws-amplify/api";
import { messagesByRoomId } from "../../../graphql/queries";
import { createMessage } from "../../../graphql/mutations";
import Tour from "../../../tour";
import "@aws-amplify/pubsub";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectSettings,
} from "../../../redux/user/user-selector";
import {
  selectNewMessages,
  selectSubscribedRooms,
} from "../../../redux/notifications/notification-selector";
import {
  setNewMessages,
  setSubscribedRooms,
} from "../../../redux/notifications/notification-actions";
import { setSettings } from "../../../redux/user/user-actions";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Logout from "../../../logout";
import "./Chat-style.scss";
import moment from "moment";

const ChatHistoryMemo = React.memo(ChatHistory);

function ChatPage({
  userData,
  match,
  history,
  newMessages,
  setNewMessages,
  subscribedRooms,
  setSubscribedRooms,
  tourSettings,
  setSettings,
}) {
  const talkjsContainer = React.useRef();

  const [otherId, setOtherId] = useState(null);
  const [convHistory, setConvHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messageLoading, setMessageLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [collaborations, setCollaborations] = useState([]);
  const [unRead, setUnRead] = useState([]);
  const [tempStore, setTempStore] = useState(null);
  const [tourStatus, setTourStatus] = useState(false);
  const [showFileSizeExceededOverlay, setShowFileSizeExceededOverlay] =
    useState(false);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    if (tourSettings) {
      setTourStatus(tourSettings.chatWalkthrough);
    }
  }, [tourSettings]);

  useEffect(() => {
    if (newMessages) {
      console.log(unRead);
      console.log(newMessages);
      if (selectedConversation) {
        if (
          newMessages.roomId !==
            selectedConversation.collabDetails.collaborationPublicId &&
          newMessages.author !== userData.userInformation.publicId
        ) {
          console.log("hitted 1");
          let unReadRooms = subscribedRooms.filter((item) => item.unread !== 0);
          if (unReadRooms.length > 0) {
            unReadRooms = unReadRooms.map((item) => ({
              id: item.id,
              quantity: item.unread,
            }));
            console.log(unReadRooms);
            setUnRead(unReadRooms);
          }
          // if(tempStore) {
          //   if(tempStore.id !== newMessages.id) {
          //     setTempStore(newMessages);
          //     let exist = unRead.find(item => item.id === newMessages.roomId);
          //     if(!exist) {
          //       setUnRead(prevValue => {return [...prevValue,{id: newMessages.roomId, quantity: 1}]});
          //     } else{
          //       let current = unRead;
          //       current = current.map(item => item.id === newMessages.roomId ? {...item, quantity: item.quantity + 1} : item);
          //       setUnRead(current);
          //     }
          //   }
          // } else {
          //   setTempStore(newMessages);
          //     let exist = unRead.find(item => item.id === newMessages.roomId);
          //     if(!exist) {
          //       setUnRead(prevValue => {return [...prevValue,{id: newMessages.roomId, quantity: 1}]});
          //     } else{
          //       let current = unRead;
          //       current = current.map(item => item.id === newMessages.roomId ? {...item, quantity: item.quantity + 1} : item);
          //       setUnRead(current);
          //     }
          // }
          let currentHistory = convHistory;
          if (currentHistory) {
            let latestMessages = [];
            let convUpdate = currentHistory.find(
              (item) =>
                item.collabDetails.collaborationPublicId === newMessages.roomId
            );
            latestMessages.push(convUpdate);
            currentHistory = currentHistory.filter(
              (item) =>
                item.collabDetails.collaborationPublicId !== newMessages.roomId
            );
            unReadRooms.forEach((room) => {
              let exist = currentHistory.find(
                (item) => item.collabDetails.collaborationPublicId === room.id
              );
              if (exist) {
                latestMessages.push(exist);
                currentHistory = currentHistory.filter(
                  (item) => item.collabDetails.collaborationPublicId !== room.id
                );
              }
            });
            currentHistory = [...latestMessages, ...currentHistory];
            setConvHistory(currentHistory);
          }
        } else {
          if (newMessages.author !== userData.userInformation.publicId) {
            setTempStore(newMessages);
            console.log("hitted 2");
            let current = selectedConversation;
            let messageExist = selectedConversation.messages.find(
              (item) => item.id === newMessages.id
            );
            if (!messageExist) {
              current = {
                ...current,
                messages: [
                  ...current.messages,
                  {
                    ...newMessages,
                    displayTime: calculateTimestamp(newMessages.createdAt),
                    status: "recieved",
                    messageType: "recieved",
                  },
                ],
              };
              setSelectedConversation(current);
              setSubscribedRooms({
                id: newMessages.roomId,
                status: true,
                unread: 0,
              });
            }
          }
        }
      } else {
      }
      // convHistory.forEach((entry) => {
      //   let exist = newMessages.find(item => item.roomId === entry.)
      // });
    }
  }, [newMessages, userData, selectedConversation, subscribedRooms]);

  const calculateTimestamp = (date) => {
    const someDate = new Date(date);
    const today = new Date();
    if (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    ) {
      return someDate.toLocaleString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      });
    } else {
      return `${someDate.getDate()} ${someDate.toLocaleString("default", {
        month: "long",
      })}`;
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}user/userProfile/${match.params.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    };

    async function settingTalkJS() {
      try {
        let otherUser = null;
        if (match.params.userId !== "00000000-0000-0000-0000-000000000000") {
          let res = await getUserInfo();
          otherUser = res.data;
        }
        let getCollabResult = await axios.post(
          `${process.env.REACT_APP_API_URL}collaboration/participants`,
          { participants: [userData.userInformation.publicId] },
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (getCollabResult.data.length > 0) {
          getCollabResult.data.forEach((collab) => {
            //let exist = subscribedRooms.find(item => item.id === collab.collaborationPublicId);
            if (collab.status === 1) {
              setSubscribedRooms({
                id: collab.collaborationPublicId,
                status: false,
              });
            }
          });
          let convMap = new Map();
          let otherParticipants = [];
          getCollabResult.data.forEach((collab) => {
            if (collab.status === 1) {
              let participant = collab.participants.filter(
                (item) => item !== userData.userInformation.publicId
              );
              let obj = {
                collabDetails: collab,
              };
              convMap.set(participant[0], obj);
              otherParticipants.push(participant[0]);
            }
          });
          console.log(otherParticipants);
          let gettingOtherParticipants = await axios.post(
            `${process.env.REACT_APP_API_URL}user/profiles/info`,
            { userPublicIds: otherParticipants },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
          );
          gettingOtherParticipants.data.forEach((person) => {
            let id = Object.keys(person)[0];
            let conv = convMap.get(id);
            conv = {
              ...conv,
              participants: {
                ...person[id],
                name: `${person[id].firstName} ${person[id].lastName}`,
                id: id,
              },
            };
            convMap.set(id, conv);
          });
          console.log(convMap);
          let finalArray = Array.from(convMap.values());
          if (match.params.userId === "00000000-0000-0000-0000-000000000000") {
            setOtherId(finalArray[0].participants.id);
          }
          setConvHistory(finalArray);
          setLoading(false);
          if (!otherUser) {
            handleUserChange(finalArray[0]);
          } else {
            let convFound = finalArray.find(
              (item) => item.participants.id === otherUser.publicId
            );
            handleUserChange(convFound);
          }
        } else {
          setConvHistory([]);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    //if(talkjsContainer.current.children.length === 0 && userData) {
    if (userData) {
      setLoading(true);
      setShowTour(true);
      settingTalkJS();
    }

    //}
  }, [userData]);

  function settingMessages(conv) {
    try {
      console.log("collabId: ", conv.collabDetails.collaborationPublicId);
      API.graphql(
        graphqlOperation(messagesByRoomId, {
          roomId: conv.collabDetails.collaborationPublicId,
          sortDirection: "ASC",
        })
      )
        .then((response) => {
          console.log("response: ", response);
          const items = response.data?.messagesByRoomId?.items;

          if (items) {
            let manipulatedMessages = items.map((msg) =>
              msg.author === userData.userInformation.publicId
                ? {
                    ...msg,
                    messageType: "sent",
                    displayTime: calculateTimestamp(msg.createdAt),
                    status: "sent",
                  }
                : {
                    ...msg,
                    messageType: "recieved",
                    displayTime: calculateTimestamp(msg.createdAt),
                    status: "recieved",
                  }
            );
            setSelectedConversation({
              ...conv,
              messages: manipulatedMessages,
            });
          }
        })
        .catch((err) => console.log("chat messages error: ", err));
      // setMessageLoading(true);
      // let messages = await axios.get(`https://api.talkjs.com/v1/typcBJ8o/conversations/${conv.id}/messages`,{
      //   headers:{
      //   'Authorization': `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`
      //   }
      // });

      // //let conv = convMap.get(participantID);
      // let manipulatedMessages = messages.data.data.map(msg =>  msg.senderId === userData.userInformation.publicId ?
      //   {...msg, messageType: 'sent', displayTime: calculateTimestamp(msg.createdAt), status:'sent'} :
      //   {...msg, messageType: 'recieved', displayTime: calculateTimestamp(msg.createdAt), status:'recieved'}
      // )
      // //console.log(new Date(messages.data.data[0].createdAt).toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }))
      // conv = {
      //   ...conv,
      //   messages: manipulatedMessages.sort((a, b) => a.createdAt - b.createdAt)
      // }
      // setSelectedConversation(conv);
      // setMessageLoading(false);
      // // convMap.set(participantID, conv);
      // // console.log(convMap);
      // // if(conv.messages) {
      // //   counter += 1
      // //   console.log(counter);
      // // }
    } catch (error) {
      console.log(error);
    }
  }

  const handleUserChange = (conv) => {
    if (showFileSizeExceededOverlay) {
      setShowFileSizeExceededOverlay(false);
    }
    setOtherId(conv.participants.id);
    let currentUnreads = unRead;
    let exist = currentUnreads.find(
      (item) => item.id === conv.collabDetails.collaborationPublicId
    );
    if (exist) {
      console.log(currentUnreads);
      currentUnreads = currentUnreads.filter(
        (item) => item.id !== conv.collabDetails.collaborationPublicId
      );
      console.log(currentUnreads);
      setUnRead(currentUnreads);
      setSubscribedRooms({ id: exist.id, status: true, unread: 0 });
      // setNewMessages(null);
    }
    settingMessages(conv);
    // Talk.ready.then(() => {
    //     var me = new Talk.User({
    //         role: "User",
    //         id: userData.userInformation.publicId,
    //         name: `${userData.userInformation.firstName} ${userData.userInformation.lastName}`,
    //         email: userData.userInformation.email,
    //         photoUrl: userData.userInformation.profilePhotoUrl?userData.userInformation.profilePhotoUrl:'https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    //         welcomeMessage: "Hey there! How are you? :-)"
    //     });

    //   let talkSession = new Talk.Session({
    //     appId: "typcBJ8o",
    //     me: me
    //   });

    //       var conversation = talkSession.getOrCreateConversation(convId);
    //       var inbox = talkSession.createChatbox(conversation);
    //   inbox.mount(talkjsContainer.current);
    // })
  };

  const handleChatSend = async (message) => {
    try {
      if (message.body.split(" ").join("").length > 0) {
        const input = {
          ...message,
          body: message.body.trim(),
          companyPublicId: userData.userInformation.companyPublicId,
        };
        console.log(input);
        let conv = selectedConversation;
        conv = {
          ...conv,
          messages: [
            ...conv.messages,
            {
              ...input,
              status: "sending",
              messageType: "sent",
              displayTime: "Just Now",
            },
          ],
        };
        setSelectedConversation(conv);
        let result = await API.graphql(
          graphqlOperation(createMessage, { input })
        );
        console.log(result);
        conv = {
          ...conv,
          messages: conv.messages.map((item) =>
            !item.id
              ? {
                  ...item,
                  status: "sent",
                  id: result.data.createMessage.id,
                  displayTime: calculateTimestamp(
                    result.data.createMessage.createdAt
                  ),
                }
              : item
          ),
        };
        setSelectedConversation(conv);
        setUploading(false);
        let currentHistory = convHistory;
        currentHistory = currentHistory.filter(
          (item) =>
            item.collabDetails.collaborationPublicId !==
            conv.collabDetails.collaborationPublicId
        );
        currentHistory = [conv, ...currentHistory];
        setConvHistory(currentHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (e, convDetails) => {
    try {
      // 15 MegaBytes equals 15728640 Bytes (accurate)
      if (e.target.files[0].size > 15728640) {
        setShowFileSizeExceededOverlay(true);
      } else {
        setUploading(true);
        var reader = new FileReader();
        var url = reader.readAsDataURL(e.target.files[0]);
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        // reader.onloadend = async function (event) {
        // console.log(reader.result)
        let uploadAttachmentResult = await axios.post(
          `${process.env.REACT_APP_OWNER_URL}ownerPanel/file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(uploadAttachmentResult);
        // let attachmentToken = uploadAttachmentResult.data.attachmentToken
        let message = {
          roomId: convDetails.collabDetails.collaborationPublicId,
          author: userData.userInformation.publicId,
          contentType: uploadAttachmentResult.data.fileType,
          body: uploadAttachmentResult.data.fileUrl,
          attachmentKey: uploadAttachmentResult.data.fileKey,
        };
        handleChatSend(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMessage = async (convId, id) => {
    try {
      let conv = selectedConversation;
      conv = {
        ...conv,
        messages: conv.messages.map((item) =>
          item.id === id ? { ...item, status: "deleting" } : item
        ),
      };
      setSelectedConversation(conv);
      let deleteMessageResult = await axios.delete(
        `https://api.talkjs.com/v1/typcBJ8o/conversations/${convId}/messages/${id}`,
        {
          headers: {
            Authorization: `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`,
          },
        }
      );
      console.log(deleteMessageResult);
      conv = conv.filter((item) => item.id !== id);
      setSelectedConversation(conv);
    } catch (error) {
      console.log(error);
    }
  };

  const addSkillToConversation = (data, id) => {
    let current = convHistory;
    current = current.map((item) =>
      item.collabDetails.collaborationPublicId === id
        ? { ...item, collabDetails: data }
        : item
    );
    setConvHistory(current);
    let selected = current.find((item) => item.participants.id === otherId);
    setSelectedConversation(selected);
  };

  const stopChatTour = async () => {
    let currentSettings = tourSettings;
    currentSettings = {
      ...currentSettings,
      chatWalkthrough: true,
    };
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}user/settings`,
      currentSettings,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSettings(result.data);
  };

  const closeChatTour = () => {
    setTourStatus(true);
  };

  return (
    <div className="chat-page-conatiner">
      <div className="chat-main-container">
        <ChatHistoryMemo
          convHistory={convHistory}
          handleUserChange={handleUserChange}
          otherId={otherId}
          historyLoading={loading}
          showTour={showTour}
          setHistoryLoading={(value) => setLoading(value)}
          newUnRead={unRead}
        />
        {selectedConversation && (
          <ChatBox
            convDetails={selectedConversation}
            messageLoading={messageLoading}
            userId={userData.userInformation.publicId}
            handleChatSend={handleChatSend}
            handleFileChange={handleFileChange}
            uploading={uploading}
            handleDeleteMessage={handleDeleteMessage}
            addSkillToConversation={addSkillToConversation}
            tourStatus={tourStatus}
            showFileSizeExceededOverlay={showFileSizeExceededOverlay}
            setShowFileSizeExceededOverlay={setShowFileSizeExceededOverlay}
          />
        )}
      </div>
      {selectedConversation && (
        <Tour
          tourType="chat"
          status={!tourSettings.chatWalkthrough}
          stopTour={stopChatTour}
          closeTour={closeChatTour}
        />
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  newMessages: selectNewMessages,
  subscribedRooms: selectSubscribedRooms,
  tourSettings: selectSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setNewMessages: (object) => dispatch(setNewMessages(object)),
  setSubscribedRooms: (object) => dispatch(setSubscribedRooms(object)),
  setSettings: (object) => dispatch(setSettings(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatPage)
);

{
  /* <Link className='click-invisible' to={`/User/Profile/${otherId}`}></Link> */
}
{
  /* <div ref={talkjsContainer} className="chatbox-container">
</div> */
}

//   let me = null;
//   let otherUser = null;
//   let inbox;
//   let conversation;

// if(match.params.userId !== '00000000-0000-0000-0000-000000000000') {
//   let res = await getUserInfo();
//   otherUser = res.data;
// }
//   async function gettingParticipants(arrayOfUsers) {
//   let userResult = await axios.post(`${process.env.REACT_APP_API_URL}user/profiles/info`,{userPublicIds: arrayOfUsers},{
//     headers:{
//       'Authorization': `Bearer ${token}`,
//       'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
//     }
//   });
// return userResult.data;
//   }

//   async function getNewHistory() {
//     try {
//         let result = await axios.get(`https://api.talkjs.com/v1/typcBJ8o/users/${userData.userInformation.publicId}/conversations`,{
//           headers:{
//           'Authorization': `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`
//           }
//         })
//         /*let convResult = await axios.get(`https://api.talkjs.com/v1/typcBJ8o/conversations`,{
//           headers:{
//           'Authorization': `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`
//           }
//         });*/
//         if(result) {
//           /*if(convResult) {
//             console.log(convResult);
//           }*/
//           let conversations = result.data.data;
//           console.log(conversations);
//           if(conversations.length > 0) {
//               let otherParticipants = [];
//               let convMap = new Map();

//               conversations.forEach(conv => {
//                 if(Object.keys(conv.participants)[0] !== '137' && Object.keys(conv.participants).length === 2) {
//                   let ids = Object.keys(conv.participants);
//                   let otherId = ids.filter(item => item !== userData.userInformation.publicId);
//                   convMap.set(otherId[0], conv);
//                   otherParticipants.push(otherId[0]);
//                 }
//               });
//                   let participantsResult = await gettingParticipants(otherParticipants);
//                   participantsResult.forEach(p => {
//                   let id = Object.keys(p)[0];
//                   let conv = convMap.get(id);
//                   conv = {
//                     ...conv,
//                     participants: {
//                       ...p[id],
//                       name: `${p[id].firstName} ${p[id].lastName}`,
//                       id: id
//                     }
//                   }
//                   convMap.set(id, conv);
//                 });

//                 let getCollabResult = await axios.post(`${process.env.REACT_APP_API_URL}collaboration/participants`,{participants: [userData.userInformation.publicId]},{
//                   headers:{
//                   'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
//                   'Authorization': `Bearer ${token}`
//                   }
//                 });
//                 if(getCollabResult.data.length > 0) {
//                     getCollabResult.data.forEach(collab => {
//                       if(collab.status === 1) {
//                         let user = collab.participants.filter(id => id !== userData.userInformation.publicId);
//                         let conv = convMap.get(user[0]);
//                         if(conv) {
//                           conv = {
//                             ...conv,
//                             lastMessage: conv.lastMessage && {...conv.lastMessage,createdAt: moment(conv.lastMessage.createdAt).fromNow()},
//                             createdAt: moment(conv.createdAt).fromNow(),
//                             collabDetails: collab
//                           }
//                           convMap.set(user[0],conv);
//                         }
//                       }
//                     });

//                     otherParticipants.forEach( participant => {
//                       const convMapValue = convMap.get(participant);
//                       if(!convMapValue.hasOwnProperty('collabDetails'))
//                           convMap.delete(participant);
//                     });

// let finalArray = Array.from(convMap.values());
// if(match.params.userId === '00000000-0000-0000-0000-000000000000') {
//   setOtherId(finalArray[0].participants.id);
// }
// setConvHistory(finalArray);
// setLoading(false);
//   if(!otherUser) {
//     setOtherId(finalArray[0].participants.id);
//     handleUserChange(finalArray[0]);
// }else{
//   // setOtherId(otherUser.publicId);
//   let convFound = finalArray.find(item => item.participants.id === otherUser.publicId);
//   handleUserChange(convFound);
//   //if(inbox) {
//     //inbox.mount(talkjsContainer.current);
//   //}
// }
//                 }else {
//                   setConvHistory([]);
//                   setLoading(false);
//                 }
//               // }
//           }
//           else{
//             setConvHistory([]);
//             setLoading(false);
//           }
//       }
//     } catch (error) {
//       setConvHistory([]);
//       setLoading(false);
//       console.log(error);
//       if(error.response) {
//         if(error.response.status === 401) {
//           Logout();
//         }
//       }
//     }
// }

//   Talk.ready.then(async () => {
//     let conversationFound = false;
//     let conversationCreated = false;
//     setLoading(true);
//     if(userData) {
//       me = new Talk.User({
//           role: "User",
//           id: userData.userInformation.publicId,
//           name: `${userData.userInformation.firstName} ${userData.userInformation.lastName}`,
//           email: userData.userInformation.email,
//           photoUrl: userData.userInformation.profilePhotoUrl?userData.userInformation.profilePhotoUrl : null,
//           welcomeMessage: "Hey there! How are you? :-)"
//       });
//     }else {
//       me = new Talk.User({
//         role: "User",
//           id: '137',
//           name: 'saurabh',
//           email: 'saurabh@gmail',
//           photoUrl: 'nm',
//           welcomeMessage: "Hey there! How are you? :-)"
//       });
//     }

//     let talkSession = new Talk.Session({
//       appId: "typcBJ8o",
//       me: me
//     });

//     if(otherUser) {
//         setOtherId(otherUser.publicId);
//         var other = new Talk.User({
//             role:'User',
//             id: otherUser.publicId,
//             name: `${otherUser.firstName} ${otherUser.lastName}`,
//             email: otherUser.email,
//             photoUrl: otherUser.profilePhotoUrl? otherUser.profilePhotoUrl : null,
//             welcomeMessage: "So glad to connect! How can we collaborate?"
//         });

//         conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other));
//         conversation.setParticipant(me);
//         conversation.setParticipant(other);
//         //inbox = talkSession.createChatbox(conversation);
//         try {
//           let convFound = await axios.get(`https://api.talkjs.com/v1/typcBJ8o/conversations/${conversation.id}`,{
//             headers:{
//             'Authorization': `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`
//             }
//           });
//           if(convFound) {
//             conversationFound = true;
//           }
//         } catch (error) {
//           if(error.response) {
//             if(error.response.data.errorCode === "CONVERSATION_NOT_FOUND") {
//               let putConv = await axios.put(`https://api.talkjs.com/v1/typcBJ8o/conversations/${conversation.id}`,{
//                 participants: [userData.userInformation.publicId, otherUser.publicId],
//                 subject: null,
//                 welcomeMessages: ["So glad to connect! How can we collaborate?"],
//                 custom:{},
//                 photoUrl:null
//               },{
//                 headers:{
//                   'Authorization': `Bearer sk_test_moQNUpvJP21ZLywy5UPhxRo3`
//                 }
//               });
//               conversationCreated = true;
//             }
//           }
//         }
//     }
//     // else{
//     //   inbox = talkSession.createChatbox(conversation);
//     // }
//     // if(inbox) {
//     //   setShowTour(true);
//       if(otherUser) {
//         if(conversationFound) {
//           await getNewHistory();
//         }else{
//           if(conversationCreated) {
//             await getNewHistory();
//           }
//         }
//       }else {
//         await getNewHistory();
//       }
//     //}
//   });
