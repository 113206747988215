import { FETCH_USERS } from './actions/fetchUsers';
import { SEARCH_USERS } from './actions/searchUsers';
import { CLEAR_SEARCH_USERS } from './actions/clearUsersSearch';

const initialState = {
    users: [],
    search:[],
    usersIsLoaded: false
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS:
            return { ...state, users: action.payload, usersIsLoaded: true }
        case SEARCH_USERS:
            return { ...state, search: action.payload }
        case CLEAR_SEARCH_USERS:
            return { ...state, search: [] }
        default:
            return { ...state };
    }
}
export default usersReducer