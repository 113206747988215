/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,TextField,IconButton,Button } from '@material-ui/core';
import Input from '../Input/Input';
import PublishIcon from '@material-ui/icons/Publish';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function EditBadgeModal({open,handleClose,details,handleUpdate}) {
  const classes = useStyles();

  const [info,setInfo] = useState({
    oldBadgeName:'',
    oldImage:null,
    badgeName:'',
    image:null
  });

  useEffect(()=>{
    try {
      if(details){
        const image = details.badgeImageKey;
        const imageInfo = {
          name:image.slice(14,image.length)
        };
        setInfo({
          oldBadgeName:details.badgeName,
          oldImage:image.slice(14,image.length),
          badgeName:details.badgeName,
          image:imageInfo
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  },[details]);

  const handleChange = e => {
    const{name,value} = e.target;
    if(e.target.files){
      const file = e.target.files[0];
      setInfo(prevValue =>{
        return{
          ...prevValue,
          [name]:file
        };
      });
      return;
    }
    setInfo(prevValue =>{
      return{
        ...prevValue,
        [name]:value
      };
    });
  };


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction='up'>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold'}}>Update badge</Typography>
            <div style={{width:'100%',marginTop:'20px'}}>
            <Input label='Badge Name' value={info.badgeName} name='badgeName' onChange={handleChange}/>
            <br/>
            <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
              <div style={{width:'100%'}}>
                <TextField
                className='input'
                id="input-with-icon-textfield"
                label='Badge Image'
                type='text'
                value={info.image&&info.image.name}
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}

                />
                  <input  type='file' id='logo-update' style={{display:'none'}} name='image'  onChange={handleChange}/>
                </div>
                <div >
                  <label htmlFor='logo-update'>
                    <IconButton color="primary" aria-label="upload picture" component="span" button >
                      <PublishIcon/>
                    </IconButton>
                  </label>
                </div>
            </div>
            <br/>
            </div>
            <div style={{width:'80%',display:'flex',justifyContent:'space-between'}}>
            <Button variant='outlined' style={{color:'#66CCFF',borderColor:'#66CCFF'}} onClick={()=>handleUpdate(info)}>Update</Button>
            <Button variant='outlined' style={{color:'red',borderColor:'red'}} onClick={()=>handleClose()}>Discard</Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
