import React from "react";
import {Paper,IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.scss';

function DepartmentListItem ({name, handleDelete, handleEdit}) {
    return (
        <Paper className='department-list-item-container'>
            <small>{name}</small>
            <IconButton onClick={() => handleEdit()}>
                <EditIcon/>
            </IconButton>
            <IconButton onClick={() => handleDelete()}>
                <DeleteIcon/>
            </IconButton>
        </Paper>
    )
}

export default DepartmentListItem;