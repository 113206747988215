export const articles = [
    {
        id: 14,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        "comments": [
            {
                id: 13,
                commentsPublicId: "29cb08eb-8f17-4631-bc0c-48e519700256",
                author: {
                    id: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                    name: "anthony",
                    profile: "https://images.pexels.com/photos/1975342/pexels-photo-1975342.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                },
                comment: "test comment",
                createdAt: "2021-07-22T23:33:08.966Z",
                updatedAt: "2021-07-22T23:33:08.966Z",
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 1,
        noOfViews: 0
    },
    {
        id: 15,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    },
    {
        id: 16,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    },
    {
        id: 17,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    },
    {
        id: 18,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    },
    {
        id: 19,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    },
    {
        id: 20,
        contentPublicId: "0abfc523-ad33-4add-a0da-56b83d11aa03",
        primaryAuthorPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
        fileName: "Sed ut perspiciatis unde",
        title: "Lorem Ipsum",
        status: "PUBLISHED",
        taggedSkills: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e"
        ],
        taggedCoAuthorsPublicId: [
            "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            "fb023e0e-fb05-45dd-8372-6eeadd85746f"
        ],
        author_name: "Anthony Fniix",
        author_profile: "https://images.pexels.com/photos/4355346/pexels-photo-4355346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        author_designation: "Software Developer",
        fileExtension: null,
        uploadedFileUrl: null,
        uploadedFileKey: null,
        fileMimeType: null,
        publishedOn: null,
        modifiedBy: "Test",
        synopsis: "",
        coverImageUrl: "https://images.pexels.com/photos/273935/pexels-photo-273935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        coverImageKey: null,
        thumbnailImageUrl: null,
        thumbnailImageKey: null,
        content: "omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
        createdAt: "2021-07-22T22:29:29.302Z",
        updatedAt: "2021-07-26T04:58:16.947Z",
        likes: [
            {
                id: 4,
                likesPublicId: "253479cb-eb11-40b5-871d-5cf2ea10ac96",
                contentPublicId: "1ae04b8a-d89a-4bf4-b643-59a1571c6b39",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:48.894Z",
                updatedAt: "2021-07-22T22:40:48.894Z"
            },
            {
                id: 5,
                likesPublicId: "e7bb0482-3ba3-4ddd-95c0-8e84f0df9c65",
                contentPublicId: "f4168038-bd59-4ae6-8aeb-65cbdf81e5c3",
                userPublicId: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
                createdAt: "2021-07-22T22:40:57.881Z",
                updatedAt: "2021-07-22T22:40:57.881Z"
            }
        ],
        views: [],
        bookmarks: [],
        noOfLikes: 2,
        noOfComments: 0,
        noOfViews: 0
    }
]

export const comment = [
    {
        id: 13,
        commentsPublicId: "29cb08eb-8f17-4631-bc0c-48e519700256",
        author: {
            id: "fb023e0e-fb05-45dd-8372-6eeadd85746e",
            name: "anthony",
            profile: "https://images.pexels.com/photos/1975342/pexels-photo-1975342.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        },
        comment: "test comment",
        createdAt: "2021-07-22T23:33:08.966Z",
        updatedAt: "2021-07-22T23:33:08.966Z",
    }
]

export const users = [
    { name: "test" },
    { name: "test1" },
    { name: "test2" },
    { name: "test3" },
]