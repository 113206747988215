
export const iconUndoSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="15.7" height="13.738" viewBox="0 0 15.7 13.738">
<path id="Icon_metro-undo" data-name="Icon metro-undo" d="M10.421,3.856a7.825,7.825,0,0,0-5.551,2.3l-2.3-2.3V9.744H8.458l-2.2-2.2a5.887,5.887,0,1,1,8.057,8.579l1.3,1.472A7.85,7.85,0,0,0,10.421,3.856Z" transform="translate(-2.571 -3.856)"/>
</svg>`
export const iconRedoSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="15.7" height="13.738" viewBox="0 0 15.7 13.738">
<path id="Icon_metro-undo" data-name="Icon metro-undo" d="M10.421,3.856a7.825,7.825,0,0,1,5.551,2.3l2.3-2.3V9.744H12.383l2.2-2.2a5.887,5.887,0,1,0-8.057,8.579l-1.3,1.472A7.85,7.85,0,0,1,10.421,3.856Z" transform="translate(-2.571 -3.856)"/>
</svg>`
export const iconTextColorSvg = `<svg id="Icon_material-format-color-fill" data-name="Icon material-format-color-fill" xmlns="http://www.w3.org/2000/svg" width="14.718" height="16.345" viewBox="0 0 14.718 16.345">
<path id="Path_6387" data-name="Path 6387" d="M14.561,6.634,7.926,0,6.88,1.046,8.646,2.812,4.825,6.634a1.106,1.106,0,0,0,0,1.573l4.081,4.081a1.111,1.111,0,0,0,1.573,0l4.081-4.081A1.106,1.106,0,0,0,14.561,6.634Zm-8.423.787L9.693,3.866l3.555,3.555ZM16.371,8.534s-1.484,1.61-1.484,2.6a1.484,1.484,0,0,0,2.968,0C17.855,10.144,16.371,8.534,16.371,8.534Z" transform="translate(-4.145 0)"/>
<path id="Path_6388" data-name="Path 6388" d="M0,30H14.718v1.5H0Z" transform="translate(0 -15.159)"/>
</svg>`
export const iconCodeSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="25.464" height="16.068" viewBox="0 0 25.464 16.068">
<g id="Group_483" data-name="Group 483" transform="translate(0 0)">
  <path id="Path_2255" data-name="Path 2255" d="M2483.133,150.75a.919.919,0,0,1-.884-1.188l4.255-14.217a.925.925,0,0,1,1.772.529l-4.258,14.217A.925.925,0,0,1,2483.133,150.75Z" transform="translate(-2472.531 -134.683)"/>
  <path id="Path_2256" data-name="Path 2256" d="M2487,144.924a.941.941,0,0,1-.463-.122l-6.481-3.744a.925.925,0,0,1,0-1.6l6.481-3.74a.926.926,0,1,1,.925,1.605l-5.094,2.934,5.094,2.941a.925.925,0,0,1-.462,1.728Z" transform="translate(-2479.593 -132.222)"/>
  <path id="Path_2257" data-name="Path 2257" d="M2485.15,144.924a.925.925,0,0,1-.462-1.728l5.094-2.941-5.094-2.934a.926.926,0,1,1,.925-1.605l6.481,3.74a.925.925,0,0,1,0,1.6l-6.481,3.744A.941.941,0,0,1,2485.15,144.924Z" transform="translate(-2467.093 -132.222)"/>
</g>
</svg>`
export const iconLinkSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="19.352" height="9.676" viewBox="0 0 19.352 9.676">
<path id="Path_1308" data-name="Path 1308" d="M3.838,11.838a3,3,0,0,1,3-3h3.87V7H6.838a4.838,4.838,0,0,0,0,9.676h3.87V14.838H6.838A3,3,0,0,1,3.838,11.838Zm3.967.968h7.741V10.87H7.806ZM16.514,7h-3.87V8.838h3.87a3,3,0,1,1,0,6h-3.87v1.838h3.87a4.838,4.838,0,1,0,0-9.676Z" transform="translate(-2 -7)"/>
</svg>`
export const iconTableSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="15.969" height="15.968" viewBox="0 0 15.969 15.968">
<path id="Path_6386" data-name="Path 6386" d="M2528.193,168.35h-12.523a1.724,1.724,0,0,0-1.723,1.722V182.6a1.724,1.724,0,0,0,1.723,1.722h12.523a1.724,1.724,0,0,0,1.723-1.722V170.072A1.724,1.724,0,0,0,2528.193,168.35Zm.364,1.722v5.584h-5.948v-5.949h5.583A.365.365,0,0,1,2528.557,170.072Zm-12.888-.365h5.583v5.949H2515.3v-5.584A.365.365,0,0,1,2515.669,169.707ZM2515.3,182.6v-5.584h5.948v5.949h-5.583A.365.365,0,0,1,2515.3,182.6Zm12.889.365h-5.583v-5.949h5.948V182.6A.365.365,0,0,1,2528.193,182.961Z" transform="translate(-2513.946 -168.35)"/>
</svg>`