/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,Button} from '@material-ui/core';
import Input from '../Input/Input';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddSkillModal({open,handleClose,data,handleAdd,operation, handleDelete}) {
  const classes = useStyles();
  const [skill,setSkill] = useState({
    skillId:null,
    skill:''
  });

  useEffect(()=>{
    if(data){
      console.log(data);
      setSkill({
        skillId:data.skillId,
        skill:data.skillName
      });
    }
  },[data]);

  const handleChange = e => {
    const {value} = e.target;
    setSkill(prevValue => {
      return{
        ...prevValue,
        skill:value
      };
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction='up'>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold'}}>{operation==='add' ? `Add a skill to ${data&&data.skillCategory.skillCategoryDisplayName}` : operation==='edit' ? `Update ${data&&data.skillName}` : `Delete ${data&&data.skillName}`}</Typography>
            <br/>
            {(operation === 'add' || operation === 'edit') && <Input value={skill.skill} onChange={handleChange} type='text' label='Skill Name'/>}
            {operation === 'delete' && <Typography variant='h6' style={{textAlign:'center'}}> Are you sure?</Typography>}
            <br/>
            <div style={{textAlign:'center'}}>
            {(operation === 'add' || operation === 'edit') && <Button variant='outlined' color='primary' style={{marginRight:'5px'}} onClick={()=>{handleAdd(data,skill)}}>{operation==='add'?'Add':'Update'}</Button>}
            {operation === 'delete' && <Button variant='outlined' color='primary' style={{marginRight:'5px', color:'red', borderColor:'red'}} onClick={()=>{handleDelete(data.skillId,data.categoryId)}}> Delete </Button>}
            <Button variant='outlined' style={{color:operation !== 'delete' && 'red',borderColor:operation !== 'delete' && 'red'}} onClick={()=>handleClose()}>{operation === 'delete' ? 'Cancel' : 'Discard'}</Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
