/*jshint esversion:9*/

import React, { useState } from "react";
import { Typography, IconButton, Avatar, Tooltip } from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CheckIcon from "@material-ui/icons/Check";
import "./interestCard-style.scss";

function InterestCard({ handleSelect, name, Id, selected, imgUrl, view }) {
  //const [mouseOver, setMouseOver] = useState(false);

  return (
    <div className="card-container">
      <div className="image-container">
        <Avatar
          src={
            imgUrl !== ""
              ? imgUrl
              : "https://images.unsplash.com/photo-1594909122845-11baa439b7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"
          }
          className={
            !view ? `${selected ? "image-selected" : "image"}` : "view-image"
          }
          // onMouseEnter={() => !view && setMouseOver(true)}
          // onMouseLeave={() => !view && setMouseOver(false)}
          onClick={() => {
            // console.log("Clicked Icon!");
            // !view &&
            handleSelect(Id);
          }}
        />
        {selected && (
          <CheckIcon
            className="check-icon"
            fontSize="large"
            onClick={() => handleSelect(Id)}
          />
        )}
      </div>
      <Tooltip title={name}>
        <Typography
          variant="subtitle2"
          className="interest-name"
          onClick={() => handleSelect(Id)}
        >
          {name}
        </Typography>
      </Tooltip>
    </div>
  );
}

export default InterestCard;

{
  /* <div className='card' />
<img src={imgUrl!==''?imgUrl:"https://images.unsplash.com/photo-1594909122845-11baa439b7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"} alt='img' className='image'/>
<div className='card-overlay' >
  <Typography variant='subtitle1' style={{fontWeight:'bold'}}> {name} </Typography>
 {!view && <IconButton className='heart-btn' button >
  {selected?<FavoriteIcon style={{cursor:'pointer',color:'#b71c1c'}}/>:<FavoriteBorderIcon/>}
  </IconButton>}
</div> */
}
