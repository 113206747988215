import React from "react";
import Profile from "../profile";
import classes from "./commentTextField.module.scss";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import { Avatar } from "@material-ui/core";
import AutoCompleteTagging from "../autoCompleteTagging";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import cmsAxios from "../../axios/authAxios";
import Emoji from "../emoji";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

const Item = ({ entity: { name } }) => {
  return (
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "96%",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          padding: "0 5px 0 5px",
        }}
      >{`${name}`}</span>
    </div>
  );
};
const User = ({ entity: { profilePhotoUrl, firstName, lastName } }) => (
  <div
    style={{
      display: "flex",
      padding: "0 5px 0 5px",
    }}
  >
    <Avatar src={profilePhotoUrl} style={{ width: "30px", height: "30px" }} />
    <span
      style={{
        marginLeft: "10px",
      }}
    >{`${firstName} ${lastName}`}</span>
  </div>
);

const CommentTextField = ({
  userImage,
  handleChange,
  handleEnter,
  commentValue,
  setCommentValue,
  handleSent,
  userTagCounter,
  skillTagCounter,
  onSelect,
  ...props
}) => {
  const emojiIconRef = React.useRef(null);
  const [isEmojiDialogOpen, setIsEmojiDialog] = React.useState(false);
  const handleEmojiClick = (e) => {
    emojiIconRef.current = e.currentTarget;
    setIsEmojiDialog(!isEmojiDialogOpen);
  };
  const getEmojiIcon = (e) =>
    setCommentValue(
      (currentComment) => (currentComment = currentComment + e.native)
    );
  const closeEmojiDialog = () => setIsEmojiDialog(false);
  return (
    <div className={`${classes.wrapper} my-sm`}>
      <div className={`${classes.profile_textfield_wrapper}`}>
        <Profile className="mt-xs" image={userImage} size="xs" />
        {/* <TextField
          variant="outlined"
          multiline
          rows={3}
          value={commentValue}
          placeholder="Write a comment"
          onChange={handleChange}
        /> */}

        <ReactTextareaAutocomplete
          loaderStyle={{
            position: "relative",
            top: "20px",
          }}
          containerStyle={{
            width: "100%",
            position: "relative",
            zIndex: "1",
            borderColor: "yellow",
            borderRadius: "10px",
          }}
          listStyle={{
            width: "250px",
            marginTop: "10px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            zIndex: "10",
            borderRadius: "2px",
            padding: "5px 0 5px 0",
            backgroundColor: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          dropdownStyle={{
            zIndex: "10",
            position: "absolute",
            bottom: "10px",
            backgroundColor: "white",
          }}
          itemClassName={classes.listItem}
          ref={(rta) => {
            CommentTextField.rta = rta;
          }}
          innerRef={(textarea) => {
            CommentTextField.textarea = textarea;
          }}
          value={commentValue}
          className={classes.articleCommentTextArea}
          placeholder="Write a comment"
          onChange={handleChange}
          loadingComponent={() => <span>Loading</span>}
          rows={3}
          trigger={{
            "#": {
              dataProvider: async (token) => {
                let skillNames = [];
                if (token.length >= 3) {
                  if (token.includes("_")) {
                    token = token.split("_").join(" ");
                  }
                  skillNames = await cmsAxios
                    .get(`user/autocomplete/skills?skillName=${token}`)
                    .then((res) => {
                      return res.data;
                    });
                  skillNames = skillNames.map((item) => {
                    return { name: item.skillName };
                  });
                  skillNames = skillNames.map((item) =>
                    item.name.split("").includes(" ")
                      ? { name: item.name.replace(/ /g, "_") }
                      : item
                  );
                  console.log(skillNames);
                  console.log(skillNames);
                  return skillNames;
                }
              },
              component: Item,
              output: (item, trigger) => `#${item.name}`,
            },
            "@": {
              dataProvider: async (token) => {
                let userNames = [];
                if (token.length >= 3) {
                  if (token.includes("_")) {
                    token = token.split("_").join(" ");
                  }
                  userNames = await cmsAxios
                    .get(`user/username/autocomplete?name=${token}&limit=3`)
                    .then((res) => {
                      console.log(res.data);
                      return res.data;
                    })
                    .catch((err) => console.log(err));
                  userNames = userNames.map((item) => {
                    return { ...item };
                  });
                  //skillNames = skillNames.map(item => item.name.split('').includes(" ")?{name:item.name.replace(/\s+/, '_')}:item);
                  console.log(userNames);
                  return userNames;
                }
              },
              component: User,
              output: (item, trigger) => `@${item.firstName}_${item.lastName}`,
            },
          }}
        />
      </div>
      <div className={`${classes.attachment_wrapper}`}>
        <IconButton ref={emojiIconRef} onClick={handleEmojiClick}>
          <InsertEmoticonIcon />
        </IconButton>
        <IconButton onClick={handleSent}>
          <SendIcon />
        </IconButton>
      </div>
      <Emoji
        open={isEmojiDialogOpen}
        handleClose={closeEmojiDialog}
        el={emojiIconRef.current}
        handleEmojiOnClick={getEmojiIcon}
      />
      <AutoCompleteTagging
        userCounter={userTagCounter}
        skillCounter={skillTagCounter}
        onSelect={onSelect}
      />
    </div>
  );
};

export default CommentTextField;
