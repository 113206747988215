import React, { useState, useEffect } from "react";
import { Avatar, Paper, Divider, Typography } from "@material-ui/core";
import RelvantSkillCard from "../SubComponents/relevant-skills-card/relevant-skills-card";
import { Link } from "react-router-dom";
import PointsTableModal from "../SubComponents/points-table-modal/points-table-modal";
import BadgesDetailsModal from "../SubComponents/badgesDetails-modal/badgesDetails-modal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectLocationData,
  selectCompanyDepartments,
} from "../../redux/user/user-selector";
import pointsImg from "../../Assets/points.png";
import badgesImg from "../../Assets/BeeIcon.png";
import RankImg from "../../Assets/rank-logo.png";
import axios from "axios";
import Logout from "../../logout";
import { withRouter } from "react-router-dom";
import "./profile-brief-style.scss";
import getDepartmentName from "../../utils/getDepartmentName";

function ProfileBrief({
  userData,
  location,
  history,
  allRecommendedSkills,
  handleFilterAddedSkill,
  companyDepartments,
}) {
  let userAgentString = navigator.userAgent;

  const [userbadges, setUserBadges] = useState(null);
  const [userPoints, setUserPoints] = useState(0);
  const [userRank, setUserRank] = useState("");
  const [pointsTable, setPointsTable] = useState(false);
  const [pointsTableData, setPointsTableData] = useState(null);
  const [badgesModal, setBadgesModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    try {
      const getUserInfo = async (users) => {
        let result = await axios.post(
          `${process.env.REACT_APP_API_URL}user/profiles/info`,
          { userPublicIds: users },
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };
      let badges = userData.userInformation.badges;

      if (badges) {
        console.log(badges);
        async function getUserPoints() {
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL_POINTS}points/rank`,
            { userPublicId: userData.userInformation.publicId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
          );
          setUserPoints(result.data.points);
          setUserRank(result.data.rank);
        }

        function setProfileCompletion() {
          try {
            var percent = 0;
            if (userData) {
              if (
                userData.userInformation.firstName &&
                userData.userInformation.lastName &&
                userData.userInformation.gender &&
                userData.userInformation.userLocation &&
                userData.userInformation.dateOfBirth
              ) {
                percent = percent + 15;
              }

              if (userData.userInformation.profilePhotoUrl) {
                percent = percent + 5;
              }

              if (userData.userInformation.workExperience) {
                percent = percent + 20;
              }

              if (userData.userInformation.userSkillsSet) {
                if (
                  userData.userInformation.userSkillsSet.userSkills.length < 5
                ) {
                  var percentToDeduct =
                    (5 -
                      userData.userInformation.userSkillsSet.userSkills
                        .length) *
                    1.25;
                  percent = percent + (30 - percentToDeduct);
                } else {
                  percent = percent + 30;
                }
              }
              if (userData.userInformation.userInterestSet) {
                percent = percent + 30;
              }
              setProgress(percent);
            }
          } catch (error) {
            console.log(error.message);
          }
        }

        async function gettingAllBadges() {
          try {
            let userIds = [];
            let userList = [];
            let badgeList = [];
            badges.forEach((badge) => {
              userIds.push(badge.badgeDonorPublicId);
            });
            let usersResult = await getUserInfo(userIds);
            if (usersResult) {
              usersResult.data.forEach((user) => {
                let id = Object.keys(user)[0];
                let object = {
                  id: id,
                  name: `${user[id].firstName} ${user[id].lastName}`,
                };
                userList.push(object);
              });
            }
            badges.forEach((badge) => {
              let donorExist = userList.find(
                (item) => item.id === badge.badgeDonorPublicId
              );
              if (donorExist) {
                let badgeObject = {
                  ...badge,
                  name: donorExist.name,
                };
                badgeList.push(badgeObject);
              }
            });
            setUserBadges(badgeList);
          } catch (error) {
            if (error.response) {
              console.log(error.response.message);
              if (error.response.status === 401) {
                history.push("/logout");
              }
            }
          }
        }
        if (userData.userInformation) {
          getUserPoints();
          setProfileCompletion();
          gettingAllBadges();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [userData]);

  const handlePointsTable = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let getPointsTableResult = await getPointsTable();
      if (getPointsTableResult) {
        console.log(getPointsTableResult.data);
        setPointsTableData(getPointsTableResult.data);
        setLoading(false);
      }
      setPointsTable(true);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        console.log(err.response.message);
        if (err.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const getPointsTable = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL_POINTS}points/company/pointsTable`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  return (
    <Paper
      style={{
        width: "100%",
        padding: "8% 0",
        height: "fit-content",
        marginBottom: "40px",
      }}
    >
      <div className="container-title">
        <Typography variant="h6" className="collab-section-title">
          My Skill Passport
        </Typography>
        <p style={{ width: "100%", fontSize: "1.1rem" }}>
          Your profile and skill card
        </p>
      </div>
      <div className="profile-brief-main-container">
        {userData && (
          <div
            className="profile-brief-container"
            style={{
              textAlign:
                userAgentString.indexOf("Firefox") > -1 &&
                "-moz-center!important",
            }}
          >
            <div className="profile-brief-user-info-container">
              {userData.userInformation.profilePhotoUrl !== "" && (
                <Avatar
                  src={userData.userInformation.profilePhotoUrl}
                  className="profile-brief-avatar"
                  onClick={() =>
                    history.push(
                      `/User/Profile/${userData.userInformation.publicId}`
                    )
                  }
                  style={{
                    textAlign:
                      userAgentString.indexOf("Firefox") > -1 && "-moz-center",
                  }}
                />
              )}
              {userData.userInformation.profilePhotoUrl === "" && (
                <div className="initial-main-container">
                  <Typography
                    variant="h4"
                    onClick={() =>
                      history.push(
                        `/User/Profile/${userData.userInformation.publicId}`
                      )
                    }
                    style={{
                      textAlign:
                        userAgentString.indexOf("Firefox") > -1
                          ? "-moz-center"
                          : "-webkit-center",
                      color: "white",
                      background: "#FFCC33",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                  >
                    {`${userData.userInformation.firstName.slice(
                      0,
                      1
                    )}${userData.userInformation.lastName.slice(0, 1)}`}
                  </Typography>
                </div>
              )}
              <div className="profile-brief-basic-info">
                <p
                  className="profile-brief-name"
                  onClick={() =>
                    history.push(
                      `/User/Profile/${userData.userInformation.publicId}`
                    )
                  }
                >{`${userData.userInformation.firstName} ${userData.userInformation.lastName}`}</p>
                {!!userData &&
                  !!userData.userInformation &&
                  !!userData.userInformation.department &&
                  !!companyDepartments && (
                    <p className="profile-brief-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="18"
                        viewBox="0 0 23 23"
                        style={{ marginTop: "3px" }}
                      >
                        <g id="outline-work_outline-24px" opacity="0.489">
                          <path
                            id="Path_2077"
                            data-name="Path 2077"
                            d="M0,0H23V23H0Z"
                            fill="none"
                          />
                          <path
                            id="Path_2078"
                            data-name="Path 2078"
                            d="M13.4,5.789V3.895H9.6V5.789ZM3.9,7.684V18.105H19.1V7.684ZM19.1,5.789A1.891,1.891,0,0,1,21,7.684V18.105A1.891,1.891,0,0,1,19.1,20H3.9A1.891,1.891,0,0,1,2,18.105L2.01,7.684A1.883,1.883,0,0,1,3.9,5.789H7.7V3.895A1.891,1.891,0,0,1,9.6,2h3.8a1.891,1.891,0,0,1,1.9,1.895V5.789Z"
                          />
                        </g>
                      </svg>
                      <span>
                        {getDepartmentName(
                          userData.userInformation.department,
                          companyDepartments
                        )}
                      </span>
                    </p>
                  )}
                <p className="profile-brief-title profile-brief-location">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 18 18"
                    style={{ marginTop: "3px" }}
                  >
                    <g id="outline-location_on-24px" opacity="0.5">
                      <path
                        id="Path_2071"
                        data-name="Path 2071"
                        d="M0,0H18V18H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_2072"
                        data-name="Path 2072"
                        d="M9.917,2A5.038,5.038,0,0,0,5,7.157c0,3.868,4.917,9.577,4.917,9.577s4.917-5.709,4.917-9.577A5.038,5.038,0,0,0,9.917,2ZM6.4,7.157A3.6,3.6,0,0,1,9.917,3.473,3.6,3.6,0,0,1,13.43,7.157c0,2.122-2.023,5.3-3.512,7.279C8.456,12.468,6.4,9.256,6.4,7.157Z"
                        transform="translate(-0.917 -0.367)"
                      />
                      <circle
                        id="Ellipse_11"
                        data-name="Ellipse 11"
                        cx="2.041"
                        cy="2.041"
                        r="2.041"
                        transform="translate(6.959 4.761)"
                      />
                    </g>
                  </svg>
                  <span>
                    {!location.streetAddress ||
                    location.streetAddress.length === 0
                      ? `${location.city}, ${location.country}`
                      : `${location.streetAddress}, ${location.city}`}
                  </span>
                </p>
              </div>
            </div>
            <Divider />
            <RelvantSkillCard
              userSkills={
                userData && userData.userInformation.userSkillsSet.userSkills
              }
              allRecommendedSkills={
                allRecommendedSkills && allRecommendedSkills
              }
              handleFilterAddedSkill={(skill) => handleFilterAddedSkill(skill)}
            />
            {/* <div className='profile-brief-info'>
                    <img src={pointsImg} alt='points logo' width='70' height='70' onClick={handlePointsTable} style={{cursor:'pointer'}} className='profile-brief-info-logo'/>
                    <div style={{textAlign:'left'}}>
                        <h2>{userPoints}</h2>
                        <p className='profile-brief-info-link' onClick={handlePointsTable}>Points</p>
                    </div>
                </div>
                <Divider/>
                <div className='profile-brief-info'>
                    <img src={badgesImg} alt='points logo' width='70' height='70' onClick={() => setBadgesModal(true)} style={{cursor:'pointer'}} className='profile-brief-info-logo'/>
                    <div style={{textAlign:'left'}}>
                        <h2>{userData.userInformation.badges.length}</h2>
                        <p className='profile-brief-info-link' onClick={() => setBadgesModal(true)}>Badges</p>
                    </div>
                </div>
                <Divider/>
                <div className='profile-brief-info'>
                    <img src={RankImg} alt='points logo' width='70' height='70' className='profile-brief-info-logo'/>
                    <div style={{textAlign:'left'}}>
                        <h2>{userRank}</h2>
                        <p className='profile-brief-info-link' style={{cursor:'auto',textDecoration:'none'}}>Rank</p>
                    </div>
                </div>
                <Divider/>
                <div className='profile-brief-info'>
                  <div className="profile-brief-completion-logo-main-container">
                    <div className="profile-brief-completion-logo-contianer" style={{height:`calc(100% - ${progress}%)`}}>
                      <div className="profile-brief-completion-logo"></div>
                    </div>
                    <div className="filled-profile-brief-completion-logo-container">
                      <div className="filled-profile-brief-completion-logo"></div>
                    </div>
                  </div>
                  <div style={{textAlign:'left'}}>
                      <h2>{progress}%</h2>
                      <Link className='profile-brief-info-link' to={`/User/Profile/${userData.userInformation.publicId}`}>Completed</Link>
                  </div>
                </div> */}
          </div>
        )}
        <PointsTableModal
          open={pointsTable}
          handleClose={() => setPointsTable(false)}
          data={pointsTableData}
          loading={loading}
        />
        <BadgesDetailsModal
          open={badgesModal}
          badges={userbadges && userbadges}
          handleClose={() => setBadgesModal(false)}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  location: selectLocationData,
  companyDepartments: selectCompanyDepartments,
});

export default withRouter(connect(mapStateToProps)(ProfileBrief));
