import React,{useState,memo} from 'react';
import style from './preview.module.scss';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import SecondaryButton from '../secondaryButton';
import './preview_style.css';
import parse from 'html-react-parser';
import PdfLink from '../pdfLink';
import ImagePreview from '../ImagePreviewPopup'
import FileLink from '../FileLink'
import {isExcel,isPpt,isWord} from '../FileTypes'

const PreviewArticle = ({ noPadding, shadow, content, handleEdit, handleNext, noHeader }) => {
    const [imageUrl,setImageUrl] = useState()
    const [open,setOpen] = useState(false)
    const NoPreviewData = () => <div className={`${style.no_preview}`}>preview data not found</div>
    const getImageLink = (event)=>{
        if(event.target.src){
            setOpen(true)
            setImageUrl(event.target.src)
        }
    }
    const parsedHtml = parse(content || '', {
        replace: domNode => {
          if (domNode.attribs && domNode.attribs['data-type'] === 'application/pdf') {
            return <PdfLink url={domNode.attribs['href']} text={domNode.children[0].data}/>;
        //   } else if((domNode.attribs && domNode.attribs['data-type']) &&( isWord(domNode.attribs['data-type'] ) || isPpt(domNode.attribs['data-type'] )|| isExcel(domNode.attribs['data-type'] ))){
        //       return <FileLink url={domNode.attribs['href']} text={domNode.children[0].data}/>
          }
        }
    });
    const PreviewData = () =>{
        return (
          <>
            <div onClick={getImageLink} id="preview-content">
              {parsedHtml}
            </div>
            <ImagePreview open={open} setOpen={setOpen} imageUrl={imageUrl} />
          </>
        );
    }
    let cssStyle = {}
    if (shadow) cssStyle.boxShadow = "0 6px 6px #0000003a"
    return (
        <div className={`${style.wrapper}`} style={cssStyle}>
            <div className={`${style.content} ${noPadding ? "p-0" : ""}`}>
                {content ? <PreviewData /> : <NoPreviewData />}
            </div>
            {
                noHeader ? null : (
                    <div className={`test ${style.header} px-md py-sm`}>
                        <IconButton aria-label="back" onClick={handleEdit}>
                            <ArrowBackIcon />
                        </IconButton>
                        <h3>Preview</h3>
                        <div className={`${style.action_wrapper}`}>
                            {/* <SecondaryButton onClick={handleEdit ? handleEdit : () => console.log('no implemented')}>Edit</SecondaryButton> */}
                            <SecondaryButton disabled={content ? false : true} variant="outlined" onClick={handleNext ? handleNext : () => {/*console.log('no implemented')*/}}>Next</SecondaryButton>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default memo(PreviewArticle);