/*jshint esversion:9*/
import React from "react";
import { makeStyles, Typography, Link, IconButton } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import aboutUs from "../../../Assets/aboutUs.png";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    maxWidth: "720px",
    maxHeight: "550px",
    padding: "50px",
    textAlign: "center",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AboutUsModal({ open, handleClose, handleDetails }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <img src={aboutUs} alt="comingSoon" style={{ width: "250px" }} />
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Unlock The Power of Peer Learning!
            </Typography>
            <br />
            <Typography variant="h6" style={{ fontWeight: "500" }}>
              BHyve is the most powerful software you can use to <br />
              Learn, Create & Share Knowledge.
            </Typography>
            <br />
            <Typography variant="body1">
              We help connect and empower every learner and transform your
              workplace into a highly engaged one. With BHyve, your employees
              can emerge as the most valuable asset of your organisation!
            </Typography>
            <br />
            <Link
              style={{ color: "#EE813A", cursor: "pointer" }}
              href="https://bhyve.io/about-us/"
              target="_blank"
              rel="noopener"
            >
              More details?
            </Link>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
