/*jshint esversion:9*/
import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import CreateYourProfile from "./Pages/Users/CreateYourProfile/CreateYourProfile-page";
import User from "./Pages/Users/User/User-page";
import Home from "./Pages/Users/Home/Home-page";
import Login from "./Pages/Users/Login/Login-page";
import OwnerPanel from "./Pages/OwnerPanel/owner/owner-page";
import ErrorBoundary from "./Components/error-boundary/error-boundary-component";
import Monsterrat from "./fonts/Montserrat-Regular.ttf";
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from "@material-ui/core";
import Authentication from "./Pages/authenticate-page";
import "./app.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectBackgroundColor,
  selectCompanyInformation,
} from "./redux/user/user-selector";
import SingleSignOn from "./Pages/single-sign-on-page";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import Logout from "./logout";
import SamlSingleSignOn from "./Pages/Users/Login/Saml-sso-page";
import ImplicitCallback from "./Pages/implicitCallback";
import NotFound from "./Components/not-found/NotFound";

function App({ history, backgroundColor, companyInfo }) {
  useEffect(() => {
    console.log("issuer: ", process.env.REACT_APP_ISSUER);
    window.addEventListener("keydown", function (e) {
      if (e.key === " " && e.target === document.body) {
        e.preventDefault();
      }
    });
  }, []);

  const monsterrat = {
    fontFamily: "Monsterrat",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: "lighter",
    src: `
      local('Monsterrat'),
      local('Monsterrat-Regular'),
      local('Monsterrat-Bold'),
      local('Monsterrat-ExtraBold'),
      url(${Monsterrat}) format('ttf')
    `,
    unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  };

  const theme = createMuiTheme({
    Paper: {
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)!important",
    },
    typography: {
      fontFamily: "Montserrat",
      fontWeight: "300",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [monsterrat],
          "@font-size": "16px",
          ".MuiTypography-subtitle1": {
            fontSize: "1.4rem!important",
            textOverflow: "ellipsis",
          },
          h1: {
            fontSize: "2em",
          },
          h2: {
            fontSize: "1.5em",
          },
          h3: {
            fontSize: "1.17em",
          },
          h4: {
            fontSize: "1em",
          },
          h5: {
            fontSize: "0.83em",
          },
          h6: {
            fontSize: "0.75em",
          },
          p: {
            fontSize: "1.05rem",
            textOverflow: "ellipsis",
            fontWeight: "400",
          },
          "p span": {
            fontSize: "1.05rem!important",
            textOverflow: "ellipsis",
            color: "black",
          },
          ".MuiTypography-h5": {
            fontSize: "1.7rem!important",
            textOverflow: "ellipsis",
          },
          ".MuiTypography-h6": {
            fontSize: "1.5rem!important",
            textOverflow: "ellipsis",
          },
          ".MuiTypography-body2": {
            fontSize: "0.5rem!important",
            textOverflow: "ellipsis",
          },
          ".MuiTypography-body1": {
            fontSize: "1.05rem!important",
            textOverflow: "ellipsis",
            fontWeight: "400!important",
          },
          ".MuiChip-label": {
            fontSize: "0.6rem!important",
          },
          ".MuiTypography-caption": {
            fontSize: "0.7rem!important",
            fontWeight: "400!important",
          },
          ".MuiPaper-root": {
            borderRadius: "10px",
            //border: companyInfo && companyInfo.companyBackgroundColor && `1px solid #B4B4B4`,
            //100,100,111
            boxShadow:
              companyInfo && companyInfo.companyBackgroundColor
                ? "rgba(153, 218, 254, 0.4) 0px 7px 29px 0px"
                : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            //boxShadow: 'none'
          },
          ".MuiCard-root": {
            //border: companyInfo && companyInfo.companyBackgroundColor && `1px solid #B4B4B4!important`,
            boxShadow:
              companyInfo && companyInfo.companyBackgroundColor
                ? "rgba(153, 218, 254, 0.4) 0px 7px 29px 0px"
                : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            //boxShadow: 'none'
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#FFCC33",
      },
    },
  });

  function onAuthRequired() {
    history.push("/login");
  }
  if (process.env.NODE_ENV === "production") {
    console.log =
      console.error =
      console.info =
      console.debug =
      console.warn =
      console.trace =
      console.dir =
      console.dirxml =
      console.group =
      console.groupEnd =
      console.time =
      console.timeEnd =
      console.assert =
      console.profile =
        function () {};
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <ErrorBoundary>
          <Security
            issuer={process.env.REACT_APP_ISSUER}
            clientId={process.env.REACT_APP_CLIENT_ID}
            redirectUri={window.location.origin + "/callback"}
            pkce={true}
          >
            <Route
              path={"/singlesignon/:userPublicId"}
              exact
              component={SingleSignOn}
            />
            <Route
              path="/login"
              exact={true}
              render={() => (
                <Login issuer="https://dev-953032.okta.com/oauth2/default" />
              )}
            />
            <Route path="/callback" exact={true} component={LoginCallback} />
            <Route path="/authenticate" exact component={Authentication} />
            <Route path="/" exact component={Home} />
            <Route path="/OwnerPanel" sensitive component={OwnerPanel} />
            <Route
              path="/CreateYourProfile"
              exact
              component={CreateYourProfile}
            />
            <Route path="/User" sensitive component={User} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/sso/saml/:token" exact component={SamlSingleSignOn} />
            <Route
              path="/implicit/callback"
              exact
              component={ImplicitCallback}
            />
            {/* <Route component={NotFound}/> */}
          </Security>
        </ErrorBoundary>
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  backgroundColor: selectBackgroundColor,
  companyInfo: selectCompanyInformation,
});

export default withRouter(connect(mapStateToProps)(App));
// <SecureRoute path="/Main"  component={Main}/>
