/*jshint esversion:9*/

import React,{useState} from 'react';
import {TextField,IconButton,Button} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import Input from '../SubComponents/Input/Input';
import './add-badges-style.scss';

export default function AddBadges({handleAdd}){
  const [info,setInfo] = useState({
    badgeName:'',
    image:null
  });


  const handleChange = e => {
    try {
      const{name,value} = e.target;
    if(e.target.files){
      const file = e.target.files[0];
      setInfo(prevValue =>{
        return{
          ...prevValue,
          [name]:file
        };
      });
      return;
    }
    setInfo(prevValue =>{
      return{
        ...prevValue,
        [name]:value
      };
    }); 
    } catch (error) {
      console.log(error.message);
    }
  };


  return(
    <div className='add-badge-form'>
      <div className='add-badge-form-container'>
        <Input label='Badge Name' type='text' name='badgeName' onChange={handleChange} value={info.badgeName}/>
        <br/>
        <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
          <div style={{width:'100%'}}>
            <TextField
            className='input'
            id="input-with-icon-textfield"
            label='Badge Image'
            type='text'
            value={info.image&&info.image.name}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}

            />
              <input  type='file' id='logo-upload' style={{display:'none'}} name='image' onChange={handleChange}/>
            </div>
            <div >
              <label htmlFor='logo-upload'>
                <IconButton color="primary" aria-label="upload picture" component="span" button >
                  <PublishIcon/>
                </IconButton>
              </label>
            </div>
        </div>
        <br/>
        <Button variant='outlined' color='primary' onClick={()=>{
          setInfo({
            badgeName:'',
            image:null
          });
          handleAdd(info);
        }}>Add</Button>
      </div>
    </div>
  )
}
