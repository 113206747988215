/*jshint esversion:9*/

import {companyActionTypes} from './company-types';
import {updateCompany} from './company-utils';

const INITIAL_STATE={
  companyList:[]
};

const companyReducer = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case companyActionTypes.SET_COMPANY_LIST:
      return{
        ...state,
        companyList:action.payload
      };

    case companyActionTypes.DELETE_COMPANY:
      console.log(action.payload);
    return{
      ...state,
      companyList:state.companyList.filter(item=>item.companyPublicId !== action.payload)
    } ;

    case companyActionTypes.UPDATE_COMPANY:
    return{
      ...state,
      companyList:updateCompany(state.companyList,action.payload)
    };

    case companyActionTypes.ADD_COMPANY:
    return{
      ...state,
      companyList:[action.payload,...state.companyList]
    }

    default:
      return state;
  }
};

export default companyReducer;
