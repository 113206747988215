import React from 'react';
import style from './button.module.scss';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button'
const styles = {
    root:{
        "text-transform": "capitalize"
    }
}
const CustomButton = React.forwardRef( (props, ref ) => 
    (
        <Button {...props} ref={ref} className={`${style.btn_wrapper} ${props.className}`}>{props.children}</Button>
    )
)

export default withStyles(styles)(CustomButton);