/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import { makeStyles,Typography,Popper,Paper,IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectBadges} from '../../../redux/badges/badges-selector';
import './badgesDetails-style.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  popover: {
   pointerEvents: 'none',
   backgroundColor:'primary',
   zIndex:'9999'
 },
 Paper: {
   padding: theme.spacing(1),
   zIndex:'9999'
 },
}));

 function BadgesDetailsModal({open,handleClose,badges,allBadges}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [donor,setDonor] = useState('');
  const [badgeList,setBadgeList] = useState([]);

  useEffect(()=> {
    try {
      if(allBadges && badges && badges.length > 0) {
        let list = [];
        badges.forEach(badge => {
          let badgeFound = allBadges.find(item => item.publicId === badge.badgePublicId);
          if(badgeFound) {
            list.push({
              ...badgeFound,
              ...badge
            });
          }
        });
        console.log(list);
        setBadgeList(list);
      } else {
        setBadgeList([]);
      }
    } catch (error) {
      console.log(error);
    }  
  },[badges,allBadges])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setDonor(event.currentTarget.name);
    console.log(event.currentTarget.name);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
 const popUp = Boolean(anchorEl);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <Typography variant='h4' style={{fontWeight:'bold',textAlign:'center',width:'100%'}}>Badges</Typography>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon/>
              </IconButton>
            </div>
            <br/>
            <br/>
            <div className='badges-container'>
            {badgeList.map(
              (item,index)=>
              <div key={index}>
              <div className='badge-container'
              >
                <img alt='badge' src={item.badgeImageUrl} width='300' heigth='500'
                aria-describedby={item.userBadgePublicId}
                onMouseOver={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                name={item.name}
                />
                <Typography variant='h6'>{item.badgeDisplayName}</Typography>
              </div>
              <Popper
      id={item.userBadgePublicId}
      className={classes.popover}
      open={popUp}
      anchorEl={anchorEl}
    >
    <Paper className={classes.paper}>
      <Typography>{`Gifted By ${donor}`}</Typography>
    </Paper>
    </Popper>
              </div>
            )}
            {badges&&badges.length===0&&<Typography variant='subtitle1'>No badge earned yet !</Typography>}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  allBadges:selectBadges
});

export default connect(mapStateToProps)(BadgesDetailsModal)