/*jshint esversion:9*/

export const userActionTypes = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_COMPANY_INFORMATION: "SET_COMPANY_INFORMATION",
  SET_USER_POINTS: "SET_USER_POINTS",
  SET_LOCATION_DATA: "SET_LOCATION_DATA",
  SET_BACKGROUND_COLOR: "SET_BACKGROUND_COLOR",
  SET_SETTINGS: "SET_SETTINGS",
  SET_COMPANY_DEPARTMENTS: "SET_COMPANY_DEPARTMENTS",
};
