import React, {useEffect,useState} from 'react';
import {makeStyles,Modal,Button,Fade,Backdrop,IconButton,Divider,FormControl,InputLabel,Select,MenuItem,CircularProgress} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SuccessLogo from '../../../Assets/success.png';
import axios from 'axios';
import './add-collab-skill-modal-style.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width:'600px',  
      outline:'none',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    },
    formControl: {
        width: '80%'
    }
  }));

  export default function AddCollabSkillModal({open,handleClose,details,handleAddSuccess}) {

    const classes = useStyles();

    const [availableSkills , setAvailableSkills] = useState([]);
    const [selectedSkill,setSelectedSkill] = useState(null);
    const [loading,setLoading] = useState(false);
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState(false);

    const token = localStorage.getItem('bhyve-token');

    useEffect(() => {
        console.log(details);
        try {
            async function getAvailableSkills() {
                const fetchUserInfoResult = await axios.get(`${process.env.REACT_APP_API_URL}user/view/${details.userDetails.id}`,{
                    headers:{
                    'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
                    'Authorization': `Bearer ${token}`
                    }
                  });
                  console.log(fetchUserInfoResult.data);
                  let userSkills = fetchUserInfoResult.data.userSkillsSet.userSkills;
                  let availSkills = [];
                  userSkills.forEach(skill => {
                      let exist = details.collabDetails.collaborationSkill.find(item => item.mentorSkillPublicId === skill.skillPublicId);
                      if(!exist) {
                        if(skill.totalEndorsementCount) {
                            delete skill.totalEndorsementCount

                            if(skill.totalEndorsementAverage) {
                                delete skill.totalEndorsementAverage
                            }
                        }
                        skill = {
                            ...skill,
                            menteeId: details.collabDetails.participants.filter(item => item !== details.userDetails.id)[0],
                            mentorId: details.userDetails.id
                        }
                        availSkills.push(skill);
                      } 
                  });
                  console.log(availSkills);
                  setAvailableSkills(availSkills);
            }
            if(details && Object.keys(details.userDetails).length !== 0) {
                getAvailableSkills();
            }
        } catch (error) {
            console.log(error)
        }
    },[details]);


    const handleSubmit = async () => {
        try {
            if(selectedSkill) {
                setLoading(true);
                let finalSkill = availableSkills.find(item => item.skillPublicId === selectedSkill);
                finalSkill = {
                    ...finalSkill,
                    score: finalSkill.score === 0.333 ? 1 : finalSkill.score === 0.667 ? 2 : 3
                }
                let addCollabSkillResult = await axios.patch(`${process.env.REACT_APP_API_URL}collaboration/addSkill/${details.collabDetails.collaborationPublicId}`,finalSkill,{
                    headers:{
                    'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
                    'Authorization': `Bearer ${token}`
                    }
                });
        
                if(addCollabSkillResult) {
                    console.log(addCollabSkillResult);
                    setLoading(false);
                    setSuccess(true);
                    handleAddSuccess(addCollabSkillResult.data)
                }
            }else{
                setError(true);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }   
    }

      return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
            <div className={classes.paper}>
                <div className='add-collab-skill-title'>
                    {!loading && !success && <h3 className='add-collab-skill-title-text'>Add a skill you wish to learn from <span style={{color:'#EE813A', fontWeight:'bold'}}>{details && details.userDetails.name}</span></h3>}
                    {!loading && <IconButton onClick={() => {
                        setTimeout(() => {
                            setLoading(false);
                            setSuccess(false);
                            setSelectedSkill(null);
                            setAvailableSkills([]);
                        },1000)
                        handleClose();
                        }}>
                        <CloseIcon/>
                    </IconButton>}
                </div>
                {loading && 
                <div className='add-collab-skill-loading-container'>
                    <CircularProgress color='primary'/>
                </div>
                }
                {!loading && !success && <Divider/>}
                {!loading && !success && <div className='add-collab-skill-main-container'>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label" >Select a skill</InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedSkill}
                        onChange={(e) => setSelectedSkill(e.target.value)}
                        label="Select a skill"
                        >
                        {availableSkills.map(item => 
                            <MenuItem key={item.skillPublicId} value={item.skillPublicId}>{item.skillDisplayName}</MenuItem>
                            )}        
                        </Select>
                    </FormControl>
                    <div className='add-collab-skill-btn-container'>
                        <Button variant='contained' className='add-collab-skill-btn' onClick={handleSubmit}>Add</Button>
                        {error && <p style={{color:'red'}}>You must select a skill inorder to proceed!</p>}
                    </div>
                </div>}
                {!loading && success && <div className='add-collab-skill-success-container'>
                <div className='add-collab-skill-success-logo'>
                    <img src={SuccessLogo} width='80' height='80'/>
                </div>
                <h2>Skill addded successfully !</h2>
            </div>}
            </div>
        </Fade>
      </Modal>

      )
  }