import { FETCH_ARTICLE } from './actions/fetch_articles';
import { FETCH_SINGLE_ARTICLE } from './actions/fetch_single_article';
import { CLEAR_CURRENT_ARTICLE } from './actions/clear_current_article';
import { SET_CURRENT_ARTICLE } from "./actions/set_current_article";
import { FETCH_RECOMMENDED_ARTICLES } from "./actions/fetch_recommendedArticle";
import { FETCH_TRENDING_ARTICLES } from './actions/fetch_trendingArticle';
import { FETCH_BOOKMARKED_ARTICLES } from './actions/fetch_bookmarkArticle';
import { FETCH_PUBLISHED_ARTICLES } from './actions/fetch_published_article';
import { FETCH_DRAFT_ARTICLES } from './actions/fetch_draft_article';
import { DISLIKE_POST, FETCH_PUBLISHED_ARTICLE_BY_AUTHOR, LIKE_POST, SAVE_COMMENT } from '../types';

const initialState = {
  articles: [],
  articleIsLoaded: false,
  recommendedArticles: [],
  isRecommendedLoaded: false,
  trendingArticles: [],
  isTrendingLoaded: false,
  bookmarkedArticles: [],
  isBookmarkedLoaded: false,
  publishedArticles: [],
  isPublishedLoaded: false,
  draftArticles: [],
  isDraftLoaded: false,
  currentArticle: {},
  isCurrentArticleLoaded: true,
  currentComment: '',
  comments: [],
  likes: 0,
  dislikes: 0,
  isError: false,
  articlesByAuthor:[]
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLE:
      return { ...state, articles: action.payload, articleIsLoaded: true };
    case FETCH_SINGLE_ARTICLE:
      return { ...state, isCurrentArticleLoaded: false };
    case SET_CURRENT_ARTICLE:
      return { ...state, currentArticle: action.payload, isCurrentArticleLoaded: true }
    case CLEAR_CURRENT_ARTICLE:
      return { ...state, currentArticle: {} };
    case SAVE_COMMENT:
      return { ...state, currentArticle: { ...state.currentArticle, comments: [...state.currentArticle.comments, action.payload], commentsCount: state.currentArticle.commentsCount + 1 } };
    case LIKE_POST:
      return { ...state, currentArticle: { ...state.currentArticle, likes: [...state.currentArticle.likes, { user: { publicId: action.payload } }], likesCount: state.currentArticle.likesCount + 1 } };
    case FETCH_RECOMMENDED_ARTICLES:
      return { ...state, recommendedArticles: action.payload, isRecommendedLoaded: true, isTrendingLoaded: false, isBookmarkedLoaded: false };
    case FETCH_TRENDING_ARTICLES:
      return { ...state, trendingArticles: action.payload, isTrendingLoaded: true, isRecommendedLoaded: false, isBookmarkedLoaded: false };
    case FETCH_BOOKMARKED_ARTICLES:
      return { ...state, bookmarkedArticles: action.payload, isBookmarkedLoaded: true, isRecommendedLoaded: false, isTrendingLoaded: false };
    case FETCH_PUBLISHED_ARTICLES:
      return { ...state, publishedArticles: action.payload, isPublishedLoaded: true, isDraftLoaded: false };
    case FETCH_DRAFT_ARTICLES:
      return { ...state, draftArticles: action.payload, isDraftLoaded: true, isPublishedLoaded: false };
    case DISLIKE_POST:
      return { ...state, currentArticle: { ...state.currentArticle, likes: state.currentArticle.likes.filter(item => item.user.publicId !== action.payload), likesCount: state.currentArticle.likesCount - 1 } };
    case FETCH_PUBLISHED_ARTICLE_BY_AUTHOR:
      return {...state, articlesByAuthor:[...action.payload]}
    default:
      return { ...state };
  }
};
export default articleReducer;
