/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import Input from '../Input/Input';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function EditDefaultActionPointModal({open,actionPoint,handleEdit,handleClose}) {
  const [details,setDetails] = useState({
    action:'',
    points:null
  });
  const classes = useStyles();

  useEffect(()=>{
    setDetails({
      ...actionPoint
    });
  },[actionPoint]);

  const handleChange = e => {
    const {value,name} = e.target;
    setDetails(prevValue=>{
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction='up' in={open}>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold'}}>Edit {actionPoint&&actionPoint.action}</Typography>
            <br/>
            <br/>
            <Input type='text' label='Action' value={details.action} name='action' onChange={handleChange}/>
            <br/>
            <Input type='number' label='Points' value={details.points} name='points' onChange={handleChange}/>
            <br/>
            <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
              <Button variant='outlined' style={{color:'#66CCFF',borderColor:'#66CCFF'}} onClick={()=>handleEdit(details)}>
                Update
              </Button>
              <Button variant='outlined' style={{color:'red',borderColor:'red'}} onClick={()=>handleClose()}>
                Discard
              </Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
