import React from 'react';
import style from './loading.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
    return (
        <div className={`${style.wrapper}`}>
            <CircularProgress />
        </div>

    )
}

export default Loading;