import React, { useState, useEffect, useRef, useCallback } from "react";
import ShoutoutActivity from "../shoutout-activity/shoutout-activity-component";
import BuzzBoxInfiniteScroll from "../SubComponents/buzzBoxInfiniteScroll/buzzBoxInfiniteScroll";
import BuzzBoxInfiniteScrollUpdates from "../SubComponents/buzzBoxInfiniteScroll/buzzBoxInfiniteScroll-updates";
import Snackbar from "../SubComponents/snackbar/snackbar";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/user-selector";
import axios from "axios";
import "./buzz-box-style.scss";

function BuzzBox({
  userData,
  type,
  handleDrawerClose,
  feed,
  handleEditOnFeed,
  tabValue,
}) {
  const [userSkills, setUserSkills] = useState([]);
  const [skillBuzzes, setSkillBuzzes] = useState([]);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [Buzzes, setBuzzes] = useState([]);
  const [activitySkip, setActivitySkip] = useState(0);
  const [updatesSkip, setUpdatesSkip] = useState(0);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    try {
      let skills;
      skills = userData.userInformation.userSkillsSet.userSkills.map(
        (s) => s.skillName
      );
      setUserSkills(skills);
    } catch (error) {
      console.log(error.message);
    }
  }, [userData]);

  useEffect(() => {
    if (tabValue === 0) {
      setActivitySkip(0);
    } else {
      setUpdatesSkip(0);
    }
  }, [tabValue]);

  const observer = useRef();
  const { loading, error, buzzes, hasMore } = BuzzBoxInfiniteScroll(
    activitySkip,
    0
  );
  const { updating, err, updates, more } = BuzzBoxInfiniteScrollUpdates(
    updatesSkip,
    userSkills,
    1
  );

  useEffect(() => {
    try {
        setBuzzes(buzzes);
        setSkillBuzzes(updates);
    } catch (error) {
      console.log(error);
    }
  }, [buzzes, updates]);

  const lastBuzzElement = useCallback(
    (node) => {
      try {
        if (type === "activity") {
          if (loading) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((e) => {
            if (e[0].isIntersecting && hasMore) {
              setActivitySkip((prevValue) => prevValue + 5);
            }
          });
          if (node) observer.current.observe(node);
        } else {
          if (updating) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((e) => {
            if (e[0].isIntersecting && more) {
              setUpdatesSkip((prevValue) => prevValue + 5);
            }
          });
          if (node) observer.current.observe(node);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [loading, hasMore, type, more, updating]
  );

  const [displayLoader, setDisplayLoader] = useState(false);

  const handleSendBuzz = async (buzz, taggedUsers) => {
    try {
      setDisplayLoader(true);
      setMessage("Posting...");
      setSeverity("info");
      setAlertOpen(true);
      console.log("buzz:", buzz);
      console.log("taggedUsers:", taggedUsers);
      let current = buzz.text.split(" ");
      let skillTags = [];
      current.map((item) =>
        item.split("").find((letter) => letter === "#")
          ? skillTags.push(item.slice(1).split("_").join(" "))
          : item
      );
      let userTags = [];
      current.map((item) =>
        item.split("").find((letter) => letter === "@")
          ? userTags.push(
              taggedUsers.find(
                (user) =>
                  item.slice(1).split("_")[0] === user.firstName &&
                  item.slice(1).split("_")[1] === user.lastName
              )["publicId"]
            )
          : item
      );
      console.log(userTags);
      let postBuzzResult = await postBuzz(buzz, skillTags, userTags);
      if (postBuzzResult) {
        console.log(postBuzzResult);
        let current = Buzzes;
        let currentSkillBuzzes = skillBuzzes;
        if (type === "activity") {
          if (buzz.id) {
            current = current.map((item) =>
              item.shoutoutPublicId === buzz.id ? postBuzzResult.data : item
            );
          } else {
            current = [postBuzzResult.data].concat(current);
          }
          console.log(current);
          setBuzzes(current);
        } else {
          let existingSkills = [];
          skillTags.map((tag) => {
            let exist = userSkills.find((item) => item === tag);
            if (exist) {
              existingSkills.push(tag);
            }
            return null;
          });
          if (existingSkills.lenght > 0) {
            if (buzz.id) {
              currentSkillBuzzes = currentSkillBuzzes.map((item) =>
                item.shoutoutPublicId === buzz.id ? postBuzzResult.data : item
              );
            } else {
              currentSkillBuzzes = [postBuzzResult.data].concat(
                currentSkillBuzzes
              );
            }
            setSkillBuzzes(currentSkillBuzzes);
          }
        }
        setAlertOpen(false);
        setMessage("Buzz posted successfully!");
        setSeverity("success");
        setAlertOpen(true);
        setDisplayLoader(false);
      }
    } catch (err) {
      console.log(err);
      setMessage("Buzz could not be posted!");
      setSeverity("error");
      setAlertOpen(true);
      setDisplayLoader(false);
    }
  };

  const postBuzz = async (buzz, skills, users) => {
    const req = {
      text: buzz.text,
      skillsTagged: skills,
      taggedUsers: users,
    };
    console.log(req);
    let result;
    if (buzz.id) {
      result = await axios.patch(
        `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${buzz.id}`,
        req,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
    } else {
      result = await axios.post(
        `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts`,
        req,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
    }
    return result;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Snackbar
        Open={alertOpen}
        severity={severity}
        alert={message}
        handleClose={() => setAlertOpen(false)}
      />
          <ShoutoutActivity
          data={type === "activity" ? Buzzes : skillBuzzes}
          loading={type === "activity" ? loading : updating}
          error={type === "activity" ? error : err}
          currentUserId={userData && userData.userInformation.publicId}
          lastBuzzElement={lastBuzzElement}
          sendBuzz={handleSendBuzz}
          handleDrawerClose={() => handleDrawerClose && handleDrawerClose()}
          feed={feed && feed}
          handleEditOnFeed={(buzz) => handleEditOnFeed(buzz)}
          displayLoader={displayLoader}
        />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps)(BuzzBox);
