/*jshint esversion:9*/

import React from 'react';
import './info-style.scss';
import {Typography} from '@material-ui/core'


function Info({icon,label,data}){
  return(
    <div className='info-container'>
      <Typography variant='body1' className='label'><span style={{marginRight:"8px"}}>{icon}</span>{label}:</Typography>
      <Typography variant='body1' className='muted info' style={{color:'#707070'}}>{data}</Typography>
    </div>
  )
}

export default Info;
