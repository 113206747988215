/*jshint esversion:9*/

import React,{useState} from 'react';
import {Paper,Typography,Button} from '@material-ui/core';
import Points from '../../Assets/points.png';
import RedeemPointsModal from '../SubComponents/redeemPoints-modal/redeemPoints-modal';
import PointsTableModal from '../SubComponents/points-table-modal/points-table-modal';
import axios from 'axios';
import Logout from '../../logout';
import {withRouter} from 'react-router-dom';
import './userPoints-style.scss';

function UserPoints({points,user, history}){
  const[hover,setHover] = useState(false);
  const [redeemPoints,setRedeemPoints] = useState(false);
  const [pointsTable,setPointsTable] = useState(false);
  const [pointsTableData,setPointsTableData] = useState(null);
  const [loading,setLoading] = useState(false);


  const handlePointsTable = async () => {
    try {
      setLoading(true);
      let getPointsTableResult = await getPointsTable();
      if(getPointsTableResult) {
        console.log(getPointsTableResult.data);
        setPointsTableData(getPointsTableResult.data);
        setLoading(false);
      }
      setPointsTable(true); 
    } catch (err) {
      setLoading(false);
      if(err.response) {
        console.log(err.response.message);
        if(err.response.status === 401){
          history.push('/logout');
        }
      }
    }
  };

  const getPointsTable = async()=>{
        const token = localStorage.getItem('bhyve-token');
    const result = await axios.get(`${process.env.REACT_APP_API_URL_POINTS}points/company/pointsTable`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  };

  return(
    <div className='points-container'>
      <div style={{width:'100%',textAlign:'center',cursor:'pointer'}} onClick={handlePointsTable}>
        <img alt='coins' src={Points} className='points-img'/>
      </div>
        <br/>
      <Typography variant='h5' className='profile-content' style={{fontWeight:'bold',cursor:'pointer'}} onClick={handlePointsTable}>{points&&points}</Typography>
      <Typography variant='subtitle2' className='profile-content muted'>Points</Typography>
      {user===null&&<Button variant='contained' style={{padding:'10px 30px 10px 30px'}} className='edit-btn' onClick={()=>setRedeemPoints(true)}>Redeem</Button>}
      <RedeemPointsModal open={redeemPoints} handleClose={()=>setRedeemPoints(false)}/>
      <PointsTableModal open={pointsTable} handleClose={()=>setPointsTable(false)} data={pointsTableData} loading={loading}/>
    </div>
  )
}

export default withRouter(UserPoints);
