import React,{useEffect, useState} from 'react';
import {Paper, Avatar} from '@material-ui/core';
import UserIntitials from '../userInitials/userInitials';
import cmsAxios from '../../axios/authAxios';
import style from './autoCompleteTaggingStyle.module.scss';


const AutoCompleteTagging = ({userCounter, skillCounter, onSelect}) => {

    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        const searchFunction = async request => {
            let result = await cmsAxios.get(`user/username/autocomplete?name=${request}&limit=3`);
            setTagList(result.data);
        }

        if(userCounter.length > 2) {
            let requestString = userCounter.join('');
            searchFunction(requestString);
        }else{
            if(userCounter.length === 0) {
                setTagList([]);
            }
        }
    },[userCounter]);

    useEffect(() => {
        const searchFunction = async request => {
            let result = await cmsAxios.get(`user/autocomplete/skills?skillName=${request}`);
            setTagList(result.data);
        }

        if(skillCounter.length > 2) {
            let requestString = skillCounter.join('');
            searchFunction(requestString);
        }else{
            if(skillCounter.length === 0) {
                setTagList([]);
            }
        }
    },[skillCounter]);

    useEffect(() => {
        console.log(tagList);
    },[tagList]);

    return (
        (userCounter.length > 2 || skillCounter.length > 2) &&
        tagList.length > 0
        ?
        <Paper className={style.autoCompleteMainContainer}>
            <ol className={style.autoCompleteList}>
                {tagList.map(item => 
                    <li className={style.autoCompleteListItem} onClick={() => onSelect(item)}>
                        {item.profilePhotoUrl && item.profilePhotoUrl !== "" && <Avatar 
                            className={style.profilePicture}
                            src={item.profilePhotoUrl}
                        />}
                        {item.profilePhotoUrl === "" &&
                            <UserIntitials firstName={item.firstName} lastName={item.lastName} />
                        }
                        <small>{item.firstName && item.firstName} {item.lastName && item.lastName} {item.skillDisplayName && `#${item.skillDisplayName}`}</small>
                    </li>
                )}
            </ol>
        </Paper> :
        <></>
    )
}

export default AutoCompleteTagging;