/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ShoutoutActivity from "../shoutout-activity/shoutout-activity-component";
import SearchResultCard from "../SubComponents/search-result-card/search-result-card";
import CreateCollabModal from "../SubComponents/create-collab-modal/create-collab-modal";
import PendingCollabModal from "../SubComponents/pending-collab-modal/pending-collab-modal";
import Articles from "../../Pages/Users/cms/util/articleListItem";
import getSynopsis from "../../Pages/Users/cms/util/getSynopsis";
import cmsAxios from "../../Pages/Users/cms/axios/authAxios";
import search from "../../Assets/search.png";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectCompanyDepartments,
} from "../../redux/user/user-selector";
import Logout from "../../logout";
import "./search-grid-style.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const useStyles = makeStyles((theme) => ({
    box: {
      padding: "10px",
    },
  }));

  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.box}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

function SearchGrid({
  results,
  history,
  userData,
  query,
  isPublished,
  publishedArticles,
  companyDepartments,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [collab, setCollab] = useState(false);
  const [collabUserId, setCollabUserId] = useState(null);
  const [pendingRequestDetails, setPendingRequestDetails] = useState({
    user: {},
    role: 0,
    details: {},
  });
  const [pendingModal, setPendingModal] = useState(false);
  const [people, setPeople] = useState([]);
  const [skills, setSkills] = useState([]);
  const [buzzes, setBuzzes] = useState([]);
  const [cms, setCms] = useState([]);
  const [matchType, setMatchType] = useState("USER");

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    setPeople(results.users);
    setMatchType(results.searchMatchType);
    setSkills(results.skills);
    setBuzzes(results.buzz);
    if (results.cms) {
      setCms([...results.cms]);
    } else {
      setCms([]);
    }
  }, [results]);

  const handleBookmark = (e, id, setBookmark, current) => {
    let articles = results.cms;
    let article = articles.find((article) => article.contentPublicId === id);
    if (!article) return;
    if (current) {
      cmsAxios
        .delete(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleArticleItemClick = (e, id) => {
    history.push(`/User/cms/${id}/singleArticle`);
  };
  const handleCardClick = (id) => {
    history.push(`/User/Profile/${id}`);
  };

  useEffect(() => {
    console.log(query);
  }, [query]);

  const handleCollaborate = async (name, id) => {
    try {
      const fetchCollabInfoResult = await axios.post(
        `${process.env.REACT_APP_API_URL}collaboration/participants`,
        { participants: [id, userData.userInformation.publicId] },
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        fetchCollabInfoResult.data === "" ||
        fetchCollabInfoResult.data.length === 0
      ) {
        setCollab(true);
        setCollabUserId(id);
      } else {
        console.log(fetchCollabInfoResult.data[0]);
        switch (fetchCollabInfoResult.data[0].status) {
          case 1:
            let user = fetchCollabInfoResult.data[0].participants.filter(
              (item) => item !== userData.userInformation.publicId
            );
            history.push(`/User/Chat/${user[0]}`);
            break;

          case 0:
            if (
              fetchCollabInfoResult.data[0].createdBy ===
              userData.userInformation.publicId
            ) {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 0,
              });
              setPendingModal(true);
            } else {
              setPendingRequestDetails({
                user: {
                  name: name,
                },
                details: fetchCollabInfoResult.data[0],
                role: 1,
              });
              setPendingModal(true);
            }
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const updateCollabRequest = async (req) => {
    try {
      let updateCollabResult = await axios.patch(
        `${process.env.REACT_APP_API_URL}collaboration`,
        req,
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return updateCollabResult;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const handelAccept = async (data) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 1,
        rejectReason: "",
      };
      let acceptRequestResult = await updateCollabRequest(reqObject);
      if (acceptRequestResult) {
        console.log(acceptRequestResult);
        let user = data.participants.filter(
          (item) => item !== userData.userInformation.publicId
        );
        history.push(`Chat/${user}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDecline = async (data, reason) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 2,
        rejectReason: reason,
      };
      let declineRequestResult = await updateCollabRequest(reqObject);
      if (declineRequestResult) {
        console.log(declineRequestResult);
        setPendingModal(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <AppBar
        position="sticky"
        className={`search-appBar ${classes.appBar}`}
        style={{
          background: `${document.body.style.backgroundColor}!important`,
          height: "100%",
          width: "100%",
          zIndex: "1",
        }}
      >
        <Tabs
          className="search-skillTab"
          style={{ textTransform: "none" }}
          value={value}
          onChange={handleChange}
          textColor="primary"
        >
          <Tab label="People" {...a11yProps(0)} />
          <Tab label="Skills" {...a11yProps(1)} />
          <Tab label="Buzzbox" {...a11yProps(1)} />
          <Tab label="Knowledge" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="search-user-grid-main-container">
            {query && people && people.length > 0 && (
              <div className="search-user-grid-container">
                <div className="search-user-grid-sub-container">
                  {people.map((result) => (
                    <SearchResultCard
                      handleCardClick={() => handleCardClick(result.publicId)}
                      name={`${result.firstName} ${result.lastName}`}
                      active={result.isUserOnline}
                      lastActive={result.userLastActive}
                      dp={result.profilePhotoUrl}
                      title={result.department}
                      handleCollaborate={() =>
                        handleCollaborate(result.firstName, result.publicId)
                      }
                      owner={
                        result.publicId === userData.userInformation.publicId
                      }
                      level={
                        matchType === "SKILL" &&
                        result &&
                        query &&
                        result.userSkillsSet.userSkills.find(
                          (item) => item.skillName === query
                        ) &&
                        result.userSkillsSet.userSkills.find(
                          (item) => item.skillName === query
                        ).score
                      }
                      companyDepartments={companyDepartments}
                    />
                  ))}
                </div>
              </div>
            )}
            {query && people && people.length === 0 && (
              <div
                className="search-error-div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <img
                  src={search}
                  alt="404"
                  style={{ height: "380px", width: "380px" }}
                />
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Sorry, we found no results that match your search!
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "light" }}>
                  Here's some tips that can help you find what you are looking
                  for :
                </Typography>
                <ol style={{ fontSize: "15px", width: "45%" }}>
                  <li>Check your spellings and try again</li>
                  <li>Use more generic terms</li>
                  <li>
                    Contact us at{" "}
                    <a
                      href="https://www.help@bhyve.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help@bhyve.io
                    </a>
                  </li>
                </ol>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="search-skill-grid-main-container search-user-grid-sub-container">
            {query && skills && skills.length > 0 && (
              <div className="search-skill-grid-container">
                {skills.map((result) => (
                  <div
                    className="search-skill-grid-entry"
                    onClick={() =>
                      history.push(`/User/Search?q=${result.skillName}`)
                    }
                  >
                    <h4>{result.skillDisplayName}</h4>
                    <Divider />
                  </div>
                ))}
              </div>
            )}
            {query && skills && skills.length === 0 && (
              <div
                className="search-error-div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <img
                  src={search}
                  alt="404"
                  style={{ height: "380px", width: "380px" }}
                />
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Sorry, we found no results that match your search!
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "light" }}>
                  Here's some tips that can help you find what you are looking
                  for :
                </Typography>
                <ol style={{ fontSize: "15px", width: "45%" }}>
                  <li>Check your spellings and try again</li>
                  <li>Use more generic terms</li>
                  <li>
                    Contact us at{" "}
                    <a
                      href="https://www.help@bhyve.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help@bhyve.io
                    </a>
                  </li>
                </ol>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="search-buzz-grid-main-container search-user-grid-sub-container">
            {query && buzzes && buzzes.length > 0 && (
              <div className="search-buzz-grid-container">
                <ShoutoutActivity data={buzzes} search />
              </div>
            )}
            {query && buzzes && buzzes.length === 0 && (
              <div
                className="search-error-div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <img
                  src={search}
                  alt="404"
                  style={{ height: "380px", width: "380px" }}
                />
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Sorry, we found no results that match your search!
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "light" }}>
                  Here's some tips that can help you find what you are looking
                  for :
                </Typography>
                <ol style={{ fontSize: "15px", width: "45%" }}>
                  <li>Check your spellings and try again</li>
                  <li>Use more generic terms</li>
                  <li>
                    Contact us at{" "}
                    <a
                      href="https://www.help@bhyve.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help@bhyve.io
                    </a>
                  </li>
                </ol>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="search-buzz-grid-main-container search-user-grid-sub-container">
            <div
              style={{
                width: "100%",
              }}
            >
              {query && cms.length > 0 ? (
                cms.map((article) => {
                  const id = article.user.publicId;
                  return (
                    <Articles
                      key={article.contentPublicId}
                      articleId={article.contentPublicId}
                      publishedOn={article.updatedAt}
                      onClick={handleArticleItemClick}
                      author={article.user}
                      authorDesignation={
                        !!article.user ? !!article.user.department : null
                      }
                      articleTitle={article.title}
                      articleContent={article.synopsis}
                      articleCover={article.coverImageUrl}
                      articleThumbnail={article.thumbnailImageUrl}
                      likesNo={article.likesCount}
                      commentNo={article.commentsCount}
                      viewsNo={article.viewsCount}
                      author_profile={
                        article.user && article.user.profilePhotoUrl
                      }
                      read={article.content}
                      like={article.likedByMe}
                      bookmark={article.bookmarkedByMe}
                      handleBookmark={handleBookmark}
                      edit={true}
                      remove={true}
                      share={true}
                      moreOptionHidden={
                        !(id === userData.userInformation.publicId)
                      }
                      isEdited={article.isEdited}
                    />
                  );
                })
              ) : (
                <div className="search-buzz-grid-main-container search-user-grid-sub-container">
                  <div
                    className="search-error-div"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <img
                      src={search}
                      alt="404"
                      style={{ height: "380px", width: "380px" }}
                    />
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "bolder", marginBottom: "10px" }}
                    >
                      Sorry, we found no results that match your search!
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "light" }}>
                      Here's some tips that can help you find what you are
                      looking for :
                    </Typography>
                    <ol style={{ fontSize: "15px", width: "45%" }}>
                      <li>Check your spellings and try again</li>
                      <li>Use more generic terms</li>
                      <li>
                        Contact us at{" "}
                        <a
                          href="https://www.help@bhyve.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          help@bhyve.io
                        </a>
                      </li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
          </div>
        </TabPanel>
      </AppBar>
      <CreateCollabModal
        open={collab}
        handleClose={() => {setCollabUserId(null); setCollab(false);}}
        collabUserId={collabUserId}
        userId={userData && userData.userInformation.publicId}
        companyDepartments={companyDepartments}
      />
      <PendingCollabModal
        open={pendingModal}
        handleClose={() => setPendingModal(false)}
        pendingRequestDetails={pendingRequestDetails}
        handelAccept={handelAccept}
        handleDecline={handleDecline}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  companyDepartments: selectCompanyDepartments,
});

export default withRouter(connect(mapStateToProps)(SearchGrid));
