/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography,IconButton} from '@material-ui/core';
import ExperienceData from '../SubComponents/experience-data/experience-data';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ExpModal from '../SubComponents/add-edit-exp-modal/add-edit-exp-modal';
import Loader from '../SubComponents/loader/loader-component';
import Snackbar from '../SubComponents/snackbar/snackbar';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectCompanyInformation, selectUserData} from '../../redux/user/user-selector';
import {setUserData} from '../../redux/user/user-actions';
import Logout from '../../logout';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import './experience-style.scss';

function Experience({mode,user,userData,setUserData,info,history,companyDepartments, companyDetails, openModal}){
  const [experiences,setExperiences] = useState(null);
  const [expModal,setExpModal] = useState(false);
  const [selected,setSelected] = useState(null);
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);
  const token = localStorage.getItem('bhyve-token');


  useEffect(() => {
    if(openModal) {
      setExpModal(true);
    }
  },[openModal])

  useEffect(()=>{
    try {
      let array;
      if(!user) {
        if(info){
          // console.log(info);
          if(info.workExperience.workExperiences.length > 1) {
            array=sortRecordsByDate(info.workExperience.workExperiences);
          }else{
            array=info.workExperience.workExperiences;
          }
        }
      }else{
        if(userData) {
          if(userData.userInformation.workExperience) {
            if(userData.userInformation.workExperience.workExperiences.length > 1) {
              array=sortRecordsByDate(userData.userInformation.workExperience.workExperiences);
            }else{
              array=userData.userInformation.workExperience.workExperiences;
            }
          }
        }
      }
      // console.log(array);
      setExperiences(array);
    } catch (error) {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
  },[info,mode,userData]);

  const handleAddExp = () => {
    setSelected(null);
    setExpModal(true);
  };

  const handleExpModalClose = () => {
    // console.log('hi');
    setExpModal(false);
  };

  const handleEdit = (index,data) => {
    const Data = {
      id:index,
      ...data
    };
        console.log(Data);
    setSelected(Data);
    setExpModal(true);
  } ;

  const handleUpdateExperience = async data => {
    console.log('handleUpdateExperience--->', data)
    // return
    try {
      setLoading(true);
      var array = [];
      if(data.id !== '') {
        array = userData.userInformation.workExperience.workExperiences.map((item,index) => index ===data.id?{...data,startDate:data.startDate.split('-').reverse().join('/'),endDate:data.endDate?data.endDate.split('-').reverse().join('/'):'present'}:item);
      }else{
        if(userData.userInformation.workExperience) {
          array = userData.userInformation.workExperience.workExperiences;
        }
        array.push({
          ...data,
          startDate:data.startDate.split('-').reverse().join('/'),
          endDate:data.endDate.split('-').reverse().join('/')
        });
      }
      array = array.map(item => {
        delete item.id;
        return item;
      });
      const Data = {
        workExperiences:array
      };
      // console.log(Data);
      setExpModal(false);
      let updateExperienceResult = await updateExperience(Data);
      if(updateExperienceResult) {
        // console.log(updateExperienceResult);
        const current={
          ...userData,
          userInformation:updateExperienceResult.data
        };
        setUserData(current);
        setSelected(null);
        setLoading(false);
        setMessage('Work experience updated successfully!');
        setSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
  };


  function sortRecordsByDate(records) {
    try {
      return records.map(record => {
        const dateString = record.startDate.split('/').reverse().toString();
        const dateTimestamp = Date.parse(dateString);

        record.startDate = dateTimestamp;

        return record;
   }).sort((a, b) => b.startDate - a.startDate).map(record => {
       const submissionDate = new Date(record.startDate).toLocaleDateString('en-GB');
       record.startDate = submissionDate;

       return record;
  });
    } catch (error) {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
}


  const updateExperience = async data => {
    var result;
    if(userData.userInformation.workExperience) {
      result = await axios.patch(`${process.env.REACT_APP_API_URL}user/workExperience/${userData.userInformation.workExperience.workExperiencePublicId}`,data,{
         headers:{
           'Authorization': `Bearer ${token}`,
           'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
         }
       });
    }else{
     result = await axios.post(`${process.env.REACT_APP_API_URL}user/workExperience`,data,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
    }
    return result;
  };




  return(
    <Paper className='skillCard-container exp-container' id='experience-container'>
    <Loader open={loading}/>
    <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
    <div style={{display:'flex',padding:'10px 10px'}}>
      <Typography variant='h6' style={{width:'100%',borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5',display:'flex',justifyContent:'space-between'}}>
        <span style={{padding:'2px 0 4px 0',position:mode==='edit'?'relative':'',fontSize:'1.5rem'}} className='skill-card-title'>Experience</span>
        {user && <AddOutlinedIcon onClick={handleAddExp} style={{opacity:'0.6',cursor:'pointer'}}/>}
      </Typography>
    </div>
      <br/>
      <div className='exp-data-container'>
      {experiences && experiences.map((item,index)=>
        <ExperienceData key={index} 
          id={index} 
          edit={user} 
          handleEdit={()=>handleEdit(index,item)} 
          startMonth={item.startDate&&item.startDate.split('/')[1]} 
          startYear={item.startDate&&item.startDate.split('/')[2]} 
          mode={mode} endMonth={item.endDate&&item.endDate!=='present'?item.endDate.split('/')[1]:'present'} 
          endYear={item.endDate&&item.endDate!=='present'?item.endDate.split('/')[2]:''}  
          designation={item.designation} 
          company={item.companyName} 
          description={item.description}/>
      )}
      </div>
        <ExpModal
          Open={expModal}
          selected={selected}
          handleExpModalClose={handleExpModalClose}
          handleUpdate={handleUpdateExperience}
          companyName={companyDetails?.companyDisplayName}
          companyDepartments={companyDepartments}
        />
    </Paper>
  );
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  companyDetails: selectCompanyInformation
});

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Experience));
