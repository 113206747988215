/*jshint esversion:9*/

export const editActionPoint = (list,object) => {
  try {
    const exist = list.find(item=> item.actionPublicId === object.actionPublicId);

    if(exist){
      return list.map(item => item.actionPublicId === object.actionPublicId?
        object:item
      );
    }
  
    return list; 
  } catch (error) {
    console.log(error.message);
  }
};
