/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Crown from "../../../Assets/crown@2x.png";
import "./relevant-skills-card-style.scss";
import axios from "axios";
import Logout from "../../../logout";
import { connect } from "react-redux";
import { setUserData } from "../../../redux/user/user-actions";
import { selectUserData } from "../../../redux/user/user-selector";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
//import ModeCommentIcon from '@material-ui/icons/ModeComment';

function RelevantSkills({
  userSkills,
  allRecommendedSkills,
  currentUser,
  setCurrentUser,
  handleFilterAddedSkill,
  history,
}) {
  const [loading, setLoading] = useState(false);
  const [mappedSkills, setMappedSkills] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectExpertise, setSelectExpertise] = useState(false);
  const [selectedExpertise, setSelectedExpertise] = useState("Expert");

  const token = localStorage.getItem("bhyve-token");

  //const skills = ['Marketing Analysis','Sales','Financial Consultant','SEO','Management','Advertising','CMS','Public Relations','A/B testing','Story telling'];
  useEffect(() => {
    if (allRecommendedSkills) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [allRecommendedSkills]);

  useEffect(() => {
    if (userSkills) {
      let mapped = [];
      userSkills.forEach((skill) => {
        let object = {};
        switch (skill.score) {
          case 1:
            object = {
              ...skill,
              score: "Expert",
            };
            break;
          case 0.8:
            object = {
              ...skill,
              score: "Advanced",
            };
            break;
          case 0.667:
            object = {
              ...skill,
              score: "Intermediate",
            };
            break;
          default:
            object = {
              ...skill,
              score: "Basic",
            };
            break;
        }
        object = {
          ...object,
          name: skill.skillDisplayName,
        };
        mapped.push(object);
      });
      console.log(mapped);

      function compare(a, b) {
        if (a.skillName < b.skillName) {
          return -1;
        }
        if (a.skillName > b.skillName) {
          return 1;
        }
        return 0;
      }

      let newArr = [];
      let basicSkills = [];
      let averageSkills = [];
      let expertSkills = [];
      mapped.map((item) =>
        item.score === "Basic"
          ? basicSkills.push(item)
          : item.score === "Average"
          ? averageSkills.push(item)
          : expertSkills.push(item)
      );
      newArr = [
        ...expertSkills.sort(compare),
        ...averageSkills.sort(compare),
        ...basicSkills.sort(compare),
      ];
      setMappedSkills(newArr);
    }
  }, [userSkills]);

  const handleForward = () => {
    setCurrentIndex((prevValue) => {
      return prevValue + 1;
    });
  };

  const handleBack = () => {
    setCurrentIndex((prevValue) => {
      return prevValue - 1;
    });
  };

  const addUserSkill = async (req) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}user/skills/skillName`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleAddSkill = () => {
    setSelectExpertise(true);
  };

  const handleExpertiseSelect = async (e) => {
    try {
      setSelectExpertise(false);
      setLoading(true);
      let skillSelected = allRecommendedSkills[currentIndex];
      let reqObject = {
        score: parseFloat(e.target.value),
        skillName: skillSelected,
        skillDisplayName: capitalize(skillSelected),
      };
      console.log(reqObject);
      let addUserSkillResult = await addUserSkill(reqObject);
      if (addUserSkillResult) {
        console.log(addUserSkillResult);
        let user = {
          ...currentUser,
          userInformation: addUserSkillResult.data,
        };
        setCurrentUser(user);
        handleFilterAddedSkill(skillSelected);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  // const sortArray = data => {
  //   try {
  //     let newArray = data.userSkills;
  //     let basicSkills = [];
  //     let averageSkills = [];
  //     let expertSkills = [];
  //     newArray.map(item => item.score === 0.333?basicSkills.push(item):item.score===0.667?averageSkills.push(item):expertSkills.push(item));
  //     newArray = [...expertSkills.sort(compare),...averageSkills.sort(compare),...basicSkills.sort(compare)];
  //     return newArray;
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  function capitalize(input) {
    try {
      let currentInput = input;
      if (typeof input === "object") {
        if (
          input.skillName.toLowerCase() === input.skillDisplayName.toLowerCase()
        ) {
          if (input.skillName !== input.skillDisplayName) {
            return input.skillDisplayName;
          } else {
            currentInput = input.skillDisplayName;
          }
        } else {
          return input.skillDisplayName;
        }
      }
      var words = currentInput.split(" ");
      var CapitalizedWords = [];
      let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
      words.forEach((element) => {
        let currentElement = "";
        if (element.split("").length > 1 && element.split("").includes("&")) {
          let temp = element.split[0];
          currentElement =
            element.split("")[0].toUpperCase() +
            "&" +
            element.split("")[2].toUpperCase();
        } else {
          currentElement = element;
        }
        if (currentElement === "cpr") {
          CapitalizedWords.push("CPR");
        } else if (currentElement === "chp") {
          CapitalizedWords.push("CHP");
        } else if (wordsTobeIgnored.includes(currentElement)) {
          CapitalizedWords.push(currentElement);
        } else {
          CapitalizedWords.push(
            currentElement[0].toUpperCase() +
              currentElement.slice(1, currentElement.length)
          );
        }
      });
      return CapitalizedWords.join(" ");
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  return (
    <div className="relevant-skills-main-container">
      <p className="relevant-skills-main-container-title">My Skills</p>
      <div className="relevant-skills-user-skills-map">
        <Paper className="relevant-skills-user-skills-map-tab">
          <div className="relevant-skills-user-skills-map-tab-headers">
            <div
              className="header"
              onClick={() => setSelectedExpertise("Expert")}
            >
              <p
                className={selectedExpertise === "Expert" && "active-category"}
              >
                Expert
              </p>
            </div>
            <div
              className="header"
              onClick={() => setSelectedExpertise("Advanced")}
            >
              <p
                className={
                  selectedExpertise === "Advanced" && "active-category"
                }
              >
                Advanced
              </p>
            </div>
            <div
              className="header"
              onClick={() => setSelectedExpertise("Intermediate")}
            >
              <p
                className={
                  selectedExpertise === "Intermediate" && "active-category"
                }
              >
                Intermediate
              </p>
            </div>
            <div
              className="header"
              onClick={() => setSelectedExpertise("Basic")}
            >
              <p className={selectedExpertise === "Basic" && "active-category"}>
                Beginner
              </p>
            </div>
          </div>

          <div className="relevant-skills-user-skills-map-tab-skills">
            {mappedSkills.map(
              (skill, index) =>
                skill.score === selectedExpertise && (
                  <Tooltip
                    key={index}
                    style={{ cursor: "pointer" }}
                    title={skill.name}
                    placement="left"
                  >
                    <p
                      onClick={() =>
                        history.push(
                          `/User/Search?q=${skill.name.toLowerCase()}`
                        )
                      }
                    >
                      <span>{capitalize(skill)}</span>
                      <div style={{ display: "flex" }}>
                        {skill.totalEndorsementCount && (
                          <img
                            src={Crown}
                            width="25"
                            height="20"
                            style={{ alignSelf: "right" }}
                          />
                        )}
                        {skill.totalEndorsementCount &&
                          skill.totalEndorsementCount > 2 && (
                            <span
                              style={{
                                lineHeight: "1ch",
                                margin: "auto auto",
                                marginRight: "5px",
                                textAlign: "center",
                              }}
                            >
                              {Math.floor(skill.totalEndorsementAverage)}
                            </span>
                          )}
                      </div>
                    </p>
                  </Tooltip>
                )
            )}
          </div>
        </Paper>
        {/* {mappedSkills.map(skill => 
              <div className='relevant-skills-user-skills-map-skill'>
                {skill.name.length <= 18 ?
                 <p>{skill.name}</p> 
                 :
                 <Tooltip style={{cursor:'pointer'}} title={skill.name} placement='top'>
                  <p>{`${skill.name.slice(0,17)}...`}</p>
                 </Tooltip>
                 }
                <div style={{width:'100%'}}>
                  <Chip style={{background:skill.score==='Expert'?'#66CCFF':skill.score==='Average'?'#FFCC33':'#EE813A',color:'white',height:'20px',fontWeight:'300',marginTop:'20px',borderRadius:'10px',width:'100%'}} label={skill.score==='Expert'?'Expert':skill.score==='Average'?'Proficient':'Beginner'}/>
                  <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    {skill.totalEndorsementCount && <img src={Crown} width='25' height='25' style={{alignSelf:'right'}}/>}
                    {skill.totalEndorsementCount && skill.totalEndorsementCount > 2 && <p style={{lineHeight:'1ch',marginTop:"10px",marginRight:'5px',textAlign:'center'}}>{Math.floor(skill.totalEndorsementAverage)}</p>}
                  </div>
                </div>
              </div>
            )} */}
      </div>
      <div
        style={{ textAlign: "center", position: "relative", paddingTop: "5px" }}
      >
        {selectExpertise && (
          <div className="relevant-skill-expertise-container">
            <div className="relevant-skill-expertise-sub-container">
              <p className="relevant-skill-expertise-skill">
                {capitalize(allRecommendedSkills[currentIndex])}
              </p>
              <FormControl
                component="fieldset"
                className="radio-grp"
                style={{ marginBottom: "5px" }}
              >
                <RadioGroup
                  row
                  aria-label="position"
                  name="score"
                  defaultValue="top"
                  onChange={handleExpertiseSelect}
                >
                  <FormControlLabel
                    value={0.333}
                    control={<Radio color="primary" />}
                    label="Beginner"
                  />
                  <FormControlLabel
                    value={0.667}
                    control={<Radio color="primary" />}
                    label="Intermediate"
                  />
                  <FormControlLabel
                    value={0.8}
                    control={<Radio color="primary" />}
                    label="Advanced"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Expert"
                  />
                </RadioGroup>
              </FormControl>
              <div>
                <Button
                  className="relevant-skill-expertise-back-btn"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setSelectExpertise(false)}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        )}

        {loading && <CircularProgress color="primary" />}
        {!loading && !!allRecommendedSkills && allRecommendedSkills.length > 0 && (
          <>
            <br />
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.15rem",
                textAlign: "left",
              }}
            >
              Skills you may know
            </p>
            <br />
            <div className="relevant-skills-recommended-skill-container">
              <IconButton onClick={handleBack} disabled={currentIndex === 0}>
                <ArrowBackIosIcon />
              </IconButton>
              <h2
                className="relevant-skills-recommended-skill-text"
                onClick={() =>
                  history.push(
                    `/User/Search?q=${allRecommendedSkills[currentIndex]}`
                  )
                }
              >
                {allRecommendedSkills[currentIndex] &&
                  capitalize(allRecommendedSkills[currentIndex])}
              </h2>
              <IconButton
                onClick={handleForward}
                disabled={currentIndex === allRecommendedSkills.length - 1}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ padding: "10px 30px 10px 30px", marginRight: "10px" }}
                className="edit-btn"
                onClick={handleAddSkill}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </div>
      <br />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (object) => dispatch(setUserData(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RelevantSkills)
);
