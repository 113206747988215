/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import "./interest-style.scss";
import { Paper } from "@material-ui/core";
import InterestCard from "../SubComponents/interest-card/interestCard-component";
import InterestModal from "../SubComponents/interestModal/InterestModal";
import Snackbar from "../SubComponents/snackbar/snackbar";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/user-selector";
import { setUserData } from "../../redux/user/user-actions";
import { withRouter } from "react-router-dom";
import Loader from "../SubComponents/loader/loader-component";
import Logout from "../../logout";
import axios from "axios";
import {
  Button,
  Fade,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const InterestCardMemo = React.memo(InterestCard);

function Interests({
  skillCategories,
  handleNext,
  confirmChanges,
  selectedInterests,
  profileCompletion,
  setUserData,
  setNewInterests,
  interestsFromSkills,
  userSkills,
  history,
}) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [interestModal, setInterestModal] = useState(false);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedSkills, setSearchedSkills] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryDisplayName, setCategoryDisplayName] = useState("");
  const token = localStorage.getItem("bhyve-token");

  //this useEffect is called to set already selected skills and their corresponding categories
  useEffect(() => {
    try {
      if (selectedInterests && skillCategories) {
        let skillsList = selectedInterests.map((item) => item.skillName);
        setSelectedSkills(skillsList);
        let Current = [];
        selectedInterests.forEach((item) => {
          let category;
          skillCategories.skillCategories.forEach((Cat) => {
            const exist = Cat.skills.find(
              (s) => s.skillName === item.skillName
            );
            if (exist) {
              category = Cat.skillCategory.skillCategoryName;
            }
          });

          if (!Current.includes(category)) {
            Current.push(category);
          }
        });
        setSelectedCategories(Current);
      } else {
        if (userSkills) {
          if (interestsFromSkills) {
            let skillsList = [];
            if (selectedSkills.length === 0) {
              skillsList = userSkills.map((item) => item.skillName);
              setSelectedSkills(skillsList);
              if (!profileCompletion) {
                setNewInterests(skillsList);
              }
            } else {
              skillsList = selectedSkills;
              userSkills.forEach((skill) => {
                if (!skillsList.includes(skill.skillName)) {
                  skillsList.push(skill.skillName);
                }
              });
              setSelectedSkills(skillsList);
              if (!profileCompletion) {
                setNewInterests(skillsList);
              }
            }
            let currentSelectedCategories = [];
            skillsList.forEach((item) => {
              skillCategories.skillCategories.forEach((Cat) => {
                if (Cat.skills.some((s) => s.skillName === item)) {
                  if (
                    !currentSelectedCategories.includes(
                      Cat.skillCategory.skillCategoryName
                    )
                  ) {
                    currentSelectedCategories.push(
                      Cat.skillCategory.skillCategoryName
                    );
                  }
                }
              });
            });
            setSelectedCategories(currentSelectedCategories);
          } else {
            if (selectedSkills.length > 0) {
              let currentSelected = selectedSkills;
              userSkills.forEach((skill) => {
                if (currentSelected.includes(skill.skillName)) {
                  currentSelected = currentSelected.filter(
                    (item) => item !== skill.skillName
                  );
                }
              });
              setSelectedSkills(currentSelected);
              if (!profileCompletion) {
                setNewInterests(currentSelected);
              }
              if (currentSelected.length > 0) {
                let CurrentCategories = [];
                currentSelected.forEach((item) => {
                  let category;
                  skillCategories.skillCategories.forEach((Cat) => {
                    const exist = Cat.skills.find((s) => s.skillName === item);
                    if (exist) {
                      category = Cat.skillCategory.skillCategoryName;
                    }
                  });
                  if (!CurrentCategories.includes(category)) {
                    CurrentCategories.push(category);
                  }
                });
                setSelectedCategories(CurrentCategories);
              } else {
                setSelectedCategories([]);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    return () => {
      window.scrollTo(0, 0);
    };
  }, [
    selectedInterests,
    skillCategories,
    interestsFromSkills,
    userSkills,
    profileCompletion,
  ]);

  useEffect(() => {
    if (confirmChanges) {
      handleNextBtn();
    }
  }, [confirmChanges]);

  const setProfileCompletion = async () => {
    try {
      const data = {
        profileCompleted: true,
      };
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/me/complete-profile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const addUserInterest = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/interests`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const updateInterests = async (data) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/interests`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  //handles assigning skills to 'skills' useState and identifies the already selected skills in the selected category
  const addToList = (id) => {
    try {
      const exist = skillCategories.skillCategories.find(
        (item) => item.skillCategory.publicId === id
      );
      setCurrentCategory(exist.skillCategory.skillCategoryName);
      setCategoryDisplayName(exist.skillCategory.skillCategoryDisplayName);
      const updatedSkills = exist.skills.map((skill) => {
        if (selectedSkills.includes(skill.skillName)) {
          return {
            skillDisplayName: skill.skillDisplayName,
            skillName: skill.skillName,
            skillPublicId: skill.skillPublicId,
            selected: true,
          };
        } else {
          return {
            skillDisplayName: skill.skillDisplayName,
            skillName: skill.skillName,
            skillPublicId: skill.skillPublicId,
            selected: false,
          };
        }
      });
      setSkills(updatedSkills);
      setInterestModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    if (interestModal) {
      setInterestModal(false);
      return;
    }
  };

  //handles saving interested and updating the profile completion status of user
  const handleNextBtn = async () => {
    try {
      if (selectedSkills.length !== 0) {
        setLoading(true);
        let skills = [];
        selectedSkills.map((item, index) =>
          skillCategories.skillCategories.map((category) => {
            const exist = category.skills.find(
              (skill) => skill.skillName === item
            );
            if (exist) {
              skills.push({
                skillDisplayName: exist.skillDisplayName,
                skillName: exist.skillName,
                skillPublicId: exist.skillPublicId,
                priority: index + 1,
              });
            }
            return null;
          })
        );
        const object = {
          userInterests: skills,
        };
        try {
          if (selectedInterests) {
            let updateInterestsResult = await updateInterests(object);
            if (updateInterestsResult) {
              setUserData({ userInformation: updateInterestsResult.data });
              handleNext(true);
            }
            if (!profileCompletion) {
              let profileCompletionResult = await setProfileCompletion();
              let current = {
                userInformation: profileCompletionResult.data,
              };
              setUserData(current);
            }
            setLoading(false);
            return;
          } else {
            let addInterestResult = await addUserInterest(object);
            if (addInterestResult) {
              let profileCompletionResult = await setProfileCompletion();
              let current = {
                userInformation: profileCompletionResult.data,
              };
              setUserData(current);
              setLoading(false);
              return;
            }
          }
          handleNext(selectedSkills);
        } catch (error) {
          console.log(error);
          setLoading(false);
          if (error.response && error.response.status === 401) {
            history.push("/logout");
          }
        }
      } else {
        setAlert("Select atleast one skill !");
        setSeverity("warning");
        setAlertOpen(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //handles deletion of skills
  const handleDelete = (skill) => {
    try {
      let current = selectedSkills;
      current = current.filter(
        (item) => item.skillPublicId !== skill.skillPublicId
      );
      setSelectedSkills(current);
    } catch (error) {
      console.log(error);
    }
  };

  //handles addition of skills to selected skills
  const handleSelect = (skill) => {
    try {
      let current = selectedSkills;
      const exist = current.some((item) => item === skill.skillName);
      if (!exist) {
        current.push(skill.skillName);
      } else {
        current = current.filter((item) => item !== skill.skillName);
      }
      let Current = [];
      let category;
      current.forEach((item) => {
        skillCategories.skillCategories.forEach((Cat) => {
          if (Cat.skills.some((s) => s.skillName === item)) {
            category = Cat.skillCategory.skillCategoryName;
          }
        });

        if (!Current.includes(category)) {
          Current.push(category);
        }
      });
      setSelectedCategories(Current);
      const newSkills = skills.map((s) => {
        if (s.skillName === skill.skillName) {
          return {
            skillDisplayName: s.skillDisplayName,
            skillName: s.skillName,
            skillPublicId: s.skillPublicId,
            selected: !s.selected,
          };
        } else {
          return s;
        }
      });
      setSkills(newSkills);
      setSelectedSkills(current);
      if (!profileCompletion) {
        setNewInterests(current);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    try {
      const { value } = e.target;
      setSearch(value);
      if (value.length > 0) {
        setSearchMode(true);
        let searchResult = await axios.get(
          `${process.env.REACT_APP_API_URL}user/autocomplete/skills?skillName=${value}&limit=0`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSearchedSkills(searchResult.data);
      } else {
        handleSearchClose();
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const handleSearchClose = (e) => {
    setSearch("");
    setSearchMode(false);
  };

  const handleSelectAll = (bool) => {
    if (bool) {
      let currentCategories = selectedCategories;
      if (!currentCategories.includes(currentCategory)) {
        currentCategories.push(currentCategory);
      }
      setSelectedCategories(currentCategories);
      const exist = skillCategories.skillCategories.find(
        (item) => item.skillCategory.skillCategoryName === currentCategory
      );
      const updatedSkills = exist.skills.map((skill) => {
        return {
          skillDisplayName: skill.skillDisplayName,
          skillName: skill.skillName,
          skillPublicId: skill.skillPublicId,
          selected: true,
        };
      });
      let currentSelectedSkills = selectedSkills;
      let newSelectedSkills = updatedSkills.map((item) => item.skillName);
      setSelectedSkills([...currentSelectedSkills, ...newSelectedSkills]);
      if (!profileCompletion) {
        setNewInterests([...currentSelectedSkills, ...newSelectedSkills]);
      }
      setSkills(updatedSkills);
    } else {
      if (skillCategories) {
        let currentCategories = selectedCategories;
        if (currentCategories.includes(currentCategory)) {
          currentCategories = currentCategories.filter(
            (item) => item !== currentCategory
          );
        }
        setSelectedCategories(currentCategories);
        const exist = skillCategories.skillCategories.find(
          (item) => item.skillCategory.skillCategoryName === currentCategory
        );
        if (exist) {
          const updatedSkills = exist.skills.map((skill) => {
            return {
              skillDisplayName: skill.skillDisplayName,
              skillName: skill.skillName,
              skillPublicId: skill.skillPublicId,
              selected: false,
            };
          });
          let currentSelectedSkills = selectedSkills;
          updatedSkills.forEach((skill) => {
            currentSelectedSkills = currentSelectedSkills.filter(
              (item) => item !== skill.skillName
            );
          });
          setSelectedSkills(currentSelectedSkills);
          if (!profileCompletion) {
            setSelectedSkills(currentSelectedSkills);
          }
          setSkills(updatedSkills);
        }
      }
    }
  };

  return (
    <Fade in={true} timeout={2000}>
      <Paper
        className="interests-main-container"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Loader open={loading} />
        <Snackbar
          Open={alertOpen}
          alert={alert}
          severity={severity}
          handleClose={() => setAlertOpen(false)}
        />
        <div className="search-skill-container">
          <TextField
            value={search}
            placeholder="Search Skills"
            className="search-txt-field"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchMode && (
                <InputAdornment position="end">
                  <IconButton
                    style={{ width: "10px", height: "10px" }}
                    onClick={handleSearchClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="interest-container">
          {!searchMode &&
            skillCategories &&
            skillCategories.skillCategories.map((item, index) => (
              <InterestCardMemo
                key={index}
                Id={item.skillCategory.publicId}
                name={item.skillCategory.skillCategoryDisplayName}
                imgUrl={item.skillCategory.imageUrl}
                selected={selectedCategories.includes(
                  item.skillCategory.skillCategoryName
                )}
                handleSelect={addToList}
              />
            ))}
          {searchMode && (
            <div className="searched-skills-container">
              {searchedSkills.map((item, index) => (
                <Button
                  startIcon={
                    item.selected || selectedSkills.includes(item.skillName) ? (
                      <FiberManualRecordIcon color="primary" />
                    ) : (
                      ""
                    )
                  }
                  onClick={() => handleSelect(item)}
                  style={{
                    justifyContent: "left",
                    textAlign: "left",
                    textTransform: "none",
                    minHeight: "50px",
                  }}
                  key={index}
                >
                  {item.skillDisplayName}
                </Button>
              ))}
            </div>
          )}
          <InterestModal
            categoryDisplay={categoryDisplayName}
            open={interestModal}
            skills={skills}
            selected={selectedSkills}
            handleClose={handleClose}
            handleDelete={handleDelete}
            handleSelect={handleSelect}
            handleSelectAll={handleSelectAll}
          />
        </div>
      </Paper>
    </Fade>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Interests)
);
