import React, { useReducer, useEffect } from "react";
import confetti from "./Assets/confetti.png";
import SampleVid from "./Assets/sample-vid.mov";
import buzzboxVid from "./Assets/buzzbox.MP4";
import step1Image from "./Assets/Walkthrough-Step1.png";
import step2Image from "./Assets/Walkthrough-Step2.png";
import step3Image from "./Assets/Walkthrough-Step3.png";
import step4Image from "./Assets/Walkthrough-ThankYou.png";
import { setTriggerPoint } from "./redux/walkthrough/walkthrough-actions";
import { connect } from "react-redux";
import endorseVid from "./Assets/endorse.MP4";
import "./tour.scss";

const image = () => {
  return <img src={confetti} style={{ width: "20%" }} />;
};

const Step = ({ step }) => {
  return (
    <div className="step-content">
      {step < 3 && <h1>Step {step}of3</h1>}
      {step === 4 && <h1>Thank You!</h1>}
      <div className="step-image-container">
        <img
          src={
            step === 1
              ? step1Image
              : step === 2
              ? step2Image
              : step === 2
              ? step3Image
              : step4Image
          }
          width="150"
        />
      </div>
      {step === 1 ? (
        <p>Collaborate with experts for a skill you want to learn.</p>
      ) : step === 2 ? (
        <p>Buzzbox is here to help you.</p>
      ) : step === 3 ? (
        <p>
          Reach out for help or ask questions using #SkillName and get help
          instantly
        </p>
      ) : (
        <p>
          Keep buzzing around and earn points. You can always start the tour
          again!
        </p>
      )}
      {step === 1 ? (
        <p>Click on “Collaborate” button to proceed further.</p>
      ) : step === 2 ? (
        <p>Click here to find out.</p>
      ) : (
        <p></p>
      )}
    </div>
  );
};

// Define the steps
const START_LAST = [
  {
    target: ".bee-tour-option",
    content: <Step step={4} />,
    placement: "left-start",
  },
];

const CHAT_TOUR_STEPS = [
  {
    target: ".action-btn-tour",
    content: (
      <video src={endorseVid} width="450" height="200" autoplay="autoplay" />
    ),
    disableBeacon: true,
    disableScrolling: true,
    disableScrollParentFix: true,
  },
  {
    target: ".nav-home-icon",
    content: "This icon will lead you back to the Dashboard",
  },
  {
    target: ".tour-contact",
    content: "Contact the developer",
  },
  {
    target: ".tour-policy",
    content: "We accept returns after 14 days max",
  },
];

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: START_LAST,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START_DASHBOARD":
      return { ...state, steps: START_LAST, run: true };
    case "START_CHAT":
      return { ...state, steps: CHAT_TOUR_STEPS, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      console.log("step index: ", action.payload);

      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Define the Tour component
function LastTour({ type = "last", setTrigger }) {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    //debugger;
    if (!localStorage.getItem("tour") && type) {
      //debugger;
      switch (type) {
        case "last":
          //debugger;
          dispatch({ type: "START_LAST" });
          break;
        case "chat":
          dispatch({ type: "START_CHAT" });
          break;
        default:
          break;
      }
    }
  }, [type]);

  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return <></>;
}

export default LastTour;
