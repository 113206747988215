import React, { useState, useEffect } from "react";
import { Avatar, IconButton, Typography, Popover } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import Snackbar from "../snackbar/snackbar";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../../redux/user/user-selector";
import { selectCurrentBuzzes } from "../../../redux/buzzes/buzzes-selector";
import { setCurrentBuzzes } from "../../../redux/buzzes/buzzes-actions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./buzz-feed-textarea-style.scss";
import Backdrop from "../../SubComponents/backdrop/backdrop-component";
import CircularProgress from "@material-ui/core/CircularProgress";

const Item = ({ entity: { name } }) => {
  return (
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "96%",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          padding: "0 5px 0 5px",
        }}
      >{`${name}`}</span>
    </div>
  );
};
const User = ({ entity: { profilePhotoUrl, firstName, lastName } }) => (
  <div
    style={{
      display: "flex",
      padding: "0 5px 0 5px",
    }}
  >
    <Avatar src={profilePhotoUrl} style={{ width: "30px", height: "30px" }} />
    <span
      style={{
        marginLeft: "10px",
      }}
    >{`${firstName} ${lastName}`}</span>
  </div>
);

function BuzzFeedTextArea({
  userData,
  currentBuzzes,
  setCurrentBuzzes,
  selectedBuzz,
  history,
}) {
  const token = localStorage.getItem("bhyve-token");

  const [taggedUsers, setTaggedUsers] = useState([]);
  const [buzz, setBuzz] = useState({
    id: null,
    text: "",
  });
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);

  const [displayLoader, setDisplayLoader] = useState(false);

  const [maxCountReachedMessage, setMaxCountReachedMessage] = useState(false);

  useEffect(() => {
    if (selectedBuzz) {
      setBuzz({
        id: selectedBuzz.shoutoutPublicId,
        text: selectedBuzz.text,
      });
    }
  }, [selectedBuzz]);

  const handleChange = (e) => {
    const { value } = e.target;
    let currentValue = value;
    // if (currentValue.length === 251) {
    //   return;
    // }

    if (currentValue.length >= 250) {
      setMaxCountReachedMessage(true);
      currentValue = currentValue.slice(0, 250);
    } else {
      setMaxCountReachedMessage(false);
    }
    setBuzz((prevValue) => {
      return {
        ...prevValue,
        text: currentValue,
      };
    });
  };

  const addEmoji = (e) => {
    if (buzz.text.length === 200) {
      return;
    }
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setBuzz((prevValue) => {
      return { ...prevValue, text: prevValue.text + emoji };
    });
    setEmojiAnchorEl(null);
  };

  const handleOpenEmojiMenu = (e) => {
    if (!emojiAnchorEl) {
      setEmojiAnchorEl(e.currentTarget);
    } else {
      setEmojiAnchorEl(null);
    }
  };

  const handleEmojiMenuClose = () => {
    setEmojiAnchorEl(null);
  };

  const handleSelected = (e) => {
    let current = taggedUsers;
    console.log(current);
    current.push(e.item);
    console.log(current);
    setTaggedUsers(current);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (buzz.text !== "") {
        handleSendBuzz();
      }
    }
  };

  const getAutoCompleteSuggestions = async (key) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/autocomplete/skills?skillName=${key}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const getAutoCompleteUserSuggestions = async (name) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/username/autocomplete?name=${name}&limit=3`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const handleSendBuzz = async () => {
    try {
      if (buzz.text.split(" ").join("").length > 0) {
        setBuzz({
          id: null,
          text: "",
        });
        setDisplayLoader(true);
        setMessage("Posting...");
        setSeverity("info");
        setAlertOpen(true);
        console.log("buzz:", buzz);
        console.log("taggedUsers:", taggedUsers);
        let current = buzz.text.split(" ");
        let skillTags = [];
        current.map((item) =>
          item.split("").find((letter) => letter === "#")
            ? skillTags.push(item.slice(1).split("_").join(" "))
            : item
        );
        let userTags = [];
        current.map((item) =>
          item.split("").find((letter) => letter === "@")
            ? userTags.push(
                taggedUsers.find(
                  (user) =>
                    item.slice(1).split("_")[0] === user.firstName &&
                    item.slice(1).split("_")[1] === user.lastName
                )["publicId"]
              )
            : item
        );
        console.log(userTags);
        let postBuzzResult = await postBuzz(buzz, skillTags, userTags);
        if (postBuzzResult) {
          console.log(postBuzzResult);
          let current = currentBuzzes;
          if (buzz.id) {
            current = current.map((item) =>
              item.shoutoutPublicId === buzz.id ? postBuzzResult.data : item
            );
          } else {
            current = [postBuzzResult.data].concat(current);
          }
          console.log(current);
          setCurrentBuzzes(current);
          setAlertOpen(false);
          setMessage("Buzz posted successfully!");
          setSeverity("success");
          setAlertOpen(true);
          setDisplayLoader(false);
        }
      }
    } catch (err) {
      console.log(err);
      setMessage("Buzz could not be posted!");
      setSeverity("error");
      setAlertOpen(true);
      setDisplayLoader(false);
    }
  };

  const postBuzz = async (buzz, skills, users) => {
    const req = {
      text: buzz.text,
      skillsTagged: skills,
      taggedUsers: users,
    };
    console.log(req);
    let result;
    if (buzz.id) {
      result = await axios.patch(
        `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${buzz.id}`,
        req,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
    } else {
      result = await axios.post(
        `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts`,
        req,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
    }
    return result;
  };

  const navigateToUser = () => {
    history.push(`/User/Profile/${userData.userInformation.publicId}`);
  };

  return (
    userData && (
      <div style={{ width: "100%" }}>
        <div className="buzz-feed-textarea-container">
          <div className="buzz-feed-textarea-dp-container">
            {userData.userInformation.profilePhotoUrl !== "" && (
              <Avatar
                src={userData.userInformation.profilePhotoUrl}
                className="buzz-feed-textarea-user-photo"
                onClick={navigateToUser}
              />
            )}
            {userData.userInformation.profilePhotoUrl === "" && (
              <Typography
                variant="h2"
                className="initial-container"
                onClick={navigateToUser}
                style={{
                  cursor: "pointer",
                  textAlign: "center!important",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "12%",
                  color: "white",
                  background: "#FFCC33",
                  width: "60px",
                  height: "60px",
                }}
              >
                {userData.userInformation.firstName &&
                  `${userData.userInformation.firstName.slice(
                    0,
                    1
                  )}${userData.userInformation.lastName.slice(0, 1)}`}
              </Typography>
            )}
          </div>
          <ReactTextareaAutocomplete
            loaderStyle={{
              position: "relative",
              top: "20px",
            }}
            containerStyle={{
              width: "100%",
              position: "relative",
              zIndex: "1",
              borderColor: "yellow",
              borderRadius: "10px",
            }}
            listStyle={{
              width: "220px",
              marginTop: "10px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              zIndex: "10",
              borderRadius: "2px",
              padding: "5px 0 5px 0",
              backgroundColor: "white",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dropdownStyle={{
              zIndex: "10",
              position: "absolute",
              bottom: "10px",
              backgroundColor: "white",
            }}
            itemClassName="list-item"
            ref={(rta) => {
              BuzzFeedTextArea.rta = rta;
            }}
            innerRef={(textarea) => {
              BuzzFeedTextArea.textarea = textarea;
            }}
            value={buzz.text}
            className="buzz-feed-text-area"
            placeholder="Need help with a skill? Ask a question using #SkillName (250 characters max.)"
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            onItemSelected={handleSelected}
            loadingComponent={() => <span>Loading</span>}
            trigger={{
              "#": {
                dataProvider: async (token) => {
                  let skillNames = [];
                  if (token.length >= 3) {
                    if (token.includes("_")) {
                      token = token.split("_").join(" ");
                    }
                    skillNames = await getAutoCompleteSuggestions(token).then(
                      (res) => {
                        return res.data;
                      }
                    );
                    skillNames = skillNames.map((item) => {
                      return { name: item.skillName };
                    });
                    skillNames = skillNames.map((item) =>
                      item.name.split("").includes(" ")
                        ? { name: item.name.replace(/ /g, "_") }
                        : item
                    );
                    console.log(skillNames);
                    console.log(skillNames);
                    return skillNames;
                  }
                },
                component: Item,
                output: (item, trigger) => `#${item.name}`,
              },
              "@": {
                dataProvider: async (token) => {
                  let userNames = [];
                  if (token.length >= 3) {
                    if (token.includes("_")) {
                      token = token.split("_").join(" ");
                    }
                    userNames = await getAutoCompleteUserSuggestions(token)
                      .then((res) => {
                        console.log(res.data);
                        return res.data;
                      })
                      .catch((err) => console.log(err));
                    userNames = userNames.map((item) => {
                      return { ...item };
                    });
                    //skillNames = skillNames.map(item => item.name.split('').includes(" ")?{name:item.name.replace(/\s+/, '_')}:item);
                    console.log(userNames);
                    return userNames;
                  }
                },
                component: User,
                output: (item, trigger) =>
                  `@${item.firstName}_${item.lastName}`,
              },
            }}
          />
          <IconButton
            aria-describedby="emoji-popover"
            onClick={handleOpenEmojiMenu}
          >
            <InsertEmoticonIcon />
          </IconButton>
          {displayLoader ? (
            <div>
              <Backdrop open={displayLoader} />
              <CircularProgress color="primary" />
            </div>
          ) : (
            <IconButton
              onClick={() => handleSendBuzz()}
              className="buzz-send-btn"
            >
              <SendIcon />
            </IconButton>
          )}
        </div>
        <Popover
          id={Boolean(emojiAnchorEl) ? "emoji-popover" : undefined}
          open={Boolean(emojiAnchorEl)}
          anchorEl={emojiAnchorEl}
          onClose={handleEmojiMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Picker onSelect={addEmoji} />
        </Popover>
        {maxCountReachedMessage && (
          <small
            style={{
              color: "red",
              fontSize: "12px",
              marginLeft: "370px",
            }}
          >
            You have reached 250 character limit!
          </small>
        )}
        <Snackbar
          Open={alertOpen}
          severity={severity}
          alert={message}
          handleClose={() => setAlertOpen(false)}
        />
      </div>
    )
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  currentBuzzes: selectCurrentBuzzes,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentBuzzes: (array) => dispatch(setCurrentBuzzes(array)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuzzFeedTextArea)
);
