import React from 'react';
import { makeStyles,Fade,Modal,Backdrop,Button} from '@material-ui/core';
import walkthroughLogo from '../../../Assets/walkthrough-logo.svg';
import './style.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outline:'none',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: '5px',
      minWidth:'40%'
    },
  }));

const WalkthroughStart = ({open, handleClose, startTour, skipTour}) => {

    const classes = useStyles();

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className='main-container'>
                <div className='skip-container'>
                    <Button onClick={() => skipTour()}>Skip Tour</Button>
                </div>
                <div className='logo-container'>
                    <img src={walkthroughLogo} alt='logo'/>
                </div>
                <h1>Welcome to BHyve!</h1>
                <p>An easy 4 step process to get you started</p>
                <p>on your BHyve</p>
                <div className='bottom-btn-container'>
                    <Button variant='contained' className='start-tour-btn' onClick={()=>startTour()}>Take a Bee-Tour</Button>
                </div>
            </div>
          </div>
        </Fade>
      </Modal>
    )
}


export default WalkthroughStart;