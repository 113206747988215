/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import Info from "../SubComponents/info/info";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import PeopleIcon from "@material-ui/icons/People";
import InfoEdit from "../SubComponents/info-edit/info-edit";
import gender from "../../Assets/gender.png";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/user-selector";
import { setUserData } from "../../redux/user/user-actions";
import Snackbar from "../SubComponents/snackbar/snackbar";
import axios from "axios";
import { useHistory } from "react-router";
import { AGE_GROUP_MAP } from "../createProfile/createProfile-component";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

function BasicInformation({
  mode,
  userData,
  setUserData,
  info,
  handleEditDone,
  companyDepartments,
}) {
  const [details, setDetails] = useState({
    ageRange: null,
    department: null,
    firstName: "",
    lastName: "",
    gender: null,
  });
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [ageRangeError, setaAgeRangeError] = useState(false);
  const [genderError, setGenderError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    try {
      if (mode === "view") {
        if (info) {
          setDetails({
            firstName: info.firstName,
            lastName: info.lastName,
            gender: info.gender,
            ageRange: info.ageRange,
            department: info.department,
          });
        }
      } else {
        if (userData) {
          let ageValue = null;
          for (let age in AGE_GROUP_MAP) {
            if (
              AGE_GROUP_MAP[age].toLowerCase() ===
              userData.userInformation.ageRange
            ) {
              ageValue = age;
            }
          }
          setDetails({
            firstName: userData.userInformation.firstName,
            lastName: userData.userInformation.lastName,
            gender: userData.userInformation.gender,
            ageRange: ageValue,
            department: userData.userInformation.department,
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [info, mode, userData]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    let ageKey;
    let departmentValue;
    if (name === "department") {
      departmentValue =
        companyDepartments.filter(
          (item) => item.departmentDisplayName === value
        )[0]?.departmentPublicId ?? "";
    }
    if (name === "ageRange") {
      for (let age in AGE_GROUP_MAP) {
        if (AGE_GROUP_MAP[age] === value) {
          ageKey = age;
        }
      }
    }
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [name]:
          name === "ageRange"
            ? ageKey
            : name === "department"
            ? departmentValue
            : value,
      };
    });
  };

  const handleSave = async () => {
    console.log("handle save --> ", details);
    try {
      if (
        details.firstName !== "" &&
        details.firstName.replace(/\s/g, "").length > 0
      ) {
        setFirstNameError(false);
        if (
          details.lastName !== "" &&
          details.lastName.replace(/\s/g, "").length > 0
        ) {
          setLastNameError(false);
          if (!!details.gender) {
            setGenderError(false);
            if (details.ageRange) {
              setaAgeRangeError(false);
              if (details.department) {
                setDepartmentError(false);
                if (
                  userData.userInformation.firstName.split(" ").join("") !==
                    details.firstName.split(" ").join("") ||
                  userData.userInformation.lastName.split(" ").join("") !==
                    details.lastName.split(" ").join("") ||
                  userData.userInformation.gender !== details.gender ||
                  userData.userInformation.department !== details.department ||
                  userData.userInformation.ageRange !==
                    AGE_GROUP_MAP[details.ageRange].toLowerCase()
                ) {
                  setLoading(true);
                  let updateUserProfileResult = await updateUserProfile(
                    details
                  );
                  if (updateUserProfileResult) {
                    console.log(updateUserProfileResult);
                    var current = {
                      ...userData,
                      userInformation: updateUserProfileResult.data.user,
                    };
                    setUserData(current);
                    setLoading(false);
                    setMessage("Profile updated successfully!");
                    setSeverity("success");
                    setAlertOpen(true);
                    handleEditDone();
                  }
                } else {
                  console.log("nothing changed!");
                  handleEditDone();
                }
              } else {
                setDepartmentError(true);
              }
            } else {
              setaAgeRangeError(true);
            }
          } else {
            setGenderError(true);
          }
        } else {
          setLastNameError(true);
        }
      } else {
        setFirstNameError(true);
      }
    } catch (err) {
      console.log(err.message);
      setMessage(err);
      setSeverity("error");
      setAlertOpen(true);
      setLoading(false);
      handleEditDone();
    }
  };

  const updateUserProfile = async (data) => {
    try {
      const token = localStorage.getItem("bhyve-token");
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/me`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const getDepartment = (depId) => {
    let value = "";
    // console.log('getDepartment', companyDepartments)
    const dep = companyDepartments.filter(
      (item) => item.departmentPublicId === depId
    );
    if (dep.length) {
      value = dep[0].departmentDisplayName;
    }
    return value;
  };

  const genderConstants = [
    { name: "Select Gender", value: "" },
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Other", value: "other" },
  ];

  const getAgeRange = (ageKey) => {
    let value = "";
    // console.log('getAgeRange', ageKey)
    value = AGE_GROUP_MAP[ageKey];
    return value;
  };

  return mode === "view" ? (
    <Paper className="skillCard-container" style={{ marginTop: "30px" }}>
      <div style={{ width: "100%", padding: "10px 10px" }}>
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid rgba(112, 112, 112,0.3)",
            lineHeight: "1.5",
          }}
        >
          <span style={{ padding: "8px 0 3px 0" }} className="skill-card-title">
            Basic Information
          </span>
        </Typography>
        <br />
        <Info
          icon={<img src={gender} alt="gender" width="23" height="23" />}
          label="Gender"
          data={details.gender}
        />
      </div>
    </Paper>
  ) : (
    <Fade in={true} timeout={2000}>
      <Paper className="skillCard-container">
        <Snackbar
          Open={alertOpen}
          severity={severity}
          alert={message}
          handleClose={() => setAlertOpen(false)}
        />
        <div style={{ width: "100%", padding: "10px 10px" }}>
          <Typography
            variant="subtitle1"
            style={{
              borderBottom: "1px solid rgba(112, 112, 112,0.3)",
              lineHeight: "1.5",
              textAlign: "left",
              marginBottom: "5px",
            }}
          >
            <span
              style={{ padding: "8px 0 3px 0" }}
              className="skill-card-title"
            >
              Your Profile
            </span>
          </Typography>
          <InfoEdit
            label="First Name"
            type="text"
            placeholder="required*"
            error={firstNameError}
            value={details.firstName}
            name="firstName"
            onChange={handleChange}
            icon={<PersonOutlineIcon />}
          />
          <InfoEdit
            label="Last Name"
            type="text"
            placeholder="required*"
            error={lastNameError}
            value={details.lastName}
            name="lastName"
            onChange={handleChange}
            icon={<PersonOutlineIcon />}
          />
          <InfoEdit
            label="Gender"
            type="select"
            value={details.gender}
            name="gender"
            onChange={handleChange}
            //options={["Male", "Female", "Other"]}
            options={genderConstants}
            error={genderError}
            forGenderSelection={true}
            icon={<img src={gender} alt="gender" width="23" height="23" />}
          />
          <InfoEdit
            label="Department"
            type="select"
            value={getDepartment(details.department)}
            name="department"
            onChange={handleChange}
            options={[
              "Select department",
              ...companyDepartments.map((item) => item.departmentDisplayName),
            ]}
            error={departmentError}
            icon={<WorkOutlineOutlinedIcon />}
          />
          <InfoEdit
            label="Age Range"
            type="select"
            value={getAgeRange(details.ageRange)}
            name="ageRange"
            onChange={handleChange}
            error={ageRangeError}
            options={["Select age range", ...Object.values(AGE_GROUP_MAP)]}
            icon={<PeopleIcon />}
          />
          {loading && <CircularProgress disableShrink />}
          {!loading && (
            <div
              style={{ width: "100%", textAlign: "left", marginTop: "10px" }}
            >
              <Button className="edit-btn" onClick={handleSave}>
                Done
              </Button>
            </div>
          )}
        </div>
      </Paper>
    </Fade>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation);
