/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const messagesByRoomIds = /* GraphQL */ `
  subscription MessagesByRoomIds($roomId: String!) {
    messagesByRoomIds(roomId: $roomId) {
      id
      author
      body
      createdAt
      updatedAt
      roomId
      contentType
      companyPublicId
      attachmentKey
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      author
      body
      createdAt
      updatedAt
      roomId
      contentType
      companyPublicId
      attachmentKey
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      author
      body
      createdAt
      updatedAt
      roomId
      contentType
      companyPublicId
      attachmentKey
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      author
      body
      createdAt
      updatedAt
      roomId
      contentType
      companyPublicId
      attachmentKey
    }
  }
`;
