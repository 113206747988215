import cmsAxios from '../../../axios/authAxios';

export const FETCH_TRENDING_ARTICLES = "FETCH TRENDING ARTICLES";
const fetchTrendingArticle = () => dispatch => {
    
    cmsAxios.get("cms/article/trending").then(response => {
        dispatch({ type: FETCH_TRENDING_ARTICLES, payload:response.data });
    })
}

export default fetchTrendingArticle
