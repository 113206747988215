import cmsAxios from '../../../axios/authAxios';

export const FETCH_BOOKMARKED_ARTICLES = "FETCH BOOKMARKED ARTICLES";
const fetchBookmarkArticle = () => dispatch => {
    cmsAxios.get("cms/article/bookmarked").then(response => {
        console.log(response)
        dispatch({ type: FETCH_BOOKMARKED_ARTICLES, payload:response.data });
    })
}

export default fetchBookmarkArticle
