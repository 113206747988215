/*jshint esversion:9*/

import React from 'react';
import {Typography} from '@material-ui/core';
import RankImg from '../../Assets/rank-logo.png';
//import './userPoints-style.scss';

function UserRank({user,rank}){
 

  return(
    <div className='points-container'>
      <div style={{width:'100%',textAlign:'center'}}>
        <img alt='rank' src={RankImg} className='points-img'/>
      </div>
      <br/>
      <Typography variant='h5' className='profile-content' style={{fontWeight:'bold'}}>{rank}</Typography>
      <Typography variant='subtitle2' className='profile-content muted'>Rank</Typography>
    </div>
  )
}



export default UserRank;
