/*jshint esversion:9*/
/*jshint -W087*/

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./home-style.scss";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import ProgressBar from "../../../Components/SubComponents/progressBar/progressBar-component";
import WelcomeText from "../../../Components/SubComponents/createProfile-welcomeText/welcomeText";
import TitleText from "../../../Components/SubComponents/createProfile-titleText/titleText";
import SubText from "../../../Components/SubComponents/createProfile-subText/subText";
import CreateProfile, { AGE_GROUP_MAP } from "../../../Components/createProfile/createProfile-component";
import PrimarySkills from "../../../Components/skillSection/primarySkills-component";
import SkillTab from "../../../Components/SubComponents/skillTab/skillTab-new-component";
import Interest from "../../../Components/interestSection/interest-component";
import SkillCardCompletedModal from "../../../Components/SubComponents/skillCard-completed-modal/skillCard-completed-modal";
import Snackbar from "../../../Components/SubComponents/snackbar/snackbar";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectCompanyInformation,
} from "../../../redux/user/user-selector";
import { selectCategories } from "../../../redux/categories/categories-selector";
import {
  setBackgroundColor,
  setUserData,
  setLocationData,
} from "../../../redux/user/user-actions";
import Loader from "../../../Components/SubComponents/loader/loader-component";
import Logout from "../../../logout";
import axios from "axios";
import moment from "moment";
import "./home-style.scss";
import Logo from "../../../Assets/logo.png";

const CreateYourProfile = ({
  history,
  userData,
  companyDetails,
  categories,
  setBackgroundColor,
  setUserData,
  setLocationData,
}) => {
  const [step, setStep] = useState(parseFloat(localStorage.getItem("step")));
  const [skillCardCompleted, setSkillCardCompleted] = useState(false);
  const [background, setBackground] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [ageRangeError, setAgeRangeError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    firstName: "",
    lastName: "",
    department: "",
    userLocation: null,
    gender: "",
    ageRange:""
  });
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [interestsFromSkills, setInterestsFromSkills] = useState(false);

  const token = localStorage.getItem("bhyve-token");

  const [disableNextButton, setDisableNextButton] = useState(false);

  const allowNextBtnOnClick = (skills) => {
    if (step === 1) {
      if (skills.length >= 5) {
        setDisableNextButton(false);
      } else {
        setDisableNextButton(true);
      }
    } else {
      setDisableNextButton(false);
    }
  };

  useEffect(() => {
    allowNextBtnOnClick(selectedSkills);
  }, [selectedSkills]);

  //This useEffect checks the details of the user, to land him/her on the desired step in the process.
  //It gets called on initial render as well as when userData is updated
  useEffect(() => {
    try {
      if (userData) {
        setProfileDetails((prevValue) => {
          return {
            ...prevValue,
            firstName: userData.userInformation.firstName,
            lastName: userData.userInformation.lastName,
            title: userData.userInformation.title,
            gender: userData.userInformation.gender,
            ageRange: userData.userInformation.ageRange,
            department: userData.userInformation.department
          };
        });
        setImageUrl(userData.userInformation.profilePhotoUrl);
        setImageKey(userData.userInformation.profilePhotoKey);
        if (userData.userInformation.userLocation) {
          if (userData.userInformation.userSkillsSet) {
            if (userData.userInformation.profileCompleted) {
              setSkillCardCompleted(true);
            } else {
              localStorage.setItem("step", "2");
              setStep(2);
            }
          } else {
            localStorage.setItem("step", "1");
            setStep(1);
          }
        } else {
          localStorage.setItem("step", "0");
          setStep(0);
        }
      }
    } catch (error) {
      console.log(error.message);
    }

    return () => {
      window.scrollTo(0, 0);
    };
  }, [userData]);

  useEffect(() => {
    if (companyDetails) {
      setProfileDetails((prevValue) => {
        return {
          ...prevValue,
          userLocation: userData.userInformation.userLocation
            ? userData.userInformation.userLocation
            : companyDetails.addresses[0].companyAddressPublicId,
        };
      });
      // if(companyDetails.companyBackgroundColor) {
      //   document.body.style.backgroundColor = companyDetails.companyBackgroundColor;
      //   setBackgroundColor(
      //     `0 8px 40px -12px
      //       ${companyDetails.companyBackgroundColor === '#FFFAEB' ?
      //         'rgba(255,204,51,0.4)'
      //       :
      //         'rgba(235,248,255,0.4)'
      //     }`
      //   );
      //   setBackground(companyDetails.companyBackgroundColor);
      //   // console.log(companyDetails.companyBackgroundColor);
      //   // for(let i = 0; i < document.body.getElementsByClassName('MuiPaper-root').length; i++) {
      //   //   console.log(document.body.getElementsByClassName('MuiPaper-root')[i]);
      //   //   document.body.getElementsByClassName('MuiPaper-root')[i].style.boxShadow = `0 8px 40px -12px ${companyDetails.companyBackgroundColor === '#FFFAEB' ? 'rgba(255,204,51,0.4)' : 'rgba(235,248,255,0.4)'}`;
      //   // }
      //   // console.log(document.body.getElementsByClassName('MuiCard-root'));
      //   // for(let i = 0; i < document.body.getElementsByClassName('MuiCard-root').length; i++) {
      //   //   console.log(document.body.getElementsByClassName('MuiCard-root')[i]);
      //   //   document.body.getElementsByClassName('MuiCard-root')[i].style.boxShadow = `0 8px 40px -12px ${companyDetails.companyBackgroundColor === '#FFFAEB' ? 'rgba(255,204,51,0.4)' : 'rgba(235,248,255,0.4)'}`;
      //   // }
      // }
    }
  }, [companyDetails]);

  const updateUserProfile = async (data) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/me`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const addUserSkills = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/skills`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const updateUserSkills = async (data) => {
    try {
      const token = localStorage.getItem("bhyve-token");
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/skills`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const setProfileCompletion = async () => {
    try {
      const data = {
        profileCompleted: true,
      };
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/me/complete-profile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const addUserInterest = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/interests`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const updateInterests = async (data) => {
    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/interests`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };
  //to move the user forward in the process when userData is not updated
  const handleNext = async () => {
    try {
      const currentStep = parseInt(localStorage.getItem("step"));
      if (currentStep === 0) {
        if (
          profileDetails.userLocation !== null &&
          profileDetails.userLocation !== "None" &&
          profileDetails.userLocation !== ""
        ) {
          setLocationError(false);
          if (
            profileDetails.firstName !== "" &&
            profileDetails.firstName.replace(/\s/g, "").length > 0
          ) {
            setFirstNameError(false);
            if (
              profileDetails.lastName !== "" &&
              profileDetails.lastName.replace(/\s/g, "").length > 0
            ) {
              setLastNameError(false);
              if(profileDetails.ageRange !== '') {
                setAgeRangeError(false)
                if(profileDetails.department !== '') {
                  setDepartmentError(false)
                  try {
                    //checks whether there is any change in the details or not
                    let ageRange
                    for (let age in AGE_GROUP_MAP){
                      if (AGE_GROUP_MAP[age].toLowerCase() === profileDetails.ageRange.toLowerCase()) {
                        ageRange = age
                      }
                    }
                    const ProfileData = {
                      ...profileDetails,
                      ageRange
                    }
                    if (
                      !userData.userInformation.userLocation ||
                      userData.userInformation.userLocation !== ProfileData.userLocation ||
                      userData.userInformation.firstName !== ProfileData.firstName ||
                      userData.userInformation.lastName !== ProfileData.lastName ||
                      userData.userInformation.title !== ProfileData.title ||
                      userData.userInformation.gender !== ProfileData.gender ||
                      userData.userInformation.department !== ProfileData.department ||
                      userData.userInformation.ageRange !== ageRange ||
                      userData.userInformation.profilePhotoKey !== imageKey ||
                      userData.userInformation.profilePhotoUrl !== imageUrl
                    ) {
                      setLoading(true);
                      try {
                        let updateUserProfileResult = await updateUserProfile(ProfileData);
                        if (updateUserProfileResult) {
                          let current = {};
                          if (imageKey !== "" || imageUrl !== "") {
                            current = {
                              userInformation: {
                                ...updateUserProfileResult.data.user,
                                profilePhotoKey: imageKey,
                                profilePhotoUrl: imageUrl,
                              },
                            };
                          } else {
                            current = {
                              userInformation: updateUserProfileResult.data.user,
                            };
                          }
    
                          setUserData(current);
                          if (updateUserProfileResult.data.userLocation) {
                            setLocationData(
                              updateUserProfileResult.data.userLocation
                            );
                          }
                          setLoading(false);
                          // return;
                        }
                      } catch (err) {
                        console.log(err);
                        if (err.response && err.response.status === 401) {
                          history.push("/logout");
                        }
                        setLoading(false);
                        return;
                      }
                    }
                    // handleNext(details);
                  } catch (err) {
                    console.log(err);
                    if (err.response && err.response.status === 401) {
                      history.push("/logout");
                    }
                    setLoading(false);
                    return;
                  }
                } else {
                  setDepartmentError(true)
                  return
                }
              } else {
                setAgeRangeError(true)
                return
              }
            } else {
              setLastNameError(true);
              return;
            }
          } else {
            setFirstNameError(true);
            return;
          }
        } else {
          setLocationError(true);
          return;
        }
      }

      if (currentStep === 1) {
        if (selectedSkills.length > 4) {
          let identical = false;
          if (
            userData.userInformation.userSkillsSet &&
            userData.userInformation.userSkillsSet.userSkills
          ) {
            //check if there is any change in the already saved userSkillSet
            if (
              selectedSkills.length ===
              userData.userInformation.userSkillsSet.userSkills.length
            ) {
              selectedSkills.forEach((item, index) => {
                let identicalItem =
                  userData.userInformation.userSkillsSet.userSkills.find(
                    (i) => i.skillPublicId === item.skillPublicId
                  );
                if (identicalItem) {
                  if (JSON.stringify(identicalItem) === JSON.stringify(item)) {
                    identical = true;
                  } else {
                    identical = false;
                  }
                } else {
                  identical = false;
                }
              });
            }
          }
          if (!identical) {
            const object = {
              userSkills: selectedSkills,
            };
            setLoading(true);
            if (
              userData.userInformation.userSkillsSet &&
              userData.userInformation.userSkillsSet.userSkills
            ) {
              try {
                let updateUserSkillsResult = await updateUserSkills(object);
                if (updateUserSkillsResult) {
                  const current = {
                    userInformation: updateUserSkillsResult.data,
                  };
                  setUserData(current);
                  setLoading(false);
                }
              } catch (err) {
                console.log(err);
                if (err.response && err.response.status === 401) {
                  history.push("/logout");
                }
                setLoading(false);
              }
            } else {
              try {
                let addUserSkillsResult = await addUserSkills(object);
                if (addUserSkillsResult) {
                  const current = {
                    userInformation: addUserSkillsResult.data,
                  };
                  setUserData(current);
                  setLoading(false);
                }
              } catch (err) {
                console.log(err);
                if (err.response && err.response.status === 401) {
                  history.push("/logout");
                }
                setLoading(false);
              }
            }
          }
        } else {
          setAlert("Please select atleast 5 skills");
          setSeverity("warning");
          setAlertOpen(true);
          return;
        }
      }

      if (currentStep === 2) {
        if (selectedInterests.length !== 0) {
          setLoading(true);
          let skills = [];
          selectedInterests.forEach((item, index) => {
            categories.skillCategories.forEach((category) => {
              const exist = category.skills.find(
                (skill) => skill.skillName === item
              );
              if (exist) {
                skills.push({
                  skillDisplayName: exist.skillDisplayName,
                  skillName: exist.skillName,
                  skillPublicId: exist.skillPublicId,
                  priority: index + 1,
                });
              }
            });
          });
          // data.map((item,index) => skills.push({skillName:item.skillName,skillPublicId:item.skillPublicId,priority:index+1}));
          const object = {
            userInterests: skills,
          };
          try {
            if (userData.userInformation.userInterestSet) {
              let updateInterestsResult = await updateInterests(object);
              if (updateInterestsResult) {
                setUserData({ userInformation: updateInterestsResult.data });
                // handleNext(true);
              }
              if (!userData.userInformation.profileCompleted) {
                let profileCompletionResult = await setProfileCompletion();
                let current = {
                  userInformation: profileCompletionResult.data,
                };
                setUserData(current);
              }
              setLoading(false);
              setSkillCardCompleted(true);
            } else {
              let addInterestResult = await addUserInterest(object);
              if (addInterestResult) {
                let profileCompletionResult = await setProfileCompletion();
                let current = {
                  userInformation: profileCompletionResult.data,
                };
                setUserData(current);
                setLoading(false);
                setSkillCardCompleted(true);
              }
            }
            // handleNext(selectedSkills);
          } catch (error) {
            console.log(error);
            setLoading(false);
            if (error.response && error.response.status === 401) {
              history.push("/logout");
            }
          }
        } else {
          setAlert("Select atleast one skill !");
          setSeverity("warning");
          setAlertOpen(true);
        }

        return;
      }

      localStorage.setItem("step", currentStep + 1);
      setStep(currentStep + 1);
    } catch (error) {
      console.log(error.message);
      if (error.response && error.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  //to move user back in the process
  const handleBack = () => {
    try {
      const currentStep = parseFloat(localStorage.getItem("step"));
      if (currentStep > 0) {
        localStorage.setItem("step", currentStep - 1);
        setStep(currentStep - 1);
      }
      return;
    } catch (error) {
      console.log(error.message);
    }
  };

  //called from the completed-profile modal
  const headToDashboard = () => {
    setSkillCardCompleted(false);
    history.push("/User/Dashboard");
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    console.log(name, ' ---> ', value);
    setProfileDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const setNewSkills = (skills) => {
    allowNextBtnOnClick(skills);
    setSelectedSkills(skills);
  };

  const setNewInterests = (interests) => {
    console.log(interests);
    setSelectedInterests(interests);
  };

  const handleCheck = (e) => {
    if (interestsFromSkills) {
      setInterestsFromSkills(false);
    } else {
      setInterestsFromSkills(true);
    }
  };

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "top",
    horizontal: "center",
  });

  return (
    <div className="create-profile-container">
      <Loader open={loading} />
      <div className="create-profile-top-grid">
        <div className="logo-container">
          {!!companyDetails && companyDetails.companyLogoDetails.length > 0 ? (
            <img
              src={
                companyDetails &&
                companyDetails.companyLogoDetails[
                  companyDetails.companyLogoDetails.length - 1
                ].imageUrl
              }
              alt="logo"
            />
          ) : (
            <img src={Logo} alt="logo" />
          )}
        </div>
        <div className="create-profile-top-right">
          <div>
            {step === 0 ? (
              <h2>Let's build your profile</h2>
            ) : step === 1 ? (
              <h2>Pick your top skills from the list</h2>
            ) : (
              <h2>Pick your Interests and you're done</h2>
            )}

            {step === 0 ? (
              <p>You can scroll or just click next when you're done</p>
            ) : step === 1 ? (
              <p>Please select atleast 5 skills</p>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={interestsFromSkills}
                    onChange={handleCheck}
                    name="checkedF"
                    style={{ color: "#66CCFF" }}
                  />
                }
                label="Select same options from my skills"
              />
            )}
          </div>
          <div className="button-container">
            {step > 0 && (
              <Button className="back-btn" onClick={handleBack}>
                {step === 1 ? "Back to Profile" : "Back to Skills"}
              </Button>
            )}
            <Button
              variant="contained"
              className="nxt-btn"
              onClick={handleNext}
              style={{ backgroundColor: "black" }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <div className="create-profile-bottom-grid">
        <ProgressBar step={step} background={background} />
        <div className="create-profile-bottom-right">
          {step === 0 ? (
            <CreateProfile
              userDetails={{
                ...profileDetails,
                profilePhotoUrl: imageUrl,
                profilePhotoKey: imageKey,
              }}
              addressList={companyDetails && companyDetails.addresses}
              companyDetails={companyDetails}
              handleNext={handleNext}
              handleChange={handleProfileChange}
              setProfileImageUrl={(value) => setImageUrl(value)}
              setProfileImageKey={(value) => setImageKey(value)}
              locationError={locationError}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              ageRangeError={ageRangeError}
              departmentError={departmentError}
            />
          ) : step === 1 ? (
            <SkillTab
              userCategories={
                userData &&
                userData.userInformation.userSkillsSet &&
                userData.userInformation.userSkillsSet.userSkills
              }
              allCategoriesAndSkills={categories && categories.skillCategories}
              setNewSkillList={setNewSkills}
            />
          ) : (
            <Interest
              skillCategories={categories}
              profileCompletion={
                userData && userData.userInformation.profileCompleted
              }
              userSkills={
                userData &&
                userData.userInformation.userSkillsSet &&
                userData.userInformation.userSkillsSet.userSkills
              }
              selectedInterests={
                userData &&
                userData["userInformation"].userInterestSet &&
                userData["userInformation"].userInterestSet.userInterests
              }
              setNewInterests={setNewInterests}
              handleNext={handleNext}
              interestsFromSkills={interestsFromSkills}
            />
          )}
        </div>
      </div>
      <SkillCardCompletedModal
        open={skillCardCompleted}
        handleNext={() => {
          headToDashboard();
        }}
        handleClose={() => setSkillCardCompleted(false)}
      />
      <Snackbar
        anchorOrigin={snackbarPosition}
        Open={alertOpen}
        alert={alert}
        severity={severity}
        handleClose={() => setAlertOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  companyDetails: selectCompanyInformation,
  categories: selectCategories,
});

const mapDispatchToProps = (dispatch) => ({
  setBackgroundColor: (color) => dispatch(setBackgroundColor(color)),
  setUserData: (object) => dispatch(setUserData(object)),
  setLocationData: (object) => dispatch(setLocationData(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateYourProfile)
);

{
  /* <ProgressBar step={step} background={background}/>
<br/>
<br/>
<WelcomeText text={step===0?'Welcome!':step===1?'Add skills to your profile':step===1.5?'Expand Your Skill Card':'Customize Your Interests '}/>
<br/>
<TitleText text={step===0?"Let's create your profile":step===1?'Select your Top Skills':step===1.5?'Select your secondary skills':'What would you like to learn ?'}/>
<br/>
<SubText text={step===0?'Edit or verify the details below to create your profile':step===1?'Pick the skills which you can teach your peers':step===1.5?'These are skills you may not use everyday, but can help or guide your peers in':'Select skills which you want to learn from your colleagues'}/>
<br/>
<br/>
{step===0?
  <CreateProfile userDetails={userData&&userData['userInformation']} addressList={companyDetails&&companyDetails.addresses} handleNext={handleNext}/>:
  step < 2?
  <PrimarySkills skillCategories={categories} handleNext={handleNext}  userSkillSet={userData && userData.userInformation.userSkillsSet && userData.userInformation.userSkillsSet.userSkills} />:
  <Interest skillCategories={categories} profileCompletion={userData && userData.userInformation.profileCompleted} selectedInterests={userData&&userData['userInformation'].userInterestSet&&userData['userInformation'].userInterestSet.userInterests} handleNext={handleNext}/>
}

<br/>
<br/>
<Button style={{display:step===0?'none':''}}  onClick={handleBack}>Back</Button>
<br/>
<br/>
<SkillCardCompletedModal open={skillCardCompleted} handleNext={()=>{headToDashboard()}} handleClose={()=>setSkillCardCompleted(false)}/> */
}
