import React,{useEffect,useState} from 'react';
import {withRouter} from 'react-router-dom';
import Loader from '../Components/SubComponents/loader/loader-component';
import {RoutingUrlForLoginApp} from "./routing-url";
import Logout from "../logout";
const createGuest = require('cross-domain-storage/guest');


function Authenticate({match,history}){
    

    useEffect(()=>{
        try {
            const routingUrlForLoginApp = RoutingUrlForLoginApp();
            const token = createGuest(`${routingUrlForLoginApp}/accessStorage`);
            token.get('bhyve-token', function(error, value) {
                console.log('error ', error);
                console.log('token ', value);
                token.close();
                let currentToken = value;
                let tokenExist = localStorage.getItem('bhyve-token');
                if((currentToken && !tokenExist)||(currentToken !== tokenExist)){
                    localStorage.setItem('bhyve-token',currentToken);
                }
                history.push('/');
            });
        } catch (error) {
            console.log(error);
            Logout();
        }
     
    },[])

  
    return(
        <div>
            <Loader open={true} text='Authenticating'/>
        </div>
    )
}

export default withRouter(Authenticate);

