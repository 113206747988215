import cmsAxios from '../../../axios/authAxios';

export const FETCH_DRAFT_ARTICLES = "FETCH DRAFT ARTICLES";
const fetchDraftArticle = () => dispatch => {
    cmsAxios.get("cms/article/me/draft").then(response => {
        dispatch({ type: FETCH_DRAFT_ARTICLES, payload: response.data });
    })
}

export default fetchDraftArticle
