/*jshint esversion:9*/

import React from "react";
import { makeStyles, Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import Tour from "../../../tour";
import "./recomm-collaborator-card-style.scss";
import getDepartmentName from "../../../utils/getDepartmentName";

{
  /* <CardMedia
          className='card-img-container'
          image={img}
          component='image'
        /> */
}

function RecommCollaborator({
  firstName,
  lastName,
  img,
  skillMatched,
  navigateToUser,
  collaborate,
  userTitle,
  index,
  locationDetails,
  companyDepartments,
}) {
  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    actionArea: {
      height: "calc(100% - 35.29px)",
    },
  });

  function capitalize(input) {
    try {
      let word = input;
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              "&" +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  const classes = useStyles();

  let userAgentString = navigator.userAgent;
  return (
    <div
      className={`${
        index === 0 && `first-collaborator-container`
      } collaborator-container`}
      style={{
        borderRight: (index === 0 || index === 1) && "1px solid #B4B4B4",
      }}
    >
      <div
        className="collaborator-img-container"
        onClick={() => navigateToUser()}
      >
        {img !== "" && (
          <Avatar src={img} alt="dp" className="collaborator-img-avatar" />
        )}
        {img === "" && (
          <Typography
            variant="h3"
            className="initial-container"
            style={{
              textAlign: "center",
              color: "white",
              background: "#FFCC33",
              fontWeight: "400",
            }}
          >
            {`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
          </Typography>
        )}
      </div>
      <Typography
        variant="subtitle1"
        className="collaborator-name"
        onClick={() => navigateToUser()}
      >
        {`${firstName} ${
          lastName.length > 8 ? lastName.slice(0, 1) : lastName
        }`}
      </Typography>
      {!!userTitle ? (
        <div className="collaborator-other-info">
          <p className="collaborator-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="18"
              viewBox="0 0 23 23"
              style={{ marginTop: "3px" }}
            >
              <g id="outline-work_outline-24px" opacity="0.489">
                <path
                  id="Path_2077"
                  data-name="Path 2077"
                  d="M0,0H23V23H0Z"
                  fill="none"
                />
                <path
                  id="Path_2078"
                  data-name="Path 2078"
                  d="M13.4,5.789V3.895H9.6V5.789ZM3.9,7.684V18.105H19.1V7.684ZM19.1,5.789A1.891,1.891,0,0,1,21,7.684V18.105A1.891,1.891,0,0,1,19.1,20H3.9A1.891,1.891,0,0,1,2,18.105L2.01,7.684A1.883,1.883,0,0,1,3.9,5.789H7.7V3.895A1.891,1.891,0,0,1,9.6,2h3.8a1.891,1.891,0,0,1,1.9,1.895V5.789Z"
                />
              </g>
            </svg>
            <Tooltip title={userTitle} aria-label="add" placement="top">
              <span>{getDepartmentName(userTitle, companyDepartments)}</span>
            </Tooltip>
          </p>
        </div>
      ) : (
        <div className="collaborator-other-info">&nbsp;</div>
      )}
      <div className="collaborator-other-info">
        <p className="collaborator-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 18 18"
            style={{ marginTop: "3px" }}
          >
            <g id="outline-location_on-24px" opacity="0.5">
              <path
                id="Path_2071"
                data-name="Path 2071"
                d="M0,0H18V18H0Z"
                fill="none"
              />
              <path
                id="Path_2072"
                data-name="Path 2072"
                d="M9.917,2A5.038,5.038,0,0,0,5,7.157c0,3.868,4.917,9.577,4.917,9.577s4.917-5.709,4.917-9.577A5.038,5.038,0,0,0,9.917,2ZM6.4,7.157A3.6,3.6,0,0,1,9.917,3.473,3.6,3.6,0,0,1,13.43,7.157c0,2.122-2.023,5.3-3.512,7.279C8.456,12.468,6.4,9.256,6.4,7.157Z"
                transform="translate(-0.917 -0.367)"
              />
              <circle
                id="Ellipse_11"
                data-name="Ellipse 11"
                cx="2.041"
                cy="2.041"
                r="2.041"
                transform="translate(6.959 4.761)"
              />
            </g>
          </svg>
          <Tooltip
            title={
              !locationDetails.streetAddress ||
              locationDetails.streetAddress.length === 0
                ? `${locationDetails.city}, ${locationDetails.country}`
                : `${locationDetails.streetAddress}, ${locationDetails.city}`
            }
            aria-label="add"
            placement="top"
          >
            <span>
              {!locationDetails.streetAddress ||
              locationDetails.streetAddress.length === 0
                ? `${locationDetails.city}, ${locationDetails.country}`
                : `${locationDetails.streetAddress}, ${locationDetails.city}`}
            </span>
          </Tooltip>
        </p>
      </div>
      <div className="collaborator-other-info">
        <p className="collaborator-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            style={{ marginTop: "3px" }}
          >
            <g
              id="Skills_icon"
              data-name="Skills icon"
              transform="translate(0 4)"
              opacity="0.504"
            >
              <g id="_2469408602128" transform="translate(0 -4)">
                <path
                  id="Path_2139"
                  data-name="Path 2139"
                  d="M0,0H24V24H0Z"
                  fill="rgba(0,0,0,0)"
                  fill-rule="evenodd"
                />
                <g
                  id="Group_87"
                  data-name="Group 87"
                  transform="translate(2.199 2.501)"
                >
                  <path
                    id="Path_2140"
                    data-name="Path 2140"
                    d="M14.829,14.19l1.548,2.634,3.071.294a.688.688,0,0,1,.663.736.622.622,0,0,1-.221.412l-2.367,2.053.573,2.847a.683.683,0,0,1-.606.78.85.85,0,0,1-.475-.066l-2.932-1.4-2.662,1.31a.8.8,0,0,1-1.016-.272.664.664,0,0,1-.09-.316h0v-.331a.989.989,0,0,1,.016-.154l.557-2.487L8.547,18.274a.626.626,0,0,1-.041-.949.792.792,0,0,1,.483-.213l3.047-.361L13.5,14.2a.791.791,0,0,1,1.007-.294.718.718,0,0,1,.319.287Zm.409,3.576-1.065-1.81-.975,1.7a.753.753,0,0,1-.581.383l-1.892.221,1.45,1.221a.63.63,0,0,1,.262.647l-.409,1.832,1.654-.809a.836.836,0,0,1,.737-.022l1.933.92-.36-1.817A.626.626,0,0,1,16.2,19.6L17.695,18.3l-1.851-.177a.76.76,0,0,1-.606-.361Z"
                    transform="translate(-4.394 -4.956)"
                  />
                  <path
                    id="Path_2141"
                    data-name="Path 2141"
                    d="M20.142,3.4h4.341a.669.669,0,0,1,.677.67.611.611,0,0,1-.088.324l-3.833,8.247a.658.658,0,0,1-.7.383l-1.155-.162a.679.679,0,0,1-.515-.39L17.317,9.2a.653.653,0,0,1,.015-.6l2.2-4.812a.67.67,0,0,1,.611-.39h0Zm3.289,1.339H20.569L18.663,8.9l1.273,2.678.294.044,3.2-6.886Z"
                    transform="translate(-5.558 -3.4)"
                  />
                  <path
                    id="Path_2142"
                    data-name="Path 2142"
                    d="M3.659,3.4H8.007a.674.674,0,0,1,.64.464l2.1,4.863a.657.657,0,0,1-.007.552h0l-1.471,3.2a.68.68,0,0,1-.589.39l-1.295.081a.668.668,0,0,1-.662-.427L3.048,4.342a.663.663,0,0,1,.338-.883A.607.607,0,0,1,3.659,3.4h0ZM7.566,4.739H4.7l3.075,6.842.449-.029L9.405,8.984,7.566,4.739Z"
                    transform="translate(-2.988 -3.4)"
                  />
                </g>
              </g>
            </g>
          </svg>
          <Tooltip
            title={capitalize(skillMatched)}
            aria-label="add"
            placement="top"
          >
            <span>{capitalize(skillMatched)}</span>
          </Tooltip>
        </p>
      </div>
      <Button
        className="collaborator-btn"
        variant="contained"
        color="primary"
        startIcon={<ForumOutlinedIcon />}
        onClick={() => collaborate()}
      >
        Collaborate
      </Button>
    </div>
  );
}

export default RecommCollaborator;

// <Typography variant='body2' style={{color:'black'}}><svg style={{color:'black',marginRight:'2px'}} height="18" viewBox="0 0 192 192" width="18" xmlns="http://www.w3.org/2000/svg"><circle cx="96" cy="32" r="8"/><circle cx="184" cy="72" r="8"/><circle cx="8" cy="72" r="8"/><path d="m23.805 160h144.395l15.61-70.265a8 8 0 0 0 -12.248-8.391l-40.462 26.972-27.945-55.894a8 8 0 0 0 -14.31 0l-27.945 55.894-40.463-26.972a8 8 0 0 0 -12.247 8.391z"/><path d="m24 168v16a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-16z"/></svg>   3</Typography>

//       <Card className={classes.root} >
//   <CardActionArea onClick={()=>navigateToUser()} className={classes.actionArea} >
//   <div className='card-img-container'>
//     {img !== '' &&
//       <img src={img} alt='dp'  />
//     }
//     {img===''&&
//       <div style={{position:'absolute', width:'100%', height:'100%',paddingTop:'18%',textAlign:userAgentString.indexOf("Firefox")>-1?'-moz-center':'-webkit-center'}}>
//         <Typography variant='h3' className='initial-container' style={{textAlign:'center',paddingTop:'10%',color:'white',background:'#FFCC33',fontWeight:'400'}}>
//           {`${firstName.slice(0,1)}${lastName.slice(0,1)}`}
//         </Typography>
//       </div>
//     }
//   </div>
//     <CardContent className='card-content'>
//       <Typography gutterBottom variant="subtitle1" style={{fontWeight:'600',paddingBottom:'0',margin:'10px 0 10px 0', textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden'}}>
//         {`${firstName} ${lastName.length>8?lastName.slice(0,1):lastName}`}
//       </Typography>
//       <p className='card-content-info'>
//         <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 23 23" style={{marginTop:'5px'}}>
//           <g id="outline-work_outline-24px" opacity="0.489">
//             <path id="Path_2077" data-name="Path 2077" d="M0,0H23V23H0Z" fill="none"/>
//             <path id="Path_2078" data-name="Path 2078" d="M13.4,5.789V3.895H9.6V5.789ZM3.9,7.684V18.105H19.1V7.684ZM19.1,5.789A1.891,1.891,0,0,1,21,7.684V18.105A1.891,1.891,0,0,1,19.1,20H3.9A1.891,1.891,0,0,1,2,18.105L2.01,7.684A1.883,1.883,0,0,1,3.9,5.789H7.7V3.895A1.891,1.891,0,0,1,9.6,2h3.8a1.891,1.891,0,0,1,1.9,1.895V5.789Z"/>
//           </g>
//         </svg>
//         {userTitle.length <= 20 ?
//           <span style={{fontWeight:'500',paddingTop:'3px',fontSize:'15px!important',color:'black', textOverflow:'ellipsis',overflow:'hidden',maxWidth:'18ch',whiteSpace:'noWrap'}}>
//             {userTitle}
//           </span>
//           :
//           <Tooltip title={userTitle} aria-label="add" placement='top'>
//             <span style={{fontWeight:'500',paddingTop:'3px',fontSize:'15px!important',color:'black', textOverflow:'ellipsis',overflow:'hidden',maxWidth:'18ch',whiteSpace:'noWrap'}}>
//               {`${userTitle.slice(0,19)}...`}
//             </span>
//           </Tooltip>
//         }
//       </p>
//       <p className='card-content-info'>
// <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18" style={{marginTop:'5px'}}>
//   <g id="outline-location_on-24px" opacity="0.5">
//     <path id="Path_2071" data-name="Path 2071" d="M0,0H18V18H0Z" fill="none"/>
//     <path id="Path_2072" data-name="Path 2072" d="M9.917,2A5.038,5.038,0,0,0,5,7.157c0,3.868,4.917,9.577,4.917,9.577s4.917-5.709,4.917-9.577A5.038,5.038,0,0,0,9.917,2ZM6.4,7.157A3.6,3.6,0,0,1,9.917,3.473,3.6,3.6,0,0,1,13.43,7.157c0,2.122-2.023,5.3-3.512,7.279C8.456,12.468,6.4,9.256,6.4,7.157Z" transform="translate(-0.917 -0.367)"/>
//     <circle id="Ellipse_11" data-name="Ellipse 11" cx="2.041" cy="2.041" r="2.041" transform="translate(6.959 4.761)"/>
//   </g>
// </svg>
//         <span style={{fontWeight:'500',paddingTop:'3px',fontSize:'15px!important',color:'black', textOverflow:'ellipsis',whiteSpace:'noWrap',overflow:'hidden'}}>
//           {locationDetails.city}, {locationDetails.country}
//         </span>
//       </p>
//       <p className='card-content-info'>
// <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" style={{marginTop:'5px'}}>
//   <g id="Skills_icon" data-name="Skills icon" transform="translate(0 4)" opacity="0.504">
//     <g id="_2469408602128" transform="translate(0 -4)">
//       <path id="Path_2139" data-name="Path 2139" d="M0,0H24V24H0Z" fill="rgba(0,0,0,0)" fill-rule="evenodd"/>
//       <g id="Group_87" data-name="Group 87" transform="translate(2.199 2.501)">
//         <path id="Path_2140" data-name="Path 2140" d="M14.829,14.19l1.548,2.634,3.071.294a.688.688,0,0,1,.663.736.622.622,0,0,1-.221.412l-2.367,2.053.573,2.847a.683.683,0,0,1-.606.78.85.85,0,0,1-.475-.066l-2.932-1.4-2.662,1.31a.8.8,0,0,1-1.016-.272.664.664,0,0,1-.09-.316h0v-.331a.989.989,0,0,1,.016-.154l.557-2.487L8.547,18.274a.626.626,0,0,1-.041-.949.792.792,0,0,1,.483-.213l3.047-.361L13.5,14.2a.791.791,0,0,1,1.007-.294.718.718,0,0,1,.319.287Zm.409,3.576-1.065-1.81-.975,1.7a.753.753,0,0,1-.581.383l-1.892.221,1.45,1.221a.63.63,0,0,1,.262.647l-.409,1.832,1.654-.809a.836.836,0,0,1,.737-.022l1.933.92-.36-1.817A.626.626,0,0,1,16.2,19.6L17.695,18.3l-1.851-.177a.76.76,0,0,1-.606-.361Z" transform="translate(-4.394 -4.956)"/>
//         <path id="Path_2141" data-name="Path 2141" d="M20.142,3.4h4.341a.669.669,0,0,1,.677.67.611.611,0,0,1-.088.324l-3.833,8.247a.658.658,0,0,1-.7.383l-1.155-.162a.679.679,0,0,1-.515-.39L17.317,9.2a.653.653,0,0,1,.015-.6l2.2-4.812a.67.67,0,0,1,.611-.39h0Zm3.289,1.339H20.569L18.663,8.9l1.273,2.678.294.044,3.2-6.886Z" transform="translate(-5.558 -3.4)"/>
//         <path id="Path_2142" data-name="Path 2142" d="M3.659,3.4H8.007a.674.674,0,0,1,.64.464l2.1,4.863a.657.657,0,0,1-.007.552h0l-1.471,3.2a.68.68,0,0,1-.589.39l-1.295.081a.668.668,0,0,1-.662-.427L3.048,4.342a.663.663,0,0,1,.338-.883A.607.607,0,0,1,3.659,3.4h0ZM7.566,4.739H4.7l3.075,6.842.449-.029L9.405,8.984,7.566,4.739Z" transform="translate(-2.988 -3.4)"/>
//       </g>
//     </g>
//   </g>
// </svg>
//         {skillMatched.length <= 20 ?
//           <span style={{fontWeight:'500',paddingTop:'3px',fontSize:'15px!important',color:'black',textOverflow:'ellipsis',maxWidth:'18ch',whiteSpace:'noWrap'}}>
//             {skillMatched}
//           </span>
//           :
//           <Tooltip title={skillMatched} aria-label="add" placement='top'>
//             <span style={{fontWeight:'500',paddingTop:'3px',fontSize:'15px!important',color:'black',textOverflow:'ellipsis',maxWidth:'18ch',whiteSpace:'noWrap'}}>
//               {`${skillMatched.slice(0,19)}...`}
//             </span>
//           </Tooltip>
//         }
//       </p>
//     </CardContent>

//   </CardActionArea>
//   <CardActions className={`card-actions ${index === 0 && 'collab-tour'}`}>
//     <Button startIcon={<ForumOutlinedIcon/>} size="small"  onClick={()=>collaborate()}>
//       Collaborate
//     </Button>
//   </CardActions>
// </Card>
