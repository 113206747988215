/*jshint esversion:9*/
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import companyReducer from './company/company-reducer';
import categoryReducer from './categories/categories-reducer';
import pointsReducer from './points/points-reducer';
import badgesReducer from './badges/badges-reducer';
import userReducer from './user/user-reducer';
import notificationReducer from './notifications/notification-reducer';
import walkthroughReducer from './walkthrough/walkthrough-reducer';
import buzzesReducer from './buzzes/buzzes-reducer';
import articleReducer from '../Pages/Users/cms/redux/article/article-reducer';
import usersReducer from '../Pages/Users/cms/redux/users/users-reducer';
const persistConfig = {
key:'root',
storage,
whitelist:['company','user','categories','badges']
};

const rootReducer = combineReducers({
  company:companyReducer,
  categories:categoryReducer,
  points:pointsReducer,
  badges:badgesReducer,
  user:userReducer,
  notifications: notificationReducer,
  buzzes:buzzesReducer,
  walkthrough: walkthroughReducer,
  articles:articleReducer,
  users:usersReducer
});

export default persistReducer(persistConfig,rootReducer);
