import React, {useEffect} from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {withRouter} from 'react-router-dom';
import style from './breadCrumbsStyle.module.scss';

const BreadCrumbs = (match) => {

    const nameToPath = (id) => {
        return {
            'User' : {name:'Home',path:'/User/Dashboard'},
            'cms' : {name:'My Articles', path:'/User/cms'},
            'singleArticle': {name:'Single Article',path:`/User/cms/${id}/singleArticle`},
            'newArticle': {name:'New Article',path:'/User/cms/newArticle'},
            'edit': {name:'Edit Article',path:`/User/cms/${id}/edit`}
        }
    }

    const blocks = match && match.match.path.split('/');
    const param = match && match.match.params.articleId;

    return(
        <div className={style.wrapper}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {blocks && blocks.map(item => 
                nameToPath(param)[item] ?
                    <Link color="inherit" href={nameToPath(param)[item].path}>
                        {nameToPath(param)[item].name}
                    </Link> :
                    null   
                )}
            </Breadcrumbs>
        </div>
    )
}

export default withRouter(BreadCrumbs)