import React from 'react';
import style from './cms.module.scss';
import './cms.scss';
import { Route, Switch, useRouteMatch } from "react-router-dom";
// import MyRecommendedArticlesPage from './pages/recommended/myRecommendedArticlesPage';
import MyArticlesPage from './pages/myArticles';
import NewArticle from './pages/newArticle';
import SingleArticle from './pages/singleArticle';
const CmsPage = () => {
    let { path } = useRouteMatch()
    return (
        <div id="cms-main-wrapper" className={`${style.wrapper}`}>
            <Switch>
                <Route exact path={`${path}`} sensitive render={() => <MyArticlesPage />} />
                <Route path={`${path}/newArticle`} sensitive render={() => <NewArticle />} />
                <Route path={`${path}/:articleId/singleArticle`} sensitive render={() => <SingleArticle />} />
                <Route path={`${path}/:articleId/edit`} sensitive render={() => <NewArticle />} />
            </Switch>
        </div>
    )
}

export default CmsPage;