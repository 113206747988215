/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Button,Typography,IconButton,Backdrop,CircularProgress } from '@material-ui/core';
import ImageList from '../SubComponents/clientList-imageList/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectCompanyList} from '../../redux/company/company-selector';
import {deleteCompany,updateCompany} from '../../redux/company/company-actions';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import './client-list-style.scss';
import Snackbar from '../SubComponents/snackbar/snackbar';
import EditCompanyModal from '../SubComponents/edit-company-modal/edit-company-modal';
import EditCompanySubModal from '../SubComponents/edit-company-sub-modal/edit-company-sub-modal';
import { selectUserData } from '../../redux/user/user-selector';


const useStyles = makeStyles((theme)=>({
table: {
  minWidth: 850,
    maxHeight:'auto'
},
paper:{
  width:'80%',
  marginTop:'80px'
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
}));

 function ClientList({history,companyList,deleteCompany,updateCompany, userData}){
   const [categories,setCategories] = useState([]);
   const [edit,setEdit] = useState(false);
   const [selection,setSelection] = useState(false);
   const [selectedCompany,setSelectedCompany] = useState({});
   const [selectedOption,setSelectedOption] = useState('');
   const [loading,setLoading] = useState(false);
   const [alertOpen,setalertOpen] = useState(false);
   const [alert,setAlert] = useState('');
   const [severity,setSeverity] = useState('');
  const [startIndex,setStartIndex] = useState(0);
  const [endIndex,setEndIndex] = useState(5);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const [data,setData] = useState([]);
  const [departments, setDepartments] = useState(null)
  const [selectedDepartments,setSelectedDepartments] = useState([]);


  useEffect(()=>{
    if(companyList){
      console.log(companyList);
      setData(companyList);
    }
  },[companyList]);


  useEffect(()=>{
    try {
      if(selectedOption==='categories'){
        getAllCategories()
        .then(res =>{
            console.log(res);
            setCategories(res.data);
        })
        .then(err => console.log(err));
      }
      if(selectedOption==='departments'){
        getAllDepartments().then(res => {
          setDepartments(res.data??[])
        }).catch(err => console.log(err));
      }
    } catch (error) {
      console.log(error.message);
    }
  },[selectedOption]);

  const getAllDepartments = async() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const token = localStorage.getItem('bhyve-token');
    const result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/departments`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    const companyDepartmentsResult = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${selectedCompany.companyPublicId}`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    if(companyDepartmentsResult.data && companyDepartmentsResult.data.companyDepartmentPublicId) {
      setSelectedDepartments(companyDepartmentsResult.data);
    }else{
      setSelectedDepartments([]);
    }
    return result;
  }
  };

  const updateCompanyDepartments = async (data, token) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

    try {
      let result;
      // console.log(selectedDepartments);
      if(selectedDepartments.companyDepartments && selectedDepartments.companyDepartments.length > 0) {
         result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${selectedDepartments.companyDepartmentPublicId}`,{companyPublicId: selectedCompany.companyPublicId, departments: data},{
          headers:{
            'Authorization': `Bearer ${token}`,
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
          }
        })
      }else{
         result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments`,{companyPublicId: selectedCompany.companyPublicId, departments: data},{
          headers:{
            'Authorization': `Bearer ${token}`,
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
          }
        })
      }
  
      return result
    } catch (error) {
      console.log(error);
    }
    // console.log('data --> ', data, selectedCompany.companyPublicId);
  }
  }

  const getAllCategories = async() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    
        const token = localStorage.getItem('bhyve-token');
    const result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/skillCategories`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

const handleChangePage = (event, newPage) => {
  try {
    if(page > newPage){
      if(data.slice(startIndex,endIndex).length === rowsPerPage){
        setStartIndex(prevValue => prevValue - rowsPerPage);
        setEndIndex(prevValue => prevValue - rowsPerPage);
      }else{
        setStartIndex(prevValue => prevValue - rowsPerPage);
        setEndIndex(prevValue => prevValue - data.slice(startIndex,endIndex).length);
      }
    }else{
      if(data.slice(endIndex,data.length).length > rowsPerPage){
        setStartIndex(prevValue => prevValue + rowsPerPage);
        setEndIndex(prevValue => prevValue + rowsPerPage);
      }else{
        setStartIndex(prevValue => prevValue + rowsPerPage);
        setEndIndex(data.length);
      }
    }
    setPage(newPage); 
  } catch (error) {
    console.log(error.message);
  }
};

const handleChangeRowsPerPage = (event) => {
  try {
    setRowsPerPage(parseInt(event.target.value, 10));
  const rows = parseInt(event.target.value);
  setStartIndex(0);
  setEndIndex(rows);
  console.log(rowsPerPage);
  setPage(0);
  } catch (error) {
    console.log(error.message);
  }
};

const DeleteCompany = id => {
  try {
    setLoading(true);
    handleDelete(id)
    .then(res => {
      console.log(res);
      deleteCompany(id);
      setAlert('Company deleted successfully!');
      setSeverity('info');
      setalertOpen(true);
        setLoading(false);
    })
    .catch(err => {
      setAlert(err.message);
      setSeverity('error');
      setalertOpen(true);
        setLoading(false);
    });
  } catch (error) {
    console.log(error.message);
  }
};

const handleDelete = async(id) => {
  if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
  setLoading(true);
  const token = localStorage.getItem('bhyve-token');
  const result = await axios.delete(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${id}`,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
return result;
  }
};


  const handleEdit = company => {
    setEdit(true);
    setSelectedCompany(company);
    console.log(company);
  };

  const handleSelect = option => {
    setEdit(false);
    setSelectedOption(option);
    setSelection(true);
  };

  const handleUpdate = (details,edit) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('bhyve-token');
    switch (selectedOption) {
       case 'logo':
        let formData = new FormData();
        formData.append('companyLogo',details.logo);
        updateLogo(formData,token)
        .then(res => {
            updateCompany(res);
            setLoading(false);
            alertFunction('ok');
        })
        .catch(err => {
          setLoading(false);
          alertFunction('error');
        });
        break;
        case 'other':
          const body ={
            companyName:details.name,
            tagLine:details.tagLine
          };
          updateCompanyDetails(body,token)
          .then(res => {
              console.log(res);
              updateCompany(res.data);
              setLoading(false);
              alertFunction('ok');
          })
          .catch(err => {
            setLoading(false);
            alertFunction('error');
          });
          break;
          case 'location':
          if(details.streetAddress&&details.extendedStreetAddress&&details.city&&details.state&&details.country&&details.zipCode){
            if(details.state.length === 2){
              if(details.companyAddressPublicId){
                updateCompanyAddress(details,token,'edit')
                .then(res =>{
                    updateCompany(res.data);
                    setLoading(false);
                    alertFunction('ok');
                    setLoading(false);
                })
                .catch(err => {
                  setLoading(false);
                  alertFunction('error');
                });
              }else{
                updateCompanyAddress(details,token,'add')
                .then(res =>{
                  console.log(res);
                    updateCompany(res.data);
                    setLoading(false);
                    alertFunction('ok');
                    setLoading(false);
                })
                .catch(err => {
                  setLoading(false);
                  alertFunction('error');
                });
              }
            }else{
              setLoading(false);
              setAlert('Invalid State! Please enter valid state, for e.g: MH,GJ,CA etc');
              setSeverity('warning');
              setalertOpen(true);
              return;
            }
          }else{
            setLoading(false);
            setAlert('Please fill all the fields!');
            setSeverity('warning');
            setalertOpen(true);
            return;
          }
          break;
        case 'categories':
          setLoading(true);
        if(details.length!==0){
          const request = {
            companyPublicId:selectedCompany.companyPublicId,
            skillsCategories:details
          };
          console.log(request);
          updateCompanyCategories(request,token,edit)
          .then(res=>{
              console.log(res);
              setLoading(false);
              alertFunction('ok');
          })
          .catch(err => {
            setLoading(false);
            alertFunction('error');
          });
        }else{
          setLoading(false);
          setAlert('Please add atleast 1 category!');
          setSeverity('warning');
          setalertOpen(true);
          return;
        }
        break;

        case 'points':
          updatePoints(token,edit,details)
          .then(res => {
              setLoading(false);
              alertFunction('ok');
          })
          .catch(err => {
            setLoading(false);
            alertFunction('error');
          });
        break;

        case 'departments':
          console.log(details);
          updateCompanyDepartments(details, token)
            .then(res => {
              setLoading(false)
              alertFunction('ok')
            })
            .catch(err => {
              setLoading(false);
              console.log(err);
              alertFunction('error');
            })
        break;

      default:
    }

    setSelection(false);
 
    } catch (error) {
      console.log(error.message);
    }
  };

  const alertFunction = status => {
    if(status === 'ok'){
      setAlert('Company Updated Successfully');
      setSeverity('success');
      setalertOpen(true);
    }else{
      setAlert('Company Update Unsuccessfull!. Try again later.');
      setSeverity('error');
      setalertOpen(true);
    }
  };

  const updateLogo = async(data,token) =>{
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    console.log(data);
    const result = await fetch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${selectedCompany.companyPublicId}/logo`,{
      method:'PATCH',
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      },
      body:data
    });
    return result.json();
  }
  } ;

  const updateCompanyDetails = async(data,token) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${selectedCompany.companyPublicId}`,data,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

  const updateCompanyAddress = async(data,token,operation) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
     
    const DATA = {
      streetAddress:data.streetAddress,
      extendedStreetAddress:data.extendedStreetAddress,
      city:data.city,
      state:data.state,
      country:data.country,
      zipCode:parseInt(data.zipCode)
    };

    console.log(DATA);
    if(operation==='add'){
      const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${selectedCompany.companyPublicId}/address`,DATA,{
        headers:{
          'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }else{
      const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${selectedCompany.companyPublicId}/${data.companyAddressPublicId}`,DATA,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }
  }
  };

  const updateCompanyCategories = async(req,token,edit) =>{
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

    console.log(edit);
    if(edit){
      // console.log(edit);
      console.log(`${process.env.REACT_APP_OWNER_URL}companySkillsCategories`);
      const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}companySkillsCategories`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }else{
      const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}companySkillsCategories`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }
  }
  } ;

  const handleSyncWithDefaultPoints = async() =>{  
    const token = localStorage.getItem('bhyve-token');

    await axios.get(`${process.env.REACT_APP_API_URL_POINTS}customActionPoints/sync/${selectedCompany?.companyPublicId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        company: selectedCompany.companyName,
      },
    });

     
  }

  const updatePoints = async(token,edit,details) => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

    const req={
      companyPublicId:selectedCompany.companyPublicId,
      actionsPoints:details
    };
    console.log(edit);
    if(edit){
      const result = await axios.patch(`${process.env.REACT_APP_API_URL_POINTS}customActionPoints`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
          'company': selectedCompany.companyName
        }
      });
      return result;
    }else{
      const result = await axios.post(`${process.env.REACT_APP_API_URL_POINTS}customActionPoints`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
        }
      });
      return result;
    }
  }
  };

  const handleClose = ()=> {
    if(selection){
      setSelection(false);
    }
    if(edit){
          setEdit(false);
    }
    setSelectedOption('');
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertOpen(false);
  };

  const handleBack = () => {
    setSelection(false);
    setEdit(true);
  };

  const handleDeleteAddress = id => {
    try {
      setLoading(true);
      deleteCompanyAddress(id)
      .then(res => {
          setLoading(false);
          alertFunction('ok');
      })
      .catch(err => {
        setLoading(false);
        alertFunction('error');
      });
        setSelection(false); 
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteCompanyAddress = async id => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const token = localStorage.getItem('bhyve-token');
    const result = await axios.delete(`${process.env.REACT_APP_OWNER_URL}ownerPanel/company/${selectedCompany.companyPublicId}/address/${id}`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  };

  const classes = useStyles();



  return(
  <div className='client-list-container'>
    <Backdrop className={classes.backdrop} open={loading} >
      <CircularProgress color="primary" />
    </Backdrop>
    <Snackbar Open={alertOpen} alert={alert} severity={severity} handleClose={handleAlertClose}/>
  <Typography variant='h3' style={{fontWeight:'bold',width:'100%',textAlign:'center'}}>Client List</Typography>
    <Paper className={classes.paper}>
    <TableContainer className={classes.table}>
     <Table className={classes.table} aria-label="simple table">
       <TableHead className='table-head'>
         <TableRow>
         <TableCell className='th-cell' align="center">Logo</TableCell>
           <TableCell className='th-cell' align="center">Name</TableCell>
           <TableCell className='th-cell' align="center">Location</TableCell>
           <TableCell className='th-cell' align="center"><Button startIcon={<AddIcon color='primary'/>} className='add-btn' onClick={()=>history.push('/OwnerPanel/Client/AddClient')}>Add</Button></TableCell>
         </TableRow>
       </TableHead>
       <TableBody className='tableBody'>
         {data.slice(startIndex,endIndex).map((row,index) => (
           <TableRow key={index}>
             <TableCell component="th" scope="row" align="center">
               {row.companyLogoDetails.length > 0 && <ImageList images={row.companyLogoDetails}/>}
             </TableCell>
             <TableCell align='center'>
             {row.companyDisplayName}
             </TableCell>
             <TableCell align="center" style={{maxWidth: '30ch',textOverflow: 'ellipsis', overflow:'hidden' }}>
             {row.addresses.map((item,index) => index!==row.addresses.length-1?`${item.city},`:item.city)}
             </TableCell>
             <TableCell align="center">
             <IconButton button onClick={()=>handleEdit(row)}><EditIcon/></IconButton>
             <IconButton button onClick={() =>DeleteCompany(row.companyPublicId)}><DeleteIcon /></IconButton>
             </TableCell>
           </TableRow>
         ))}
       </TableBody>
     </Table>
   </TableContainer>
   <TablePagination
   style={{backgroundColor:'#EFEFEE'}}
   rowsPerPageOptions={[5,10,15,20]}
      component="div"
      count={data.length}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
   </Paper>
   <EditCompanyModal Open={edit} handleClose={handleClose} handleSelect={handleSelect}/>
   <EditCompanySubModal
      company={selectedCompany}
      categories={categories}
      Open={selection}
      handleUpdate={handleUpdate}
      handleClose={handleClose}
      selected={selectedOption} handleBack={handleBack}
      handleDeleteAddress={handleDeleteAddress}
      departments={departments}
      departmentsSelected={selectedDepartments.companyDepartments}
      handleSyncWithDefaultPoints={handleSyncWithDefaultPoints}
   />
  </div>
  )
}

const mapStateToProps = createStructuredSelector({
  companyList:selectCompanyList,
  userData: selectUserData
});

const mapDispatchToProps = dispatch => ({
  deleteCompany: id => dispatch(deleteCompany(id)),
  updateCompany: object => dispatch(updateCompany(object))
});

export default connect(mapStateToProps,mapDispatchToProps)(ClientList)
