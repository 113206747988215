/*jshint esversion:9*/

import { userActionTypes } from "./user-types";

export const setUserData = (object) => ({
  type: userActionTypes.SET_USER_DATA,
  payload: object,
});

export const setCompanyInformation = (object) => ({
  type: userActionTypes.SET_COMPANY_INFORMATION,
  payload: object,
});

export const setCompanyDepartments = (object) => ({
  type: userActionTypes.SET_COMPANY_DEPARTMENTS,
  payload: object,
});

export const setUserPoints = (points) => ({
  type: userActionTypes.SET_USER_POINTS,
  payload: points,
});

export const setLocationData = (data) => ({
  type: userActionTypes.SET_LOCATION_DATA,
  payload: data,
});

export const setBackgroundColor = (color) => ({
  type: userActionTypes.SET_BACKGROUND_COLOR,
  payload: color,
});

export const setSettings = (object) => ({
  type: userActionTypes.SET_SETTINGS,
  payload: object,
});
