/*jshint esversion:9*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import errorImg from '../../Assets/error-img.png';
import {withRouter} from 'react-router-dom';

class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }
  render() {
    const { hasError, errorInfo } = this.state;
if (hasError) {
  return (
    <div >
      <div>
      <img src={errorImg} alt='error' width='600' height='399'/>
        <Typography variant='h4' style={{fontWeight:'bolder'}}>
          Oops! An error occured!
        </Typography>
        <br/>
          <span
            style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload this page
          </span>{' '}
          <p>
            OR
          </p>{' '}
          <span
            style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={() => {
              this.props.history.push('/');
            }}
          >
            Go back to feed
          </span>{' '}
      </div>
      <div>
      </div>
    </div>
  );
}

  return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
};

// <details>
//   <summary>Click for error details</summary>
//   {errorInfo && errorInfo.componentStack.toString()}
// </details>
