import React, { useState, useEffect } from "react";
import {
  Modal,
  Fade,
  makeStyles,
  Backdrop,
  IconButton,
  Divider,
  Avatar,
  Button,
  CircularProgress,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import EndorseIcon from "../../../Assets/endorse.png";
import "./endorsement-modal-style.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "80vh",
    maxHeight: "88vh",
    overflowY: "auto",
    padding: "20px 0",
    width: "52%",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",

    "&::-webkit-scrollbar": {
      width: "4px !important",
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1 !important",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#888 !important",
    },
  },
}));

export default function EndorsementModal({
  open,
  handleClose,
  details,
  handleSubmit,
  loading,
  openEndorsement,
}) {
  const [review, setReview] = useState({
    reviewPointOne: 0,
    reviewPointTwo: 0,
    reviewPointThree: 0,
  });

  const [recomm, setRecomm] = useState("");

  const [selectedSkill, setSelectedSkill] = useState(null);

  const [error, setError] = useState(false);

  const [canEndorse, setCanEndorse] = useState(false);
  const [maxCountReachedMessage, setMaxCountReachedMessage] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (details) {
      if (details.skills[0].review) {
        if (Object.keys(details.skills[0].review).length !== 0) {
          setReview(details.skills[0].review);
          setCanEndorse(false);
        } else {
          setCanEndorse(true);
        }
        setRecomm(details.skills[0].menteeRecommendation);
      }
    }
  }, [details]);

  // useEffect(() => {
  //     if(selectedSkill) {

  //     }
  // },[selectedSkill])

  const handleReview = (e) => {
    const { value, name } = e.target;
    console.log(value);
    setReview((prevValue) => {
      return {
        ...prevValue,
        [name]: parseInt(value),
      };
    });
  };

  const Submit = () => {
    if (
      review.reviewPointOne === 0 ||
      review.reviewPointTwo === 0 ||
      review.reviewPointThree === 0
    ) {
      setError(true);
    } else {
      handleSubmit(review, recomm, selectedSkill);
      setReview({
        reviewPointOne: 0,
        reviewPointTwo: 0,
        reviewPointThree: 0,
      });
      setRecomm("");
      setCanEndorse(false);
    }
  };

  const handleRecommendation = (e) => {
    const { value } = e.target;
    let currentValue = value;
    // if (currentValue.length === 501) {
    //   return;
    // }
    if (currentValue.length >= 500) {
      setMaxCountReachedMessage(true);
      currentValue = currentValue.slice(0, 500);
    } else {
      setMaxCountReachedMessage(false);
    }
    setRecomm(currentValue);
  };

  const handleSkillChange = (e) => {
    const { value } = e.target;
    setSelectedSkill(value);
    if (details.skills[0].review) {
      details.skills.forEach((skill) => {
        if (skill.collaborationSkillPublicId === value) {
          if (Object.keys(skill.review).length > 0) {
            setCanEndorse(false);
            setReview(skill.review);
            setRecomm(skill.menteeRecommendation);
            return;
          } else {
            setReview({
              reviewPointOne: 0,
              reviewPointTwo: 0,
              reviewPointThree: 0,
            });
            setRecomm("");
            setCanEndorse(true);
            return;
          }
        }
      });
    } else {
      setReview({
        reviewPointOne: 0,
        reviewPointTwo: 0,
        reviewPointThree: 0,
      });
      setRecomm("");
      console.log("set true");
      setCanEndorse(true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onBackdropClick={() => {
        handleClose();
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {!loading && (
            <div className="endorsement-modal-title">
              <h3>Endorsement</h3>
              <IconButton
                className="close-icon"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {!loading && <Divider />}
          {!loading && (
            <div className="endorsement-modal-user-info">
              {details && details.user.profilePhotoUrl !== "" && (
                <Avatar
                  className="endorsement-modal-user-info-avatar"
                  src={details.user.profilePhotoUrl}
                />
              )}
              {details && details.user.profilePhotoUrl === "" && (
                <Typography
                  variant="h4"
                  className="initial-container"
                  style={{
                    cursor: "pointer",
                    textAlign: "center!important",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20%",
                    color: "white",
                    background: "#FFCC33",
                    width: "89px",
                    height: "89px",
                  }}
                >
                  {`${details.user.name
                    .split(" ")[0]
                    .slice(0, 1)}${details.user.name
                    .split(" ")[1]
                    .slice(0, 1)}`}
                </Typography>
              )}
              <div className="endorsement-modal-user-info-details">
                <h2>{details && details.user.name}</h2>
                <h4>
                  Endorse {details && details.user.name} for their contribution
                  and level of teaching
                </h4>
                <p>Jan 8, 2020</p>
              </div>
            </div>
          )}
          {details && (
            <FormControl
              variant="outlined"
              className="endorsement-modal-select"
            >
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={selectedSkill}
                onChange={handleSkillChange}
              >
                <MenuItem value={null} disabled>
                  Select Skill
                </MenuItem>
                {details.skills.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={
                      openEndorsement
                        ? item.skillPublicId
                        : item.collaborationSkillPublicId
                    }
                  >
                    {item.skillDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!loading && selectedSkill && (
            <div className="endorsement-modal-rating-main-container">
              <div className="endorsement-modal-rating-container">
                <h4>
                  Evaluate {details && details.user.name.split(" ")[0]} on a
                  scale of 1 - 5{" "}
                  <span>(1 being the lowest and 5 being the highest)</span>
                </h4>
                <div className="endorsement-modal-rating-container-main">
                  <div className="endorsement-modal-rating-container-sub">
                    <p>Skill Competency</p>
                    <Rating
                      disabled={!canEndorse}
                      value={review.reviewPointOne}
                      name="reviewPointOne"
                      onChange={handleReview}
                      size="large"
                    />
                  </div>
                  <div className="endorsement-modal-rating-container-sub">
                    <p>This addressed my needs</p>
                    <Rating
                      disabled={!canEndorse}
                      value={review.reviewPointTwo}
                      name="reviewPointTwo"
                      onChange={handleReview}
                      size="large"
                    />
                  </div>
                  <div className="endorsement-modal-rating-container-sub">
                    <p>Communication</p>
                    <Rating
                      disabled={!canEndorse}
                      value={review.reviewPointThree}
                      name="reviewPointThree"
                      onChange={handleReview}
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && selectedSkill && (
            <div className="endorsement-modal-review-container">
              <TextField
                className="endorsement-modal-review-textField"
                variant="outlined"
                multiline
                rows="2"
                placeholder="Write your recommendation (optional) (500 characters max.)"
                value={recomm}
                onChange={handleRecommendation}
              />
              {maxCountReachedMessage && (
                <small
                  style={{
                    color: "red",
                    fontSize: "14px",
                    float: "right",
                    marginTop: "5px",
                  }}
                >
                  You have reached 500 character limit!
                </small>
              )}
            </div>
          )}
          {!loading && (
            <div className="endorsement-modal-btn-container">
              <Button
                className="cancel-btn"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!canEndorse}
                className="endorsement-modal-btn-container-btn"
                variant="contained"
                onClick={Submit}
              >
                Submit
              </Button>
              {error && <p>Need to give each quality atleast 1 star !</p>}
            </div>
          )}
          {loading && (
            <div className="loader-container">
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
