import React,{useState} from 'react';
import {makeStyles,Modal,Fade,Backdrop,TextField,IconButton,Button,Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './collab-decline-modal-style.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
        width:'600px', 
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function CollabDeclineModal({open,handleClose,handleSend}) {

    const classes = useStyles();

    const [reason,setReason] = useState('');

    const handleReasonChange = e => {
      const {value} = e.target;
      if(value.length === 200) {
        return;
      }
      setReason(value);
    }

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className='collab-decline-modal-title'>
                <h3>Tell us why are you declining</h3>
                <IconButton onClick={() => handleClose()}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <Divider/>
            <TextField variant='outlined' multiline rows={4} className='collab-decline-modal-input' value={reason} onChange={handleReasonChange} placeholder='(200 characters max.)' />
            <div className='collab-decline-modal-submit'>
                <Button variant='contained' className='collab-decline-modal-submit-btn' onClick={() => handleSend(reason)}>Send</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    )
}