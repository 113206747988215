import React from "react";
import Button from "@material-ui/core/Button";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ChatIcon from "@material-ui/icons/Chat";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useBtnStyle = makeStyles({
  root: {
    textTransform: "none",
  },
  active: {
    color: "rgba(255,204,51,1)!important",
  },
  inActive: {
    color: "rgba(0, 0, 0, 1)!important",
  },
});
const useIconStyle = makeStyles({
  inActive: {
    opacity: 0.3,
  },
  active: {
    opacity: 1,
  },
  liked: {
    opacity: 1,
    color: "#FFCC33",
  },
});
const ArticleAction = ({
  children,
  className,
  icon,
  active,
  viewOnly,
  ...props
}) => {
  const btnClasses = useBtnStyle();
  const iconClasses = useIconStyle();
  const getIcon = (iconName) => {
    switch (iconName) {
      case "like":
        return (
          <ThumbUpIcon
            className={`${active ? iconClasses.active : iconClasses.inActive}`}
          />
        );
      case "comment":
        return <ChatIcon className={`${iconClasses.inActive}`} />;
      case "views":
        return <VisibilityIcon className={`${iconClasses.inActive}`} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {icon === "like" || icon === "comment" ? (
        <Tooltip
          title={icon === "like" ? "Like" : icon === "comment" ? "Comment" : ""}
        >
          <Button
            size="small"
            startIcon={getIcon(icon, props.isLiked, props.isUnliked)}
            className={`${btnClasses.root} ${
              active ? btnClasses.active : btnClasses.inActive
            } ${className}`}
            disabled={viewOnly}
            {...props}
          >
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Button
          size="small"
          startIcon={getIcon(icon, props.isLiked, props.isUnliked)}
          className={`${btnClasses.root} ${
            active ? btnClasses.active : btnClasses.inActive
          } ${className}`}
          disabled={viewOnly}
          {...props}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default ArticleAction;
