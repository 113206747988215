import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { withRouter } from 'react-router-dom';


const SingleSignOn = ({history}) => {
    const { userPublicId } = useParams();
    console.log('Zing hr user public id ', userPublicId);

    useEffect( () => {
        const getBhyveToken = async () => {
            const result = await axios.get(`${process.env.REACT_APP_API_URL_AUTH}authentication/token`,{
                headers:{
                    'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
                },
                params:{
                    userPublicId: userPublicId
                }
            });
            if(result) {
                localStorage.setItem('bhyve-token', result.data);
                history.push('/');
            }
        }
        getBhyveToken();
    })

    return(
        <div>
            Authenticating....
        </div>
    )
}

export default withRouter(SingleSignOn);