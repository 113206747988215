/*jshint esversion:9*/

export const categoriesActionTypes = {
  SET_CATEGORIES:'SET_CATEGORIES',
  ADD_SKILL_TO_CATEGORY:'ADD_SKILL_TO_CATEGORY',
  DELETE_SKILL:'DELETE_SKILL',
  UPDATE_SKILL:'UPDATE_SKILL',
  ADD_SINGLE_CATEGORY:'ADD_SINGLE_CATEGORY',
  DELETE_CATEGORY:'DELETE_CATEGORY',
  UPDATE_CATEGORY_NAME:'UPDATE_CATEGORY_NAME'
};
