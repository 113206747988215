import React, { useState, useRef } from "react";
import { youtube, viemo, youtubeId, viemoId } from "./util/videoEmbed";
import cmsApi from "../../axios/authAxios";
import axios from "axios";
import style from "./editor.module.scss";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/codesample";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autolink";
import contentStyle from "./contentStyle";
import { Editor } from "@tinymce/tinymce-react";
import "./editor.scss";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ImageIcon from "@material-ui/icons/Image";
import VideocamIcon from "@material-ui/icons/Videocam";
import LinkIcon from "@material-ui/icons/Link";
import CodeIcon from "@material-ui/icons/Code";
import Upload from "@material-ui/icons/Backup";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SecondaryButton from "../secondaryButton";
import EditorHintDialog from "../../util/editorHintDialog";
import styleFormats from "./styleFormats";
import UploadFileSelectDialog from "./util/uploadFileSelectDialog";
import UploadProgressDialog from "../uploadProgressDialog";
import AddLinkDialog from "./util/addLinkDialog";
import {
  iconUndoSvg,
  iconRedoSvg,
  iconTextColorSvg,
  iconCodeSvg,
  iconLinkSvg,
  iconTableSvg,
} from "./util/icons";
import Tooltip from "@material-ui/core/Tooltip";

import CodeSlash from "./../../../../../Assets/code-slash.png";

const ArticleEditor = ({
  initContent,
  onChange,
  saveDraft,
  handleValue,
  cantPreview,
  lastSave,
  ...props
}) => {
  const axiosCancelRef = React.useRef(null);
  const CancelToken = axios.CancelToken;
  const editorRef = React.useRef(null);
  const actionWidgetRef = React.useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [actionWidgetState, setActionWidgetState] = React.useState({
    x: 0,
    y: 0,
    isActive: false,
  });
  const [isEditorHintDialogOpen, setIsEditorHintDialogOpen] = useState(false);
  const closeEditorHintDialog = () => setIsEditorHintDialogOpen(false);
  const previewBtnRef = React.useRef(null);
  const typeRef = useRef(null);
  const pdfRef = useRef(null);
  const [isAddLinkDialogOpen, setAddLinkDialogOpen] = React.useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [currentUploadFileDetails, setCurrentUploadFileDetails] =
    React.useState(false);
  const [selectedFileError, setSelectedFileError] = React.useState("");
  const currentUploadFileDetailsRef = React.useRef(null);
  const currentUploadDialogFileType = React.useRef("");
  const urlRef = useRef(null);
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const [fileUploadProgress, setFileUploadProgress] = React.useState(null);
  const [isUploadProgressDialogOpen, setIsUploadProgressDialogOpen] =
    React.useState(false);
  const [videoUrlError, setVideoUrlError] = useState(false);
  const [msg, setMsg] = useState();
  const showYTModal = () => {
    setIsUploadDialogOpen("video");
    currentUploadDialogFileType.current = "video";
  };
  const handleImage = () => {
    setIsUploadDialogOpen("image");
    currentUploadDialogFileType.current = "image";
  };
  const handlePdf = () => {
    setIsUploadDialogOpen("document");
    currentUploadDialogFileType.current = "document";
  };
  const handleLink = () => setAddLinkDialogOpen(true);

  // const handleImage = () => { typeRef.current = 'image'; editorRef.current.execCommand("mceImage", true); setShowToolbar(false) };
  // const handleVideo = () => { typeRef.current = 'video'; editorRef.current.execCommand("mceMedia", true); setShowToolbar(false) };
  // const handleLink = () => { editorRef.current.execCommand("mceLink", true); setShowToolbar(false) };
  const handleCode = () => {
    // editorRef.current.execCommand("mceCodeEditor", true);
    editorRef.current.execCommand("codesample", true);
    setShowToolbar(false);
  };
  const handleTable = () => {
    editorRef.current.execCommand("mceInsertTable", true);
    setShowToolbar(false);
  };
  // const handlePdf = () => pdfRef.current.click()
  const videoUpload = () => videoRef.current.click();
  const imageUpload = () => imageRef.current.click();
  const uploadPdf = () => pdfRef.current.click();
  const onUploadProgress = (progressEvent) =>
    setFileUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
  const onDrop = React.useCallback((acceptedFiles) => {
    switch (currentUploadDialogFileType.current) {
      case "video":
        return onVideoSelect({ target: { files: acceptedFiles } });
      case "image":
        return onImageSelect({ target: { files: acceptedFiles } });
      case "document":
        return handlePdfFile({ target: { files: acceptedFiles } });
      default:
        return "";
    }
  }, []);
  const handleAddLinkDialogClose = () => {
    setShowToolbar(false);
    setAddLinkDialogOpen(false);
  };
  const handleLinkSave = (linkDetails) => {
    appendLinkToEditor(linkDetails);
    handleAddLinkDialogClose();
  };
  const handleUploadProgressDialogClose = () => {
    if (axiosCancelRef.current) axiosCancelRef.current("cancelled by user");
    setFileUploadProgress(null);
    setCurrentUploadFileDetails(false);
    setIsUploadProgressDialogOpen(false);
    currentUploadFileDetailsRef.current = null;
    setSelectedFileError("");
    videoRef.current.value = null;
    pdfRef.current.value = null;
    imageRef.current.value = null;
    currentUploadDialogFileType.current = "";
  };
  const handleUploadFileChange = () => {
    if (axiosCancelRef.current) axiosCancelRef.current("cancelled by user");
    setFileUploadProgress(null);
    setCurrentUploadFileDetails(false);
    currentUploadFileDetailsRef.current = null;
    videoRef.current.value = null;
    pdfRef.current.value = null;
    imageRef.current.value = null;
    setIsUploadDialogOpen(isUploadProgressDialogOpen);
    setIsUploadProgressDialogOpen(false);
  };
  const handleUploadDialogClose = () => {
    setSelectedFileError("");
    setIsUploadDialogOpen(false);
    setShowToolbar(false);
    currentUploadDialogFileType.current = "";
  };
  const onVideoSelect = async (event) => {
    try {
      if (!event.target.files.length) return null;
      let file = event.target.files[0];
      if (file.type.split("/")[0] !== "video") {
        setSelectedFileError("File should be a video");
        return (videoRef.current.value = null);
      }
      if (file.size > 26214400) {
        setSelectedFileError("File Size Should be less than 25MB");
        return (videoRef.current.value = null);
      }
      setIsUploadDialogOpen(false);
      setShowToolbar(false);
      currentUploadFileDetailsRef.current = {
        name: file.name,
        size: file.size,
      };
      setCurrentUploadFileDetails({ name: file.name, size: file.size });
      const formData = new FormData();
      formData.append("file", file);
      setIsUploadProgressDialogOpen("video");
      const response = await cmsApi.post("cms/upload", formData, {
        onUploadProgress,
        cancelToken: new CancelToken((c) => (axiosCancelRef.current = c)),
      });
      // setIsUploadProgressDialogOpen(false);
      axiosCancelRef.current = null;
      const url = response.data.url;
      currentUploadFileDetailsRef.current = {
        ...currentUploadFileDetailsRef.current,
        remoteUrl: url,
      };
      setCurrentUploadFileDetails({ ...currentUploadFileDetailsRef.current });
      // editorRef.current.insertContent(`<video width="300" height="240" src='${url}' controls />`)
    } catch (err) {
      console.log(err);
    }
  };
  const onImageSelect = async (event) => {
    try {
      if (!event.target.files.length) return null;
      let file = event.target.files[0];
      if (file.type.split("/")[0] !== "image") {
        setSelectedFileError("File Should be an Image");
        return (imageRef.current.value = null);
      }
      if (file.size > 26214400) {
        setSelectedFileError("File Size Should be less than 25MB");
        return (imageRef.current.value = null);
      }
      setCurrentUploadFileDetails({ name: file.name, size: file.size });
      currentUploadFileDetailsRef.current = {
        name: file.name,
        size: file.size,
      };
      setIsUploadDialogOpen(false);
      setShowToolbar(false);
      let image = new Image();
      let width = 0;
      let height = 0;
      image.src = URL.createObjectURL(file);
      image.onload = function () {
        if (this.width > 1000) {
          width = "100%";
          height = "auto";
        } else {
          width = this.width;
          height = this.height;
        }
      };
      const formData = new FormData();
      formData.append("file", file);
      setIsUploadProgressDialogOpen("image");
      const response = await cmsApi.post("cms/upload", formData, {
        onUploadProgress,
        cancelToken: new CancelToken((c) => (axiosCancelRef.current = c)),
      });
      // setIsUploadProgressDialogOpen(false);
      axiosCancelRef.current = null;
      const url = response.data.url;
      currentUploadFileDetailsRef.current = {
        ...currentUploadFileDetailsRef.current,
        remoteUrl: url,
        width,
        height,
      };
      setCurrentUploadFileDetails({ ...currentUploadFileDetailsRef.current });
      // editorRef.current.insertContent(`<img src="${url}" width="${width}" height="${height}" data-mce-src="${url}">`)
    } catch (err) {
      console.log(err);
    }
  };
  const handlePdfFile = async (event) => {
    try {
      if (!event.target.files.length) return null;
      let file = event.target.files[0];
      // if(file.type.split('/')[0] !== "application"){
      //     setSelectedFileError("File Should be a Document");
      //     return pdfRef.current.value = null;
      // }
      if (file.size > 26214400) {
        setSelectedFileError("File Size Should be less than 25");
        return (pdfRef.current.value = null);
      }
      setIsUploadDialogOpen(false);
      setShowToolbar(false);
      currentUploadFileDetailsRef.current = {
        name: file.name,
        size: file.size,
      };
      setCurrentUploadFileDetails({ name: file.name, size: file.size });
      const formData = new FormData();
      formData.append("file", file);
      setIsUploadProgressDialogOpen("document");
      const response = await cmsApi.post("cms/upload", formData, {
        onUploadProgress,
        cancelToken: new CancelToken((c) => (axiosCancelRef.current = c)),
      });
      // setIsUploadProgressDialogOpen(false);
      axiosCancelRef.current = null;
      const url = response.data.url;
      currentUploadFileDetailsRef.current = {
        ...currentUploadFileDetailsRef.current,
        remoteUrl: url,
        contentType: response.data.contentType,
      };
      setCurrentUploadFileDetails({ ...currentUploadFileDetailsRef.current });
      // editorRef.current.insertContent(`<a href='${url}' target="_blank" data-type="${response.data.contentType}">${name}</a>`)
    } catch (err) {
      console.log(err);
    }
  };
  const handleYoutube = () => {
    if (!urlRef.current || !urlRef.current.nodeName)
      return console.log("urlRef is empty or not an element");
    let input = urlRef.current.querySelector("input");
    if (!input) return console.log("urlRef does not contains input element");
    let value = input.value;
    if (!value) return setVideoUrlError("Please Enter Url");

    const ytId = youtubeId(value);
    const vId = viemoId(value);
    if (ytId) {
      youtube(editorRef, ytId);
      handleUploadDialogClose();
      setVideoUrlError(false);
      return;
    } else if (vId) {
      viemo(editorRef, vId);
      handleUploadDialogClose();
      setVideoUrlError(false);
      return;
    } else {
      setVideoUrlError("Please Enter Valid Url");
    }
  };
  const appendImageToEditor = () => {
    let file = currentUploadFileDetailsRef.current;
    editorRef.current.insertContent(
      `<img src="${file.remoteUrl}" width="${file.width}" height="${file.height}" data-mce-src="${file.remoteUrl}">`
    );
    handleUploadProgressDialogClose();
  };
  const appendVideoToEditor = () => {
    let file = currentUploadFileDetailsRef.current;
    editorRef.current.insertContent(
      `<video width="300" height="240" src='${file.remoteUrl}' controls />`
    );
    handleUploadProgressDialogClose();
  };
  const appendDocumentToEditor = () => {
    let file = currentUploadFileDetailsRef.current;
    editorRef.current.insertContent(
      `<a href='${file.remoteUrl}' target="_blank" data-type="${file.contentType}">${file.name}</a>`
    );
    handleUploadProgressDialogClose();
  };
  const appendLinkToEditor = ({ url, title, target }) => {
    editorRef.current.insertContent(
      `<a href="${url}" target="${target}" rel="noopener" data-mce-href="${url}" data-mce-selected="inline-boundary">${title}</a>`
    );
  };
  const toggleToolbar = () => setShowToolbar((state) => !state);
  const content = React.useRef(handleValue);
  const handleChange = (value, editor) => {
    handleActionWidget(actionWidgetRef.current, editor);
    onChange(value, editor);
  };
  const handleResize = () => handleActionWidget();
  const handleActionWidget = (widget, editor) => {
    if (!widget)
      if (actionWidgetRef.current) {
        widget = actionWidgetRef.current;
      } else {
        return;
      }
    if (editorRef.current) {
      editor = editorRef.current;
    } else {
      return;
    }
    let currentElCoordinated = editor.selection
      .getNode()
      .getBoundingClientRect();
    widget.style.top = `${
      currentElCoordinated.y +
      (document.querySelector("#content_wrapper").getBoundingClientRect().y -
        14)
    }px`;
    // console.log(editor);
    // console.log(editor.selection.getNode());
  };
  const getIntersectionObservable = () => {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        setTimeout(() => {
          if (!entry.isIntersecting) {
            if (document.querySelector(".tox-toolbar")) {
              document.querySelector(".tox-toolbar").style.position = "fixed";
              document.querySelector(".tox-toolbar").style.width = "1080px";
              document.querySelector(".tox-toolbar").style.bottom = "0px";
            }
            if (document.querySelector("#action_wrapper")) {
              document.querySelector("#action_wrapper").style.position =
                "fixed";
              if (document.querySelector("#content_wrapper")) {
                let coordinates = document
                  .querySelector("#content_wrapper")
                  .getBoundingClientRect();
                document.querySelector("#action_wrapper").style.left = `${
                  coordinates.x + coordinates.width - 357
                }px`;
              }
            }
          } else {
            if (
              document.querySelector(".tox-toolbar") &&
              document.querySelector("#action_wrapper")
            ) {
              document.querySelector(".tox-toolbar").style.position = "static";
              document.querySelector("#action_wrapper").style.position =
                "absolute";
              document.querySelector("#action_wrapper").style.left = "auto";
            }
          }
        }, 1500);
      });
    }, {});
    let interval = setInterval(() => {
      let element = document.querySelector("#anchorPoint");
      if (element) {
        // document.querySelector('.tox-editor-header').style.minHeight = "10px";
        observer.observe(element);
        clearInterval(interval);
      }
    }, 3000);
    return observer;
  };
  const addControllerObserver = (controllerEl) => {
    if (controllerEl) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {});
      }, {}).observe(controllerEl);
    }
  };
  const setControllerRef = React.useCallback((controllerEl) => {
    actionWidgetRef.current = controllerEl;
    handleActionWidget(controllerEl);
    if (controllerEl) {
      let contentWrapperEl = false;
      let contentWrapperPoll = setInterval(() => {
        if (!contentWrapperEl) {
          if (document.querySelector("#content_wrapper")) {
            clearInterval(contentWrapperPoll);
            contentWrapperEl = document.querySelector("#content_wrapper");
            let coordinates = contentWrapperEl.getBoundingClientRect();
            controllerEl.style.left = `${coordinates.x - 55}px`;
            let bottom =
              coordinates.y - coordinates.height < 300
                ? 300
                : coordinates.y - coordinates.height;
            // controllerEl.style.bottom = `${bottom}px`
            // let editor = null;
            // let editorPoll = setInterval(() => {
            //     if (editorRef.current) {
            //         clearInterval(editorPoll)
            //         editor = editorRef.current;
            // controllerEl.style.top = editor.selection.getNode().getBoundingClientRect().y;
            // console.log(controllerEl.style.top = `${editor.selection.getNode().getBoundingClientRect().y}px`)
            // }
            // }, 10)
          }
        }
      }, 10);
    }

    // addControllerObserver(controllerEl)
  });
  const uploadEditorImage = async (
    blobInfo,
    success,
    failure,
    progress,
    myfile
  ) => {
    try {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      const response = await cmsApi.post("cms/upload", formData);
      success(response.data.url);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    getIntersectionObservable();
    addControllerObserver();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={style.wrapper}>
      <AddLinkDialog
        open={isAddLinkDialogOpen}
        handleClose={handleAddLinkDialogClose}
        handleSave={handleLinkSave}
      />
      <UploadFileSelectDialog
        open={isUploadDialogOpen}
        urlRef={urlRef}
        handleClose={handleUploadDialogClose}
        videoUrlError={videoUrlError}
        setShowToolbar={setShowToolbar}
        onDrop={onDrop}
        // video
        videoUpload={videoUpload}
        imageUpload={imageUpload}
        pdfUpload={uploadPdf}
        handleYoutube={handleYoutube}
        selectedFileError={selectedFileError}
      />
      <UploadProgressDialog
        open={isUploadProgressDialogOpen}
        handleClose={handleUploadProgressDialogClose}
        progress={fileUploadProgress}
        currentUploadFileDetails={currentUploadFileDetails}
        appendImageToEditor={appendImageToEditor}
        appendVideoToEditor={appendVideoToEditor}
        appendDocumentToEditor={appendDocumentToEditor}
        handleUploadFileChange={handleUploadFileChange}
      />
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={content.current ? content.current : ""}
        init={{
          browser_spellcheck: true,
          style_formats: styleFormats,
          skin_url: `${process.env.PUBLIC_URL}/bhyve`,
          plugins:
            "lists image media codesample table paste link autoresize autolink",
          // plugins: `advlist autolink lists link image media charmap print preview anchor autoresize searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount `,
          toolbar:
            "undo redo | styleselect | bold italic underline forecolor align | codesample customLink table | numlist bullist",
          toolbar_sticky: true,
          media_live_embeds: true,
          image_uploadtab: false,
          default_link_target: "_blank",
          link_title: false,
          file_picker_callback: function (callback, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
              const uploadedfile = this.files[0];
              if (meta.filetype === "image") {
                callback(URL.createObjectURL(uploadedfile), {
                  title: uploadedfile.name,
                });
                return;
              }
            };
            input.click();
          },
          audio_template_callback: function (data) {
            return (
              "<audio controls>" +
              '\n<source src="' +
              data.source +
              '"' +
              (data.sourcemime ? ' type="' + data.sourcemime + '"' : "") +
              " />\n" +
              (data.altsource
                ? '<source src="' +
                  data.altsource +
                  '"' +
                  (data.altsourcemime
                    ? ' type="' + data.altsourcemime + '"'
                    : "") +
                  " />\n"
                : "") +
              "</audio>"
            );
          },
          video_template_callback: function (data) {
            return (
              '<video width="' +
              data.width +
              '" height="' +
              data.height +
              '"' +
              (data.poster ? ' poster="' + data.poster + '"' : "") +
              ' controls="controls">\n' +
              '<source src="' +
              data.source +
              '"' +
              (data.sourcemime ? ' type="' + data.sourcemime + '"' : "") +
              " />\n" +
              (data.altsource
                ? '<source src="' +
                  data.altsource +
                  '"' +
                  (data.altsourcemime
                    ? ' type="' + data.altsourcemime + '"'
                    : "") +
                  " />\n"
                : "") +
              "</video>"
            );
          },
          media_url_resolver: function (data, resolve, reject) {
            if (data.url) {
              var embedHtml =
                '<iframe src="' +
                data.url +
                '" width="400" height="400" ></iframe>';
              resolve({ html: embedHtml });
            } else {
              resolve({ html: "" });
            }
          },
          images_upload_handler: uploadEditorImage,
          min_height: 300,
          placeholder: "Write here. Add images or videos for visual impact.",
          autoresize_overflow_padding: 80,
          plugin_preview_width: "500",
          plugin_preview_height: "600",
          content_style: contentStyle,
          toolbar_location: "bottom",
          menubar: false,
          statusbar: false,
          contextmenu: false,
          setup: function (editor) {
            editor.ui.registry.addIcon("undo", iconUndoSvg);
            editor.ui.registry.addIcon("redo", iconRedoSvg);
            editor.ui.registry.addIcon("text-color", iconTextColorSvg);
            editor.ui.registry.addIcon("code-sample", iconCodeSvg);
            editor.ui.registry.addIcon("link", iconLinkSvg);
            editor.ui.registry.addIcon("table", iconTableSvg);
            editor.ui.registry.addContextToolbar("textselection", {
              predicate: function (node) {
                return !editor.selection.isCollapsed();
              },
              items: "bold italic underline | H1 H2 H3 H4 | blockquote | link",
              position: "selection",
              scope: "node",
            });
            editor.ui.registry.addButton("customLink", {
              icon: "link",
              onAction: () => handleLink(),
            });
          },
        }}
        onClick={() => {
          handleActionWidget();
        }}
        onEditorChange={handleChange}
        value={handleValue}
      />
      <div id="anchorPoint"></div>
      <input
        onChange={handlePdfFile}
        ref={pdfRef}
        type="file"
        id="fileUpload"
        style={{ display: "none" }}
      />
      <input
        onChange={onVideoSelect}
        ref={videoRef}
        type="file"
        accept="video/*"
        id="video"
        style={{ display: "none" }}
      />
      <input
        onChange={onImageSelect}
        ref={imageRef}
        type="file"
        accept="image/*"
        id="image_input"
        style={{ display: "none" }}
      />
      <div id="action_wrapper" className={`${style.action_wrapper}`}>
        <span className={style.autoSave}>
          {lastSave ? lastSave : "not provided"}
        </span>
        {/* <span className={style.saveDraftBtn} id="saveDraftBtn" onClick={saveDraft}>save to draft</span> */}
        <SecondaryButton
          ref={previewBtnRef}
          id="preview_btn"
          onClick={
            cantPreview
              ? () => setIsEditorHintDialogOpen(true)
              : props.handlePreview
          }
          variant="contained"
          className={`${style.preview_btn} ${
            cantPreview ? style.disabled : ""
          }`}
          disable={cantPreview}
        >
          Preview
        </SecondaryButton>
      </div>
      <span
        ref={setControllerRef}
        id="action_wrapper"
        className={style.toolButton}
      >
        <IconButton size="medium" onClick={toggleToolbar}>
          <AddCircleOutlineIcon
            fontSize="medium"
            style={{ transform: showToolbar ? "rotate(45deg)" : "rotate(0)" }}
          />
        </IconButton>
        <span className={style.toolbar}>
          <Grow
            style={showToolbar ? { display: "block" } : { display: "none" }}
            in={showToolbar}
          >
            <Paper elevation={4} className={style.toolbar_inner}>
              <Tooltip title="Image">
                <IconButton size="small" onClick={handleImage}>
                  <ImageIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Video">
                <IconButton size="small" onClick={showYTModal}>
                  <VideocamIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Attachment">
                <IconButton size="small" onClick={handlePdf}>
                  <AttachmentIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Link">
                <IconButton size="small" onClick={handleLink}>
                  <LinkIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Code Snippet">
                <IconButton size="small" onClick={handleCode}>
                  {/* <CodeIcon fontSize="medium" /> */}
                  <img src={CodeSlash} width="32" height="32" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Table">
                <IconButton size="small" onClick={handleTable}>
                  <BorderAllIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Paper>
          </Grow>
        </span>
      </span>
      <EditorHintDialog
        open={isEditorHintDialogOpen}
        el={document.getElementById("preview_btn")}
        handleClose={closeEditorHintDialog}
      />
    </div>
  );
};

export default ArticleEditor;
