/*jshint esversion:9*/
import React from 'react'
import {Typography} from '@material-ui/core';

function SubText({text}){
  return(
    <Typography variant='body1' style={{color:'#808080'}}>{text}</Typography>
  );
}

export default SubText;
