import React from 'react';
import style from './thumbnail.module.scss';
import defaultThumbnail from '../../img/default_thumbnail.png'

const Thumbnail = (props) => {
    let image = props.image ? props.image : defaultThumbnail;
    let styles = {
        backgroundImage: `url(${image})`
    }
    return (
        <div style={{ ...styles, ...props.style }} className={`${style.wrapper}`}></div>
    )
}
export default Thumbnail;