/*jshint esversion:9*/

import React from 'react';
import { makeStyles,IconButton } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from '../../Assets/BHYVE_logo.png';
import PersonIcon from '@material-ui/icons/Person';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StarsIcon from '@material-ui/icons/Stars';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WorkIcon from '@material-ui/icons/Work';
import Logout from '../../logout';
import {withRouter} from 'react-router-dom';
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  logo:{
    width:'150px',
    marginTop:'60px',
    marginLeft:'40px',
    marginBottom:'60px',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor:'white'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
}));

function OwnerPanelNavbar({history}) {
       
  const classes = useStyles();


  const handleLogout = async() => {
      const token = await localStorage.getItem("bhyve-token");

      if(!token) return

      await axios.get(
          `${process.env.REACT_APP_OWNER_URL}ownerPanel/user/logout`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
          }
      );

      await localStorage.clear()

      history.push('/logout');
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar style={{display:'flex',justifyContent:'space-between'}}>
          <Typography variant="h6" noWrap>
            Owner's Panel
          </Typography>
          <IconButton color='primary' onClick={handleLogout}>
            <ExitToAppIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
      <img src={Logo} alt='logo' className={classes.logo}/>
        <List>
            <ListItem button onClick={()=>history.push('/OwnerPanel/Client/ClientList')}>
              <ListItemIcon ><PersonIcon color='primary'/></ListItemIcon>
              <ListItemText primary='Client' />
            </ListItem>
            <ListItem button onClick={()=>history.push('/OwnerPanel/Categories')}>
              <ListItemIcon ><FormatListBulletedIcon color='primary'/></ListItemIcon>
              <ListItemText primary='Categories' />
            </ListItem>
            <ListItem button onClick={()=>history.push('/OwnerPanel/Points')}>
              <ListItemIcon ><StarsIcon color='primary'/></ListItemIcon>
              <ListItemText primary='Points' />
            </ListItem>
            <ListItem button onClick={()=>history.push('/OwnerPanel/Badges')}>
              <ListItemIcon ><LoyaltyIcon color='primary'/></ListItemIcon>
              <ListItemText primary='Badges' />
            </ListItem>
            <ListItem button onClick={()=>history.push('/OwnerPanel/Departments')}>
              <ListItemIcon ><WorkIcon color='primary'/></ListItemIcon>
              <ListItemText primary='Departments' />
            </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export default withRouter(OwnerPanelNavbar)
