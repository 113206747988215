/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import {Typography,Divider,IconButton} from '@material-ui/core';
import './experience-data-style.scss';
import EditIcon from '@material-ui/icons/Edit';

function ExperienceData({designation,company,description,startMonth,startYear,endMonth,endYear,handleEdit,id,edit}){
  const months = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
  const [start,setStart] = useState('');
  const [end,setEnd] = useState('');
  const [seeMore,setSeeMore] = useState(false);

  useEffect(()=>{
    try {
      let startArray = startMonth.split('');
      if(startArray[0]==='0'){
        setStart(startArray[1]);
      }else{
        setStart(startMonth);
      }
      if(endMonth!=='present'){
        let endArray = endMonth.split('');
        if(endArray[0]==='0'){
          setEnd(endArray[1]);
        }else{
          setEnd(endMonth);
        }
      } 
    } catch (error) {
      console.log(error.message);
    }
  },[startMonth,endMonth])

  return(
    <div className='exp-data-entry'>
    <div className='exp-data-top'>
  {edit&&<div>
    <Typography variant='body1' className='position'>{designation}</Typography>
    <Typography variant='body1' >{company}</Typography>
    <Typography variant='body1' className='muted'>{`${months[start]} ${startYear}-${endMonth!==''&&endMonth!=='present'?months[end]:'Present'} ${endYear&&endYear}`}</Typography>
    </div>}
    {!edit&&<div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
      <div>
      <Typography variant='body1' className='position'>{designation}</Typography>
      <Typography variant='body1' >{company}</Typography>
      </div>
      <Typography variant='body1' style={{paddingTop:'30px'}} className='muted'>{`${months[start]} ${startYear}-${endMonth!==''&&endMonth!=='present'?months[end]:'Present'} ${endYear&&endYear}`}</Typography>
      </div>}
    {edit&&<IconButton button onClick={()=>handleEdit()}>
    <EditIcon/>
    </IconButton>}
    </div>
    <p  className={`description ${seeMore && 'extend'}`} style={{marginTop:'10px',color:'#707070'}}>{description}</p>
    {description.length > 113 && <p className='seeMore' onClick={() => setSeeMore(!seeMore) }>{seeMore ? '...See Less' : '...See More'}</p>}
  <Divider/>
    </div>
  )
}

export default ExperienceData;
