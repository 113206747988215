/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Avatar,
  Typography,
  Button,
  Divider,
  Link,
} from "@material-ui/core";
import CreateCollabModal from "../SubComponents/create-collab-modal/create-collab-modal";
import PendingCollabModal from "../SubComponents/pending-collab-modal/pending-collab-modal";
import DonateBadgeModal from "../SubComponents/donate-badge-modal/donate-badge-modal.jsx";
import EndorsementModal from "../SubComponents/endorsement-modal/endorsement-modal";
import SuccessModal from "../SubComponents/ack-endorse-success-modal/ack-endorse-success-modal";
import Loader from "../SubComponents/loader/loader-component";
import Snackbar from "../SubComponents/snackbar/snackbar";
import "./basicProfile-style.scss";
import DoneIcon from "@material-ui/icons/Done";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EditIcon from "@material-ui/icons/Edit";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import RedeemIcon from "@material-ui/icons/Redeem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import GradeIcon from "@material-ui/icons/Grade";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import skill from "../../Assets/skill-black.png";
import { connect } from "react-redux";
import { setUserData } from "../../redux/user/user-actions";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectCompanyDepartments,
} from "../../redux/user/user-selector";
import Logout from "../../logout";
import axios from "axios";
import getDepartmentName from "../../utils/getDepartmentName";

function BasicProfile({
  mode,
  handleEdit,
  info,
  handleProfilePicChange,
  user,
  currentUserId,
  Location,
  history,
  setUserBadges,
  userData,
  setUserData,
  companyDepartments,
}) {
  const [collabModal, setCollabModal] = useState(false);
  const [pendingRequestDetails, setPendingRequestDetails] = useState({
    user: {},
    role: 0,
    details: {},
  });
  const [pendingModal, setPendingModal] = useState(false);
  const [donateBadge, setDonateBadge] = useState(false);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [endorsementDetails, setEndorsementDetails] = useState(null);
  const [endorseModal, setEndorseModal] = useState(false);
  const [endorseLoading, setEndorseLoading] = useState(false);
  const [endorseSuccess, setEndorseSuccess] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const token = localStorage.getItem("bhyve-token");

  // useEffect()

  const handleCollab = async () => {
    try {
      const fetchCollabInfoResult = await axios.post(
        `${process.env.REACT_APP_API_URL}collaboration/participants`,
        { participants: [info.publicId, currentUserId] },
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        fetchCollabInfoResult.data === "" ||
        fetchCollabInfoResult.data.length === 0
      ) {
        setCollabModal(true);
      } else {
        console.log(fetchCollabInfoResult.data[0]);
        switch (fetchCollabInfoResult.data[0].status) {
          case 1:
            let user = fetchCollabInfoResult.data[0].participants.filter(
              (item) => item !== currentUserId
            );
            history.push(`/User/Chat/${user[0]}`);
            break;

          case 0:
            if (fetchCollabInfoResult.data[0].createdBy === currentUserId) {
              setPendingRequestDetails({
                user: {
                  name: info.firstName,
                },
                details: fetchCollabInfoResult.data[0],
                role: 0,
              });
              setPendingModal(true);
            } else {
              setPendingRequestDetails({
                user: {
                  name: info.firstName,
                },
                details: fetchCollabInfoResult.data[0],
                role: 1,
              });
              setPendingModal(true);
            }
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const updateCollabRequest = async (req) => {
    let updateCollabResult = await axios.patch(
      `${process.env.REACT_APP_API_URL}collaboration`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return updateCollabResult;
  };

  const handelAccept = async (data) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 1,
        rejectReason: "",
      };
      let acceptRequestResult = await updateCollabRequest(reqObject);
      if (acceptRequestResult) {
        console.log(acceptRequestResult);
        let user = data.participants.filter((item) => item !== currentUserId);
        history.push(`Chat/${user}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDecline = async (data, reason) => {
    try {
      let reqObject = {
        collaborationPublicId: data.collaborationPublicId,
        status: 2,
        rejectReason: reason,
      };
      let declineRequestResult = await updateCollabRequest(reqObject);
      if (declineRequestResult) {
        console.log(declineRequestResult);
        setPendingModal(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDonateBadge = async (id) => {
    try {
      if (id) {
        setDonateBadge(false);
        setLoading(true);
        let donateABadgeResult = await donateABadge(id);
        if (donateABadgeResult) {
          console.log(donateABadgeResult);
          let currentObject = userData;
          setUserData({
            ...currentObject,
            userInformation: donateABadgeResult.data.currentUserInformation,
          });
          setUserBadges(
            donateABadgeResult.data.badgeReceiverUserInformation.badges
          );
          setLoading(false);
          setMessage("Badge gifted successfully!");
          setSeverity("success");
          setAlertOpen(true);
        }
      } else {
        setMessage("Please select a badge to gift!");
        setSeverity("warning");
        setAlertOpen(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      setMessage("Badge could not be gifted!");
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const donateABadge = async (id) => {
    const req = {
      receiverUserPublicId: info.publicId,
      badgePublicId: id,
    };
    console.log(req);
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}badges/donate`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleOpenModal = () => {
    if (userData.userInformation.totalBadgeDonationsAllowed > 0) {
      setDonateBadge(true);
    } else {
      setMessage("You have reached your daily badge gifting limit");
      setSeverity("warning");
      setAlertOpen(true);
    }
  };

  const handleEndorsement = () => {
    try {
      let user = {
        name: `${info.firstName} ${info.lastName}`,
        profilePhotoUrl: info.profilePhotoUrl,
      };
      let details = {
        user: user,
        skills: info.userSkillsSet.userSkills,
      };
      setEndorsementDetails(details);
      setEndorseModal(true);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const handleEndorsementSubmit = async (
    review,
    recommendation,
    skillPublicId
  ) => {
    try {
      setEndorseLoading(true);

      let skill = info.userSkillsSet.userSkills.find(
        (item) => item.skillPublicId === skillPublicId
      );

      let reqObject = {
        from: userData.userInformation.publicId,
        to: info.publicId,
        skillPublicId: skillPublicId,
        skillName: skill.skillName,
        skillDisplayName: skill.skillDisplayName,
        review: review,
        recommendation: recommendation,
      };

      console.log(reqObject);
      //setEndorseModal(false);

      const endorseResult = await axios.post(
        `${process.env.REACT_APP_API_URL}openEndorsement/endorse`,
        reqObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );

      if (endorseResult) {
        console.log(endorseResult);
        setEndorseLoading(false);
        setEndorseModal(false);
        setEndorsementDetails(null);
        setSelectedSkill(skill.skillDisplayName);
        setEndorseSuccess(true);
        setTimeout(() => setEndorseSuccess(false), 1500);
      }
    } catch (error) {
      setEndorseLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  let userAgentString = navigator.userAgent;

  console.log("My Info", info);

  return mode === "view" ? (
    <Paper className="basic-profile-container">
      <Loader open={loading} />
      <Snackbar
        Open={alertOpen}
        severity={severity}
        alert={message}
        handleClose={() => setAlertOpen(false)}
      />
      <div
        style={{
          width: "100%",
          textAlign:
            userAgentString.indexOf("Firefox") > -1
              ? "-moz-center"
              : "-webkit-center",
        }}
      >
        {info && info.profilePhotoUrl && (
          <Avatar
            alt="Remy Sharp"
            src={info && info.profilePhotoUrl}
            className="profile-pic"
          />
        )}
        {info && info.profilePhotoUrl === "" && (
          <Typography
            variant="h3"
            className="initial-container"
            style={{
              textAlign:
                userAgentString.indexOf("Firefox") > -1
                  ? "-moz-center"
                  : "-webkit-center",
              paddingTop: "7%",
              color: "white",
              background: "#FFCC33",
              marginBottom: "15px",
            }}
          >
            {`${info.firstName.slice(0, 1)}${info.lastName.slice(0, 1)}`}
          </Typography>
        )}
      </div>
      {info && (
        <Typography
          variant="subtitle1"
          className="profile-content"
          style={{ fontWeight: "600" }}
        >
          {info.firstName} {info.lastName}
        </Typography>
      )}
      {!!info && info.department && (
        <Typography variant="body1" className="profile-content user-title">
          {getDepartmentName(info.department, companyDepartments)}
        </Typography>
      )}
      {Location && (
        <Typography
          style={{ marginBottom: "10px" }}
          variant="body1"
          className="profile-content muted"
        >
          {!Location.streetAddress || Location.streetAddress.length === 0
            ? `${Location.city}, ${Location.country}`
            : `${Location.streetAddress}, ${Location.city}`}
        </Typography>
      )}
      {/* {info && !info.socialMediaUrls && <br/>} */}
      {info && info.socialMediaUrls && (
        <div className="social-container muted">
          {info && info.socialMediaUrls.facebook !== "" ? (
            <Link
              style={{ color: "gray" }}
              target="_blank"
              rel="noopener"
              href={info.socialMediaUrls.facebook}
            >
              <FacebookIcon />
            </Link>
          ) : (
            ""
          )}
          {info && info.socialMediaUrls.twitter !== "" ? (
            <Link
              style={{ color: "gray" }}
              target="_blank"
              rel="noopener"
              href={info.socialMediaUrls.twitter}
            >
              <TwitterIcon />
            </Link>
          ) : (
            ""
          )}
          {info && info.socialMediaUrls.linkedIn !== "" ? (
            <Link
              style={{ color: "gray" }}
              target="_blank"
              rel="noopener"
              href={info.socialMediaUrls.linkedIn}
            >
              <LinkedInIcon />
            </Link>
          ) : (
            ""
          )}
        </div>
      )}
      {user && handleEdit && (
        <Button
          startIcon={<EditIcon />}
          endIcon={<ArrowForwardIosIcon />}
          className="profile-content edit-btn"
          onClick={() => handleEdit()}
        >
          Edit Profile
        </Button>
      )}
      {!user && (
        <Button
          startIcon={<QuestionAnswerIcon />}
          endIcon={<ArrowForwardIosIcon />}
          className="profile-content edit-btn"
          onClick={() => handleCollab()}
        >
          Collaborate
        </Button>
      )}
      {!user && (
        <Button
          startIcon={<RedeemIcon />}
          endIcon={<ArrowForwardIosIcon />}
          className="profile-content edit-btn"
          onClick={() => handleOpenModal()}
        >
          Gift badge
        </Button>
      )}
      {!user && (
        <Button
          startIcon={<GradeIcon />}
          endIcon={<ArrowForwardIosIcon />}
          className="profile-content edit-btn"
          onClick={handleEndorsement}
        >
          Endorse
        </Button>
      )}
      {!user && (
        <CreateCollabModal
          open={collabModal}
          handleClose={() => setCollabModal(false)}
          userId={currentUserId}
          collabUserId={info.publicId}
          companyDepartments={companyDepartments}
        />
      )}
      {!user && (
        <PendingCollabModal
          open={pendingModal}
          handleClose={() => setPendingModal(false)}
          pendingRequestDetails={pendingRequestDetails}
          handelAccept={handelAccept}
          handleDecline={handleDecline}
        />
      )}
      {!user && (
        <DonateBadgeModal
          open={donateBadge}
          handleClose={() => setDonateBadge(false)}
          handleDonateBadge={handleDonateBadge}
        />
      )}
      {!user && (
        <EndorsementModal
          openEndorsement={true}
          open={endorseModal}
          handleClose={() => {
            setEndorsementDetails(null);
            setEndorseModal(false);
          }}
          details={endorsementDetails}
          handleSubmit={handleEndorsementSubmit}
          loading={endorseLoading}
        />
      )}
      {!user && (
        <SuccessModal
          open={endorseSuccess}
          handleClose={() => setEndorseSuccess(false)}
          skill={selectedSkill}
          role={1}
        />
      )}
    </Paper>
  ) : mode === "edit" ? (
    <Paper className="basic-profile-container">
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign:
              userAgentString.indexOf("Firefox") > -1
                ? "-moz-center"
                : "-webkit-center",
          }}
        >
          {info && info.profilePhotoUrl !== "" && (
            <Avatar
              alt="Remy Sharp"
              src={info.profilePhotoUrl}
              className="profile-pic"
            />
          )}
          {info && info.profilePhotoUrl === "" && (
            <Typography
              variant="h2"
              className="initial-container"
              style={{
                textAlign:
                  userAgentString.indexOf("Firefox") > -1
                    ? "-moz-center"
                    : "-webkit-center",
                paddingTop: "6%",
                color: "white",
                background: "#FFCC33",
                marginBottom: "15px",
              }}
            >
              {`${info.firstName.slice(0, 1)}${info.lastName.slice(0, 1)}`}
            </Typography>
          )}
        </div>
      </div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => handleProfilePicChange(e)}
      />
      <label htmlFor="contained-button-file">
        <Button component="span" style={{ color: "rgb(238, 129, 58)" }}>
          Upload
        </Button>
      </label>
      {info && (
        <Typography
          variant="h6"
          style={{ fontSize: "20px" }}
          className="profile-content"
        >
          {info.firstName} {info.lastName}
        </Typography>
      )}
      {info && info.department && (
        <Typography
          variant="body1"
          style={{ fontSize: "16px" }}
          className="profile-content user-title"
        >
          {getDepartmentName(info.department, companyDepartments)}
        </Typography>
      )}

      {Location && (
        <Typography variant="caption" className="profile-content muted">
          {Location.city}, {Location.country}
        </Typography>
      )}
      <br />
    </Paper>
  ) : (
    <div></div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  companyDepartments: selectCompanyDepartments,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicProfile)
);
