/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import {Typography,CircularProgress} from '@material-ui/core';
import SearchGrid from '../../../Components/search-grid/search-grid-component';
import axios from 'axios';
// import  Logout from '../../../logout';
import {withRouter} from 'react-router-dom';
import './Search-style.scss';

function SearchPage(props){
  const { history, location } = props
  const [searchQuery, setSearchQuery] = useState('')

  const[results,setResults] = useState([]);
  const[loading,setLoading] = useState(true);

  useEffect(() => {
    if(location.search) {
      const search = new URLSearchParams(location.search)
      if(search.get('q')) {
        setSearchQuery(search.get('q'))
      }
    }
  }, [location])

  useEffect(()=>{
    if(!searchQuery.length) return
    try {
      const search = async query => {
        const token = localStorage.getItem('bhyve-token');
        const result = await axios.post(`${process.env.REACT_APP_API_URL}search/unknown`,{searchQuery},{
          headers:{
            'Authorization': `Bearer ${token}`,
            'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
          }
        });
        return result;
      };
      async function SearchQuery(){
        try {
          let searchResult = await search(searchQuery.toLowerCase())
          if(searchResult){
            setResults(searchResult.data);
            console.log(searchResult);
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
          if(err.response && err.response.status === 401){
            history.push('/logout');
          }
          setLoading(false);
        }
      }
      SearchQuery();
    } catch (error) {
     console.log(error.message); 
    }
    return ()=> {
      window.scrollTo(0,0);
   }
  },[searchQuery]);

  function capitalize(input) {  
    try {
      let word = input;
      if(word) {
        var words = word.split(' ');  
        var CapitalizedWords = [];  
        let wordsTobeIgnored = ['and','the','of','on','are', 'a']; 
        words.forEach(element => {
            let currentElement = '';  
            if(element.split('').length > 1 && element.split('').includes('&')) {
              let temp = element.split[0];
              currentElement = element.split('')[0].toUpperCase() + '&' + element.split('')[2].toUpperCase();
            }else{
              currentElement = element;
            }
            if(currentElement === 'cpr') {
              CapitalizedWords.push('CPR');  
            }else if(currentElement === 'chp') {
              CapitalizedWords.push('CHP');  
            }else if(wordsTobeIgnored.includes(currentElement)) {
              CapitalizedWords.push(currentElement); 
            }else {
              CapitalizedWords.push(currentElement[0].toUpperCase() + currentElement.slice(1, currentElement.length));  
            }         
        });  
        return CapitalizedWords.join(' ');  
      }else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  } 


  return(
    <div className='search-page-main-container'>
      <div className='search-page-container'>
        <Typography className='search-title'><Typography variant='body1' component='span'>Results for</Typography>  <Typography variant='h5' component='span' style={{fontWeight:'bold',marginLeft:'2px',fontSize:'1.3rem!important'}}>{capitalize(searchQuery)}</Typography></Typography>
        {loading&&<div style={{width:'100%',textAlign:'center'}}><CircularProgress/></div>}
        {!loading&&<SearchGrid results={results} query={searchQuery}/>}
      </div>
    </div>
  )
}

export default withRouter(SearchPage);
