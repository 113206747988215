export default `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
body {
  font-family: 'Montserrat', sans-serif;
  font-size:16px}
  .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{
      left:80px
}
blockquote {
    padding: 10px 20px;
    margin-left: 0px;
    font-style: italic;
    position: relative;
    display: inline-block;
  }
  blockquote:before,
  blockquote:after {
    content: "";
    background-image: url("https://static.thenounproject.com/png/14163-200.png");
    background-size: cover;
    width: 26px;
    height: 21px;
    display: block;
    position: absolute;
    left: -8px;
    top: 0px;
    z-index: 10;
    opacity: 0.3;
  }
  blockquote:after{
      left: auto;
      top: auto;
      bottom: 0px;
      right: -8px;
      transform: rotate(180deg);
  }
  blockquote > p {
    margin: 3px 0px;
    // font-family: georgia;
    // font-weight: 900;
    font-style: inherit;
    color: #898989;
    display: table-row;
    word-break: break-word;
  }
  table {
    width: 100% !important;
  }
  td {
    word-break: break-word;
    padding: 0.4rem;
    border-color: #b1b1b1;
  }
  img{
    max-width: 100%;
    height: auto;
  }
`