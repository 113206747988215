import cmsAxios from "../../../axios/authAxios";
export default async (file, name) => {
    try {
        const result = new File([file], name, { type: file.type });
        const formData = new FormData();
        formData.append("file", result);
        return await cmsAxios.post('cms/upload', formData)
    } catch (err) {
        console.log(err)
        return {error:err}
    }
}