import React, { useEffect, useState } from "react";
import he from "he";
import style from "./articleListItem.module.scss";
import DeleteDialog from "../DeleteDialog";
import { useRef } from "react";
import Profile from "../profile";
import Thumbnail from "../thumbnail";
import Button from "../button";
import ArticleAction from "../articleAction";
import BookmarkBtn from "../bookmarkBtn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Comment from "../comment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ArticleShareDialog from "../articleShareDialog";
import ArticleListItemOptionDialog from "../articleListItemsOptionDialog";
import settingDate from "../settingDate/settingDate";
import getReadTime from "../../util/getReadTime";
import UserIntitials from "../userInitials/userInitials";
import { withRouter } from "react-router-dom";
import cmsAxios from "../../axios/authAxios";
import logout from "../../../../../logout";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCompanyDepartments } from "./../../../../../redux/user/user-selector";
import getDepartmentName from "../../../../../utils/getDepartmentName";

const ArticleListItem = ({
  articleId,
  author,
  tag,
  publishedOn,

  articleTitle,
  articleContent,

  like,
  likesNo,
  handleLike,
  // comment,
  commentNo,
  handleComment,
  viewsNo,
  handleView,
  bookmark,
  handleBookmark,

  articleCover,
  articleThumbnail,

  comment,

  className,
  onClick,
  read,
  editArticleEligible,
  disableBookMark,
  handleMore,

  edit,
  share,
  remove,
  handleDelete,
  handleEdit,
  isDraft,
  isEdited,
  match,
  history,
  moreOptionHidden,
  companyDepartments,
  ...props
}) => {
  const wrapper = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState();
  const [isMoreDialogOpen, setIsMoreDialogOpen] = useState(false);
  const [isShareArticleDialogOpen, setIsShareArticleDialogOpen] =
    useState(false);
  const selectedArticleRef = React.useRef(null);
  const [isLiked, setIsLiked] = React.useState(false);
  const [isCommented, setIsCommented] = React.useState(false);
  const [isViewed, setIsViewed] = React.useState(false);
  const [isBookmarked, setIsBookmarked] = React.useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  React.useEffect(() => {
    if (like) setIsLiked(true);
    if (bookmark) setIsBookmarked(true);
  }, []);

  const handleNavigateToComment = () => {
    let redirectUrl = `cms/${articleId}/singleArticle?scrollToComment=true`;
    history.push(redirectUrl);
  };

  const [inDeletionProcess, setInDeletionProcess] = useState(false);

  const handleDeletionProcess = (articleId) => {
    setInDeletionProcess(true);
    handleDelete(articleId);
  };

  const navigateToUser = async (user) => {
    try {
      let query = user.split("_").join(" ");
      let userInfo = await cmsAxios.post(
        `${process.env.REACT_APP_API_URL}search/unknown`,
        { searchQuery: query }
      );
      history.push(`/User/Profile/${userInfo.data.users[0].publicId}`);
    } catch (error) {
      console.log(error);
      if (error.status && error.status.code === "401") {
        logout();
      }
    }
  };

  const getComment = (comment) => {
    if (comment) {
      return (
        <>
          <Comment
            author={`${comment.user.firstName} ${comment.user.lastName}`}
            comment={comment.comment}
            profile={comment.user.profilePhotoUrl}
            designation={comment.user.department}
            userr={comment.user}
            isExpandable
            navigateToAuthor={() =>
              history.push(`/User/Profile/${comment.user.publicId}`)
            }
            navigateToUser={navigateToUser}
          />
          <Button
            size="small"
            style={{ "margin-left": -5 }}
            onClick={handleNavigateToComment}
          >
            View more comments
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  // const handleClose = () => setMenuOpen(false);

  // const handleOpenShareDialog = e => {
  //   e.stopPropagation();
  //   setShareArticle(true);
  // }

  // const popperMenu = () => {
  //   return (
  //     <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
  //       {({ TransitionProps, placement }) => (
  //         <Grow
  //           {...TransitionProps}
  //           style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
  //         >
  //           <Paper className={style.menuPaper}>
  //             <ClickAwayListener onClickAway={handleClose}>
  //               <MenuList autoFocusItem={menuOpen} id="menu-list-grow" >
  //                 {editArticleEligible && <MenuItem onClick={handleClose} className={style.menuItem}>
  //                   <ListItemIcon>
  //                     <EditIcon style={{ color: 'black' }} />
  //                   </ListItemIcon>
  //                   <small>Edit</small>
  //                 </MenuItem>}
  //                 <MenuItem onClick={handleOpenShareDialog} className={style.menuItem}>
  //                   <ListItemIcon>
  //                     <ShareIcon style={{ color: 'black' }} />
  //                   </ListItemIcon>
  //                   <small>Share Content</small>
  //                 </MenuItem>
  //                 <MenuItem onClick={handleClose} style={{ color: 'red' }} className={style.menuItem}>
  //                   <ListItemIcon>
  //                     <DeleteIcon style={{ color: 'red' }} />
  //                   </ListItemIcon>
  //                   <small>Delete Content</small>
  //                 </MenuItem>
  //               </MenuList>
  //             </ClickAwayListener>
  //           </Paper>
  //         </Grow>
  //       )}
  //     </Popper>
  //   )
  // }
  const handleClick = (e) => (onClick ? onClick(e, articleId) : null);
  const handleMoreBtnClick = (e) => {
    e.stopPropagation();
    selectedArticleRef.current = e.currentTarget;
    setIsMoreDialogOpen(true);
    if (handleMore) handleMore(e, articleId);
  };
  const handleLikeClick = (e, id) => {
    e.stopPropagation();
    if (handleLike) {
      handleLike(e, id, setIsLiked, isLiked);
    } else {
      setIsLiked(!isLiked);
    }
    // if (!likedByMe) {
    //   likeClick(e, id)
    //   return;
    // }
    // unLike(e, id)
  };
  const handleCommentClick = (e) => {
    e.stopPropagation();
    setIsCommented(!isCommented);
  };
  const handleBookMarkClick = (e, id) => {
    e.stopPropagation();
    if (handleBookmark) {
      handleBookmark(e, id, setIsBookmarked, isBookmarked);
    } else {
      setIsBookmarked(!isBookmarked);
    }
    // if (!bookmarkedByMe) {
    //   bookMarkClick(e, id)
    //   return;
    // }
    // unBookMark(e, id)
  };
  useEffect(() =>
    setWrapperWidth(wrapper.current.getBoundingClientRect().width)
  );

  return (
    <>
      <div
        {...props}
        id="article-wrapper"
        className={`${style.main_wrapper} ${className} m-md p-sm`}
        ref={wrapper}
        style={
          inDeletionProcess
            ? { backgroundColor: "#D3D3D3" }
            : { backgroundColor: "#FFF" }
        }
      >
        <div className={`${style.wrapper}`}>
          <div className={`${style.thumbnail_wrapper}`} onClick={handleClick}>
            <Thumbnail image={articleThumbnail} />
          </div>
          <div className={`${style.content} ml-md`}>
            {match.url !== "/User/cms" && (
              <div
                className={`${style.author_wrapper}`}
                onClick={() => history.push(`/User/Profile/${author.publicId}`)}
              >
                {author && author.profilePhotoUrl !== "" && (
                  <Profile image={author.profilePhotoUrl} size="sm" />
                )}
                {author && author.profilePhotoUrl === "" && (
                  <UserIntitials
                    firstName={author.firstName}
                    lastName={author.lastName}
                  />
                )}
                <p className={`w-600 my-0`}>
                  {author && author.firstName
                    ? `${author.firstName} ${author.lastName}`
                    : "no author"}
                </p>
                {!!author &&
                  !!author.department &&
                  !!companyDepartments &&
                  companyDepartments.length > 0 && (
                    <span className={`${style.separator}`}></span>
                  )}
                {!!author &&
                  !!author.department &&
                  !!companyDepartments &&
                  companyDepartments.length > 0 && (
                    <p className={`w-300 my-0`}>
                      {getDepartmentName(author.department, companyDepartments)}
                    </p>
                  )}
                {/* <small className={`ml-auto`}>{tag ? `#${tag}` : "#trending"}</small> */}
              </div>
            )}
            <div
              className={`${style.article_content_wrapper} mt-sm`}
              onClick={handleClick}
            >
              <h3
                style={{ width: wrapperWidth - 250 }}
                className={`${style.article_title} my-0 ${
                  match.url === "/User/cms" && style.myArticleTitle
                }`}
              >
                {" "}
                {articleTitle ? articleTitle : "No Title"}
              </h3>
              <p
                style={{ width: wrapperWidth - 190 }}
                className={`${style.article_content} ${
                  match.url === "/User/cms" && style.myArticleContent
                }`}
              >
                {articleContent ? articleContent : ""}
              </p>
              {!!publishedOn && (
                <p className={style.publishedOn}>
                  {isDraft ? `Last Updated on` : `Published on`}{" "}
                  {publishedOn && settingDate(publishedOn)}
                  {isEdited ? " (Edited)" : ""}
                </p>
              )}
            </div>
            <div className={`${style.bottom_wrapper}`}>
              <div className={`${style.badge_wrapper}`}>
                <ArticleAction viewOnly active={isLiked} icon="like">
                  {likesNo ? likesNo : "0"}
                </ArticleAction>
                <ArticleAction viewOnly active={isCommented} icon="comment">
                  {commentNo ? commentNo : "0"}{" "}
                </ArticleAction>
                <ArticleAction viewOnly active={isViewed} icon="views">
                  {viewsNo ? `${viewsNo} views` : "0"}
                </ArticleAction>
                <small className={`${style.read_length} ml-md`}>
                  {getReadTime(read ? read : "")}
                </small>
              </div>
              {!inDeletionProcess && (
                <div>
                  {disableBookMark ? null : (
                    <BookmarkBtn
                      active={isBookmarked}
                      onClick={(e) => handleBookMarkClick(e, articleId)}
                      className={`${style.bookMarkBtn}`}
                    />
                  )}
                  {!moreOptionHidden && (
                    <Button
                      size="small"
                      id="published-menu-btn"
                      startIcon={<MoreVertIcon />}
                      className={`${style.optionsBtn}`}
                      onClick={handleMoreBtnClick}
                    />
                  )}
                </div>
              )}
            </div>
            <div> {getComment(comment)} </div>
          </div>
        </div>
      </div>
      <DeleteDialog
        open={openDeleteModal}
        setDeleteModal={setDeleteModal}
        deleteArticle={() => handleDeletionProcess(articleId)}
      />
      {/* {popperMenu()} */}
      {/* <ArticleShareDialog open={shareArticle} handleClose={() => setShareArticle(false)} shareArticle={() => setShareArticle(false)} /> */}
      <ArticleListItemOptionDialog
        handleEdit={() => handleEdit(articleId)}
        share={share}
        handleDeleteOpenDialog={setDeleteModal}
        remove={remove}
        edit={edit}
        isOpen={isMoreDialogOpen}
        handleClose={() => setIsMoreDialogOpen(false)}
        elRef={selectedArticleRef.current}
      />
      <ArticleShareDialog
        open={isShareArticleDialogOpen}
        handleClose={() => setIsShareArticleDialogOpen(false)}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  companyDepartments: selectCompanyDepartments,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticleListItem)
);
//withRouter(ArticleListItem);
