/*jshint esversion:9*/

import { userActionTypes } from "./user-types";

const INITIAL_DATA = {
  UserData: null,
  companyInformation: null,
  points: null,
  locationData: null,
  backgroundColor: null,
  settings: null,
  companyDepartments: [],
};

const UserReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case userActionTypes.SET_USER_DATA:
      return {
        ...state,
        UserData: action.payload,
      };

    case userActionTypes.SET_COMPANY_INFORMATION:
      return {
        ...state,
        companyInformation: action.payload,
      };

    case userActionTypes.SET_COMPANY_DEPARTMENTS:
      console.log("SET_COMPANY_DEPARTMENTS payload", action.payload);
      return {
        ...state,
        companyDepartments: action.payload,
      };

    case userActionTypes.SET_USER_POINTS:
      return {
        ...state,
        points: action.payload,
      };

    case userActionTypes.SET_LOCATION_DATA:
      console.log(action.payload);
      return {
        ...state,
        locationData: action.payload,
      };

    case userActionTypes.SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.payload,
      };

    case userActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
