/*jshint esversion:9*/

import React from 'react';
import {Avatar,Typography} from '@material-ui/core';
import './leaderboard-rankers-style.scss';

function LeaderboardRankers({serial,firstName,lastName,points,img,navigateToUser}){
  return(
    <div className='leaderboard-residents-container'>
      <div className='leaderboard-residents-info'>
      <Typography variant='subtitle2' style={{marginRight:'10px',paddingTop:'5px',display:'flex',justifyContent:'center',flexDirection:'column'}}>{serial+4}</Typography>
      {img!==''&&<Avatar style={{cursor:'pointer',width:'60px',height:'60px'}} src={img} onClick={()=>navigateToUser()}/>}
      {img===''&& <Typography variant='h5' className='initial-container' onClick={()=>navigateToUser()} style={{textAlign:'center!important',display:'',paddingTop:'19%',color:'white',background:'#FFCC33',cursor:'pointer'}}>
          {`${firstName.slice(0,1)}${lastName.slice(0,1)}`}
        </Typography>}
      <div>
        <Typography variant='body1' style={{marginLeft:'10px',paddingTop:'5px',cursor:'pointer',display:'flex',justifyContent:'flex-start'}} onClick={()=>navigateToUser()}>{firstName}</Typography>
        <Typography variant='body1' style={{marginLeft:'10px',paddingTop:'5px',cursor:'pointer',display:'flex',justifyContent:'flex-start'}} onClick={()=>navigateToUser()}>{lastName}</Typography>
      </div>
      </div>
      <div className='rankers-points-container'>
        <Typography varaint='subtitle2' style={{justifyContent:'center',color:'#66CCFF',display:'flex'}}>{points}</Typography>
        <Typography varaint='caption' style={{justifyContent:'center',display:'flex'}}>points</Typography>
      </div>
    </div>
  )
}

export default LeaderboardRankers;
