import React from "react";
import classes from "./addDialog.module.scss";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "../../../button";
import isUrl from "../../../isUrl";

const AddLinkDialog = ({ open, handleClose, handleSave, ...props }) => {
  const [url, setUrl] = React.useState("");
  const [urlError, setUrlError] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [anchorTarget] = React.useState("_blank");
  const handleUrlChange = (e) => {
    let element = e.currentTarget;
    setUrl(element.value);
  };
  const handleTitleChange = (e) => {
    let element = e.currentTarget;
    setTitle(element.value);
  };
  const handleSaveClick = () => {
    let currentUrl = url;
    !isUrl(currentUrl) ? setUrlError("Url should be valid") : setUrlError("");
    if (currentUrl.substring(0, 4) === "www.")
      currentUrl = "http://" + currentUrl;
    title === ""
      ? setTitleError("Title should not be empty")
      : setTitleError("");
    if (handleSave) {
      handleSave({ url: currentUrl, title, target: anchorTarget });
      setUrl("");
      setTitle("");
    } else {
      console.log({ url, title, anchorTarget });
    }
  };
  return (
    <Dialog
      open={!!open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <div className={`${classes.header}`}>
        <h2>Insert Link</h2>
        <IconButton className={`${classes.closeBtn}`} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={`${classes.content}`}>
        <div className={`${classes.form_control}`}>
          <h4 style={{ fontWeight: 900 }}>URL</h4>
          <div>
            <TextField
              placeholder="Enter Video Link Here"
              value={url}
              onChange={handleUrlChange}
              size="small"
              fullWidth
              error={!!urlError}
              helperText={!!urlError ? urlError : ""}
              variant="outlined"
            />
          </div>
        </div>
        <div className={`${classes.form_control}`}>
          <h4 style={{ fontWeight: 900 }}>Text to Display</h4>
          <div>
            <TextField
              placeholder="Enter Text Here"
              value={title}
              onChange={handleTitleChange}
              error={!!titleError}
              helperText={!!titleError ? titleError : ""}
              size="small"
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
        <Button
          onClick={handleSaveClick}
          className={`${classes.save_btn}`}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default AddLinkDialog;
