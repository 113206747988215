import React from 'react';
import classes from './editorHintDialog.module.scss';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
const EditorHintDialog = ({ el, open, handleClose, ...props }) => {
    return (
        <Popper className={classes.main_wrapper} anchorEl={el} open={open} placement="top-end">
            <ClickAwayListener onClickAway={handleClose} >
                <Paper elevation={3}>
                        <IconButton aria-label="delete" onClick={handleClose} className={`${classes.close}`}>
                            <CloseIcon />
                        </IconButton>
                    <div className={classes.wrapper}>
                        <ErrorOutlineIcon />
                        <div className={classes.separate}></div>
                        <p>A title and content<br />
                            is require to preview it</p>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}

export default EditorHintDialog;