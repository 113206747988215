/*jshint esversion:9*/
/*jshint -W087*/

import React, { useState, useEffect } from "react";
import {
  Avatar,
  Fade,
  InputAdornment,
  TextField,
  Button,
  Paper,
} from "@material-ui/core";
import "./createProfile-style.scss";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// import WcIcon from "@material-ui/icons/Wc";
import CakeIcon from "@material-ui/icons/Cake";
import Input from "../SubComponents/Input/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { setUserData, setLocationData } from "../../redux/user/user-actions";
// import Loader from "../SubComponents/loader/loader-component";
// import Logout from "../../logout";
import axios from "axios";
// import moment from "moment";
import { useHistory } from "react-router";

export const AGE_GROUP_MAP = {
  junior1: "Under 20",
  junior2: "20-25",
  junior3: "26-30",
  mid1: "31-35",
  mid2: "36-40",
  mid3: "41-45",
  mid4: "46-50",
  senior1: "51-55",
  senior2: "56-60",
  senior3: "60 Above",
};

function CreateProfile({
  userDetails,
  addressList,
  // handleNext,
  // setUserData,
  // setLocationData,
  handleChange,
  // handleDateChange,
  setProfileImageUrl,
  setProfileImageKey,
  locationError,
  firstNameError,
  lastNameError,
  companyDetails,
  ageRangeError,
  departmentError,
}) {
  const history = useHistory();
  const [imgLoading, setImgLoading] = useState(false);
  const [imageKey, setImageKey] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  // const [details, setDetails] = useState({
  //   firstName: "",
  //   lastName: "",
  //   department: "",
  //   userLocation: null,
  //   gender: "",
  //   ageRange: "",
  // });
  // const [locationError,setLocationError] = useState(false);
  // const [firstNameError, setFirstNameError] = useState(false);
  // const [lastNameError, setLastNameError] = useState(false);
  // const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("bhyve-token");
  const [departments, setDepartments] = useState(null);

  const [userGender, setUserGender] = useState(null);
  const genderConstants = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Other", value: "other" },
  ];

  useEffect(() => {
    const getDepartments = async (companyPublicId) => {
      const result = await axios.get(
        `${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${companyPublicId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );

      const departments = result.data?.companyDepartments ?? [];
      setDepartments(departments);
    };

    if (companyDetails?.companyPublicId) {
      getDepartments(companyDetails.companyPublicId);
    }
  }, [companyDetails]);

  //this useEffect is called to prefill the existing details into the 'details' useState
  useEffect(() => {
    try {
      //scrolls the screen to the top on initial render and refresh
      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        window.scrollTo(0, 0);
      });

      if (userDetails && addressList) {
        console.log(userDetails);
        // setDetails({
        //   firstName:userDetails.firstName,
        //   lastName:userDetails.lastName,
        //   title:userDetails.title,
        //   userLocation:userDetails.userLocation?userDetails.userLocation:addressList[0].companyAddressPublicId,
        //   gender:userDetails.gender,
        //   dateOfBirth:userDetails.dateOfBirth?userDetails.dateOfBirth.split('/').reverse().join('-'):'01-01-1990'
        // });
        if (!!userDetails.gender) {
          setUserGender(userDetails.gender);
        } else {
          // Note: Gender 'Other' is not saved in database unlike male/female
          // Returns 'undefined' in response in order to set Radio field on Authentication.
          setUserGender("other");
        }
        setImageUrl(userDetails.profilePhotoUrl);
        setImageKey(userDetails.profilePhotoKey);
      }
    } catch (error) {
      console.log(error.message);
    }
    return () => {
      window.scrollTo(0, 0);
    };
  }, [userDetails, addressList]);

  //uploads the user's profile picture to the server
  const imageHandler = async (e) => {
    try {
      setImgLoading(true);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      try {
        let response = await uploadProfilePic(formData);
        if (response) {
          setImageUrl(response.data.profilePhotoUrl);
          setImageKey(response.data.profilePhotoKey);
          setProfileImageUrl(response.data.profilePhotoUrl);
          setProfileImageKey(response.data.profilePhotoKey);
          setImgLoading(false);
        }
      } catch (error) {
        console.log(error);
        setImgLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const uploadProfilePic = async (formData) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}user/me/profile-picture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          },
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  //handles change in textfields except that of the birth-date
  // const handleChange = e => {
  //   console.log(e.target.value);
  //   const {name,value} = e.target;
  //   setDetails(prevValue => {
  //     return{
  //       ...prevValue,
  //       [name]:value
  //     };
  //   });
  // };

  //handle change in the birth date
  // const handleDateChange = (value,name) => {
  //   setDetails(prevValue => {
  //     return{
  //       ...prevValue,
  //       [name]:moment(value).format('yyyy-MM-DD')
  //     };
  //   });
  // }

  //handles updating user profile
  // const handleSave = async () => {};

  const focusFirstNameField = (key) => {
    // 9 is the Key Code for the Tab Key on Keyboard.
    if (key.keyCode === 9) {
      let firstField = document.getElementById(`upload-btn`);
      firstField.focus();
    }
  };

  const getAgerange = (ageKey) => {
    if (!ageKey) return "";
    return ageKey.toLowerCase();
  };

  return (
    <Fade in={true} timeout={2000}>
      <Paper className="inner-container">
        <div className="create-profile-form">
          <div className="dp-container">
            <input
              style={{ display: "none" }}
              id="icon-button-file"
              accept="image/*"
              type="file"
              onChange={imageHandler}
            />
            {!imgLoading && (
              <Avatar alt="Remy Sharp" src={imageUrl} className="dp" />
            )}
            {imgLoading && <CircularProgress />}
            {!imgLoading && (
              <label htmlFor="icon-button-file">
                <Button
                  id="upload-btn"
                  component="span"
                  style={{ color: "rgb(238, 129, 58)" }}
                >
                  Upload
                </Button>
              </label>
            )}
          </div>
          <Input
            id="first-field"
            className="emailInputField"
            variant="outlined"
            error={firstNameError}
            type="text"
            onChange={(e) => handleChange(e)}
            value={userDetails.firstName}
            name="firstName"
            label="First Name"
            placeholder="First Name"
            icon={<PersonOutlineIcon />}
          />
          <Input
            className="emailInputField"
            variant="outlined"
            error={lastNameError}
            type="text"
            onChange={(e) => handleChange(e)}
            value={userDetails.lastName}
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            icon={<PersonOutlineIcon />}
          />
          <Input
            className="emailInputField"
            type="radio"
            label="Gender"
            onChange={(e) => handleChange(e)}
            value={userGender}
            options={genderConstants}
            name="gender"
            placeholder="Gender"
            // Adding forGenderSelection prop so other Radio fields won't break.
            // Using this to accept Gender Constants (options) in key-value pair.
            forGenderSelection={true}
          />
          <div className="textfield-container">
            <label htmlFor="input-age">Age Range</label>
            <TextField
              style={{ width: "100%" }}
              className="input"
              id="input-age"
              select
              variant="outlined"
              placeholder="Age Range"
              value={getAgerange(userDetails.ageRange)}
              name="ageRange"
              onChange={(e) => handleChange(e)}
              onKeyDown={(key) => {
                focusFirstNameField(key);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "gray" }}>
                    <CakeIcon />
                  </InputAdornment>
                ),
              }}
              error={ageRangeError}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" style={{ color: "gray" }}>
                Select from the list
              </option>
              {Object.keys(AGE_GROUP_MAP).map((i) => (
                <option key={i} value={AGE_GROUP_MAP[i].toLowerCase()}>
                  {AGE_GROUP_MAP[i]}
                </option>
              ))}
            </TextField>
          </div>
          <div className="textfield-container">
            <label htmlFor="input-department">Department</label>
            <TextField
              style={{ width: "100%" }}
              className="input"
              id="input-department"
              select
              variant="outlined"
              placeholder="Department"
              value={userDetails.department}
              name="department"
              onChange={(e) => handleChange(e)}
              onKeyDown={(key) => {
                focusFirstNameField(key);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "gray" }}>
                    <WorkOutlineIcon />
                  </InputAdornment>
                ),
              }}
              error={departmentError}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" style={{ color: "gray" }}>
                Select from the list
              </option>
              {departments &&
                departments.map((item) => (
                  <option
                    key={item.departmentPublicId}
                    value={item.departmentPublicId}
                  >
                    {item.departmentDisplayName}
                  </option>
                ))}
            </TextField>
          </div>
          <div className="textfield-container">
            <label htmlFor="input-location">Location</label>
            <TextField
              style={{ width: "100%" }}
              className="input"
              id="input-location"
              select
              variant="outlined"
              placeholder="Location"
              value={userDetails.userLocation}
              name="ageRange"
              onChange={(e) => handleChange(e)}
              onKeyDown={(key) => {
                focusFirstNameField(key);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "gray" }}>
                    <LocationOnOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              error={locationError}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {addressList &&
                addressList.map((item, index) => (
                  <option key={index} value={item.companyAddressPublicId}>
                    {!item.streetAddress || item.streetAddress.length === 0
                      ? !!item.city
                        ? `${item.city}, ${item.country}`
                        : `${item.country}`
                      : `${item.streetAddress}, ${item.city}`}
                  </option>
                ))}
            </TextField>
          </div>
        </div>
      </Paper>
    </Fade>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUserData: (object) => dispatch(setUserData(object)),
  setLocationData: (object) => dispatch(setLocationData(object)),
});

export default connect(null, mapDispatchToProps)(CreateProfile);

{
  /* <div className='dp-container'>
<input  style={{display:'none'}} id="icon-button-file" accept='image/*' type="file" onChange={imageHandler} />
  {!imgLoading && <Avatar alt="Remy Sharp" src={imageUrl} className='dp'/>}
  {imgLoading && <CircularProgress/>}
  {!imgLoading && <label htmlFor="icon-button-file" style={{width:'100%',textAlign:'center',marginTop:'5px'}}>
    <Button  component="span" style={{color:'rgb(238, 129, 58)'}}>
      Upload
    </Button>
  </label>}
</div>
<div className='profile-form'>
  <Input className='emailInputField' helperText='required' error={firstNameError} type='text' onChange={handleChange} value={details.firstName} name='firstName'   placeholder='First Name' icon={<PersonOutlineIcon/>} />
  <br/>
  <Input className='emailInputField' helperText='required' error={lastNameError} type='text' onChange={handleChange} value={details.lastName} name='lastName'   placeholder='Last Name' icon={<PersonOutlineIcon/>} />
  <br/>
  <Input className='emailInputField' type='text' onChange={handleChange} value={details.title} name='title'  placeholder='Designation' icon={<WorkOutlineIcon/>} />
  <br/>
  <br/>
  <TextField
  style={{width:'100%'}}
  helperText='required'
  className='input'
  id="input-with-icon-textfield"
  select
  placeholder='Location'
  value= {details.userLocation}
  name = 'userLocation'
  onChange={handleChange}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start" style={{color:'gray'}}>
      <LocationOnOutlinedIcon/>
  </InputAdornment>
),
}}
  error={locationError}
InputLabelProps={{
    shrink: true,
  }}
SelectProps={{
      native: true,
    }}
>
{
addressList&&addressList.map((item,index)=>
<option key={index} value={item.companyAddressPublicId}>{(!item.streetAddress || item.streetAddress.length === 0) ? `${ item.city}, ${item.country}` : `${item.streetAddress}, ${item.city}`}</option>
)
}
</TextField>
<br/>
<br/>
  <Input className='emailInputField' type='select' onChange={handleChange} value={details.gender} options={['male','female','other']} name='gender'  placeholder='Gender' icon={<WcIcon/>} />
  <br/>
  <br/>
  <Input className='emailInputField' type='date' dateChange={handleDateChange} value={details.dateOfBirth} name='dateOfBirth'   placeholder='Date of Birth' icon={<CakeIcon/>} />
</div>
<br/>
<br/>
<div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
    <Button className='btn' onClick={handleSave}>Next</Button>
</div> */
}
