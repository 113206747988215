/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import {Paper,Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../redux/user/user-selector';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './profile-completion-style.scss';
import {Link} from 'react-router-dom'

function ProfileCompletion({userData, handleProfileComplete, handleEdit}){

  const [progress,setProgress] = useState(0);
  const [steps,setSteps] = useState({
    basicInformation:false,
    profilePicture:false,
    workExperience:false,
    skills:false,
    Interests:false
  });


  useEffect(()=>{
    try {
      var percent=0;
      if(userData) {
        if(
            userData.userInformation.firstName &&
            userData.userInformation.lastName &&
            userData.userInformation.gender &&
            userData.userInformation.dateOfBirth
          ) {
          percent = percent+15;
          setSteps(prevValue => {
              return{
                ...prevValue,
                basicInformation:true
              };
          });
        }

        if(userData.userInformation.profilePhotoUrl) {
          setSteps(prevValue => {
            return{
              ...prevValue,
              profilePicture:true
            };
          });
          percent = percent+5;
        }
  
        if(userData.userInformation.workExperience) { 
          setSteps(prevValue => {
            return{
              ...prevValue,
              workExperience:true
            };
          });
        percent = percent+20;
        }

        if(userData.userInformation.userSkillsSet) {
            if(userData.userInformation.userSkillsSet.userSkills.length < 5) {
              var percentToDeduct = (5-userData.userInformation.userSkillsSet.userSkills.length)*1.25;
              percent = percent+(30-percentToDeduct);
            }else{
              percent = percent+30;
            }
    
          if(userData.userInformation.userSkillsSet.userSkills.length >= 5) {
            setSteps(prevValue => {
              return{
                ...prevValue,
                skills:true
              };
            });
          }
        }
        if(userData.userInformation.userInterestSet) {
          percent = percent+30;
          setSteps(prevValue => {
            return{
              ...prevValue,
              Interests:true
            };
          });
        }
        setProgress(percent);
        if(percent === 100) {
          handleProfileComplete();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  },[userData,handleProfileComplete]);

  function handleWorkExp (e) {
    e.preventDefault();
    window.scrollTo(0,document.body.scrollHeight);
  }


  return(
    <Paper className='completion-container'>
      <div style={{display:'flex',width:'100%',flexWrap:'wrap',justifyContent:'flex-start'}} >
      <Typography variant='h6' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5',marginTop:'5px', width:'100%',textAlign:'left'}} className='title-progress-title'><span style={{padding:'8px 0 4px 0',fontSize:'1.5rem',fontWeight:'720'}}>Complete your profile</span></Typography>
        <div style={{position:'relative', width:'90px', height:'100px',marginRight:'20px',marginTop:'10px',height:'fitContent'}} className='title-progress-logo-container '>
          <div style={{zIndex:'999',width:'100%',overflow:'hidden',position:'relative',height:`calc(100% - ${progress}%)`}}>
            <div style={{position:'absolute',width:'90px',height:'100px',background:'rgb(233,233,233)',clipPath:'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'}} className='title-progress-logo'></div>
          </div>
          <div style={{width:'100%',height:'100%',zIndex:'1',position:'absolute',top:'0'}}>
            <div style={{width:'90px',height:'100px',background:'#66CCFF',clipPath:'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'}} className='title-progress-logo'></div>
          </div>
        </div>
        <h1 style={{fontWeight:'bold',display:'flex',flexDirection:'column',justifyContent:'center',marginTop:'7px',fontSize:'30px',textAlign:'left'}} className='title-progress-title'>{`${progress}%`} <span style={{fontSize:'1.05rem'}}>Completed</span></h1>
      </div>
      <div style={{width:'100%'}}>
        <div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'20px',width:'100%',marginTop:'20px'}} className='completion-entry-grid'>
          <div className='milestones' style={{backgroundColor:steps.basicInformation?'#66CCFF':'#ccc',marginLeft:'30%'}}>
            {steps.basicInformation?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
          </div>
          <p style={{height:'fitToContent',cursor:'pointer',paddingTop:'5px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'1.05rem',fontWeight:'500'}} onClick={handleEdit}>Basic information</p>
        </div>
        <br/>
        <div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'20px',width:'100%',marginTop:'20px'}} className='completion-entry-grid'>
          <div className='milestones' style={{backgroundColor:steps.profilePicture?'#66CCFF':'#ccc',marginLeft:'30%'}}>
          {steps.profilePicture?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
          </div>
          <p style={{height:'fitToContent',cursor:'pointer',paddingTop:'5px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'1.05rem',fontWeight:'500'}} onClick={handleEdit}>Upload Profile Picture</p>
        </div>
      </div>
      <div style={{width:'100%'}}>
      <div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'20px',width:'100%',marginTop:'20px'}} className='completion-entry-grid'>
          <div className='milestones' style={{backgroundColor:steps.skills?'#66CCFF':'#ccc',marginLeft:'30%'}}>
          {steps.skills?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
          </div>
          <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'1.05rem',fontWeight:'500'}} to='/User/EditProfile/EditSkillsCategories'>Add Skills</Link>
        </div>
        <br/>
        <div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'20px',width:'100%',marginTop:'20px'}} className='completion-entry-grid'>
          <div className='milestones' style={{backgroundColor:steps.Interests?'#66CCFF':'#ccc',marginLeft:'30%'}}>
          {steps.Interests?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
          </div>
          <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'1.05rem',fontWeight:'500'}} to='/User/EditProfile/Interest'>Add Interests</Link>
        </div>
      </div>
      <div style={{width:'100%'}}>
      <div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'20px',width:'100%',marginTop:'20px'}} className='completion-entry-grid'>
          <div className='milestones' style={{backgroundColor:steps.workExperience?'#66CCFF':'#ccc',marginLeft:'30%'}}>
          {steps.workExperience?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
          </div>
          <p style={{height:'fitToContent',cursor:'pointer',paddingTop:'5px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'1.05rem',fontWeight:'500'}} onClick={handleWorkExp}>Work Experience</p>
        </div>
      </div>

    </Paper>
  )
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
});

export default connect(mapStateToProps)(ProfileCompletion);

{/* <Typography variant='h6' style={{borderBottom:'1px solid rgba(112, 112, 112,0.5)',lineHeight:'1.5',width:'100%',marginTop:'5px'}}><span style={{padding:'8px 0 3px 0',borderBottom:'1.5px solid black',fontSize:'16px',fontWeight:'600'}}>COMPLETE YOUR PROFILE</span></Typography>
<div style={{display:'flex',width:'100%',justifyContent:'center',paddingTop:'20px'}}>
  <div style={{position:'relative', width:'90px', height:'100px',marginRight:'20px'}}>
    <div style={{zIndex:'999',width:'100%',overflow:'hidden',position:'relative',height:`calc(100% - ${progress}%)`}}>
      <div style={{position:'absolute',width:'90px',height:'100px',background:'rgb(233,233,233)',clipPath:'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'}}></div>
    </div>
    <div style={{width:'100%',height:'100%',zIndex:'1',position:'absolute',top:'0'}}>
      <div style={{width:'90px',height:'100px',background:'#66CCFF',clipPath:'polygon(51% 1%, 100% 24%, 100% 74%, 50% 100%, 0 74%, 0 24%)'}}></div>
    </div>
  </div>
  <h1 style={{fontWeight:'bold',display:'flex',flexDirection:'column',justifyContent:'center',marginTop:'7px',fontSize:'30px',textAlign:'left'}}>{`${progress}%`} <span style={{fontSize:'15px',opacity:'0.6'}}>Completed</span></h1>
</div>
<div style={{width:'80%',marginTop:'20px'}}>
<div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'10px',width:'100%',marginTop:'20px'}}>
  <div className='milestones' style={{backgroundColor:steps.basicInformation?'#66CCFF':'#ccc',marginLeft:'30%'}}>
  {steps.basicInformation?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
  </div>
  <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'14px'}} to='/User/EditProfile/Profile'>Basic information</Link>
</div>
<div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'10px',width:'100%',marginTop:'20px'}}>
  <div className='milestones' style={{backgroundColor:steps.profilePicture?'#66CCFF':'#ccc',marginLeft:'30%'}}>
  {steps.profilePicture?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
  </div>
  <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'14px'}} to='/User/EditProfile/Profile'>Upload Profile Picture</Link>
</div>
<div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'10px',width:'100%',marginTop:'20px'}}>
  <div className='milestones' style={{backgroundColor:steps.workExperience?'#66CCFF':'#ccc',marginLeft:'30%'}}>
  {steps.workExperience?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
  </div>
  <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'14px'}} to='/User/EditProfile/Profile'>Work Experience</Link>
</div>
<div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'10px',width:'100%',marginTop:'20px'}}>
  <div className='milestones' style={{backgroundColor:steps.skills?'#66CCFF':'#ccc',marginLeft:'30%'}}>
  {steps.skills?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
  </div>
  <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'14px'}} to='/User/EditProfile/EditSkillsCategories'>Add Skills</Link>
</div>
<div style={{display:'grid',gridTemplateColumns:'30% 70%',columnGap:'10px',width:'100%',marginTop:'20px'}}>
  <div className='milestones' style={{backgroundColor:steps.Interests?'#66CCFF':'#ccc',marginLeft:'30%'}}>
  {steps.Interests?<DoneIcon style={{color:'white'}}/>:<Typography variant='h6' style={{color:'white'}}>?</Typography>}
  </div>
  <Link style={{paddingTop:'20px',textAlign:'left',color:'black',textDecoration:'none',fontSize:'14px'}} to='/User/EditProfile/Interest'>Add Interests</Link>
</div>
</div> */}