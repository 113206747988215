/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import {Typography} from '@material-ui/core';
import DefaultPoints from '../../../Components/default-points/default-points-component';
import AddDefaultPoints from '../../../Components/add-default-points/add-default-points-component';
import EditModal from '../../../Components/SubComponents/edit-default-action-point-modal/edit-default-action-point-modal';
import Snackbar from '../../../Components/SubComponents/snackbar/snackbar';
import {connect} from 'react-redux';
import {selectDefaultPoints} from '../../../redux/points/points-selector';
import {createStructuredSelector} from 'reselect';
import {setDefaultPoints,addDefaultPoints,editDefaultPoint,deleteDefaultPoint} from '../../../redux/points/points-actions';
import axios from 'axios';
import Logout from '../../../logout';
import './points-style.scss';
import { selectUserData } from '../../../redux/user/user-selector';

function PointsPage({DefaultActionPoints,setDefaultActionPoints,addDefaultActionPoints,editDefaultActionPoint,deleteDefaultActionPoint, userData}){
  const [alert,setAlert] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);
  const [severity,setSeverity] = useState('');
  const [selectedActionPoint,setSelectedActionPoint] = useState(null);
  const [editModal,setEditModal] = useState(false);

    const token = localStorage.getItem('bhyve-token');

useEffect(()=> {
  const getDefaultPoints = async() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const result = await axios.get(`${process.env.REACT_APP_API_URL_POINTS}default-action-points`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
      }
    });
    return result;
  }
  };

  const getAllActions = async() => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

    const result = await axios.get(`${process.env.REACT_APP_API_URL_POINTS}default-action-points/actions`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
      }
    });
    return result;
  }
  };

  const setDefaultPoints = async array => {
    if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
    const result = await axios.post(`${process.env.REACT_APP_API_URL_POINTS}default-action-points/multi`,array,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  }
  }
  try {
    if(!DefaultActionPoints){
      async function gettingDefaultPoints(){
        let getDefaultPointsResult = await getDefaultPoints();
        if(getDefaultPointsResult){
          console.log(getDefaultPointsResult);
          if(getDefaultPointsResult.data.length===0){
            let actionPoints = [];
            let getAllActionsResult = await getAllActions();
            if(getAllActionsResult){
              let keys = Object.keys(getAllActionsResult.data);
                keys.map(item => actionPoints.push({action:getAllActionsResult.data[item],points:0}));
                let setDefaultPointsResult = await setDefaultPoints({actionsPoints:actionPoints})
                if(setDefaultPointsResult){
                  console.log(setDefaultPointsResult);
                  setDefaultActionPoints(setDefaultPointsResult.data);
                }
                return;
            }
          }
          setDefaultActionPoints(getDefaultPointsResult.data);
        }
      }
      gettingDefaultPoints();
    }
  } catch (err) {
    if(err.response){
      console.log(err.response.message);
      if(err.response.status===401){
        Logout();
      }
    }
  }

},[DefaultActionPoints,setDefaultActionPoints,token]);




    const handleAdd = async req => {
      try {
        if(req.action&&req.points){
          const exist = DefaultActionPoints.find(item => item.action.toLowerCase() === req.action.toLowerCase());
          if(exist){
            setAlert('This action already exists!');
            setSeverity('warning');
            setAlertOpen(true);
            return;
          }else{
            let addPointsResult = await addPoints(req);
            if(addPointsResult){
              addDefaultActionPoints(addPointsResult.data);
                setAlert('Default action point added successfully!');
                setSeverity('success');
                setAlertOpen(true);
            }
          }
        }else{
          console.log(req.action.toLowerCase())
          setAlert('Please fill all the fields!');
          setSeverity('warning');
          setAlertOpen(true);
        }
      } catch (err) {
        console.log(err.message);
        setAlert(err);
        setSeverity('error');
        setAlertOpen(true);
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
      }
    };

    const addPoints = async req => {
      if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
      const result = await axios.post(`${process.env.REACT_APP_API_URL_POINTS}default-action-points`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }
    };

    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    const handleEditModal = details => {
      setSelectedActionPoint(details);
      setEditModal(true);
    };

    const handleEditModalClose = () => {
      setSelectedActionPoint(null);
      setEditModal(false);
    };

    const handleEditActionPoint = async action => {
      try {
        if(action.actionPublicId&&action.action!==''&&action.points){
          setAlert('Updating...');
          setSeverity('info');
          setAlertOpen(true);
          let updateActionPointResult = await updateActionPoint(action);
          if(updateActionPointResult){
            editDefaultActionPoint(action);
            setAlert('Updated action point successfully!');
            setSeverity('success');
            setAlertOpen(true);
            setEditModal(false);
          }
        }else{
          setAlert('Please fill all the fields!');
          setSeverity('warning');
          setAlertOpen(true);
        }
      } catch (err) {
        console.log(err.message);
        setAlert(err);
        setSeverity('error');
        setAlertOpen(true);
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
      }
    };

    const updateActionPoint = async action => {
      if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

      const req = {
        action:action.action,
        points:action.points
      };
      const result = await axios.patch(`${process.env.REACT_APP_API_URL_POINTS}default-action-points/${action.actionPublicId}`,req,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });

      return result;
    }
    };

    const handleDelete = async id => {
      try {
        setAlert('Deleting....');
        setSeverity('info');
        setAlertOpen(true);
        let deleteActionPointResult = await deleteActionPoint(id);
        if(deleteActionPointResult){
          deleteDefaultActionPoint(id);
            setAlert('Deleted action point successfully!');
            setSeverity('info');
            setAlertOpen(true);
        }
      } catch (err) {
        console.log(err.message);
        setAlert(err);
        setSeverity('error');
        setAlertOpen(true);
        if(err.response){
          console.log(err.response.message);
          if(err.response.status===401){
            Logout();
          }
        }
      }
    };

    const deleteActionPoint = async id => {
      if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
      const result = await axios.delete(`${process.env.REACT_APP_API_URL_POINTS}default-action-points/${id}`,{
        headers:{
          'Authorization': `Bearer ${token}`,
          'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
        }
      });
      return result;
    }

    }

  return(
    <div className='client-container'>
    <Snackbar Open={alertOpen} alert={alert} severity={severity} handleClose={handleAlertClose}/>
    <div className='greeting'>
    <br/>
    <Typography variant='h3' style={{fontWeight:'bold',marginBottom:'80px'}}>Default value for points</Typography>
    </div>
      <AddDefaultPoints handleAdd={handleAdd}/>
      <br/>
      <DefaultPoints points={DefaultActionPoints&&DefaultActionPoints} handleEditModal={handleEditModal} handleDelete={handleDelete}/>
      <EditModal open={editModal} actionPoint={selectedActionPoint} handleEdit={handleEditActionPoint} handleClose={handleEditModalClose}/>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  DefaultActionPoints:selectDefaultPoints,
  userData: selectUserData
});

const mapDispatchToProps = dispatch => ({
  setDefaultActionPoints: array => dispatch(setDefaultPoints(array)),
  addDefaultActionPoints: object => dispatch(addDefaultPoints(object)),
  editDefaultActionPoint: object => dispatch(editDefaultPoint(object)),
  deleteDefaultActionPoint: id => dispatch(deleteDefaultPoint(id))
});

export default connect(mapStateToProps,mapDispatchToProps)(PointsPage);
