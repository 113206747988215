import React from "react";
import style from "./cropDialog.module.scss";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Slider from "@material-ui/core/Slider";
import SecondaryButton from "../../secondaryButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import getCroppedImg from "./crop";
import Cropper from "react-easy-crop";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: "100%",
  },
  slider: {
    width: 200,
    color: "rgb(102, 204, 252)",
    height: 3,
    marginRight: "5px",
    marginLeft: "5px",
  },
  track: {
    height: 3,
    color: "rgb(102, 204, 252)",
  },
  rail: {
    color: "rgb(102, 204, 252)",
    opacity: 1,
    height: 3,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  button: {
    background: "rgb(102,204,252)",
  },
  closeButton: {
    position: "absolute",
    right: "2%",
    top: "5%",
  },
}));

const CropDialog = ({ open, handleClose, image, setImage,uploadImage,fileName, ...props }) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const handleCropComplete = React.useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      setImage(URL.createObjectURL(croppedImage));
      uploadImage(croppedImage,fileName);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const classes = useStyles();
  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth={true} onClose={handleClose}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p className={`my-0`}>Edit Cover</p>
          <IconButton aria-label="close" className={classes.closeButton}>
            <CloseIcon className={classes.closeButton} onClick={handleClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`p-0`} dividers>
          <div className={`${style.cropperWrapper}`}>
            {image ? (
              <Cropper
                crop={crop}
                image={image}
                zoom={zoom}
                aspect={32 / 9}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions className={`m-md ${style.action_wrapper}`}>
          <div className={`${style.zoom_ctrl_wrapper}`}>
            <RemoveIcon
              onClick={() => {
                if (zoom > 1) setZoom(zoom - 0.1);
              }}
              style={{ zIndex: "10" }}
            />
            <Slider
              className={`${classes.slider} ${classes.track} ${classes.rail}`}
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
            />
            <AddIcon
              onClick={() => {
                if (zoom < 3) setZoom(zoom + 0.1);
              }}
              style={{ zIndex: "10" }}
            />
          </div>
          <SecondaryButton variant="contained" onClick={showCroppedImage}>
            Save and Upload
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropDialog;
