/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import { makeStyles,Typography,Button,IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectBadges} from '../../../redux/badges/badges-selector';
import {setBadges} from '../../../redux/badges/badges-actions';
import Logout from '../../../logout';
import {withRouter} from 'react-router-dom';
import './donate-badge-modal.scss';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width:'50%',
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

 function DonateBadgeModal({open,handleClose,handleDonateBadge,badges,setBadges,history}){
  const classes = useStyles();
  const [selectedBadge,setSelectedBadge] = useState(null);

  useEffect(()=>{
    const token = localStorage.getItem('bhyve-token');
  const getBadges = async() => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}badges`,{
      headers:{
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
          'Authorization': `Bearer ${token}` 
      }
    });
    return result;
  };

  async function gettingBadges() {
    try {
      let getBadgesResult = await getBadges();
      if(getBadgesResult) {
        setBadges(getBadgesResult.data);
      }
    } catch (error) {
      console.log(error);
      if(error.response) {
        console.log(error.response.message);
        if(error.response.status === 401) {
          history.push('/logout');
        }
      }
    }
  }

  if(!badges){
    gettingBadges();
  }
  },[badges]);

  const handleSelectBadge = id => {
    console.log(id);
    setSelectedBadge(id);
  };

  return(
    <div>
    <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     className={classes.modal}
     open={open}
     onClose={handleClose}
     closeAfterTransition
     BackdropComponent={Backdrop}
     BackdropProps={{
       timeout: 500,
     }}
   >
     <Fade in={open}>
       <div className={classes.paper}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
              <Typography variant='h4' style={{fontWeight:'bold',textAlign:'center',width:'100%'}}>Select a badge</Typography>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon/>
              </IconButton>
        </div>
         <br/>
         <div className='badges-container'>
          {badges && badges.map((item,index) =>
            <div key={index} className='badge-container' onClick={()=>handleSelectBadge(item.publicId)} style={{boxShadow:selectedBadge===item.publicId?'inset 0 0 5px gray':''}}>
            <img alt='badge' src={item.badgeImageUrl} width='200' heigth='300'/>
            <Typography variant='h6'>{item.badgeName}</Typography>
            </div>
          )}
         </div>
         <Button variant='contained' style={{padding:'10px 30px 10px 30px'}} className='edit-btn' onClick={()=>handleDonateBadge(selectedBadge)}>Gift</Button>
       </div>
     </Fade>
   </Modal>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  badges:selectBadges,
});

const mapDispatchToProps = dispatch => ({
  setBadges: array => dispatch(setBadges(array))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DonateBadgeModal));
