import axios from 'axios'

let token = localStorage.getItem('bhyve-token');



const cmsAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}` ,
    headers: {
        'x-api-key': '1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
})

cmsAxios.interceptors.request.use(request => {
    if (token) {
        request.headers.Authorization = `Bearer ${token}`
        return request;
    } else {
        token = localStorage.getItem('bhyve-token');
        request.headers.Authorization = `Bearer ${token}`
        return request;
    }

})

export default cmsAxios;