import React,{useState, useEffect} from 'react';
import {Avatar, Button, Badge, Typography, Tooltip} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../../redux/user/user-selector';
import './chat-history-entry-style.scss';

 function ChatHistoryEntry({item,index, handleUserChange, otherId, userData, handleAcknowledgement, handleEndorsement, newUpdate}) {

    const [allSkills,setAllSkills] = useState([]);
    const [selectedSkill,setSelectedSkill] = useState(allSkills.length > 0 && allSkills[0].collaborationSkillPublicId);
    const [allDone,setAllDone] = useState(false);
    const [canAcknowledge,setCanAcknowledge] = useState(false);
    const [canEndorse,setCanEndorse] = useState(false);
    const [unReadQuantity, setUnReadQuantity] = useState(0);

    useEffect(() => {
        if(newUpdate.length > 0) {
            console.log(newUpdate);
            let exist = newUpdate.find(update => update.id === item.collabDetails.collaborationPublicId);
            if(exist) {
                console.log(exist);
                let quant = exist.quantity;
                setUnReadQuantity(quant);
            }else {
                setUnReadQuantity(0);
            }
        }else{
            setUnReadQuantity(0);
        }
    },[newUpdate, item])

    useEffect(() => {
        let allDoneSkills = [];
        let validSkills = [];
        if(item) {
            if(item.collabDetails) {
                item.collabDetails.collaborationSkill.forEach(skill => {
                    if(skill.menteeId === userData.userInformation.publicId) {
                        if(skill.status === 1 && Object.keys(skill.review).length !== 0) {
                            allDoneSkills.push(skill);
                        }else{
                            validSkills.push(skill);
                        }
                    }else{
                        if(skill.status === 1) {
                            allDoneSkills.push(skill);
                        }else{
                            validSkills.push(skill);
                        }
                    }
                });
                if(validSkills.length > 0) {
                    setAllSkills(validSkills);
                    setSelectedSkill(validSkills[0].collaborationSkillPublicId);
                }
                if(allDoneSkills.length === item.collabDetails.collaborationSkill.length) {
                    setAllDone(true);
                }
            }
        }
  
    },[item, userData]);
    
    useEffect(() => {
        if(selectedSkill) {
            let desiredSkill = item.collabDetails.collaborationSkill.find(skill => skill.collaborationSkillPublicId === selectedSkill);
            if(desiredSkill){
                if(desiredSkill.status !== 1) {
                    setCanAcknowledge(true);
                }else{
                    setCanAcknowledge(false);
                }
                if((desiredSkill.menteeId === userData.userInformation.publicId) && 
                (Object.keys(desiredSkill.review).length === 0)) {
                    setCanEndorse(true);
                }else{
                    setCanEndorse(false);
                }
            }
        }
    },[selectedSkill,item])


    const handleDivClick = e => {
        console.log(e.target.parentNode.id);
        if(e.target.id !== 'skillSelect' && e.target.parentNode.id !== 'action-btn-1' && e.target.parentNode.id !== 'action-btn-2' && e.target.id !== 'add-skill' && e.target.parentNode.id !== 'add-skill') {
            handleUserChange();
        }
    }

    const handleChange = e => {
        const {value} = e.target;
        setSelectedSkill(value);
    }

    const endorse = () => {
        let endorsedSkill = item.collabDetails.collaborationSkill.find(i => i.collaborationSkillPublicId === selectedSkill);
        if(endorsedSkill) {
            let obj = {
                collabId: item.collabDetails.collaborationPublicId,
                user: item.participants,
                skill: endorsedSkill
            }
            handleEndorsement(obj);
        }
    }

    return(
        <div className='history-entry-container' id={index === 0? 'first-element':'other-elements'} onClick={handleDivClick} style={{backgroundColor: item.participants.id === otherId && '#FFCC33'}}>
            <div className='history-entry'>
            {item.participants.profilePhotoUrl !== '' && <Avatar src={item.participants.profilePhotoUrl} style={{width:'30px', height:'30px',margin:'auto 0'}}/>}
                    {item.participants.profilePhotoUrl === '' && <Typography variant='subtitle2' className='initial-container' style={{cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'6%',color:'white',background:'#FFCC33',width:'30px',height:'30px',margin:'auto 0'}}>
                    {`${item.participants.name.split(' ')[0].slice(0,1)}${item.participants.name.split(' ')[1].slice(0,1)}`}
                    </Typography>}
                {/* {unReadQuantity > 0 && <Badge color="primary" badgeContent={unReadQuantity}>
                    {item.participants.profilePhotoUrl !== '' && <Avatar src={item.participants.profilePhotoUrl} style={{width:'30px', height:'30px',margin:'auto 0'}}/>}
                    {item.participants.profilePhotoUrl === '' && <Typography variant='subtitle2' className='initial-container' style={{cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'6%',color:'white',background:'#FFCC33',width:'30px',height:'30px',margin:'auto 0'}}>
                    {`${item.participants.name.split(' ')[0].slice(0,1)}${item.participants.name.split(' ')[1].slice(0,1)}`}
                    </Typography>}
                </Badge>} */}
              <div className='history-entry-participant-info'>
                <div className='history-entry-participant-info-name-date'>
                    <div className='history-entry-participant-info-text'>
                        <h4 className={(item.participants.id === otherId || unReadQuantity > 0) && 'make-bold'}>{item.participants.name}</h4>
                    </div> 
                    {unReadQuantity > 0 && <span>{unReadQuantity}</span>}
                </div>
              </div>
            </div>
          </div>
    );
}

const mapStateToProps = createStructuredSelector({
    userData:selectUserData
  });

export default connect(mapStateToProps)(ChatHistoryEntry);


{/* <div className='history-entry-container' id={index === 0? 'first-element':'other-elements'} onClick={handleDivClick} style={{backgroundColor: item.participants.id === otherId && 'white'}}>
            <div className='history-entry'>
              {item.participants.profilePhotoUrl !== '' && <Avatar src={item.participants.profilePhotoUrl} style={{width:'40px', height:'40px',marginTop:'5px'}}/>}
              {item.participants.profilePhotoUrl === '' && <Typography variant='subtitle1' className='initial-container' style={{cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'4%',color:'white',background:'#FFCC33',width:'40px',height:'40px'}}>
                   {`${item.participants.name.split(' ')[0].slice(0,1)}${item.participants.name.split(' ')[1].slice(0,1)}`}
                  </Typography>}
              <div className='history-entry-participant-info'>
                <div className='history-entry-participant-info-name-date'>
                    <div className='history-entry-participant-info-text'>
                        <h3>{item.participants.name}</h3>
                        <p>{item.lastMessage ? item.lastMessage.createdAt : item.createdAt}</p>
                    </div> 
                    <Tooltip title='Add Skill' onClick={() => handleAddSkill(item)}>
                            <AddIcon style={{opacity:'0.6'}} id='add-skill'/>
                    </Tooltip>
                </div>
                {!allDone && <p>Skill collaborating for <select id="skillSelect" style={{maxWidth:'100px'}} value={selectedSkill} onChange={handleChange}>
                        {allSkills && allSkills.map(skill => 
                            <option value={skill.collaborationSkillPublicId}>
                                {skill.skillDisplayName}
                            </option>
                        )}
                    </select>
                </p>}
                <div className='history-entry-action-btns'>
                  {canAcknowledge && 
                  <Button id='action-btn-1' variant='contained' className='action-btn' onClick={() => handleAcknowledgement(item.collabDetails.collaborationPublicId,selectedSkill)}>
                    Acknowledge
                  </Button>}
                  {canEndorse && 
                  <Button id='action-btn-2' variant='contained' className={`action-btn ${index === 0 && 'action-btn-tour'}`} onClick={endorse}>
                    Endorse
                  </Button>}
                </div>
              </div>
            </div>
            <br/>
            <Divider/>
          </div> */}