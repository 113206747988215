/*jshint esversion:9*/

import React,{useState} from 'react';
import {IconButton,Button,Typography,TextField} from '@material-ui/core';
import Input from '../SubComponents/Input/Input';
import PublishIcon from '@material-ui/icons/Publish';
import AddCircleIcon from '@material-ui/icons/AddCircle';

function ManuallyAddCategory({handleAdd}){

  const [data,setData] = useState({
    skillCategoryName:null,
    image:null
  });

  const handleChange = e => {
    const {name,value} = e.target;
    setData(prevValue=>{
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  const handleUpload = e => {
    setData(prevValue=>{
      return{
        ...prevValue,
        image:e.target.files[0]
      };
    });
  };


  return(
    <div style={{width:'100%',textAlign:'center'}}>
    <Typography variant='h5' style={{fontWeight:'bold'}}>Add manually</Typography>
    <br/>
      <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
        <div style={{width:'20%'}}>
          <Input type='text' helperText='*required' label='Category Name' value={data.name&&data.name} onChange={handleChange} name='skillCategoryName'/>
          </div>
        </div>
        <br/>
        <div style={{display:'flex',width:'100%',justifyContent:'center',paddingLeft:'45px'}}>
          <div style={{width:'20%'}}>
            <TextField
            className='input'
            id="input-with-icon-textfield"
            label='Category Image'
            type='text'
            value={data.image&&data.image.name}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}

            />
              <input onChange={handleUpload} name='image' type='file' id='image-upload' style={{display:'none'}}/>
            </div>
            <div >
              <label htmlFor='image-upload'>
                <IconButton color="primary" aria-label="upload picture" component="span" >
                  <PublishIcon/>
                </IconButton>
              </label>
            </div>
      </div>
      <br/>
      <Button variant='outlined'  startIcon={<AddCircleIcon/>} color='primary' onClick={()=>handleAdd(data)}>Add</Button>
    </div>
  );
}

export default ManuallyAddCategory;
