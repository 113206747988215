/*jshint esversion:9*/
import {badgesActionTypes} from './badges-types';
import {updateBadge} from './badges-utils';

const INITIAL_STATE = {
  badges:null
};


const badgesReducer = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case badgesActionTypes.SET_BADGES:
      return{
        ...state,
        badges:action.payload
      };

    case badgesActionTypes.ADD_BADGE:
    return{
      ...state,
      badges:[...state.badges,action.payload]
    };

    case badgesActionTypes.UPDATE_BADGE:
    return{
      ...state,
      badges:updateBadge(action.payload,state.badges)
    };

    case badgesActionTypes.DELETE_BADGE:
    return{
      ...state,
      badges:state.badges.filter(item => item.publicId !== action.payload)
    };

    default:
      return state;
  }
};

export default badgesReducer;
