import getHTMLTextContent from "./getHTMLTextContent";
export default (str, noOfChar) => {
    if (!noOfChar || (typeof noOfChar !== 'numbers')) noOfChar = 100
    const parser = new DOMParser()
    let test = parser.parseFromString(str, "text/html");
    let paragraphs = test.getElementsByTagName('p');
    test = "";
    for (var i = 0; i < paragraphs.length; i++) {
        paragraphs[i].childNodes.forEach(node => {
            if(node.nodeName === "#text") test = test + node.textContent.replace(/(<([^>]+)>)/ig, '')
        })
        // test = test + paragraphs[i].textContent
    }
    return test.substring(0, noOfChar);
    // if ((str === null) || (str === '') || (str === undefined)) return false;
    // return getHTMLTextContent(str.toString()).substring(0, noOfChar);
    // return str.replace(/(<([^>]+)>)/ig, '');
}