import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import style from './articleShareDialog.module.scss';
import PrimaryButton from '../primaryButton';
import AutocompleteUI from '../autocompleteUI/autocompleteUI';
import cmsAxios from '../../axios/authAxios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline:'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4, 3),
    minWidth: 450,
    borderRadius: '5px'
  },
}));

export default function ArticleShareDialog({open,handleClose,shareArticle}) {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [selectedUsers,setSelectedUsers] = React.useState([]);

  const userTimer = React.useRef();

  const getUserName = (users) => users.map(user => user['firstName'] ? `${user['firstName']} ${user['lastName']}` : user)

  const onUserInputValueChange = (e) => {
    let value = e.currentTarget.value;
    clearTimeout(userTimer.current);
    setUsers(['loading'])
    userTimer.current = setTimeout(() => {
        cmsAxios.get('/user/username/autocomplete', {
            params: { name: value, limit: 5 }
        }).then(response => {
            setUsers(response.data)
        });
    }, 2000)
}

const handleUserTagChange = (e, value) => {
    setSelectedUsers(value);
}

const handleUserDelete = (value) => {
    let currentUsers = selectedUsers.filter(user => `${user['firstName']} ${user['lastName']}` != value);
    setSelectedUsers([...currentUsers]);
}

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} onClick={e => e.stopPropagation()}>
            <div className={`mb-sm`}>
                <h4 className={`my-0 w-600`}>Share Article</h4>
                <small>Type a name to get the user</small>
            </div>
            <AutocompleteUI
                options={getUserName(users)}
                onChange={onUserInputValueChange}
                onSelectionChange={handleUserTagChange}
                handleDelete={handleUserDelete}
            />
            <div className={style.shareButtonGroup}>
                <PrimaryButton className={style.cancelButton} onClick={handleClose}>Cancel</PrimaryButton>
                <PrimaryButton variant='contained' className={style.shareButton} onClick={handleClose}>Share Article</PrimaryButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
