/*jshint esversion:9*/
/*jshint -W087*/

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import LeaderboardTopRankers from "../SubComponents/leaderboard-top-rankers/leaderboard-top-rankers";
import LeaderboardRankers from "../SubComponents/leaderboard-rankers/leaderboard-rankers";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./leaderboard-weekly-report-style.scss";
import Logout from "../../logout";

function LeaderboardWeeklyReport({ history, currentWeek, handleClose }) {
  const [topRankers, setTopRankers] = useState(null);
  const [rankers, setRankers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const token = localStorage.getItem("bhyve-token");
      const getLeaderboard = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL_POINTS}${
            currentWeek ? "leaderboard" : "leaderboard-weekly-snap"
          }`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };

      async function gettingLeaderboard() {
        try {
          var leaderBoard;
          let getLeaderboardResult = await getLeaderboard();
          if (getLeaderboardResult) {
            console.log(getLeaderboardResult);
            leaderBoard = getLeaderboardResult.data;
            let users = [];
            leaderBoard.forEach((item) => {
              let id = Object.keys(item);
              let info = item[id[0]];
              let user = {
                publicId: id[0],
                ...info,
              };
              users.push(user);
            });
            if (users.length < 3) {
              setTopRankers(users.slice(0, users.length));
            } else {
              setTopRankers(users.slice(0, 3));
              if (users.length > 3) {
                setRankers(users.slice(3, users.length));
              }
            }
            setLoading(false);
            console.log(users);
          }
        } catch (err) {
          console.log(err.message);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }

      if (!topRankers && !rankers) {
        setLoading(true);
        gettingLeaderboard();
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [topRankers, rankers, currentWeek]);

  const navigateToUser = (id) => {
    handleClose();
    history.push(`/User/Profile/${id}`);
    window.scroll(0, 0);
  };

  return (
    <div className="weekly-report-container">
      {loading && (
        <div className="loading-container">
          <CircularProgress disableShrink />
        </div>
      )}
      <div className="top-rankers-container">
        {topRankers &&
          topRankers.map((item, index) => (
            <LeaderboardTopRankers
              firstName={item.firstName}
              lastName={item.lastName}
              points={item.points}
              navigateToUser={() => navigateToUser(item.publicId)}
              serial={index}
              img={item.profilePhotoUrl}
              key={index}
            />
          ))}
      </div>
      {rankers &&
        rankers.map((item, index) => (
          <LeaderboardRankers
            key={index}
            serial={index}
            firstName={item.firstName}
            lastName={item.lastName}
            navigateToUser={() => navigateToUser(item.publicId)}
            img={item.profilePhotoUrl}
            points={item.points}
          />
        ))}
    </div>
  );
}

export default withRouter(LeaderboardWeeklyReport);
