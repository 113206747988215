/*jshint esversion:9*/

import React from 'react';
import {Avatar,Typography,Tooltip} from '@material-ui/core';
import './recognition-feed-text-style.scss';

  function RecognitionFeedText(
    { type, 
      skillName, 
      senderFirstName,
      senderLastName,
      senderImg,
      recieverImg,
      recepientFirstName,
      recepientLastName,
      item,
      time,
      handleClick,
      handleDonorLink,
      handleRecieverLink,
      handleSkillSearch
    }
      ){
       
        
  function capitalize(input) {  
    try {
      let word = input;
      if(word) {
        var words = word.split(' ');  
        var CapitalizedWords = [];  
        let wordsTobeIgnored = ['and','the','of','on','are', 'a']; 
        words.forEach(element => {
            let currentElement = '';  
            if(element.split('').length > 1 && element.split('').includes('&')) {
              let temp = element.split[0];
              currentElement = element.split('')[0].toUpperCase() + '&' + element.split('')[2].toUpperCase();
            }else{
              currentElement = element;
            }
            if(currentElement === 'cpr') {
              CapitalizedWords.push('CPR');  
            }else if(currentElement === 'chp') {
              CapitalizedWords.push('CHP');  
            }else if(wordsTobeIgnored.includes(currentElement)) {
              CapitalizedWords.push(currentElement); 
            }else {
              CapitalizedWords.push(currentElement[0].toUpperCase() + currentElement.slice(1, currentElement.length));  
            }         
        });  
        return CapitalizedWords.join(' ');  
      }
    } catch (error) {
      console.log(error);
      return input;
    }
}  


  return(
    <div className='feed-text-container'>
      <div className='feed-sender-reciever-info'>
        { senderImg !== '' &&
          <Avatar src={senderImg} style={{width:'30px',height:'30px',cursor:'pointer'}} onClick={()=>handleDonorLink()}/>
        }
        { senderImg === '' && 
          <Typography variant='body1' className='initial-container' onClick={()=>handleDonorLink()} style={{width:'30px',height:'30px',cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'5px',color:'white',background:'#FFCC33'}}>
              {`${senderFirstName.slice(0,1)}${senderLastName.slice(0,1)}`}
          </Typography>
        }
        {type === 'BADGE' ? 
          <Typography variant='body1' className='recognition-feed-main-text' style={{paddingTop:'5px',marginLeft:'10px',marginRight:'10px'}}>
            <span style={{cursor:'pointer'}} onClick={()=>handleDonorLink()}>{senderFirstName}</span> 
            {` gifted a `} 
            <span className='badge-link' onClick={()=>handleClick()}>{`${capitalize(item)} badge`}</span> 
            {` to `} 
            <span style={{cursor:'pointer'}} onClick={()=>handleRecieverLink()}>{recepientFirstName}</span> 
          </Typography>
        : type === "RECOMMEND" ? 
          <Typography variant='body1' className='recognition-feed-main-text' style={{paddingTop:'5px',marginLeft:'10px',marginRight:'10px'}}>
            <span style={{cursor:'pointer'}} onClick={()=>handleDonorLink()}>{senderFirstName}</span> 
            {` recommended `} 
            <span style={{cursor:'pointer'}} onClick={()=>handleRecieverLink()}>{recepientFirstName}</span> 
            {` for `}
            <Tooltip title={capitalize(skillName)}>
              <span className='badge-link' onClick={()=>handleSkillSearch()}>#{capitalize(skillName)}</span> 
            </Tooltip>
          </Typography>
        :
          <Typography variant='body1' className='recognition-feed-main-text' style={{paddingTop:'5px',marginLeft:'10px',marginRight:'10px'}}>
            <span style={{cursor:'pointer'}} onClick={()=>handleDonorLink()}>{senderFirstName}</span> 
            {` endorsed `} 
            <span style={{cursor:'pointer'}} onClick={()=>handleRecieverLink()}>{recepientFirstName}</span> 
            {` for `}
            <Tooltip title={capitalize(skillName)}>
              <span className='badge-link' onClick={()=>handleSkillSearch()}>#{capitalize(skillName)}</span> 
            </Tooltip>
          </Typography>
        }
        { recieverImg !== '' &&
          <Avatar src={recieverImg} style={{width:'30px',height:'30px',cursor:'pointer'}} onClick={()=>handleRecieverLink()}/>
        }
        { recieverImg === '' && 
          <Typography variant='body1' className='initial-container' onClick={()=>handleRecieverLink()} style={{width:'30px',height:'30px',cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop:'5px',color:'white',background:'#FFCC33'}}>
              {`${recepientFirstName.slice(0,1)}${recepientLastName.slice(0,1)}`}
          </Typography>
        }
      </div>
      <div className='recognition-feed-post-time-container'> 
        <Typography variant='caption' className='recognition-feed-post-time' style={{paddingTop:'5px',fontWeight:'lighter',fontSize:'13px'}}>{time}</Typography>
      </div>
    </div>
  )
}

export default RecognitionFeedText;
