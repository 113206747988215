import React,{useEffect} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import LoginForm from '../../../Components/login/login-from';
import {Redirect} from 'react-router-dom';

const Login = ({ issuer }) => {
  const { authState } = useOktaAuth();
  console.log('window location ', window.location.href);
  useEffect(()=>{
    console.log(authState);
    if(window.location.origin.includes('https://hindalco.bhyve.io')) {
      window.location = 'https://dev-953032.okta.com/sso/saml2/0oa43qgo6lIQcBoei4x7';
      return;
    }else if(window.location.origin.includes('https://rieco.bhyve.io')) {
      window.location = 'https://dev-953032.okta.com/oauth2/v1/authorize?idp=0oa48rnsyev9bwpJw4x7&client_id=0oa44uoegg9ORoRE54x7&response_type=id_token&response_mode=fragment&scope=openid profile email&redirect_uri=https://rieco.bhyve.io/implicit/callback&state=WM6D&nonce=YsG76jo';
      return;
    }else if(window.location.origin.includes('https://naikwealth.bhyve.io')) {
      window.location = 'https://dev-953032.okta.com/oauth2/v1/authorize?idp=0oa4gl60o0aqkLfEd4x7&client_id=0oa44uoegg9ORoRE54x7&response_type=id_token&response_mode=fragment&scope=openid profile email&redirect_uri=https://naikwealth.bhyve.io/implicit/callback&state=WM6D&nonce=YsG76jo';
      return;
    }
  },[]);

  if (authState.isPending) {
    return <div>Loading...</div>;
  }
  return authState.isAuthenticated ?
  <Redirect to={{ pathname: '/' }}/> :
    <LoginForm issuer={issuer} />;
};
export default Login;