import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  useTheme,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Divider,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Input,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import Logout from "../../../logout";
import SuccessLogo from "../../../Assets/success.png";
import { withRouter } from "react-router-dom";
import "./create-collab-modal-style.scss";
import getDepartmentName from "../../../utils/getDepartmentName";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "600px",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  formControl: {
    width: "100%",
    marginBottom: "20px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getMenuStyles(id, skillList, theme) {
  return {
    fontWeight: skillList.find((i) => i.skillPublicId === id)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

function CreateCollabModal({
  open,
  handleClose,
  collabUserId,
  userId,
  navigateToUser,
  history,
  companyDepartments,
}) {
  const [userToCollab, setUserToCollab] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Fetching user info...");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const token = localStorage.getItem("bhyve-token");

  const handleSkillSelect = (e) => {
    const { value } = e.target;
    setSelectedSkill(value);
  };

  useEffect(() => {
    async function gettingUserSkills() {
      try {
        const fetchUserInfoResult = await axios.get(
          `${process.env.REACT_APP_API_URL}user/view/${collabUserId}`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(fetchUserInfoResult.data);
        setUserToCollab(fetchUserInfoResult.data);
        setLoading(false);
        setLoadingText("Sending your request...");
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response.message);
          if (error.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    }
    if (open && collabUserId) {
      setLoading(true);
      console.log("hit");
      gettingUserSkills();
    }
  }, [open, collabUserId]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!selectedSkill) {
        setError(true);
        return;
      }
      setError(false);
      setSuccess(true);
      setLoading(true);
      let skill = {
        ...selectedSkill,
        score:
          selectedSkill.score === 0.333
            ? 1
            : selectedSkill.score === 0.667
            ? 2
            : 3,
        mentorId: collabUserId,
        menteeId: userId,
      };
      const req = {
        participants: [collabUserId, userId],
        description: details,
        skill: [skill],
      };
      console.log(req);
      let createCollabResult = await axios.post(
        `${process.env.REACT_APP_API_URL}collaboration`,
        req,
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (createCollabResult) {
        console.log(createCollabResult);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSelectedSkill(null);
          handleClose();
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.message);
        if (error.response.status === 401) {
          history.push("/logout");
        }
      }
    }
  };

  const handleDetails = (e) => {
    const { value } = e.target;
    let currentValue = value;
    if (currentValue.length === 201) {
      return;
    }
    if (currentValue.length > 200) {
      currentValue = currentValue.slice(0, 200);
    }
    setDetails(currentValue);
  };

  function capitalize(input) {
    try {
      let word = input;
      if (word) {
        var words = word.split(" ");
        var CapitalizedWords = [];
        let wordsTobeIgnored = ["and", "the", "of", "on", "are", "a"];
        words.forEach((element) => {
          let currentElement = "";
          if (element.split("").length > 1 && element.split("").includes("&")) {
            let temp = element.split[0];
            currentElement =
              element.split("")[0].toUpperCase() +
              "&" +
              element.split("")[2].toUpperCase();
          } else {
            currentElement = element;
          }
          if (currentElement === "cpr") {
            CapitalizedWords.push("CPR");
          } else if (currentElement === "chp") {
            CapitalizedWords.push("CHP");
          } else if (wordsTobeIgnored.includes(currentElement)) {
            CapitalizedWords.push(currentElement);
          } else {
            CapitalizedWords.push(
              currentElement[0].toUpperCase() +
                currentElement.slice(1, currentElement.length)
            );
          }
        });
        return CapitalizedWords.join(" ");
      } else {
        return input;
      }
    } catch (error) {
      console.log(error);
      return input;
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      onBackdropClick={() => {
        setSelectedSkill(null);
        handleClose();
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="create-collab-title">
            {!loading && !success && <h3>Send a peer learning request</h3>}
            {(loading || success) && <div></div>}
            <IconButton
              onClick={() => {
                setTimeout(() => {
                  setLoading(false);
                  setLoadingText("Fetching user info...");
                  setSuccess(false);
                }, 1000);
                setDetails("");
                setSelectedSkill(null);
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {loading && (
            <div className="create-collab-loading-container">
              <CircularProgress color="primary" />
              <p>{loadingText}</p>
            </div>
          )}
          {!loading && !success && <Divider />}
          {!loading && !success && userToCollab && (
            <div className="create-collab-user-info">
              {userToCollab.profilePhotoUrl !== "" && (
                <Avatar
                  className="create-collab-user-info-avatar"
                  src={userToCollab.profilePhotoUrl}
                  onClick={() => history.push(`Profile/${collabUserId}`)}
                />
              )}
              {userToCollab.profilePhotoUrl === "" && (
                <Typography
                  variant="h4"
                  className="create-collab-user-info-initial"
                  onClick={() => history.push(`Profile/${collabUserId}`)}
                  style={{
                    cursor: "pointer",
                    textAlign: "center!important",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    background: "#FFCC33",
                  }}
                >
                  {`${userToCollab.firstName.slice(
                    0,
                    1
                  )}${userToCollab.lastName.slice(0, 1)}`}
                </Typography>
              )}
              <div className="create-collab-user-info-details">
                <p
                  className="create-collab-user-info-details-name"
                  onClick={() => history.push(`Profile/${collabUserId}`)}
                >{`${userToCollab.firstName} ${userToCollab.lastName}`}</p>
                {!!userToCollab.department && (
                  <p className="create-collab-user-info-details-title">
                    {getDepartmentName(
                      userToCollab.department,
                      companyDepartments
                    )}
                  </p>
                )}
                <p className="create-collab-user-info-details-location">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                    >
                      <g id="outline-location_on-24px" opacity="0.5">
                        <path
                          id="Path_2071"
                          data-name="Path 2071"
                          d="M0,0H18V18H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_2072"
                          data-name="Path 2072"
                          d="M9.917,2A5.038,5.038,0,0,0,5,7.157c0,3.868,4.917,9.577,4.917,9.577s4.917-5.709,4.917-9.577A5.038,5.038,0,0,0,9.917,2ZM6.4,7.157A3.6,3.6,0,0,1,9.917,3.473,3.6,3.6,0,0,1,13.43,7.157c0,2.122-2.023,5.3-3.512,7.279C8.456,12.468,6.4,9.256,6.4,7.157Z"
                          transform="translate(-0.917 -0.367)"
                        />
                        <circle
                          id="Ellipse_11"
                          data-name="Ellipse 11"
                          cx="2.041"
                          cy="2.041"
                          r="2.041"
                          transform="translate(6.959 4.761)"
                        />
                      </g>
                    </svg>
                  </span>
                  {userToCollab.userLocation.city}
                </p>
              </div>
            </div>
          )}
          {!loading && !success && (
            <form className="create-collab-details" onSubmit={handleSubmit}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="demo-mutiple-chip-label">
                  Choose a skill for collaboration
                </InputLabel>
                <Select
                  error={error}
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  onChange={handleSkillSelect}
                  value={selectedSkill}
                  label="Choose a skill for collaboration"
                >
                  {userToCollab &&
                    userToCollab.userSkillsSet.userSkills.map((skill) => (
                      <MenuItem key={skill.skillPublicId} value={skill}>
                        {capitalize(skill.skillDisplayName)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                value={details}
                onChange={handleDetails}
                className="create-collab-details-decription"
                variant="outlined"
                multiline
                rows={5}
                label="Mention the outcomes you expect from this interaction (optional)"
                placeholder="(200 characters max.)"
              />
              <Button
                type="submit"
                variant="contained"
                className="create-collab-details-submit-btn"
              >
                Request
              </Button>
            </form>
          )}
          {!loading && success && (
            <div className="create-collab-success-container">
              <div className="create-collab-success-logo">
                <img src={SuccessLogo} width="80" height="80" />
              </div>
              <h2>Request sent!</h2>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

export default withRouter(CreateCollabModal);
