/*jshint esversion:9*/

import {categoriesActionTypes} from './categories-types';

export const setCategories = array => ({
  type:categoriesActionTypes.SET_CATEGORIES,
  payload:array
});

export const addSkill = skill => ({
  type:categoriesActionTypes.ADD_SKILL_TO_CATEGORY,
  payload:skill
});

export const deleteSkill = object => ({
  type:categoriesActionTypes.DELETE_SKILL,
  payload:object
});

export const updateSkill = object => ({
  type:categoriesActionTypes.UPDATE_SKILL,
  payload:object
});

export const addSingleCategory = object => ({
  type:categoriesActionTypes.ADD_SINGLE_CATEGORY,
  payload:object
});

export const deleteCategory = id => ({
  type:categoriesActionTypes.DELETE_CATEGORY,
  payload:id
});

export const updateCategoryName = object => ({
  type:categoriesActionTypes.UPDATE_CATEGORY_NAME,
  payload:object
});
