/*jshint esversion:9*/

import React,{useState} from 'react';
import { makeStyles,Typography,Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Input from '../Input/Input';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width:'40%',
    display:'flex',
    justifyContent:'center',
    flexWrap:'wrap'
  },
  form:{
    width:'60%',
    marginTop:'20px',
    marginBottom:'20px'
  },
  formInput:{
    width:'100%',
    marginTop:'20px'
  },
  actionBtns:{
    width:'100%',
    textAlign:'center'
  },
  btn:{
    marginRight:'10px'
  }
}));

export default function AddLocationModal({Open,handleClose,addLocation}) {
  const classes = useStyles();
  const [details,setDetails] = useState({
    streetAddress:'',
    extendedStreetAddress:'',
    city:'',
    state:'',
    country:'',
    zipCode:null
  });

  const handleChange= e => {
    const {value,name} = e.target;
    setDetails(prevValue => {
      return{
        ...prevValue,
        [name]:value
      };
    });
  };

  const extractText = (str) => (str.match(/^[a-zA-Z ]*/) || []).pop() || '';
  const handleChangeAlpha= e => {
    const {value,name} = e.target;
    setDetails(prevValue => {
      return{
        ...prevValue,
        [name]: extractText(value)
      };
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Open}
        onClose={()=>handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Open}>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold',width:'100%',textAlign:'center'}}>Add Location</Typography>
            <div className={classes.form}>
              <div className={classes.formInput}>
                <Input label='Address' type='text' value={details.streetAddress} name='streetAddress' onChange={handleChange}/>
              </div>
              <div className={classes.formInput}>
                <Input label='Street Address' type='text' value={details.extendedStreetAddress} name='extendedStreetAddress' onChange={handleChange}/>
              </div>
              <div className={classes.formInput}>
                <Input label='City' type='text' value={details.city} name='city' onChange={handleChangeAlpha}/>
              </div>
              <div className={classes.formInput}>
                <Input label='State' type='text' value={details.state} name='state' onChange={handleChangeAlpha}/>
              </div>
              <div className={classes.formInput}>
                <Input label='Country' type='text' value={details.country} name='country' onChange={handleChangeAlpha}/>
              </div>
              <div className={classes.formInput}>
                <Input label='ZipCode' type='number' value={details.zipCode} name='zipCode' onChange={handleChange}/>
              </div>
            </div>
            <div className={classes.actionBtns}>
            <Button variant='contained' style={{color:'white'}} className={classes.btn} color='primary' onClick={()=>{
              setDetails({
                streetAddress:'',
                extendedStreetAddress:'',
                city:'',
                state:'',
                country:'',
                zipCode:null
              });
              addLocation(details);
            }}>Add</Button>
            <Button variant='contained' style={{color:'white',backgroundColor:'red'}} onClick={()=>handleClose()}>Close</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
