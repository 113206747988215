/*jshint esversion:9*/

import React, {useEffect} from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "../../../Components/ownerPanel-navbar/ownerPanel-navbar-component";
import Client from "../client/client-page";
import Points from "../points/points-page";
import Categories from "../categories/categories-page";
import Badges from "../badges/badges-page";
import Welcome from "../welcome/welcome-page";
import Department from "../departments/index";
import { selectUserData } from "../../../redux/user/user-selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import axios from 'axios'

function OwnerPage({ userData }) {

  const addToken = async () => {
    const token = await localStorage.getItem("bhyve-token");
    const addedToken = await localStorage.getItem('tokenAdded')

    if(addedToken) return

    await axios.get(
      `${process.env.REACT_APP_OWNER_URL}ownerPanel/user/add/token`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
    );

    await localStorage.setItem('tokenAdded', 'true')
  }

  useEffect(() => {
    addToken()
  }, [])

  if(!userData) {
    return <div>loading...</div>
  }

  if (
    !!userData.userInformation &&
    userData.userInformation.userRole === "OWNER"
  ) {
    return (
      <div>
        <Navbar />
        <Switch>
          <Route path="/OwnerPanel/Welcome" component={Welcome} />
          <Route path="/OwnerPanel/Client" component={Client} />
          <Route path="/OwnerPanel/Points" exact component={Points} />
          <Route path="/OwnerPanel/Categories" exact component={Categories} />
          <Route path="/OwnerPanel/Badges" exact component={Badges} />
          <Route path="/OwnerPanel/Departments" exact component={Department} />
        </Switch>
      </div>
    );
  } else return <Redirect to={{ pathname: '/' }}/>
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerPage);
