import {walkthroughActionTypes} from './walkthrough-types';

export const setTourStart = bool => ({
  type:walkthroughActionTypes.SET_TOUR_START,
  payload:bool
});

export const setTriggerPoint = value => ({
  type:walkthroughActionTypes.SET_TRIGGER_POINT,
  payload:value
});
