/*jshint esversion:9*/

import Keen from 'keen-tracking';

// Record all actions to a single event stream
const EVENT_STREAM_NAME = 'app-actions';

// Omit noisy actions if necessary
const OMITTED_ACTIONS = [
  // '@@router/LOCATION_CHANGE'
];

// Define a client instance
const client = new Keen({
  projectId: '5fc726493da8e23d1d572bdc',
  writeKey: 'd6503d3d5b5bbcb02b11303de846f6316de5b7a3fc03cc1c44e08488e1003490fd952969d428c389a64d84d9193f9a75edc99e1f78773a008f4211f141f11c83845b1fad2233e73728e20052962d421dc76f2f7258bc0aeb87f20438ea1d2143'
});

if (process.env.NODE_ENV !== 'production') {
  // Optionally prevent recording in dev mode
  Keen.enabled = true;
  // Display events in the browser console
  client.on('recordEvent', Keen.log);
}

// Track a 'pageview' event and initialize auto-tracking data model
client.initAutoTracking({
  recordClicks: true,
  recordFormSubmits: false,
  recordPageViews: true
});

const reduxMiddleware = function({ getState }) {
  return (next) => (action) => {
    const returnValue = next(action);
    const eventBody = {
      'action': action,
      'state': getState()
      /*
          Include additional properties here, or
          refine the state data that is recorded
          by cherry-picking specific properties
      */
    };
    // Filter omitted actions by action.type
    // ...or whatever you name this property
    if (OMITTED_ACTIONS.indexOf(action.type) < 0) {
      client.recordEvent(EVENT_STREAM_NAME, eventBody);
    }
    return returnValue;
  };
};

export default reduxMiddleware;
