import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Tooltip from "@material-ui/core/Tooltip";

const useIconStyle = makeStyles({
  root: {
    opacity: 0.3,
  },
});
const BookmarkBtn = ({ children, active, ...props }) => {
  const iconClasses = useIconStyle();
  return (
    <Tooltip title="Bookmark">
      <Button
        size="small"
        startIcon={
          <BookmarkIcon
            color={active ? "primary" : ""}
            className={`${active ? "" : iconClasses.root}`}
          />
        }
        {...props}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
export default BookmarkBtn;
