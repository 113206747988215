/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import RecognitionFeed from "../../../Components/recognitionFeed/recognitionFeed-component";
import RelevantSkills from "../../../Components/SubComponents/relevant-skills-card/relevant-skills-card";
import Collaborators from "../../../Components/collaborators/collaborators-component";
import BadgesDetailsModal from "../../../Components/SubComponents/badgesDetails-modal/badgesDetails-modal";
import RecognitionFeedBadgeModal from "../../../Components/SubComponents/recognitionFeed-badge-modal/recognitionFeed-badge-modal";
import WalkthroughStartModal from "../../../Components/SubComponents/walkthrough-start-modal/index";
import BuzzFeed from "../../../Components/buzz-feed/buzz-feed-component";
import AddSkillModal from "../../../Components/SubComponents/edit-skillSet-modal/edit-skillSet-modal";
import ProfileBrief from "../../../Components/profile-brief/profile-brief-component";
import CollabRequestList from "../../../Components/collab-request-list/collab-request-list-component";
import DashboardCarousel from "../../../Components/dashboard-carousel/index";
import TrendingSkills from "../../../Components/trending-skills/index";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectUserPoints,
  selectLocationData,
  selectCompanyInformation,
  selectCompanyDepartments,
  selectSettings,
} from "../../../redux/user/user-selector";
import { selectBadges } from "../../../redux/badges/badges-selector";
import {
  setUserData,
  setUserPoints,
  setSettings,
  setCompanyDepartments,
} from "../../../redux/user/user-actions";
import {
  selectTourStart,
  selectTriggerPoint,
} from "../../../redux/walkthrough/walkthrough-selector";
import {
  setTriggerPoint,
  setTourStart,
} from "../../../redux/walkthrough/walkthrough-actions";
import { setBadges } from "../../../redux/badges/badges-actions";
import RecommendedArticlesWidget from "../cms/util/recommendedArticlesWidget";
import MediaQuery from "react-responsive";
import Logout from "../../../logout";
import Tour from "../../../tour";
// import {useRouter} from 'state';
import "./Dashboard-style.scss";

const CollaboratorsMemo = React.memo(Collaborators);

function Dashboard({
  setUserData,
  userData,
  setUserPoints,
  history,
  badges,
  setAllBadges,
  companyInfo,
  setStart,
  tourStartStatus,
  setTriggerPoint,
  trigger,
  settings,
  setSettings,
  setCompanyDepartments,
  companyDepartments,
}) {
  //const {pathname} = useRouter();

  const [badgesModal, setBadgesModal] = useState(false);
  const [recognitionFeedBadge, setRecognitionFeedBadge] = useState(false);
  const [badge, setBadge] = useState(null);
  const [collabUsers, setCollabUsers] = useState([]);
  const [popularUsers, setPopularUsers] = useState([]);
  const [closestUsers, setClosestUsers] = useState([]);
  const [recommendedSkills, setRecommendedSkills] = useState([]);
  const [addSkillModal, setAddSkillModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [discardedSkills, setDiscardedSkills] = useState([]);
  const [onload, setOnLoad] = useState(false);
  const [tourModal, setTourModal] = useState(false);
  const [companyPublicId, setCompanyPublicId] = useState(null);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    window.scroll(0, 0);
  }, [onload]);

  useEffect(() => {
    if (!!companyPublicId) {
      const getDepartments = async () => {
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_OWNER_URL}ownerPanel/company-departments/${companyPublicId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
          );
          return result.data.companyDepartments;
        } catch (err) {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      };
      async function gettingDepartments() {
        try {
          let departmentsArray = await getDepartments();
          if (departmentsArray.length > 0) {
            setCompanyDepartments(departmentsArray);
          }
        } catch (err) {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }
      gettingDepartments();
    }
  }, [companyPublicId]);

  useEffect(() => {
    if (settings) {
      if (settings.basicWalkthrough) {
        setTourModal(false);
        setStart(false);
      } else {
        setTourModal(true);
      }
    }
  }, [settings]);

  useEffect(() => {
    try {
      const getUserInformation = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}user/me`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };

      async function gettingUserInformation() {
        try {
          let result = await getUserInformation();
          console.log("huge result", result);
          if (result.data.userInformation) {
            console.log(result.data.userInformation);
            setUserData({ userInformation: result.data.userInformation });
            setCompanyPublicId(result.data.userInformation.companyPublicId);
          }
        } catch (err) {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }

      gettingUserInformation();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        history.push("/logout");
      }
    }
  }, []);

  //this useEffect sets the recommended user, recommended skills, dismissed skills and userPoints
  useEffect(() => {
    try {
      const getUserPoints = async () => {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL_POINTS}points`,
          {
            headers: {
              "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return result;
      };

      async function getRecommendations(req) {
        try {
          let result = await axios.post(
            `${process.env.REACT_APP_API_URL_RECOMM}recommend-users`,
            req,
            {
              headers: {
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(result);
          if (result) {
            let recommendedSkills = result.data.recommendSimilarSkills.sort(
              (a, b) => (a.rank = b.rank)
            );
            if (result.data.userDismissedSkills.length > 0) {
              setDiscardedSkills((prevValue) => {
                return [...prevValue, ...result.data.userDismissedSkills];
              });
              result.data.userDismissedSkills.forEach((item, i) => {
                recommendedSkills = recommendedSkills.filter(
                  (skill) => skill.skill !== item
                );
              });
            }
            console.log(recommendedSkills);
            setRecommendedSkills(recommendedSkills);
            let Users = [];
            result.data.recommendUserMentors.forEach((user) => {
              let id = Object.keys(user)[0];
              let matchedSkill;
              if (user[id].userInterestMatched) {
                matchedSkill = user[id].interestSkillsMatched[0];
              } else {
                if (user[id].similarInterestMatch.length > 0) {
                  let key = Object.keys(user[id].similarInterestMatch[0])[0];
                  matchedSkill = user[id].similarInterestMatch[0][key];
                } else {
                  matchedSkill = user[id].mentorSkills[0];
                }
              }
              let locDetails = companyInfo.addresses.find(
                (item) => item.companyAddressPublicId === user[id].userLocation
              );
              Users.push({
                id: id,
                skillMatched: matchedSkill,
                locationDetails: locDetails,
                ...user[id],
              });
            });
            console.log(Users);
            setCollabUsers(Users);
          }
        } catch (e) {
          console.log(e);
          if (e.response && e.response.status === 401) {
            history.push("/logout");
          }
        }
      }

      if (userData && companyInfo) {
        let userInterests = [];
        let userSkills = [];
        userData.userInformation.userInterestSet.userInterests.map((item) =>
          userInterests.push(item.skillName)
        );
        userData.userInformation.userSkillsSet.userSkills.map((item) =>
          userSkills.push(item.skillName)
        );
        let req = {
          skills: userSkills,
          interests: userInterests,
        };
        console.log(req);
        getRecommendations(req);
        gettingUserPoints();
        async function gettingUserPoints() {
          try {
            let getUserPointsResult = await getUserPoints();
            if (getUserPointsResult) {
              setUserPoints(getUserPointsResult.data.points);
            }
          } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 401) {
              history.push("/logout");
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }

    // return ()=> {
    //   window.scrollTo(0,0);
    // }
  }, [userData, setUserPoints, companyInfo]);

  useEffect(() => {
    const getBadges = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API_URL}badges`, {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      });
      return result;
    };

    async function gettingBadges() {
      try {
        let getBadgesResult = await getBadges();
        if (getBadgesResult) {
          setAllBadges(getBadgesResult.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response.message);
          if (error.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    }

    if (!badges) {
      gettingBadges();
    }
  }, [badges]);

  const handleBadgeClick = (image, name) => {
    setRecognitionFeedBadge(true);
    setBadge({
      image,
      name,
    });
  };

  const handleFilterAddedSkill = (skill) => {
    let currentSkillArray = recommendedSkills.filter(
      (item) => item.skill !== skill
    );
    console.log(currentSkillArray);
    setRecommendedSkills(currentSkillArray);
  };

  const stopTour = async () => {
    try {
      let currentSettings = settings;
      currentSettings = {
        ...currentSettings,
        basicWalkthrough: true,
      };
      const result = await axios.patch(
        `${process.env.REACT_APP_API_URL}user/settings`,
        currentSettings,
        {
          headers: {
            "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSettings(result.data);
      setStart(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="dashboard-main-container">
      <Tour
        setTrigger={(value) => setTriggerPoint(value)}
        status={tourStartStatus}
        stopTour={stopTour}
        currentTrigger={trigger}
      />
      <div className="dashboard-greeting-container">
        <div style={{ lineHeight: "1.8ch", marginBottom: "30px" }}>
          <h1 style={{ fontWeight: "bold" }}>Buzz Buzz!</h1>
        </div>
        <p style={{ color: "gray" }}>
          Take a look at what we have curated for you!
        </p>
      </div>
      {companyInfo && companyInfo.carouselDetails.length > 0 && (
        <div className="carousel-main-container">
          <DashboardCarousel />
        </div>
      )}
      <div className="dashboard-container">
        <div className="recommendation-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CollabRequestList
              currentUserId={userData && userData.userInformation.publicId}
              companyDepartments={companyDepartments}
            />
          </div>
          {collabUsers.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <MediaQuery query="(min-width: 1025px)">
                <CollaboratorsMemo
                  title="Closest Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={3}
                  Collaborators={collabUsers}
                  type="closest"
                />
              </MediaQuery>
              <MediaQuery query="(min-width: 768px) and (max-width: 1024px)">
                <CollaboratorsMemo
                  title="Closest Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={2}
                  Collaborators={collabUsers}
                  type="closest"
                />
              </MediaQuery>
              <MediaQuery query="(max-width: 767px)">
                <CollaboratorsMemo
                  title="Closest Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={1}
                  Collaborators={collabUsers}
                  type="closest"
                />
              </MediaQuery>
            </div>
          )}
          <BuzzFeed userData={userData && userData} />
          <RecommendedArticlesWidget />
          {collabUsers.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}
            >
              <MediaQuery query="(min-width: 1025px)">
                <CollaboratorsMemo
                  title="Experts in demand"
                  subtitle="Connect with them to learn new skills"
                  rows={3}
                  Collaborators={collabUsers}
                  type="popular"
                />
              </MediaQuery>
              <MediaQuery query="(min-width: 768px) and (max-width: 1024px)">
                <CollaboratorsMemo
                  title="Experts in demand"
                  subtitle="Connect with them to learn new skills"
                  rows={2}
                  Collaborators={collabUsers}
                  type="popular"
                />
              </MediaQuery>
              <MediaQuery query="(max-width: 767px)">
                <CollaboratorsMemo
                  title="Experts in demand"
                  subtitle="Connect with them to learn new skills"
                  rows={1}
                  Collaborators={collabUsers}
                  type="popular"
                />
              </MediaQuery>
            </div>
          )}
          <RecognitionFeed
            handleClick={handleBadgeClick}
            handleUserLink={(id) => history.push(`/User/Profile/${id}`)}
          />
          {collabUsers.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}
            >
              <MediaQuery query="(min-width: 1025px)">
                <CollaboratorsMemo
                  title="Recommended Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={3}
                  Collaborators={collabUsers}
                  type="recommended"
                />
              </MediaQuery>
              <MediaQuery query="(min-width: 768px) and (max-width: 1024px)">
                <CollaboratorsMemo
                  title="Recommended Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={2}
                  Collaborators={collabUsers}
                  type="recommended"
                />
              </MediaQuery>
              <MediaQuery query="(max-width: 767px)">
                <CollaboratorsMemo
                  title="Recommended Collaborators"
                  subtitle="Connect with them to learn new skills"
                  rows={1}
                  Collaborators={collabUsers}
                  type="recommended"
                />
              </MediaQuery>
            </div>
          )}
        </div>
        <div className="brief-skill-map-container">
          <ProfileBrief
            allRecommendedSkills={
              recommendedSkills && recommendedSkills.map((item) => item.skill)
            }
            handleFilterAddedSkill={handleFilterAddedSkill}
          />
          <TrendingSkills />
          {/* <RelevantSkills userSkills={userData && userData.userInformation.userSkillsSet.userSkills} allRecommendedSkills={recommendedSkills&&recommendedSkills.map(item => item.skill)}  addSkill={()=>{setAddSkillModal(true);}} setDiscardedSkills={(list)=>setDiscardedSkills(list)} discardedSkills={discardedSkills} setRecommendedSkills={(list)=>setRecommendedSkills(list)} handleFilterAddedSkill={handleFilterAddedSkill}/> */}
        </div>
      </div>
      <BadgesDetailsModal
        open={badgesModal}
        badges={userData && userData.userInformation.badges}
        handleClose={() => setBadgesModal(false)}
      />
      <RecognitionFeedBadgeModal
        open={recognitionFeedBadge}
        handleClose={() => setRecognitionFeedBadge(false)}
        badge={badge && badge}
      />
      <AddSkillModal
        open={addSkillModal}
        handleClose={() => setAddSkillModal(false)}
        selectedSkill={selectedSkill}
        handleRemove={() => setAddSkillModal(false)}
      />
      <WalkthroughStartModal
        open={tourModal}
        startTour={() => {
          setStart(true);
          setTourModal(false);
        }}
        handleClose={stopTour}
        skipTour={stopTour}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  userPoints: selectUserPoints,
  locationData: selectLocationData,
  badges: selectBadges,
  companyInfo: selectCompanyInformation,
  tourStartStatus: selectTourStart,
  trigger: selectTriggerPoint,
  settings: selectSettings,
  companyDepartments: selectCompanyDepartments,
});

const mapDispatchToProps = (dispatch) => ({
  setUserPoints: (object) => dispatch(setUserPoints(object)),
  setUserData: (object) => dispatch(setUserData(object)),
  setAllBadges: (array) => dispatch(setBadges(array)),
  setTriggerPoint: (value) => dispatch(setTriggerPoint(value)),
  setStart: (value) => dispatch(setTourStart(value)),
  setSettings: (object) => dispatch(setSettings(object)),
  setCompanyDepartments: (array) => dispatch(setCompanyDepartments(array)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
