import React, { useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import style from "./publishDialog.module.scss";
import { TextField, Select, MenuItem, Chip, Input } from "@material-ui/core";
import AutocompleteUI from "../autocompleteUI/autocompleteUI";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/Image";
import Cropper from "react-easy-crop";
import getCroppedImg from "../imageCrop/cropDialog/crop";
import cmsAxios from "../../axios/authAxios";
import { useEffect } from "react";
import Synopsis from "./Synopsis";
import getSkillsId from "../../pages/newArticle/util/getSkillsId";
import getAuthorId from "../../pages/newArticle/util/getAuthorId";
import getAuthorsName from "../../pages/newArticle/util/getAuthorsName";

const PublishDialog = ({
  open,
  handleClose,
  handleSave,
  searchUsers,
  synopsis,
  setSynopsis,
  synopsisError,
  // thumbnail
  thumbnail,
  thumbnailUrl,
  setThumbnailUrl,
  setThumbnail,
  handleThumbnail,
  thumbnailKey,
  setThumbnailKey,
  // skill
  selectedSkills,
  setSelectedSkills,
  selectedSkillsDetails,
  skillsError,

  //authors
  selectedAuthors,
  setSelectedAuthors,
  selectedAuthorsDetails,

  //util
  updateArticleNow,
  createArticle,
  coverImage,
  setIsUpdated,
  values,
  setValues,
  coAuthorValues,
  setCoAuthorValues,
  setSkillsError,
  user,
  ...props
}) => {
  // coAuthorValues={coAuthorValues}
  // setCoAuthorValues={setCoAuthorValues}
  // const authorTimer = React.useRef();
  // const skillsTimer = React.useRef();
  const [skills, setSkills] = React.useState([]);
  const [authors, setAuthors] = React.useState([]);
  const authorRepo = React.useRef([]);
  const skillsRepo = React.useRef([]);
  const [selectedSkillOptions, setSelectedSkillOptions] = useState([]);
  const [selectedCoAuthorOptions, setSelectedCoAuthorOptions] = useState([]);
  const scrollRef = useRef(null);

  // skills util function
  const getSkillName = (skills) => {
    // let data = options
    // const skills = data.map((skills) => skills.skillPublicId);
    const skillsNames = skills.map((skill) =>
      skill["skillDisplayName"] ? skill["skillDisplayName"] : skill
    );
    const skillsFilter = skillsNames.reduce((acc, item) => {
      if (!acc.includes(item)) {
        acc.push(item);
      }
      return acc;
    }, []);
    return skillsFilter;
  };
  const handleSkillTagChange = (e, values) => {
    setSelectedSkillOptions(values);
    let filteredSkillOptions = skills.filter(
      (item) => !values.includes(item.skillDisplayName)
    );
    setSkills(filteredSkillOptions);
    setValues([...new Set(values)]);
    let result = skillsRepo.current.filter((skill) => {
      return [...new Set(values)].find((value) => {
        if (skill.skillDisplayName === value) {
          return skill;
        }
      });
    });
    setSelectedSkills([...new Set(getSkillsId(result))]);
    setIsUpdated(true);
  };
  // const handleSkillDelete = (value) => {
  //     console.log(value)
  //     setValues([...new Set(values)])
  //     let currentSkills = selectedSkills.filter(skill => skill['skillDisplayName'] != value)
  //     console.log(currentSkills)
  //     setSelectedSkills([...currentSkills]);
  //     setIsUpdated(true)
  // }
  const onSkillInputValueChange = async (e) => {
    let value = e.currentTarget.value;
    let params = { skillName: value, limit: 5 };
    if (value === "") return;
    let response = await cmsAxios.get("user/autocomplete/skills", { params });
    skillsRepo.current = [...new Set([...response.data])];
    let filteredSkillOptions = skillsRepo.current.filter(
      (item) => !selectedSkillOptions.includes(item.skillDisplayName)
    );
    filteredSkillOptions = filteredSkillOptions.filter(
      (item) => !values.includes(item.skillDisplayName)
    );
    setSkills(filteredSkillOptions);
  };

  // author value change
  // const getAuthorName = (authors) => {
  //     const authorsResult = authors.map(author => author['firstName'] ? `${author['firstName']} ${author['lastName']}` : author)
  //     const authorsNames = authorsResult.reduce((acc, item) => {
  //         if (!acc.includes(item)) {
  //             acc.push(item);
  //         }
  //         return acc;
  //     }, []);
  //     return authorsNames;
  // }
  const onAuthorInputValueChange = async (e) => {
    let value = e.currentTarget.value;
    if (value === "") return;
    let params = { name: value, limit: 5 };
    let response = await cmsAxios.get("user/username/autocomplete", { params });
    authorRepo.current = [
      ...new Set([...authorRepo.current, ...response.data]),
    ];
    let filteredCoAuthorOptions = authorRepo.current.filter(
      (item) =>
        !selectedCoAuthorOptions.includes(
          `${item.firstName} ${item.lastName}`
        ) &&
        !coAuthorValues.includes(`${item.firstName} ${item.lastName}`) &&
        item.publicId !== user.UserData.userInformation.publicId
    );
    // filteredCoAuthorOptions = filteredCoAuthorOptions.filter(
    //   (item) =>
    // );
    // let filteredCoAuthorOptions = response.data.filter(
    //   (item) =>
    //     !selectedCoAuthorOptions.includes(`${item.firstName} ${item.lastName}`)
    // );
    // if (authorRepo.current.some((value) => (value.firstName === author.firstName) && (value.secondName === value.secondName))) {
    //     authorRepo.current.push(author)
    // }
    // authorRepo.current = [...new Set(...authorRepo.current,...response.data)]
    filteredCoAuthorOptions = Array.from(
      new Set(filteredCoAuthorOptions.map((coAuthor) => coAuthor.publicId))
    ).map((authorId) =>
      filteredCoAuthorOptions.find((author) => author.publicId === authorId)
    );
    setAuthors(filteredCoAuthorOptions);
  };

  const handleAuthorTagChange = (e, values) => {
    let filteredCoAuthorOptions = authors.filter(
      (item) => !values.includes(`${item.firstName} ${item.lastName}`)
    );
    setAuthors(filteredCoAuthorOptions);
    setSelectedCoAuthorOptions(values);
    setCoAuthorValues([...values]);
    let result = authorRepo.current.filter((author) => {
      let name = `${author.firstName} ${author.lastName}`;
      return values.find((value) => name === value);
    });
    setSelectedAuthors([...new Set(getAuthorId(result))]);
    setIsUpdated(true);
  };

  const removeThumbnail = () => {
    setThumbnail("");
    setThumbnailUrl("");
  };
  // const handleAuthorDelete = (value) => {
  //     let currentAuthors = selectedAuthors.filter(author => `${author['firstName']} ${author['lastName']}` != value)
  //     setSelectedAuthors([...currentAuthors]);
  //     setIsUpdated(true)
  // }
  // const [isThumbnailEdit, setIsThumbnailEdit] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const handleOnCropComplete = React.useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  // const handleThumbnailClick = () => { setIsThumbnailEdit(true) }
  // const Thumbnail = (props) => <img className={style.thumbnail} onClick={handleThumbnailClick} src={thumbnail} />
  const ThumbnailPlaceholder = () => (
    <label className={`${style.thumbnail}`}>
      <ImageIcon />
      <input type="file" onChange={handleThumbnail} />
    </label>
  );
  // const setCropImage = React.useCallback(async (image, pixel) => {
  //     try {
  //         const croppedImage = await getCroppedImg(image, pixel);
  //         setThumbnail(croppedImage);
  //         // setIsThumbnailEdit(false)
  //     } catch (e) {
  //         console.error(e);
  //     }
  // }, [croppedAreaPixels]);
  const handleSaveAndPublish = async () => {
    if (selectedSkills.length === 0) {
      setSkillsError(true);
      return scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (!thumbnail) {
      // console.log("no thumbnail")
      handleSave(true);
    } else if (thumbnailUrl === thumbnail) {
      handleSave(true);
    } else {
      // console.log('thumbnail is new')
      let imageBlob = await getCroppedImg(thumbnail, croppedAreaPixels);
      if (!imageBlob)
        return console.error("There was an error cropping thumbnail");
      let file = new File([imageBlob], "thumbnail", { type: imageBlob.type });
      const formData = new FormData();
      formData.append("file", file);
      let response = await cmsAxios.post("cms/upload", formData);
      if (!response.data)
        return console.error("No data from thumbnail upload response");
      let { url, storeKey } = response.data;
      if (!url)
        return console.log("There is no url in thumbnail upload response");
      setThumbnailUrl(url);
      setThumbnailKey(storeKey);
      updateArticleNow({
        thumbnailImageUrl: url,
        thumbnailImageKey: storeKey,
      }).then((response) => {
        if (response.status < 300) {
          // console.log(response.data)
          handleSave();
        }
      });
    }
    // return getCroppedImg(thumbnail, croppedAreaPixels)
    //     .then(imageBlob => {
    //         const result = new File([imageBlob], 'thumbnail', { type: imageBlob.type });
    //         const formData = new FormData();
    //         formData.append("file", result);
    //         cmsAxios.post('cms/upload', formData).then(response => {
    //             setThumbnail(response.data.url)
    //             setThumbnailKey(response.data.storeKey);
    //             setIsUpdated(true);
    //             setTimeout(() => handleSave(), 2000);
    //         });
    //     })
  };
  useEffect(() => {
    if (selectedSkills.length > 0) {
      setSkillsError(false);
    } else {
      setSkillsError(true);
    }
  }, [selectedSkills]);
  useEffect(() => {
    skillsRepo.current = selectedSkillsDetails;
    authorRepo.current = selectedAuthorsDetails;
  }, []);
  useEffect(() => {
    // console.log("authors",authors)
    // console.log("co-authors",coAuthorValues)
    // console.log("selectedCoAuthorOptions",selectedCoAuthorOptions)
  });
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div ref={scrollRef} className={`p-lg ${style.wrapper}`}>
          <div>
            <div className={`mb-sm`}>
              <h4 className={`my-0 w-600`}>
                Tag Skills<sup>*</sup>
              </h4>
              <small>
                Search below and select the skill you want to add with your
                article
              </small>
            </div>
            <AutocompleteUI
              value={values}
              options={getSkillName(skills)}
              onChange={onSkillInputValueChange}
              onSelectionChange={handleSkillTagChange}
              // handleDelete={handleSkillDelete}
              skillsError={skillsError}
            />
          </div>
          <div className={`mt-lg`}>
            <div className={`mb-sm`}>
              <h4 className={`mb-0 w-600`}>Tag Co-Author</h4>
              <small>
                Search below and select the author you would like to collaborate
                with
              </small>
            </div>
            <AutocompleteUI
              options={getAuthorsName(authors)}
              onChange={onAuthorInputValueChange}
              onSelectionChange={handleAuthorTagChange}
              // handleDelete={handleAuthorDelete}
              value={coAuthorValues}
            />
          </div>
          <Synopsis
            error={synopsisError}
            value={synopsis}
            setValue={setSynopsis}
          />
          <div className={`mt-lg`}>
            <div
              className={`mb-sm`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <h4 className={`my-0 w-600`}>Thumbnail</h4>
              <div style={{ display: "flex", alignItems: "center" }}>
                {thumbnail || thumbnailUrl ? (
                  <h6
                    className="my-0 mr-md"
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={removeThumbnail}
                  >
                    Remove{" "}
                    <label
                      style={{ left: 0, opacity: 0, position: "absolute" }}
                    ></label>{" "}
                  </h6>
                ) : null}
                <h6 className="my-0 mr-md" style={{ position: "relative" }}>
                  Change{" "}
                  <label style={{ left: 0, opacity: 0, position: "absolute" }}>
                    <input type="file" onChange={handleThumbnail} />
                  </label>{" "}
                </h6>
              </div>
            </div>
            <div className={style.cropper_wrapper}>
              {thumbnail ? (
                <Cropper
                  aspect={1 / 1}
                  image={thumbnail}
                  onCropComplete={handleOnCropComplete}
                  onCropChange={setCrop}
                  crop={crop}
                />
              ) : (
                <label className={`${style.thumbnail}`}>
                  <ImageIcon />
                  <input type="file" onChange={handleThumbnail} />
                </label>
              )}
            </div>
          </div>
          <div className={`${style.action_wrapper} mt-lg`}>
            <Button
              style={{ textTransform: "capitalize" }}
              className={`mr-md`}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={handleSaveAndPublish}
            >
              Save and Publish
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default PublishDialog;
