import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import { graphqlOperation, API } from '@aws-amplify/api';
import '@aws-amplify/pubsub';
import { messagesByRoomIds } from './graphql/subscriptions';
import {createStructuredSelector} from 'reselect';
import {setNewMessages, setSubscribedRooms} from './redux/notifications/notification-actions';
import {selectSubscribedRooms} from './redux/notifications/notification-selector';
// import * as AWS from 'aws-sdk';


function SubscribeToRooms({setNewMessages, setSubscribedRooms, subscribedRooms}){

    const [collabIds,setCollabIds] = useState([]);
    // const [unReads,setUnReads] = useState([]);

    useEffect(() => {
        window.addEventListener('online', () => {
          if(subscribedRooms) {
            subscribedRooms.forEach(item => {
              setSubscribedRooms({
                id: item.id,
                status: false
              });
            })
          }
        });
        window.addEventListener('offline', () => setCollabIds([]));
    
        return () => {
          window.removeEventListener('online', () => console.log('Became online'));
          window.removeEventListener('offline', () => console.log('Became offline'));
        }
      },[])

    useEffect(() => {
            if(subscribedRooms) {
                try {
                    subscribedRooms.forEach(async item => {
                                if(!item.status && !collabIds.includes(item.id)) {
                                    setCollabIds(prevValue => {return [...prevValue, item.id]});
                                    let subscription =  await API
                                    .graphql(graphqlOperation(messagesByRoomIds, {
                                        roomId: item.id
                                    }))
                                    .subscribe({                                  
                                        next: ((event) => {
                                            setNewMessages(event.value.data.messagesByRoomIds);   
                                        }),
                                        error: error => console.warn(error)
                                    });
                                    if(subscription._state === 'ready') {
                                        setSubscribedRooms({
                                            id: item.id,
                                            status: true,
                                            unread: 0
                                        });
                                    }
                                }
                    });            
                } catch (error) {
                    console.log('subscription end error', error);
                }
            }
    },[subscribedRooms]);

    return <React.Fragment></React.Fragment>;

}

const mapStateToProps = createStructuredSelector({
    subscribedRooms : selectSubscribedRooms
  });

const mapDispatchToProps = dispatch => ({
    setNewMessages: array => dispatch(setNewMessages(array)),
    setSubscribedRooms: room => dispatch(setSubscribedRooms(room))
  })

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeToRooms);