/*jshint esversion:9*/

import React, { useState, useEffect } from "react";
import { IconButton, Avatar, Popover } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SendIcon from "@material-ui/icons/Send";
import "./shoutout-activity-style.scss";
import ActivityPost from "../SubComponents/activity-post/activity-post";
import ShoutoutModal from "../SubComponents/shoutout-modal/shoutout-modal";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import Snackbar from "../SubComponents/snackbar/snackbar";
import axios from "axios";
import moment from "moment";
import Logout from "../../logout";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentBuzzes } from "../../redux/buzzes/buzzes-selector";
import { setCurrentBuzzes } from "../../redux/buzzes/buzzes-actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "../SubComponents/backdrop/backdrop-component";

const Item = ({ entity: { name } }) => {
  return (
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "96%",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          padding: "0 5px 0 5px",
        }}
      >{`${name}`}</span>
    </div>
  );
};
const User = ({ entity: { profilePhotoUrl, firstName, lastName } }) => (
  <div
    style={{
      display: "flex",
      padding: "0 5px 0 5px",
    }}
  >
    <Avatar src={profilePhotoUrl} style={{ width: "30px", height: "30px" }} />
    <span
      style={{
        marginLeft: "10px",
      }}
    >{`${firstName} ${lastName}`}</span>
  </div>
);

function ShoutoutActivity({
  data,
  loading,
  lastBuzzElement,
  sendBuzz,
  currentUserId,
  history,
  currentBuzzes,
  setCurrentBuzzes,
  handleDrawerClose,
  feed,
  handleEditOnFeed,
  search,
  displayLoader,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [newBuzz, setNewBuzz] = useState({
    id: null,
    text: "",
  });
  const [selectedPost, setSelectedPost] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);
  const [specialWords, setSpecialWords] = useState(null);

  const token = localStorage.getItem("bhyve-token");

  useEffect(() => {
    try {
      function getAllBuzzes(Data) {
        try {
          setCurrentBuzzes(Data);
        } catch (e) {
          console.log(e);
          if (e.response && e.response.status === 401) {
            history.push("/logout");
          }
        }
      }
      getAllBuzzes(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [data, currentUserId]);

  const getAutoCompleteSuggestions = async (key) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/autocomplete/skills?skillName=${key}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const getAutoCompleteUserSuggestions = async (name) => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}user/username/autocomplete?name=${name}&limit=3`,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleClick = (postData) => {
    setSelectedPost(postData);
    setModalOpen(true);
  };

  const handleClose = () => {
    setSelectedPost(null);
    setModalOpen(false);
  };

  const handleOpenEmojiMenu = (e) => {
    if (!emojiAnchorEl) {
      setEmojiAnchorEl(e.currentTarget);
    } else {
      setEmojiAnchorEl(null);
    }
  };

  const handleEmojiMenuClose = () => {
    setEmojiAnchorEl(null);
  };
  const [maxCountReachedMessage, setMaxCountReachedMessage] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    // console.log(value);
    let currentValue = value;
    // if (currentValue.length === 251) {
    //   return;
    // }

    if (currentValue.length >= 250) {
      console.log("Max count reached", currentValue.length);
      setMaxCountReachedMessage(true);
      currentValue = currentValue.slice(0, 250);
    } else {
      setMaxCountReachedMessage(false);
    }

    setNewBuzz((prevValue) => {
      return { ...prevValue, text: currentValue };
    });
  };

  const handleKeyPress = (e) => {
    if (
      e.keyCode === 13 &&
      !e.shiftKey &&
      newBuzz.text.split(" ").join("").length > 0
    ) {
      e.preventDefault();
      setNewBuzz({
        id: null,
        text: "",
      });
      sendBuzz(newBuzz, taggedUsers);
    }
  };

  const addEmoji = (e) => {
    if (newBuzz.text.length === 200) {
      return;
    }
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setNewBuzz((prevValue) => {
      return { ...prevValue, text: prevValue.text + emoji };
    });
    setEmojiAnchorEl(null);
  };

  const handleComment = async (comment, id, taggedUsers) => {
    try {
      setLoadingComments(true);
      console.log(taggedUsers);
      let current = currentBuzzes;
      let currentComment = comment.text.split(" ");
      let skillTags = [];
      currentComment.map((item) =>
        item.split("").find((letter) => letter === "#")
          ? skillTags.push(item.slice(1).split("_").join(" "))
          : item
      );
      let userTags = [];
      currentComment.map((item) =>
        item.split("").find((letter) => letter === "@")
          ? userTags.push(
              taggedUsers.find(
                (user) =>
                  item.slice(1).split("_")[0] === user.firstName &&
                  item.slice(1).split("_")[1] === user.lastName
              )["publicId"]
            )
          : item
      );
      console.log(userTags);
      // let comments =
      let req = {
        text: comment.text,
        skillsTagged: skillTags,
        taggedUsers: userTags,
      };
      if (comment.id) {
        try {
          let editCommentResult = await editComment(req, comment.id, id);
          if (editCommentResult) {
            console.log("currentPost: ", editCommentResult);
            current = current.map((item) =>
              item.shoutoutPublicId === id ? editCommentResult.data : item
            );
            setCurrentBuzzes(current);
            setSelectedPost(editCommentResult.data);
            setLoadingComments(false);
          }
        } catch (err) {
          console.log(err);
          setLoadingComments(false);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      } else {
        try {
          let addCommentResult = await addComment(req, id);
          if (addCommentResult) {
            console.log("currentPost: ", addCommentResult);
            current = current.map((item) =>
              item.shoutoutPublicId === id ? addCommentResult.data : item
            );
            setCurrentBuzzes(current);
            setSelectedPost(addCommentResult.data);
            setLoadingComments(false);
          }
        } catch (err) {
          console.log(err);
          setLoadingComments(false);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addComment = async (req, id) => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${id}/comment`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const editComment = async (req, commentId, shoutoutId) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${shoutoutId}/comment/${commentId}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
        },
      }
    );
    return result;
  };

  const handleLike = async (likeType, target, likes, shoutout) => {
    try {
      const liked = likes.find((like) => like.author === currentUserId);
      if (liked) {
        if (likeType === "COMMENTS") {
          setLoadingComments(true);
        } else {
          setMessage("unLiking...");
          setSeverity("info");
          setAlertOpen(true);
        }
        if (likeType === "COMMENTS") {
          try {
            let deleteCommentLikeResult = await deleteCommentLike(target);
            if (deleteCommentLikeResult) {
              console.log(deleteCommentLikeResult);
              let currentPost = selectedPost;
              let currentComments = currentPost.comments.map((item) => {
                return {
                  ...item,
                  likes: item.likes.filter(
                    (like) => like.likePublicId !== liked.likePublicId
                  ),
                };
              });
              currentPost = {
                ...currentPost,
                comments: currentComments,
              };
              setSelectedPost(currentPost);
              setLoadingComments(false);
              let current = currentBuzzes;
              current = current.map((item) =>
                item.shoutoutPublicId === selectedPost.shoutoutPublicId
                  ? currentPost
                  : item
              );
              setCurrentBuzzes(current);
            }
          } catch (err) {
            console.log(err);
            setLoadingComments(false);
            if (err.response && err.response.status === 401) {
              history.push("/logout");
            }
          }
        } else {
          try {
            let deleteLikeResult = await deleteLike(target);
            if (deleteLikeResult) {
              if (selectedPost) {
                let currentPost = selectedPost;
                currentPost = {
                  ...currentPost,
                  likes: currentPost.likes.filter(
                    (like) => like.likePublicId !== liked.likePublicId
                  ),
                };
                setSelectedPost(currentPost);
              }
              let current = currentBuzzes;
              current = current.map((buzz) =>
                buzz.shoutoutPublicId === target
                  ? {
                      ...buzz,
                      likes: buzz.likes.filter(
                        (like) => like.likePublicId !== liked.likePublicId
                      ),
                    }
                  : buzz
              );
              setCurrentBuzzes(current);
              setAlertOpen(false);
              setMessage("unLiked the shoutout !");
              setSeverity("success");
              setAlertOpen(true);
            }
          } catch (err) {
            console.log(err);
            setAlertOpen(false);
            setMessage("Could not unLike the shoutout !");
            setSeverity("error");
            setAlertOpen(true);
            if (err.response && err.response.status === 401) {
              history.push("/logout");
            }
          }
        }
      } else {
        try {
          if (likeType === "COMMENTS") {
            setLoadingComments(true);
          } else {
            setMessage("Liking...");
            setSeverity("info");
            setAlertOpen(true);
          }

          const req = {
            likeType,
            target,
            shoutoutPublicId: shoutout,
          };
          console.log(req);

          let addLikeResult = await addLike(req);
          if (addLikeResult) {
            console.log(addLikeResult);
            if (selectedPost) {
              if (likeType === "SHOUTOUTS") {
                setSelectedPost({
                  ...selectedPost,
                  likes: addLikeResult.data.likes,
                });
              } else {
                let currentPost = selectedPost;
                let currentPostComments = currentPost.comments.map((item) =>
                  item.commentPublicId === addLikeResult.data.commentPublicId
                    ? addLikeResult.data
                    : item
                );
                currentPost = {
                  ...currentPost,
                  comments: currentPostComments,
                };
                setSelectedPost(currentPost);
              }
            }
            let current = currentBuzzes;
            if (likeType === "SHOUTOUTS") {
              current = current.map((item) =>
                item.shoutoutPublicId === target
                  ? { ...item, likes: addLikeResult.data.likes }
                  : item
              );
            } else {
              let currentPost = current.find(
                (item) =>
                  item.shoutoutPublicId === selectedPost.shoutoutPublicId
              );
              let currentComments = currentPost.comments.map((item) =>
                item.commentPublicId === target ? addLikeResult.data : item
              );
              currentPost = {
                ...currentPost,
                comments: currentComments,
              };
              current = current.map((item) =>
                item.shoutoutPublicId === currentPost.shoutoutPublicId
                  ? currentPost
                  : item
              );
            }
            setCurrentBuzzes(current);
            if (likeType === "SHOUTOUTS") {
              setAlertOpen(false);
              setMessage("Liked the shoutout !");
              setSeverity("success");
              setAlertOpen(true);
            } else {
              setLoadingComments(false);
            }
          }
        } catch (err) {
          console.log(err);
          setAlertOpen(false);
          setMessage("Could not like the shoutout !");
          setSeverity("error");
          setAlertOpen(true);
          setLoadingComments(false);
          if (err.response && err.response.status === 401) {
            history.push("/logout");
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addLike = async (req) => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/like`,
      req,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const deleteLike = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${id}/like`,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const deleteCommentLike = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/comment/${id}/like`,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleDeleteShoutout = async (id) => {
    try {
      setMessage("Deleting...");
      setSeverity("info");
      setAlertOpen(true);
      let deleteShoutoutResult = await deleteShoutout(id);
      if (deleteShoutoutResult) {
        let current = currentBuzzes;
        current = current.filter((buzz) => buzz.shoutoutPublicId !== id);
        console.log(current);
        setCurrentBuzzes(current);
        setAlertOpen(false);
        setMessage("Buzz deleted successfully!");
        setSeverity("success");
        setAlertOpen(true);
      }
    } catch (err) {
      console.log(err);
      setAlertOpen(false);
      setMessage("Buzz could not be deleted!");
      setSeverity("error");
      setAlertOpen(true);
      if (err.response && err.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const deleteShoutout = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${id}`,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleDeleteComment = async (id) => {
    try {
      setMessage("deleting comment ....");
      setSeverity("info");
      setAlertOpen(true);
      let deleteCommentResult = await deleteComment(id);
      if (deleteCommentResult) {
        let currentPost = selectedPost;
        currentPost = {
          ...currentPost,
          comments: currentPost.comments.filter(
            (comment) => comment.commentPublicId !== id
          ),
        };
        let currentList = currentBuzzes;
        currentList = currentList.map((item) =>
          item.shoutoutPublicId === currentPost.shoutoutPublicId
            ? currentPost
            : item
        );
        setSelectedPost(currentPost);
        setCurrentBuzzes(currentList);
        setAlertOpen(false);
        setMessage("Comment deleted successfully!");
        setSeverity("success");
        setAlertOpen(true);
      }
    } catch (err) {
      setAlertOpen(false);
      setMessage("Comment could not be deleted!");
      setSeverity("error");
      setAlertOpen(true);
      if (err.response && err.response.status === 401) {
        history.push("/logout");
      }
    }
  };

  const deleteComment = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/comment/${id}`,
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleVerification = async (commentId, shoutOutId, verified) => {
    try {
      let verifyCommentResult = await verifyComment(
        commentId,
        shoutOutId,
        verified
      );
      if (verifyCommentResult) {
        console.log(verifyCommentResult);
        let current = currentBuzzes;
        let currentPost = selectedPost;
        current = current.map((item) =>
          item.shoutoutPublicId === shoutOutId
            ? { ...item, comments: item.comments }
            : item
        );
        currentPost = {
          ...currentPost,
          comments: currentPost.comments.map((item) =>
            item.commentPublicId === commentId
              ? { ...item, verified: !item.verified }
              : item
          ),
        };
        setSelectedPost(currentPost);
        setCurrentBuzzes(current);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.message === 401) {
        history.push("/logout");
      }
    }
  };

  const verifyComment = async (commentId, shoutOutId, verified) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${shoutOutId}/comment/${commentId}/verify`,
      { verified: !verified },
      {
        headers: {
          "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  };

  const handleEditShoutout = async (id) => {
    try {
      let current = currentBuzzes.find((item) => item.shoutoutPublicId === id);
      console.log(current);
      if (current) {
        if (feed) {
          handleEditOnFeed(current);
        } else {
          let userInfos = await axios.post(
            `${process.env.REACT_APP_API_URL}user/profiles/info`,
            { userPublicIds: current.taggedUsersPublicId },
            {
              headers: {
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let tempTaggedUsers = [];
          userInfos.data.forEach((user, i) => {
            let id = Object.keys(user);
            let tagged = {
              publicId: id[0],
              ...user[id],
            };
            tempTaggedUsers.push(tagged);
          });
          setTaggedUsers(tempTaggedUsers);
          setNewBuzz({
            id: id,
            text: current.text,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.message === 401) {
        history.push("/logout");
      }
    }
  };

  const handleSelected = (e) => {
    let current = taggedUsers;
    console.log(e.item);
    current.push(e.item);
    console.log(current);
    setTaggedUsers(current);
  };

  const navigateToUser = (user, taggedUsers) => {
    try {
      let taggedUser = taggedUsers.find(
        (item) =>
          item.firstName === user.split("_")[0] &&
          item.lastName === user.split("_")[1]
      );
      if (taggedUser) {
        history.push(`/User/Profile/${taggedUser.publicId}`);
        if (handleDrawerClose) {
          handleDrawerClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToAuthor = (author) => {
    history.push(`/User/Profile/${author}`);
    if (handleDrawerClose) {
      handleDrawerClose();
    }
  };

  const handleSend = () => {
    if (newBuzz.text.split(" ").join("").length > 0) {
      setNewBuzz({
        id: null,
        text: "",
      });
      sendBuzz(newBuzz, taggedUsers);
    }
  };

  return (
    <div className="activity-container">
      <div
        className="posts-main-container"
        style={{ maxHeight: feed ? `calc(100% - 10px)` : `calc(100% - 180px)` }}
      >
        <div className="posts-container">
          {currentBuzzes && currentBuzzes.length !== 0 && currentBuzzes.map((item, index) => {
            if (currentBuzzes.length === index + 1)
              return (
                <div className="posts-container-content" ref={lastBuzzElement}>
                  <ActivityPost
                    firstName={item.firstName}
                    lastName={item.lastName}
                    navigateToAuthor={() => navigateToAuthor(item.author)}
                    img={item.profilePhotoUrl}
                    liked={
                      currentUserId &&
                      item.likes.find((like) => like.author === currentUserId)
                    }
                    user={currentUserId && currentUserId}
                    commentList={item.comments && item.comments}
                    handleClick={() => handleClick(item)}
                    handleLike={() =>
                      handleLike(
                        "SHOUTOUTS",
                        item.shoutoutPublicId,
                        item.likes,
                        ""
                      )
                    }
                    text={item.text}
                    time={moment(item.createdAt).fromNow()}
                    comments={item.comments && item.comments.length}
                    likes={item.likes && item.likes.length}
                    tags={item.skillsTagged}
                    users={item.taggedUsersPublicId}
                    owned={item.author === currentUserId}
                    deleteShoutOut={() =>
                      handleDeleteShoutout(item.shoutoutPublicId)
                    }
                    editShoutout={() =>
                      handleEditShoutout(item.shoutoutPublicId)
                    }
                    navigateToUser={navigateToUser}
                    handleDrawerClose={() =>
                      handleDrawerClose && handleDrawerClose()
                    }
                    feed={feed}
                    search={search}
                  />
                </div>
              );
            else
              return (
                <div className="posts-container-content">
                  <ActivityPost
                    firstName={item.firstName}
                    lastName={item.lastName}
                    navigateToAuthor={() => navigateToAuthor(item.author)}
                    img={item.profilePhotoUrl}
                    liked={
                      currentUserId &&
                      item.likes.find((like) => like.author === currentUserId)
                    }
                    commented={
                      currentUserId &&
                      item.comments.find(
                        (comment) => comment.author === currentUserId
                      )
                    }
                    user={currentUserId && currentUserId}
                    commentList={item.comments && item.comments}
                    handleClick={() => handleClick(item)}
                    handleLike={() =>
                      handleLike(
                        "SHOUTOUTS",
                        item.shoutoutPublicId,
                        item.likes,
                        ""
                      )
                    }
                    text={item.text}
                    time={moment(item.createdAt).fromNow()}
                    comments={item.comments && item.comments.length}
                    likes={item.likes && item.likes.length}
                    tags={item.skillsTagged}
                    users={item.taggedUsersPublicId}
                    owned={item.author === currentUserId}
                    deleteShoutOut={() =>
                      handleDeleteShoutout(item.shoutoutPublicId)
                    }
                    editShoutout={() =>
                      handleEditShoutout(item.shoutoutPublicId)
                    }
                    navigateToUser={navigateToUser}
                    handleDrawerClose={() =>
                      handleDrawerClose && handleDrawerClose()
                    }
                    feed={feed}
                    search={search}
                  />
                </div>
              );
          })}
          {loading && <div>Loading...</div>}
          {!loading && (!currentBuzzes || currentBuzzes.length === 0) && (
            <div>No Buzz in your BHyve :(</div>
          )}
        </div>
      </div>

      {sendBuzz && !feed && (
        <div className="shoutout-text-editor">
          <ReactTextareaAutocomplete
            loaderStyle={{
              position: "relative",
              top: "20px",
            }}
            containerStyle={{
              width: "100%",
              position: "relative",
              zIndex: "1",
              borderColor: "yellow",
              borderRadius: "10px",
            }}
            listStyle={{
              width: "220%",
              marginTop: "10px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              zIndex: "10",
              borderRadius: "2px",
              padding: "5px 0 5px 0",
              backgroundColor: "white",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dropdownClassName="dropdown"
            itemClassName="list-item"
            ref={(rta) => {
              ShoutoutActivity.rta = rta;
            }}
            innerRef={(textarea) => {
              ShoutoutActivity.textarea = textarea;
            }}
            value={newBuzz.text}
            className="my-textarea"
            placeholder="Need help with a skill? Ask a question using #SkillName (250 characters max.)"
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            onItemSelected={handleSelected}
            loadingComponent={() => <span></span>}
            trigger={{
              "#": {
                dataProvider: async (token) => {
                  let skillNames = [];
                  if (token.length >= 3) {
                    if (token.includes("_")) {
                      token = token.split("_").join(" ");
                    }
                    console.log(token);
                    skillNames = await getAutoCompleteSuggestions(token).then(
                      (res) => {
                        return res.data;
                      }
                    );
                    skillNames = skillNames.map((item) => {
                      return { name: item.skillName };
                    });
                    skillNames = skillNames.map((item) =>
                      item.name.split("").includes(" ")
                        ? { name: item.name.replace(/ /g, "_") }
                        : item
                    );
                    console.log(skillNames);
                    console.log(skillNames);
                    return skillNames;
                  }
                },
                component: Item,
                output: (item, trigger) => `#${item.name}`,
              },
              "@": {
                dataProvider: async (token) => {
                  let userNames = [];
                  if (token.length >= 3) {
                    if (token.includes("_")) {
                      token = token.split("_").join(" ");
                    }
                    console.log(token);
                    userNames = await getAutoCompleteUserSuggestions(token)
                      .then((res) => {
                        console.log(res.data);
                        return res.data;
                      })
                      .catch((err) => {
                        console.log(err);
                        if (err.response && err.response.status === 401) {
                          window.location.href =
                            "https://fe.bhyve-app.com:5000/login";
                        }
                      });
                    userNames = userNames.map((item) => {
                      return { ...item };
                    });
                    console.log(userNames);
                    return userNames;
                  }
                },
                component: User,
                output: (item, trigger) =>
                  `@${item.firstName}_${item.lastName}`,
              },
            }}
          />
          <span className="shoutout-send-options">
            {maxCountReachedMessage && (
              <span
                style={{
                  color: "red",
                  float: "left",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                You have reached 250 character limit!
              </span>
            )}
            <IconButton
              aria-describedby="emoji-popover"
              onClick={handleOpenEmojiMenu}
            >
              <InsertEmoticonIcon />
            </IconButton>
            {displayLoader ? (
              <div>
                <Backdrop open={displayLoader} />
                <CircularProgress color="primary" />
              </div>
            ) : (
              <IconButton button onClick={handleSend}>
                <SendIcon />
              </IconButton>
            )}
          </span>
        </div>
      )}
      <Popover
        id={Boolean(emojiAnchorEl) ? "emoji-popover" : undefined}
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={handleEmojiMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Picker onSelect={addEmoji} />
      </Popover>
      <ShoutoutModal
        Open={modalOpen}
        data={selectedPost}
        navigateToAuthor={() =>
          history.push(`/User/Profile/${selectedPost.author}`)
        }
        handleLike={handleLike}
        handleClose={handleClose}
        sendComment={handleComment}
        loadingComments={loadingComments}
        handleDeleteComment={handleDeleteComment}
        handleVerification={handleVerification}
        navigateToUser={navigateToUser}
      />
      <Snackbar
        Open={alertOpen}
        severity={severity}
        alert={message}
        handleClose={() => setAlertOpen(false)}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  currentBuzzes: selectCurrentBuzzes,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentBuzzes: (array) => dispatch(setCurrentBuzzes(array)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShoutoutActivity)
);
