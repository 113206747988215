import cmsAxios from '../../../axios/authAxios';
import { SET_CURRENT_ARTICLE } from './set_current_article';
export const FETCH_SINGLE_ARTICLE = "FETCH SINGLE ARTICLE";
const fetchSingleArticle = id => dispatch => {
    dispatch({ type: FETCH_SINGLE_ARTICLE });

    cmsAxios.get(`cms/article/${id}`)
        .then((response) => {
            dispatch({ type: SET_CURRENT_ARTICLE, payload: response.data })
        })
    // let currentArticle = articles.find((article) => {
    //     if (article.id == id) {
    //         console.log(article)
    //         return article
    //     }
    // })
}

export default fetchSingleArticle;