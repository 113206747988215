/*jshint esversion:9*/
/*jshint -W087*/

import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Logout from "../../../logout";

//function
export default function BuzzBoxInfiniteScroll(skip, skills, tab) {
  const [updating, setUpdating] = useState(false);
  const [err, setErr] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [more, setMore] = useState(false);

  useEffect(() => {
    if (tab === 1) {
      if (skip === 0) {
        setUpdates([]);
      }
      setUpdating(true);
      setErr(false);
      console.log(skills);

      const getBuzzes = async () => {
        const token = localStorage.getItem("bhyve-token");
        const getBuzz = async () => {
          let req = { userSkills: skills };
          console.log(req);
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/skills?skip=${skip}&take=5`,
            req,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-api-key": "1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e",
              },
            }
          );
          return result;
        };
        try {
          let res = await getBuzz();
          if (res) {
            console.log(res);
            let currentArray = [];
            let Keys = Object.keys(res.data);
            Keys.map((item) =>
              res.data[item].map((buzz) => currentArray.push(buzz))
            );
            console.log(currentArray);
            setMore(currentArray.length > 0);
            if (currentArray.length > 0) {
              console.log(currentArray);
              setUpdates((prevBuzzes) => {
                return [...new Set([...prevBuzzes, ...currentArray])];
              });
              setUpdating(false);
            } else {
              setUpdating(false);
            }
          }
        } catch (e) {
          console.log(e);
          setErr(true);
          setUpdating(false);
          // if(e.response && e.response.status === 401){
          //   history.push('/logout');
          // }
        }
      };

      if (skills.length > 0) {
        getBuzzes();
      } else {
        setUpdating(false);
      }
    }
  }, [skip, skills, tab]);

  return { updating, err, updates, more };
}
