/*jshint esversion:9*/

import {categoriesActionTypes} from './categories-types';
import {addSkill,deleteSkill,updateSkill,updateCategoryName} from './categories-utils';

const INITIAL_STATE={
  categories:null
};

const categoryReducer = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case categoriesActionTypes.SET_CATEGORIES:
      return{
        ...state,
        categories:action.payload
      };

    case categoriesActionTypes.ADD_SKILL_TO_CATEGORY:
    return{
      ...state,
      categories:{skillCategories:addSkill(state.categories.skillCategories,action.payload)}
    };

    case categoriesActionTypes.DELETE_SKILL:
    return{
      ...state,
      categories:{skillCategories:deleteSkill(state.categories.skillCategories,action.payload)}
    };

    case categoriesActionTypes.UPDATE_SKILL:
    return{
      ...state,
      categories:{skillCategories:updateSkill(state.categories.skillCategories,action.payload)}
    };

    case categoriesActionTypes.ADD_SINGLE_CATEGORY:
    return{
      ...state,
      categories:{skillCategories:[...state.categories.skillCategories,{...action.payload}]}
    };

    case categoriesActionTypes.DELETE_CATEGORY:
    return{
      ...state,
      categories:{skillCategories:state.categories.skillCategories.filter(item => item.skillCategory.publicId !== action.payload)}
    };

    case categoriesActionTypes.UPDATE_CATEGORY_NAME:
    return{
      ...state,
      categories:{skillCategories:updateCategoryName(state.categories.skillCategories,action.payload)}
    };

    default:
    return state;
  }
};

export default categoryReducer;
