import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const DeleteDialog = ({ open, deleteArticle, setDeleteModal }) => {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">Do you want to delete?</Typography>
          <div className="close_button">
            <IconButton
              onClick={() => setDeleteModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#FFFFFF",
              color: "green",
              fontSize: "18px",
            }}
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#FFFFFF",
              color: "red",
              fontSize: "18px",
            }}
            onClick={() => {
              deleteArticle();
              setDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
