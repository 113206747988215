/*jshint esversion:9*/
import React from 'react';
import { makeStyles,Typography,IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding:'50px',
    textAlign:'center',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RecognitionFeedBadgeModal({open,handleClose,badge}) {
  const classes = useStyles();


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open}>
          {badge &&
          <div className={classes.paper}>
            <div style={{width:'100%',textAlign:'right'}}>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon/>
              </IconButton>
            </div>
            <img src={badge.image} alt='comingSoon' width='300' height='300'/>
            <Typography variant='h5' style={{fontWeight:'bold'}}>{badge.name}</Typography>
          </div>}
        </Fade>
      </Modal>
    </div>
  );
}
