import React,{useState} from 'react';
import {Paper,Typography,Button,IconButton,Tooltip,Fade, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './style.scss';

function SkillTabSkill({skill, selected, level, handleSkillSelect, handleRemoveSkill, ownerPanel, handleEditSkill, handleDeleteSkill}) {

    const useStyles = makeStyles((theme) => ({
        'tooltip': {
            backgroundColor:'black',
            fontSize:'0.675rem'
        }
    }))

    const [hovered,setHovered] = useState(false);
    const [tempLevel,setTempLevel] = useState(0);

    const classes = useStyles();

    if (!hovered ) {
        return( <Typography
            variant='subtitle1'
            className={`skill-tab-skill-btn ${selected && 'selected'}`}
           >
            <div 
                className='span-container'
                onMouseEnter={() => setHovered(true)}
            >
                <span>
                    {skill.skillDisplayName}
                </span>
                {selected && 
                    <div className='selected-expertise-level-container'>
                        {selected.score >= 0.333 && <label className='selected-expertise-card-level'/>}
                        {selected.score >= 0.667 && <label className='selected-expertise-card-level'/>}
                        {selected.score >= 0.80 && <label className='selected-expertise-card-level'/>}
                        {selected.score === 1 && <label className='selected-expertise-card-level'/>}
                    </div>
                }
            </div>
            {selected && 
                <CloseIcon style={{cursor:'pointer',margin:'auto 0'}} onClick={() => {handleRemoveSkill();}}/>
            }
        </Typography>
        );
    }
    else if(hovered){
        return(
            <Fade in={hovered} timeout={1000} onMouseLeave={() => setHovered(false)}>
                <Paper elevation={2} className='expertise-card'>
                    {!ownerPanel && <p>Select level</p>}
                   {!ownerPanel &&  <div className='expertise-card-level-container' onMouseLeave={() => setTempLevel(0)}>
                        <Tooltip title='Beginner' classes={{tooltip: classes.tooltip}}>
                            <label onMouseEnter={() => setTempLevel(1)} onClick={()=>{setHovered(false); handleSkillSelect(0.333,skill);}} className={`expertise-card-level bar ${ ((selected && selected.score >= 0.333) || tempLevel >= 1)  && 'selected'}`} ></label>
                        </Tooltip>
                        <Tooltip title='Intermediate' classes={{tooltip: classes.tooltip}}>
                            <label onMouseEnter={() => setTempLevel(2)} onClick={()=>{setHovered(false); handleSkillSelect(0.667,skill)}} className={`expertise-card-level bar ${((selected && selected.score >= 0.667) || tempLevel >= 2)  && 'selected'}`}></label>
                        </Tooltip>
                        <Tooltip title='Advanced' classes={{tooltip: classes.tooltip}}>
                            <label onMouseEnter={() => setTempLevel(3)} onClick={()=>{setHovered(false); handleSkillSelect(0.80,skill)}} className={`expertise-card-level bar ${((selected && selected.score >= 0.80) || tempLevel >= 3)  && 'selected'}`}></label>
                        </Tooltip>
                        <Tooltip title='Expert' classes={{tooltip: classes.tooltip}}>
                            <label onMouseEnter={() => setTempLevel(4)} onClick={()=>{setHovered(false); handleSkillSelect(1,skill)}}  className={`expertise-card-level bar ${((selected && selected.score === 1) || tempLevel === 4) && 'selected'}`}></label>
                        </Tooltip>
                    </div>}
                    {ownerPanel && <div className='expertise-card-level-container'>
                        <IconButton className='ownerPanel-btn' onClick={() => handleEditSkill()}><EditIcon/></IconButton>
                        <IconButton className='ownerPanel-btn' onClick={() => handleDeleteSkill()}><DeleteIcon/></IconButton>
                    </div>}
                </Paper>
            </Fade>
        )
    }else{
        return(
            <div>

            </div>
        )
    }
    
}

export default SkillTabSkill;