/*jshint esversion:9*/
/*jshint -W087*/

import React,{useEffect,useState} from 'react';
import {withRouter} from 'react-router';
import NotificationPost from '../SubComponents/notification-post/notification-post';
import ShoutoutModal from '../SubComponents/shoutout-modal/shoutout-modal';
import MilestoneModal from '../SubComponents/milestone-modal/milestone-modal';
import Loader from '../SubComponents/loader/loader-component';
import Snackbar from '../SubComponents/snackbar/snackbar';
import RankOneModal from '../SubComponents/rankOne-modal';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData,selectUserPoints} from '../../redux/user/user-selector';
import {selectNewNotifications} from '../../redux/notifications/notification-selector';
import Logout from '../../logout';
import axios from 'axios';
import {selectCurrentBuzzes} from '../../redux/buzzes/buzzes-selector';
import {setCurrentBuzzes} from '../../redux/buzzes/buzzes-actions';
import {updateNotifications} from '../../redux/notifications/notification-actions';
import './notifications-style.scss';

function NotificationPanel({history,userData,userPoints,newNotifications,currentBuzzes,setCurrentBuzzes,updateNotificationList}){

  const [notifications,setNotifications] = useState(null);
  const [modalOpen,setModalOpen] = useState(false);
  const [selectedPost,setSelectedPost] = useState(null);
  const [milestone,setMilestone] = useState(false);
  const [loadingComments,setLoadingComments] = useState(false);
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);
  const [badge,setBadge] = useState(null);
  const [notificationType,setNotificationType] = useState(false);
  const [rankOneModal, setRankOneModal] = useState(false);

  const token = localStorage.getItem('bhyve-token');

  useEffect(()=>{
      try {
        const updateNotifications = async(ids) => {
          console.log(ids);
          const result = await axios.patch(`${process.env.REACT_APP_API_URL_NOTIFICATIONS}notify-user`,ids,{
            headers:{
              'Authorization': `Bearer ${token}`,
              'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
            }
          });
          return result;
        };

        async function updatingAndSettingNotifications(data) {
          try {
            let posts = [];
            console.log(data);
  
            data.forEach(notification => {
              let object = {
                id:notification.notificationPublicId,
                postType:notification.publishingEvent,
                skillName:notification.skillName&&notification.skillName,
                published:notification.published,
                time:notification.createdAt,
                shoutoutPublicId:notification.shoutoutPublicId&&notification.shoutoutPublicId,
                badgePublicId:notification.badgePublicId||'',
                articleId:notification.shoutoutPublicId,
                type: notification.skillType
              }
              if(notification.publisherUserPublicId) {
                  object = {
                    ...object,
                    publisherUserPublicId: notification.publisherUserPublicId.publicId,
                    publisherName: `${notification.publisherUserPublicId.firstName} ${notification.publisherUserPublicId.lastName}`,
                    publisherDp: notification.publisherUserPublicId.profilePhotoUrl
                  }
              }
              posts.push(object);
            })
            console.log(posts);
            setNotifications(posts);
            if(posts.length > 0) {
              let notificationIds = posts
                  .filter(item => item.published === false)
                  .map(item => item.id);
              if (notificationIds.length > 0){
                let updateNotificationsResult = await updateNotifications({notificationPublicId: notificationIds});
                console.log(updateNotificationsResult);
              }
            }
          } catch (error) {
            if(error.response) {
              console.log(error.response.message);
              if(error.response.status === 401) {
                history.push('/logout');
              }
            }
          }
        }
        
        if(newNotifications) {
          updatingAndSettingNotifications(newNotifications);
        }
     
      } catch (error) {
        console.log(error.message);
      }

   
  },[newNotifications]);

  useEffect(() => {
    return () => updateNotificationList();
  },[])
  
  const navigateToShoutout = async id => {
    try {
      setLoading(true);
      setSelectedPost(id);
      setLoading(false);
      setModalOpen(true);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      if(error.response&&error.response.status===401){
        history.push('/logout');
      }
    }
  };



  const handleLike = async (likeType,target,likes,shoutout) => {
    try {
      const liked = likes.find(like => like.author === userData.userInformation.publicId);
      if(liked){
        if(likeType==='COMMENTS'){
          setLoadingComments(true);
        }else{
          setMessage('unLiking...');
          setSeverity('info');
          setAlertOpen(true);
        }
        if(likeType==='COMMENTS'){
          try {
            let deleteCommentLikeResult = await deletCommentLike(target);
            if(deleteCommentLikeResult){
              console.log(deleteCommentLikeResult);
              let currentPost = selectedPost;
              let currentComments = currentPost.comments.map(item => {return {...item,likes:item.likes.filter(like => like.likePublicId !== liked.likePublicId)};});
              currentPost = {
                ...currentPost,
                comments:currentComments
              };
              setSelectedPost(currentPost);
              setLoadingComments(false);
              let current = currentBuzzes;
              current = current.map(item => item.shoutoutPublicId === currentPost.shoutoutPublicId? currentPost:item);
              setCurrentBuzzes(current);
            }
          } catch (err) {
            console.log(err);
            setLoadingComments(false);
            if(err.response && err.response.status===401){
              history.push('/logout');
            }
          }
        }else{
          try {
            let deleteLikeResult = await deleteLike(target);
            if(deleteLikeResult){
              if(selectedPost){
                let currentPost = selectedPost;
                currentPost = {
                  ...currentPost,
                  likes:currentPost.likes.filter(like => like.likePublicId !== liked.likePublicId)
                };
                setSelectedPost(currentPost);
              }
                     let current = currentBuzzes;
            current = current.map(buzz => buzz.shoutoutPublicId === target?
              {...buzz,likes:buzz.likes.filter(like => like.likePublicId !== liked.likePublicId)}
              :
              buzz
            )
            setCurrentBuzzes(current);
              setAlertOpen(false);
              setMessage('unLiked the shoutout !');
              setSeverity('success');
              setAlertOpen(true);
            }
          } catch (err) {
            console.log(err.message);
            setAlertOpen(false);
            setMessage('Could not unLike the shoutout !');
            setSeverity('error');
            setAlertOpen(true);
            if(err.response && err.response.status===401){
              history.push('/logout');
            }
          }
        }
  
      }else{
        try {
          if(likeType==='COMMENTS'){
            setLoadingComments(true);
          }else{
            setMessage('Liking...');
            setSeverity('info');
            setAlertOpen(true);
          }   
          const req = {
            likeType,
            target,
            shoutoutPublicId:shoutout
          };
          console.log(req);
          let addLikeResult = await addLike(req);
          if(addLikeResult){
            console.log(addLikeResult);
          if(selectedPost){
            if(likeType==='SHOUTOUTS'){
              setSelectedPost({
                ...selectedPost,
                likes:addLikeResult.data.likes
              });
            }else{
              let currentPost = selectedPost;
              let currentPostComments = currentPost.comments.map(item => item.commentPublicId === addLikeResult.data.commentPublicId? addLikeResult.data:item);
              currentPost = {
                ...currentPost,
                comments:currentPostComments
              };
              setSelectedPost(currentPost);
            }
          }
          let current = currentBuzzes;
          if(likeType==='SHOUTOUTS'){
          current = current.map(item => item.shoutoutPublicId === target? {...item,likes:addLikeResult.data.likes} : item );
        }else{
          let currentPost = current.find(item => item.shoutoutPublicId === selectedPost.shoutoutPublicId);
          let currentComments = currentPost.comments.map(item => item.commentPublicId === target? addLikeResult.data:item);
          currentPost = {
            ...currentPost,
            comments:currentComments
          }
          current = current.map(item => item.shoutoutPublicId === currentPost.shoutoutPublicId? currentPost:item);
        }
          setCurrentBuzzes(current);
            if(likeType==='SHOUTOUTS'){
              setAlertOpen(false);
              setMessage('Liked the shoutout !');
              setSeverity('success');
              setAlertOpen(true);
            }else{
                setLoadingComments(false);
            }
          }
        } catch (err) {
          console.log(err);
          setAlertOpen(false);
          setMessage('Could not like the shoutout !');
          setSeverity('error');
          setAlertOpen(true);
          setLoadingComments(false);
          if(err.response && err.response.status===401){
            history.push('/logout');
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addLike = async req => {
      const token = localStorage.getItem('bhyve-token');
  const result = await axios.post(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/like`,req,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
};

  const deleteLike = async id => {
      const token = localStorage.getItem('bhyve-token');
  const result = await axios.delete(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${id}/like`,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
};

  const deletCommentLike = async id => {
      const token = localStorage.getItem('bhyve-token');
  const result = await axios.delete(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/comment/${id}/like`,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
};

const handleClose= () => {
  setSelectedPost(null);
  setModalOpen(false);
};

  const handleComment = async (comment,id,taggedUsers) => {
    try {
      setLoadingComments(true);
      console.log(taggedUsers);
      let current = currentBuzzes;
      let currentComment = comment.text.split(' ');
        let skillTags =[];
     currentComment.map(item => item.split('').find(letter => letter === '#')?skillTags.push(item.slice(1).split('_').join(" ")):item);
         let userTags=[];
     currentComment.map(item => item.split('').find(letter => letter === '@')?userTags.push(taggedUsers.find(user => item.slice(1).split('_')[0]===user.firstName&&item.slice(1).split('_')[1]===user.lastName)['publicId']):item);
      console.log(userTags);
      // let comments =
      let req={
        text:comment.text,
      skillsTagged:skillTags,
      taggedUsers:userTags
      };
      if(comment.id){
        let editCommentResult = await editComment(req,comment.id,id);
        if(editCommentResult){
          console.log(editCommentResult);
          current = current.map(item => item.shoutoutPublicId === id ? editCommentResult.data : item);
          setCurrentBuzzes(current);
          setSelectedPost(editCommentResult.data);
          setLoadingComments(false);
        }
      }else{
        let addCommentResult = await addComment(req,id);
        if(addCommentResult){
          console.log(addCommentResult);
          current = current.map(item => item.shoutoutPublicId === id ? addCommentResult.data : item);
          setCurrentBuzzes(current);
          setSelectedPost(addCommentResult.data);
          setLoadingComments(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setLoadingComments(false);
      if(error.response && error.response.status===401){
        history.push('/logout');
      }
    }
  };

  const addComment = async (req,id) => {
      const token = localStorage.getItem('bhyve-token');
  const result = await axios.post(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${id}/comment`,req,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
};

  const editComment = async (req,commentId,shoutoutId) => {
      const token = localStorage.getItem('bhyve-token');
  const result = await axios.patch(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${shoutoutId}/comment/${commentId}`,req,{
    headers:{
      'Authorization': `Bearer ${token}`,
      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
    }
  });
  return result;
};

const deleteComment = async id => {
    const token = localStorage.getItem('bhyve-token');
const result = await axios.delete(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/comment/${id}`,{
  headers:{
    'Authorization': `Bearer ${token}`,
    'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
  }
});
return result;
};

  const handleDeleteComment = async id => {
    try {
      setMessage('deleting comment ....');
      setSeverity('info');
      setAlertOpen(true);
      let deleteCommentResult = await deleteComment(id);
      if(deleteCommentResult){
        let currentPost = selectedPost;
        currentPost = {
          ...currentPost,
          comments:currentPost.comments.filter(comment => comment.commentPublicId !== id)
        }
        let currentList = currentBuzzes;
        currentList = currentList.map(item => item.shoutoutPublicId === currentPost.shoutoutPublicId? currentPost : item);
        setSelectedPost(currentPost);
        setCurrentBuzzes(currentList);
        setAlertOpen(false);
        setMessage('Comment deleted successfully!');
        setSeverity('success');
        setAlertOpen(true);
      }
    } catch (err) {
      console.log(err.message);
      setAlertOpen(false);
      setMessage('Comment could not be deleted!');
      setSeverity('error');
      setAlertOpen(true);
      if(err.response && err.response.status===401){
        history.push('/logout');
      }
    }
  };

    const verifyComment = async (commentId,shoutOutId,verified) => {
        const token = localStorage.getItem('bhyve-token');
    const result = await axios.patch(`${process.env.REACT_APP_API_URL_BUZZBOX}shoutouts/${shoutOutId}/comment/${commentId}/verify`,{verified:!verified},{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  };


  const handleVerification = async (commentId,shoutOutId,verified) => {
    try {
      let verifyCommentResult = await verifyComment(commentId,shoutOutId,verified);
      if(verifyCommentResult){
        console.log(verifyCommentResult);
        let current = currentBuzzes;
        let currentPost = selectedPost;
        current = current.map(item => item.shoutoutPublicId === shoutOutId?{...item,comments:item.comments}:item);
        currentPost = {
          ...currentPost,
          comments:currentPost.comments.map(item => item.commentPublicId === commentId? {...item,verified:!item.verified}:item)
        };
        setSelectedPost(currentPost);
        setCurrentBuzzes(current);
      }
    } catch (error) {
      console.log(error.message);
      if(error.response&&error.response.status===401){
        history.push('/logout');
      }
    }
  };

  const getTaggedUsers = async id => {
       const token = localStorage.getItem('bhyve-token');
    const result = await axios.get(`${process.env.REACT_APP_API_URL}user/userProfile/${id}`,{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e',
      }
    });
    return result;
  };

  const navigateToUser = (user,taggedUsers) => {
    taggedUsers.map(item =>
      getTaggedUsers(item)
      .then(res => res.data.firstName===user.split('_')[0]&&res.data.lastName===user.split('_')[1]?history.push(`/User/Profile/${res.data.publicId}`):''
    )
    .catch(err => console.log(err))
  );
  };

  const handleModal = (type,Badge) => {
    setNotificationType(type);
    if(type==='BADGE_RECEIVED'){
      setBadge(Badge);
    }
    setMilestone(true);
  };

  const navigateToChat = id => {
    try {
      history.push(`/User/Chat/${id}`);
    } catch (error) {
      console.log(error)
    }
  }

  const navigateToSearch = name => {
    try {
      history.push(`/User/Search?q=${name}`);
    } catch (error) {
      console.log(error);
    }
  }

  const navigateToArticle = id => {
    try {
      history.push(`/User/cms/${id}/singleArticle`);
    } catch (error) {
      console.log(error);
    }
  }

  const navigateToExperience = () => {
    history.push(`/User/Profile/${userData.userInformation.publicId}?scrollToExperience=true`);
  }

  return(
    <div style={{width:'100%'}}>
    <Loader open={loading}/>
    <div className='notifications-container'>
      {notifications && notifications.length===0&&<div>No new notifications for you ! Check here later !</div>}
      {notifications && notifications.map(post => 
        <NotificationPost 
          post={post} 
          navigateToPublisher={()=>history.push(`/User/Profile/${post.publisherUserPublicId}`)} 
          navigateToShoutout={navigateToShoutout} 
          openMilestoneModal={handleModal} 
          navigateToChat={navigateToChat}
          navigateToSearch={navigateToSearch}
          navigateToArticle={navigateToArticle}
          openRankOneModal={() => setRankOneModal(true)}
          navigateToExperience={navigateToExperience}
          />
      )}
    </div>
    <ShoutoutModal Open={modalOpen} data={selectedPost} navigateToAuthor={()=>history.push(`/User/Profile/${selectedPost.author}`)} handleLike={handleLike}  handleClose={handleClose} sendComment={handleComment} loadingComments={loadingComments} handleDeleteComment={handleDeleteComment} handleVerification={handleVerification} navigateToUser={navigateToUser} />
    <MilestoneModal open={milestone} handleClose={()=>{setMilestone(false);setBadge(null);}} points={userPoints} badge={badge} type={notificationType}/>
    <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
    <RankOneModal open={rankOneModal} handleClose={() => setRankOneModal(false)}/>
    </div>
  )

}
const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  userPoints:selectUserPoints,
  newNotifications:selectNewNotifications,
  currentBuzzes: selectCurrentBuzzes
});

const mapDispatchToProps = dispatch => ({
  setCurrentBuzzes: array => dispatch(setCurrentBuzzes(array)),
  updateNotificationList: () => dispatch(updateNotifications())
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NotificationPanel));
