import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import style from './flagReasonModal.module.scss';
import PrimaryButton from '../primaryButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline:'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
    minWidth: 450,
    borderRadius: '5px'
  },
}));

export default function FlagReasonModal({open,handleClose,flagArticle}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p className={style.flagReasonTitle}>Select Reason</p>
            <div>
                <FormGroup className={style.flagCheckGroup}>
                    <FormControlLabel
                        className={style.flagCheckLabel}
                        control={<Checkbox name="gilad" className={style.flagCheckbox}/>}
                        label="Incorrect Facts"
                    />
                    <FormControlLabel
                        className={style.flagCheckLabel}
                        control={<Checkbox name="jason" className={style.flagCheckbox}/>}
                        label="Inappropriate"
                    />
                    <FormControlLabel
                        className={style.flagCheckLabel}
                        control={<Checkbox  name="antoine" className={style.flagCheckbox}/>}
                        label="Misleading"
                    />
                </FormGroup>
                <div className={style.flagButtonGroup}>
                    <PrimaryButton className={style.cancelButton} onClick={handleClose}>Cancel</PrimaryButton>
                    <PrimaryButton variant='contained' className={style.flagButton} onClick={flagArticle}>Flag Article</PrimaryButton>
                </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
