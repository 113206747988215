/*jshint esversion:9*/

export const updateBadge = (object,list) => {
  try {
    const exist = list.find(item => item.publicId === object.id);

    if(exist){
      var currentList;
      if(object.name){
        currentList = list.map(item => item.publicId === object.id?
        {...item,badgeName:object.name}:item);
      }
      if(object.image){
        currentList = list.map(item => item.publicId === object.id?
        {...item,badgeImageUrl:object.image}:item);
      }
      return currentList;
    }
  
    return list;
  } catch (error) {
    console.error(error.message);
  }
};
