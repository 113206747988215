export default [
    {
        title: 'Heading', items: [
            { title: 'Heading 1', format: 'h1' },
            { title: 'Heading 2', format: 'h2' },
            { title: 'Heading 3', format: 'h3' },
            { title: 'Heading 4', format: 'h4' },
            { title: 'Heading 5', format: 'h5' },
            { title: 'Heading 6', format: 'h6' }
        ]
    },
    {
        title: 'Inline', items: [
            { title: 'Bold', format: 'bold' },
            { title: 'Italics', format: 'italic' },
            { title: 'Underline', format: 'underline' },
            { title: 'Strikethrough', format: 'strikethrough' },
            { title: 'Subscript', format: 'subscript' },
            { title: 'Code', format: 'code' },
        ]
    },
    {
        title: 'blocks', items: [
            { title: 'Paragraph', format: 'p' },
            { title: 'Blockquote', format: 'blockquote' },
        ]
    },
]