/*jshint esversion:9*/

import React from 'react';
import { makeStyles,Typography,Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import congratsImg from '../../../Assets/woohoo.png';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY:'auto'
  },
  paper: {
    minWidth:300,
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap:'wrap',
    textAlign:'center',
    outline:'none',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 3, 5),
  },
  congoImg:{
    width:'178px',
    height: '178px',
    borderRadius: '50%',
    backgroundColor:'#66CCFF',
    position:'relative'
  }
}));

export default function SkillCardCompletedModal({open,handleClose,handleNext}) {
  const classes = useStyles();


  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction='up'>
        <div className={classes.paper}>
          <div className={classes.congoImg}>
            <CheckIcon style={{margin: 'auto 0', fontSize:'130px',position:'absolute', top:'15%',right:'12%',color:'white'}}/>
          </div>
          <br/>
          <Typography variant='h4' style={{fontWeight:'bold', width:'100%',margin:'30px 0 10px 0'}} >Great Job</Typography>
          <Typography variant='subtitle1' style={{fontWeight:'500', width:'100%',marginBottom:'10px'}} >Your profile is created!</Typography>
                    <br/>
                    <br/>
          <Button className='next-btn' onClick={()=>handleNext()}>OK, What's next?</Button>
        </div>
        </Slide>
      </Modal>
    </div>
  );
}
