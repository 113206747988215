import cmsAxios from '../../../axios/authAxios';

export const FETCH_RECOMMENDED_ARTICLES = "FETCH RECOMMENDED ARTICLES";
const fetchRecommendedArticle = () => dispatch => {
    cmsAxios.get("cms/article/recommended").then(response => {
        dispatch({ type: FETCH_RECOMMENDED_ARTICLES, payload:response.data });
    })
}

export default fetchRecommendedArticle
