/*jshint esversion:9*/

import React from "react";
import { Typography, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import "./info-edit-style.scss";

function InfoEdit({
  icon,
  label,
  placeholder,
  type,
  options,
  value,
  onChange,
  name,
  disabled,
  multiline,
  dateChange,
  views,
  helperText,
  error,
  forGenderSelection,
}) {
  return (
    <div className="edit-contact-content">
      <Typography variant="body1" className="label">
        {icon && (
          <span style={{ marginRight: "8px", fontWeight: "550!important" }}>
            {icon}
          </span>
        )}
        {label}:
      </Typography>
      {type !== "Date" && (
        <TextField
          error={error}
          multiline={multiline}
          rows={multiline ? "3" : ""}
          className="txt-field"
          disabled={disabled}
          style={{ width: "100%" }}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={(e) => onChange(e)}
          views={type === "date" && ["year", "month"]}
          select={type === "select" ? true : false}
          helperText={helperText}
          SelectProps={{
            native: true,
          }}
        >
          {type === "select" &&
            !forGenderSelection &&
            options.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          {type === "select" &&
            forGenderSelection &&
            options.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
        </TextField>
      )}
      {type === "Date" && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            placeholder={placeholder}
            error={error}
            className="txt-field"
            disabled={disabled}
            invalidDateMessage={null}
            style={{ width: "100%" }}
            format={!views && "dd/MM/yyyy"}
            disableFuture
            openTo="year"
            views={views ? views : ["date", "month", "year"]}
            name={name}
            value={value}
            onChange={(e) => dateChange(e, name)}
          />
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
}

export default InfoEdit;
