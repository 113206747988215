import React, { useReducer, useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import confetti from "./Assets/confetti.png";
import SampleVid from "./Assets/sample-vid.mov";
import buzzboxVid from "./Assets/buzzbox.MP4";
import step1Image from "./Assets/Walkthrough-Step1.png";
import step2Image from "./Assets/Walkthrough-Step2.png";
import step3Image from "./Assets/Walkthrough-Step3.png";
import step4Image from "./Assets/Walkthrough-Step4.png";
import step5Image from "./Assets/Walkthrough-ThankYou.png";
import endorseImage from "./Assets/Walkthrough-Endorse.png";
import {
  setTourStart,
  setTriggerPoint,
} from "./redux/walkthrough/walkthrough-actions";
import { connect } from "react-redux";
import endorseVid from "./Assets/endorse.MP4";
import "./tour.scss";

const image = () => {
  return <img src={confetti} style={{ width: "20%" }} />;
};

const Step = ({ step, image, para1, para2 }) => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [dontShow, setDontShow] = useState(false);

  const handleCheck = () => {
    if (!dontShow) {
      dispatch({ type: "DONT_SHOW_AGAIN" });
    }
    setDontShow(!dontShow);
  };

  return (
    <div className={`step-content ${!step && "chat-tour-step"}`}>
      {step && step < 5 && <h1>Step {step} of 4</h1>}
      {step === 5 && <h1 style={{ textAlign: "center" }}>Thank You!</h1>}
      <div className="step-image-container">
        <img src={image} width={step ? "150" : "200"} />
      </div>
      <p>{para1}</p>
      {!step && <p>or</p>}
      {para2 && <p>{para2}</p>}
      {/* {
        !step && 
          <FormControlLabel
          control={
            <Checkbox
              checked={dontShow}
              onChange={handleCheck}
              name="checkedB"
              color="primary"
            />
          }
          label="Don't show this again"
        />
      } */}
    </div>
  );
};

// Define the steps
const DASHBOARD_TOUR_STEPS = [
  {
    target: ".first-collaborator-container",
    content: (
      <Step
        step={1}
        image={step1Image}
        para1="Collaborate with experts for a skill you want to learn."
        para2="Click on “Collaborate” button to proceed further."
      />
    ),
    disableBeacon: true,
    placement: "right-start",
    hideCloseButton: true,
    // disableScrolling: true,
    // disableScrollParentFix: true
  },
  {
    target: ".sidebar-buzzBox",
    content: (
      <Step
        step={2}
        image={step2Image}
        para1="Buzzbox is here to help you."
        para2="Click here to find out."
      />
    ),
    placement: "left-start",
    hideCloseButton: true,
  },
  {
    target: ".buzz-box-transparent",
    content: (
      <Step
        step={3}
        image={step3Image}
        para1="Reach out for help or ask questions using #SkillName and get help instantly"
      />
    ),
    placement: "left-start",
    hideCloseButton: true,
  },
  {
    target: ".sidebar-cms",
    content: (
      <Step
        step={4}
        image={step4Image}
        para1="Create content and contribute to knowledge building among your peers."
      />
    ),
    placement: "left-start",
    hideCloseButton: true,
  },
  {
    target: ".nav-help",
    content: (
      <Step
        step={5}
        image={step5Image}
        para1="Keep buzzing around and earn points. You can always start the tour again!"
      />
    ),
    placement: "left-start",
    hideCloseButton: true,
  },
];

const CHAT_TOUR_STEPS = [
  {
    target: ".chat-more-icon",
    content: (
      <Step
        image={endorseImage}
        para1="Endorse your peers for any skill they are expert at and earn points."
        para2="Add a topic you wish to learn from them"
      />
    ),
    placement: "left-start",
    disableBeacon: true,
    disableScrolling: true,
    disableScrollParentFix: true,
  },
];

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: DASHBOARD_TOUR_STEPS,
  dontShowAgain: false,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START_DASHBOARD":
      return { ...state, steps: DASHBOARD_TOUR_STEPS, run: true };
    case "START_CHAT":
      return { ...state, steps: CHAT_TOUR_STEPS, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    case "DONT_SHOW_AGAIN":
      return {
        ...state,
        dontShowAgain: true,
      };
    default:
      return state;
  }
};

// Define the Tour component
function Tour({
  tourType = "dashboard",
  setTrigger,
  status,
  stopTour,
  currentTrigger,
  closeTour,
}) {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (!localStorage.getItem("tour") && tourType && status) {
      //debugger;
      switch (tourType) {
        case "dashboard":
          //debugger;
          if (tourState.stepIndex > 0) {
            startTour();
          } else {
            dispatch({ type: "START_DASHBOARD" });
          }
          break;
        case "chat":
          dispatch({ type: "START_CHAT" });
          break;
        default:
          break;
      }
    }
  }, [tourType, status]);

  const startTour = () => {
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      {tourState.run && tourState.stepIndex < 4 && (
        <div className="buzz-box-transparent" />
      )}
    </>
  );
}

export default Tour;
