/*jshint esversion:9*/
/*jshint -W087*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,TextField,IconButton,Button,CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SuccessLogo from '../../../Assets/success.png';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline:'none!important',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,2,2),
    width:'48%'
  }
}));

export default function MasterClassModal({Open,handleClose,skill}) {
  const classes = useStyles();

  const [loading,setLoading] = useState(false);
  const [success,setSuccess] = useState(false);
  const [question, setQuestion] = useState('');

  const handleRegistration = () => {
    try {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }
 
  const handleQuestionChange = e => {
    const {value} = e.target;
    if(value.length === 200) {
      return;
    }
    setQuestion(value);
  }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={Open}>
          <div className={classes.paper}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                {!loading && !success && <Typography variant='h6' className='skill-name' style={{width:'100%'}}><span style={{position:'relative',top:'15px', fontWeight:'720',fontSize:'1.3rem'}}>Master Class on {skill}</span></Typography>}
                {(loading || success) && <div></div>}
                <div style={{borderBottom: (!loading && !success) && '1.5px solid black'}}>
                  <IconButton onClick={() => handleClose()}>
                      <CloseIcon/>
                  </IconButton>
                </div>
            </div>
            {loading && 
            <div className='loading-container'>
                <CircularProgress color='primary'/>
                <p>Getting you registered...</p>
            </div>
            }
            {!loading && !success && 
              <div className='form'>
                <TextField 
                  value={question}
                  variant='outlined' 
                  label='Your Question' 
                  placeholder='Ask your question (200 characters max.)' 
                  className='question-textField'
                  onChange={handleQuestionChange}
                  multiline
                  rows={3}
                />
                <br/>
                <div className='register-btn-container'>
                  <Button variant='contained' className='edit-btn' onClick={handleRegistration}>Register</Button>
                </div>
            </div>}
            {!loading && success && <div className='success-container'>
                <div className='create-collab-success-logo'>
                    <img src={SuccessLogo} width='80' height='80'/>
                </div>
                <h2>See you at the master class!</h2>
            </div>}
            <br/>
          </div>
        </Fade>
      </Modal>
  );
}
