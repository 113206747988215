/*jshint esversion:9*/

import React from 'react';
import {Typography} from '@material-ui/core';
import './welcome-style.scss';

export default function WelcomePage(){
  return(
    <div className='welcome-container'>
      <Typography variant='h4' style={{fontWeight:'bold'}}>Welcome, Owner !</Typography>
    </div>
  )
}
