/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BuzzBox from '../buzz-box/buzz-box-component';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData} from '../../redux/user/user-selector';
import './shoutout-style.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height:'100%'}}
    >
      {value === index && (
        <Box p={3} className='shoutout-tab-panel-box'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height:'100%',
  },
}));

 function ShoutoutTab({userData,handleClose}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [activitySkip,setActivitySkip] = useState(0);
  const [updatesSkip,setUpdatesSkip] = useState(0);

 

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

 
  return (
    <div className={classes.root}>
      <AppBar position="sticky" className='appBar shoutout-appBar' style={{height:'100%',width:'350px'}}>
        <Tabs
        className='skillTab'
        value={value}
        onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Activity" {...a11yProps(0)} />
          <Tab label="Updates" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <BuzzBox type='activity' handleDrawerClose={()=>handleClose()} tabValue={value}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BuzzBox type='updates' handleDrawerClose={()=>handleClose()} tabValue={value}/>           
        </TabPanel>
      </AppBar>

    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
});

export default connect(mapStateToProps)(ShoutoutTab);
