/*jshint esversion:9*/
import {companyActionTypes} from './company-types';

export const setCompanyList = array =>({
  type:companyActionTypes.SET_COMPANY_LIST,
  payload:array
});

export const deleteCompany = id => ({
  type:companyActionTypes.DELETE_COMPANY,
  payload:id
});

export const updateCompany = object => ({
  type:companyActionTypes.UPDATE_COMPANY,
  payload:object
});

export const addCompany = object => ({
  type:companyActionTypes.ADD_COMPANY,
  payload:object
});
