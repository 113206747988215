import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Document, Page, pdfjs } from "react-pdf";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import "./style.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FilePreviewPopup = ({ open, setOpen, url }) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const zoomOut = () => {
    if (scale > 0.6) {
      setScale(scale - 0.1);
    }
  };
  const zoomIn = () => {
    if (scale < 2) {
      setScale(scale + 0.1);
    }
  };
  return (
    <>
      <Dialog
        onContextMenu={(e) => e.preventDefault()}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className='modal_scrollbar'
      >
        <DialogTitle id="alert-dialog-title">
        <div 
        className='close_button'
        >
        <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Document onLoadSuccess={onDocumentLoadSuccess} file={url}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                scale={scale}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </DialogContent>
        <DialogActions>
          <IconButton
            disabled={scale <= 0.6 ? true : false}
            onClick={zoomOut}
            color="primary"
          >
            <ZoomOutIcon />
          </IconButton>
          <strong>Zoom</strong>
          <IconButton
            disabled={scale >= 2 ? true : false}
            onClick={zoomIn}
            color="primary"
          >
            <ZoomInIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilePreviewPopup;
