import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core';

const CustomTab = withStyles({
    root: {
        minWidth: 72,
        marginLeft: '1rem',
        textTransform: 'capitalize',
        '& > span': {
            fontWeight: '600'
        }
    },
    selected: {
        color: '#2092ca'
    }
})(Tab);

export default CustomTab