/*jshint esversion:9*/

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CommonSnackbar({
  Open,
  handleClose,
  alert,
  severity,
  anchorOrigin,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        open={Open}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          onClose={() => handleClose()}
          severity={severity}
          style={{ backgroundColor: severity === "success" ? "#66CCFF" : "" }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
}
