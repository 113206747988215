/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,Button,TextField,IconButton} from '@material-ui/core';
import Input from '../Input/Input';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddSkillModal({open,handleClose,data,handleUpdate}) {
  const classes = useStyles();
  const [info,setInfo] = useState({
    skillCategoryName:'',
    image:null
  });

  useEffect(()=>{
    if(data){
      console.log(data);
          setInfo({
            skillCategoryName:data.skillCategory.skillCategoryDisplayName,
            image:data.skillCategory.imageKey
          });
    }
  },[data]);

  const handleChange = e => {
    const {value} = e.target;
    setInfo(prevValue => {
      return{
        ...prevValue,
        skillCategoryName:value
      };
    });
  };

  const handleUpload = e => {
    setInfo(prevValue => {
      return{
        ...prevValue,
        image:e.target.files[0]
      };
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction='up'>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold'}}>Edit {data && data.skillCategory.skillCategoryDisplayName}</Typography>
            <br/>
            <Input value={info.skillCategoryName} onChange={handleChange} type='text' label='Category Name'/>
            <br/>
            <Typography variant='subtitle1'>Current Image: {data&&data.skillCategory.imageKey!==''?data.skillCategory.imageKey:'none'}</Typography>
            <br/>
            <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
              <div style={{width:'100%'}}>
                <TextField
                className='input'
                value={info.image&&info.image.name}
                id="input-with-icon-textfield"
                label='Category Image'
                type='text'
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}

                />
                  <input  name='image' type='file' id='edit-image-upload' onChange={handleUpload} style={{display:'none'}}/>
                </div>
                <div >
                  <label htmlFor='edit-image-upload'>
                    <IconButton color="primary" aria-label="upload picture" component="span" >
                      <PublishIcon/>
                    </IconButton>
                  </label>
                </div>
          </div>
          <br/>
            <div style={{textAlign:'center'}}>
            <Button variant='outlined'  style={{marginRight:'5px',color:'#66CCFF',borderColor:'#66CCFF'}} onClick={()=>
              {
            handleUpdate(data,info);
          }
        }>Update</Button>
            <Button variant='outlined' style={{color:'red',borderColor:'red'}} onClick={()=>handleClose()}>Discard</Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
