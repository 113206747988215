/*jshint esversion:9*/

import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import EditCompanyOption from "../edit-company-option/edit-company-option";
import logoIcon from "../../../Assets/logo-icon.png";
import AddressLogo from "../../../Assets/address-logo.png";
import DetailsLogo from "../../../Assets/details-logo.png";
import CategoryLogo from "../../../Assets/category-logo.png";
import PointsLogo from "../../../Assets/star.png";
import "./edit-company-modal-style.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 550,
  },
}));

export default function EditCompanyModal({ Open, handleClose, handleSelect }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={Open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={Open} direction="up">
          <div className={classes.paper}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                marginBottom: "50px ",
              }}
            >
              Choose an option to edit
            </Typography>
            <div className="edit-option-container">
              <EditCompanyOption
                img={logoIcon}
                text="Logo"
                onClick={() => {
                  handleSelect("logo");
                }}
              />
              <EditCompanyOption
                img={AddressLogo}
                text="Locations"
                onClick={() => handleSelect("location")}
              />
              <EditCompanyOption
                img={CategoryLogo}
                text="Categories"
                onClick={() => handleSelect("categories")}
              />
              <EditCompanyOption
                img={PointsLogo}
                text="Points"
                onClick={() => handleSelect("points")}
              />
              <EditCompanyOption
                img={CategoryLogo}
                text="Departments"
                onClick={() => handleSelect("departments")}
              />
              <EditCompanyOption
                img={DetailsLogo}
                text="Masterclass"
                onClick={() => handleSelect("masterclass")}
              />
              <EditCompanyOption
                img={DetailsLogo}
                text="Others"
                onClick={() => handleSelect("other")}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClose()}
              >
                Close
              </Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
