/*jshint esversion:9*/

import React,{useState,useEffect} from 'react';
import { makeStyles,Typography,Button,TextField,IconButton} from '@material-ui/core';
import Input from '../Input/Input';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddSkillModal({open,handleClose,data,handleCategoryDelete}) {
  const classes = useStyles();
    
  useEffect(() => {
    console.log(data);
  },[data])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} direction='up'>
          <div className={classes.paper}>
            <Typography variant='h5' style={{fontWeight:'bold'}}>Delete {data && data.skillCategory.skillCategoryDisplayName}</Typography>
            <br/>
            <Typography variant='h6' style={{textAlign:'center'}}>Are you sure ?</Typography>
            <br/>
            <div style={{textAlign:'center'}}>
            <Button variant='outlined'  style={{marginRight:'5px',color:'red',borderColor:'red'}} onClick={()=>
              {
                handleCategoryDelete(data.skillCategory.publicId);
            }
            }>Delete</Button>
            <Button variant='outlined'  onClick={()=>handleClose()}>Cancel</Button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
