import cmsAxios from '../../../axios/authAxios';

export const FETCH_PUBLISHED_ARTICLES = "FETCH PUBLISHED ARTICLES";
const fetchPublishedArticle = () => dispatch => {
    cmsAxios.get("cms/article/me/published")
    .then(response => {
        dispatch({ type: FETCH_PUBLISHED_ARTICLES, payload: response.data });
    })
}

export default fetchPublishedArticle
