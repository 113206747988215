import {buzzesActionTypes} from './buzzes-types';

const INITIAL_STATE = {
    currentBuzzes : []
}

const buzzesReducer = (state = INITIAL_STATE,action) => {
    switch (action.type) {
        case buzzesActionTypes.SET_CURRENT_BUZZES:
            return {
                ...state,
                currentBuzzes: action.payload
            }
    
        default:
            return state;
    }
}

export default buzzesReducer;