import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const ImagePreviewPopup = ({ open, setOpen, imageUrl }) => {
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        className="modal_scrollbar"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="close_button">
            <IconButton aria-label="close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <img
            style={{ display: "block", margin: "auto" }}
            alt="img_"
            width='100%'
            height='100%'
            src={imageUrl}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreviewPopup;
