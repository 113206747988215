import React from "react";
import TextField from "@material-ui/core/TextField";
import classes from "./synopsis.module.scss";
import { makeStyles } from "@material-ui/core/styles";

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: "1px",
    float: "right",
    position: "absolute",
    bottom: "-20px",
    right: "0px",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
}));

const Synopsis = (props) => {
  const helperTestClasses = helperTextStyles();

  const [charCount, setCharCount] = React.useState(0);
  React.useEffect(() => {
    setCharCount(props.value.length);
  }, [props.value]);
  return (
    <div className={`my-md`}>
      <h4 className={`w-600`}>
        Synopsis<sup>*</sup>
      </h4>
      <TextField
        multiline
        variant="outlined"
        fullWidth
        value={props.value}
        onChange={props.setValue}
        error={props.error}
        helperText={props.error && props.error}
        FormHelperTextProps={{ classes: helperTestClasses }}
      />
      <small className={`${classes.charCount}`}>
        Maximum character: {charCount}/140
      </small>
    </div>
  );
};

export default Synopsis;
