import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
const useStylesContained = makeStyles({
    root: {
        backgroundColor: "#66CCFF",
        textTransform: "none",
        padding: 10,
        '& > span': {
            fontWeight: 600,
            color: "white"
        }
    }
})
const useStylesOutlined = makeStyles({
    root: {
        border: "2px solid #66CCFF",
        textTransform: "none",
        padding: 10,
        '& > span': {
            fontWeight: 600,
            color: "#66CCFF"
        }
    }
})
const useStyles = makeStyles({
    root: {
        textTransform: "none",
        padding: 10,
        '& > span': {
            fontWeight: 600,
        }
    }
})
const SecondaryButton = ({ children, ...props }) => {
    const classContained = useStylesContained();
    const classesOutlined = useStylesOutlined();
    const classes = useStyles();
    switch (props.variant) {
        case "contained":
            return <Button className={`${classContained.root} ${props.className}`} {...props} >{children}</Button>
        case "outlined":
            return <Button className={`${classesOutlined.root} ${props.className}`} {...props} >{children}</Button>
        default:
            return <Button className={`${classes.root} ${props.className}`} {...props} >{children}</Button>
    }
}

export default SecondaryButton;