/*jshint esversion:9*/
/*jshint -W087*/


import React,{useEffect,useState} from 'react';
import {Button,CircularProgress} from '@material-ui/core';
import SkillTab from '../SubComponents/skillTab/skillTab-new-component';
import TitleText from '../SubComponents/createProfile-titleText/titleText';
import SubText from '../SubComponents/createProfile-subText/subText';
import Snackbar from '../SubComponents/snackbar/snackbar';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectUserData,selectCompanyInformation} from '../../redux/user/user-selector';
import {setUserData} from '../../redux/user/user-actions';
import ErrorBoundary from '../error-boundary/error-boundary-component';
import Loader from '../SubComponents/loader/loader-component';
import Logout from '../../logout';
import axios from 'axios';
import './style.scss'

function EditSkillsCategories({companyInformation,userData,setUserData, history}){

  const [allSkillsAndCategories,setAllSkillsAndCategories] = useState(null);
  const [selectedSkills,setSelectedSkills] = useState([]);
  const [getLoading,setGetLoading] = useState(false);
  const [loading,setLoading] = useState(false);
  const [severity,setSeverity] = useState('');
  const [message,setMessage] = useState('');
  const [alertOpen,setAlertOpen] = useState(false);

  useEffect(()=>{
    try {
      const token = localStorage.getItem('bhyve-token');
      if(companyInformation) {
        const getAllInterests = async() => {
          const result = await axios.get(`${process.env.REACT_APP_API_URL}user/me/skillCategories`,{
            headers:{
              'Authorization': `Bearer ${token}`,
              'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
            }
          });
          return result;
        };
  
        const getCategoriesAndSkills = async () => {
          setGetLoading(true);
          let allCategoriesResult = await getAllInterests();
          if(allCategoriesResult) {
            let allCategories = allCategoriesResult.data;
            setAllSkillsAndCategories(allCategories);
            console.log(allCategories);
            setGetLoading(false);
          }
        };
        getCategoriesAndSkills();
  
      }
    } catch (error) {
      console.log(error);
      if(error.response && error.response.status === 401) {
        history.push('/logout');
      }
    }
  },[companyInformation]);


  useEffect(()=>{
    if(userData) {
      setSelectedSkills(userData.userInformation.userSkillsSet.userSkills);
    }
  },[userData])


 

  const handleSave = async () => {
    try {
      console.log(selectedSkills);
    if(selectedSkills.length > 4) {
      setLoading(true);
      let updateSkillsResult = await updateSkills();
      if(updateSkillsResult) {
        console.log(updateSkillsResult);
        var current = {
          ...userData,
          userInformation:updateSkillsResult.data
        };
        setUserData(current);
        setLoading(false);
        setMessage('User skill set updated successfully!');
        setSeverity('success');
        setAlertOpen(true);
      }
  }else{
    setMessage('Please select atleast 5 skills');
    setSeverity('warning');
    setAlertOpen(true);
  }
    } catch (err) {
      console.log(err);
      setMessage(err.message);
      setSeverity('error');
      setAlertOpen(true);
    }
  };

  const updateSkills = async () => {

      const token = localStorage.getItem('bhyve-token');
    const result = await axios.patch(`${process.env.REACT_APP_API_URL}user/skills`,{userSkills:selectedSkills},{
      headers:{
        'Authorization': `Bearer ${token}`,
        'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
      }
    });
    return result;
  };



  const setNewSkillList = list => {
    setSelectedSkills(list);
  }

  return(
    <div style={{width:'100%',textAlign:'center'}}>
                    <Loader open={getLoading}/>
    <ErrorBoundary>
            <Snackbar Open={alertOpen} severity={severity} alert={message} handleClose={()=>setAlertOpen(false)}/>
      <div className='create-profile-top-right'>
          <div>     
              <h2>Pick your top skills from the list</h2>
              <p>Please select atleast 5 skills</p> 
          </div>
          <div className='button-container'>
            <Button  className='back-btn' onClick={() => history.push(`/User/Profile/${userData.userInformation.publicId}`)}>Back to Profile</Button>
            {!loading && <Button variant='contained' className='nxt-btn' onClick={() => handleSave()}>Save</Button>}
            {loading && <CircularProgress disableShrink style={{margin: 'auto auto'}}/>}
          </div>
        </div>
      <SkillTab  userCategories={userData&&userData.userInformation.userSkillsSet.userSkills}  allCategoriesAndSkills={allSkillsAndCategories&&allSkillsAndCategories.skillCategories} setNewSkillList={setNewSkillList}/>      
      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  userData:selectUserData,
  companyInformation:selectCompanyInformation
});

const mapDispatchToProps = dispatch => ({
  setUserData: object => dispatch(setUserData(object))
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditSkillsCategories));

//<EditSkillSetModal open={editModal} handleDone={handleSkillSelect} handleClose={()=>setEditModal(false)} handleRemove={handleRemove} selectedSkill={selected}/>
