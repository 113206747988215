import React, { useState, useRef, useCallback, useEffect } from "react";
import style from "./myArticles.module.scss";
import CustomTabs from "../../util/CustomTabs";
import ArticleListItem from "../../util/articleListItem";
import Wrapper from "../../util/wrapper";
import Paper from "@material-ui/core/Paper";
import CustomTab from "../../util/tab";
import { useHistory, useRouteMatch } from "react-router";
import SecondaryButton from "../../util/secondaryButton";
import { connect } from "react-redux";
import cmsAxios from "../../axios/authAxios";
import AddIcon from "@material-ui/icons/Add";
import BreadCrumbs from "../../util/breadCrumbs";
import Loading from "../../util/loading";
import fetchPublishedArticle from "../../redux/article/actions/fetch_published_article";
import fetchDraftArticle from "../../redux/article/actions/fetch_draft_article";
import getSynopsis from "../../util/getSynopsis";

const MyArticlesPage = ({
  isDraftLoaded,
  isPublishedLoaded,
  publishedArticles,
  draftArticles,
  ...props
}) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [currentTab, setCurrentTab] = useState(0);
  const handleArticleItemClick = (e, id) =>
    history.push(`/User/cms/${id}/singleArticle`);
  const TabPanel = (props) =>
    props.value === props.index ? (
      <div class={`${style.article_list_wrapper}`}>{props.children}</div>
    ) : null;
  const handleEditArticleItemClick = (e, id) =>
    history.push(`/User/cms/${id}/edit`);
  const handleBookmark = (e, id, setBookmark, current) => {
    let articles =
      currentTab === 0
        ? publishedArticles
        : currentTab === 1
        ? draftArticles
        : publishedArticles;
    let article = articles.find((article) => article.contentPublicId === id);
    if (!article) return console.log("no article found with id" + id);
    if (current) {
      cmsAxios
        .delete(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/bookmark`)
        .then((response) => {
          if (response.status < 300) {
            console.log("success");
            setBookmark(!current);
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleLike = (e, id, setLike, current) => {
    let element = e.currentTarget;
    let articles =
      currentTab === 0
        ? publishedArticles
        : currentTab === 1
        ? draftArticles
        : publishedArticles;
    const article = articles.find((article) => article.contentPublicId === id);
    if (!article) return console.log("no article found with id" + id);
    if (current) {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/unlike`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            // console.log(
            //   (element.querySelector("small").innerHTML =
            //     parseInt(element.querySelector("small").innerHTML) - 1)
            // );
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    } else {
      cmsAxios
        .post(`/cms/article/${article.contentPublicId}/like`)
        .then((response) => {
          if (response.status < 300) {
            setLike(!current);
            // console.log(
            //   (element.querySelector("small").innerHTML =
            //     parseInt(element.querySelector("small").innerHTML) + 1)
            // );
            return response.data;
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const handleDelete = (id) => {
    cmsAxios.delete(`/cms/article/${id}`).then((response) => {
      if (isDraftLoaded) props.fetchDraftArticle();
      if (isPublishedLoaded) props.fetchPublishedArticle();
    });
  };
  const handlePublishEditClick = (id) => {
    cmsAxios.put(`/cms/article/${id}/unpublish`).then((response) => {
      if (response.data && response.data === "Article moved to draft") {
        history.push(`/User/cms/${id}/edit`);
      }
    });
  };
  const handleEdit = (id) => history.push(`${path}/${id}/edit`);
  const getPublishedArticles = (articles) => {
    return articles.map((article) => (
      <ArticleListItem
        key={article.contentPublicId}
        articleId={article.contentPublicId}
        publishedOn={article.updatedAt}
        onClick={handleArticleItemClick}
        authorName={
          article.user && `${article.user.firstName} ${article.user.lastName}`
        }
        moreOptionHidden={
          props.user.UserData.userInformation.publicId !== article.user.publicId
        }
        authorDesignation={article.user ? article.user.department : null}
        articleTitle={article.title}
        articleContent={article.synopsis}
        articleCover={article.coverImageUrl}
        articleThumbnail={article.thumbnailImageUrl}
        likesNo={article.likesCount}
        commentNo={article.commentsCount}
        viewsNo={article.viewsCount}
        author_profile={article.user && article.user.profilePhotoUrl}
        articleThumbnail={article.thumbnailImageUrl}
        read={article.content}
        handleLike={handleLike}
        like={article.likedByMe}
        bookmark={article.bookmarkedByMe}
        handleBookmark={handleBookmark}
        edit={true}
        remove={true}
        share={true}
        handleDelete={handleDelete}
        handleEdit={handlePublishEditClick}
        isEdited={article.isEdited}
        // likedByMe={article.likedByMe || (article.contentPublicId === like.id && like.liked)}
        // bookmarkedByMe={article.bookmarkedByMe || (article.contentPublicId === bookmark.id && bookmark.bokkmarked)}
        // isUnliked={article.contentPublicId === like.id && unLike.unliked}
        // unLike={handleUnLike}
        // deleteArticle={deleteArticle}
        // unBookMark={handleDeleteBookmark}
        // isUnBookmarked={article.contentPublicId === unBookmark.id && unBookmark.bokkmarked}
        disableBookMark
        // editArticleEligible={false}
      />
    ));
  };
  const getDraftArticles = (articles) => {
    return articles.map((article) => (
      <ArticleListItem
        key={article.contentPublicId}
        publishedOn={article.updatedAt}
        articleId={article.contentPublicId}
        onClick={handleEditArticleItemClick}
        author={article.user}
        articleTitle={article.title}
        articleContent={article.synopsis}
        articleCover={article.coverImageUrl}
        articleThumbnail={article.thumbnailImageUrl}
        likesNo={article.likesCount}
        commentNo={article.commentsCount}
        viewsNo={article.viewsCount}
        read={article.content}
        handleLike={handleLike}
        like={article.likedByMe}
        bookmark={article.bookmarkedByMe}
        handleBookmark={handleBookmark}
        remove={true}
        share={false}
        handleDelete={handleDelete}
        isEdited={article.isEdited}
        isDraft={true}
        // editArticleEligible={true}
        // deleteArticle={deleteArticle}
        disableBookMark
      />
    ));
  };
  React.useEffect(() => props.fetchPublishedArticle(), []);
  React.useEffect(() => {
    if (currentTab == 0 && !isPublishedLoaded) props.fetchPublishedArticle();
    if (currentTab == 1 && !isDraftLoaded) props.fetchDraftArticle();
  }, [currentTab]);
  return (
    <>
      <div className={`${style.wrapper} mt-lg`}>
        <BreadCrumbs />
        <Paper
          className="py-md"
          style={{ width: "100%", backgroundColor: "white" }}
        >
          <div className={`px-md ${style.main_header_wrapper}`}>
            <h3 className={`w-500 my-0`}>My Content</h3>
            <SecondaryButton
              style={{ borderRadius: 25 }}
              variant="outlined"
              startIcon={<AddIcon />}
              className={style.addBtn}
              onClick={() => history.push("/User/cms/newArticle")}
            >
              {" "}
              New
            </SecondaryButton>
          </div>

          <CustomTabs
            value={currentTab}
            onChange={(_, val) => setCurrentTab(val)}
          >
            <CustomTab label="Published" />
            <CustomTab label="Drafts" />
          </CustomTabs>

          <hr className={`my-0`} style={{ opacity: 0.5 }} />
          <TabPanel value={currentTab} index={0}>
            {isPublishedLoaded ? (
              publishedArticles.length > 0 ? (
                getPublishedArticles(publishedArticles)
              ) : (
                <p className="px-md">No articles to show!</p>
              )
            ) : (
              <Loading />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {isDraftLoaded ? (
              draftArticles.length > 0 ? (
                getDraftArticles(draftArticles)
              ) : (
                <p className="px-md">No articles to show!</p>
              )
            ) : (
              <Loading />
            )}
          </TabPanel>
        </Paper>
      </div>
    </>
  );
  // const history = useHistory();
  // const [currentArticles, setCurrentArticles] = useState([]);
  // const [currentTab, setCurrentTab] = useState(0);
  // const [isDelete, setIsDelete] = useState(false);
  // const [like, setLike] = useState({
  //   id: 1,
  //   liked: false
  // })
  // const [unLike, setUnLike] = useState({
  //   id: 1,
  //   unliked: false
  // })
  // const [bookmark, setBookMark] = useState({
  //   id: 1,
  //   bokkmarked: false
  // })
  // const [unBookmark, setUnBookMark] = useState({
  //   id: 1,
  //   bokkmarked: false
  // })
  // const TabPanel = (props) => (props.value === props.index) ? <div class={`${style.article_list_wrapper}`}>{props.children}</div> : null;
  // const handleArticleItemClick = (e, id) => history.push(`/User/cms/${id}/singleArticle`)
  // const handleEditArticleItemClick = (e, id) => history.push(`/User/cms/${id}/edit`)
  // useEffect(() => {
  //   try {
  //     async function gettingArticles(tabValue) {
  //       let gettingArticlesResult;
  //       if (tabValue === 0) {
  //         gettingArticlesResult = await cmsAxios.get('cms/article/me/published');
  //       } else {
  //         gettingArticlesResult = await cmsAxios.get('cms/article/me/draft');
  //       }
  //       fetchArticles(gettingArticlesResult.data);
  //     }
  //     gettingArticles(currentTab)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [currentTab, articlesLoaded]);

  // const deleteArticle = async (id) => {
  //   try {
  //     const response = await cmsAxios.delete(`/cms/article/${id}`)
  //     setIsDelete(!isDelete)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const handleLike = async (e, id) => {
  //   const article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${article.contentPublicId}/like`)
  //     setLike({
  //       id: article.contentPublicId,
  //       liked: true
  //     })
  //     setUnLike({
  //       id,
  //       unliked: false
  //     })

  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // const handleUnLike = async (e, id) => {
  //   const article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${article.contentPublicId}/unlike`)
  //     setUnLike({
  //       id,
  //       unliked: true
  //     })
  //     setLike({
  //       id,
  //       liked: false
  //     })
  //   } catch (err) {
  //   }
  // }
  // const handleBookmark = async (e, id) => {
  //   let article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.post(`/cms/article/${article.contentPublicId}/bookmark`)
  //     setBookMark({
  //       id: article.contentPublicId,
  //       bokkmarked: true
  //     })
  //     setUnBookMark({
  //       id: article.contentPublicId,
  //       bokkmarked: false
  //     })

  //   } catch (err) {
  //   }
  // }
  // const handleDeleteBookmark = async (e, id) => {
  //   let article = articles.find(article => article.contentPublicId === id);
  //   try {
  //     const response = await cmsAxios.delete(`/cms/article/${article.contentPublicId}/bookmark`)
  //     setBookMark({
  //       id,
  //       bokkmarked: false
  //     })
  //     setUnBookMark({
  //       id: article.contentPublicId,
  //       bokkmarked: true
  //     })
  //   } catch (err) {
  //   }
  // }
  // console.log(like, 'my like')

  // return (
  //   <div className={`${style.wrapper} mt-lg`}>
  //     <BreadCrumbs />
  //     <Wrapper className="py-md" style={{ width: '100%', backgroundColor: 'white' }}>
  //       <div className={`px-md ${style.main_header_wrapper}`}>
  //         <h3 className={`w-500 my-0`}>My Content</h3>
  //         <SecondaryButton
  //           style={{ borderRadius: 25 }}
  //           variant="outlined"
  //           startIcon={<AddIcon />}
  //           onClick={() => history.push('/User/cms/newArticle')}> New</SecondaryButton>
  //       </div>

  //       <CustomTabs value={currentTab} onChange={(_, val) => setCurrentTab(val)}>
  //         <CustomTab label="Published" />
  //         <CustomTab label="Drafts" />
  //       </CustomTabs>

  //       <hr className={`my-0`} />
  //       <TabPanel value={currentTab} index={0}>
  //         {(currentArticles && currentArticles.length > 0) ? currentArticles.map((article) => (
  //           <ArticleListItem
  //             articleId={article.contentPublicId}
  //             onClick={handleArticleItemClick}
  //             authorName={article.user && `${article.user.firstName} ${article.user.lastName}`}
  //             authorDesignation={article.author_designation}
  //             articleTitle={article.title}
  //             likesNo={article.likesCount}
  //             commentNo={article.commentsCount}
  //             viewsNo={article.viewsCount}
  //             articleCover={article.coverImageUrl}
  //             articleContent={article.content}
  //             author_profile={article.user && article.user.profilePhotoUrl}
  //             bookMarkClick={handleBookmark}
  //             likeClick={handleLike}
  //             likedByMe={article.likedByMe || (article.contentPublicId === like.id && like.liked)}
  //             bookmarkedByMe={article.bookmarkedByMe || (article.contentPublicId === bookmark.id && bookmark.bokkmarked)}
  //             isUnliked={article.contentPublicId === like.id && unLike.unliked}
  //             unLike={handleUnLike}
  //             deleteArticle={deleteArticle}
  //             unBookMark={handleDeleteBookmark}
  //             isUnBookmarked={article.contentPublicId === unBookmark.id && unBookmark.bokkmarked}
  //             editArticleEligible={false}
  //             disableBookMark
  //           />
  //         )) : <Loading />}
  //       </TabPanel>
  //       <TabPanel value={currentTab} index={1}>
  //         {currentArticles ? currentArticles.map((article) => (
  //           <ArticleListItem
  //             articleId={article.contentPublicId}
  //             onClick={handleEditArticleItemClick}
  //             authorName={article.user && `${article.user.firstName} ${article.user.lastName}`}
  //             authorDesignation={article.author_designation}
  //             articleTitle={article.title}
  //             likesNo={article.likesCount}
  //             articleCover={article.coverImageUrl}
  //             commentNo={article.commentsCount}
  //             articleContent={article.content}
  //             viewsNo={article.viewsCount}
  //             author_profile={article.user && article.user.profilePhotoUrl}
  //             read={article.content}
  //             editArticleEligible={true}
  //             deleteArticle={deleteArticle}
  //             disableBookMark
  //           />
  //         )) : <Loading />}
  //       </TabPanel>
  //     </Wrapper>
  //   </div>
  // );
};

const mapStateToProps = (state) => ({
  publishedArticles: state.articles.publishedArticles,
  isPublishedLoaded: state.articles.isPublishedLoaded,
  draftArticles: state.articles.draftArticles,
  isDraftLoaded: state.articles.isDraftLoaded,
  user: state.user,
});

const mapDispatchToProps = {
  fetchDraftArticle,
  fetchPublishedArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyArticlesPage);
