/*jshint esversion:9*/

import React from 'react';
import {Typography,Avatar,Link} from '@material-ui/core';
import confetti from '../../../Assets/confetti.png';
import bhyveLogo from '../../../Assets/bhyveNewLogo.png';
import bhyveFav from '../../../Assets/favicon.ico'
import moment from 'moment';

export default function NotificationPost(
  {
    post,
    navigateToPublisher,
    navigateToShoutout,
    openMilestoneModal,
    navigateToChat,
    navigateToSearch,
    navigateToArticle,
    openRankOneModal,
    navigateToExperience
  }){

  function getTaggedSkills(input) {
    try {
      let words = input.split(', ');
      words = words.map(item => `#${item}`);
      console.log('notification words: ',words);
      return words;
    } catch (error) {
      console.log(error);
      return [`#${input}`];
    }
  }

  const handleClick = e => {
    console.log(e.target.id);
    try {
      if(e.target.id !== 'tagged-skill') {
        switch (post.postType) {
          case 'ACCEPT_COLLABORATION':
            navigateToChat(post.publisherUserPublicId);
            break;
          case 'REJECT_COLLABORATION':
            navigateToSearch(post.skillName);
            return null;
            break;
          case 'POINTS_MILESTONE':
          case 'BADGE_RECEIVED':
          case 'POINTS_ACCOUNT_OPEN':
            openMilestoneModal(post.postType,post.badgePublicId&&post.badgePublicId);
            break;
          case 'PUBLISH_ARTICLE':
          case 'SKILL_TAGGED_ARTICLE':
          case 'CO_AUTHOR_TAGGED_ARTICLE':
          case 'USER_TAGGED_ARTICLE_COMMENT':
          case 'ARTICLE_LIKED':
          case 'ARTICLE_COMMENTED':
            navigateToArticle(post.articleId);
            break;
          case 'NUMERO_UNO':
            openRankOneModal();
            break;
          case 'LIKE_POSTED_SHOUTOUT':
          case 'SKILL_TAGGED_SHOUTOUT':
          case 'LIKE_POSTED_COMMENT':
          case 'USER_TAGGED_SHOUTOUT':
          case 'SKILL_TAGGED_COMMENT':
          case 'ANOTHER_COMMENT_ACTIVITY_SHOUTOUT':
            navigateToShoutout(post.shoutoutPublicId);
            break;
          case 'BROADCAST':
            if(post.type === "WORK_EXPERIENCE") {
              navigateToExperience();
              return;
            }
            break;
          default:
            return null;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function capitalize(input) {  
    try {
      let word = input;
      if(word) {
        var words = word.split(' ');  
        var CapitalizedWords = [];  
        let wordsTobeIgnored = ['and','the','of','on','are', 'a']; 
        words.forEach(element => {
            let currentElement = '';  
            if(element.split('').length > 1 && element.split('').includes('&')) {
              let temp = element.split[0];
              currentElement = element.split('')[0].toUpperCase() + '&' + element.split('')[2].toUpperCase();
            }else{
              currentElement = element;
            }
            if(currentElement === 'cpr') {
              CapitalizedWords.push('CPR');  
            }else if(currentElement === 'chp') {
              CapitalizedWords.push('CHP');  
            }else if(wordsTobeIgnored.includes(currentElement)) {
              CapitalizedWords.push(currentElement); 
            }else {
              CapitalizedWords.push(currentElement[0].toUpperCase() + currentElement.slice(1, currentElement.length));  
            }         
        });  
        return CapitalizedWords.join(' ');  
      } 
    } catch (error) {
      console.log(error);
      return input;
    }
}  

  return(
    <div onClick={handleClick} style={{cursor:'pointer',width:'100%',marginBottom:'20px',padding:'5px 5px 20px 5px',borderBottom:'1px solid rgba(200, 200, 200, 0.5)',backgroundColor:post.published?'':'#E8F3FC'}}>

        <div style={{display:'grid',gridTemplateColumns:'20% 60%',columnGap:'5px'}}>
          {(post.postType !== 'POINTS_MILESTONE' && post.postType !== 'POINTS_ACCOUNT_OPEN') && post.publisherDp!=='' &&
            <Avatar src={post.publisherDp} style={{cursor:'pointer',width:'60px',height:'60px'}} onClick={e=>{e.stopPropagation(); navigateToPublisher();}}/>
          }
          {(post.postType !== 'POINTS_MILESTONE' && post.postType !== 'POINTS_ACCOUNT_OPEN' && post.postType !== 'BROADCAST') && post.publisherDp==='' && 
            <Typography variant='h5' className='initial-container' onClick={e => {e.stopPropagation(); navigateToPublisher();}} style={{width:'60px',height:'60px',textAlign:'center',display:'',paddingTop:'15px',color:'white',background:'#FFCC33',marginBottom:'15px'}}>
              {post.publisherName.split(' ').length>1?`${post.publisherName.split(' ')[0].slice(0,1)}${post.publisherName.split(' ')[1].slice(0,1)}`:`${post.publisherName.slice(0,1)}`}
            </Typography>
          }
          {(post.postType==='POINTS_MILESTONE'||post.postType==='POINTS_ACCOUNT_OPEN') && 
          <div style={{width:'60px',height:'60px',textAlign:'center'}}>
            <img src={confetti} width='35' height='35' alt='confetti'/>
          </div>}
          {post.postType==="BROADCAST" &&
            <div style={{width:'60px',height:'60px',textAlign:'center'}}>
              <img src={bhyveLogo} width='60px' alt='logo'/>
          </div>
          }
          <Typography style={{paddingTop: post.postType==='LIKE_POSTED_SHOUTOUT' && '15px'}}>
            {post.postType==='BADGE_RECEIVED'?` ${post.publisherName} has gifted you a ${post.badgePublicId.badgeDisplayName} badge !`:
              post.postType==='ACCEPT_COLLABORATION' ? `${post.publisherName} has accepted your request to collaborate. Start your conversation.`:
              post.postType==='REJECT_COLLABORATION' ? `${post.publisherName} is busy right now and has declined your request for #${post.skillName} . Look for other experts`:
              post.postType==='POINTS_MILESTONE'?` You have achieved a points milestone!`:
              post.postType==='POINTS_ACCOUNT_OPEN'?` Congratulations! You've just won your first points on BHyve!`:
              post.postType==='SKILL_TAGGED_SHOUTOUT'?<span>{` ${post.publisherName} posted a buzz about `}{getTaggedSkills(post.skillName).map((item,index,arr) => index !== arr.length - 1 ? <Link style={{color:'#EE813A'}} href={`/User/Search?q=${capitalize(item.slice(1))}`} id='tagged-skill'>#{capitalize(item.slice(1))}, </Link> : <Link style={{color:'#EE813A'}} href={`/User/Search?q=${capitalize(item.slice(1))}`} id='tagged-skill'>#{capitalize(item.slice(1))}</Link>)}{'. Join the conversation'}</span>:
              post.postType==='COMMENT_SHOUTOUT'?` ${post.publisherName} just replied to your buzz. Go check it out.`:
              post.postType==='USER_TAGGED_COMMENT'?` ${post.publisherName} just replied to your buzz. Go check it out.`:
              post.postType==='LIKE_POSTED_SHOUTOUT'?` ${post.publisherName} just liked your buzz! Great job!`:
              post.postType==='LIKE_POSTED_COMMENT'?` ${post.publisherName} liked your comment on a buzz!`:
              post.postType==='USER_TAGGED_SHOUTOUT'?` ${post.publisherName} tagged you in a buzz. Go check it out!`:
              post.postType==='SKILL_TAGGED_COMMENT'?<span>{` ${post.publisherName} posted a comment about `}<Link style={{color:'#EE813A'}} href={`/User/Search?q=${post.skillName}`}>{`#${post.skillName}`}</Link><span>{` on a buzz!`}</span></span>:
              post.postType==='SKILL_TAGGED_ARTICLE'?<span>{` ${post.publisherName} has published an article on `}{getTaggedSkills(post.skillName).map((item,index,arr) => index !== arr.length - 1 ? <Link style={{color:'#EE813A'}} href={`/User/Search?q=${capitalize(item.slice(1))}`} id='tagged-skill'>#{capitalize(item.slice(1))}, </Link> : <Link style={{color:'#EE813A'}} href={`/User/Search?q=${capitalize(item.slice(1))}`} id='tagged-skill'>#{capitalize(item.slice(1))}</Link>)}{`. Take a look.`}</span>:
              post.postType==='CO_AUTHOR_TAGGED_ARTICLE'?` ${post.publisherName} has tagged you as a co-author in an Article. Have a look!`:
              post.postType==='USER_TAGGED_ARTICLE_COMMENT'?` ${post.publisherName} just tagged you in a comment`:
              post.postType==='ARTICLE_LIKED'?` ${post.publisherName} just liked your Article.`:
              post.postType==='ARTICLE_COMMENTED'?` ${post.publisherName} just commented on your Article.`:
              post.postType==='OPEN_ENDORSEMENT'?<span>{`Hello Rockstar! ${post.publisherName} just endorsed you for `}<Link style={{color:'#EE813A'}} href={`/User/Search?q=${post.skillName}`}>{`#${post.skillName}`}</Link><span>{`! Kudos!`}</span></span>:
              post.postType==='NUMERO_UNO'?` Congratulations! You're #1 on the leaderboard! Keep up the good work!`:
              post.postType==='ANOTHER_COMMENT_ACTIVITY_SHOUTOUT'? ` ${post.publisherName} commented on a buzz, you commented!`:
              post.postType==="BROADCAST"?post.skillName:
              ``
            }
        </Typography>
      </div>
      <div style={{width:'100%',textAlign:'right'}}>
        <Typography variant='caption' >{moment(post.time).fromNow()}</Typography>
      </div>
    </div>
  )
}
