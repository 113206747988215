import React,{useEffect} from 'react';
import { Typography } from '@material-ui/core';
import style from './userInitialsStyle.module.scss';

const UserIntitials = ({firstName, lastName, size}) => {
    useEffect(() => {
    },[firstName, lastName])
    return (
       <Typography variant={size === 'small'?'body1' : 'h6'} className={style.initialContainer} style={{width:size === 'small'?'30px' : '40px',height:size === 'small'?'30px' : '40px',cursor:'pointer',textAlign:'center!important',display:'flex',justifyContent:'center',paddingTop: '3px',color:'white',background:'#FFCC33'}}>
        {`${firstName && firstName.slice(0,1)}${lastName && lastName.slice(0,1)}`}
      </Typography>
    )
}

export default UserIntitials;