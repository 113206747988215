import {createSelector} from 'reselect';

const walkthrough = state => state.walkthrough;

export const selectTourStart = createSelector(
  [walkthrough],
  walkthrough => walkthrough.tourStart
);

export const selectTriggerPoint = createSelector(
    [walkthrough],
    walkthrough => walkthrough.triggerPoint
  );