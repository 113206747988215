/*jshint esversion:9*/

import React from 'react';
import {Typography,Paper} from '@material-ui/core';
import Skills from '../SubComponents/skillCard-skills/skillCard-skills';
import EditIcon from '@material-ui/icons/Edit';
import './skillCard-style.scss';

function SkillCard({info, user, handleEdit}){


  function compare( a, b ) {
if ( a.skillName < b.skillName ){
  return -1;
}
if ( a.skillName > b.skillName ){
  return 1;
}
return 0;
}

  const sortArray = data => {
    try {
      console.log('skillCard data: ', data);
      let newArray = data.userSkills;
      let basicSkills = [];
      let intermediateSkills = [];
      let advancedSkills = [];
      let expertSkills = [];
      newArray.map(item => item.score === 0.333?basicSkills.push(item):item.score===0.667?intermediateSkills.push(item):item.score===0.8?advancedSkills.push(item):expertSkills.push(item));
      newArray = [...expertSkills.sort(compare),...advancedSkills.sort(compare),...intermediateSkills.sort(compare),...basicSkills.sort(compare)];
      return newArray;
    } catch (error) {
      console.log(error.message);
    }
  };

  return(
    <Paper className='skillCard-container'>
      <div style={{width:'100%',padding:'10px 10px'}}>
        <Typography variant='subtitle1' style={{borderBottom:'1px solid rgba(112, 112, 112,0.3)',lineHeight:'1.5',display:'flex', justifyContent:'space-between'}}>
          <span style={{padding:'8px 0 3px 0'}} className='skill-card-title'>Skills</span>
          {user && <EditIcon style={{opacity:'0.6',cursor:'pointer',marginTop:'10px'}} onClick={() => handleEdit()}/>}
        </Typography>
      </div>
      <div className='skills-scroll-container'>
      {sortArray(info).map((item,index)=><Skills key={index} name={item.skillDisplayName} level={item.score} des={item.description} totalEndorsementCount={item.totalEndorsementCount && item.totalEndorsementCount} endorsementAvg={item.totalEndorsementAverage && item.totalEndorsementAverage}/>)}
      </div>
    </Paper>
  )
}

export default SkillCard;
