/*jshint esversion:9*/

import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { FixedSizeList } from 'react-window';
import {Tooltip,Fab,Typography,ListItem,ListItemText} from '@material-ui/core';



function LocationsList({locations,handleClick}){

  const Row = ({ index, style }) => (
  <div  style={{marginBottom:'5px'}}>
    <ListItem button  key={index}>
      <ListItemText primary={`${locations[index].streetAddress}, ${locations[index].extendedStreetAddress}, ${locations[index].city}, ${locations[index].state}, ${locations[index].country}-${locations[index].zipCode}`} />
    </ListItem>
  </div>
  );



  return(
    <div>
    <Typography variant='caption' style={{display:'flex',justifyContent:'flex-start'}}>Location</Typography>
    <div style={{display:'flex'}}>
    <FixedSizeList height={200} width={320} itemSize={40} itemCount={locations.length} style={{border:'1px solid black',marginRight:'10px'}}>
      {Row}
    </FixedSizeList>
    <Tooltip title="Add" aria-label="add" onClick={()=>handleClick()}>
      <Fab color="primary" >
        <AddIcon style={{color:'white'}}/>
      </Fab>
    </Tooltip>
    </div>
    </div>
  )
}

export default LocationsList;
