import cmsAxios from "../../../axios/authAxios"
export default async ({
    id, title, content, synopsis, selectedSkills, selectedAuthors, coverImageUrl, coverImageKey, thumbnailImageUrl, thumbnailImageKey,
}) => {
  //  const skills = selectedSkills.map((skills) => skills.skillPublicId);
  //  const skillsFilter = skills.reduce((acc, item) => {
  //    if (!acc.includes(item)) {
  //      acc.push(item);
  //    }
  //    return acc;
  //  }, []);
  //  const authors = selectedAuthors.map(author=>author.publicId)
  //  const filterAuthors = authors.reduce((acc, item) => {
  //   if (!acc.includes(item)) {
  //     acc.push(item);
  //   }
  //   return acc;
  // }, []);
    let body = {
        title,
        synopsis,
        content,
        coverImageUrl,
        coverImageKey,
        thumbnailImageUrl,
        thumbnailImageKey,
        taggedSkills: selectedSkills ? selectedSkills : [],
        taggedCoAuthorsPublicId: selectedAuthors ? selectedAuthors : [],
    }
    // console.log(body);
    try {
        return await cmsAxios.patch(`cms/article/${id}`, body);
    } catch (err) {
        // console.log(err)
        return { error: err }
    }
}