import React,{useState, useEffect} from "react";
import {TextField, Button} from '@material-ui/core';
import DepartmentListItem from '../../../Components/SubComponents/department-list-item/index';
import EditDepartmentModal from "../../../Components/SubComponents/edit-department-modal";
import axios from 'axios';
import './style.scss'
import { selectUserData } from "../../../redux/user/user-selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

function DepartmentPage({ userData}) {
    const [departments,setDepartments] = useState([]);
    const [newDepartment, setNewDepartment] = useState("");
    const [currentDepartment, setCurrentDepartment] = useState('');
    const [editModal, setEditModal] = useState(false);

    const token = localStorage.getItem('bhyve-token');

    useEffect(() => {
        try {
            if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {
            const getAllDepartments = async () => {
                const result = await axios.get(`${process.env.REACT_APP_OWNER_URL}ownerPanel/departments`,{
                    headers:{
                      'Authorization': `Bearer ${token}`,
                      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                    }
                  });
                  setDepartments(result.data);
            }

            getAllDepartments();
        }
        } catch (error) {
            console.log(error);
        }
    },[]);

    function capitalize(word) {
        try {
            if(word.length > 0) {
                let finalWord;
                let CapitalizedWords = [];
                let words = word.split(' ');
                CapitalizedWords = words.map(i => i[0].toUpperCase() + i.slice(1,i.length));
                finalWord = CapitalizedWords.join(' ');
                return finalWord;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = e => {
        const {value} = e.target;
        setNewDepartment(value);
    }

    const addDepartment = async () => {
        try {
            if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

            if(newDepartment.length > 0) {
                let departmentNames = [];
                if(newDepartment.includes(',')) {
                    departmentNames = newDepartment.split(', ');
                    departmentNames = departmentNames.map(i => capitalize(i));
                }else{
                    departmentNames = [capitalize(newDepartment)];
                }
                let finalArray = [];
                departmentNames.map(i => finalArray.push({departmentDisplayName: i}));
                let requestBody = {
                    departments: finalArray
                }
                const result = await axios.post(`${process.env.REACT_APP_OWNER_URL}ownerPanel/departments`,requestBody,{
                    headers:{
                      'Authorization': `Bearer ${token}`,
                      'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                    }
                  });
                setDepartments(result.data);
            }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteDepartment = async id => {
        try {
            if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

            const result = await axios.delete(`${process.env.REACT_APP_OWNER_URL}ownerPanel/department/${id}`,{
                headers:{
                  'Authorization': `Bearer ${token}`,
                  'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                }
              });
            if(result) {
                let currentDepartments = departments;
                currentDepartments = currentDepartments.filter(i => i.departmentPublicId !== id);
                setDepartments(currentDepartments);
            }
        }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEditModal = id => {
        try {
            let departmentFound = departments.find(i => i.departmentPublicId === id);
            if(departmentFound) {
                setCurrentDepartment(departmentFound);
                setEditModal(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleModalClose = () => {
        setCurrentDepartment('');
        setEditModal(false);
    }

    const handleEditDepartment = async name => {
        try {
            if (!!userData && !!userData.userInformation && userData.userInformation.userRole === "OWNER") {

            let newName = capitalize(name);
            const result = await axios.patch(`${process.env.REACT_APP_OWNER_URL}ownerPanel/department/${currentDepartment.departmentPublicId}`,{departmentDisplayName: newName},{
                headers:{
                  'Authorization': `Bearer ${token}`,
                  'x-api-key':'1119d3ce-fb6d-44ef-bbc0-fb7fbbba967e'
                }
              });
            console.log(result);
            let newDepartmentArray = departments.map(i => i.departmentPublicId === currentDepartment.departmentPublicId ? result.data : i);
            setDepartments(newDepartmentArray);
            setEditModal(false);
            setCurrentDepartment('');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <div className='department-page-container'>
            <h1>Departments</h1>
            <small>Add a department</small>
            <TextField 
                label='Department Names' 
                className='department-name-txtfield'
                placeholder="seperate names by ', '"
                value={newDepartment}
                onChange={handleChange}
            />
            <Button 
                className='department-add-btn' 
                variant='outlined' 
                color='primary'
                onClick={addDepartment}
            >
                Add
            </Button>
            <div className='department-grid-view'>
                {departments.map(i => 
                    <DepartmentListItem 
                        key={i.id} 
                        name={i.departmentDisplayName} 
                        handleDelete={() => deleteDepartment(i.departmentPublicId)}
                        handleEdit={() => handleEditModal(i.departmentPublicId)}
                        />
                )}
            </div>
            <EditDepartmentModal open={editModal} handleClose={handleModalClose} currentDepartment={currentDepartment} handleEdit={handleEditDepartment}/>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    userData: selectUserData,
  });
  
  const mapDispatchToProps = (dispatch) => ({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPage);