import React from "react";
import Chip from "@material-ui/core/Chip";
import { FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import "./autoComplete-style.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 500,
    // "& > * + *": {
    //   marginTop: theme.spacing(3),
    // }
  },
}));

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: "1px",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
}));

export const AutocompleteUI = ({
  defaultValue,
  options,
  value,
  onSelectionChange,
  onChange,
  handleDelete,
  label,
  skillsError,
  getOptionLabel,
}) => {
  const classes = useStyles();
  const helperTestClasses = helperTextStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        value={value}
        id="tags-outlined"
        multiple
        options={options && options}
        // defaultValue={[...defaultValue]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              // onDelete={(e) => { handleDelete ? handleDelete(option) : console.log(option) }}
            />
          ))
        }
        onChange={onSelectionChange}
        renderInput={(params) => (
          <TextField
            onChange={onChange}
            variant="outlined"
            error={skillsError}
            helperText={skillsError && "Please tag atleast one skill"}
            FormHelperTextProps={{ classes: helperTestClasses }}
            {...params}
            autoFocus={skillsError}
          />
        )}
        // getOptionLabel={getOptionLabel ? getOptionLabel : null}
      />
    </div>
  );
};

AutocompleteUI.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default AutocompleteUI;
