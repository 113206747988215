import React,{useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    txtField: {
        margin: '10px auto'
    }
  }));

function EditDepartmentModal({open, handleClose, currentDepartment, handleEdit}) {

    const [currentName, setCurrentName] = useState('');

    const classes = useStyles();

    const handleChange = e => {
        const {value} = e.target;
        setCurrentName(value);
    }

    return(
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2>{currentDepartment.departmentDisplayName}</h2>
              <TextField className={classes.txtField} value={currentName} onChange={handleChange} placeholder='Enter new name'/>
              <div>
                <Button variant='outlined' color='primary' onClick={() => {setCurrentName(''); handleEdit(currentName)}}>Edit</Button>
                <Button onClick={() => {setCurrentName(''); handleClose();}}>Close</Button>
              </div>
            </div>
          </Fade>
        </Modal>
    )
}

export default EditDepartmentModal;