import React from 'react';
import {Modal,makeStyles,Backdrop,Fade,IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SuccessLogo from '../../../Assets/success.png';



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width:'600px', 
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
    },
  }));

export default function AckEndorseSuccessModal({open,handleClose,role,skill}) {

    const classes = useStyles();

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{width: '100%', textAlign: 'right'}}>
                <IconButton onClick={() => handleClose()}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <div style={{width: '100%', textAlign: 'center',padding: '0, 30px'}}>
                <img src={SuccessLogo} width='80' height='80'/>
                {/* {role === 0 ? 
                    <h2 style={{fontWeight:'bold'}}>Acknowledgement sent for <span style={{color: '#EE813A',fontWeight:'bold',fontSize: '1.5rem'}}>{skill}</span></h2>
                : */}
                    <h2 style={{fontWeight:'bold'}}>Endorsement sent for <span style={{color: '#EE813A',fontWeight:'bold',fontSize: '1.5rem'}}>{skill}</span></h2> 
                {/* } */}
            </div>
          </div>
        </Fade>
      </Modal>
    )
}