/*jshint esversion:9*/

import React,{useEffect,useState} from 'react';
import { makeStyles,Typography,IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import milestone from '../../../Assets/milestone.png';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth:'500px',
    outline:'none',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign:'center'
  },
}));

export default function MilestoneModal({open,handleClose,points,badge,type}) {
  const classes = useStyles();
  const [badgeDetails,setBadgeDetails] = useState(null);
  //const [loading,setLoading] = useState(true);
useEffect(()=>{
  try {
    if(badge){
      setBadgeDetails(badge);
    }
  } catch (error) {
    console.log(error.message);
  }
 
},[badge]);



  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          handleClose();
      }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <div style={{width:"100%",textAlign:'right'}}>
            <IconButton onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
          </div>
          {type==='BADGE_RECEIVED'&&badgeDetails&&<img src={badgeDetails.badgeImageUrl} alt='milestone' style={{width:'70%'}}/>}
            {type!=='BADGE_RECEIVED'&&<img src={milestone} alt='milestone' style={{width:'100%'}}/>}
            <Typography variant={type==='BADGE_RECEIVED'?'h5':'h4'} style={{fontWeight:'bolder'}}>{type!=='BADGE_RECEIVED'?`Congratulations !`:badgeDetails?badgeDetails.badgeName:``}</Typography>
            <br/>
            {type==='POINTS_MILESTONE'&&<Typography variant='subtitle1' >You have achieved a milestone of</Typography>}
            {type==='POINTS_ACCOUNT_OPEN'&&<Typography variant='subtitle1' >You have achieved your first points</Typography>}
            {type!=='BADGE_RECEIVED'&&<Typography variant='h4' style={{fontWeight:'bolder'}}>{`${points} Points`}</Typography>}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
